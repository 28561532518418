import {
  createStore,
  compose,
  applyMiddleware,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware as createRouterMiddleware } from 'react-router-redux';
import { createTracker } from 'redux-segment';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import actionMapper from './segment/actionMapper';

export const history = createHistory();

const sagaMiddleware = createSagaMiddleware();
const routerMiddleware = createRouterMiddleware(history);
const tracker = createTracker(actionMapper);

const initialState = window.__INITIAL_STATE__ || {};
delete window.__INITIAL_STATE__;

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware,
      tracker,
    ),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
  ),
);

sagaMiddleware.run(rootSaga());

export default store;
