import React from 'react';
import PropTypes from 'prop-types';

import {
  generatePath,
} from 'react-router-dom';

import {
  Heading,
  Body,
  Link,
  ExtendedFlex,
} from '../../coreui';

import { autoInvestDirectEnrollmentUrl } from '../../dashboard/links';

class AutoInvestConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const {
      io,
      foe,
    } = this.props;
    return (
      <ExtendedFlex
        flexDirection="column"
        mb={80}
      >
        <Heading
          type="H6.1"
        >
          Now available: Enroll in Auto Invest.
        </Heading>
        <Body
          type="B1"
        >
          As a REIT investor, you may enroll in our Auto Invest Program. Once
          your enrollment is active, sit back and watch your money get put to
          work for you automatically. Auto Invest may be modified, paused, or
          cancelled by you at anytime.
        </Body>
        <Link
          type="L1"
          href={generatePath(autoInvestDirectEnrollmentUrl, { io, foe })}
        >
          Add Auto Invest
        </Link>
      </ExtendedFlex>
    );
  }
}

AutoInvestConfirmation.propTypes = {
  io: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  foe: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

export default AutoInvestConfirmation;
