import types from './types';

export default {
  updateSelectedPastInvestmentIoTypeFilterValue: (value) => ({
    type: types.UPDATE_SELECTED_PAST_INVESTMENT_IO_TYPE_FILTER_VALUE,
    value,
  }),
  updateShowMorePastInvestments: (value) => ({
    type: types.UPDATE_SHOW_MORE_PAST_INVESTMENTS,
    value,
  }),
};
