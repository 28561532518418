import React from 'react';

import ArticleCardType from '../components/ArticleCardType';

const articleCardTypeFactoryFactory = (props) => {
  const {
    type,
  } = props;

  const articleCardTypeFactory = () => {
    return (
      <ArticleCardType
        type={type}
      />
    );
  };

  return articleCardTypeFactory;
};

export default articleCardTypeFactoryFactory;
