import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import { callApiCallback } from '../../api';

export function* getMoreArticlesFlow(action) {
  const apiToCall = action.url;
  try {
    const response = yield call(callApiCallback, apiToCall);
    yield put(
      actions.getMoreArticlesSucceeded(response.data),
    );
  } catch (error) {
    yield put(
      actions.getMoreArticlesFailed(error),
    );
  }
}

export function* watchGetMoreArticlesRequested() {
  yield takeLatest(
    types.GET_MORE_ARTICLES_REQUESTED,
    getMoreArticlesFlow,
  );
}

export default watchGetMoreArticlesRequested;
