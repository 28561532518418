import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DashboardLoader from '../../dashboard/components/DashboardLoader';

const AppRedirect = ({
  frontendPrefix,
  match,
}) => {
  useEffect(() => {
    if (match.url === '/electronic-consent-and-delivery-of-documents') {
      window.location.href = 'https://www.realtymogul.com/legal-electronic-consent';
    } else {
      window.location.href = `${frontendPrefix}${match.url}`;
    }
  }, [frontendPrefix, match]);

  return (
    <DashboardLoader />
  );
};

AppRedirect.propTypes = {
  frontendPrefix: PropTypes.string,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  frontendPrefix: state.app.frontendPrefix,
});

export default connect(mapStateToProps)(AppRedirect);
