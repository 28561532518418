import React from 'react';

import {
  ExtendedFlex,
  Heading,
  Body,
} from '../../coreui';

const Questions = () => {
  return (
    <ExtendedFlex
      flexDirection="column"
    >
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        Questions?
      </Heading>
      <Body
        type="B2"
      >
        Our Investor Relations team is available 8AM-6PM PST M-F.
      </Body>
      <Body
        type="B1"
      >
        (877) 977-2776
      </Body>
    </ExtendedFlex>
  );
};

export default Questions;
