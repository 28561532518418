import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import get from 'lodash/get';

import {
  Box,
  ExtendedFlex,
  Formatters,
  Heading,
  Link,
} from '../../coreui';

import DashboardSection from './DashboardSection';
import ReitIContent from './ReitIContent';
import ReitIIContent from './ReitIIContent';
import SummaryItem from './SummaryItem';

export const getReitData = (data, asArray = false) => {
  const autoinvestConfigs = get(data, 'autoinvestConfigs') || [];
  const gotData = get(data, 'activeInvestmentsData.reitData.reits') || [];

  const reitData = {
    reitIData: {
      ...gotData.find((item) => item.nid === 193744),
      autoinvestConfigs: autoinvestConfigs.filter((item) => item.id === 193744),
    },
    reitIIData: {
      ...gotData.find((item) => item.nid === 274949),
      autoinvestConfigs: autoinvestConfigs.filter((item) => item.id === 274949),
    },
  };

  return asArray ? [reitData.reitIData, reitData.reitIIData] : reitData;
};

const Reits = ({ data }) => {
  if (data.activeInvestmentsData.reitData === undefined) {
    return null;
  }

  const totalInvested = data.activeInvestmentsData.reitData !== undefined ?
    data.activeInvestmentsData.reitData.summary.items.totalInvested.value : 0;
  const formattedTI = data.activeInvestmentsData !== undefined ?
    numeral(totalInvested).format(Formatters.DOLLARS_DECIMALS) : 0;
  const currentValue = data.activeInvestmentsData.reitData !== undefined ?
    data.activeInvestmentsData.reitData.summary.items.currentValue.value : 0;
  const formattedTD = data.activeInvestmentsData.reitData !== undefined ?
    numeral(currentValue).format(Formatters.DOLLARS_DECIMALS) : 0;
  const totalDistributed = data.activeInvestmentsData?.reitData?.reits?.reduce((acc, currentValue) => {
    const total = currentValue.overview?.cashDistributionsPaid?.value + currentValue.overview?.distributionsReinvested?.value;
    return acc + total;
  }, 0);

  const reitData = getReitData(data);

  return (
    <ExtendedFlex
      flexDirection="column"
      width={1}
      alignItems="center"
    >
      <DashboardSection
        title="REITS"
      >
        <ExtendedFlex
          flexDirection={['column', 'column', 'column', 'row', 'row']}
          justifyContent="center"
          alignItems="center"
          w={1}
          py={48}
        >
          {
            formattedTI !== undefined &&
            <SummaryItem
              value={formattedTI}
              label="Total Invested"
              valueFormat={Formatters.DOLLARS_DECIMALS}
            />
          }
          <Box m={8} mx={[0, 0, 0, 16, 24]} />
          {
            totalDistributed !== undefined &&
            <SummaryItem
              value={totalDistributed}
              label="Total Distributed"
              valueFormat={Formatters.DOLLARS_DECIMALS}
            />
          }
          <Box m={8} mx={[0, 0, 0, 16, 24]} />
          {
            formattedTD !== undefined &&
            <SummaryItem
              value={formattedTD}
              label="Total Investment Value"
              valueFormat={Formatters.DOLLARS_DECIMALS}
            />
          }
        </ExtendedFlex>
        <ExtendedFlex
          w={1}
          flexDirection={['column', 'row']}
          justifyContent="center"
        >
          {
            reitData.reitIData?.overview?.totalShares?.value !== 0 &&
            <ReitIContent
              data={reitData.reitIData}
            />
          }
          {
            reitData.reitIIData?.overview?.totalShares?.value !== 0 &&
            <ReitIIContent
              data={reitData.reitIIData}
            />
          }
          {
            reitData.reitIData?.overview?.totalShares?.value === 0 &&
            reitData.reitIIData?.overview?.totalShares?.value === 0 &&
            <ExtendedFlex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
            >
              <Heading type="H4" lineHeight={1.5} mb={24}>You currently have no REIT investments.</Heading>
              <Link
                type="L1"
                href="https://www.realtymogul.com/investment-opportunities"
              >
                Browse Open Investments
              </Link>
            </ExtendedFlex>
          }
        </ExtendedFlex>
      </DashboardSection>
    </ExtendedFlex>
  );
};

Reits.propTypes = {
  data: PropTypes.object,
};

export default Reits;
