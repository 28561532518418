/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Body,
} from '../../coreui';

const DashboardTableCellWrapper = ({
  containerProps,
  bodyProps,
  children,
}) => {
  return (
    <ExtendedFlex
      alignItems="center"
      m={0}
      {...containerProps}
    >
      <Body
        type="B3"
        m={0}
        {...bodyProps}
      >
        {children}
      </Body>
    </ExtendedFlex>
  );
};

DashboardTableCellWrapper.propTypes = {
  children: PropTypes.object,
  bodyProps: PropTypes.object,
  containerProps: PropTypes.object,
};

export default DashboardTableCellWrapper;
