import at from 'lodash/at';
import mergeWith from 'lodash/mergeWith';

import types from './types';

const initialState = {
  isqEntity: undefined,
  latestIsq: undefined,
  loaded: false,
  accountDefaultData: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LATEST_ISQ_REQUESTED: {
      return {
        ...state,
        loaded: false,
      };
    }
    case types.GET_LATEST_ISQ_SUCCEEDED: {
      const isqEntity = {
        ...action.data.data[0],
      };
      const [accountDefaultData] = at(action.data, ['_meta.account']);
      const latestIsq = {
        ...isqEntity,
      };

      mergeWith(latestIsq, accountDefaultData, (currentValue, accountValue) => {
        if (currentValue === null || currentValue === undefined) {
          return accountValue;
        }
        return currentValue;
      });

      return {
        ...state,
        loaded: true,
        // The latest ISQ entity data.
        isqEntity,
        // The merged data from account and ISQ entity.
        latestIsq,
        // The data just on the account.
        accountDefaultData,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
