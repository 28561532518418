import React from 'react';

import InvestmentCardIoLabel from '../components/InvestmentCardIoLabel';

const section3FactoryFactory = (props) => {
  const { ioLabel, IoLabelTooltip } = props;

  const section3Factory = () => {
    return (
      <InvestmentCardIoLabel
        ioLabel={ioLabel}
        IoLabelTooltip={IoLabelTooltip}
      />
    );
  };

  return section3Factory;
};

export default section3FactoryFactory;
