/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import {
  ExtendedFlex,
  Data,
  Body,
  Link,
  Heading,
  Image,
  ExtendedBox,
  ModalWithOverlay,
  Content,
} from '../../coreui';

import linkedInIcon from '../../assets/icon_linkedin.svg';

// eslint-disable-next-line
class TeamMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState((state) => ({
      isModalVisible: !state.isModalVisible,
    }));
  }

  render() {
    const {
      photo,
      linkedIn,
      name,
      title,
      bio,
      ...passedProps
    } = this.props;
    return (
      <ExtendedFlex
        flexDirection="row"
        alignItems="center"
        bg="white"
        mx={10}
        my={10}
        minWidth="320px"
        {...passedProps}
      >
        {
          photo.url &&
          <Image
            src={photo.url}
            height={93}
            width={93}
            onClick={this.toggleModal}
            hover
          />
        }
        <ExtendedFlex
          flexDirection="column"
          alignItems="flex-start"
          mx={10}
          my={10}
        >
          <Data
            type="D2"
            my={0}
          >
            {name}
          </Data>
          <Body
            type="B5"
            my={0}
          >
            {title}
          </Body>
        </ExtendedFlex>
        {
          linkedIn &&
          <Link
            display="inline-block"
            type="L4"
            href={linkedIn}
            target="_blank"
            mx={30}
            hover
          >
            <Image
              src={linkedInIcon}
              height={17}
              width={18}
              hover
            />
          </Link>
        }
        <ModalWithOverlay
          isVisible={this.state.isModalVisible}
          handleClick={this.toggleModal}
        >
          <ExtendedBox
            width={['100%', '100%', '640px', '800px']}
            height={['100%', '100%', '360px', '440px']}
            overflowY="scroll"
          >
            <ExtendedFlex
              flexDirection="row"
            >
              <ExtendedBox
                w={140}
                h={140}
              >
                <Image
                  src={photo.url}
                  height={140}
                  width={140}
                />
              </ExtendedBox>
              <ExtendedFlex
                flexDirection="column"
                ml={40}
              >
                <Heading
                  m={0}
                  type="H6"
                >
                  {name}
                </Heading>
                <Body
                  type="B2"
                >
                  {title}
                </Body>
                <Content>
                  {ReactHtmlParser(bio)}
                </Content>
                {
                  linkedIn &&
                  <Link
                    display="inline-block"
                    type="L4"
                    href={linkedIn}
                    target="_blank"
                  >
                    <Image
                      src={linkedInIcon}
                      height={30}
                      width={30}
                      cursor="pointer"
                    />
                  </Link>
                }
              </ExtendedFlex>
            </ExtendedFlex>
          </ExtendedBox>
        </ModalWithOverlay>
      </ExtendedFlex>
    );
  }
}

TeamMember.propTypes = {
  photo: PropTypes.object,
  title: PropTypes.string,
  name: PropTypes.string,
  linkedIn: PropTypes.string,
  bio: PropTypes.string,
};

TeamMember.defaultProps = {
  photo: {},
};

export default TeamMember;
