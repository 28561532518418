import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from '../../coreui';

const ShareRepurchaseHeading = ({ children }) => {
  return (
    <Heading
      type="H2"
      mt={0}
      mb={25}
    >
      {children}
    </Heading>
  );
};

ShareRepurchaseHeading.propTypes = {
  children: PropTypes.object,
};

export default ShareRepurchaseHeading;
