import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  VisibilitySensor,
} from '../coreui';

class VisibilityTrigger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible: true,
      });
    }
  }

  render() {
    const {
      children,
      trigger,
    } = this.props;

    return (
      <>
        <VisibilitySensor
          callback={this.handleSensorChange}
        />
        {
          React.Children.map(children, (child) => {
            // Skip children that aren't rendered.
            if (!child) {
              return null;
            }

            return (
              React.cloneElement(
                child,
                {
                  [trigger]: this.state.isVisible,
                },
              )
            );
          })
        }
        <VisibilitySensor
          callback={this.handleSensorChange}
        />
      </>
    );
  }
}

VisibilityTrigger.propTypes = {
  children: PropTypes.node,
  trigger: PropTypes.string,
};

export default VisibilityTrigger;
