/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Heading,
  Body,
  Link,
} from '../../../coreui';
import AutoInvestContentWrapper from './AutoInvestContentWrapper';

const Welcome = ({
  reit1,
  reit2,
  ownershipOptions,
}) => {
  const reit1Options = ownershipOptions.filter(({ io: { id } }) => id === reit1.nid);
  const reit2Options = ownershipOptions.filter(({ io: { id } }) => id === reit2.nid);

  // Check if enrollment is disabled on any of the ownership options
  const enrollmentDisabled = ownershipOptions.some((option) => option.io && option.io.enrollmentsDisabled);

  const noReit1 = !reit1Options.length;
  const noReit2 = !reit2Options.length;
  const hasReit1 = !noReit1;
  const hasReit2 = !noReit2;

  return (
    <AutoInvestContentWrapper>
      <ExtendedFlex
        flexDirection="column"
        w={1}
        maxWidth={650}
        mt={[50]}
        mb={50}
      >
        <Heading
          type="H2"
          mt={0}
          mb={25}
        >
          Welcome to Auto Invest
        </Heading>
        {
          noReit2 && (reit1.isPledge || noReit1) &&
          <>
            <Body
              type="B1"
            >
              You currently have no active investments eligible for enrollment
              into Auto Invest. {reit1.isPledge && `${reit1.title} is currently in "Pledge" and is not eligible for Auto Invest Enrollment. The only REIT currently eligible for enrollment is ${reit2.title}.`} {!reit1.isPledge && `${reit1.title} or `} {reit2.title} investors enrolled in Auto Invest can choose to set up a recurring amount that will be automatically added to their investment month after month.
            </Body>
            <ExtendedFlex
              flexDirection={['column', 'row']}
            >
              {
                !reit1.isPledge && reit1.smartButtonUrl &&
                <Link
                  mt={50}
                  type="L1"
                  href={reit1.smartButtonUrl}
                  mr={20}
                  mb={[30, 0]}
                >
                  Add {reit1.title}
                </Link>
              }
              {
                reit2.smartButtonUrl &&
                <Link
                  mt={[!reit1.isPledge && reit1.smartButtonUrl ? 0 : 20, 50]}
                  type="L1"
                  href={reit2.smartButtonUrl}
                >
                  Add {reit2.title}
                </Link>
              }
            </ExtendedFlex>
          </>
        }
        {
          ((!reit1.isPledge && hasReit1) || hasReit2) &&
          <Body
            type="B1"
          >
            {
              enrollmentDisabled &&
              <>
                We are not currently accepting auto invest enrollments. When the
                Auto Investment Program is available we will promptly notify
                you.
              </>
            }
            {
              !enrollmentDisabled &&
              <>
                Put your money to work for you automatically. Set up a recurring investment amount that will automatically be added to your current {!reit1.isPledge && hasReit1 && `${reit1.title} or `} {reit2.title} investment month after month. {reit1.isPledge && `${reit1.title} is currently in "Pledge" and is not eligible for Auto Invest Enrollment.`}
              </>
            }
          </Body>
        }
      </ExtendedFlex>
    </AutoInvestContentWrapper>
  );
};

Welcome.propTypes = {
  reit1: PropTypes.object,
  reit2: PropTypes.object,
  ownershipOptions: PropTypes.array,
};

export default Welcome;
