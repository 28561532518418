import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  withRouter,
} from 'react-router-dom';

import {
  ExtendedFlex,
} from '../../../coreui';

import Reits from '../../components/Reits';
import PrivatePlacements from '../../components/PrivatePlacements';

class ActiveInvestmentsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const data = {
      autoinvestConfigs: this.props.autoinvestConfigs,
      ...this.props.data,
    };

    return (
      <ExtendedFlex
        flexDirection="column"
        width="100%"
        alignItems="center"
      >
        {
          this.props.data.activeInvestmentsData.privatePlacementData !== undefined &&
          <PrivatePlacements
            data={this.props.data}
            pendingData={this.props.pendingData}
            type="active"
          />
        }
        <Reits
          data={data}
        />
      </ExtendedFlex>
    );
  }
}

// TODO: HERE. Implement flow
ActiveInvestmentsContainer.propTypes = {
  data: PropTypes.object,
  pendingData: PropTypes.object,
  autoinvestConfigs: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    data: state.dashboard.data,
    pendingData: state.dashboard.pendingData,
    autoinvestConfigs: state.autoInvest.configs,
  };
};

export default withRouter(connect(mapStateToProps)(ActiveInvestmentsContainer));
