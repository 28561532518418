import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import { transactionsApi } from '../../api';

export function* getTransactionsFlow() {
  try {
    const response = yield call(transactionsApi);

    yield put(
      actions.dashboardTransactionsApiActionSucceeded({
        ...response.data,
      }),
    );
  } catch (error) {
    yield put(
      actions.dashboardTransactionsApiActionFailed(error),
    );
  }
}

export function* watchDashboardTransactionsRequested() {
  yield takeLatest(
    types.DASHBOARD_TRANSACTIONS_API_REQUESTED,
    getTransactionsFlow,
  );
}

export default watchDashboardTransactionsRequested;
