import imgSafeway from '../assets/RP_safeway.jpg';
import imgBelAir from '../assets/RP_belair.jpg';
import img1600Golf from '../assets/RP_1600golf.jpg';
import imgLakeside from '../assets/RP_lakeside_at_town.jpg';
import imgLodgeAtMain from '../assets/RP_Lodgeatmain.jpg';
import imgVillaFrance from '../assets/RP_Villafrance.jpg';
import imgCumberlandMetro from '../assets/RP_Cumberlandmetro.jpg';
import imgEnglishVillage from '../assets/RP_Englishvillage.jpg';
import imgTrinityPlace from '../assets/RP_Trinityplace.jpg';

const recentTransactionsData = [
  {
    label: 'Equity Investment',
    location: 'Prunedale, CA',
    image: imgSafeway,
    type: 'Safeway & CVS Retail Center',
    value: '$3,510,000',
  },
  {
    label: 'Commercial Loan',
    location: 'Bel Air, CA',
    image: imgBelAir,
    type: '5 Unit Multifamily',
    value: '$2,100,000',
  },
  {
    label: 'Equity Investment',
    location: 'Rolling Meadows, IL',
    image: img1600Golf,
    type: '1600 Golf Road',
    value: '$1,525,000',
  },
  {
    label: 'Equity Investment',
    location: 'Marietta, GA',
    image: imgLakeside,
    type: 'Lakeside at Town Center Apts',
    value: '$1,240,000',
  },
  {
    label: 'Equity Investment',
    location: 'Fort Worth, TX',
    image: imgLodgeAtMain,
    type: 'Lodge At Main',
    value: '$2,015,000',
  },
  {
    label: 'Equity Investment',
    location: 'Irving, TX',
    image: imgVillaFrance,
    type: 'Villa France Apartments',
    value: '$1,515,000',
  },
  {
    label: 'Equity Investment',
    location: 'Chicago, IL',
    image: imgCumberlandMetro,
    type: 'Cumberland Metro',
    value: '$2,040,000',
  },
  {
    label: 'Equity Investment',
    location: 'Indianapolis, IL',
    image: imgEnglishVillage,
    type: 'English Village Apartments',
    value: '$510,000',
  },
  {
    label: 'Equity Investment',
    location: 'Mesquite, TX',
    image: imgTrinityPlace,
    type: 'Trinity Place Apartments',
    value: '$1,015,000',
  },
];

export default recentTransactionsData;
