/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Body,
  Heading,
  Link,
} from '../../coreui';

import CalloutFrame from '../../components/CalloutFrame';

const ImageAndCopy = ({
  heading,
  body,
  image,
  disclaimer,
  url,
  href,
  link,
  isButton = false,
}) => {
  return (
    <CalloutFrame
      image={image}
      bg="white"
      imagePosition="second"
      imagebg="center"
      maxHeight={400}
    >
      <Heading
        type="H2"
        mt={0}
      >
        {heading}
      </Heading>
      <Body
        type="B1"
        mb={0}
      >
        {body}
      </Body>
      <Body
        type="B5"
        color="#777777"
        mt={80}
      >
        {disclaimer}
      </Body>
      {
        link &&
        <Link
          type={isButton ? 'L1' : 'L4'}
          {...(href !== undefined ? { href } : { to: url })}
        >
          {link}
        </Link>
      }
    </CalloutFrame>
  );
};

ImageAndCopy.propTypes = {
  heading: PropTypes.string,
  image: PropTypes.any,
  link: PropTypes.string,
  url: PropTypes.string,
  href: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  disclaimer: PropTypes.arrayOf(PropTypes.string),
  isButton: PropTypes.bool,
};

export default ImageAndCopy;
