/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  color,
} from 'styled-system';

import {
  ExtendedFlex,
  Data,
} from '../../coreui';

export const typeMapping = {
  status: 'blue.0',
  warning: 'orange',
  error: 'red',
};

const ExtendedData = styled(Data)`
  a {
    text-decoration: underline;
    ${color}
  }
  a:visited {
    ${color}
  }
  .krumo-root {
    color: black;
  }
`;

const Message = ({
  message,
  children,
  type,
}) => {
  return (
    <ExtendedFlex
      bg={typeMapping[type]}
      flexDirection="column"
      align="flex-start"
    >
      <ExtendedData
        m={0}
        color="white"
        type="D5"
        {...{
          ...(message ? {
            dangerouslySetInnerHTML: { __html: message },
          } : {
            children,
          }),
        }}
      />
    </ExtendedFlex>
  );
};

Message.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf([
    'status',
    'warning',
    'error',
  ]),
  children: PropTypes.node,
};

Message.defaultProps = {
  type: 'status',
};

export default Message;
