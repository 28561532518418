import React from 'react';

import {
  Heading,
  Body,
} from '../../coreui';

import CalloutFrame from '../../components/CalloutFrame';
import backgroundImage from '../../assets/funds_retire_bg.jpg';

const Funds = () => {
  return (
    <CalloutFrame
      image={backgroundImage}
      imagePosition="second"
    >
      <Heading
        type="H2"
        display="block"
      >
        RETIREMENT FUNDS AND REITS
      </Heading>
      <Body
        type="B1"
        display="block"
      >
        A simple way to invest in commercial real estate is through a Real Estate Investment Trust (REIT). REITs are a diversified basket of commercial real estate and can have the objective of appreciation and cash-flow, without any of the day-to-day property management responsibilities  for the investor.
      </Body>
      <Body
        type="B1"
        display="block"
      >
        The RealtyMogul platform allows investors to use retirement funds from their Self-Directed IRAs (SDIRAs) to purchase shares in public, non-traded REITs. By allocating to real estate, investors diversify their portfolios and potentially generate income that can grow and compound within a tax-sheltered IRA over time.
      </Body>
    </CalloutFrame>
  );
};

export default Funds;
