import styled from 'styled-components';
import {
  space,
  color,
} from 'styled-system';
import Theme from '../Theme';

const Slider = styled.input.attrs({
  type: 'range',
})`
  ${space}
  ${color}
  -webkit-appearance: none;
  background: transparent;
  width: 100%;
  margin: 0;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    height: 2px;
    width: 100%;
    cursor: pointer;
    border: none;
    background: ${Theme.colors.gray[10]};
  }
  &::-webkit-slider-thumb {
    border: 2px solid ${Theme.colors.blue[1]};
    height: 18px;
    width: 18px;
    border-radius: 18px;
    background: ${Theme.colors.gray[0]};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
    box-shadow: 2px 2px 4px ${Theme.colors.gray[10]};
  }
  &::-moz-range-track {
    height: 2px;
    width: 100%;
    cursor: pointer;
    border: none;
    background: ${Theme.colors.gray[10]};
  }
  &::-moz-range-thumb {
    border: 2px solid ${Theme.colors.blue[1]};
    height: 18px;
    width: 18px;
    border-radius: 18px;
    background: ${Theme.colors.gray[0]};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
    box-shadow: 2px 2px 4px ${Theme.colors.gray[10]};
    box-sizing: border-box;
  }
  &::-ms-track {
    height: 2px;
    width: 100%;
    cursor: pointer;
    border: solid 11px;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  &::-ms-thumb {
    border: 2px solid ${Theme.colors.blue[1]};
    height: 18px;
    width: 18px;
    border-radius: 18px;
    background: ${Theme.colors.gray[0]};
    cursor: pointer;
    position: relative;
    margin-top: -2px;
    box-shadow: 2px 2px 4px ${Theme.colors.gray[10]};
    box-sizing: border-box;
  }
  &::-ms-fill-upper {
    background: ${Theme.colors.gray[10]};
  }
  &::-ms-fill-lower {
    background: ${Theme.colors.gray[10]};
  }

`;

export default Slider;
