/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { ReitTabs, Carousel } from 'rtmg-component-library';
import LinkedIn from '../../assets/LI-Logo.png';

const Container = styled.div`
  font: normal normal 300 16px/28px var(--font-sans);
  letter-spacing: 0px;
  color: #202020;
  padding-bottom: 50px;
`

const CardWrapper = styled.div`
  width: 200px;
  height: 310px;
  display: inline-block;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 6px 6px 0px 0px;
  overflow: hidden;
  &:not(:last-child) {
    margin-right: 30px;
  }
  img {
    width: 100%;
  }
  .info {
    padding: 0 20px;
  }
  .name {
    text-transform: uppercase;
    font: normal normal 500 13px/28px var(--font-sans);
    letter-spacing: 0.78px;
    color: #202020;
  }
  .title {
    font: normal normal 400 10px/15px var(--font-sans);
    letter-spacing: 0px;
    color: #606060;
  }
  a {
    width: 51px;
    display: block;
    cursor: pointer;
  }
`;

const Card = ({
  image,
  name,
  title,
  href
}) => (
  <CardWrapper>
    <img src={image} alt={name} />
    <div className="info">
      <div className="name">{name}</div>
      <div className="title">{title}</div>
      <a href={href} target="_blank" rel="noopener noreferrer"><img src={LinkedIn} alt="linked-in" /></a>
    </div>
  </CardWrapper>
)

export const IncomeREIT = () => (
  <Container>
    <div>
      RM Adviser, LLC manages the day-to-day operations of the Income REIT and makes all the decisions regarding the selection, negotiation, financing, and disposition of its investments, subject to the limitations set forth in our Second Amended And Restated Limited Liability Company Agreement, as amended. RM Adviser also provides asset management, marketing, investor relations, and other administrative services on our behalf of the REIT. For transactions involving potential conflicts of interests, such as principal transactions, real estate transactions with affiliates, and affiliate loan transactions, an approval by the Independent Representative is required.
    </div>
    <div style={{ marginTop: '40px' }}>
      <Card
        image="https://cdn.sanity.io/images/jpzshlka/production/4033ec8553e7c3a6b68b82c714d30348c128e996-197x197.png"
        name="Jilliene Helman"
        title="Chief Executive Officer"
        href="https://www.linkedin.com/in/jillienehelman/"
      />
      <Card
        image="https://cdn.sanity.io/images/jpzshlka/production/4d876c111feccde3373f1a83898ba8a20aa010b6-350x350.jpg"
        name="Kevin Moclair"
        title="Chief Accounting Officer"
        href="https://www.linkedin.com/in/kevin-moclair-1894b610a/"
      />
      <Card
        image="https://cdn.sanity.io/images/jpzshlka/production/f689c1eddddf67fb6c404d3a4f95419e7adc1751-500x500.jpg"
        name="Saher Hamideh"
        title="Chief Compliance Officer and Secretary"
        href="https://www.linkedin.com/in/saher-hamideh-70a904201/"
      />
      <Card
        image="https://cdn.sanity.io/images/jpzshlka/production/51593e1d734b3404c69a0ec8dab87fe07c3ad482-197x197.png"
        name="Eric Levy"
        title="Vice President, Portfolio Manager"
        href="https://www.linkedin.com/in/eric-levy/"
      />
    </div>
  </Container>
)

const MinimalCardWrapper = styled.div`
  width: 280px;
  min-height: 115px;
  margin: 20px 0;
  padding: 20px;
  box-sizing: border-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 6px 6px 0px 0px;
  .name {
    text-transform: uppercase;
    font: normal normal 500 13px/28px var(--font-sans);
    letter-spacing: 0.78px;
    color: #202020;
  }
  .title {
    font: normal normal 400 10px/15px var(--font-sans);
    letter-spacing: 0px;
    color: #606060;
  }
  a {
    width: 51px;
    display: block;
  }
`;

const MinimalCard = ({
  name,
  title,
  href
}) => (
  <MinimalCardWrapper>
    <div className="name">{name}</div>
    <div className="title">{title}</div>
    <a href={href} target="_blank" rel="noopener noreferrer"><img src={LinkedIn} alt="linked-in" /></a>
  </MinimalCardWrapper>
)

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3.2,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2.75,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const ApartmentREIT = () => (
  <Container>
    <div>
      Although the Manager, RM Adviser, LLC, manages the day-to-day operations, the Apartment Growth REIT operates under the direction of its Board of Directors, a majority of whom are independent directors. Other than the limited stockholder voting rights described in our Offering Circular, the Board vets most other decisions relating to our assets and to the business of the Apartment Growth REIT, including certain decisions relating to acquisitions and dispositions, the engagement of asset managers, the issuance of securities in the Company including additional shares of our common stock, mergers, roll-up transactions, listing on a national securities exchange, and other decisions relating to our business, to our Board of Directors.
    </div>
    <div style={{ marginTop: '40px', paddingBottom: '20px' }}>
      <Carousel settings={settings}>
        <MinimalCard
          name="Jilliene Helman"
          title="Chief Executive Officer and Chairman of the Board"
          href="https://www.linkedin.com/in/jillienehelman/"
        />
        <MinimalCard
          name="Kevin Moclair"
          title="Chief Accounting Officer"
          href="https://www.linkedin.com/in/kevin-moclair-1894b610a/"
        />
        <MinimalCard
          name="Saher Hamideh"
          title="Chief Compliance Officer and Secretary"
          href="https://www.linkedin.com/in/saher-hamideh-70a904201/"
        />
        <MinimalCard
          name="Eric Levy"
          title="Vice President, Portfolio Manager"
          href="https://www.linkedin.com/in/eric-levy/"
        />
        <MinimalCard
          name="Flynann Janisse"
          title="Independent Director"
          href="https://www.linkedin.com/in/flynann-janisse-207b5480/"
        />
        <MinimalCard
          name="Louis S Weeks III"
          title="Independent Director"
          href="https://www.linkedin.com/in/louis-s-weeks-iii-059498a/"
        />
      </Carousel>
    </div>
  </Container>
);


export const Management = () => {
  return (
    <>
      <ReitTabs
        title="Management"
        background="#FFF"
        tabs={[
          {
            title: 'Income REIT',
            content: <IncomeREIT />,
          },
          {
            title: 'Apartment Growth REIT',
            content: <ApartmentREIT />,
          },
        ]}
      />
    </>
  );
};

export default Management;
