import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Heading,
  Body,
  Data,
} from '../../coreui';

import Form from '../../components/forms/Form';
import Label from '../../components/forms/Label';
import Input from '../../components/forms/Input';
import Actions from '../../components/forms/Actions';
import PrimaryButton from '../../components/forms/PrimaryButton';

class ChangeEmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      email: '',
      confirmEmail: '',
    };

    this.emailInput = null;
    this.confirmEmailInput = null;
    this.setEmailInputRef = (element) => {
      this.emailInput = element;
    };
    this.setConfirmEmailInputRef = (element) => {
      this.confirmEmailInput = element;
    };

    this.updateSubmittingStatus = this.updateSubmittingStatus.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleEmailValidation = this.handleEmailValidation.bind(this);
  }

  componentDidMount() {
    if (window && window.hyperform) {
      window.hyperform.addValidator(this.emailInput, this.handleEmailValidation);
      window.hyperform.addValidator(this.confirmEmailInput, this.handleEmailValidation);
    }
  }

  handleEmailValidation(element) {
    const {
      email,
      confirmEmail,
    } = this.state;
    const valid = email === confirmEmail &&
      email !== this.props.email;

    const message = (() => {
      if (this.emailInput.validity.typeMismatch) {
        return 'Please enter a valid email.';
      }
      if (this.emailInput.validity.valueMissing) {
        return 'This field is required.';
      }
      if (!valid) {
        if (email === this.props.email) {
          return 'Please enter a new email address.';
        }
        if (email !== confirmEmail) {
          return 'Please make sure the emails match.';
        }
      }
      return '';
    })();

    this.emailInput.setCustomValidity(message);

    if (element !== this.emailInput) {
      this.emailInput.checkValidity();
    }

    return valid;
  }

  updateSubmittingStatus(submitting) {
    this.setState({
      submitting,
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    if (!this.state.submitting) {
      this.updateSubmittingStatus(true);
      this.props.handleClearMessages();
      this.props.handleChangeEmail(this.state.email)
        .catch((err) => {
          this.props.handleErrorMessage(err);
        })
        .finally(() => {
          this.setState({
            submitting: false,
          });
        });
    }
  }

  render() {
    const {
      email,
    } = this.props;
    return (
      <>
        <ExtendedFlex
          flexDirection="column"
        >
          <Heading
            type="H6"
            textTransform="uppercase"
            mb={0}
          >
            Email
          </Heading>
          <Body
            type="B2"
          >
            Changes to your registered email address will require re-verification.
          </Body>
        </ExtendedFlex>
        <Form onSubmit={this.handleFormSubmit} >
          <ExtendedFlex
            flexDirection="column"
          >
            <Data
              type="D4"
            >
              Registered Email
            </Data>
            <Body
              type="B1"
              mt={0}
              color="gray.10"
            >
              {email}
            </Body>
          </ExtendedFlex>
          <Label
            htmlFor="email"
          >
            Enter new email
            <Input
              forwardedRef={this.setEmailInputRef}
              name="email"
              onChange={(e) => {
                this.setState({
                  email: (e.target.value || '').toLowerCase(),
                });
              }}
              type="email"
              value={this.state.email}
              placeholder="E-mail"
              autoComplete="email"
              required
              data-test="email"
            />
          </Label>
          <Label
            htmlFor="email"
          >
            Confirm new email
            <Input
              forwardedRef={this.setConfirmEmailInputRef}
              name="confirmEmail"
              onChange={(e) => {
                this.setState({
                  confirmEmail: (e.target.value || '').toLowerCase(),
                });
              }}
              type="email"
              value={this.state.confirmEmail}
              placeholder="E-mail"
              autoComplete="email"
              required
              data-test="confirm-email"
            />
          </Label>
          <Actions>
            <PrimaryButton
              buttonType="submit"
              loading={this.state.submitting}
              disable={this.state.submitting}
              data-test="sign-in-submit"
            >
              Verify New Email
            </PrimaryButton>
          </Actions>
        </Form>
      </>
    );
  }
}

ChangeEmailForm.propTypes = {
  email: PropTypes.string,
  handleChangeEmail: PropTypes.func,
  handleErrorMessage: PropTypes.func,
  handleClearMessages: PropTypes.func,
};

export default ChangeEmailForm;
