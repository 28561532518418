/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  InlineTextLink,
} from '../../coreui';

import types from './types';
import appWrapperTypes from '../../appWrapper/types';
import authTypes from '../../auth/types';

const initialState = {
  isLoggedIn: null,
  userId: null,
  firstName: 'Hello',
  accreditationRoleStatus: '',
  gtkyStatus: 'not_submitted',
  showWelcomeMessage: false,
  welcomeMessage: {
    title: '',
    subheader: 'Welcome to RealtyMogul',
    blurb: `
      Congratulations, you are now eligible to invest.
    `,
  },
  closeWelcomeMessage: null,
  loaded: false,
  unauthorized: false,
  browseNavAnimationSettings: {
    duration: '1s',
    delay: '0.25s',
  },
  retrying: false,
  retryCount: 0,
};

const phoneComponent = (
  <InlineTextLink
    color="white"
    href="tel:+16469218716"
  >
    (646) 921-8716
  </InlineTextLink>
);

const ClickToScheduleComponent = ({ gtkyContactLink }) => (
  <InlineTextLink
    color="white"
    href={gtkyContactLink}
    {...(!gtkyContactLink.startsWith('mailto:') ? {
      target: '_blank',
    } : {})}
  >
    click here to schedule a meeting
  </InlineTextLink>
);

ClickToScheduleComponent.propTypes = {
  gtkyContactLink: PropTypes.string,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case appWrapperTypes.GET_USER_DATA_REQUESTED: {
      return {
        ...state,
        isLoggedIn: null,
      };
    }
    case appWrapperTypes.GET_USER_DATA_SUCCEEDED: {
      const gtkyContactLink = action.user.gtkyContactLink || '';
      const firstName = action.user.firstName;
      const showGtkyMessage = action.user.accreditationRoleStatus === 'Accredited' && action.user.gtkyStatus === 'submitted';
      const welcomeMessage = {
        ...initialState.welcomeMessage,
        title: `${firstName},`,
        ...(showGtkyMessage ? {
          subheader: 'Thank you for submitting your information. There is one more step required for full accredited investor access.',
          blurb: (
            <>
              We like to get to know all of our investors prior to
              sharing the full suite of investment opportunities. A
              member of our Investor Relations team will be reaching out
              to you shortly. For immediate access to all investments now,
              please call us at {phoneComponent} (M-F 8am-6pm PST) or
              {' '}<ClickToScheduleComponent gtkyContactLink={gtkyContactLink} />.
              It only takes 2 minutes.
            </>
          ),
        } : {}),
      };
      return {
        ...state,
        isLoggedIn: action.user.isLoggedIn,
        ...(action.user.id ? {
          userId: action.user.id,
          accreditationRoleStatus: action.user.accreditationRoleStatus,
          gtkyStatus: action.user.gtkyStatus,
          showWelcomeMessage: action.user.showWelcomeMessage && showGtkyMessage,
          welcomeMessage,
          closeWelcomeMessageUrl: action.user.closeWelcomeMessage,
          loaded: true,
        } : {}),
      };
    }

    case types.GET_BROWSE_PAGE_DATA_FAILED: {
      const unauthorized = action.error.response.status === 401;

      return {
        ...state,
        unauthorized,
        retryCount: action.error.retry.count,
        retrying: action.error.retry.retry,
      };
    }

    case types.UPDATE_BROWSE_NAV_ANIMATION_SETTINGS:
      return {
        ...state,
        browseNavAnimationSettings: {
          duration: '0s',
          delay: '0s',
        },
      };

    case authTypes.USER_LOGOUT_REQUESTED: {
      return {
        ...state,
        ...initialState,
      };
    }

    default:
      return state;
  }
};
