import types from './types';

export default {
  getAdminMenuContentRequested: () => ({
    type: types.GET_ADMIN_MENU_REQUESTED,
  }),
  getAdminMenuContentFailed: (error) => ({
    type: types.GET_ADMIN_MENU_FAILED,
    error,
  }),
  getAdminMenuContentSucceeded: (content) => ({
    type: types.GET_ADMIN_MENU_SUCCEEDED,
    content,
  }),
};
