import appWrapperTypes from '../appWrapper/types';
import types from './types';

const initialState = {
  user: {
    isLoggedIn: false,
  },
  totalMembers: null,
  totalAmountFinanced: null,
  amountPaidOut: null,
  totalPropertyValueFinanced: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case appWrapperTypes.APP_INIT:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data.user,
        },
      };

    case appWrapperTypes.GET_USER_DATA_SUCCEEDED:
      return {
        ...state,
        user: action.user,
      };

    case types.GET_TOKENS_SUCCEEDED:
      return {
        ...state,
        totalMembers: action.tokens.totalMembers,
        totalAmountFinanced: action.tokens.totalAmountFinanced,
        amountPaidOut: action.tokens.amountPaidOut,
        totalPropertyValueFinanced: action.tokens.totalPropertyValueFinanced,
      };

    default:
      return {
        ...state,
      };
  }
};
