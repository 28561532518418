import numeral from 'numeral';
import {
  Formatters,
} from '../coreui';
import types from '../ioDetails/types';

const initialState = {
  io: {},
  variants: { privatePlacement: { v: '' } },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_IO_DATA_SUCCEEDED: {
      const location = action.data.data[0].address.locality +
        (action.data.data[0].address.administrative_area ? `, ${action.data.data[0].address.administrative_area}` : '');
      const progressBar = {};
      if (action.data.data[0].fundraisingTarget) {
        progressBar.target = parseInt(action.data.data[0].fundraisingTarget, 10);
      }
      if (action.data.data[0].statusValue === 'status-10-open'
        && action.data.data[0].fundsRaised
        && action.data.data[0].fundraisingTarget) {
        progressBar.percent = parseInt(action.data.data[0].fundsRaised, 10) /
          parseInt(action.data.data[0].fundraisingTarget, 10);
      }
      let images = [];
      if (action.data.data[0].images) {
        images = action.data.data[0].images.reduce((accum, current) => {
          accum.push({
            src: current[1].url,
            carousel: current[2].url,
            thumbnail: current[0].url,
            thumbnailWidth: current[0].width,
            thumbnailHeight: current[0].height,
          });
          return accum;
        }, []);
      }
      const ioInfo = action.data.data[0];
      if (action.data.data[0].acquisitionPrice && ioInfo.quickLook) {
        ioInfo.quickLook.push({
          label: 'Acquisition Price',
          value: `<p>${numeral(action.data.data[0].acquisitionPrice).format(Formatters.DOLLARS)}</p>`,
        });
      }

      const detailsInfo = [
        { label: 'Property Type', value: action.data.data[0].propertyType },
        { label: 'Investment Type', value: action.data.data[0].investmentType },
        { label: 'Estimated Hold Period', value: action.data.data[0].estimatedHoldPeriod },
        { label: 'Location', value: location },
      ];

      if (action.data.data[0].expectedFirstDistribution) {
        detailsInfo.push({ label: 'Estimated First Distribution', value: action.data.data[0].expectedFirstDistribution });
      }
      detailsInfo.push({ label: 'Minimum Investment', value: numeral(action.data.data[0].minimumInvestment).format(Formatters.DOLLARS) });

      return {
        ...state,
        io: ioInfo,
        gallery: images,
        details: detailsInfo,
      };
    }
    case types.GET_IO_DATA_FAILED:
      return {
        ...state,
      };
    case types.GET_IO_FAQ_DATA_SUCCEEDED:
      return {
        ...state,
        faqItems: action.data.data.map((item) => {
          return ({
            header: item.header,
            content: item.content,
            id: item.id,
          });
        }),
      };
    case types.UNSET_IO_STATE:
      return {
        ...state,
        io: {},
        gallery: null,
        details: null,
        faqItems: null,
      };
    default:
      return {
        ...state,
      };
  }
};
