import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedFlex,
  AnimationContainer,
} from '../../..';

class ArticleCardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      children,
      zIndex,
      fadeIndex,
      height,
      noAnimate,
    } = this.props;

    return (
      <AnimationContainer
        name="fadeIn"
        duration="1s"
        delay={`${fadeIndex / 5}s`}
        fillMode="backwards"
        noAnimate={noAnimate}
      >
        <ExtendedFlex
          data-test="article-card"
          w={[315, 315, 315, 400, 525]}
          height={height}
          position="relative"
          p={10}
          mx={4}
          mt={30}
          bg="white"
          flexDirection="column"
          transition="box-shadow 0.3s linear"
          hoverShadow
          hoverRemoveFilter
          zIndex={zIndex}
        >
          { children }
        </ExtendedFlex>
      </AnimationContainer>
    );
  }
}

ArticleCardContainer.propTypes = {
  children: PropTypes.any,
  fadeIndex: PropTypes.number,
  zIndex: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
  ]),
  noAnimate: PropTypes.bool,
};

ArticleCardContainer.defaultProps = {
  noAnimate: false,
};

export default ArticleCardContainer;
