/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
} from '../../coreui';

import BrowseLink from '../../components/BrowseLink';

class FundProperty extends React.Component {
  // eslint-disable-next-line
  showZendesk() {
    if (typeof zE !== 'undefined') {
      // eslint-disable-next-line
      zE.activate();
    }
  }

  render() {
    const {
      isLoggedIn,
      ...additionalProps
    } = this.props;

    return (
      <ExtendedFlex
        w={1}
        align="center"
        justify="center"
        flexDirection="column"
        py="10%"
        {...additionalProps}
      >
        <Heading
          type="H2"
          color="black"
          textAlign="center"
          m={0}
        >
          FUND YOUR NEXT 1031 PROPERTY
        </Heading>
        <ExtendedBox>
          <BrowseLink
            anonText="Get Started"
            isLoggedIn={isLoggedIn}
          />
        </ExtendedBox>
      </ExtendedFlex>
    );
  }
}

FundProperty.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default FundProperty;
