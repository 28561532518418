import React from 'react';
import PropTypes from 'prop-types';

import {
  Body,
  Box,
  ExtendedBox,
  ExtendedFlex,
  Formatters,
  Heading,
  Link,
} from '../../coreui';

import SummaryItem from './SummaryItem';
import LoadableHighcharts from '../../components/LoadableHighcharts';

const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return `${date.getMonth() + 1}/${date.getFullYear()}`;
};

const generatePieData = (activeData) => {
  if (!activeData) return [];
  const pieData = [];
  activeData.reitData?.reits?.map((reit) => {
    if (reit.overview.currentTotalInvested.value > 0) {
      pieData.push({
        name: reit.nid === 193744 ? 'Income REIT' : 'Apt. REIT',
        color: reit.nid === 193744 ? '#0C4779' : '#A4C2D5',
        y: reit.overview.currentTotalInvested.value / activeData.totalInvested.value,
      });
    }
    return reit;
  });
  if (activeData.privatePlacementData?.summary?.items?.portfolioAllocation?.value > 0) {
    pieData.push({
      name: 'Private Placements',
      color: '#176DB6',
      y: activeData.privatePlacementData.summary.items.portfolioAllocation.value,
    });
  }
  return pieData;
};

const countInvestments = (investmentData) => {
  if (!investmentData) return 0;
  let count = investmentData.privatePlacementData?.ios?.length;
  investmentData.reitData?.reits?.map((reit) => {
    if (reit.currentInvestmentValue > 0) {
      count += 1;
    }
  });
  return count;
};

const generateBarOptions = (data, pendingData) => {
  if (!data || !pendingData) return {};
  const barOptions = {
    chart: {
      type: 'column',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: 'Private Placement Investment Summary',
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: '# of Investments',
      },
      minTickInterval: 1,
    },
    legend: {
      enabled: false,
    },
  };

  const numActive = countInvestments(data.activeInvestmentsData);
  const numCompleted = countInvestments(data.completedInvestmentsData);
  const numPending = pendingData.length;
  const countArray = [];

  if (numActive > 0) {
    barOptions.xAxis.categories.push('Active');
    countArray.push({
      y: numActive,
      color: '#176DB6',
    });
  }
  if (numCompleted > 0) {
    barOptions.xAxis.categories.push('Completed');
    countArray.push({
      y: numCompleted,
      color: '#A4C2D5',
    });
  }
  if (numPending > 0) {
    barOptions.xAxis.categories.push('Pending');
    countArray.push({
      y: numPending,
      color: '#0C4779',
    });
  }

  barOptions.series = [{
    name: '# of Investments',
    data: countArray,
    cursor: 'pointer',
    id: 'numOfInvestments',
  }];

  return barOptions;
};

const DashboardSummary = ({ data, user, pendingData, isApp, frontendPrefix }) => {
  const pieOptions = {
    chart: {
      type: 'pie',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: 'Portfolio Allocation',
    },
    series: [{
      name: '% of Portfolio',
      data: generatePieData(data.activeInvestmentsData),
      cursor: 'auto',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f}%',
      },
      size: '70%',
      innerSize: '60%',
      id: 'investmentType',
      states: {
        hover: {
          enabled: false,
        },
      },
    }],
    tooltip: {
      enabled: false,
    },
  };

  const barOptions = generateBarOptions(data, pendingData);

  return (
    <ExtendedFlex
      flexDirection="column"
      w={1}
      maxWidth={1540}
      px={[20, 50]}
      pt={24}
      pb={0}
    >
      {user.createdDate &&
      <ExtendedFlex
        flexDirection={['column', 'column', 'column', 'row', 'row']}
        w={1}
        pt={24}
        justifyContent="space-between"
        alignItems="center"
      >
        <ExtendedFlex
          flexDirection="column"
          alignItems={['center', 'center', 'center', 'flex-start', 'flex-start']}
          maxWidth={['100%', '100%', '100%', '45%', '50%']}
        >
          <div style={{ marginBottom: '4px' }}>
            <Heading my={12} mr={15} type="H2" textTransform="capitalize">Welcome, {user.firstName}!</Heading>
          </div>
          <Heading type="H4" fontStyle="italic" fontSize="16px">{user.accreditationRoleStatus} member since: {formatDate(user.createdDate)}</Heading>
        </ExtendedFlex>
        <ExtendedFlex
          flexDirection={['column', 'column', 'column', 'row', 'row']}
          justifyContent="flex-end"
          alignItems="center"
          width={1}
          maxWidth={['100%', '100%', '100%', '50%', '65%']}
          mt={[24, 24, 24, 0, 0]}
        >
          {
            data?.activeInvestmentsData.totalInvested !== undefined && data?.activeInvestmentsData?.totalInvested?.value !== 0 &&
            <SummaryItem
              value={data.activeInvestmentsData.totalInvested.value}
              label="Total Invested - Active"
              valueFormat={Formatters.DOLLARS_DECIMALS}
              tooltipText={data.activeInvestmentsData.totalInvested.tooltip}
            />
          }
          <Box m={8} mx={[0, 0, 0, 16, 24]} />
          {
            data?.activeInvestmentsData.totalDistributions !== undefined && data?.activeInvestmentsData?.totalInvested?.value !== 0 &&
            <SummaryItem
              value={data.activeInvestmentsData.totalDistributions.value}
              label="Total Distributed - Active"
              valueFormat={Formatters.DOLLARS_DECIMALS}
              tooltipText={data.activeInvestmentsData.totalDistributions.tooltip}
              marginTop={[24, 24, 24, 0, 0]}
            />
          }
        </ExtendedFlex>
      </ExtendedFlex>}
      {data?.activeInvestmentsData.totalInvested &&
      <ExtendedFlex
        flexDirection={['column', 'column', 'column', 'column', 'row', 'row']}
        maxWidth={['100%', 1540]}
        w={1}
        alignItems={['center', 'center', 'flex-start', 'flex-start', 'flex-start']}
        pt={[24, 48]}
      >
        <ExtendedFlex
          flexDirection={['column', 'column', 'column', 'row', 'row']}
          justifyContent="space-between"
          width={1}
        >
          {
              data?.activeInvestmentsData?.totalInvested?.value !== 0 ?
                <>
                  <ExtendedBox
                    w={['100%', '100%', '100%', '50%', '50%']}
                    pt={[24, 0]}
                    boxSizing="border-box"
                  >
                    <LoadableHighcharts options={pieOptions} />
                    <Body type="B3">Portfolio Allocation based on amount invested in Growth REIT, Income REIT, and Private Placements as a percent of all amounts invested.</Body>
                  </ExtendedBox>
                  <ExtendedBox
                    w={['100%', '100%', '100%', '50%', '50%']}
                    mt={[36, 0]}
                    boxSizing="border-box"
                  >
                    {
                      data.activeInvestmentsData?.privatePlacementData ?
                        <LoadableHighcharts options={barOptions} /> :
                        <ExtendedFlex
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                          height="100%"
                        >
                          <Heading type="H4" lineHeight={1.5} mb={24}>You currently have no private placement investments.</Heading>
                          <Link
                            type="L1"
                            {...(isApp ? {
                              href: `${frontendPrefix}/investment-opportunities`,
                            } : {
                              to: '/investment-opportunities',
                            })}
                          >
                            Browse Open Investments
                          </Link>
                        </ExtendedFlex>
                    }
                  </ExtendedBox>
                </> :
                <ExtendedFlex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                  my={48}
                >
                  <Heading type="H4" mb={24}>You currently have no active investments.</Heading>
                  <Link
                    type="L1"
                    {...(isApp ? {
                      href: `${frontendPrefix}/investment-opportunities`,
                    } : {
                      to: '/investment-opportunities',
                    })}
                  >
                    Browse Open Investments
                  </Link>
                </ExtendedFlex>
            }
        </ExtendedFlex>
      </ExtendedFlex>}
    </ExtendedFlex>
  );
};

DashboardSummary.propTypes = {
  data: PropTypes.object,
  pendingData: PropTypes.array,
  user: PropTypes.object,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

export default DashboardSummary;
