import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withPortal from '../../withPortal';

import appActions from '../../appWrapper/actions';

import Messages from '../../dsm/components/Messages';
import ChangeEmailForm from './ChangeEmailForm';
import VerifyEmailForm from './VerifyEmailForm';

const formRoot = typeof document !== 'undefined' ? document.getElementById('email-profile-form') : null;

const errorMessage = (err) => {
  if (typeof err === 'string') { return err; }
  if (err.response && err.response.data && err.response.data.title) {
    return err.response.data.title;
  }
  return err.message ? err.message : JSON.stringify(err);
};

class ChangeEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
    };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleShowMessage = this.handleShowMessage.bind(this);
    this.handleClearMessages = this.handleClearMessages.bind(this);
    this.handleEmailVerified = this.handleEmailVerified.bind(this);
    this.handleErrorMessage = this.handleErrorMessage.bind(this);
    this.handleStatusMessage = this.handleStatusMessage.bind(this);
  }

  handleChangeEmail(email) {
    return this.props.setRequestedEmail({ email });
  }

  handleShowMessage(message) {
    this.setState({
      messages: [
        message,
      ],
    });
  }

  handleClearMessages() {
    this.setState({
      messages: [],
    });
  }

  handleStatusMessage(message) {
    this.handleShowMessage({
      message,
      type: 'status',
    });
  }

  handleErrorMessage(error) {
    this.handleShowMessage({
      message: errorMessage(error),
      type: 'error',
    });
  }

  handleEmailVerified() {
    return this.props.getUserDataRequested()
      .then(() => {
        this.handleShowMessage({
          message: 'Changes saved',
          type: 'status',
        });
      })
      .catch(() => {

      });
  }

  render() {
    const {
      email,
      emailVerified,
    } = this.props;

    return (
      <>
        <Messages
          messages={this.state.messages}
        />
        {
          (!emailVerified &&
            <VerifyEmailForm
              email={email}
              handleClearMessages={this.handleClearMessages}
              handleChangeEmail={this.handleChangeEmail}
              handleEmailVerified={this.handleEmailVerified}
              handleErrorMessage={this.handleErrorMessage}
              handleStatusMessage={this.handleStatusMessage}
            />
          ) ||
          <ChangeEmailForm
            email={email}
            handleClearMessages={this.handleClearMessages}
            handleChangeEmail={this.handleChangeEmail}
            handleEmailVerified={this.handleEmailVerified}
            handleErrorMessage={this.handleErrorMessage}
            handleStatusMessage={this.handleStatusMessage}
          />
        }
      </>
    );
  }
}

ChangeEmail.propTypes = {
  email: PropTypes.string,
  emailVerified: PropTypes.bool,
  setRequestedEmail: PropTypes.func,
  getUserDataRequested: PropTypes.func,
};

const mapStateToProps = (state) => ({
  email: state.app.user.email,
  emailVerified: state.app.user.emailVerified,
});

const mapDispatchToProps = (dispatch) => ({
  setRequestedEmail: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(appActions.setUserDataRequested({
        data,
        resolve,
        reject,
      }));
    });
  },
  getUserDataRequested: () => {
    return new Promise((resolve, reject) => {
      dispatch(appActions.getUserDataRequested({
        resolve,
        reject,
      }));
    });
  },
});

export default withPortal(
  connect(mapStateToProps, mapDispatchToProps)(ChangeEmail),
  formRoot,
);
