/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import uniqueId from 'lodash/uniqueId';
import styled from 'styled-components';

import {
  themeGet,
  color,
  fontFamily,
} from 'styled-system';

import InputLabel from '@material-ui/core/InputLabel';
import FilledInput from '@material-ui/core/FilledInput';
import MUISelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import {
  Data,
} from '../../coreui';

const StyledFormControl = styled(({
  zebra,
  ...props
}) => <FormControl {...props} />)`
  ${(props) => ({
    background: props.zebra === 'odd'
      ? themeGet('colors.gray.0')(props)
      : themeGet('colors.white')(props),
  })}
`;

export const StyledSelect = styled(({
  zebra,
  textTransform,
  ...props
}) => <MUISelect {...props} />)`
  && {
    ${fontFamily}
    text-transform: ${(props) => (props.textTransform || 'none')};
    border-radius: 0;
    svg {
      font-size: 32px;
    }
  }

  &&:after {
    border-bottom: 0 none;
  }
`;

export const StyledInputLabel = styled(({
  textTransform,
  ...props
}) => <InputLabel {...props} />)`
  &&& {
    ${fontFamily}
    ${color}
    text-transform: ${(props) => (props.textTransform || 'none')};
    font-size: .75rem;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      font-size: 1rem;
    }
  }
`;

export const StyledFilledInput = styled(({
  zebra,
  ...props
}) => <FilledInput {...props} />)`
  ${fontFamily}
  text-transform: ${(props) => (props.textTransform || 'none')};
  && {
    .hf-warning {
      color: red;
      font-size: .75rem;
      padding: 12px;
      @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
        font-size: 1rem;
      }
    }
  }

  ${(props) => ({
    '&&': {
      background: props.zebra === 'odd'
        ? themeGet('colors.gray.0')(props)
        : themeGet('colors.white')(props),
    },
  })}

  &&:before {
    border-bottom: 0 none;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    ${fontFamily}
  }
`;

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showValidationMessage: undefined,
    };

    this.inputRef = null;
    this.setElementRef = (element) => { this.inputRef = element; };

    this.handleOnInvalid = this.handleOnInvalid.bind(this);
  }

  handleOnInvalid(evt) {
    evt.preventDefault();
    this.setState({
      showValidationMessage: true,
    });
  }

  render() {
    const {
      name,
      zebra,
      placeholder,
      required,
      options,
      isNative,
      showEmpty,
      ...props
    } = this.props;
    const {
      showValidationMessage,
    } = this.state;

    const idUnique = uniqueId(`select-${name}`);

    const validationMessage = showValidationMessage && isNative
      && !this.inputRef.validity.valid && this.inputRef.validationMessage;
    return (
      <>
        <StyledFormControl variant="filled" zebra={zebra}>
          <StyledInputLabel
            htmlFor={idUnique}
            fontFamily="sansSerif"
            color="black"
            textTransform="uppercase"
          >
            {placeholder}
          </StyledInputLabel>
          <StyledSelect
            input={(
              <StyledFilledInput
                name={name}
                id={idUnique}
                fontFamily="sansSerif"
                zebra={zebra}
              />
            )}
            native={isNative}
            inputProps={{
              required,
              ref: this.setElementRef,
              ...(isNative ? {
                onInvalid: this.handleOnInvalid,
              } : {}),
              ...props.inputProps,
            }}
            required={required}
            fontFamily="sansSerif"
            textTransform="uppercase"
            {...props}
          >
            {
              placeholder && !isNative && showEmpty &&
                <StyledMenuItem
                  value=""
                  fontFamily="sansSerif"
                >
                  {placeholder}
                </StyledMenuItem>
            }
            {
              placeholder && isNative && showEmpty &&
              // eslint-disable-next-line
              <option
                value=""
              />
            }
            {
              options.map((option) => (
                !isNative
                  ?
                    <StyledMenuItem
                      value={option.value}
                      key={option.value}
                      fontFamily="sansSerif"
                    >
                      {option.label}
                    </StyledMenuItem>
                  :
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
              ))
            }
          </StyledSelect>
        </StyledFormControl>
        {
          validationMessage &&
          <Data
            type="D4"
            display="block"
            my={2}
            color="red"
          >
            {validationMessage}
          </Data>
        }
      </>
    );
  }
}

Select.defaultProps = {
  options: [],
  showEmpty: true,
  name: '',
  placeholder: '',
  required: false,
  zebra: 'odd',
  isNative: true,
};

export default Select;
