import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  MiniEssay,
  Body,
  Data,
  Theme,
} from '../coreui';

import VisibilityTrigger from '../components/VisibilityTrigger';
import DynamicBrowseJoinLink from '../helpers';
import FadeIn from '../components/FadeIn';
import { PassiveVsActiveData } from './_contentData';

const PassiveVsActive = ({ isLoggedIn }) => {
  return (
    <ExtendedFlex
      flexDirection="column"
      align="center"
      mx="auto"
      my={[50, 50, 100]}
      maxWidth={1500}
    >
      <VisibilityTrigger
        trigger="isVisible"
      >
        <MiniEssay
          header={PassiveVsActiveData.heading}
          paragraphsOfText={PassiveVsActiveData.subHeading}
        />
      </VisibilityTrigger>
      <ExtendedFlex
        flexDirection={['column', 'column', 'row', 'row', 'row']}
        mt={[10, 20, 40, 50, 50]}
      >
        <FadeIn>
          <ExtendedFlex
            flexDirection="column"
            justify="space-around"
            maxWidth={680}
            w={1}
            mb={0}
            align="center"
            transition={`opacity ${Theme.fadeInDelay}ms 0s linear`}
          >
            <Data
              type="D5"
              color="#9b9b9b"
            >
              {PassiveVsActiveData.notOffered.heading}
            </Data>
            <ExtendedFlex
              w="97%"
              bg="#202020"
              align="center"
            >
              <Data
                type="D4"
                mx="auto"
                color="#FFFFFF"
                textAlign="center"
                my={3}
              >
                {PassiveVsActiveData.notOffered.label}
              </Data>
            </ExtendedFlex>
            <ExtendedFlex
              flexDirection={['row', 'row', 'row', 'row', 'row']}
              justify="space-around"
              width={[1, 1, 1, 1, 1]}
              mb={0}
            >
              {
                PassiveVsActiveData.notOffered.paragraphs.map((text) => (
                  <ExtendedFlex
                    bg="gray.0"
                    m={10}
                    height={[105, 75, 125, 100, 135]}
                    maxWidth={340}
                  >
                    <Body
                      type="B3"
                      textAlign="center"
                      m={0}
                      px="5%"
                      py="5%"
                    >
                      {text}
                    </Body>
                  </ExtendedFlex>
                ))
              }
            </ExtendedFlex>
          </ExtendedFlex>
        </FadeIn>
        <FadeIn>
          <ExtendedFlex
            flexDirection="column"
            justify="space-around"
            maxWidth={680}
            w={1}
            mb={0}
            align="center"
            transition={`opacity ${Theme.fadeInDelay}ms 0.3s linear`}
          >
            <Data type="D5">
              {PassiveVsActiveData.offered.heading}
            </Data>
            <ExtendedFlex
              w="97%"
              bg="#176DB6"
            >
              <Data
                type="D4"
                mx="auto"
                color="#FFFFFF"
                textAlign="center"
                my={3}
              >
                {PassiveVsActiveData.offered.label}
              </Data>
            </ExtendedFlex>
            <ExtendedFlex
              flexDirection={['row', 'row', 'row', 'row', 'row']}
              justify="space-around"
              width={[1, 1, 1, 1, 1]}
              mb={0}
            >
              {
                PassiveVsActiveData.offered.paragraphs.map((text) => (
                  <ExtendedFlex
                    bg="gray.0"
                    m={10}
                    height={[105, 75, 125, 100, 135]}
                    maxWidth={340}
                  >
                    <Body
                      type="B3"
                      textAlign="center"
                      m={0}
                      px="5%"
                      py="5%"
                    >
                      {text}
                    </Body>
                  </ExtendedFlex>
                ))
              }
            </ExtendedFlex>
          </ExtendedFlex>
        </FadeIn>
      </ExtendedFlex>
      <ExtendedFlex
        pt={60}
        flexDirection="column"
        align="center"
      >
        <DynamicBrowseJoinLink
          type="L1"
          isLoggedIn={isLoggedIn}
        />
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

PassiveVsActive.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default PassiveVsActive;
