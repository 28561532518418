import get from 'lodash/get';
import types from './types';

const initialState = {
  loaded: undefined,
  ioOptions: [],
  workflows: [],
  daysPriorEndOfQuarter: undefined,
  daysProcessedEndOfQuarter: undefined,
  repurchaseRequestErrors: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHARE_REPURCHASE_LIST_API_SUCCEEDED: {
      const workflows = action?.data?._meta?.workflows || [];

      return {
        ...state,
        loaded: true,
        workflows,
        daysPriorEndOfQuarter:
          action?.data?._meta?.constants?.DAYS_PRIOR_END_OF_QUARTER,
        daysProcessedEndOfQuarter:
          action?.data?._meta?.constants?.DAYS_PROCESSED_END_OF_QUARTER,
      };
    }
    case types.SHARE_REPURCHASE_POST_API_REQUESTED: {
      return {
        ...state,
        repurchaseRequestErrors: undefined,
      };
    }
    case types.SHARE_REPURCHASE_POST_API_FAILED: {
      return {
        ...state,
        repurchaseRequestErrors: get(action.error, 'response.data'),
      };
    }

    default:
      return {
        ...state,
      };
  }
};
