export default {
  GET_KNOWLEDGE_CENTER_DATA_REQUESTED: 'GET_KNOWLEDGE_CENTER_DATA_REQUESTED',
  GET_KNOWLEDGE_CENTER_DATA_SUCCEEDED: 'GET_KNOWLEDGE_CENTER_DATA_SUCCEEDED',
  GET_KNOWLEDGE_CENTER_DATA_FAILED: 'GET_KNOWLEDGE_CENTER_DATA_FAILED',
  UPDATE_KNOWLEDGE_CENTER_FILTER_REQUESTED: 'UPDATE_KNOWLEDGE_CENTER_FILTER_REQUESTED',
  UPDATE_KNOWLEDGE_CENTER_FILTER_SUCCEEDED: 'UPDATE_KNOWLEDGE_CENTER_FILTER_SUCCEEDED',
  UPDATE_KNOWLEDGE_CENTER_FILTER_FAILED: 'UPDATE_KNOWLEDGE_CENTER_FILTER_FAILED',
  GET_MORE_ARTICLES_REQUESTED: 'GET_MORE_ARTICLES_REQUESTED',
  GET_MORE_ARTICLES_SUCCEEDED: 'GET_MORE_ARTICLES_SUCCEEDED',
  GET_MORE_ARTICLES_FAILED: 'GET_MORE_ARTICLES_FAILED',
  GET_ARTICLE_QUEUE_REQUESTED: 'GET_ARTICLE_QUEUE_REQUESTED',
  GET_ARTICLE_QUEUE_SUCCEEDED: 'GET_ARTICLE_QUEUE_SUCCEEDED',
  GET_ARTICLE_QUEUE_FAILED: 'GET_ARTICLE_QUEUE_FAILED',
};
