/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
} from '../coreui';

const NotificationWrapper = ({
  children,
  ...props
}) => {
  return (
    <ExtendedFlex
      w={1}
      bg="blue.1"
      align="center"
      justify="center"
      {...props}
    >
      {children}
    </ExtendedFlex>
  );
};

NotificationWrapper.propTypes = {
  children: PropTypes.object,
};

export default NotificationWrapper;
