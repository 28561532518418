/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReitTabs, Button } from 'rtmg-component-library';
import NavLeft from '../../assets/nav-left.png';
import Nav from '../../assets/nav.png';
import ADRLeft from '../../assets/adr-left.png';
import ADR from '../../assets/adr.png';

const Container = styled.div`
  height: 100%;
  overflow: auto;
  position: relative;
  display: flex;
  flex: 1;
`;

const Scroll = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: ${({reverse}) => reverse ? 'row-reverse' : 'row'};
  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    background-size: cover;
    background: red;
    border-radius: 100%;
    border: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 150ms;
  }
`;

const Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: linear-gradient(to left, transparent 98%, white);
  pointer-events: none;
`;

const Tab = ({ leftImg, mainImg, isSecond = false }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ width: '40px', height: '314px' }}>
        <img src={leftImg} alt="nav-left" style={{ width: '100%' }} />
      </div>
      <div style={{ width : '20px' }} />
      <Container>
        <Scroll reverse={!isSecond}>
          <img src={mainImg} alt="nav" style={{ height: '340px' }} />
        </Scroll>
        <Gradient />
      </Container>
    </div>
  );
};

Tab.propTypes = {
  leftImg: PropTypes.string,
  mainImg: PropTypes.string,
};

const Content = {
  title: 'Historical Performance',
  background: '#FFF',
  tabs: [
    {
      title: 'NET ASSET VALUE',
      content: (
        <Tab leftImg={NavLeft} mainImg={Nav} />
      ),
    },
    {
      title: 'ANNUAL DISTRIBUTION RATE',
      content: (
        <Tab leftImg={ADRLeft} mainImg={ADR} isSecond={true} />
      ),
    },
  ],
};

const LabelSection = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Label = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  .title {
    display: inline-block;
    color: ${({ color }) => color};
    font: normal normal 500 14px/23px var(--font-sans);
    letter-spacing: 0px;
  }
  .label {
    font: normal normal 400 11px/12px var(--font-sans);
    letter-spacing: 0px;
    text-align: center;
    color: #606060;
  }
  .circle {
    display: inline-block;
    box-sizing: border-box;
    width: 10px;
    height: 10px;
    border: ${({ color }) => (color ? `2px solid ${color}` : '')};
    border-radius: 50%;
    margin-right: 8px;
  }
`;

const ButtonSection = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ButtonWrapper = styled.div`
  width: calc(100% - 40px);
  margin: 0 auto;
  &:nth-child(1) {
    margin-bottom: 20px;
  }
  button, a {
    font: normal normal 400 10px/12px var(--font-sans);
    letter-spacing: 2.4px;
    color: #FFFFFF;
  }
  @media (min-width: 768px) {
    max-width: 400px;
    margin: unset;
    &:nth-child(1) {
      margin-right: 30px;
    }
  }
`;

const Line = styled.div`
  letter-spacing: 0;
  margin: 0 auto;
  width: calc(100% - 60px);
  .title {
    font: normal normal 500 9px/12px var(--font-sans);
    color: #606060;
  }
  .copy {
    font: normal normal 400 9px/12px var(--font-sans);
    color: #9B9B9B;
    margin-left: 5px;
  }
  @media (min-width: 768px) {
    font-size: 10px;
    line-height: 14px;
`;

export const HistoricalPerformance = ({ mr1Button, mr2Button }) => (
  <>
    <ReitTabs {...Content} />
    <LabelSection>
      <Label color="#5F91BC">
        <div style={{ marginBottom: '1px' }}>
          <div className="circle" />
          <div className="title">The Income REIT</div>
        </div>
        <div className="label">Inception: Sep 2015</div>
      </Label>
      <Label color="#FAC12D">
        <div style={{ marginBottom: '1px' }}>
          <div className="circle" />
          <div className="title">The Apartment Growth REIT</div>
        </div>
        <div className="label">Inception: Sep 2016</div>
      </Label>
    </LabelSection>
    <ButtonSection>
      <ButtonWrapper>
        <Button href={mr1Button.button_href}>INVEST IN THE INCOME REIT</Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <Button href={mr2Button.button_href}>INVEST IN THE APARTMENT GROWTH REIT</Button>
      </ButtonWrapper>
    </ButtonSection>
    <div style={{ textAlign: 'center', paddingBottom: '40px' }}>
      <Line>
        <span className="title">INCOME REIT</span>
        <span className="copy">December 2021 annualized distribution rate includes a one-time special distribution of $0.115 per share at an annualized rate of 12.8%.</span>
      </Line>
      <Line>
        <span className="title">GROWTH REIT</span>
        <span className="copy">4Q21 annualized distribution rate includes a one-time special distribution of $0.802 per share at an annualized rate of 30.7%.
        </span>
      </Line>
    </div>
  </>
);

export default HistoricalPerformance;
