import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Flex,
  MiniEssay,
  Heading,
  Data,
  VisibilitySensor,
  Theme,
} from '../coreui';

// Creates a single triplet "info block". Meant to run as a .map over a three-long array.
// INPUT1: obj, two info keys (`eyegrabbingText`, `descriptiveText`)
// INPUT2: num, index of array being .map-ed [First triplet has no borders]
// INPUT3: bool, should the content be visible or invisible (for animation)
// INPUT4: num, base delay before any of the triplets begin to fade in
// OUTPUT: JSX, triplet meant to fit three side-by-side or stacks in smaller windows
const createTriplet = (info, index, isVisible, baseDelay) => {
  // FIXME -- styled-system has a conflict if you use borderColor and responsive border together.
  // No easy workaround now, so this is an expedient hack.
  // We're hardcoding the color into the responsive border shorthand.
  // The right way /should/ be setting borderColor, but that will not work now.
  // There is an issue on the `styled-system` repo here:
  //   https://github.com/jxnblk/styled-system/issues/133
  // Color is `gray.10` from theme -- #9b9b9b -- as of May 2, 2018
  const borderHack = '1px solid #9b9b9b';

  // Delays are num, not string. We use format string later.
  // Borders get baseDelay as when they fade in, content starts to fade in after borders.
  const additionalDelay = 0.3 * (index + 1); // (index + 1) is so content is after borders
  const totalDelay = additionalDelay + baseDelay;

  // Note: Wondering why the borderTop and borderLeft props are in separate places?
  // Because styled-system bugs out if multiple border types on single component.
  return (
    <ExtendedBox
      key={index}
      boxSizing="border-box"
      width={[0.65, 540, 1 / 3]}
      py={[20, 20, 0]}
      borderTop={index === 0 ? 'none' : [borderHack, borderHack, 'none']}
      opacity={isVisible ? '1' : '0'}
      transition={`opacity ${Theme.fadeInDelay}ms ${baseDelay}s linear`}
    >
      <ExtendedFlex
        flexDirection="column"
        align="center"
        justify="center"
        width={1}
        borderLeft={index === 0 ? 'none' : ['none', 'none', borderHack]}
      >
        <Heading
          type="H2"
          color="blue.1"
          m={0}
          opacity={isVisible ? '1' : '0'}
          transition={`opacity ${Theme.fadeInDelay}ms ${totalDelay}s linear`}
        >
          {info.highlight}
        </Heading>
        <Data
          type="D1"
          display="block"
          textAlign="center"
          maxWidth={[null, null, 120, 275, 275]}
          opacity={isVisible ? '1' : '0'}
          transition={`opacity ${Theme.fadeInDelay}ms ${totalDelay}s linear`}
        >
          {info.description}
        </Data>
      </ExtendedFlex>
    </ExtendedBox>
  );
};

class Highlighted3ColCallout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible: true,
      });
    }
  }

  render() {
    const {
      isVisible,
    } = this.state;

    const {
      title,
      subTitle,
      cta,
      highlights,
      ...additionalProps
    } = this.props;

    return (
      <ExtendedBox
        // eslint-disable-next-line
        {...additionalProps}
      >
        <Flex
          flexDirection="column"
          align="center"
          justify="center"
        >
          <VisibilitySensor
            callback={this.handleSensorChange}
            offset={{ bottom: 150 }}
          />
          <MiniEssay
            header={title}
            paragraphsOfText={[subTitle]}
            animatedFadeIn
            isVisible={isVisible}
          />
          <Flex
            flexDirection={['column', 'column', 'row', 'row', 'row']}
            align="center"
            justify="center"
            width={[1, 1, 600, 840, 1160]}
            mt={[10, 20, 40, 50, 80]}
            mb={[20, 20, 50, 60, 100]}
          >
            {
              highlights.map((info, index) => {
                if (info.highlight) {
                  return createTriplet(
                    info,
                    index,
                    isVisible,
                    0.6,
                  );
                }
                return null;
              })
            }
          </Flex>
          {
            cta &&
            <ExtendedBox
              opacity={isVisible ? '1' : '0'}
              transition={`opacity ${Theme.fadeInDelay}ms 1.8s linear`}
            >
              {cta}
            </ExtendedBox>
          }
        </Flex>
        <VisibilitySensor
          callback={this.handleSensorChange}
        />
      </ExtendedBox>
    );
  }
}

Highlighted3ColCallout.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  highlights: PropTypes.array,
  cta: PropTypes.any,
};

export default Highlighted3ColCallout;
