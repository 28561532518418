/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'qs';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BrowseFilter, InvestmentTile, Button } from 'rtmg-component-library';
import _ from 'lodash';

import {
  ExtendedBox,
  ExtendedFlex,
  Link,
} from '../../coreui';

import Loading from '../../auth/components/Loading';
import AuthOverlay from '../components/AuthOverlay/AuthOverlay';

import actions from './actions';
import browseActions from '../browsePage/actions';
import MRIimage from '../../assets/mr1_nobackground.jpg';
import MRIIimage from '../../assets/mr2_nobackground.jpg';

const InvestmentsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  @media (min-width: 1050px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1410px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1770px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const TileContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 0 70px;
  box-sizing: border-box;
  @media (min-width: 768px) {
    padding: 0 20px 70px;
  }
`;

const HeaderText = styled.h2`
  width: 100%;
  box-sizing: border-box;
  padding-top: ${(props) => props.top ? '0' : '25px'};
  margin-top: ${(props) => props.top ? '0' : '0.83em'};
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 30px;
  border-top: ${(props) => props.top ? 'none' : '1px solid #202020'};
  font-family: var(--font-sans);
  font-size: 20px;
  letter-spacing: 3.6px;
  font-weight: 400;
  @media (max-width: 768px) {
    font: normal normal 600 14px/27px var(--font-sans);
    letter-spacing: 2.52px;
    color: #202020;
    border-top: ${(props) => props.top ? '1px solid #A4C2D5' : '1px solid #202020'};
    margin: 0;
    padding: 15px 25px;
  }
`;

const PastInvestmentsContainer = styled.div`
  width: 100%;
  padding: 25px 25px 0;
  box-sizing: border-box;
  margin: 0.83em auto 30px;
  border-top: 1px solid #1D89FF;
`;

const ActiveInvestments = ({
  investments,
  user,
  getBrowsePageDataRequested,
  updateFilteredInvestments,
  loadedActive = false,
  tokens = {},
  pageVariant,
  location,
}) => {
  const [filteredInvestments, setFilteredInvestments] = useState([investments]);
  const [invStatusFilterState, setInvStatusFilterState] = useState({});
  const [propertyTypeFilterState, setPropertyTypeFilterState] = useState({});
  const [investmentStrategyFilterState, setInvestmentStrategyFilterState] = useState({});
  const [matchedNum, setMatchedNum] = useState(investments.length);
  const totalNum = investments.length;
  const [isReturningUser, setIsReturningUser] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [redirect, setRedirect] = useState('/');
  const shouldUseVariant = typeof pageVariant === 'string';

  const sortCategories = ['Recently Added', 'IRR', 'Cash on Cash'];

  const buildFilter = useCallback((label) => {
    const result = investments.reduce((acc, curr) => {
      const doesElemExist = acc.findIndex((item) => {
        return item.label === curr[label];
      });
      if (doesElemExist === -1) {
        if (curr[label]) {
          const obj = {};
          obj.label = curr[label];
          obj.number = 1;
          acc.push(obj);
        }
      } else {
        acc[doesElemExist].number += 1;
      }
      return acc;
    }, []);
    return result;
  }, [investments]);

  useEffect(() => {
    getBrowsePageDataRequested();
  }, [getBrowsePageDataRequested]);

  const filterInvestmentStatus = useCallback((label) => {
    if (Object.keys(invStatusFilterState).includes(label)) {
      setInvStatusFilterState((i) => {
        return { ...i, [label]: !i[label] };
      });
    } else {
      setInvStatusFilterState((i) => {
        return { ...i, [label]: true };
      });
    }
  }, [invStatusFilterState]);

  const filterPropertyTypeStatus = useCallback((label) => {
    if (Object.keys(propertyTypeFilterState).includes(label)) {
      setPropertyTypeFilterState((i) => {
        return { ...i, [label]: !i[label] };
      });
    } else {
      setPropertyTypeFilterState((i) => {
        return { ...i, [label]: true };
      });
    }
  }, [propertyTypeFilterState]);

  const filterInvestmentStrategyStatus = useCallback((label) => {
    if (Object.keys(investmentStrategyFilterState).includes(label)) {
      setInvestmentStrategyFilterState((i) => {
        return { ...i, [label]: !i[label] };
      });
    } else {
      setInvestmentStrategyFilterState((i) => {
        return { ...i, [label]: true };
      });
    }
  }, [investmentStrategyFilterState]);

  const initializeFilters = () => {
    const filters = [];
    filters.push({
      label: 'Investment Status',
      filterFunction: filterInvestmentStatus,
      filters: buildFilter('statusLabel'),
    });
    filters.push({
      label: 'Property Type',
      filterFunction: filterPropertyTypeStatus,
      filters: buildFilter('propertyType'),
    });
    const investmentStrategyFilters = buildFilter('investmentStrategyLabel');
    if (investmentStrategyFilters.length >= 1) {
      filters.push({
        label: 'Investment Strategy',
        filterFunction: filterInvestmentStrategyStatus,
        filters: investmentStrategyFilters,
      });
    }
    return filters;
  };

  const filters = initializeFilters();

  const filterIsEmpty = (filter) => {
    let isEmpty = true;
    Object.entries(filter).map((item) => {
      if (item[1]) isEmpty = false;
      return null;
    });
    return isEmpty;
  };

  const findMatches = (filterState, filterBy, arrayToFilter) => {
    if (filterIsEmpty(filterState)) {
      return arrayToFilter;
    }
    let matches = [];
    Object.entries(filterState).map((item) => {
      if (item[1]) {
        matches = matches.concat(arrayToFilter.filter((investItem) => {
          return investItem[filterBy] === item[0];
        }));
      }
      return null;
    });
    return matches;
  };

  const buildFilteredArray = () => {
    const noFilterSelected = filterIsEmpty(invStatusFilterState) && filterIsEmpty(propertyTypeFilterState) && filterIsEmpty(investmentStrategyFilterState);

    if (noFilterSelected) {
      setMatchedNum(investments.length);
      return investments;
    }

    const statusFilteredArray = findMatches(invStatusFilterState, 'statusLabel', investments);
    const propertyTypeFilteredArray = findMatches(propertyTypeFilterState, 'propertyType', statusFilteredArray);
    const investmentStrategyFilteredArray = findMatches(investmentStrategyFilterState, 'investmentStrategyLabel', propertyTypeFilteredArray);

    setMatchedNum(investmentStrategyFilteredArray.length);
    return investmentStrategyFilteredArray;
  };

  useEffect(() => {
    if (!loadedActive) return;
    const filteredArray = buildFilteredArray();
    setFilteredInvestments(filteredArray);
    updateFilteredInvestments(filteredArray);
  }, [invStatusFilterState, propertyTypeFilterState, investmentStrategyFilterState, loadedActive]);

  const resetFunction = () => {
    setFilteredInvestments(investments);
    setInvStatusFilterState({});
    setPropertyTypeFilterState({});
    setInvestmentStrategyFilterState({});
    document.querySelector('input[type="radio"]').checked = true;
  };

  const sortFunction = (index) => {
    let sortedArray = [];
    switch (index) {
      case 0:
        sortedArray = buildFilteredArray();
        break;
      case 1:
        sortedArray = _.orderBy(filteredInvestments, ({ targetReturn }) => targetReturn || '', ['desc']);
        break;
      case 2:
        sortedArray = _.orderBy(filteredInvestments, ({ estimatedCashOnCashLow }) => estimatedCashOnCashLow || '', ['desc']);
        break;
      default:
        sortedArray = _.orderBy(filteredInvestments, ({ targetHoldMin }) => targetHoldMin || '', ['asc']);
    }
    setFilteredInvestments(sortedArray);
  };

  const handleWatchlistClick = (flagged, watchlist) => {
    if (flagged) {
      axios.post(watchlist.unflagLink);
    } else {
      axios.post(watchlist.flagLink);
    }
  };

  const getImage = (item) => {
    if (item.REIT) {
      if (item.id === 193744) return MRIimage;
      return MRIIimage;
    }
    return item.image;
  };

  const getLTM = (id) => {
    return id === 193744 ? tokens?.mogulReit1LastTwelveMonthsReturn : tokens?.mogulReit2LastTwelveMonthsReturn;
  };

  const getLTMDate = (id) => {
    return id === 193744 ? tokens?.mogulReit1LastTwelveMonthsReturnDate : tokens?.mogulReit2LastTwelveMonthsReturnDate;
  };

  const [cookies] = useCookies(['returning-user']);

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (shouldUseVariant) console.log('Variant active');
    if (cookies['returning-user'] || params.utm_medium === 'email' || params.utm_medium === 'sms') {
      setIsReturningUser(true);
      return;
    }
    setIsReturningUser(false);
  }, []);

  const handleOverlayClick = (path = '/') => {
    setRedirect(path);
    setShowOverlay(true);
  };

  const handleCloseOverlay = (success = false) => {
    setShowOverlay(false);
    if (success) {
      window.location = redirect;
    }
  };

  if (!loadedActive) return <Loading />;

  return (
    <>
      {showOverlay && <AuthOverlay
        whichScreen={isReturningUser ? 'signIn' : 'signUp'}
        closeOverlayFunc={handleCloseOverlay}
      />}
      <ExtendedFlex
        flexDirection={['column', 'row', 'row']}
        bg="gray.0"
        w={[1]}
        py={[30, 40]}
      >
        <ExtendedBox minWidth={[1, 225]} ml={[0, 30]} mr={[0, 35]} px={[20, 0]} mb={[25, 0]} boxSizing="border-box">
          <BrowseFilter
            matchedNum={matchedNum}
            totalNum={totalNum}
            resetFunction={resetFunction}
            sort={sortCategories}
            sortFunction={sortFunction}
            filters={filters}
          />
        </ExtendedBox>
        <ExtendedBox w={1}>
          {
            filteredInvestments.findIndex((item) => item.statusLabel?.toLowerCase() === 'open for investment') !== -1 &&
            <>
              <HeaderText top>
                OPEN FOR INVESTMENT
              </HeaderText>
              <InvestmentsContainer>
                {
                  filteredInvestments.map((item) => {
                    if (item.statusLabel?.toLowerCase() !== 'open for investment') return null;
                    return (
                      <TileContainer
                        key={item.title}
                        data-testid={item.id}
                      >
                        {!shouldUseVariant || user.isLoggedIn || item.REIT ?
                          <Link
                            to={user.isLoggedIn || item.REIT ? item.path : `${isReturningUser ? '/user/login' : '/user/register'}?destination=${item.path}`}
                            className={item.REIT ? 'reit-card' : 'private-placement-card'}
                          >
                            <InvestmentTile
                              isLoggedIn={user.isLoggedIn}
                              watchlistCallback={handleWatchlistClick}
                              {...item}
                              image={getImage(item)}
                              ltmReturn={item.REIT ? getLTM(item.id) : null}
                              ltmReturnDate={item.REIT ? getLTMDate(item.id) : null}
                              statusLabel={item.fullyPledged ? 'Fully Pledged' : item.statusLabel}
                              buttonText="VIEW DETAILS"
                            />
                          </Link>
                          :
                          <button
                            type="button"
                            className={item.REIT ? 'reit-card' : 'private-placement-card'}
                            onClick={(e) => {
                              e.preventDefault();
                              handleOverlayClick(item.path);
                            }}
                            style={{ border: 'none', background: 'none', textAlign: 'left', cursor: 'pointer' }}
                          >
                            <InvestmentTile
                              isLoggedIn={user.isLoggedIn}
                              watchlistCallback={handleWatchlistClick}
                              {...item}
                              image={getImage(item)}
                              ltmReturn={item.REIT ? getLTM(item.id) : null}
                              ltmReturnDate={item.REIT ? getLTMDate(item.id) : null}
                              statusLabel={item.fullyPledged ? 'Fully Pledged' : item.statusLabel}
                              buttonText="VIEW DETAILS"
                            />
                          </button>}
                      </TileContainer>
                    );
                  })
                }
              </InvestmentsContainer>
            </>
          }
          {
            filteredInvestments.findIndex((item) => item.statusLabel?.toLowerCase() === 'open for pledging') !== -1 &&
            <>
              <HeaderText>
                OPEN FOR PLEDGING
              </HeaderText>
              <InvestmentsContainer>
                {
                  filteredInvestments.map((item) => {
                    if (item.statusLabel?.toLowerCase() !== 'open for pledging') return null;
                    return (
                      <TileContainer
                        key={item.title}
                        data-testid={item.id}
                      >
                        {!shouldUseVariant || user.isLoggedIn ?
                          <Link
                            to={user.isLoggedIn || item.REIT ? item.path : `${isReturningUser ? '/user/login' : '/user/register'}?destination=${item.path}`}
                            className={item.REIT ? 'reit-card' : 'private-placement-card'}
                          >
                            <InvestmentTile
                              isLoggedIn={user.isLoggedIn}
                              watchlistCallback={handleWatchlistClick}
                              {...item}
                              image={getImage(item)}
                              ltmReturn={item.REIT ? getLTM(item.id) : null}
                              ltmReturnDate={item.REIT ? getLTMDate(item.id) : null}
                              statusLabel={item.fullyPledged ? 'Fully Pledged' : item.statusLabel}
                              buttonText="VIEW DETAILS"
                            />
                          </Link>
                          :
                          <button
                            type="button"
                            className={item.REIT ? 'reit-card' : 'private-placement-card'}
                            onClick={(e) => {
                              e.preventDefault();
                              handleOverlayClick(item.path);
                            }}
                            style={{ border: 'none', background: 'none', textAlign: 'left', cursor: 'pointer' }}
                          >
                            <InvestmentTile
                              isLoggedIn={user.isLoggedIn}
                              watchlistCallback={handleWatchlistClick}
                              {...item}
                              image={getImage(item)}
                              ltmReturn={item.REIT ? getLTM(item.id) : null}
                              ltmReturnDate={item.REIT ? getLTMDate(item.id) : null}
                              statusLabel={item.fullyPledged ? 'Fully Pledged' : item.statusLabel}
                              buttonText="VIEW DETAILS"
                            />
                          </button>}
                      </TileContainer>
                    );
                  })
                }
              </InvestmentsContainer>
            </>
          }
          {
            filteredInvestments.findIndex((item) => item.statusLabel?.toLowerCase() === 'waitlist') !== -1 &&
            <>
              <HeaderText>
                FULLY PLEDGED AND WAITLIST
              </HeaderText>
              <InvestmentsContainer>
                {
                  filteredInvestments.map((item) => {
                    if (item.statusLabel?.toLowerCase() !== 'waitlist') return null;
                    return (
                      <TileContainer
                        key={item.title}
                        data-testid={item.id}
                      >
                        {!shouldUseVariant || user.isLoggedIn ?
                          <Link
                            to={user.isLoggedIn || item.REIT ? item.path : `${isReturningUser ? '/user/login' : '/user/register'}?destination=${item.path}`}
                            className={item.REIT ? 'reit-card' : 'private-placement-card'}
                          >
                            <InvestmentTile
                              isLoggedIn={user.isLoggedIn}
                              watchlistCallback={handleWatchlistClick}
                              {...item}
                              image={getImage(item)}
                              ltmReturn={item.REIT ? getLTM(item.id) : null}
                              ltmReturnDate={item.REIT ? getLTMDate(item.id) : null}
                              statusLabel={item.fullyPledged ? 'Fully Pledged' : item.statusLabel}
                              buttonText="VIEW DETAILS"
                            />
                          </Link>
                          :
                          <button
                            type="button"
                            className={item.REIT ? 'reit-card' : 'private-placement-card'}
                            onClick={(e) => {
                              e.preventDefault();
                              handleOverlayClick(item.path);
                            }}
                            style={{ border: 'none', background: 'none', textAlign: 'left', cursor: 'pointer' }}
                          >
                            <InvestmentTile
                              isLoggedIn={user.isLoggedIn}
                              watchlistCallback={handleWatchlistClick}
                              {...item}
                              image={getImage(item)}
                              ltmReturn={item.REIT ? getLTM(item.id) : null}
                              ltmReturnDate={item.REIT ? getLTMDate(item.id) : null}
                              statusLabel={item.fullyPledged ? 'Fully Pledged' : item.statusLabel}
                              buttonText="VIEW DETAILS"
                              grayedOut
                            />
                          </button>}
                      </TileContainer>
                    );
                  })
                }
              </InvestmentsContainer>
            </>
          }
          <PastInvestmentsContainer>
            <Button href="/past-investments" target="_blank">VIEW PAST INVESTMENTS</Button>
          </PastInvestmentsContainer>
        </ExtendedBox>
      </ExtendedFlex>
    </>
  );
};

const mapStateToProps = (state) => ({
  loadedActive: state.activeInvestments.loaded,
  investments: state.activeInvestments.investments,
  tokens: state.tokens,
  pageVariant: state.variants?.browsePage?.v,
  user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
  getBrowsePageDataRequested: () => {
    dispatch(browseActions.getBrowseOpenDataRequested());
  },
  updateSelectedFilterValue: (value) => {
    dispatch(actions.updateSelectedCurrentInvestmentFilterValue(value));
  },
  watchInvestmentFlag: (investment) => {
    dispatch(actions.watchInvestmentFlagRequested(investment));
  },
  updateFilteredInvestments: (filteredInvestments) => {
    dispatch(actions.updateFilteredInvestments(filteredInvestments));
  },
});

ActiveInvestments.propTypes = {
  loadedActive: PropTypes.bool,
  investments: PropTypes.array,
  tokens: PropTypes.object,
  watchInvestmentFlag: PropTypes.func,
  getBrowsePageDataRequested: PropTypes.func,
  user: PropTypes.object,
  updateFilteredInvestments: PropTypes.func,
  pageVariant: PropTypes.string,
  location: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActiveInvestments));
