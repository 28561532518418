import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
} from '../coreui';

import DynamicBrowseJoinLink from '../helpers';

const BrowseLink = ({
  isLoggedIn,
  anonText,
}) => {
  return (
    <ExtendedBox
      textAlign="center"
      mx="auto"
      mt={[25, 50, 40, 90, 100]}
    >
      <DynamicBrowseJoinLink
        isLoggedIn={isLoggedIn}
        anonText={anonText}
        type="L1"
      />
    </ExtendedBox>
  );
};

BrowseLink.propTypes = {
  isLoggedIn: PropTypes.bool,
  anonText: PropTypes.string,
};

BrowseLink.defaultProps = {
  anonText: 'Get Started',
};

export default BrowseLink;
