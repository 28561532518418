import React from 'react';
import PropTypes from 'prop-types';

import {
  Heading,
  Body,
  ExtendedFlex,
  ExtendedBox,
  StyledButton,
  Image,
  Data,
  ModalWithOverlay,
} from '../../coreui';

import chartImg from '../../assets/multifamily_chart.png';

function ModalContent(content) {
  return (
    <ExtendedBox>
      {
        content.map((item) => {
          return (
            <Body
              type="B5"
              color="black"
              display="block"
              textAlign="left"
            >
              {item}
            </Body>
          );
        })
      }
    </ExtendedBox>
  );
}

class Investing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      modalContent: null,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(modalContent) {
    this.setState((state) => ({
      isModalVisible: !state.isModalVisible,
      modalContent: state.isModalVisible ? null : modalContent,
    }));
  }

  render() {
    const {
      isModalVisible,
      modalContent,
    } = this.state;

    const {
      disclosure,
    } = this.props;

    return (
      <ExtendedFlex
        w={1}
        height="auto"
        flexDirection={['column', 'column', 'row-reverse', 'row-reverse', 'row-reverse']}
        maxWidth={['90%', '90%', '100%', '1440px', '1440px']}
        mx={['5%', '5%', 'auto', 'auto', 'auto']}
      >
        <ExtendedBox
          width={[1, 1, 0.5, 0.5, 0.5]}
          pr={10}
        >
          <ExtendedBox
            maxWidth={['90%', '90%', 620, 620, 590]}
            mr="auto"
            ml={['auto', 'auto', 50, 65, 90]}
          >
            <Heading
              type="H2"
              display="block"
            >
              DEMAND FOR MULTIFAMILY IS OUTPACING SUPPLY
            </Heading>
            <Body
              type="B1"
              display="block"
              mb={[50, 60, 60, 80, 90]}
            >
              Aging Baby Boomers and the large Millennial
              generation are driving demand for multifamily.
              These older and younger adults prefer the
              flexibility renting provides over home
              ownership.
            </Body>
          </ExtendedBox>
        </ExtendedBox>
        <ExtendedBox
          width={[1, 1, 0.5, 0.5, 0.5]}
        >
          <ExtendedBox
            height="100%"
            w={1}
            maxWidth={['90%', '90%', 620, 620, 590]}
            mx="auto"
            textAlign="center"
          >
            <Image
              src={chartImg}
              maxWidth="542px"
              width={['75%', '75%', '85%', '100%', '100%']}
              IEAspectRatioFix
              mx="auto"
              pl={[0, 0, 30, 30, 0]}

            />
            <Data
              type="D4"
              display="block"
              my={25}
            >
              AMERICA NEEDS 4.6MM MULTIFAMILY UNITS BY 2030
            </Data>
            <StyledButton
              type="L4"
              onClick={() => this.toggleModal(ModalContent(disclosure))}
            >
              Disclosure
            </StyledButton>
            <ModalWithOverlay
              isVisible={isModalVisible}
              handleClick={this.toggleModal}
            >
              {modalContent}
            </ModalWithOverlay>
          </ExtendedBox>
        </ExtendedBox>
      </ExtendedFlex>
    );
  }
}

Investing.propTypes = {
  disclosure: PropTypes.any,
};

export default Investing;
