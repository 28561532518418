import React from 'react';

import ArticleCardViewDetails from '../components/ArticleCardViewDetails';

const section4FactoryFactory = (props) => {
  const {
    url,
  } = props;

  const section4Factory = () => {
    return (
      <ArticleCardViewDetails
        url={url}
      />
    );
  };

  return section4Factory;
};

export default section4FactoryFactory;
