/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  space,
  themeGet,
} from 'styled-system';
import MUICheckbox from '@material-ui/core/Checkbox';

/* eslint-disable indent */
export const StyledCheckboxIcon = styled.i`
  font-style: normal;
  display: inline-block;
  width: 28px;
  height: 28px;
  ${(props) => ({
    border: `1px ${themeGet('colors.gray.10')(props)} solid`,
  })}
  background: #FFFFFF;
  color: #FFFFFF;
  flex: none;
  position: relative;
  transition: background ${themeGet('transition')}ms ease;

  &::before {
    transition: background ${themeGet('transition')}ms ease;
    color: #FFF;
    font-weight: 700;
    position: absolute;
    font-size: 18px;
    top: 5px;
    left: 5px;
  }

  ${(props) => {
    if (props.checked) {
      const color = themeGet('colors.blue.0')(props);
      return props.disabled
        ? {
          '&::before': {
            color: themeGet('colors.gray.10')(props),
          },
        }
        : {
          background: color,
          borderColor: color,
        };
    }
    return {};
  }}
`;

const StyledCheckbox = styled(MUICheckbox)`
  && {
    padding: 0;
    margin-top: 3px;
    margin-right: 15px;

    ${(props) => space({
      mt: 3,
      mr: 15,
      ...props,
    })}
  }
`;
/* eslint-enable indent */

const Checkbox = ({
  hovering,
  required,
  disabled,
  inputProps,
  ...props
}) => {
  return (
    <StyledCheckbox
      icon={<StyledCheckboxIcon className="icon-checkbox" disabled={disabled} hovering={hovering} />}
      checkedIcon={<StyledCheckboxIcon className="icon-checkmark" checked disabled={disabled} hovering={hovering} />}
      disableRipple
      inputProps={{
        ...inputProps,
      }}
      disabled={disabled}
      {...props}
    />
  );
};

Checkbox.propTypes = {
  hovering: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
};

Checkbox.defaultProps = {
  inputProps: {},
};

export default Checkbox;
