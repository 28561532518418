import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import { getQueueArticleDataApi } from '../../api';

export function* getArticleQueueDataFlow(action) {
  try {
    const response = yield call(getQueueArticleDataApi, action.queue);
    yield put(
      actions.getArticleQueueSucceeded({
        queue: action.queue,
        items: response.data.data,
      }),
    );
  } catch (error) {
    yield put(
      actions.getArticleQueueFailed(error),
    );
  }
}

export function* watchGetArticleQueueDataRequested() {
  yield takeLatest(
    types.GET_ARTICLE_QUEUE_REQUESTED,
    getArticleQueueDataFlow,
  );
}

export default watchGetArticleQueueDataRequested;
