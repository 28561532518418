/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';
import cleanElement from 'clean-element';
import { detect } from 'detect-browser';

import {
  height,
  space,
  display,
  alignItems,
  propTypes,
  themeGet,
} from 'styled-system';

import {
  Body,
  Data,
} from '../../coreui';

import Checkbox from './Checkbox';

const CleanFormControlLabel = cleanElement(FormControlLabel);

CleanFormControlLabel.propTypes = {
  ...propTypes.height,
  ...propTypes.space,
  ...propTypes.alignItems,
};

// To override the specificity of Material UI components, we need to concat the
// styled-components class onto itself, hence the `&&`.
/* eslint-disable indent */
const StyledFormControlLabel = styled(({
  zebra,
  ...props
}) => <CleanFormControlLabel {...props} />)`
  && {
    margin-left: 0;
    margin-right: 0;
    ${height}
    ${space}
    ${display}
    ${(props) => alignItems({
      alignItems: 'flex-start',
      ...props,
    })}
  }

  ${(props) => (props.zebra === 'odd' ? {
    background: themeGet('colors.gray.0')(props),
  } : {})}
`;
/* eslint-enable indent */

class CheckboxLabel extends React.Component {
  constructor(props) {
    super(props);
    const browser = detect();
    this.state = {
      hovering: false,
      valid: null,
      browser: browser.name === 'ie' ? 'ie' : 'notie',
    };
    this.validationEventsAttached = false;

    this.inputRef = null;
    this.setInputRef = (element) => { this.inputRef = element; };

    this.handleOnValid = this.handleOnValid.bind(this);
    this.handleOnInvalid = this.handleOnInvalid.bind(this);
    this.handleOnMouseOver = this.handleOnMouseOver.bind(this);
    this.handleOnMouseOut = this.handleOnMouseOut.bind(this);
  }

  componentDidMount() {
    if (this.state.browser === 'notie') {
      this.inputRef.addEventListener('valid', this.handleOnValid);
    }
  }

  handleOnValid(evt) {
    evt.preventDefault();
    this.setState({
      valid: true,
    });
  }

  handleOnInvalid(evt) {
    evt.preventDefault();
    this.setState({
      valid: false,
    });
  }

  handleOnMouseOver() {
    this.setState({ hovering: true });
  }

  handleOnMouseOut() {
    this.setState({ hovering: false });
  }

  render() {
    const {
      label,
      labelProps,
      inputProps,
      checkboxProps,
      ...props
    } = this.props;

    return (
      <StyledFormControlLabel
        onMouseOver={this.handleOnMouseOver}
        onMouseOut={this.handleOnMouseOut}
        onFocus={this.handleOnMouseOver}
        onBlur={this.handleOnMouseOut}
        control={(
          <Checkbox
            hovering={this.state.hovering}
            {...checkboxProps}
            inputProps={{
              ...inputProps,
              ref: (ref) => {
                this.setInputRef(ref);
                if (inputProps && inputProps.ref) {
                  inputProps.ref(ref);
                }
              },
              onInvalid: this.handleOnInvalid,
            }}
          />
        )}
        label={
          <>
            {
              (typeof label === 'string' &&
                <Body
                  type="B1"
                  display="block"
                  mt={3}
                  mb={0}
                  textTransform="uppercase"
                  {...labelProps}
                >
                  {label}
                </Body>
              ) || label
            }
            {
              this.state.valid === false &&
              <Data
                type="D4"
                display="block"
                my={2}
                color="red"
              >
                {(window
                  && window.hyperform
                  && window.hyperform.validationMessage(this.inputRef))
                  || this.inputRef.validationMessage}
              </Data>
            }
          </>
        }
        {...props}
      />
    );
  }
}

CheckboxLabel.propTypes = {
  label: PropTypes.string,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  checkboxProps: PropTypes.object,
};

CheckboxLabel.displayName = 'CheckboxLabel';

export default CheckboxLabel;
