import React from 'react';
import Helmet from 'react-helmet';

const MetaTags = (metaData) => {
  return (
    <Helmet>
      {
        metaData.metaData &&
        metaData.metaData.meta &&
        <title>{metaData.metaData.meta.title}</title>
      }
      {
        metaData.metaData &&
        metaData.metaData.meta &&
        Object.keys(metaData.metaData.meta).map(
          (key) => {
            return (<meta name={key} content={metaData.metaData.meta[key]} />);
          },
        )
      }
      {
        metaData.metaData &&
        metaData.metaData.link &&
        Object.keys(metaData.metaData.link).map(
          (key) => {
            return (<link rel={key} href={metaData.metaData.link[key]} />);
          },
        )
      }
    </Helmet>
  );
};

export default MetaTags;
