import React, { Component } from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';

import actions from './actions';
import Messages from './components/Messages';
import StayConnectedMessage from './components/StayConnectedMessage';

const stayConnectedCookieName = 'dismissedStayConnectedMessage';
const currentStayConnectedCount = 3;
const messagePath = '/knowledge-center/article/note-ceo-part-iii';

class StatusMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    this.handleDismissStayConnected = this.handleDismissStayConnected.bind(this);
  }

  componentDidMount() {
    if (this.props.fetchOnMount) {
      this.props.getStatusMessages();
    }
  }

  handleDismissStayConnected() {
    this.props.cookies.set(stayConnectedCookieName, currentStayConnectedCount, {
      path: '/',
      // 1 Year.
      maxAge: 31536000,
    });
  }

  render() {
    const { cookies } = this.props;

    let dismissedStayConnectedMessage = false;
    const storedCount = cookies.get(stayConnectedCookieName) || 0;
    if (storedCount >= currentStayConnectedCount) {
      dismissedStayConnectedMessage = true;
    }

    const showStayConnectedMessage = this.props.showStayConnectedMessage &&
      !dismissedStayConnectedMessage;

    const messages = [
      showStayConnectedMessage && {
        children: (
          <StayConnectedMessage
            handleClick={this.handleDismissStayConnected}
            path={messagePath}
          />
        ),
        type: 'status',
      },
      ...this.props.messages,
    ].filter((message) => message);

    const wrapperProps = {};
    const closeProps = {};

    // If this is the only message, adjust the size of the output.
    if (showStayConnectedMessage && messages.length === 1) {
      wrapperProps.pt = 10;
      wrapperProps.pb = 10;
      closeProps.top = 10;
    }

    return (
      <Messages
        messages={messages}
        handleClick={() => {
          this.handleDismissStayConnected();
          this.props.closeMessages();
        }}
        wrapperProps={wrapperProps}
        closeProps={closeProps}
      />
    );
  }
}

StatusMessages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  })),
  getStatusMessages: PropTypes.func,
  closeMessages: PropTypes.func,
  fetchOnMount: PropTypes.bool,
  showStayConnectedMessage: PropTypes.bool,
  cookies: instanceOf(Cookies).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getStatusMessages: () => {
    dispatch(actions.getDSMRequested());
  },
  closeMessages: () => {
    dispatch(actions.resetDSMRequested());
  },
});

const mapStateToProps = (state) => ({
  messages: state.dsm.messages,
  showStayConnectedMessage: state.dsm.showStayConnectedMessage,
});

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(StatusMessages),
);
