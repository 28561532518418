import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledButton,
} from '../../coreui';
import CancelModal from '../../dashboard/autoinvest/components/CancelModal';

class CancelButtonWithModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal || false,
      submitting: false,
      hasError: false,
    };
    this.isUnmounted = false;

    this.toggleModal = this.toggleModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  toggleModal() {
    const {
      submitting,
    } = this.state;
    if (submitting) {
      return;
    }
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      // Clear the error if we're closing the modal.
      hasError: prevState.showModal ? false : prevState.hasError,
    }));
  }

  handleCancel(e) {
    const {
      handleCancel,
    } = this.props;
    const {
      hasError,
    } = this.state;
    if (hasError) {
      this.setState({
        hasError: false,
      });
    }
    this.setState({
      submitting: true,
    });

    const result = handleCancel(e);
    if (result instanceof Promise) {
      result
        .then(() => {
          // Only update the state if the component is still mounted.
          if (!this.isUnmounted) {
            this.setState({
              showModal: false,
              hasError: false,
            });
          }
        })
        .catch((e) => {
          if (!this.isUnmounted) {
            this.setState({
              hasError: true,
            });
          }
          throw e;
        })
        .finally(() => {
          // Only update the state if the component is still mounted.
          if (!this.isUnmounted) {
            this.setState({
              submitting: false,
            });
          }
        });
    }
  }

  render() {
    const {
      text,
      header,
      subtext,
      body,
      type,
      nextWithdrawalDate,
    } = this.props;
    const {
      showModal,
      submitting,
      hasError,
    } = this.state;

    return (
      <>
        <StyledButton
          type={type}
          onClick={this.toggleModal}
        >
          {text}
        </StyledButton>
        <CancelModal
          nextWithdrawalDate={nextWithdrawalDate}
          isVisible={showModal}
          header={header}
          subtext={subtext}
          body={body}
          handleClick={this.toggleModal}
          handleCancel={this.handleCancel}
          submitting={submitting}
          error={hasError}
        />
      </>
    );
  }
}

CancelButtonWithModal.propTypes = {
  nextWithdrawalDate: PropTypes.string,
  text: PropTypes.string,
  header: PropTypes.string,
  subtext: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  type: PropTypes.string,
  handleCancel: PropTypes.func,
  showModal: PropTypes.bool,
};

CancelButtonWithModal.defaultProps = {
  text: 'Cancel Enrollment',
  type: 'L4',
};

export default CancelButtonWithModal;
