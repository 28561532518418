import styled, { keyframes } from 'styled-components';

import { animations } from '../helpers/animation';

const mergeAnimations = (animationArray) => {
  return animationArray.reduce(
    (prev, next, index) => {
      return animations.merge(
        index ? prev : animations[prev],
        animations[next],
      );
    },
  );
};

const frames = (name) => {
  const selectedAnimations = name.split(' ');
  return keyframes`
    ${selectedAnimations.length > 1 ?
    mergeAnimations(selectedAnimations) :
    animations[name]}
  `;
};

export const getAnimationCss = ({ animationName, duration, timing, delay, fillMode }) => {
  if (animationName) {
    const animation = `${frames(animationName)} ${(duration || '1s')} ${(timing || 'ease')} ${(delay || '0s')}`;
    return {
      animation,
      animationFillMode: fillMode || 'forwards',
    };
  }
  return {};
};

const Animation = styled.div`
  ${getAnimationCss}
`;

export default Animation;
