import types from './types';

export default {
  getFAQDataRequested: (opts) => ({
    type: types.GET_FAQ_DATA_REQUESTED,
    opts,
  }),
  getFAQDataSucceeded: (data) => ({
    type: types.GET_FAQ_DATA_SUCCEEDED,
    data,
  }),
  getFAQDataFailed: (error) => ({
    type: types.GET_FAQ_DATA_FAILED,
    error,
  }),
};
