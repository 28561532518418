import React from 'react';

import InvestmentCardBlurb from '../components/InvestmentCardBlurb';
import InvestmentCardEmptyPlaceholder from '../components/InvestmentCardEmptyPlaceholder';

const section5FactoryFactory = (props) => {
  const {
    blurb,
    status,
  } = props;

  const section5Factory = () => {
    if (status === 'status-30-active') {
      return (
        <InvestmentCardEmptyPlaceholder />
      );
    }

    if (status === 'status-40-closed') {
      return (
        <InvestmentCardEmptyPlaceholder />
      );
    }
    return (
      <InvestmentCardBlurb
        blurb={blurb}
      />
    );
  };

  return section5Factory;
};

export default section5FactoryFactory;
