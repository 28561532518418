/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Image,
  Heading,
  Body,
  MiniEssay,
} from '../../coreui';

import VisibilityTrigger from '../../components/VisibilityTrigger';
import Container from '../../components/Container';
import Divider from '../../components/Divider';
import FadeIn from '../../components/FadeIn';
import IconBulletCheck from '../../assets/icon_bullet_check.svg';

const Access = ({
  heading,
  insights,
}) => {
  return (
    <ExtendedBox
      py={[60, 80, 100, 120, 140]}
    >
      <Container
        align="center"
        type="simple"
      >
        <ExtendedBox
          maxWidth={[null, 450, 550, 650, 750]}
        >
          <VisibilityTrigger
            trigger="isVisible"
          >
            <MiniEssay
              header={heading}
              animatedFadeIn
            />
          </VisibilityTrigger>
        </ExtendedBox>
      </Container>
      <Container
        type="simple"
        justifyContent="space-between"
      >
        {
          insights.map((insight, index) => {
            return (
              <FadeIn
                key={insight.heading}
                fadeInIndex={1}
              >
                <ExtendedBox
                  mt={40}
                  maxWidth={[null, 400, 500, 600]}
                  {...(index === 0 ? {
                    mr: [null, 25],
                  } : {
                    ml: [null, 25],
                  })}
                >
                  <Heading
                    type="H8"
                    display="block"
                    textAlign="center"
                    alignSelf="center"
                  >
                    {insight.heading}
                  </Heading>
                  {
                    insight.items.map((item, itemIndex) => {
                      return (
                        <FadeIn
                          key={item}
                          fadeInIndex={2 + itemIndex}
                        >
                          <Divider
                            type="horizontal"
                          />
                          <ExtendedFlex
                            align="flex-start"
                          >
                            <Image
                              src={IconBulletCheck}
                              w={[11, 12, 13, 15]}
                              h="auto"
                              mt={[5, 5, 6, 7, 10]}
                              mr={13}
                              IEAspectRatioFix
                            />
                            <Body
                              key={item}
                              type="B1"
                              display="block"
                              mt={0}
                            >
                              {item}
                            </Body>
                          </ExtendedFlex>
                        </FadeIn>
                      );
                    })
                  }
                </ExtendedBox>
              </FadeIn>
            );
          })
        }
      </Container>
    </ExtendedBox>
  );
};

Access.propTypes = {
  heading: PropTypes.string,
  insights: PropTypes.arrayOf(PropTypes.shape({
    heading: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
  })),
};

export default Access;
