/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  Heading,
  Data,
} from '../../coreui';

// eslint-disable-next-line
class Section extends React.Component {
  render() {
    const {
      bg,
      title,
      subtitle,
      keyline,
      titleProps,
      ...passedProps
    } = this.props;
    return (
      <ExtendedBox
        bg={bg}
      >
        <ExtendedBox
          m="auto"
          maxWidth={1440}
          py={[40, 40, 60, 100, 120]}
          borderBottom={(keyline) ? '1px solid' : ''}
          borderColor={(keyline) ? 'gray.7' : ''}
          {...passedProps}
        >
          {
            title &&
            <Heading
              type="H2"
              display="block"
              textAlign="center"
              alignSelf="center"
              m={0}
              mx={[20, null, null, null, 0]}
              mb={[30, 30, 40, 40, 60]}
              {...titleProps}
            >
              {title}
            </Heading>
          }
          {
            subtitle &&
            <Data
              type="D4"
              color="black"
              display="block"
              textAlign="center"
              alignSelf="center"
            >
              {subtitle}
            </Data>
          }
          {this.props.children}
        </ExtendedBox>
      </ExtendedBox>
    );
  }
}

Section.propTypes = {
  bg: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any,
  keyline: PropTypes.bool,
  titleProps: PropTypes.object,
};

Section.defaultProps = {
  keyline: false,
};

export default Section;
