import React, { useState, useEffect, useCallback } from 'react';
import NewSdiraContinueModal from './NewSdiraContinueModal';

import Portal from './Portal';

const NewSdiraContinueWrapper = () => {
  const [portalId, setPortalId] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [openNewSdiraChecked, setOpenNewSdiraChecked] = useState(null);
  const [externalPath, setExternalPath] = useState(null);
  const [externalLabel, setExternalLabel] = useState(null);

  const radioInputName = ':input[name="foe_sdira_foe[new_account]"]';
  const continueInputName = ':input[name="foe_sdira-submit"]';

  const drupal = typeof window !== 'undefined' && window?.Drupal;
  useEffect(() => {
    if (drupal) {
      // Get the initial setting from Drupal.
      // eslint-disable-next-line camelcase
      const initialPortalId = drupal?.settings?.rm_invest_flow?.new_sdira_continue_portal_id;
      setPortalId(initialPortalId);

      // eslint-disable-next-line camelcase
      const externalPath = drupal?.settings?.rm_invest_flow?.new_sdira_url;
      setExternalPath(externalPath);

      // eslint-disable-next-line camelcase
      const externalLabel = drupal?.settings?.rm_invest_flow?.new_sdira_label;
      setExternalLabel(externalLabel);
    }
  }, [drupal, portalId]);

  const $ = typeof window !== 'undefined' && window?.jQuery;
  useEffect(() => {
    const onChange = () => {
      setOpenNewSdiraChecked($(radioInputName).filter(':checked').val() === 'new_rm_ira');
    };

    $(radioInputName).on('change', onChange);

    return () => {
      $(radioInputName).off('change', onChange);
    };
  }, [$, setOpenNewSdiraChecked]);

  const submitFoeForm = useCallback(() => {
    $(continueInputName).click();
  }, [$]);

  useEffect(() => {
    const onClick = (e) => {
      if (openNewSdiraChecked) {
        if (!showModal) {
          // Prevent default event from bubbling.
          e.preventDefault();

          setShowModal(true);
        }
      }
    };

    $(continueInputName).on('click', onClick);

    return () => {
      $(continueInputName).off('click', onClick);
    };
  }, [$, openNewSdiraChecked, showModal, setOpenNewSdiraChecked]);

  const toggleModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  if (!portalId) {
    return null;
  }

  return (
    <Portal id={`${portalId}-modal`}>
      <NewSdiraContinueModal
        isVisible={showModal}
        path={externalPath}
        label={externalLabel}
        continueHandler={(e) => {
          window.open(externalPath, '_blank');

          e.preventDefault();
          setShowModal(false);
          submitFoeForm();
        }}
        handleClick={toggleModal}
      />
    </Portal>
  );
};

export default NewSdiraContinueWrapper;
