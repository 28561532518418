import {
  takeLatest,
  put,
  select,
  call,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';
import { getCloseWelcomeUrl } from '../selectors';
import { callApiCallback } from '../../../api';

export function* closeWelcomeMessageFlow() {
  try {
    const callback = yield select(getCloseWelcomeUrl);
    const response = yield call(callApiCallback, callback);
    yield put(
      actions.closeWelcomeMessageSucceeded(
        response.data,
      ),
    );
  } catch (error) {
    yield put(
      actions.closeWelcomeMessageFailed(
        error,
      ),
    );
  }
}

export function* watchCloseWelcomeMessageRequested() {
  yield takeLatest(
    types.CLOSE_WELCOME_MESSAGE_REQUESTED,
    closeWelcomeMessageFlow,
  );
}

export default watchCloseWelcomeMessageRequested;
