import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledButton,
  Body,
  ExtendedFlex,
} from '../../../coreui';

import ClickableEnvironmentInCarousel from '../../components/ClickableEnvironmentInCarousel';

class ModalLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    this.handleClick = this.handleClick.bind(this);
    this.constructModalContent = this.constructModalContent.bind(this);
  }

  constructModalContent() {
    return (
      <ExtendedFlex
        height="100%"
        w={1}
        align="center"
        flexDirection="row"
        display="inline"
        minHeight="inherit"
        justify="center"
      >
        {
          this.props.slideInfo.disclosureTooltipParagraphs.map(
            (paragraph) => {
              return (
                <Body
                  type="B5"
                  color="black"
                  display="block"
                >
                  {paragraph}
                </Body>
              );
            },
          )
        }
      </ExtendedFlex>
    );
  }

  handleClick(e) {
    e.preventDefault();
    this.props.handleClick(this.constructModalContent());
  }

  render() {
    const { slideInfo } = this.props;

    return (
      <ClickableEnvironmentInCarousel>
        {/* TODO -- Design Q: not a proper L4, but no link type matches D1. */}
        <StyledButton
          type="L4"
          fontSize={[8, 8, 8, 10, 10]}
          onClick={this.handleClick}
        >
          {slideInfo.disclosureTooltipText}
        </StyledButton>
      </ClickableEnvironmentInCarousel>
    );
  }
}

ModalLink.propTypes = {
  slideInfo: PropTypes.object,
  handleClick: PropTypes.func,
};

export default ModalLink;
