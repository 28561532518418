import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';
import stubResponse from '../data';

import { getMessagesApi } from '../../api';

export const mockApi = () => ({
  data: stubResponse,
});

export function* getMessagesDataFlow(action) {
  try {
    const response = yield call(getMessagesApi, action.callback);

    yield put(
      actions.getMessageDataSucceeded({
        ...response.data,
      }),
    );
  } catch (error) {
    yield put(
      actions.getMessageDataFailed(error),
    );
  }
}

export function* watchGetMessageDataRequested() {
  yield takeLatest(
    types.GET_MESSAGE_DATA_REQUESTED,
    getMessagesDataFlow,
  );
}

export default watchGetMessageDataRequested;
