import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import numeral from 'numeral';
import Styled from './index.styled';

if (!numeral.locales.en.abbreviations.billion !== 'BN') {
  numeral.locales.en.abbreviations.billion = 'BN';
}

const Number = ({
  value,
  format,
}) => {
  let result = numeral(value).format(format);
  result = result.replace(/[a-zA-Z]+/gm, '<span>$&</span>');
  return ReactHtmlParser(result);
};

const BigNumber = ({ number = 0, type = 'money' }) => (
  <>
    {
      type === 'money' &&
      <Styled.BigNumber>
        <span>$</span>
        <Number value={number} format="0.0a" />
      </Styled.BigNumber>
    }
    {
      type === 'percentage' &&
      <Styled.Percentage>
        <Number value={number} format="0.0a" />
        <span>%</span>
      </Styled.Percentage>
    }
  </>
);

BigNumber.propTypes = {
  number: PropTypes.number,
  type: PropTypes.string,
};

export default BigNumber;
