import React from 'react';
import PropTypes from 'prop-types';

import {
  Link,
  ExtendedFlex,
  ExtendedBox,
} from '../../coreui';

const PrimaryLinks = ({
  items,
}) => {
  return (
    <ExtendedBox
      my={40}
      px="3%"
    >
      <ExtendedFlex
        flexDirection="row"
        m={0}
        p={0}
      >
        {
          items.map((item) => {
            return (
              <Link
                type="L4"
                href={item.path}
                mx={10}
                pt={3}
              >
                {item.text}
              </Link>
            );
          })
        }
      </ExtendedFlex>
    </ExtendedBox>
  );
};

PrimaryLinks.propTypes = {
  items: PropTypes.array,
};

export default PrimaryLinks;
