/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Image,
} from '../coreui';

const IconCallout = ({
  image,
  children,
  ...additionalProps
}) => {
  return (
    <ExtendedFlex
      flexDirection="row"
      mr={20}
      {...additionalProps}
    >
      <Image
        src={image}
        w={[50, 50, 50, 73]}
        height={[50, 50, 50, 73]}
        mr={[15, 15, 40]}
        IEAspectRatioFix
      />
      <ExtendedFlex
        flexDirection="column"
        flexBasis={1}
      >
        {children}
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

IconCallout.propTypes = {
  image: PropTypes.string,
  children: PropTypes.node,
};

export default IconCallout;
