import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Heading } from '../../coreui';
import RadioGroup from '../../components/forms/RadioGroup';
import RadioLabel from '../../components/forms/RadioLabel';
import FollowUp from '../../components/isq/FollowUp';

const PrimaryReason = ({
  isVisible,
  handleChange,
  value,
  followUp,
}) => {
  const [dirty, setDirty] = useState(false);

  const onChange = useCallback((e) => {
    setDirty(true);
    handleChange(e);
  }, [setDirty, handleChange]);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        What is your primary reason for investing in real estate?
      </Heading>
      <RadioGroup
        name="primaryInvestmentReason"
        value={value}
        onChange={onChange}
        inputProps={{
          'data-test': 'primary_reason',
          required: true,
        }}
      >
        <RadioLabel
          value="grow"
          label="Grow my investment portfolio value"
        />
        <RadioLabel
          value="preserve"
          label="Preserve my savings with minimal risk"
        />
      </RadioGroup>
      {
        value === 'preserve' && dirty &&
        <FollowUp
          subheader="You indicated you are looking for a safe-haven for savings. Illiquid investments into real estate are high risk, do not guarantee preservation of capital and risk loss of principal. Are you comfortable with this level of risk?"
          name="primaryInvestmentReasonFollowUp"
          value={followUp}
          handleChange={onChange}
          options={[
            {
              value: 'comfortable',
              label: 'Yes',
            },
            {
              value: 'not_comfortable',
              label: 'No, please cancel my investment',
            },
          ]}
        />
      }
    </>
  );
};

PrimaryReason.propTypes = {
  isVisible: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.oneOf([
    'grow',
    'preserve',
  ]),
  followUp: PropTypes.string,
};

export default PrimaryReason;
