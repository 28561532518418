import React from 'react';

import {
  ExtendedBox,
  ExtendedFlex,
  Image,
  Body,
  Link,
  CarouselQuotes,
  VisibilitySensor,
} from '../../coreui';

import pressMentionCollection from './pressMentionsData';
import FadeIn from '../../components/FadeIn';

class PressMentions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };

    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible,
      });
    }
  }

  render() {
    const { isVisible } = this.state;

    return (
      <ExtendedBox
        my={[100, 100, 100, 160, 220]}
      >
        <CarouselQuotes>
          {
            pressMentionCollection.map(
              (entry) => {
                return (
                  <ExtendedBox
                    key={entry.name}
                  >
                    <FadeIn>
                      <ExtendedFlex
                        align="center"
                        justify="center"
                        flexDirection="column"
                        opacity={isVisible ? '1' : '0'}
                      >
                        <Image src={entry.logoImage} />
                        <ExtendedBox
                          maxWidth="100%"
                        >
                          <VisibilitySensor
                            callback={this.handleSensorChange}
                          />
                          <Body
                            type="B4"
                            display="block"
                            textAlign="center"
                            maxWidth={[340, 500, 600, 840, 1000]}
                            mx={['15%', '25%', 0, '15%', 0]}
                          >
                            {/* TODO: review Body mx with Craig */}
                            {entry.quote}
                          </Body>
                          {entry.link &&
                            <Link type="B5" href={entry.link}>- {entry.link}</Link>}
                        </ExtendedBox>
                      </ExtendedFlex>
                    </FadeIn>
                  </ExtendedBox>
                );
              },
            )
          }
        </CarouselQuotes>
      </ExtendedBox>
    );
  }
}

export default PressMentions;
