/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  ExtendedBox,
  Image,
} from '../../coreui';

import Message, {
  typeMapping,
} from './Message';
import close from '../../assets/close_wht.svg';
import Krumo from './Krumo';

const Messages = ({
  messages,
  handleClick,
  wrapperProps,
  closeProps,
}) => {
  const sortedMessages = messages.reduce((sortedMessages, message) => {
    const type = typeof sortedMessages[message.type] !== 'undefined' ? message.type : 'status';
    return {
      ...sortedMessages,
      [type]: [
        ...sortedMessages[type],
        message,
      ],
    };
  }, {
    error: [],
    warning: [],
    status: [],
  });
  if (messages.length <= 0) {
    return null;
  }
  return (
    <ExtendedFlex
      w={1}
      flexDirection="column"
      position="relative"
    >
      <Krumo messages={messages} />
      {
        handleClick &&
        <ExtendedBox
          position="absolute"
          right="40px"
          top="40px"
          {...closeProps}
        >
          <Image
            src={close}
            onClick={handleClick}
            data-test="close-messages"
            hover
          />
        </ExtendedBox>
      }
      {
        Object.keys(sortedMessages).map((type) => (
          sortedMessages[type].length > 0 &&
          <ExtendedFlex
            key={type}
            w={1}
            bg={typeMapping[type]}
            flexDirection="column"
            pt={35}
            pb={35}
            align="center"
            px={[10, 0]}
            {...wrapperProps}
          >
            <ExtendedFlex
              flexDirection="column"
              align="flex-start"
              w="90%"
              maxWidth={[1024, null, null, null, 1440]}
            >
              {
                sortedMessages[type].map((message) => (
                  <Message
                    key={message.message}
                    {...message}
                  />
                ))
              }
            </ExtendedFlex>
          </ExtendedFlex>
        ))
      }
    </ExtendedFlex>
  );
};

Messages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape(
    Message.propTypes,
  )),
  handleClick: PropTypes.func,
  wrapperProps: PropTypes.object,
  closeProps: PropTypes.object,
};

export default Messages;
