import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import {
  ModalWithOverlay, ExtendedBox,
} from '../../coreui';

const WebinarModal = ({
  isVisible,
  handleClick,
  url,
  playing,
}) => {
  return (
    <ModalWithOverlay
      isVisible={isVisible}
      handleClick={handleClick}
    >
      <ExtendedBox
        width={['100%', '100%', '640px', '800px']}
        height={['100%', '100%', '360px', '440px']}
      >
        <ReactPlayer
          url={url}
          playing={playing}
          controls
          width="100%"
          height="100%"
        />
      </ExtendedBox>
    </ModalWithOverlay>
  );
};

WebinarModal.propTypes = {
  isVisible: PropTypes.bool,
  handleClick: PropTypes.func,
  url: PropTypes.string,
  playing: PropTypes.bool,
};

export default WebinarModal;
