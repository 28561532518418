import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Formatters,
} from '../../coreui';

import SummaryItem from '../../dashboard/components/SummaryItem';

const Summary = ({
  totalInvested,
  totalDistributions,
}) => {
  return (
    <ExtendedFlex
      flexDirection="column"
      width={1}
      minWidth={280}
      maxWidth={['75%', 1540]}
      px={[0, 50]}
      pb={[40, 50]}
    >
      <ExtendedFlex
        w={1}
        py={[0, 30, 50]}
        flexDirection={['column', 'row']}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <SummaryItem
          value={totalInvested}
          label="Total Invested"
          dataType="D8"
          valueFormat={Formatters.DOLLARS_DECIMALS}
        />
        <SummaryItem
          value={totalDistributions}
          label="Total Distributions"
          dataType="D8"
          valueFormat={Formatters.DOLLARS_DECIMALS}
        />
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

Summary.propTypes = {
  totalInvested: PropTypes.number,
  totalDistributions: PropTypes.number,
};

export default Summary;
