import React from 'react';
import PropTypes from 'prop-types';
import {
  Body,
  Data,
  Image,
  ExtendedBox,
} from '../../coreui';
import close from '../../assets/close_wht.svg';

const WelcomeMessage = ({
  title,
  subheader,
  blurb,
  handleClick,
}) => {
  return (
    <ExtendedBox
      w={1}
      bg="blue.1"
    >
      <ExtendedBox
        position="relative"
        m="auto"
        w={1}
        maxWidth={1920}
      >
        <ExtendedBox
          position="absolute"
          right="40px"
          top="40px"
        >
          <Image
            src={close}
            onClick={handleClick}
            hover
          />
        </ExtendedBox>
        <ExtendedBox
          m="auto"
          py={[40]}
          px={[20, 20, 20, 100]}
          maxWidth={1200}
        >
          <Body
            m={0}
            type="B4"
            color="white"
            display="block"
          >
            { title }
          </Body>
          {
            subheader &&
            <Data
              type="D5"
              m={0}
              mb={[5]}
              color="white"
              display="block"
            >
              { subheader }
            </Data>
          }
          <Data
            m={0}
            type="D3"
            color="white"
            display="block"
          >
            { blurb }
          </Data>
        </ExtendedBox>
      </ExtendedBox>
    </ExtendedBox>
  );
};

WelcomeMessage.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  blurb: PropTypes.string,
  handleClick: PropTypes.func,
};

export default WelcomeMessage;
