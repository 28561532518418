import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { InlineTextLink, isDrupal } from '../../coreui';
import actions from '../actions';

const StayConnectedMessage = ({
  handleClick,
  handleDispatch,
  history,
  path,
}) => {
  return (
    <>
      Stay connected with the RealtyMogul community -{' '}
      {
        <InlineTextLink
          href={path}
          onClick={(e) => {
            e.preventDefault();
            if (typeof handleClick === 'function') {
              handleClick();
            }
            handleDispatch();

            if (isDrupal()) {
              window.location = path;
            } else {
              history.push(path);
            }
          }}
        >
          Read A Note from the CEO - Part III
        </InlineTextLink>
      }
      .
    </>
  );
};

StayConnectedMessage.propTypes = {
  handleDispatch: PropTypes.func,
  handleClick: PropTypes.func,
  history: PropTypes.object,
  path: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  handleDispatch: () => {
    dispatch(actions.handleHideStayConnected());
  },
});

const mapStateToProps = (state) => ({
  messages: state.dsm.messages,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StayConnectedMessage),
);
