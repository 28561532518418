/* eslint-disable react/jsx-props-no-spreading */
// This is a special component only to be used with react-router
import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledNavLink,
  StyledLink,
  TextLink,
  isDrupal,
} from '..';
import { types as linkTypes } from './Link';
import { types as dataTypes } from './Data';
import { types as bodyTypes } from './Body';

const types = {
  ...linkTypes,
  ...dataTypes,
  ...bodyTypes,
};

const NavLink = (props) => {
  const {
    children,
    type,
    withActiveStyle,
    to,
    href,
    drupal,
    ...passedProps
  } = props;

  const NavLinkElement = withActiveStyle ? StyledNavLink : StyledLink;

  // Check if we need a text link.
  const needsTextLink = isDrupal() || href || drupal;
  const Element = needsTextLink ? TextLink : NavLinkElement;

  const styleProps = {
    fontSize: types[type].fontSize,
    fontFamily: types[type].fontFamily,
    fontWeight: types[type].fontWeight,
    textTransform: types[type].textTransform,
    letterSpacing: types[type].letterSpacing,
    textDecoration: types[type].textDecoration,
    border: types[type].border,
    borderColor: types[type].borderColor,
    display: types[type].display,
    width: types[type].width,
    height: types[type].height,
    maxWidth: types[type].maxWidth,
    minWidth: types[type].minWidth,
    px: types[type].px,
    py: types[type].py,
    lineHeight: types[type].lineHeight,
    textAlign: types[type].textAlign,
    bg: types[type].bg,
    color: types[type].color,
    hoverBG: types[type].hoverBG,
    hoverColor: types[type].hoverColor ? types[type].hoverColor : 'black',
    hoverBorderColor: types[type].hoverBorderColor,
  };

  return (
    <Element
      {...styleProps}
      {...(needsTextLink ? {
        href: to || href,
      } : {
        to,
      })}
      {...passedProps}
    >
      { children }
    </Element>
  );
};

NavLink.defaultProps = {
  withActiveStyle: true,
};

NavLink.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(
    Object.keys(types),
  ).isRequired,
  withActiveStyle: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  href: PropTypes.string,
  drupal: PropTypes.bool,
};

export default NavLink;
