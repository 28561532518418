import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import {
  Search,
} from '../../../coreui';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      value: props.value,
    };

    this.updateSearch = this.updateSearch.bind(this);
    this.enterSubmitSearch = this.enterSubmitSearch.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
  }

  updateSearch(event) {
    this.setState({
      redirect: false,
      value: event.target.value,
    });
  }

  enterSubmitSearch(event) {
    if (event.key === 'Enter' && event.target.value) {
      this.submitSearch(event);

      // Call blur on the input so that the keyboard hides in mobile devices.
      event.target.blur();
    }
  }

  submitSearch() {
    this.setState({ redirect: true });
  }

  render() {
    const {
      match,
    } = this.props;

    return (
      <>
        {
          this.state.redirect && this.state.value !== match.params.query &&
          <Redirect to={{
            pathname: `/knowledge-center/search/${encodeURI(this.state.value)}`,
          }}
          />
        }
        <Search
          value={this.state.value}
          placeholder="Search"
          handleChange={this.updateSearch}
          mt={[20, 40]}
          onKeyDown={this.enterSubmitSearch}
          iconOnClick={this.submitSearch}
        />
      </>
    );
  }
}

SearchBar.propTypes = {
  value: PropTypes.string,
  match: PropTypes.object,
};

SearchBar.defaultProps = {
  value: '',
};

export default withRouter(SearchBar);
