import types from './types';

export default {
  getSearchRequested: (query, pageNum) => ({
    type: types.GET_KC_SEARCH_REQUESTED,
    query,
    pageNum,
  }),
  getSearchSucceeded: (data) => ({
    type: types.GET_KC_SEARCH_SUCCEEDED,
    data,
  }),
  getSearchFailed: (error) => ({
    type: types.GET_KC_SEARCH_FAILED,
    error,
  }),
};
