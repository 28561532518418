import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import {
  ExtendedFlex,
  ExtendedBox,
  Data,
  Tooltip,
  TooltipIcon,
  Body,
} from '../../coreui';

import LearnMoreLink from './LearnMoreLink';

const ReitContentItem = ({
  label,
  value,
  valueFormat,
  lastItem,
  tooltipId,
  tooltipText,
  learnMore,
}) => {
  const formattedValue = typeof valueFormat === 'string' ? numeral(value).format(valueFormat) : value;
  return (
    <ExtendedFlex
      flexDirection="column"
      width={[1, 'auto']}
      my={[20, 0]}
    >
      <ExtendedBox
        width={1}
        bg="gray.7"
        height="1px"
        display={['none', 'flex']}
      />
      <ExtendedFlex
        flexDirection={['column-reverse', 'row']}
        justifyContent="space-between"
        alignItems={['flex-start', 'center']}
        py={[0, '1em']}
      >
        <ExtendedFlex
          justifyContent="space-between"
          width={[1, 'auto']}
          pr={[0, 20]}
          alignItems="center"
        >
          <ExtendedFlex
            alignItems="center"
          >
            <Data
              type="D2"
              color={['gray.7', 'black']}
            >
              {label}
            </Data>
            { tooltipText &&
              <ExtendedFlex>
                <TooltipIcon ml={5} tooltipId={tooltipId} />
                <Tooltip id={tooltipId}>
                  <Body type="B5">
                    {tooltipText}
                  </Body>
                </Tooltip>
              </ExtendedFlex> }
            {
              learnMore &&
              <LearnMoreLink
                content=""
              />
            }
          </ExtendedFlex>
        </ExtendedFlex>
        <ExtendedFlex
          borderBottom={['1px solid', 'medium none']}
          borderColor="black"
          width={[1, 'auto']}
        >
          <Body
            type="B1"
            py={[0, 0]}
            my={[0, 0]}
            display={['none', 'block']}
            whiteSpace="nowrap"
          >
            {formattedValue}
          </Body>
          <Data
            type="D7"
            py={[0, 0]}
            my={[0, 0]}
            display={['block', 'none']}
            whiteSpace="nowrap"
          >
            {formattedValue}
          </Data>
        </ExtendedFlex>
      </ExtendedFlex>
      {
        lastItem &&
        <ExtendedBox
          width={1}
          bg="gray.7"
          height="1px"
          display={['none', 'flex']}
        />
      }
    </ExtendedFlex>
  );
};

ReitContentItem.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  valueFormat: PropTypes.string,
  lastItem: PropTypes.bool,
  learnMore: PropTypes.string,
  tooltipText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  tooltipId: PropTypes.string,
};

export default ReitContentItem;
