import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Heading } from '../../coreui';
import RadioLabel from '../forms/RadioLabel';
import RadioGroupInline from '../forms/RadioGroupInline';
import FollowUp from './FollowUp';

class Experience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dirty: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({
      dirty: true,
    });
    return this.props.handleChange(e);
  }

  render() {
    const {
      expStocks,
      expBonds,
      expRealEstate,
      expPrivateEquity,
      expVentureCapital,
      expSmallBiz,
    } = this.props;
    return (
      <>
        <Heading
          type="H6"
          textTransform="uppercase"
        >
          Please indicate your level of experience with each of the following
          investment types:
        </Heading>
        <Heading
          type="H6"
        >
          Stocks
        </Heading>
        <RadioGroupInline
          name="expStocks"
          onChange={this.handleChange}
          inputProps={{
            required: true,
          }}
          value={expStocks}
        >
          <RadioLabel
            value="none"
            label="None"
          />
          <RadioLabel
            value="limited"
            label="Limited"
          />
          <RadioLabel
            value="extensive"
            label="Extensive"
          />
        </RadioGroupInline>
        <Heading
          type="H6"
        >
          Bonds
        </Heading>
        <RadioGroupInline
          name="expBonds"
          onChange={this.handleChange}
          inputProps={{
            required: true,
          }}
          value={expBonds}
        >
          <RadioLabel
            value="none"
            label="None"
          />
          <RadioLabel
            value="limited"
            label="Limited"
          />
          <RadioLabel
            value="extensive"
            label="Extensive"
          />
        </RadioGroupInline>
        <Heading
          type="H6"
        >
          Real Estate
        </Heading>
        <RadioGroupInline
          name="expRealEstate"
          onChange={this.handleChange}
          inputProps={{
            required: true,
          }}
          value={expRealEstate}
        >
          <RadioLabel
            value="none"
            label="None"
          />
          <RadioLabel
            value="limited"
            label="Limited"
          />
          <RadioLabel
            value="extensive"
            label="Extensive"
          />
        </RadioGroupInline>
        <Heading
          type="H6"
        >
          Private Equity
        </Heading>
        <RadioGroupInline
          name="expPrivateEquity"
          onChange={this.handleChange}
          inputProps={{
            required: true,
          }}
          value={expPrivateEquity}
        >
          <RadioLabel
            value="none"
            label="None"
          />
          <RadioLabel
            value="limited"
            label="Limited"
          />
          <RadioLabel
            value="extensive"
            label="Extensive"
          />
        </RadioGroupInline>
        <Heading
          type="H6"
        >
          Venture Capital
        </Heading>
        <RadioGroupInline
          name="expVentureCapital"
          onChange={this.handleChange}
          inputProps={{
            required: true,
          }}
          value={expVentureCapital}
        >
          <RadioLabel
            value="none"
            label="None"
          />
          <RadioLabel
            value="limited"
            label="Limited"
          />
          <RadioLabel
            value="extensive"
            label="Extensive"
          />
        </RadioGroupInline>
        <Heading
          type="H6"
        >
          Restaurant/Small Business
        </Heading>
        <RadioGroupInline
          name="expSmallBiz"
          onChange={this.handleChange}
          inputProps={{
            required: true,
          }}
          value={expSmallBiz}
        >
          <RadioLabel
            value="none"
            label="None"
          />
          <RadioLabel
            value="limited"
            label="Limited"
          />
          <RadioLabel
            value="extensive"
            label="Extensive"
          />
        </RadioGroupInline>
        {
          expStocks === 'none'
          && expBonds === 'none'
          && expRealEstate === 'none'
          && expPrivateEquity === 'none'
          && expVentureCapital === 'none'
          && expSmallBiz === 'none'
          && this.state.dirty === true &&
          <FollowUp
            subheader="You indicated you do not have any investing experience. This investment may not be appropriate for a first time investor."
            name="experienceFollowUp"
            value={this.props.followUp}
            handleChange={this.props.handleChange}
          />
        }
      </>
    );
  }
}

const options = [
  'none',
  'limited',
  'extensive',
];

Experience.propTypes = {
  handleChange: PropTypes.func,
  expStocks: PropTypes.oneOf(options),
  expBonds: PropTypes.oneOf(options),
  expRealEstate: PropTypes.oneOf(options),
  expPrivateEquity: PropTypes.oneOf(options),
  expVentureCapital: PropTypes.oneOf(options),
  expSmallBiz: PropTypes.oneOf(options),
  followUp: PropTypes.string,
};

export default Experience;
