import types from './types';
import authTypes from '../../auth/types';
import { getTransactions } from '../deserializers/transactions';

const initialState = {
  loaded: false,
  completedInvestmentsData: [],
  completedInvestmentsDataExportUrl: '/api/v1/dashboard/transactions?type=completedInvestmentsData&export',
  activeInvestmentsData: [],
  activeInvestmentsDataExportUrl: '/api/v1/dashboard/transactions?type=activeInvestmentsData&export',
  allInvestmentsData: [],
  allInvestmentsDataExportUrl: '/api/v1/dashboard/transactions?type=allInvestmentsData&export',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.DASHBOARD_TRANSACTIONS_API_REQUESTED:
      return {
        ...state,
        loaded: false,
      };

    case types.DASHBOARD_TRANSACTIONS_API_SUCCEEDED:
      return {
        ...state,
        loaded: true,
        ...getTransactions(action.data),
      };

    case authTypes.USER_LOGOUT_REQUESTED: {
      return {
        ...state,
        ...initialState,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
