/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Link,
} from '../../coreui';

import actions from './actions';

class FlagButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const url = (this.props.flagged) ? this.props.flag.unflag : this.props.flag.flag;
    this.props.flagToggled({ flagged: !this.props.flagged });
    this.props.flagApiActionRequested({ id: this.props.flag.id, callback: url });
  }

  render() {
    const {
      flag,
      flagged,
      ...additionalProps
    } = this.props;
    const isFlagged = (flagged == null) ? flag.flagged : flagged;
    return (
      <>
        <Link
          onClick={this.handleClick}
          width="100%"
          type="L1"
          {...additionalProps}
        >
          {(isFlagged) ? flag.button_text.unflag : flag.button_text.flag}
        </Link>
      </>
    );
  }
}

FlagButton.propTypes = {
  flag: PropTypes.object,
  flagToggled: PropTypes.func,
  flagged: PropTypes.bool,
  flagApiActionRequested: PropTypes.func,
};

const mapStateToProps = (state) => ({
  flagged: state.flagButton.flagged,
});

const mapDispatchToProps = (dispatch) => {
  return {
    flagApiActionRequested: (opts) => {
      dispatch(actions.flagApiActionRequested(opts));
    },
    flagToggled: (opts) => {
      dispatch(actions.flagToggled(opts));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlagButton);
