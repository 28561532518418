import types from './types';

export default {
  getQueueFAQItemsDataRequested: (id) => ({
    type: types.GET_FAQ_ITEMS_REQUESTED,
    id,
  }),
  getQueueFAQItemsDataSucceeded: (data) => ({
    type: types.GET_FAQ_ITEMS_SUCCEEDED,
    data,
  }),
  getQueueFAQItemsDataFailed: (error) => ({
    type: types.GET_FAQ_ITEMS_FAILED,
    error,
  }),
};
