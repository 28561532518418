import React from 'react';
import PropTypes from 'prop-types';

import {
  ModalWithOverlay,
  Data,
  Body,
  ExtendedFlex,
  Link,
  StyledButton,
} from '../../coreui';

import withPortal from '../../withPortal';

const componentRoot = typeof document !== 'undefined' ? document.getElementById('invest-flow') : null;

const CancelModal = ({
  path,
  handleClick,
  children,
  ...props
}) => {
  return (
    <ModalWithOverlay
      handleClick={handleClick}
      // eslint-disable-next-line
      {...props}
    >
      <ExtendedFlex
        flexDirection="column"
        align="center"
        m={[null, null, 30]}
      >
        <Data type="D2" textTransform="uppercase">
          Are you sure you want to cancel your investment?
        </Data>
        <Body type="B1" textAlign="center">
          Your progress will be lost and your investment will be canceled.
        </Body>
        <ExtendedFlex
          w={1}
          my={30}
          justify="center"
          align="center"
          flexDirection={['column', null, 'row']}
        >
          <StyledButton type="L4" onClick={handleClick}>
            Back to page
          </StyledButton>
          <ExtendedFlex w="15%" display={['none', null, 'block']} />
          <Link type="L4" href={path} color="gray.7" mt={[30, null, 0]}>
            Cancel my investment
          </Link>
        </ExtendedFlex>
      </ExtendedFlex>
    </ModalWithOverlay>
  );
};

CancelModal.propTypes = {
  path: PropTypes.string,
  handleClick: PropTypes.func,
  children: PropTypes.object,
};

export default withPortal(
  CancelModal,
  componentRoot,
  {
    noRootNoRender: true,
  },
);
