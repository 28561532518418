import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { connect } from 'react-redux';

import {
  ExtendedFlex,
  Image,
  Heading,
  Body,
  Data,
  Link,
  StyledButton,
  Formatters,
} from '../../coreui';

import actions from '../actions';

class WatchlistItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.state.loading) {
      return;
    }
    this.props.watchlistFlag(this.props.item.id, this.props.item.cancelUrl);
    this.setState({
      loading: true,
    });
  }

  render() {
    const {
      item,
    } = this.props;

    const formattedAmount = numeral(item.amount).format(Formatters.DOLLARS_DECIMALS);

    return (
      <ExtendedFlex
        width="100%"
        flexDirection="column"
        alignItems="center"
        borderBottom="1px solid"
        borderColor="gray.10"
        py={['3em', '100px']}
      >
        <ExtendedFlex
          justifyContent="space-between"
          width="92%"
          flexDirection={['column', 'column', 'row']}
          alignItems={['flex-start', 'flex-start', 'center']}
        >
          <ExtendedFlex
            flexDirection={['column', 'row']}
            alignItems={['flex-start', 'center']}
          >
            <Image
              src={item.imageUrl}
              w={[280, 316]}
              height={[130, 170]}
            />
            <ExtendedFlex
              flexDirection="column"
              px={['0px', '42px']}
            >
              <Heading
                type="H6"
                mb="0"
              >
                {item.title}
              </Heading>
              <Body
                type="B3"
              >
                {item.location}
              </Body>
              <Data type="D5">
                Minimum Investment: {formattedAmount}
              </Data>
            </ExtendedFlex>
          </ExtendedFlex>
          <ExtendedFlex
            flexDirection="column"
            justifyContent="space-around"
            alignItems={['flex-start', 'center']}
          >
            <Link
              type="L1"
              href={item.buttonUrl}
              my={['2em']}
            >
              View
            </Link>
            <StyledButton
              type="L4"
              onClick={this.handleClick}
              my={['1em']}
              loading={this.state.loading}
            >
              Remove from Watchlist
            </StyledButton>
          </ExtendedFlex>
        </ExtendedFlex>
      </ExtendedFlex>
    );
  }
}

WatchlistItem.propTypes = {
  item: PropTypes.object,
  watchlistFlag: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    watchlistFlag: (id, callback) => {
      dispatch(actions.pendingDashboardWatchlistFlagRequested(id, false, callback));
    },
  };
};

export default connect(null, mapDispatchToProps)(WatchlistItem);
