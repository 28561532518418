import React from 'react';
import PropTypes from 'prop-types';
import RMButton from '../RMButton';
import Styled from './index.styled';
import Text from '../Text';

const Item = ({
  title,
  value,
  label,
}) => (
  <Styled.Item>
    <div className="title">{title}</div>
    <div className="value"><Text>{value}</Text></div>
    <div className="label"><Text>{label}</Text></div>
  </Styled.Item>
);

const ReitHistorical = ({
  background = '#F2F2F2',
  title = 'Overview',
  graph,
  distributions,
  items = [],
  cta,
}) => {
  return (
    <Styled.ReitHistorical background={background}>
      <Styled.Container>
        <Styled.DescriptionTile>
          <h1>{title}</h1>
        </Styled.DescriptionTile>
        <Styled.Column>
          <h2>{graph.title}</h2>
          <img src={graph.image} alt={graph.title} />
        </Styled.Column>
        <Styled.Column>
          <div>
            <h2><Text>{distributions.title}</Text></h2>
            <div className="value"><Text>{distributions.value}</Text></div>
          </div>
          <Styled.ContentTiles>
            {items.map((item) => (<Item
              key={item.title}
              title={item.title}
              value={item.value}
              label={item.label}
            />))}
          </Styled.ContentTiles>
        </Styled.Column>
        {
          cta &&
          <Styled.ButtonContainer>
            <RMButton href={cta.href} styleType={cta?.type ? cta.type : 'Primary'} target="_blank">{cta.label}</RMButton>
          </Styled.ButtonContainer>
        }
      </Styled.Container>
    </Styled.ReitHistorical>
  );
};

ReitHistorical.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string,
  graph: PropTypes.object,
  distributions: PropTypes.object,
  items: PropTypes.array,
  cta: PropTypes.object,
};

Item.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
};

export default ReitHistorical;
