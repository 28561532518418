import { injectGlobal } from 'styled-components';

import merlinIconsEot from '../assets/fonts/merlinIcons.eot';
import merlinIconsTtf from '../assets/fonts/merlinIcons.ttf';
import merlinIconsWoff from '../assets/fonts/merlinIcons.woff';
import merlinIconsSvg from '../assets/fonts/merlinIcons.svg';
import './styles.css';

export default injectGlobal`
  @font-face {
    font-family: 'merlinIcons';
    src:  url('${merlinIconsEot}');
    src:  url('${merlinIconsEot}#iefix') format('embedded-opentype'),
    url('${merlinIconsTtf}') format('truetype'),
    url('${merlinIconsWoff}') format('woff');
    url('${merlinIconsSvg}#merlinIcons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }
`;
