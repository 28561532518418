import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledButton,
  ExtendedBox,
  ExtendedFlex,
} from '../coreui';

class ModalLink extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.constructModalContent = this.constructModalContent.bind(this);
  }

  constructModalContent() {
    const { content } = this.props;
    return (
      <ExtendedFlex
        height="100%"
        w={1}
        align="center"
        flexDirection="row"
        display="inline"
        minHeight="inherit"
        justify="center"
      >
        {content}
      </ExtendedFlex>
    );
  }

  handleClick(e) {
    e.preventDefault();
    this.props.handleClick(this.constructModalContent());
  }

  render() {
    const { title } = this.props;

    return (
      <ExtendedBox>
        <StyledButton
          type="L4"
          fontSize={[8, 8, 8, 10, 10]}
          onClick={this.handleClick}
        >
          {title}
        </StyledButton>
      </ExtendedBox>
    );
  }
}

ModalLink.propTypes = {
  content: PropTypes.any,
  title: PropTypes.string,
  handleClick: PropTypes.func,
};

export default ModalLink;
