/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import noop from 'lodash/noop';

import {
  ExtendedFlex,
  Heading,
  Body,
  Numeral,
  formatters,
  InlineTextLink,
  Link,
} from '../../../coreui';

import Actions from '../../../components/forms/Actions';
import PrimaryButton from '../../../components/forms/PrimaryButton';
import CancelButtonWithModal from './CancelButtonWithModal';
import Form from '../../../components/forms/Form';
import AutoInvestContentWrapper from './AutoInvestContentWrapper';
import CheckboxLabel from '../../../components/forms/CheckboxLabel';
import Label from '../../../components/forms/Label';
import Input from '../../../components/forms/Input';
import Select from '../../../components/forms/Select';
import EnrollmentErrors from './EnrollmentErrors';
import { autoInvestUrl } from '../../links';
import ConfirmModal from './ConfirmModal';

class EditAutoInvestConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlyAmountFocus: '',
      requestedAmount: props.requestedAmount,
      bankAccount: props.bankAccount,
      submitting: false,
      isModalVisible: false,
    };

    this.isUnmounted = false;

    this.amountInput = undefined;

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.addAmountValidator = this.addAmountValidator.bind(this);
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  addAmountValidator() {
    if (this.amountInput && window && window.hyperform) {
      window.hyperform.addValidator(this.amountInput, () => {
        const {
          requestedAmount,
        } = this.state;
        const {
          minPerMonth,
          maxPerMonth,
        } = this.props;

        const valid = requestedAmount >= minPerMonth
          && (maxPerMonth === null || requestedAmount <= maxPerMonth);

        const errorMessage = maxPerMonth === null
          ? `Please enter at least ${numeral(minPerMonth).format(formatters.DOLLARS)}.`
          : `Please enter an amount between ${numeral(minPerMonth).format(formatters.DOLLARS)} and ${numeral(maxPerMonth).format(formatters.DOLLARS)}.`;
        this.amountInput.setCustomValidity(valid ? '' : errorMessage);
        return valid;
      });
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();

    if (!this.state.isModalVisible) {
      this.setState({
        isModalVisible: true,
      });
      return;
    }

    this.setState({
      submitting: true,
    });
    this.props.handleSubmit({
      requestedAmount: Number.parseInt(this.state.requestedAmount, 10),
      bankAccount: Number.parseInt(this.state.bankAccount, 10),
    })
      .finally(() => {
        if (!this.isUnmounted) {
          this.setState({
            submitting: false,
          });
        }
      });
  }

  render() {
    const {
      selectedOffering,
      selectedAccount,
      nextWithdrawalDate,
      minPerMonth,
      maxPerMonth,
      bankAccountValues,
      bankAccounts,
      enrollmentErrors,
      handleCancel,
      cancelBody,
    } = this.props;
    const {
      submitting,
    } = this.state;

    return (
      <AutoInvestContentWrapper>
        <Form onSubmit={(e) => this.handleFormSubmit(e)} >
          <ExtendedFlex
            flexDirection="column"
            w={1}
            maxWidth={650}
          >
            <Heading
              type="H2"
            >
              Edit Your Enrollment
            </Heading>
            <ExtendedFlex
              flexDirection="column"
              align="flex-start"
              mb={50}
            >
              <CancelButtonWithModal
                text="Cancel this enrollment?"
                handleCancel={handleCancel}
                body={cancelBody}
                nextWithdrawalDate={nextWithdrawalDate}
              />
            </ExtendedFlex>
            <Heading
              type="H6"
              textTransform="uppercase"
            >
              Current offering selected:
            </Heading>
            <CheckboxLabel
              label={selectedOffering}
              mb={50}
              checkboxProps={{
                checked: true,
                disabled: true,
              }}
            />
            <Heading
              type="H6"
              textTransform="uppercase"
            >
              Current account selected:
            </Heading>
            <CheckboxLabel
              label={selectedAccount}
              mb={50}
              checkboxProps={{
                checked: true,
                disabled: true,
              }}
            />
            <Heading
              type="H6"
              textTransform="uppercase"
            >
              Change your enrollment amount?
            </Heading>
            <Body
              type="B2"
            >
              {
                maxPerMonth &&
                <>
                  You are eligible for a maximum auto investment amount
                  of <Numeral value={maxPerMonth} format={formatters.DOLLARS} /> per
                  month.&nbsp;
                </>
              }
              The minimum Auto Invest amount
              is <Numeral value={minPerMonth} format={formatters.DOLLARS} /> per
              month. Please input your desired monthly auto-invest amount below.
            </Body>
            <Label
              htmlFor="monthlyAmount"
            >
              Monthly Auto Investment Amount
              <Input
                forwardedRef={(ref) => {
                  this.amountInput = ref;
                  this.addAmountValidator();
                }}
                id="monthlyAmount"
                placeholder="$0"
                name="monthly_amount"
                onFocus={() => {
                  if (!this.state.monthlyAmountFocus) {
                    this.setState({
                      monthlyAmountFocus: '$',
                    });
                  }
                }}
                onBlur={() => {
                  if (!this.state.requestedAmount) {
                    this.setState({
                      monthlyAmountFocus: '',
                    });
                  }
                }}
                value={(
                  this.state.requestedAmount ?
                    numeral(this.state.requestedAmount).format(formatters.DOLLARS)
                    : this.state.monthlyAmountFocus
                ) || ''}
                onChange={
                  // This is to prevent a warning. We handle it via onInput.
                  noop
                }
                onInput={(e) => {
                  const value = e.target.value
                    // Replace everything after the period.
                    .replace(/\..*$/, '')
                    // Replace non-digits.
                    .replace(/[\D]/g, '');
                  if (value <= maxPerMonth || maxPerMonth === null) {
                    this.setState({
                      requestedAmount: (value && parseInt(value, 10) > 0) ?
                        value.substring(0, 12) : '',
                    });
                  }
                }}
                data-test="monthly_amount"
                required
              />
            </Label>
            <Heading
              type="H6"
              textTransform="uppercase"
            >
              Switch your bank account?
            </Heading>
            <Body
              type="B2"
            >
              Select from an approved bank account below. If you would like to add
              a new account, please contact us
              at <InlineTextLink href="tel:+18777817062">877-781-7062</InlineTextLink> or
              via email
              at <InlineTextLink href="mailto:investor-help@realtymogul.com">investor-help@realtymogul.com</InlineTextLink>.
            </Body>
            <Select
              name="bank_account"
              value={this.state.bankAccount}
              onChange={(e) => {
                this.setState({
                  bankAccount: e.target.value,
                });
              }}
              placeholder="Select a bank account"
              required
              showEmpty={false}
              options={bankAccountValues}
            />
            <CheckboxLabel
              label={
                <>
                  <Body
                    type="B1"
                    display="block"
                    mt={3}
                    mb={0}
                  >
                    The Issuer of this investment uses VeriCheck to process electronic payments. By placing my investment below, I authorize the purchase amount to be automatically debited from the bank account listed above (“My Account”) and credited directly to the relevant account established by the Issuer of this investment. Additionally, I authorize all distributions paid by the Issuer of this investment to be deposited directly into My Account. I understand that if I request to cancel my subscription to this offering after funds are debited from My Account, a refund may not be processed for up to 90 days after funds have been received in the Issuer&apos;s relevant account. Additionally, I authorize debits from My Account for any deposits made in error. This authorization will remain in effect until I give written notice to cancel it.
                  </Body>
                  <Body
                    type="B1"
                    display="block"
                  >
                    ACH Agreement: In the case of an ACH Transaction being rejected for Non Sufficient Funds (NSF) I understand that the Issuer of this investment opportunity may at its discretion attempt to process the charge again within 30 days. I agree to be bound by NACHA Operating Rules as they pertain to this transaction. I acknowledge that the origination of ACH transactions from and to My Account must comply with the provisions of U.S. law. I certify that I am an authorized signatory of this bank account and will not dispute this transaction with my bank; so long as the transaction corresponds to the terms indicated in this transaction.
                  </Body>
                </>
              }
              my={50}
              inputProps={{
                required: true,
                'data-test': 'agreement',
              }}
            />
            {
              enrollmentErrors &&
              <EnrollmentErrors
                {...enrollmentErrors}
              />
            }
            <Actions>
              <PrimaryButton
                loading={submitting}
                disable={submitting}
                buttonType="submit"
              >
                Save
              </PrimaryButton>
              <Link
                type="L4"
                to={autoInvestUrl}
              >
                Cancel edits
              </Link>
            </Actions>
          </ExtendedFlex>
        </Form>
        <ConfirmModal
          isVisible={this.state.isModalVisible}
          amount={Number.parseInt(this.state.requestedAmount, 10)}
          bankAccount={bankAccounts.find((i) => (
            i.id === Number.parseInt(this.state.bankAccount, 10)))}
          handleClick={() => {
            this.setState({
              isModalVisible: false,
            });
            this.amountInput.focus();
          }}
          handleSubmit={this.handleFormSubmit}
          submitting={submitting}
          error={(
            enrollmentErrors &&
            <EnrollmentErrors
              {...enrollmentErrors}
            />
          )}
        />
      </AutoInvestContentWrapper>
    );
  }
}

EditAutoInvestConfig.propTypes = {
  requestedAmount: PropTypes.number,
  bankAccount: PropTypes.number,
  selectedOffering: PropTypes.string,
  selectedAccount: PropTypes.string,
  nextWithdrawalDate: PropTypes.string,
  minPerMonth: PropTypes.number,
  maxPerMonth: PropTypes.number,
  bankAccountValues: PropTypes.array,
  bankAccounts: PropTypes.array,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  cancelBody: CancelButtonWithModal.propTypes.body,
  enrollmentErrors: PropTypes.object,
};

EditAutoInvestConfig.defaultProps = {
  minPerMonth: 250,
  maxPerMonth: 5000,
  bankAccountValues: [
    { value: 1234, label: 'Savings 5833' },
  ],
  handleSubmit: noop,
  handleCancel: noop,
};

export default EditAutoInvestConfig;
