import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// eslint-disable-next-line
class Zendesk extends React.Component {
  componentDidMount() {
    // RTML-1254: Hide ZenDesk widget on /past-investments
    const { pathname } = this.props.location;
    if (typeof zE === 'undefined' && pathname !== '/past-investments') {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://static.zdassets.com/ekr/snippet.js?key=f0fb7231-d555-4b6c-b382-06ff3c2efb4e';
      s.id = 'ze-snippet';
      document.body.appendChild(s);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user
      && this.props.user.firstName
      && this.props.user.lastName
      && this.props.user.email) {
      if (typeof zE !== 'undefined') {
        // eslint-disable-next-line
        zE(() => {
          // eslint-disable-next-line
          zE.identify({ name: `${this.props.user.firstName} ${this.props.user.lastName}`, email: this.props.user.email });
        });
      }
    }
  }

  render() {
    return ('');
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user,
});

Zendesk.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
};

export default connect(mapStateToProps)(withRouter(Zendesk));
