import types from './types';

export default {
  careerListRequested: () => ({
    type: types.CAREER_LIST_REQUESTED,
  }),
  careerListSucceeded: (data) => ({
    type: types.CAREER_LIST_SUCCEEDED,
    data,
  }),
  careerListFailed: (error) => ({
    type: types.CAREER_LIST_FAILED,
    error,
  }),
  testimonialsRequested: () => ({
    type: types.TESTIMONIAL_LIST_REQUESTED,
  }),
  testimonialsSucceeded: (data) => ({
    type: types.TESTIMONIAL_LIST_SUCCEEDED,
    data,
  }),
  testimonialsFailed: (error) => ({
    type: types.TESTIMONIAL_LIST_FAILED,
    error,
  }),
};
