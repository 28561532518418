import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import browse from './browse/reducers';

import app from './appWrapper/reducer';
import auth from './auth/reducer';
import salesforce from './salesforce/reducer';
import tokens from './tokens/reducer';
import careers from './careers/reducer';
import homepage from './homepage/reducer';
import howItWorks from './howItWorks/reducer';
import ourStory from './ourStory/reducer';
import header from './header/reducer';
import footer from './footer/reducer';
import knowledgeCenter from './knowledgeCenter/reducer';
import article from './knowledgeCenter/article/reducer';
import video from './knowledgeCenter/video/reducer';
import queueFAQItems from './components/queueFAQItems/reducer';
import popularContent from './knowledgeCenter/components/popularContent/reducer';
import glossary from './knowledgeCenter/glossary/reducer';
import knowledgeCenterSearch from './knowledgeCenter/search/reducer';
import benefits from './benefits/reducer';
import retirement from './retirement/reducer';
import tenThirtyOneExchange from './1031Exchange/reducer';
import reitData from './reitData/reducer';

import faq from './faq/reducer';
import reit from './reit/reducer';
import reitLanding from './components/ReitLanding/reducer';
import ioDetails from './ioDetails/reducer';
import privatePlacement from './privatePlacement/reducer';
import invested from './invested/reducer';
import dashboard from './dashboard/reducers';
import adminMenu from './adminMenu/reducer';
import watchList from './ioDetails/components/watchListToggle/reducer';
import flagButton from './components/FlagButton/reducer';
import dsm from './dsm/reducer';
import revalidateEmail from './revalidateEmail/reducer';
import isq from './isq/reducer';
import gtky from './gtky/reducer';
import shareRepurchase from './shareRepurchase/reducer';

import variants from './variants/reducer';

const rootReducer = combineReducers({
  app,
  auth,
  salesforce,
  tokens,
  ...browse,
  ...dashboard,
  careers,
  homepage,
  howItWorks,
  ourStory,
  header,
  footer,
  knowledgeCenter,
  faq,
  reit,
  article,
  video,
  queueFAQItems,
  adminMenu,
  popularContent,
  knowledgeCenterSearch,
  glossary,
  benefits,
  retirement,
  ioDetails,
  invested,
  privatePlacement,
  watchList,
  flagButton,
  tenThirtyOneExchange,
  dsm,
  revalidateEmail,
  reitData,
  isq,
  gtky,
  shareRepurchase,
  variants,
  reitLanding,
  router: routerReducer,
});

export default rootReducer;
