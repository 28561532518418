import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';

import {
  ScrollToTopOnMount,
  ExtendedFlex,
  ExtendedBox,
  ShowMore,
  UnlockOpportunities,
  Hero,
  Loader,
} from '../coreui';

import actions from './actions';

import Filters from './filters';
import Cards from './cards';
import Callouts from './callouts';
import QueueFAQItems from '../components/queueFAQItems';

import hero from '../assets/chair_hero.jpg';
import ScrollToSpanOnMount from '../components/ScrollToSpanOnMount';

class KnowledgeCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    this.showMoreClickHandler = this.showMoreClickHandler.bind(this);
    this.updateSelectedFilterValue = this.updateSelectedFilterValue.bind(this);
  }

  UNSAFE_componentWillMount() {
    const topic = (this.props.match.params.topic) ? this.props.match.params.topic.replace(/-/g, '_') : 'all';
    this.updateSelectedFilterValue(topic);
  }

  showMoreClickHandler() {
    this.props.getMoreArticlesRequested(`/${this.props.nextUrl}`);
  }

  updateSelectedFilterValue(category) {
    const topicPath = (category === 'all') ? '' : `/${category.replace(/_/g, '-')}`;
    this.props.history.push(`/knowledge-center${topicPath}`);
    this.props.updateKnowledgeCenterFilterRequested(category);
  }

  render() {
    const topic = this.props.match.params.topic;

    const {
      items,
      itemTopics,
      selectedCategory,
      user,
      nextUrl,
      waitingForPayload,
    } = this.props;

    return (
      <ExtendedFlex
        flexDirection="column"
        align="center"
        bg="gray.0"
        w={1}
      >
        {
          !topic &&
          <ScrollToTopOnMount />
        }
        <Hero
          image={hero}
          header="RealtyMogul Knowledge Center"
        />
        {
          topic &&
          <ScrollToSpanOnMount />
        }
        <Filters
          topics={itemTopics}
          updateSelectedFilterValue={this.updateSelectedFilterValue}
          selectedCategory={selectedCategory}
        />

        <Cards
          items={items}
          addPadding={!nextUrl}
        />

        {
          nextUrl && !waitingForPayload &&
          <ShowMore
            handleClick={this.showMoreClickHandler}
            title="Show More"
          />
        }
        {
          waitingForPayload &&
          <ExtendedBox
            pt={80}
            pb={80}
            position="relative"
          >
            <Loader />
          </ExtendedBox>
        }
        <QueueFAQItems
          title="Popular Questions"
          queueName="popular_questions"
        />
        <Callouts />
        <UnlockOpportunities isLoggedIn={user.isLoggedIn} />
      </ExtendedFlex>
    );
  }
}

const mapStateToProps = (state) => ({
  items: state.knowledgeCenter.items,
  itemTopics: state.knowledgeCenter.itemTopics,
  selectedCategory: state.knowledgeCenter.selectedCategory,
  user: state.knowledgeCenter.user,
  nextUrl: state.knowledgeCenter.nextUrl,
  waitingForPayload: state.knowledgeCenter.waitingForPayload,
});

const mapDispatchToProps = (dispatch) => ({
  getMoreArticlesRequested: (url) => {
    dispatch(actions.getMoreArticlesRequested(url));
  },
  updateKnowledgeCenterFilterRequested: (category) => {
    dispatch(actions.updateKnowledgeCenterFilterRequested(category));
  },
});

KnowledgeCenter.propTypes = {
  items: PropTypes.array,
  itemTopics: PropTypes.array,
  getMoreArticlesRequested: PropTypes.func,
  updateKnowledgeCenterFilterRequested: PropTypes.func,
  selectedCategory: PropTypes.string,
  user: PropTypes.object,
  nextUrl: PropTypes.string,
  waitingForPayload: PropTypes.bool,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KnowledgeCenter));
