import React from 'react';
import {
  Body,
  Heading,
  Link,
  ExtendedBox,
} from '../coreui';

const FourOhOne = () => {
  return (
    <ExtendedBox
      maxWidth={1440}
      maxHeight={674}
      height={['32vh', '50vh']}
      px="10%"
      m="auto"
      mt={[25, 70, 90, 110]}
      pb={[25, 70, 90, 150]}
    >
      <Heading
        type="H2"
        color="blue.1"
        textTransform="uppercase"
        display="block"
        textAlign="left"
      >
        401: Unauthorized.
      </Heading>
      <Body type="B1">
        You must log in to see this content.
      </Body>
      <ExtendedBox
        mt={80}
        mb={80}
      >
        <Link
          href="/user/login"
          type="L1"
          textTransform="uppercase"
          display="inline-block"
        >
          Sign In
        </Link>
        <Link
          to="/user/register"
          type="L3"
          textTransform="uppercase"
          display="inline-block"
          ml={40}
        >
          Join Now
        </Link>
      </ExtendedBox>
    </ExtendedBox>
  );
};

export default FourOhOne;
