import { injectGlobal } from 'styled-components';
import styledNormalize from 'styled-normalize';

// global css; only add to this if there are no other options
// Note regarding `.slick-dots` stuff:
//   margin was done for design to pull dots together more than default CSS
//   li button stuff avoids bug with default `:focus` looking continually active
//
// Note regarding h2 and below:
//   This is styling for html that comes over from Drupal for KC
export const globalCSS = injectGlobal`
  @import url("https://use.typekit.net/zuh8ztw.css");
  @import url("https://cloud.typography.com/7455036/7907792/css/fonts.css");
  @import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
  @import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");
  @import url("https://unpkg.com/react-select@1.2.1/dist/react-select.css");
  @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600;900&display=swap');
  ${styledNormalize}
  html {
    --color-rm-main: #176DB6;
    --color-rm-secondary: #115288;
    --color-text-black: #202020;
    --color-text-gray: #767676;
    --color-text-white: #FFF;
    --color-red: #B1392A;
    --color-background-gray: #F2F2F2;
    
    --font-serif: mrs-eaves-xl-serif-narrow, serif;
    --font-sans: Gotham SSm A, Gotham SSm B, sans-serif;
    --font-size-sm: 12px;
    --letter-height-sm: 14px;

    --min-tap-target-height: 50px;
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-dots li button:focus:before {
    opacity: 0.25;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
  }
`;

const colors = {
  orange: '#E9573E',
  white: '#FFFFFF',
  black: '#202020',
  // TODO: Post merging all projects into 1 repository,
  // do a grep and replace all gray.X that have been
  // deprecated
  gray: Object.values({
    0: '#F8F8F8',
    1: '#F2F2F2',
    2: 'pink', // #808285 deprecated
    3: 'pink', // #434A54 deprecated
    4: 'pink', // #414042 deprecated
    5: 'pink', // #A7A9AC deprecated
    6: 'pink', // #E6E7E8 deprecated
    7: '#777777',
    8: '#202020',
    9: '#171717',
    10: '#9b9b9b',
    11: 'pink', //  #bebebe deprecated
    12: '#ededed',
  }),
  blue: Object.values({
    0: '#176DB6', // #4B89DC deprecated
    1: '#176DB6',
    2: '#A4C2D5',
    3: '#1D89E5',
  }),
  transparent: 'transparent',
  red: '#B1392B',
};

const fontSizes = [
];

const breakpoints = Object.values({
  0: '640px',
  1: '832px',
  2: '1024px',
  3: '1920px',
});

const space = [
  0,
  1,
  2,
  3,
  4,
  5,
];

const fontWeights = {
  extraLight: 200,
  light: 300,
  book: 400,
  medium: 500,
  bold: 700,
};

const borders = [
  0,
  '1px solid',
];

const letterSpacings = {
  normal: 'normal',
  large: '0.1em',
  xlarge: '0.2em',
};

const radii = Object.values({
  0: 0,
  1: 2,
  2: 4,
  3: 8,
});

const lineHeights = Object.values({
  0: 1,
  1: 1.125,
  2: 1.25,
  3: 1.5,
});

const fontFamily = {
  serif: 'mrs-eaves-xl-serif-narrow, serif',
  sansSerif: '"Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif',
};

const fonts = {
  ...fontFamily,
};

const fadeInDelay = 75;
const transition = 500;

const Theme = {
  colors,
  fontSizes,
  breakpoints,
  space,
  fontWeights,
  borders,
  letterSpacings,
  radii,
  lineHeights,
  fonts,
  fontFamily,
  fadeInDelay,
  transition,
};

export default Theme;
