import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactHtmlParser from 'react-html-parser';
import RMButton from '../RMButton';
import { Accordion } from '../../coreui';

import Styled from './index.styled';
import DLIcon from '../../assets/icon_download.svg';

const PDFItem = ({
  label,
  link,
}) => (
  <Styled.PDFItem>
    <span>{label}</span>
    <div className="spacer" />
    <a href={link} target="_blank" rel="noopener noreferrer"><img src={DLIcon} alt="download PDF" />  Download PDF</a>
  </Styled.PDFItem>
);

const PDFSlide = ({ items }) => {
  return items.map((item) => <PDFItem key={item.label} label={item.label} link={item.link} />);
};

const transformFunction = (node) => {
  if (node.type === 'style') return null;
  return undefined;
};

const TeamSlide = ({ content }) => (
  <Styled.TeamSlide>
    {ReactHtmlParser(content, {
      transform: transformFunction,
    })}
  </Styled.TeamSlide>
);

const MapSlide = ({ img, items }) => (
  <Styled.MapSlide>
    <Styled.Column>
      <img src={img} alt="Property Map" />
    </Styled.Column>
    <Styled.Column>
      {items.map((item) => (
        <Styled.Breakdown>
          <Styled.Swatch background={item.swatchColor} />
          <Styled.BreakdownCopy>
            <h2>{item.header}</h2>
            <p>{item.copy}</p>
          </Styled.BreakdownCopy>
        </Styled.Breakdown>
      ))}
    </Styled.Column>
  </Styled.MapSlide>
);

const Slide = ({ tab }) => {
  switch (tab.type) {
    case 'PDF':
      return <PDFSlide items={tab.items} />;
    case 'TEAM':
      return <TeamSlide content={tab.content} />;
    case 'FAQ':
      return <Accordion items={tab.items} contentIsHtml isReitLP />;
    case 'MAP':
      return <MapSlide img={tab.img} items={tab.items} />;
    default:
      return null;
  }
};

const ReitTabs = ({
  background = '#FFF',
  title = 'Overview',
  tabs = [],
  cta,
}) => {
  const [fadeOut, setFadeOut] = useState(true);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setFadeOut(false);
        } else setFadeOut(true);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref]);

  return (
    <Styled.ReitTabs background={background}>
      <Styled.Container>
        <h1>{title}</h1>
        <Tabs>
          <TabList>
            <Styled.FadeOut fadeOut={fadeOut} />
            {
              tabs.map((tab, index) => <Tab key={tab.title}><div ref={index + 1 === tabs.length ? ref : null} />{tab.title}</Tab>)
            }
          </TabList>
          {
            tabs.map((tab) => <TabPanel key={tab.title}><Styled.Slide><Slide tab={tab} /></Styled.Slide></TabPanel>)
          }
        </Tabs>
        {
          cta &&
          <Styled.ButtonContainer>
            <RMButton href={cta.href} styleType={cta?.type ? cta.type : 'Primary'} target="_blank">{cta.label}</RMButton>
          </Styled.ButtonContainer>
        }
      </Styled.Container>
    </Styled.ReitTabs>
  );
};

ReitTabs.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string,
  tabs: PropTypes.array,
  cta: PropTypes.object,
};

PDFItem.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
};

TeamSlide.propTypes = {
  content: PropTypes.string,
};

MapSlide.propTypes = {
  img: PropTypes.string,
  items: PropTypes.array,
};

Slide.propTypes = {
  tab: PropTypes.object,
};

export default ReitTabs;
