import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
} from '../../coreui';

import Tab from './components/tab';
import FadeIn from '../FadeIn';

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.onClickTabItem = this.onClickTabItem.bind(this);
    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }

  onClickTabItem(tab) {
    this.setState({ activeTab: tab });
    if (this.props.isNew) this.props.setTab(tab);
  }

  render() {
    const {
      onClickTabItem,
      props: {
        children,
        isNew = false,
      },
      state: {
        activeTab,
      },
    } = this;

    return (
      <ExtendedBox
        w={1}
      >
        <ExtendedFlex
          align="center"
          justify="center"
          pb={[27, 32, 48, 64]}
          px={[20, 50]}
          justifyContent="center"
          width={[1, 'auto']}
        >
          {children.map((child) => {
            const { label } = child.props;
            return (
              <Tab
                activeTab={activeTab}
                label={label}
                onClick={onClickTabItem}
                isNew={isNew}
              />
            );
          })}
        </ExtendedFlex>
        <ExtendedBox>
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return (
              <FadeIn>
                <ExtendedBox>
                  {child.props.children}
                </ExtendedBox>
              </FadeIn>
            );
          })}
        </ExtendedBox>
      </ExtendedBox>
    );
  }
}

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  isNew: PropTypes.bool,
  setTab: PropTypes.func,
};

export default Tabs;
