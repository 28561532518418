/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  ExtendedFlex,
  StackableCallout,
} from '../../coreui';

import callouts from './data';

const Callouts = () => {
  return (
    <ExtendedFlex
      w={1}
      bg="white"
      justify="center"
      pt={40}
      pb={80}
    >
      <ExtendedFlex
        w={1}
        flexDirection={['column', 'column', 'column', 'row']}
        justify="center"
        align="center"
        maxWidth={1400}
      >
        {
          callouts.map(
            (callout) => {
              return (
                <ExtendedFlex
                  key={callout.header}
                  align="center"
                  flexDirection="column"
                >
                  <StackableCallout
                    w={1}
                    {...callout}
                  />
                </ExtendedFlex>
              );
            },
          )
        }
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

export default Callouts;
