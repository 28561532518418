/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Image,
  ExtendedBox,
} from '..';
import icon from '../assets/tooltip_icon.svg';

const TooltipIcon = ({
  tooltipId,
  position,
  top,
  right,
  bottom,
  left,
  mt,
  mr,
  mb,
  ml,
  ...passedProps
}) => {
  const size = [13, 13, 16, 16, 16];
  return (
    <ExtendedBox
      position={position}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      {...passedProps}
    >
      <span>
        <Image
          {...(tooltipId ? {
            'data-for': tooltipId,
            'data-tip': true,
            'data-event': 'touchstart focus mouseover',
            'data-event-off': 'mouseout',
          } : {})}
          src={icon}
          w={size}
          height={size}
          mt={mt}
          mr={mr}
          mb={mb}
          ml={ml}
          hover
        />
      </span>
    </ExtendedBox>
  );
};

TooltipIcon.propTypes = {
  tooltipId: PropTypes.string,
  position: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  mr: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
  ]),
  ml: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
  ]),
  mb: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
  ]),
  mt: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
  ]),
};

TooltipIcon.defaultProps = {
  mt: 0,
  mr: 0,
  mb: 0,
  ml: 0,
};

export default TooltipIcon;
