import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PrimaryLinks from '../ioDetails/components/primaryLinks';
import ReitDescription from '../components/ReitDescription';
import StartWidget from '../components/ReitDescription/StartWidget';
import ReitOverview from '../components/ReitOverview';
import ReitSection from '../components/ReitSection';
import ReitTabs from '../components/ReitTabs';
import ReitHistorical from '../components/ReitHistorical';
import ReitPortfolio from '../components/ReitPortfolio';
import ReitRiskFactors from '../components/ReitRiskFactors';

import MRIGraph from '../assets/income_reit_graph.png';

import { createData } from './_data';

import {
  ExtendedFlex,
  Loader,
} from '../coreui';

const Loading = () => (
  <ExtendedFlex
    position="relative"
    minHeight={600}
  >
    <Loader />
  </ExtendedFlex>
);

const Reit = ({
  io,
  faqItems,
  highlights,
  historical,
  user,
  details,
}) => {
  const [reitData, setReitData] = useState(null);

  useEffect(() => {
    if (io && faqItems) {
      setReitData(createData({ io, faqItems, highlights, historical, details }));
    }
  }, [io, faqItems, highlights, historical, details]);

  return reitData ? (
    <>
      {
        user && user.isStaff &&
        <PrimaryLinks
          items={[
            { path: `/node/${io.id}/edit`, text: 'Edit' },
            { path: `/node/${io.id}/salesforce_activity`, text: 'Salesforce Activity' },
            { path: `/node/${io.id}/documents`, text: 'Documents' },
            { path: `/node/${io.id}/orders`, text: 'orders' },
          ]}
        />
      }
      <ReitDescription
        isLoggedIn={user.isLoggedIn}
        id={io.id}
        {...reitData.description}
      />
      <ReitOverview
        {...reitData.overview}
      />
      {
        io.id === 193744 &&
        <ReitSection>
          <h1>What would happen if you invested $10k?</h1>
          <p>If you invested $10,000 in the RealtyMogul Income REIT at inception and reinvested all of your monthly distributions, your investment would now be worth $16,647:</p>
          <img src={MRIGraph} alt="invest chart" style={{ margin: '60px auto', display: 'block', width: '100%' }} />
          <p style={{ margin: '0 auto', textAlign: 'center' }}>
            Based on a $10,000 initial investment in 2016<sup>4</sup>
          </p>
        </ReitSection>
      }
      <ReitTabs
        {...reitData.resources}
      />
      <ReitHistorical
        {...reitData.historical}
      />
      <ReitTabs
        {...reitData.portfolioBreakdown}
      />
      <ReitPortfolio
        assets={io.assets}
        {...reitData.portfolio}
      />
      <ReitRiskFactors
        {...reitData.riskFactors}
      />
      {
        io.id === 193744 &&
        <div style={{ position: 'relative', paddingTop: '50px' }}>
          <div style={{ position: 'absolute', top: '-300px', left: '50%', transform: 'translate(-50%, 100%)', width: '100%', fontFamily: '"Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif' }}>
            <StartWidget />
          </div>
          <ReitSection>
            <p style={{ fontSize: '11px' }}>
              1.  As with all investments, past performance does not guarantee future results and there is no guarantee that the REIT will make distributions.
            </p>
            <p style={{ fontSize: '11px' }}>
              2.  September 30, 2021 compared to September 30, 2020.
            </p>
            <p style={{ fontSize: '11px' }}>
              3.  Aggregate value of all underlying properties in RealtyMogul Income REIT, LLC is based on the most recent internal valuations as of June 30, 2021 pursuant to our valuation policies, provided, however, the property value of investments made since that date is based on the most recent purchase price . The aggregate value of the properties underlying loans made by RealtyMogul Income REIT, LLC is based on independent appraisals dated within six months of the original acquisition dates by RM Adviser, Realty Mogul, Co. or Realty Mogul Commercial Capital, Co., as applicable. As with any methodology used to estimate value, the methodology employed by our affiliates’ internal accountants or asset managers is based upon a number of estimates and assumptions about future events that may not be accurate or complete. For more information, see the “Description of Our Common Stock – Valuation Policies” section of our offering circular.
            </p>
            <p style={{ fontSize: '11px' }}>
              4.  These hypothetical case studies are provided for illustrative purposes only and do not represent an actual investor or an actual investor’s experience, but rather are meant to provide an example of the REIT’s process and methodology. An individual’s experience may vary based on his or her individual circumstances. There can be no assurance that the REIT will be able to achieve similar results in comparable situations. Hypothetical returns are net of advisory fees and transaction costs; all dividends are assumed to be reinvested annually. Actual returns may differ materially from hypothetical returns. Hypothetical returns are from RealtyMogul Income REIT inception date thru March 31, 2022. There is no substitute for actual returns. Past hypothetical performance is not a guarantee of future returns.
            </p>
          </ReitSection>
        </div>
      }
    </>
  )
    : <Loading />;
};

const mapStateToProps = (state) => ({
  io: state.reit.io,
  faqItems: state.reit.faqItems,
  highlights: state.reit.highlights,
  historical: state.reit.historical,
  details: state.reit.details,
  user: state.app.user,
});

Reit.propTypes = {
  io: PropTypes.object,
  faqItems: PropTypes.array,
  highlights: PropTypes.object,
  historical: PropTypes.object,
  details: PropTypes.array,
  user: PropTypes.object,
};

export default withRouter(connect(mapStateToProps)(Reit));
