import React from 'react';
import PropTypes from 'prop-types';
import Styled from './index.styled';

const ReitSection = (props) => {
  return (
    <Styled.ReitSection background={props.background}>
      <Styled.Container>
        {props.children}
      </Styled.Container>
    </Styled.ReitSection>
  );
};

ReitSection.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node,
};

export default ReitSection;
