import React from 'react';
// import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { ReitTabs, Accordion } from 'rtmg-component-library';

const buildFAQTabs = (data) => {
  const mr1 = {
    title: 'INCOME REIT',
    content: data[0].map((item) => {
      return (
        <div style={{ marginBottom: '10px' }}>
          <Accordion title={item.header} copy={ReactHtmlParser(item.content)} />
        </div>
      );
    }),
  };
  const mr2 = {
    title: 'APARTMENT GROWTH REIT',
    content: data[1].map((item) => {
      return (
        <div style={{ marginBottom: '10px' }}>
          <Accordion title={item.header} copy={ReactHtmlParser(item.content)} />
        </div>
      );
    }),
  };
  return [mr1, mr2];
};

export const FAQs = ({
  faqItems = [],
}) => {
  return (
    <>
      {
        faqItems.length > 0 &&
        <ReitTabs title="FAQS" background="#F7F8F8" tabs={buildFAQTabs(faqItems)} />
      }
    </>
  );
};

FAQs.propTypes = {
  faqItems: PropTypes.array,
};

export default FAQs;
