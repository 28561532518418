import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  ExtendedFlex,
  Formatters,
} from '../../coreui';

import SummaryItem from './SummaryItem';

const PendingSummary = ({ data }) => {
  if (data === undefined) {
    return null;
  }
  const totalPending = data.pendingData.totalInvested !== undefined ?
    data.pendingData.investments.reduce((accum, item) => {
      return accum + item.amount;
    }, 0) : undefined;

  const totalPledged = data.pledgedData.totalInvested !== undefined ?
    data.pledgedData.investments.reduce((accum, item) => {
      return accum + item.amount;
    }, 0) : undefined;

  return (
    <ExtendedFlex
      flexDirection="column"
      width={1}
      minWidth={280}
      maxWidth={['75%', 1540]}
      px={[0, 50]}
      pb={[40, 50]}
    >
      <ExtendedFlex
        flexDirection={['column', 'column', 'column', 'row', 'row']}
        justifyContent="center"
        alignItems="center"
        w={1}
      >
        {
          data.pendingData.totalInvested !== undefined &&
          <SummaryItem
            value={totalPending}
            label={data.pendingData.totalInvested.label}
            dataType="D8"
            valueFormat={Formatters.DOLLARS_DECIMALS}
            tooltipText={data.pendingData.totalInvested.tooltip}
          />
        }
        <Box m={8} mx={[0, 0, 0, 16, 24]} />
        {
          data.pledgedData.totalInvested !== undefined &&
          <SummaryItem
            value={totalPledged}
            label={data.pledgedData.totalInvested.label}
            dataType="D8"
            valueFormat={Formatters.DOLLARS_DECIMALS}
            tooltipText={data.pledgedData.totalInvested.tooltip}
          />
        }
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

PendingSummary.propTypes = {
  data: PropTypes.object,
};

export default PendingSummary;
