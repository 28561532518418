import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';
import {
  ExtendedBox,
  ExtendedFlex,
  Text,
} from '../coreui';
import withPortal from '../withPortal';

import actions from './actions';

import FooterSection1 from './components/FooterSection1';
import FooterSection2 from './components/FooterSection2';
import FooterSection3 from './components/FooterSection3';
import { transformMenuItem } from '../header/helpers';

const footerRoot = typeof document !== 'undefined' ? document.getElementById('react-footer') : null;

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLimitedNav: false,
    };

    this.setNavTypes = this.setNavTypes.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.getFooterDataRequested();
    this.setNavTypes(this.props.location.pathname);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setNavTypes(nextProps.location.pathname);
    }
  }

  componentDidMount() {
    // eslint-disable-next-line
    if (window && window.Drupal?.settings?.rm_invest_flow?.is1031) {
      this.is1031 = true;
    }
  }

  setNavTypes(pathname) {
    const limitedPaths = ['/invest/', '/user/login', '/user/password', '/user/register', '/sharerepurchaserequest'];

    const isLimitedNav = limitedPaths.reduce((accum, item) => {
      return pathname.includes(item) ? true : accum;
    }, false);

    this.setState({
      isLimitedNav,
    });
  }

  render() {
    const { items: propItems, socialMediaItems, isApp, frontendPrefix } = this.props;
    const { isLimitedNav } = this.state;

    const items = propItems
      .map((item) => ({
        ...item,
        children: item.children.length > 0
          ? item.children.map((item) => ({ ...item, ...transformMenuItem(item, { isApp, frontendPrefix }) }))
          : item.children,
      }));

    return (
      <ExtendedFlex
        bg="gray.9"
        w={1}
        align="center"
        justify="center"
        px={['10%']}
        py={isLimitedNav ? 0 : '5%'}
        id="rm-footer"
      >
        <ExtendedBox w={1} maxWidth={1600}>
          <ExtendedFlex
            flexDirection={['column', 'column', 'row', 'row', 'row']}
            display={isLimitedNav ? 'none' : 'flex'}
            w={1}
            mb={[40, 40, 0]}
          >
            <FooterSection1 items={items} isApp={isApp} frontendPrefix={frontendPrefix} />
            <FooterSection2 items={socialMediaItems} />
          </ExtendedFlex>
          <FooterSection3
            isLimitedNav={isLimitedNav}
            isApp={isApp}
            frontendPrefix={frontendPrefix}
            is1031={this.is1031}
          />
          <Text
            color="#9b9b9b"
            fontFamily="sansSerif"
            fontSize={[11]}
            lineHeight={[1.82]}
            m={0}
            hoverColor="white"
          >{!this.is1031 ? (
            <span>
              * Information in this message, including information regarding
              targeted returns and investment performance, is provided by the
              sponsor of the investment opportunity and is subject to change.
              Forward-looking statements, hypothetical information or
              calculations, financial estimates and targeted returns are
              inherently uncertain. Such information should not be used as a
              primary basis for an investor’s decision to invest. Investment
              opportunities on the RealtyMogul Platform are speculative and
              involve substantial risk. You should not invest unless you can
              sustain the risk of loss of capital, including the risk of total
              loss of capital. Please see additional disclosures{' '}
              <a
                href="https://www.realtymogul.com/investment-disclosure"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#FFF' }}
              >
                here.
              </a>
            </span>
          ) : (
            <div>
              <p>By accessing this site and any pages thereof (the “Platform”), you agree to be bound by our <a href="https://www.realtymogul.com/terms-service" rel="noreferrer" target="_blank" style={{ color: '#FFF' }}>Terms of Service</a> and <a href="https://www.realtymogul.com/privacy-policy" rel="noreferrer" target="_blank" style={{ color: '#FFF' }}>Privacy Policy</a>.
              </p>
              <p>Brokerage services are offered by RM Securities, LLC, an SEC-registered broker-dealer (member <a href="https://www.finra.org/" rel="noreferrer" target="_blank" style={{ color: '#FFF' }}>FINRA</a>/<a href="https://www.sipc.org/" rel="noreferrer" target="_blank" style={{ color: '#FFF' }}>SIPC</a>). Research RM Securities, LLC, on <a href="https://brokercheck.finra.org/" rel="noreferrer" target="_blank" style={{ color: '#FFF' }}>FINRA’s BrokerCheck</a>. <b>Please note, brokerage services are currently not available in all states and are only offered in those states in which RM Securities, LLC is registered. Brokerage services are not intended for or directed to, nor will they be provided to, residents in any other state or otherwise where the provision of such services would violate any state law.</b></p>
              <p>RM Adviser, LLC, a wholly owned subsidiary of RealtyMogul, Co., is an SEC-registered investment adviser and an affiliate of Realty Mogul, Co. and RM Securities, LLC.  RM Adviser, LLC provides investment management services exclusively to certain REITs and single purpose funds and does not provide investment advisory services to the Platform’s users.</p>
              <p>Please consider your objectives before investing. Past performance is not indicative of future results. Forward-looking statements, hypothetical information or calculations, financial estimates, assumptions, or estimates are inherently uncertain. Such information should not be used as a primary basis for an investor’s decision to invest. Privately offered securities offered by RM Securities, LLC on the Platform, including investments in real estate, are speculative, highly illiquid, and involve substantial risk. You should not invest unless you can sustain the risk of loss of capital, including the risk of total loss of capital.</p>
              <p>All information contained on the Platform, or in any advertisement linking to the Platform, concerning any prospective or actual real estate investment, including any forward-looking statements, hypothetical information or calculations, financial estimates, assumptions or estimates, is provided by the relevant sponsor and/or issuer (or their affiliates) (“Sponsor”) of the relevant real estate investment opportunity and not by Realty Mogul, Co., RM Securities, LLC, or any of its affiliates, and is subject to change. Such information is subject to the various disclosures on the Platform and is qualified in its entirety by the more comprehensive information provided in the relevant Sponsor’s offering materials, which contain important information about the product’s investment objectives, business plan, risks, charges, expenses, and other information which should be considered carefully before investing. Unless affirmatively and specifically stated to the contrary, Realty Mogul, Co., RM Securities, LLC, and their affiliates make no representations or warranties as to the accuracy of a Sponsor’s information, including in advertisements, and accepts no liability therefor.</p>
              <p>
                <b>Advice from a securities professional is strongly advised, and we recommend that you consult with a financial advisor, attorney, accountant, and any other professional that can help you to understand and assess the risks associated with any real estate investment and privately offered securities.
                </b>
              </p>
            </div>
          )}
          </Text>
        </ExtendedBox>
      </ExtendedFlex>
    );
  }
}

const mapStateToProps = (state) => ({
  items: state.footer.items,
  socialMediaItems: state.footer.socialMediaItems,
  isApp: state.app.isApp,
  frontendPrefix: state.app.frontendPrefix,
});

const mapDispatchToProps = (dispatch) => ({
  getFooterDataRequested: () => {
    dispatch(actions.getFooterDataRequested());
  },
});

Footer.propTypes = {
  getFooterDataRequested: PropTypes.func,
  items: PropTypes.array,
  socialMediaItems: PropTypes.array,
  location: PropTypes.object,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

export default withPortal(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer)),
  footerRoot,
);
