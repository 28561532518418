import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import numeral from 'numeral';

import {
  ScrollToTopOnMount,
  ExtendedFlex,
  Heading,
  Link,
  Body,
  StyledButton,
  ModalWithOverlay,
  Formatters,
  InlineTextLink,
} from '../coreui';

import RedirectToLoginOnLoggedOut from '../components/RedirectToLoginOnLoggedOut';
import DashboardLoader from '../dashboard/components/DashboardLoader';
import ShareRepurchaseForm from './components/ShareRepurchaseForm';
import UnderstandingShareRepurchaseRates from './components/UnderstandingShareRepurchaseRates';
import ShareRepurchaseAgreements from './components/ShareRepurchaseAgreements';
import ShareRepurchaseConfirmation from './components/ShareRepurchaseConfirmation';

import actions from './actions';
import messageActions from '../dsm/actions';

const ShareRepurchase = ({
  loaded,
  getShareRepurchaseApiData,
  postShareRepurchaseApiData,
  workflows,
  daysPriorEndOfQuarter,
  daysProcessedEndOfQuarter,
  addStatusMessage,
  repurchaseRequestErrors,
}) => {
  useEffect(getShareRepurchaseApiData, [getShareRepurchaseApiData]);

  // Flow control.
  const [step, setStep] = useState('form');
  const [showCancelModal, setShowCancelModal] = useState();
  const [submitting, setSubmitting] = useState();
  const [hasNoWorkflows, setHasNoWorkflows] = useState();
  const [hasNoAdditionalWorkflows, setHasNoAdditionalWorkflows] = useState();

  // Form data.
  const [payload, setPayload] = useState({});

  // Repurchase Request Payload.
  const [investmentOpportunity, setInvestmentOpportunity] = useState();
  const [foe, setFoe] = useState();
  const [sharesPercent, setSharesPercent] = useState();
  const [quarter, setQuarter] = useState();
  const [year, setYear] = useState();
  const [repeat, setRepeat] = useState();

  // Derived variables based on selections.
  const [options, setOptions] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [
    investmentOpportunityLabel,
    setInvestmentOpportunityLabel,
  ] = useState();
  const [currentAccountLabel, setAccountLabel] = useState();
  const [currentAccountTypeLabel, setAccountTypeLabel] = useState();
  const [percentLimit, setPercentLimit] = useState();
  const [percentLimitFormatted, setPercentLimitFormatted] = useState();
  const [offeringCircular, setOfferingCircular] = useState();
  const [eligibleShares, setEligibleShares] = useState();

  // Set up the options.
  useEffect(() => {
    const options = [
      ...new Map(
        workflows
          .filter(({ hasActiveWorkflow }) => !hasActiveWorkflow)
          .map(({ io: { id, label } }) => [id, { value: id, label }]),
      ).values(),
    ];
    setOptions(options);
    setHasNoWorkflows(loaded && workflows.length === 0);
    setHasNoAdditionalWorkflows(loaded && workflows.length > 0 && options.length === 0);
  }, [workflows, loaded]);

  // Set up the accounts.
  useEffect(() => {
    const options = [
      ...new Map(
        workflows
          .filter(
            (workflow) => `${workflow.io.id}` === `${investmentOpportunity}`,
          )
          .map(({ foe }) => [
            foe.id,
            { value: foe.id, label: `${foe.title} - ${foe.type.label}` },
          ]),
      ).values(),
    ];
    setAccounts(options);
  }, [workflows, investmentOpportunity]);

  // Set additional values based on the IO selected.
  useEffect(() => {
    const values = workflows.filter(
      (workflow) => `${workflow.io.id}` === `${investmentOpportunity}`,
    );
    const { percentLimit, offeringCircular } = values?.[0]?.io || {};
    const percentLimitFormatted =
      (percentLimit && numeral(percentLimit).format(Formatters.PERCENTAGE)) ||
      '';
    setPercentLimit(percentLimit);
    setPercentLimitFormatted(percentLimitFormatted);
    setOfferingCircular(offeringCircular);
  }, [workflows, investmentOpportunity]);

  // Set additional values based on the IO selected and account.
  useEffect(() => {
    let quarter;
    let year;

    const currentWorkflow = workflows.filter(
      (workflow) => `${workflow.io.id}` === `${investmentOpportunity}` &&
        `${workflow.foe.id}` === `${foe}`,
    )?.[0];

    if (currentWorkflow) {
      const {
        io: { label: investmentOpportunityLabel },
        foe: {
          title: currentAccountLabel,
          type: { label: currentAccountTypeLabel },
        },
      } = currentWorkflow || {};
      setInvestmentOpportunityLabel(investmentOpportunityLabel);
      setAccountLabel(currentAccountLabel);
      setAccountTypeLabel(currentAccountTypeLabel);
    }

    setEligibleShares(currentWorkflow?.eligibleShares);
    const sortedInvestments = currentWorkflow?.investments
      ?.filter(({ shares }) => shares > 0)
      ?.sort((a, b) => {
        if (a.tradeDate === b.tradeDate) return 0;
        return a.tradeDate > b.tradDate ? 1 : -1;
      });
    if (sortedInvestments && sortedInvestments.length) {
      const {
        quarter: nextEligibleQuarter,
        year: nextEligibleYear,
      } = sortedInvestments[0].nextEligible;
      quarter = nextEligibleQuarter;
      year = nextEligibleYear;
    }
    setQuarter(quarter);
    setYear(year);
  }, [workflows, investmentOpportunity, foe]);

  return (
    <ExtendedFlex
      flexDirection="column"
      justifyContent="center"
      width="100%"
      alignItems="center"
      pb={140}
      {...(!loaded
        ? {
          justifyContent: 'flex-start',
          minHeight: [650, 700, 900, 1000, '100vh'],
          position: 'relative',
        }
        : {})}
    >
      <ScrollToTopOnMount />
      <RedirectToLoginOnLoggedOut />
      <DashboardLoader loaded={loaded}>
        {step === 'form' && (
          <ShareRepurchaseForm
            options={options}
            accounts={accounts}
            investmentOpportunity={
              investmentOpportunity ? `${investmentOpportunity}` : undefined
            }
            account={foe ? `${foe}` : undefined}
            sharesPercent={sharesPercent}
            percentLimit={percentLimit}
            percentLimitFormatted={percentLimitFormatted}
            quarter={quarter}
            year={year}
            eligibleShares={eligibleShares}
            repeat={repeat}
            offeringCircular={offeringCircular}
            handelCancelRequest={() => {
              setShowCancelModal(true);
            }}
            handleSetInvestmentOpportunity={setInvestmentOpportunity}
            handleSetFoe={setFoe}
            handleSetRepeat={setRepeat}
            handleSetSharesPercent={setSharesPercent}
            daysPriorEndOfQuarter={daysPriorEndOfQuarter}
            daysProcessedEndOfQuarter={daysProcessedEndOfQuarter}
            handleFormSubmit={(e) => {
              e.preventDefault();
              const payload = {
                investmentOpportunity,
                foe,
                repeat,
                sharesPercent,
                year,
                quarter,
              };
              setPayload(payload);
              setStep('rates');
            }}
          />
        )}
        {step === 'rates' && (
          <UnderstandingShareRepurchaseRates
            handelCancelRequest={() => {
              setShowCancelModal(true);
            }}
            handleFormSubmit={() => {
              setStep('agreements');
            }}
          />
        )}
        {step === 'agreements' && (
          <ShareRepurchaseAgreements
            offeringCircular={offeringCircular}
            percentLimitFormatted={percentLimitFormatted}
            daysProcessedEndOfQuarter={daysProcessedEndOfQuarter}
            submitting={submitting}
            repurchaseRequestErrors={repurchaseRequestErrors}
            handelCancelRequest={() => {
              setShowCancelModal(true);
            }}
            handleFormSubmit={(e) => {
              e.preventDefault();

              new Promise((resolve, reject) => {
                setSubmitting(true);
                postShareRepurchaseApiData(payload, {
                  resolve,
                  reject,
                });
              })
                .then(() => {
                  setStep('confirmation');
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          />
        )}
        {step === 'confirmation' && (
          <ShareRepurchaseConfirmation
            investmentOpportunityLabel={investmentOpportunityLabel}
            currentAccountTypeLabel={currentAccountTypeLabel}
            currentAccountLabel={currentAccountLabel}
            sharesPercentFormatted={numeral(sharesPercent).format(
              Formatters.PERCENTAGE,
            )}
            repeat={repeat}
          />
        )}
        <ModalWithOverlay isVisible={hasNoWorkflows} showClose={false}>
          <ExtendedFlex
            flexDirection="column"
            align="center"
            m={[null, null, 30]}
            data-test="confirm-cancel-popup"
          >
            <Heading type="H3" textTransform="uppercase">
              You are currently ineligible for the share repurchase program
            </Heading>
            <Body type="B1" textAlign="center">
              Shares held for one year may be eligible for repurchase. Please
              note that repurchase requests must be made {daysPriorEndOfQuarter}{' '}
              days prior to the end of the applicable quarter. If you have any
              questions regarding your eligibility, feel free to contact us at{' '}
              <InlineTextLink href="mailto:sharerepurchaserequests@realtymogul.com">
                ShareRepurchaseRequests@realtymogul.com
              </InlineTextLink>
              .
            </Body>
            <ExtendedFlex
              w={1}
              my={30}
              justify="center"
              align="center"
              flexDirection={['column', null, 'row']}
            >
              <Link type="L4" to="/investor-dashboard" mt={[30, null, 0]}>
                Back to my dashboard
              </Link>
            </ExtendedFlex>
          </ExtendedFlex>
        </ModalWithOverlay>
        <ModalWithOverlay
          isVisible={hasNoAdditionalWorkflows}
          showClose={false}
        >
          <ExtendedFlex
            flexDirection="column"
            align="center"
            m={[null, null, 30]}
            data-test="confirm-cancel-popup"
          >
            <Heading type="H3" textTransform="uppercase">
              There are no eligible shares left to request
            </Heading>
            <Body type="B1" textAlign="center">
              Our records indicate that you have previously submitted a share
              repurchase request. There is no further action required at this
              time. Please refer to your investor dashboard for the current
              status of your request(s). If you have any questions, feel free to
              contact us at{' '}
              <InlineTextLink href="mailto:sharerepurchaserequests@realtymogul.com">
                sharerepurchaserequests@realtymogul.com
              </InlineTextLink>
              .
            </Body>
            <ExtendedFlex
              w={1}
              my={30}
              justify="center"
              align="center"
              flexDirection={['column', null, 'row']}
            >
              <Link type="L4" to="/investor-dashboard" mt={[30, null, 0]}>
                Back to my dashboard
              </Link>
            </ExtendedFlex>
          </ExtendedFlex>
        </ModalWithOverlay>
        <ModalWithOverlay
          isVisible={showCancelModal}
          handleClick={() => {
            setShowCancelModal(false);
          }}
        >
          <ExtendedFlex
            flexDirection="column"
            align="center"
            m={[null, null, 30]}
            data-test="confirm-cancel-popup"
          >
            <Heading type="H3" textTransform="uppercase">
              Cancel share repurchase request?
            </Heading>
            <Body type="B1" textAlign="center" textTransform="uppercase">
              {investmentOpportunityLabel}: {currentAccountTypeLabel}:{' '}
              {currentAccountLabel}
            </Body>
            <ExtendedFlex
              w={1}
              my={30}
              justify="center"
              align="center"
              flexDirection={['column', null, 'row']}
            >
              <Link
                type="L4"
                to="/investor-dashboard"
                onClick={() => {
                  addStatusMessage({
                    message: 'Your Share Repurchase has been cancelled.',
                    type: 'status',
                  });
                }}
                mt={[30, null, 0]}
              >
                Yes, Confirm Cancellation
              </Link>
              <ExtendedFlex w="15%" display={['none', null, 'block']} />
              <StyledButton
                type="L4"
                color="gray.7"
                onClick={() => {
                  setShowCancelModal(false);
                }}
              >
                No, Back to page
              </StyledButton>
            </ExtendedFlex>
          </ExtendedFlex>
        </ModalWithOverlay>
      </DashboardLoader>
    </ExtendedFlex>
  );
};

ShareRepurchase.propTypes = {
  getShareRepurchaseApiData: PropTypes.func,
  postShareRepurchaseApiData: PropTypes.func,
  addStatusMessage: PropTypes.func,
  loaded: PropTypes.bool,
  workflows: PropTypes.array,
  daysPriorEndOfQuarter: PropTypes.number,
  daysProcessedEndOfQuarter: PropTypes.number,
  repurchaseRequestErrors: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    loaded: state.shareRepurchase.loaded,
    workflows: state.shareRepurchase.workflows,
    daysPriorEndOfQuarter: state.shareRepurchase.daysPriorEndOfQuarter,
    daysProcessedEndOfQuarter: state.shareRepurchase.daysProcessedEndOfQuarter,
    repurchaseRequestErrors: state.shareRepurchase.repurchaseRequestErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShareRepurchaseApiData: () => {
      dispatch(actions.shareRepurchaseListRequested());
    },
    postShareRepurchaseApiData: (data, promise) => {
      dispatch(actions.shareRepurchasePostRequested(data, promise));
    },
    addStatusMessage: (data) => {
      dispatch(messageActions.addStatusMessageRequested(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareRepurchase);
