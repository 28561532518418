import {
  takeEvery,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

export function* getDataFlow(action) {
  try {
    yield put(
      actions.addStatusMessageSucceeded(action.data),
    );
  } catch (error) {
    yield put(
      actions.addStatusMessageFailed(error),
    );
  }
}

export function* watchAddStatusMessageRequested() {
  yield takeEvery(
    types.ADD_STATUS_MESSAGE_REQUESTED,
    getDataFlow,
  );
}

export default watchAddStatusMessageRequested;
