/* eslint-disable */
import React from 'react';
import {
  Body,
  Heading,
  StyledButton,
  ExtendedBox,
  InlineTextLink,
} from '../coreui';

const ReactError = ({ error = '' }) => {
  function handleClick(e) {
    e.preventDefault();
    window.location.href = 'https://www.realtymogul.com/';
  }

  return (
    <ExtendedBox
      maxWidth={1440}
      px="10%"
      m="auto"
      mt={[25, 70, 90, 110]}
      pb={[25, 70, 90, 150]}
    >
      <Heading
        type="H2"
        color="blue.1"
        textTransform="uppercase"
        display="block"
        textAlign="left"
      >
        Something went wrong.
      </Heading>
      <Body
        type="B1"
        display="block"
      >
        <div style={{ backgroundColor: "#C2C2C2" }}>
          {error}
        </div>
      </Body>
      <Body type="B2">
        <p>
          Please email us with the above error code and a brief description of what you were doing when the error occurred.
        </p>
      </Body>
      <Body type="B5">
        <InlineTextLink href="mailto:help@realtymogul.com">help@realtymogul.com</InlineTextLink>
      </Body>
      <ExtendedBox
        mt={80}
        mb={80}
      >
        <StyledButton
          onClick={handleClick}
          type="L3"
          textTransform="uppercase"
          display="block"
        >
          Go to RealtyMogul Homepage
        </StyledButton>
      </ExtendedBox>
    </ExtendedBox>
  );
};

export default ReactError;
