// Collection of all the content that is used in the slides.
// Array is consumed by a .map and displayed in order by carousel.

import pictureStep1 from '../assets/HIW_steps_1.jpg';
import pictureStep2 from '../assets/HIW_steps_2.png';
import pictureStep3 from '../assets/HIW_steps_1.png';
import pictureStep4 from '../assets/HIW_steps_4.jpg';

const slideCollection = [
  {
    slideStepTitle: 'Step 1',
    mainTitle: 'Create Your Account',
    bodyContent: 'Complete your RealtyMogul.com account, and share your investing goals with us. The process is quick and simple.',
    image: pictureStep1,
  },
  {
    slideStepTitle: 'Step 2',
    mainTitle: 'Select Your Investment',
    bodyContent: 'Next, select the Income REIT or the Apartment Growth REIT for your investment. Under “ownership information” select “retirement”.',
    image: pictureStep2,
  },
  {
    slideStepTitle: 'Step 3',
    mainTitle: 'Invest in minutes',
    bodyContent: 'The Member Relations team will coordinate with you and your chosen SDIRA custodian to either process your investment into the Income REIT or the Apartment Growth REIT directly from your existing SDIRA, or assist you with transferring funds to a new SDIRA custodian, and then with completing the investment.',
    image: pictureStep3,
  },
  {
    slideStepTitle: 'Step 4',
    mainTitle: 'Track Your Investments',
    bodyContent: 'Once your investment is placed, you have full visibility into its performance in the RealtyMogul dashboard. Track your total current invested value and distributions.',
    image: pictureStep4,
  },
];

export default slideCollection;
