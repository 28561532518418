/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HeaderComponent from '../../components/TableHeader';
import StyledTable from '../../components/StyledTable';
import SubComponent from './SubComponent';
import Expander from './Expander';
import TableCell from './TableCell';

class MessagesTable extends Component {
  constructor() {
    super();
    this.state = {
      expanded: {},
      sorted: [],
      clickedRowInfo: {},
      recentlyRead: {},
    };
    this.handleExpandRow = this.handleExpandRow.bind(this);
    this.columns = this.columns.bind(this);
  }

  handleExpandRow(state, rowInfo) {
    return {
      onClick: (e, handleOriginal) => {
        this.setState({
          clickedRowInfo: rowInfo,
        }, () => {
          if (handleOriginal) {
            handleOriginal();
          }
        });
      },
    };
  }

  wasRecentlyRead(item) {
    return !!this.state.recentlyRead[item.id];
  }

  columns() {
    return [
      {
        expander: true,
        Header: null,
        width: 65,
        Expander: (props) => <Expander {...props} />,
        style: {
          padding: '0',
        },
      },
      {
        Header: <HeaderComponent>Date</HeaderComponent>,
        accessor: 'date',
        width: 80,
        Cell: TableCell,
        className: 'date-column',
        headerClassName: 'date-column',
        responsiveWidth: [125],
      },
      ...(this.props.showInvestmentLabel ? [{
        Header: <HeaderComponent>Investment</HeaderComponent>,
        accessor: 'investment',
        Cell: TableCell,
      }] : []),
      {
        Header: <HeaderComponent>Subject</HeaderComponent>,
        accessor: 'subject',
        Cell: TableCell,
      },
      {
        id: 'status',
        width: 100,
        Header: <HeaderComponent>Status</HeaderComponent>,
        accessor: (d) => d,
        Cell: TableCell,
        sortMethod: (aObj, bObj) => {
          const a = this.wasRecentlyRead(aObj) ? 'Unread' : aObj.status;
          const b = this.wasRecentlyRead(bObj) ? 'Unread' : bObj.status;

          if (a === b) {
            return a > b ? 1 : -1;
          }
          return a > b ? 1 : -1;
        },
        defaultSortDesc: true,
        className: 'status-column',
        headerClassName: 'status-column',
        responsiveWidth: [130, 150, null, 175],
      },
    ];
  }

  render() {
    const {
      messages,
      showInvestmentLabel,
      ...additionalProps
    } = this.props;

    return (
      <StyledTable
        data={messages}
        columns={this.columns()}
        noDataText="No messages."
        additionalTableProps={{
          showPageSizeOptions: false,
          SubComponent: (props) => (
            <SubComponent
              showInvestmentLabel={showInvestmentLabel}
              {...props.original}
            />
          ),

          // We have to manage our our expanded state. This is because when
          // we expand a row, we want to mark it as read. This causes the
          // row to be updated and immediately closes the row.
          expanded: this.state.expanded,
          sorted: this.state.sorted,
          onPageChange: () => {
            // Reset the expanded state on page change.
            this.setState({
              expanded: {},
            });
          },
          onExpandedChange: (newExpanded) => {
            const original = this.state.clickedRowInfo.original;
            this.setState((oldState) => ({
              expanded: newExpanded,
              recentlyRead: {
                ...oldState.recentlyRead,
                ...(!original.isRead ? {
                  [original.id]: true,
                } : {}),
              },
            }));
          },
          onSortedChange: (newSorted) => {
            this.setState({
              sorted: newSorted,

              // Reset the recently read so that the user can sort it
              // correctly.
              recentlyRead: [],
            });
          },
          getTdProps: this.handleExpandRow,
        }}
        {...(messages.length > 20 ? {
          showPagination: true,
          pageSize: 20,
        } : {
          showPagination: false,
          pageSize: messages.length,
        })}
        {...additionalProps}
      />
    );
  }
}

MessagesTable.propTypes = {
  messages: PropTypes.array,
  showInvestmentLabel: PropTypes.bool,
};

MessagesTable.defaultProps = {
  showInvestmentLabel: true,
};

export default MessagesTable;
