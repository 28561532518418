import appWrapperTypes from '../appWrapper/types';
import authTypes from '../auth/types';
import messagesTypes from '../dashboard/messages/types';
import headerData from './header.json';

const initialState = {
  items: [],
  user: {
    isLoggedIn: false,
    firstName: 'Welcome',
    lastName: '',
  },
  initialized: false,
};

const generateHeader = (user, data) => {
  const loggedOut = ['my_dashboard'];
  const preAccredited = ['open_investments_pre_accredited'];
  const isPreAccredited = user.accreditationRoleStatus === 'Pre-Accredited';
  const items = data
    .map(
      (item) => {
        return {
          ...item,
          ...(item.machineName === 'invest' && user.isLoggedIn ? {
            url: isPreAccredited ? '/accreditation' : '/investment-opportunities',
            ...(isPreAccredited ? {
              drupal: true,
            } : {}),
          } : {}),
          children: item.children.length > 0 ? item.children
            .filter(
              (child) => {
                if (preAccredited.includes(child.machineName)) {
                  return isPreAccredited;
                }
                if (!user.isLoggedIn || isPreAccredited) {
                  return !loggedOut.includes(child.machineName);
                }
                if (user.accreditationRoleStatus === 'Accredited') {
                  return true;
                }
                return true;
              },
            ) :
            item.children,
        };
      },
    )
    .filter(
      (item) => {
        if (!user.isLoggedIn) {
          return !loggedOut.includes(item.machineName);
        }
        return true;
      },
    );
  return items;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case messagesTypes.GET_MESSAGE_DATA_READ_REQUESTED: {
      return {
        ...state,
        user: {
          ...state.user,
          unreadMessagesCount: (state.user.unreadMessagesCount > 0) ?
            state.user.unreadMessagesCount - 1 : state.user.unreadMessagesCount,
        },
      };
    }

    case appWrapperTypes.GET_USER_DATA_SUCCEEDED: {
      const items = generateHeader(action.user, headerData.data);

      return {
        ...state,
        items,
        user: action.user,
      };
    }

    case appWrapperTypes.GET_USER_DATA_FAILED:
    case authTypes.USER_LOGOUT_REQUESTED: {
      // Appear logged out immediately.
      const items = generateHeader(initialState.user, headerData.data);

      return {
        ...state,
        items,
        user: {
          ...initialState.user,
        },
      };
    }

    case appWrapperTypes.APP_INIT: {
      const user = {
        ...state.user,
        ...action.data.user,
      };
      const items = generateHeader(user, headerData.data);

      return {
        ...state,
        initialized: true,
        items,
        user,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
