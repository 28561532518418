import homepageTypes from '../homepage/types';

const initialState = {
};

export default (state = initialState, action) => {
  switch (action.type) {
    case homepageTypes.GET_TOKENS_SUCCEEDED: {
      return {
        ...state,
        ...action.tokens,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
