import React from 'react';
import PropTypes from 'prop-types';
import { PropertyTypesData } from './_contentData';

import {
  ExtendedFlex,
  ExtendedBox,
  MiniEssay,
  Data,
  Body,
} from '../coreui';

import DynamicBrowseJoinLink from '../helpers';
import FadeIn from '../components/FadeIn';

const Row = ({ heading, description, advantages, odd = false }) => (
  <ExtendedFlex
    maxWidth={1380}
    bg={odd ? 'white' : 'gray.0'}
    py={20}
    px={30}
    mx="auto"
    justifyContent="space-evenly"
  >
    <Body type="B3" px={10} width={200}>
      {heading}
    </Body>
    <Body type="B3" px={10} width={350} mr={20}>
      {description}
    </Body>
    <Body type="B3" px={10} width={650}>
      {advantages}
    </Body>
  </ExtendedFlex>
);

const PropertyTypes = ({ isLoggedIn }) => {
  return (
    <ExtendedBox>
      <ExtendedFlex
        py={[50, 50, 100]}
        bg="gray.0"
        flexDirection="column"
        m={0}
        mx="auto"
        width={1}
      >
        <MiniEssay
          m={0}
          header="Commercial Real Estate Property Types"
        />
        <ExtendedBox
          width="auto"
          overflowX="scroll"
        >
          <FadeIn>
            <ExtendedBox
              bg="white"
              py={40}
              px={20}
              mx="auto"
              width="80%"
              maxWidth={1440}
              minWidth="800px"
            >
              <ExtendedFlex
                maxWidth={1380}
                bg="gray.0"
                pt={10}
                pb={20}
                px={30}
                mx="auto"
                justifyContent="space-evenly"
              >
                <Data type="D5" width={200} px={10}>
                  Property Type
                </Data>
                <Data type="D5" width={350} px={10} mr={20}>
                  Description
                </Data>
                <Data type="D5" width={650} px={10}>
                  Advantages & Disadvantages
                </Data>
              </ExtendedFlex>
              {
                PropertyTypesData.length > 0 &&
                PropertyTypesData.map((rowData, index) => (
                  <Row
                    key={rowData.heading}
                    heading={rowData.heading}
                    description={rowData.description}
                    advantages={rowData.advantages}
                    odd={(index + 1) % 2 !== 0}
                  />
                ))
              }
            </ExtendedBox>
          </FadeIn>
        </ExtendedBox>
        <ExtendedFlex
          pt={60}
          bg="gray.0"
          flexDirection="column"
          align="center"
        >
          <DynamicBrowseJoinLink
            type="L1"
            isLoggedIn={isLoggedIn}
          />
        </ExtendedFlex>
      </ExtendedFlex>
    </ExtendedBox>
  );
};

PropertyTypes.propTypes = {
  isLoggedIn: PropTypes.bool,
};

Row.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  advantages: PropTypes.string,
  odd: PropTypes.bool,
};

export default PropertyTypes;
