import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ExtendedFlex,
  Hero,
  UnlockOpportunities,
  ExtendedBox,
  ScrollToTopOnMount,
} from '../coreui';

import Callouts from './Callouts';
import heroBannerImage from './assets/img_hero_whyCRE.jpg';
import InvestmentAlternatives from './investmentAlternatives';
import IconCallouts from './IconCallouts';
import InvestmentStrategies from './InvestmentStrategies';
import PassiveVsActive from './PassiveVsActive';
import PropertyTypes from './PropertyTypes';
import { PotentialBenefitsData } from './_contentData';
import Section from '../ioDetails/components/section';
import Graphic3ColCallout from '../components/Graphic3ColCallout';
import BrowseLink from '../components/BrowseLink';

class WhyCommercialRealEstate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      user,
    } = this.props;

    return (
      <ExtendedBox>
        <ScrollToTopOnMount />
        <ExtendedFlex>
          <Hero
            image={heroBannerImage}
            header="TAKE YOUR PORTFOLIO TO NEW HEIGHTS"
            subHeader="The Power of Commercial Real Estate"
            bgPosition="74%"
          />
        </ExtendedFlex>
        <Section>
          <Graphic3ColCallout
            title="Benefits Of Real Estate Investing"
            items={PotentialBenefitsData}
          />
          <BrowseLink
            anonText="Join Now"
            isLoggedIn={user.isLoggedIn}
          />
        </Section>
        <InvestmentAlternatives
          isLoggedIn={user.isLoggedIn}
        />
        <PropertyTypes
          isLoggedIn={user.isLoggedIn}
        />
        <IconCallouts />
        <InvestmentStrategies
          bg="gray.0"
        />
        <PassiveVsActive
          isLoggedIn={user.isLoggedIn}
        />
        <Callouts
          bg="gray.0"
        />
        <ExtendedFlex>
          <UnlockOpportunities
            isLoggedIn={user.isLoggedIn}
          />
        </ExtendedFlex>
      </ExtendedBox>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.homepage.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

WhyCommercialRealEstate.propTypes = {
  user: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(WhyCommercialRealEstate);
