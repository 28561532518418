/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

// THIS IS JUST A PLACEHOLDER FILE WILL HAVE TO BE REBUILT

import {
  ExtendedFlex,
  Box,
  Flex,
  Heading,
  Link,
  VisibilitySensor,
  OptionDropdown,
  TextInput,
  formatters,
} from '../../coreui';

import FadeIn from '../../components/FadeIn';

class ProjectFinancing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectValueFocus: '',
      projectValue: '',
      dropdown1: {
        id: 'dropdown1',
        isActive: false,
        value: '1,000,000',
        options: [
          'Passive Income',
          'Capital Preservation',
          'Growth',
          '1031 Exchange',
          'Other',
        ],
      },
      dropdown2: {
        id: 'dropdown2',
        isActive: false,
        value: 'Equity',
        options: [
          'Equity',
          'Preferred Equity',
        ],
      },
      isVisible: false,
    };
    this.dropdownClickHandler = this.dropdownClickHandler.bind(this);
    this.handleSensorChange = this.handleSensorChange.bind(this);
    this.reset = this.reset.bind(this);
  }

  dropdownClickHandler(e) {
    const value = e.target.getAttribute('value');
    const target = e.target.getAttribute('id');
    // Whether open or closed, clicking should toggle dropdown in other direction.
    this.setState((state) => ({
      [target]: {
        ...state[target],
        isActive: !state[target].isActive,
        value,
      },
    }));
  }

  reset(e) {
    if (!Object.keys(this.state).includes(e.target.id)) {
      this.setState((state) => ({
        dropdown1: {
          ...state.dropdown1,
          isActive: false,
        },
        dropdown2: {
          ...state.dropdown2,
          isActive: false,
        },
      }));
    }
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible,
      });
    }
  }

  render() {
    const {
      dropdown1,
      dropdown2,
      isVisible,
    } = this.state;
    const {
      isLoggedIn,
    } = this.props;
    const projectValue = this.state.projectValue;
    const linkDestination = `/sponsor-application${projectValue ? `?amount=${projectValue}` : ''}`;
    return isLoggedIn ?
      null :
      <Box
        bg="white"
        w={1}
        py={[60, 80, 100, 120, 140]}
        onClick={this.reset}
      >
        <Flex
          flexDirection="column"
          align="center"
        >
          <FadeIn>
            <Heading
              type="H9"
              color="black"
              display="block"
              textAlign="center"
              textTransform="uppercase"
              mx={20}
              opacity={isVisible ? '1' : '0'}
              transition={`
                opacity 0.5s ${!isVisible ? '0' : '0.3'}s linear
              `}
            >
              How Much Does Your Project Need?
            </Heading>
            <VisibilitySensor
              callback={this.handleSensorChange}
            />
            <Box
              my={50}
            >
              <ExtendedFlex
                flexDirection={['column', 'column', 'column', 'row', 'row']}
                align="center"
                mb={[20, 30, 40, 40, 60]}
                opacity={isVisible ? '1' : '0'}
                transition={`
                  opacity 0.5s ${!isVisible ? '0' : '0.6'}s linear
                `}
              >
                <Flex
                  flexDirection={['column', 'row', 'row', 'row', 'row']}
                  align="center"
                  mr={[0, 0, 0, 20, 20]}
                  mb={[40, 40, 40, 0]}
                >
                  <Heading
                    type="H5"
                    color="black"
                    mr={[20, 20, 20, 20, 20]}
                    mb={[10, null]}
                  >
                    I Need
                  </Heading>
                  <Heading
                    type="H6"
                  >
                    <TextInput
                      data-test="project-financing-amount"
                      placeholder="$1,000,000"
                      placeholderColor="blue.2"
                      color="blue.2"
                      borderColor="blue.2"
                      borderTop="0 none"
                      borderLeft="0 none"
                      borderRight="0 none"
                      borderBottom="1px solid"
                      fontFamily="sansSerif"
                      textAlign="center"
                      w={200}
                      height={40}
                      onFocus={() => {
                        if (!this.state.projectValueFocus) {
                          this.setState({
                            projectValueFocus: '$',
                          });
                        }
                      }}
                      onBlur={() => {
                        if (!this.state.projectValue) {
                          this.setState({
                            projectValueFocus: '',
                          });
                        }
                      }}
                      value={this.state.projectValue ?
                        numeral(this.state.projectValue).format(formatters.DOLLARS)
                        : this.state.projectValueFocus}
                      onInput={(e) => {
                        const value = e.target.value
                          // Replace everything after the period.
                          .replace(/\..*$/, '')
                          // Replace non-digits.
                          .replace(/[\D]/g, '');
                        this.setState({
                          projectValue: (value && parseInt(value, 10) > 0) ?
                            value.substring(0, 12) : '',
                        });
                      }}
                    />
                  </Heading>
                  <ExtendedFlex
                    display={['none', 'none', 'none', 'none', 'none']}
                  >
                    <OptionDropdown
                      width={[190, 190, 210, 240, 300]}
                      display="none"
                      zIndex="2"
                      {...dropdown1}
                      handleClick={this.dropdownClickHandler}
                    />
                  </ExtendedFlex>
                </Flex>
                <Flex
                  flexDirection={['column', 'row', 'row', 'row', 'row']}
                  align="center"
                  mb={0}
                >
                  <Heading
                    type="H5"
                    color="black"
                    mr={[20, 20, 20, 20, 20]}
                    mb={[10, null]}
                  >
                    For
                  </Heading>
                  <OptionDropdown
                    data-test="project-financing-type"
                    width={[140, 140, 150, 170, 220]}
                    zIndex="1"
                    {...dropdown2}
                    handleClick={this.dropdownClickHandler}
                  />
                </Flex>
              </ExtendedFlex>
            </Box>
            <Link
              data-test="project-financing-link"
              type="L1"
              href={linkDestination}
              opacity={isVisible ? '1' : '0'}
              transition={`
              opacity 0.5s ${!isVisible ? '0' : '0.9'}s linear
              `}
            >
              FINANCE MY PROJECT
            </Link>
          </FadeIn>
        </Flex>
      </Box>;
  }
}

ProjectFinancing.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  cookies: PropTypes.string,
};

export default ProjectFinancing;
