/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import pick from 'lodash/pick';
import get from 'lodash/get';

import {
  withRouter,
  Route,
  Switch,
  Redirect,
  generatePath,
} from 'react-router-dom';

import {
  ExtendedFlex,
  Heading,
  ScrollToTopOnMount,
  Formatters,
} from '../../coreui';

import actions from '../actions';
import isqActions from '../../isq/actions';
import messageActions from '../../dsm/actions';

import {
  autoInvestUrl,
  autoInvestEditUrl,
  autoInvestIsqUrl,
  autoInvestEnrollmentInfoUrl,
  autoInvestEnrollmentSuitabilityUrl,
  autoInvestEnrollmentInvestmentUrl,
  autoInvestEnrollmentConfirmationUrl,
  autoInvestEditConfirmationUrl,
  autoInvestEnrollmentIneligibleUrl,
  autoInvestEnrollmentUrl,
  autoInvestDirectEnrollmentUrl,
} from '../links';
import RedirectToLoginOnLoggedOut from '../../components/RedirectToLoginOnLoggedOut';
import DashboardNotifications from '../components/DashboardNotifications';
import NotificationBar from '../components/NotificationBar';
import DashboardNavLink from '../components/DashboardNavLink';
import DashboardLoader from '../components/DashboardLoader';
import Landing from './components/Landing';
import EnrollmentInfo from './components/EnrollmentInfo';
import ISQ from './components/ISQ';
import Investment from './components/Investment';
import FourOhFour from '../../components/FourOhFour';
import FourOhThree from '../../components/FourOhThree';
import ScrollToSpanOnMount from '../../components/ScrollToSpanOnMount';
import Confirmation from './components/Confirmation';
import StepCounter from './components/StepCounter';
import EditAutoInvestConfig from './components/EditAutoInvestConfig';
import { canEditEnrollmentConfig, isFailedSuitability, getRoomLeft } from './utility';
import NotEligible from './components/NotEligible';
import AutoInvestDirectEnrollment from './components/AutoInvestDirectEnrollment';
import StartEnrollment from './components/StartEnrollment';
import { isqProps } from './data';
import { cleanUpIsqSubmission } from '../../isq/utility';

const getOwnershipOption = ({ options, foe: foeId, io: ioId }) => (
  options.find(({ io, foe }) => (
    io.id === ioId
    && foe.id === foeId
  ))
);

class AutoInvestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEnrollment: {},
      submitting: false,
      scrollToTopKey: undefined,
    };

    this.isUnmounted = false;

    this.handleEnrollment = this.handleEnrollment.bind(this);
    this.handleEnrollmentSubmit = this.handleEnrollmentSubmit.bind(this);
    this.handleEnrollmentEdit = this.handleEnrollmentEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleEnrollmentCancel = this.handleEnrollmentCancel.bind(this);
    this.handleActiveToggle = this.handleActiveToggle.bind(this);
    this.handleEnrollmentIsq = this.handleEnrollmentIsq.bind(this);
    this.handleSetLatestIsq = this.handleSetLatestIsq.bind(this);
    this.getLanding = this.getLanding.bind(this);
  }

  componentDidMount() {
    this.props.getLatestIsq();
    this.props.getDashboardApiData();
    this.props.getNotificationApiData();
  }

  componentDidUpdate(prevProps) {
    // Check if we need to clear out the current enrollment from the state.
    const pathChanged = this.props.location.pathname !== prevProps.location.pathname;
    const pathIsLanding = this.props.location.pathname === autoInvestUrl;
    const currentEnrollmentOption = this.getEnrollmentOption();
    const hasCurrentEnrollmentOption = !!currentEnrollmentOption;

    if (pathChanged && pathIsLanding && hasCurrentEnrollmentOption) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        currentEnrollment: {},
      });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  getEnrollmentOption() {
    const {
      ownershipOptions,
    } = this.props;
    const {
      currentEnrollment,
    } = this.state;
    return currentEnrollment.io && currentEnrollment.foe
      && getOwnershipOption({
        options: ownershipOptions,
        foe: currentEnrollment.foe,
        io: currentEnrollment.io,
      });
  }

  getLanding() {
    const {
      ownershipOptions,
      reit1,
      reit2,
    } = this.props;

    const hasAutoInvest = ownershipOptions.filter(({ status }) => status !== 'inactive').length > 0;

    return (
      <>
        <ScrollToTopOnMount key={this.state.scrollToTopKey} />
        <Landing
          hasAutoInvest={hasAutoInvest}
          reit1={reit1}
          reit2={reit2}
          ownershipOptions={ownershipOptions}
          handleEnrollment={this.handleEnrollment}
          handleCancel={this.handleCancel}
          handleActiveToggle={this.handleActiveToggle}
        />
      </>
    );
  }

  // Handle starting the enrollment process.
  handleEnrollment({
    io,
    foe,
    direct = false,
    redirect = autoInvestEnrollmentInfoUrl,
    replace = false,
  }) {
    this.setState({
      currentEnrollment: {
        io,
        foe,
        ...(direct ? {
          directEnrollment: true,
        } : {}),
      },
    });
    if (replace) {
      this.props.history.replace(redirect);
    } else {
      this.props.history.push(redirect);
    }
  }

  handleEnrollmentCancel() {
    const {
      history,
    } = this.props;
    const enrollmentOption = this.getEnrollmentOption();
    if (enrollmentOption) {
      return this.handleCancel(pick(enrollmentOption, ['id', 'foe', 'io']))
        .then(() => {
          if (this.isUnmounted) {
            return;
          }
          this.setState({
            currentEnrollment: {},
          });
          history.push(autoInvestUrl);
        });
    }
    return Promise.resolve();
  }

  handleCancel(data) {
    const {
      location,
      history,
    } = this.props;
    this.setState({ submitting: true });
    return (new Promise((resolve, reject) => {
      this.props.submitAutoInvestDelete(data, {
        resolve,
        reject,
      });
    }))
      .then(() => {
        this.props.addStatusMessage({
          message: 'Your enrollment has been cancelled.',
          type: 'status',
        });
        if (this.isUnmounted) {
          return;
        }
        if (location.pathname !== autoInvestUrl) {
          history.push(autoInvestUrl);
        } else {
          this.setState({
            scrollToTopKey: `delete:${data.id}`,
          });
        }
      })
      .catch((e) => {
        // Failed. Log to segment?
        throw e;
      })
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  handleActiveToggle(id) {
    const {
      updateAutoInvestConfig,
      ownershipOptions,
    } = this.props;

    const config = ownershipOptions.find((config) => config.id === id);

    return (new Promise((resolve, reject) => {
      const data = {
        status: config.status === 'active' ? 'paused' : 'active',
      };
      updateAutoInvestConfig(id, data, {
        resolve,
        reject,
      });
    }));
  }

  handleEnrollmentSubmit() {
    this.setState({ submitting: true });
    const {
      foe,
      io,
      requestedAmount,
      bankAccount,
      isq,
    } = this.state.currentEnrollment;

    const {
      history,
      submitAutoInvestEnrollment,
      getLatestIsq,
    } = this.props;

    return (new Promise((resolve, reject) => {
      submitAutoInvestEnrollment(
        {
          foe,
          io,
          requestedAmount,
          bankAccount,
          isq,
        },
        {
          resolve,
          reject,
        },
      );
    }))
      .then(() => {
        getLatestIsq();
        if (this.isUnmounted) {
          return;
        }
        history.push(autoInvestEnrollmentConfirmationUrl);
      })
      .catch((e) => {
        // Failed.
        // @TODO log an error in Segment?
        throw e;
      })
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  handleEnrollmentEdit(id, { requestedAmount, bankAccount }) {
    const {
      history,
      ownershipOptions,
      updateAutoInvestConfig,
      addStatusMessage,
    } = this.props;

    const config = ownershipOptions.find((config) => config.id === id);

    const changedData = {};
    if (config.requestedAmount !== requestedAmount) {
      changedData.requestedAmount = requestedAmount;
    }
    if (config.bankAccount.id !== bankAccount) {
      changedData.bankAccount = bankAccount;
      changedData.status = 'pending';
    }
    if (!Object.keys(changedData).length) {
      return Promise.resolve()
        .then(() => {
          history.push(autoInvestUrl);

          addStatusMessage({
            message: 'No changes saved.',
            type: 'status',
          });
        });
    }

    return (new Promise((resolve, reject) => {
      updateAutoInvestConfig(id, changedData, {
        resolve,
        reject,
      });
    }))
      .then((response) => {
        const doRedirect = !this.isUnmounted;
        const isPending = get(response, 'data.data[0].status') === 'pending';
        if (isPending) {
          const id = get(response, 'data.data[0].id');
          if (doRedirect) {
            history.push(generatePath(autoInvestEditConfirmationUrl, { id }));
          } else {
            addStatusMessage({
              message: 'Changes saved.',
              type: 'status',
            });
          }
        } else {
          if (doRedirect) {
            history.push(autoInvestUrl);
          }

          addStatusMessage({
            message: 'Changes saved.',
            type: 'status',
          });
        }
      });
  }

  handleEnrollmentIsq(id, isq) {
    const {
      history,
      updateAutoInvestConfig,
      addStatusMessage,
      getLatestIsq,
    } = this.props;

    return (new Promise((resolve, reject) => {
      updateAutoInvestConfig(id, { status: 'pending', isq }, {
        resolve,
        reject,
      });
    }))
      .then(() => {
        getLatestIsq();
        if (!this.isUnmounted) {
          history.push(autoInvestUrl);
        }

        addStatusMessage({
          message: 'Changes saved.',
          type: 'status',
        });
      });
  }

  handleSetLatestIsq(isq) {
    const {
      getLatestIsq,
      setLatestIsq,
    } = this.props;

    return (new Promise((resolve, reject) => {
      setLatestIsq(isq, {
        resolve,
        reject,
      });
    }))
      .then(() => {
        getLatestIsq();
      });
  }

  render() {
    const {
      loaded,
      isqLoaded,
      notifications,
      pendingData,
      ownershipOptions,
      bankAccounts,
      history,
      latestIsq,
      steps,
      enrollmentErrors,
      editErrors,
      enrollmentCancelBody,
      location,
      isAccredited,
    } = this.props;
    const {
      submitting,
      currentEnrollment,
    } = this.state;

    const hasNotification = notifications.length > 0;

    // Determine if we're in a flow by getting the current enrollment option.
    const enrollmentOption = this.getEnrollmentOption();

    return (
      <ExtendedFlex
        flexDirection="column"
        justifyContent="center"
        width="100%"
        alignItems="center"
        pb={140}
        {...(!loaded ? {
          justifyContent: 'flex-start',
          minHeight: [650, 700, 900, 1000, '100vh'],
          position: 'relative',
        } : {})}
      >
        <ScrollToTopOnMount />
        <RedirectToLoginOnLoggedOut />
        {
          hasNotification &&
          <NotificationBar data={this.props.notifications} />
        }
        <DashboardNotifications
          pendingData={pendingData}
        />
        <Heading
          type="H2"
          my="0px"
          pt="100px"
          pb="0px"
        >
          My Dashboard
        </Heading>
        <DashboardNavLink />
        <DashboardLoader loaded={loaded}>
          <Switch>
            {
              enrollmentOption &&
              <>
                <ScrollToSpanOnMount
                  key={location.pathname}
                />
                {
                  enrollmentOption.isNew &&
                  <>
                    <Route
                      path={autoInvestEnrollmentInfoUrl}
                      render={() => (
                        <>
                          <EnrollmentInfo
                            handleSubmit={() => {
                              history.push(autoInvestEnrollmentSuitabilityUrl);
                            }}
                            steps={steps}
                            selectedOffering={enrollmentOption.io.label}
                            selectedAccount={`${enrollmentOption.foe.type.label}: ${enrollmentOption.foe.title}`}
                            nextDate={
                              moment(enrollmentOption.nextEnrollmentDate)
                                .format(Formatters.MMMMYYYY)
                              }
                            handleCancel={this.handleEnrollmentCancel}
                            cancelBody={enrollmentCancelBody}
                          />
                        </>
                      )}
                    />
                    <Route
                      path={autoInvestEnrollmentSuitabilityUrl}
                      render={() => (
                        <>
                          <DashboardLoader loaded={isqLoaded}>
                            <ISQ
                              latestIsq={latestIsq}
                              timeHorizon={enrollmentOption.io.timeHorizon}
                              handleSubmit={(values) => {
                                this.setState((state) => ({
                                  currentEnrollment: {
                                    ...state.currentEnrollment,
                                    isq: values,
                                  },
                                }));

                                if (isFailedSuitability({
                                  isAccredited,
                                  aggregateAmount: enrollmentOption.io.aggregateAmount,
                                  isq: values,
                                })) {
                                  return this.handleSetLatestIsq(values)
                                    .finally(() => {
                                      history.push(autoInvestEnrollmentIneligibleUrl);
                                    });
                                }
                                history.push(autoInvestEnrollmentInvestmentUrl);
                                return Promise.resolve();
                              }}
                              handleCancel={this.handleEnrollmentCancel}
                              cancelBody={enrollmentCancelBody}
                            />
                          </DashboardLoader>
                        </>
                      )}
                    />
                    <Route
                      path={autoInvestEnrollmentInvestmentUrl}
                      render={() => (
                        <>
                          <Investment
                            handleSubmit={({ requestedAmount, bankAccount }) => {
                              this.setState((state) => ({
                                currentEnrollment: {
                                  ...state.currentEnrollment,
                                  requestedAmount,
                                  bankAccount,
                                },
                              }), this.handleEnrollmentSubmit);
                            }}
                            bankAccounts={bankAccounts}
                            bankAccountValues={bankAccounts.map((bankAccount) => ({
                              value: bankAccount.id,
                              label: bankAccount.label,
                            }))}
                            minPerMonth={enrollmentOption.io.minPerMonth}
                            maxPerMonth={(() => {
                              if (isAccredited) {
                                return enrollmentOption.io.maxPerMonth;
                              }
                              const roomLeft = getRoomLeft({
                                isAccredited,
                                aggregateAmount: enrollmentOption.io.aggregateAmount,
                                isq: currentEnrollment.isq,
                              });
                              return Math.min(enrollmentOption.io.maxPerMonth, roomLeft);
                            })()}
                            isAccredited={isAccredited}
                            isq={latestIsq}
                            submitting={submitting}
                            enrollmentErrors={enrollmentErrors}
                            handleCancel={this.handleEnrollmentCancel}
                            cancelBody={enrollmentCancelBody}
                          />
                        </>
                      )}
                    />
                    <Route
                      path={autoInvestEnrollmentUrl}
                      exact
                      render={() => {
                        return (
                          <>
                            <DashboardLoader loaded={isqLoaded}>
                              {
                                isqLoaded &&
                                <AutoInvestDirectEnrollment
                                  minPerMonth={enrollmentOption.io.minPerMonth}
                                  maxPerMonth={(() => {
                                    if (isAccredited) {
                                      return enrollmentOption.io.maxPerMonth;
                                    }
                                    const roomLeft = getRoomLeft({
                                      isAccredited,
                                      aggregateAmount: enrollmentOption.io.aggregateAmount,
                                      isq: latestIsq,
                                    });
                                    return Math.min(enrollmentOption.io.maxPerMonth, roomLeft);
                                  })()}
                                  isAccredited={isAccredited}
                                  id={enrollmentOption.io.id}
                                  netWorth={latestIsq.netWorth}
                                  netWorthSpecific={latestIsq.netWorthSpecific}
                                  bankAccountValues={bankAccounts.map((bankAccount) => ({
                                    value: bankAccount.id,
                                    label: bankAccount.label,
                                  }))}
                                  enrollmentErrors={enrollmentErrors}
                                  submitting={submitting}
                                  cancelBody={enrollmentCancelBody}
                                  handleCancel={this.handleEnrollmentCancel}
                                  handleSubmit={(data) => {
                                    this.setState((state) => ({
                                      currentEnrollment: {
                                        ...state.currentEnrollment,
                                        ...data,
                                        isq: cleanUpIsqSubmission({
                                          ...(pick(latestIsq, isqProps)),
                                          ...data.isq,
                                        }, {
                                          timeHorizon: enrollmentOption.io.timeHorizon,
                                          isqProps,
                                        }),
                                      },
                                    }), this.handleEnrollmentSubmit);
                                  }}
                                />
                              }
                            </DashboardLoader>
                          </>
                        );
                      }}
                    />
                  </>
                  }
                <Route
                  path={autoInvestEnrollmentConfirmationUrl}
                  exact
                  render={() => (
                    <>
                      <Confirmation
                        steps={!currentEnrollment.directEnrollment ? steps : false}
                        ioLabel={enrollmentOption.io.label}
                        foeType={enrollmentOption.foe.type.label}
                        foeTitle={enrollmentOption.foe.title}
                        amount={enrollmentOption.requestedAmount}
                        direct={currentEnrollment.directEnrollment}
                      />
                    </>
                  )}
                />
                <Route
                  path={autoInvestEnrollmentIneligibleUrl}
                  exact
                  render={() => (
                    <NotEligible
                      link={enrollmentOption.io.id === 193744
                        ? '/materials/mr1/offering_circular.pdf'
                        : '/materials/mr2/offering_circular.pdf'}
                    />
                  )}
                />
                {
                  !enrollmentOption.isNew
                  && location.pathname !== autoInvestEnrollmentConfirmationUrl &&
                  <Route
                    render={() => (
                      <Redirect
                        to={autoInvestEnrollmentConfirmationUrl}
                      />
                    )}
                  />
                }
              </>
            }
            <Route
              path={autoInvestEditUrl}
              exact
              render={({ match: { params: { id } } }) => {
                // Redirect to the landing page if the ID isn't numeric.
                if (!Number.parseInt(id, 10)) {
                  return <Redirect to={autoInvestUrl} push={false} />;
                }
                const option = ownershipOptions.find(
                  (config) => config.id === Number.parseInt(id, 10),
                );
                if (!option) {
                  return <FourOhFour />;
                }
                if (!canEditEnrollmentConfig(option)) {
                  if (option.status === 'pending') {
                    return (
                      <Redirect
                        to={generatePath(autoInvestEditConfirmationUrl, {
                          id: option.id,
                        })}
                      />
                    );
                  }
                  return <FourOhThree />;
                }
                return (
                  <>
                    <ScrollToSpanOnMount
                      key={option.id}
                    />
                    <EditAutoInvestConfig
                      isAccredited={isAccredited}
                      nextWithdrawalDate={option.nextWithdrawalDate}
                      requestedAmount={option.requestedAmount}
                      bankAccount={option.bankAccount.id}
                      selectedOffering={option.io.label}
                      selectedAccount={`${option.foe.type.label}: ${option.foe.title}`}
                      handleSubmit={(data) => {
                        return this.handleEnrollmentEdit(option.id, data);
                      }}
                      bankAccounts={bankAccounts}
                      bankAccountValues={bankAccounts.map((bankAccount) => ({
                        value: bankAccount.id,
                        label: bankAccount.label,
                      }))}
                      minPerMonth={option.io.minPerMonth}
                      maxPerMonth={option.io.maxPerMonth}
                      enrollmentErrors={editErrors}
                      handleCancel={() => this.handleCancel({ id: option.id })}
                    />
                  </>
                );
              }}
            />
            <Route
              path={autoInvestEditConfirmationUrl}
              exact
              render={({ match: { params: { id } } }) => {
                // Redirect to the landing page if the ID isn't numeric.
                if (!Number.parseInt(id, 10)) {
                  return <Redirect to={autoInvestUrl} push={false} />;
                }
                const option = ownershipOptions.find(
                  (config) => config.id === Number.parseInt(id, 10),
                );
                if (!option) {
                  return <FourOhFour />;
                }
                if (option.status !== 'pending') {
                  return <FourOhThree />;
                }
                return (
                  <>
                    <ScrollToSpanOnMount
                      key={option.id}
                    />
                    <Confirmation
                      ioLabel={option.io.label}
                      foeType={option.foe.type.label}
                      foeTitle={option.foe.title}
                      amount={option.requestedAmount}
                      content="You will receive an email with more information regarding your change request status shortly. Please allow time for your change request to be processed and approved before it appears on your Auto Invest account."
                    />
                  </>
                );
              }}
            />
            <Route
              path={autoInvestIsqUrl}
              exact
              render={({ match: { params: { id } } }) => {
                // Redirect to the landing page if the ID isn't numeric.
                if (!Number.parseInt(id, 10)) {
                  return <Redirect to={autoInvestUrl} push={false} />;
                }
                const option = ownershipOptions.find(
                  (config) => config.id === Number.parseInt(id, 10),
                );
                if (!option) {
                  return <FourOhFour />;
                }
                return (
                  <>
                    <DashboardLoader loaded={isqLoaded}>
                      <ISQ
                        showStepCounter={false}
                        timeHorizon={option.io.timeHorizon}
                        latestIsq={latestIsq}
                        handleSubmit={(isq) => (this.handleEnrollmentIsq(option.id, isq))}
                        handleCancel={() => {
                          history.push(autoInvestUrl);
                        }}
                        submitting={submitting}
                        enrollmentErrors={editErrors}
                        cancelText="Cancel submission"
                        cancelHeader="Cancel suitability submission?"
                        cancelBody="Your progress will be lost and your auto investment may not be able to be processed."
                      />
                    </DashboardLoader>
                  </>
                );
              }}
            />
            <Route
              path={autoInvestDirectEnrollmentUrl}
              exact
              render={({ match: { params: { io, foe } } }) => {
                // Redirect to the landing page if the ID isn't numeric.
                const ioId = Number.parseInt(io, 10);
                const foeId = Number.parseInt(foe, 10);
                if (!ioId || !foeId) {
                  return <Redirect to={autoInvestUrl} push={false} />;
                }
                const enrollmentOption = ownershipOptions.find(
                  // Find where the IR or the IO ID match.
                  ({
                    foe: { id: currentFoeId },
                    io: { id: currentIoId },
                  }) => ioId === currentIoId || foeId === currentFoeId,
                );
                if (!enrollmentOption) {
                  return <FourOhFour />;
                }
                return (
                  <StartEnrollment
                    io={enrollmentOption.io.id}
                    foe={enrollmentOption.foe.id}
                    redirect={autoInvestEnrollmentUrl}
                    direct
                    replace
                    handleEnrollment={this.handleEnrollment}
                  />
                );
              }}
            />
            <Route
              render={this.getLanding}
            />
          </Switch>
        </DashboardLoader>
      </ExtendedFlex>
    );
  }
}

AutoInvestPage.propTypes = {
  loaded: PropTypes.bool,
  isqLoaded: PropTypes.bool,
  reit1: PropTypes.object,
  reit2: PropTypes.object,
  history: PropTypes.object,
  getLatestIsq: PropTypes.func,
  latestIsq: PropTypes.object,
  bankAccounts: PropTypes.array,
  steps: StepCounter.propTypes.step,
  enrollmentErrors: PropTypes.object,
  editErrors: PropTypes.object,
  addStatusMessage: PropTypes.func,
  updateAutoInvestConfig: PropTypes.func,
  getDashboardApiData: PropTypes.func,
  getNotificationApiData: PropTypes.func,
  submitAutoInvestDelete: PropTypes.func,
  submitAutoInvestEnrollment: PropTypes.func,
  setLatestIsq: PropTypes.func,
  isAccredited: PropTypes.bool,
  enrollmentCancelBody: PropTypes.object,
  location: PropTypes.object,
  ownershipOptions: PropTypes.array,
  notifications: PropTypes.array,
  pendingData: PropTypes.array,
};

const mapStateToProps = (state) => {
  const loaded = state.dashboard.data.loaded && state.autoInvest.loaded;

  return {
    isAccredited: state.app.user.accreditationRoleStatus === 'Accredited',
    ownershipOptions: state.autoInvest.ownershipOptions,
    enrollmentErrors: state.autoInvest.enrollmentErrors,
    editErrors: state.autoInvest.editErrors,
    latestIsq: state.isq.latestIsq,
    isqLoaded: state.isq.loaded,
    bankAccounts: state.autoInvest.bankAccounts,
    notifications: state.dashboard.notifications.data,
    pendingData: state.dashboard.pendingData.pendingData.investments,
    steps: state.autoInvest.enrollmentSteps,
    enrollmentCancelBody: state.autoInvest.enrollmentCancelBody,
    loaded,
    reit1: loaded
      && state.dashboard.data.activeInvestmentsData.reitData.reits.find(
        (item) => item.nid === 193744,
      ),
    reit2: loaded
      && state.dashboard.data.activeInvestmentsData.reitData.reits.find(
        (item) => item.nid === 274949,
      ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardApiData: () => {
      dispatch(actions.autoInvestDataRequested());
      dispatch(actions.dashboardApiActionRequested());
      dispatch(actions.pendingDashboardDataRequested());
    },
    getLatestIsq: () => {
      dispatch(isqActions.getLatestIsqRequested());
    },
    setLatestIsq: (data, promise) => {
      dispatch(isqActions.setLatestIsqRequested(data, promise));
    },
    submitAutoInvestEnrollment: (data, promise) => {
      dispatch(actions.autoInvestEnrollmentRequested(data, promise));
    },
    submitAutoInvestDelete: (data, promise) => {
      dispatch(actions.autoInvestCancelRequested(data, promise));
    },
    getNotificationApiData: () => {
      dispatch(actions.notificationApiActionRequested());
    },
    addStatusMessage: (data) => {
      dispatch(messageActions.addStatusMessageRequested(data));
    },
    updateAutoInvestConfig: (id, data, promise) => {
      dispatch(actions.autoInvestUpdateRequested(id, data, promise));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AutoInvestPage),
);
