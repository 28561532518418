/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import get from 'lodash/get';

import {
  ExtendedFlex,
  Heading,
  Body,
  Numeral,
  formatters,
} from '../../../coreui';
import AutoInvestContentWrapper from './AutoInvestContentWrapper';
import Actions from '../../../components/forms/Actions';
import PrimaryButton from '../../../components/forms/PrimaryButton';
import CancelButtonWithModal from './CancelButtonWithModal';
import {
  getDefaultNetWorthSpecificValue,
  netWorthSpecificPropTypeValidation,
  sliderMapping,
  netWorthOptions,
} from '../../../components/isq/utility';
import EnrollmentErrors from './EnrollmentErrors';
import CheckboxAuthorizeDebit from './form/CheckboxAuthorizeDebit';
import SelectBankAccount from './form/SelectBankAccount';
import RecurringAmount from './form/RecurringAmount';
import Form from '../../../components/forms/Form';
import ConfirmModal from './ConfirmModal';
import NetWorthSpecific from './form/NetWorthSpecific';

class AutoInvestDirectEnrollment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      netWorthSpecific: props.netWorthSpecific,
      monthlyAmount: null,
      bankAccount: get(this.props, 'bankAccountValues[0].value'),
      isModalVisible: false,
    };

    this.amountInput = undefined;

    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleChange(e) {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const parseIntFromValue = (value) => Number.parseInt(value, 10) || null;

    const mapping = {
      // eslint-disable-next-line camelcase
      monthly_amount: {
        name: 'monthlyAmount',
        parse: parseIntFromValue,
      },
      // eslint-disable-next-line camelcase
      bank_account: {
        name: 'bankAccount',
        parse: parseIntFromValue,
      },
      netWorthSpecific: {
        name: 'netWorthSpecific',
        parse: parseIntFromValue,
      },
    };

    if (Object.keys(mapping).includes(name)) {
      this.setState({
        [mapping[name].name]: mapping[name].parse(value),
      });
    }

    this.props.handleChange(e);
  }

  handleFormSubmit(e) {
    e.preventDefault();

    if (this.state.isModalVisible) {
      this.props.handleSubmit({
        requestedAmount: this.state.monthlyAmount,
        bankAccount: this.state.bankAccount,
        isq: {
          netWorthSpecific: this.state.netWorthSpecific ||
            getDefaultNetWorthSpecificValue(this.props),
        },
      });
    } else {
      this.setState({
        isModalVisible: true,
      });
    }
  }

  render() {
    const {
      submitting,
      handleCancel,
      netWorth,
      sliderMapping: {
        [this.props.netWorth]: {
          min = null,
        } = {},
      },
      minPerMonth,
      maxPerMonth,
      isAccredited,
      bankAccountValues,
      enrollmentErrors,
      cancelBody,
      id,
    } = this.props;

    const handleChange = this.handleChange;

    const {
      netWorthSpecific,
      bankAccount,
    } = this.state;

    return (
      <AutoInvestContentWrapper>
        <Form onSubmit={(e) => this.handleFormSubmit(e)}>
          <ExtendedFlex flexDirection="column" w={1} maxWidth={650}>
            <Heading type="H2">Welcome to Auto Invest</Heading>
            <Body type="B1">
              Please fill out the information below to complete your Auto
              Invest enrollment request.
            </Body>
            <Heading type="H6.1">Enrollment date:</Heading>
            <Body type="B2">
              Your enrollment date will be dependent upon your initial
              {id === 193744 ? ' The Income REIT' : ' The Apartment Growth REIT'} investment approval date. Once your initial
              {id === 193744 ? ' The Income REIT' : ' The Apartment Growth REIT'} investment is approved, you will receive an email
              regarding your Auto Invest status. Feel free to check back
              under the “Auto Invest” tab on your Investor Dashboard for any
              status changes. Auto Invest funds will be withdrawn on a
              monthly basis on the 10th of every month or the next business
              day.
            </Body>
            {min !== null && (
              <>
                <Heading type="H6.1" mt={60}>
                  Please specify your Net Worth within Your Stated Range:
                </Heading>
                <NetWorthSpecific
                  value={netWorthSpecific}
                  netWorth={netWorth}
                  onChange={handleChange}
                />
              </>
            )}
            <ExtendedFlex flexDirection="column" mt={60}>
              <RecurringAmount
                body={
                  <>
                    The minimum Auto Invest amount is{' '}
                    {
                      <Numeral
                        value={minPerMonth}
                        format={formatters.DOLLARS}
                      />
                    }{' '}
                    per month. Please enter your desired monthly Auto Invest
                    amount below.
                  </>
                }
                minPerMonth={minPerMonth}
                maxPerMonth={maxPerMonth}
                isAccredited={isAccredited}
                onChange={handleChange}
              />
            </ExtendedFlex>
            <ExtendedFlex flexDirection="column" mt="calc(30px - 1em)">
              <SelectBankAccount
                value={bankAccount}
                options={bankAccountValues}
                onChange={handleChange}
              />
            </ExtendedFlex>
            <CheckboxAuthorizeDebit />
            {enrollmentErrors && <EnrollmentErrors {...enrollmentErrors} />}
            <Actions>
              <PrimaryButton
                loading={submitting}
                disable={submitting}
                buttonType="submit"
              >
                Continue
              </PrimaryButton>
              <CancelButtonWithModal
                handleCancel={handleCancel}
                body={cancelBody}
              />
            </Actions>
          </ExtendedFlex>
        </Form>
        <ConfirmModal
          isVisible={this.state.isModalVisible}
          amount={this.state.monthlyAmount || 0}
          bankAccount={
            bankAccountValues.find(
              (i) => i.id === this.state.bankAccount,
            ) || bankAccountValues[0]
          }
          handleClick={() => {
            this.setState({
              isModalVisible: false,
            });
          }}
          handleSubmit={this.handleFormSubmit}
          submitting={submitting}
          error={
            enrollmentErrors && <EnrollmentErrors {...enrollmentErrors} />
          }
        />
      </AutoInvestContentWrapper>
    );
  }
}

AutoInvestDirectEnrollment.propTypes = {
  submitting: PropTypes.bool,
  minPerMonth: PropTypes.number,
  maxPerMonth: PropTypes.number,
  id: PropTypes.number,
  isAccredited: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  handleChange: PropTypes.func,
  cancelBody: CancelButtonWithModal.propTypes.body,
  netWorth: PropTypes.oneOf(netWorthOptions.reduce((accum, option) => {
    return [
      ...accum,
      option.value,
    ];
  }, [''])),
  netWorthSpecific: netWorthSpecificPropTypeValidation,
  sliderMapping: PropTypes.object,
  bankAccount: PropTypes.string,
  bankAccountValues: PropTypes.arrayOf(PropTypes.string),
  enrollmentErrors: PropTypes.object,
};

AutoInvestDirectEnrollment.defaultProps = {
  handleSubmit: noop,
  handleCancel: noop,
  handleChange: noop,
  sliderMapping,
};

export default AutoInvestDirectEnrollment;
