import watchGetBrowsePageDataRequested from './getBrowsePageDataSaga';
import watchCloseWelcomeMessageRequested from './closeWelcomeMessageSaga';
import watchGetBrowseOpenDataRequested from './getBrowseOpenDataSaga';
import watchGetBrowseClosedDataRequested from './getBrowseClosedDataSaga';

export default [
  watchGetBrowsePageDataRequested(),
  watchGetBrowseOpenDataRequested(),
  watchCloseWelcomeMessageRequested(),
  watchGetBrowseClosedDataRequested(),
];
