import React from 'react';
import PropTypes from 'prop-types';

import {
  withRouter,
  matchPath,
} from 'react-router-dom';

import {
  ExtendedBox,
  ExtendedFlex,
  Image,
  Heading,
} from '../../coreui';

import DropdownArrow from '../assets/down_blue.svg';

class DashboardDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleUnhover = this.handleUnhover.bind(this);
  }

  handleHover() {
    this.setState({ isExpanded: true });
  }

  handleUnhover() {
    this.setState({ isExpanded: false });
  }

  handleClick(to) {
    return () => {
      this.props.history.push(to);
      this.handleUnhover();
    };
  }

  render() {
    const props = this.props;
    const dropdownDisplay = this.state.isExpanded === true ? 'flex' : 'none';
    const currentPageLink = this.props.links.filter((link) => {
      return link.sublinks.filter((sublink) => matchPath(props.location.pathname, {
        path: sublink,
      })).length > 0;
    });
    return (
      <ExtendedBox
        width="200px"
        height="40px"
        mt="30px"
        p="0px"
        position="relative"
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleUnhover}
        cursor="pointer"
      >
        <ExtendedFlex
          justifyContent="space-between"
          alignItems="center"
          border="1px solid #176DB6"
          p="10px"
          width="100%"
          color="#176DB6"
          tabIndex="0"
        >
          <Heading
            type="H7"
            m="0"
            p="0"
            color="#176DB6"
          >
            {
              currentPageLink.length > 0 &&
              currentPageLink[0].label
            }
          </Heading>
          <Image
            src={DropdownArrow}
          />
        </ExtendedFlex>
        <ExtendedFlex
          position="absolute"
          flexDirection="column"
          p="10px"
          width="100%"
          bg="#FFF"
          border="1px solid #176DB6"
          zIndex="1"
          display={dropdownDisplay}
        >
          {
            this.props.links.map((link) => {
              const clickHandler = link.value === this.props.history.location.pathname ?
                undefined :
                this.handleClick(link.value);
              return (
                <Heading
                  key={`DashboardDropdown:${link.label}:${link.value}`}
                  type="H7"
                  p="0"
                  m="1em"
                  height="40px"
                  color="#176DB6"
                  onClick={clickHandler}
                >
                  {link.label}
                </Heading>
              );
            })
          }
        </ExtendedFlex>
      </ExtendedBox>
    );
  }
}

DashboardDropdown.propTypes = {
  links: PropTypes.array,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(DashboardDropdown);
