import React from 'react';

import {
  Heading,
  Body,
} from '../../coreui';

import CalloutFrame from '../../components/CalloutFrame';
import backgroundImage from '../../assets/img_panel_PP.jpg';

const PrivatePlacement = () => {
  return (
    <CalloutFrame
      image={backgroundImage}
      imagePosition="second"
    >
      <Heading
        type="H2"
        display="block"
      >
        INDIVIDUAL PROPERTY INVESTMENTS
      </Heading>
      <Body
        type="B1"
        display="block"
      >
        A single investment into a specific property (or small group of properties) is sometimes referred to as a “Private Placement”. These investments are only open to investors who qualify as “Accredited Investors”, as defined by the SEC.
      </Body>
      <Body
        type="B1"
        display="block"
        mb={150}
      >
        The RealtyMogul platform offers equity investments in commercial real estate across the country in different property types such as office, multifamily, retail and industrial. Typical investment horizons range from 3 to 7 years with minimum investments from $25,000 to $50,000.
      </Body>
    </CalloutFrame>
  );
};

export default PrivatePlacement;
