/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
  withRouter,
  Switch,
} from 'react-router-dom';
import {
  Box,
} from '../../coreui';

import ActiveInvestments from '../activeInvestments';
import PastInvestments from '../pastInvestments';
import WelcomeMessage from '../components/WelcomeMessage';

import actions from './actions';

import {
  BROWSE_PAGE_OPEN_URL,
  BROWSE_PAGE_CLOSED_URL,
} from './links';

class BrowsePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showWelcomeMessage: true,
    };
    this.updateBrowseNavAnimationSettings = this.updateBrowseNavAnimationSettings.bind(this);
    this.closeWelcomeMessage = this.closeWelcomeMessage.bind(this);
  }

  updateBrowseNavAnimationSettings() {
    this.props.updateBrowseNavAnimationSettings();
  }

  closeWelcomeMessage() {
    this.setState({
      showWelcomeMessage: false,
    });
    this.props.closeWelcomeMessageRequested();
  }

  render() {
    const {
      loaded,
      welcomeMessage,
      browseNavAnimationSettings,
      showWelcomeMessage,
    } = this.props;

    return (
      <Box>
        {
          loaded && showWelcomeMessage && this.state.showWelcomeMessage &&
          <WelcomeMessage
            {...welcomeMessage}
            handleClick={this.closeWelcomeMessage}
          />
        }
        <Switch>
          <Route
            path={BROWSE_PAGE_OPEN_URL}
            render={(props) => (
              <ActiveInvestments
                {...props}
                browseNavAnimationSettings={browseNavAnimationSettings}
                updateBrowseNavAnimationSettings={this.updateBrowseNavAnimationSettings}
              />
            )}
          />
          <Route
            path={BROWSE_PAGE_CLOSED_URL}
            render={(props) => (
              <PastInvestments
                {...props}
                browseNavAnimationSettings={browseNavAnimationSettings}
                updateBrowseNavAnimationSettings={this.updateBrowseNavAnimationSettings}
              />
            )}
          />
        </Switch>
      </Box>
    );
  }
}

BrowsePage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  loaded: PropTypes.bool,
  accreditationRoleStatus: PropTypes.string,
  browseNavAnimationSettings: PropTypes.object,
  updateBrowseNavAnimationSettings: PropTypes.func,
  showWelcomeMessage: PropTypes.bool,
  welcomeMessage: PropTypes.object,
  closeWelcomeMessageRequested: PropTypes.func,
  firstName: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  userId: PropTypes.number,
  retrying: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  accreditationRoleStatus: state.browsePage.accreditationRoleStatus,
  gtkyStatus: state.browsePage.gtkyStatus,
  loaded: state.browsePage.loaded,
  browseNavAnimationSettings: state.browsePage.browseNavAnimationSettings,
  showWelcomeMessage: state.browsePage.showWelcomeMessage,
  welcomeMessage: state.browsePage.welcomeMessage,
  isLoggedIn: state.browsePage.isLoggedIn,
  userId: state.browsePage.userId,
  retrying: state.browsePage.retrying,
});

const mapDispatchToProps = (dispatch) => ({
  updateBrowseNavAnimationSettings: () => {
    dispatch(actions.updateBrowseNavAnimationSettings());
  },
  closeWelcomeMessageRequested: () => {
    dispatch(actions.closeWelcomeMessageRequested());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BrowsePage));
