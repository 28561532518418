import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  StyledButton,
  ModalWithOverlay,
} from '../../coreui';

class LearnMoreLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.toggleVisible = this.toggleVisible.bind(this);
  }

  toggleVisible() {
    this.setState((state) => ({
      isVisible: !state.isVisible,
    }));
  }

  render() {
    return (
      <ExtendedBox>
        <StyledButton
          type="L4"
          ml={10}
          onClick={this.toggleVisible}
        >
          Learn More
        </StyledButton>
        <ModalWithOverlay
          isVisible={this.state.isVisible}
          handleClick={this.toggleVisible}
        >
          {this.props.content}
        </ModalWithOverlay>
      </ExtendedBox>
    );
  }
}

LearnMoreLink.propTypes = {
  content: PropTypes.string,
};

export default LearnMoreLink;
