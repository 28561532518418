export function fetchInvestmentMetricFromData(
  data,
  metricName,
  metricLabel,
  metricType,
  metricVariation,
  metricOptions,
) {
  const metricValue = metricName in data ? data[metricName] : 0;
  const metricGroup = 'metricGroup' in metricOptions ? metricOptions.metricGroup : [];
  const metricTooltip = 'tooltip' in metricOptions ? metricOptions.tooltip : '';

  return {
    label: metricLabel,
    value: metricValue,
    type: metricType,
    variation: metricVariation,
    group: metricGroup,
    tooltip: metricTooltip,
  };
}

export function parseSmartButton(buttonJson, nid) {
  if (typeof buttonJson !== 'object') {
    return {
      text: 'Invest',
      url: `/node/${nid}`,
      content: '',
    };
  }
  return {
    text: 'button_text' in buttonJson ? buttonJson.button_text : '',
    url: 'button_href' in buttonJson ? buttonJson.button_href : '',
    content: 'help_text' in buttonJson ? buttonJson.help_text : '',
  };
}

export default {
  fetchInvestmentMetricFromData,
  parseSmartButton,
};
