/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  withRouter,
} from 'react-router-dom';

import {
  ScrollToTopOnMount,
  Data,
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Link,
  StyledButton,
  Modal,
  StyledTable,
  Body,
  Theme,
} from '../../coreui';

import actions from '../actions';
import DashboardNavLink from '../components/DashboardNavLink';
import DashboardTableCell from '../components/DashboardTableCell';
import TaxcenterFilterDropdown from '../components/TaxcenterFilterDropdown';
import { createStatusComponent } from '../components/TaxcenterStatus';
import { TaxcenterCheckbox, createTaxcenterCheckbox } from '../components/TaxcenterCheckbox';
import TableHeader from '../components/TableHeader';
import DashboardNotifications from '../components/DashboardNotifications';
import DashboardLoader from '../components/DashboardLoader';
import RedirectToLoginOnLoggedOut from '../../components/RedirectToLoginOnLoggedOut';

const filterSpacing = {
  ml: [0, 0, 10, '2%'],
  mr: 10,
};

// Configuration for the table outputs.
// This defines different blocks that are shown / hidden for each configuration
// object in the table outputs array.
const tableOutputs = [
  {
    types: ['xs'],
  },
  {
    types: ['s', 'm'],
  },
  {
    types: ['l', 'xl'],
  },
];
// filtering
//
class TaxcenterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterModalOpen: false,
      selected: 0,
      allSelected: false,
    };

    this.handleDocumentsDownload = this.handleDocumentsDownload.bind(this);
    this.handleDocumentsSelectAll = this.handleDocumentsSelectAll.bind(this);
    this.handleFilterModalToggle = this.handleFilterModalToggle.bind(this);
  }

  componentDidMount() {
    this.props.getTaxcenterApi();
    this.props.getPendingData();
  }

  handleDocumentsDownload() {
    if (this.state.selected > 0 || this.state.allSelected) {
      this.props.documentsDownload();
    }
  }

  handleDocumentsSelectAll() {
    this.props.documentToggleAll();
    this.setState((state) => ({
      allSelected: !state.allSelected,
    }));
    if (!this.state.allSelected) {
      // If all documents have been deselected reset the selected counter to 0.
      this.setState({
        selected: 0,
      });
    }
  }

  handleFilterModalToggle() {
    this.setState((state) => ({
      filterModalOpen: !state.filterModalOpen,
    }));
  }

  render() {
    const TaxcenterTableConfig = [
      {
        Header: () => {
          return (
            <TaxcenterCheckbox
              handleClick={this.handleDocumentsSelectAll}
              checked={this.props.selectAll}
            />
          );
        },
        maxWidth: 124,
        accessor: 'selectBox',
        Cell: createTaxcenterCheckbox,
        sortable: false,
        resizable: false,
        hidden: ['xs', 's', 'm'],
      },
      {
        Header: () => <TableHeader>Account</TableHeader>,
        accessor: 'account',
        Cell: DashboardTableCell(),
        minWidth: 200,
        hidden: ['xs'],
      },
      {
        Header: () => <TableHeader>Issuer</TableHeader>,
        accessor: 'issuer',
        Cell: DashboardTableCell(),
        minWidth: 150,
        hidden: ['xs', 's', 'm'],
      },
      {
        Header: () => <TableHeader>Investment</TableHeader>,
        accessor: 'investment',
        Cell: DashboardTableCell(), // link
        minWidth: 150,
      },
      {
        Header: () => <TableHeader>Document Type</TableHeader>,
        accessor: 'docType',
        Cell: DashboardTableCell(),
        width: 175,
        hidden: ['xs', 's', 'm'],
      },
      {
        Header: () => {
          return (
            <TableHeader
              justify="center"
            >
              Document Status
            </TableHeader>
          );
        },
        accessor: 'status',
        Cell: createStatusComponent,
        width: 250,
      },
    ];

    const tableData = this.props.data.map((item) => {
      const selectWithHandler = {
        ...item.selectBox,
        handleClick: () => {
          this.props.documentSelected(item.selectBox.id);
          this.setState((state) => {
            let newState = state.selected;
            if (item.selectBox.selected) {
              // The checkbox was selected to start. So this is an unchecking.
              newState -= 1;
            } else {
              newState += 1;
            }
            return {
              selected: newState,
            };
          });
        },
      };
      return {
        ...item,
        selectBox: selectWithHandler,
      };
    });

    const downloadLink = () => {
      let disabled = {
        style: {
          cursor: 'not-allowed',
        },
        color: Theme.colors.gray[1],
        hoverColor: Theme.colors.gray[1],
        bg: Theme.colors.gray[10],
        hoverBG: Theme.colors.gray[10],
      };
      if (this.state.selected > 0 || this.state.allSelected) {
        disabled = {};
      }
      return (
        <ExtendedFlex
          width="100%"
          justifyContent="space-between"
        >
          <Link
            type="L1mini"
            textTransform="uppercase"
            minWidth={150}
            maxWidth="none"
            width="auto"
            px={[20, 20, 20, 40]}
            onClick={this.handleDocumentsDownload}
            {...disabled}
          >
            Download Selected
          </Link>
        </ExtendedFlex>
      );
    };

    const {
      loaded,
      pendingData,
    } = this.props;

    return (
      <ExtendedFlex
        w={1}
        flexDirection="column"
        align="center"
      >
        <DashboardNotifications pendingData={pendingData} />
        <ExtendedFlex
          flexDirection="column"
          width={1}
          maxWidth={1700}
          alignItems="center"
          {...(!loaded ? {
            minHeight: '100vh',
          } : {})}
        >
          <ScrollToTopOnMount />
          <RedirectToLoginOnLoggedOut />
          <Heading
            type="H2"
            my="0px"
            pt="100px"
            pb="0px"
          >
            My Dashboard
          </Heading>
          <DashboardNavLink />
          <ExtendedFlex
            w={1}
            px={50}
          >
            <ExtendedFlex
              w={1}
              borderBottom="3px solid #176DB6"
              justifyContent="center"
            >
              <ExtendedFlex
                w={[1, '90%']}
                mt={[50, 50, 20]}
                mb={20}
                justifyContent="space-between"
                alignItems="center"
              >
                <Heading
                  type="H3"
                  m={['auto', '0px', '0px', '0px', '0px']}
                >
                  Tax Center
                </Heading>
              </ExtendedFlex>
            </ExtendedFlex>
          </ExtendedFlex>
          <ExtendedFlex
            width="100%"
            px={50}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <ExtendedBox
              w={1}
              display={['block', 'block', 'none']}
            >
              <ExtendedFlex
                w={1}
                my={30}
                justify="space-between"
                align="center"
                flexDirection={['column', 'row', 'row', 'row', 'row']}
              >
                <StyledButton
                  type="L3mini"
                  minWidth={150}
                  maxWidth="none"
                  onClick={this.handleFilterModalToggle}
                >
                  Filters
                </StyledButton>
                <StyledButton
                  type="L4"
                  onClick={this.props.resetFilters}
                  mt={[20, 0, 0, 0, 0]}
                >
                  Reset
                </StyledButton>
              </ExtendedFlex>
              <Modal
                isVisible={this.state.filterModalOpen}
                handleClick={this.handleFilterModalToggle}
                full
              >
                <ExtendedFlex
                  flexDirection="column"
                  align="center"
                  justify="space-between"
                  minHeight="calc(100vh - 80px)"
                >
                  <ExtendedFlex
                    flexDirection="column"
                    align="center"
                    minHeight="60vh"
                  >
                    <Heading
                      type="H3"
                      textAlign="center"
                    >
                      Filter Tax Center
                    </Heading>
                    <Data
                      type="D1"
                    >
                      Tax Year
                    </Data>
                    <TaxcenterFilterDropdown
                      options={this.props.taxYearOptions}
                      selected={this.props.taxYear}
                      handleChange={this.props.updateFilterModal}
                      name="taxYear"
                    />
                    <Data
                      type="D1"
                      mt={40}
                    >
                      Account
                    </Data>
                    <TaxcenterFilterDropdown
                      options={this.props.accountOptions}
                      selected={this.props.account}
                      handleChange={this.props.updateFilterModal}
                      name="account"
                    />
                    <Data
                      type="D1"
                      mt={40}
                    >
                      Document Type
                    </Data>
                    <TaxcenterFilterDropdown
                      options={this.props.docTypeOptions}
                      selected={this.props.docType}
                      handleChange={this.props.updateFilterModal}
                      name="docType"
                    />
                  </ExtendedFlex>
                  <ExtendedFlex
                    mt={30}
                    flexDirection="column"
                    align="center"
                    justify="center"
                  >
                    <StyledButton
                      type="L1"
                      mb={30}
                      onClick={() => {
                        this.handleFilterModalToggle();
                        this.props.applyFilters();
                      }}
                    >
                      Apply
                    </StyledButton>
                    <StyledButton
                      type="L4"
                      onClick={() => {
                        this.handleFilterModalToggle();
                        this.props.resetFilters();
                      }}
                    >
                      Reset
                    </StyledButton>
                  </ExtendedFlex>
                </ExtendedFlex>
              </Modal>
            </ExtendedBox>

            <ExtendedFlex
              w={['auto', 'auto', 'auto', 1, 1]}
              boxSizing="border-box"
              mx={[-50, -50, -50, 0, 0]}
              px="calc(4% + 15px)"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              display={['none', 'none', 'flex']}
            >
              <ExtendedFlex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                py="30px"
                flexWrap="wrap"
              >
                <ExtendedFlex
                  width={[1, 1, 1, 'auto', '75%']}
                  justifyContent="flex-start"
                  alignItems="center"
                  py="30px"
                >
                  <Data
                    type="D1"
                    textTransform="uppercase"
                    {...filterSpacing}
                    ml={0}
                  >
                    Tax Year
                  </Data>
                  <TaxcenterFilterDropdown
                    options={this.props.taxYearOptions}
                    selected={this.props.taxYear}
                    handleChange={this.props.updateFilter}
                    name="taxYear"
                    data-test="taxYear"
                  />
                  <Data
                    type="D1"
                    textTransform="uppercase"
                    {...filterSpacing}
                  >
                    Account
                  </Data>
                  <TaxcenterFilterDropdown
                    options={this.props.accountOptions}
                    selected={this.props.account}
                    handleChange={this.props.updateFilter}
                    name="account"
                    data-test="account"
                  />
                  <Data
                    type="D1"
                    textTransform="uppercase"
                    {...filterSpacing}
                  >
                    Document Type
                  </Data>
                  <TaxcenterFilterDropdown
                    options={this.props.docTypeOptions}
                    selected={this.props.docType}
                    handleChange={this.props.updateFilter}
                    name="docType"
                    mr={[0, 0, 0, 10]}
                    data-test="docType"
                  />
                </ExtendedFlex>
                <StyledButton
                  type="L4"
                  onClick={this.props.resetFilters}
                >
                  Reset
                </StyledButton>
              </ExtendedFlex>
            </ExtendedFlex>
            <ExtendedBox
              w={1}
              pb="140px"
            >
              {
                loaded &&
                <>
                  <ExtendedBox
                    w={['auto', 'auto', 'auto', 'auto', 1]}
                    mx={[-50, -50, -50, 0, 0]}
                  >
                    <ExtendedBox
                      overflowX="scroll"
                      width="auto"
                    >
                      <StyledTable
                        data={tableData}
                        pageSize={this.props.data.length}
                        columns={TaxcenterTableConfig}
                        sortable
                        showPagination={false}
                        NoDataComponent={() => { return null; }}
                        tableOutputs={tableOutputs}
                      />
                    </ExtendedBox>
                  </ExtendedBox>

                  {
                    (this.props.data.length === 0) &&
                    <ExtendedFlex
                      w="calc(92% - 30px)"
                      px={[0, 50, 50, 0, 0]}
                      mx="auto"
                      flexDirection="column"
                      align="flex-start"
                    >
                      <Body
                        type="B1"
                      >
                        You have no tax items.
                      </Body>
                    </ExtendedFlex>
                  }
                  <ExtendedBox
                    mt={30}
                    mx={[-50, -50, -50, 0, 0]}
                    boxSizing="border-box"
                    px="calc(4% + 15px)"
                    display={['none', 'none', 'block']}
                  >
                    { downloadLink() }
                  </ExtendedBox>
                  <ExtendedBox
                    mt={30}
                    display={['block', 'block', 'none']}
                  >
                    { downloadLink() }
                  </ExtendedBox>
                </>
              }
              <DashboardLoader loaded={loaded} />
            </ExtendedBox>
          </ExtendedFlex>
        </ExtendedFlex>
      </ExtendedFlex>
    );
  }
}

TaxcenterPage.propTypes = {
  loaded: PropTypes.bool,
  taxYear: PropTypes.number,
  docType: PropTypes.string,
  account: PropTypes.string,
  data: PropTypes.array,
  getTaxcenterApi: PropTypes.func,
  documentSelected: PropTypes.func,
  documentToggleAll: PropTypes.func,
  applyFilters: PropTypes.func,
  updateFilter: PropTypes.func,
  updateFilterModal: PropTypes.func,
  resetFilters: PropTypes.func,
  documentsDownload: PropTypes.func,
  selectAll: PropTypes.bool,
  taxYearOptions: PropTypes.array,
  docTypeOptions: PropTypes.array,
  accountOptions: PropTypes.array,
  getPendingData: PropTypes.func,
  pendingData: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    loaded: state.taxcenter.loaded,
    taxYear: state.taxcenter.taxYear,
    docType: state.taxcenter.docType,
    account: state.taxcenter.account,
    data: state.taxcenter.data,
    selectAll: state.taxcenter.selectAll,
    taxYearOptions: state.taxcenter.taxYearOptions,
    docTypeOptions: state.taxcenter.docTypeOptions,
    accountOptions: state.taxcenter.accountOptions,
    pendingData: state.dashboard.pendingData.pendingData.investments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetFilters: () => {
      dispatch(actions.taxcenterResetRequested());
    },
    documentToggleAll: () => {
      dispatch(actions.documentToggleAll());
    },
    documentSelected: (id) => {
      dispatch(actions.documentSelected(id));
    },
    applyFilters: () => {
      dispatch(actions.taxcenterApplyFilters());
    },
    updateFilter: (name, value) => {
      dispatch(actions.taxCenterFilterRequested(name, value));
    },
    updateFilterModal: (name, value) => {
      dispatch(actions.taxcenterUpdateFilterModal(name, value));
    },
    getTaxcenterApi: () => {
      dispatch(actions.taxcenterApiActionRequested());
    },
    documentsDownload: () => {
      dispatch(actions.taxCenterDownloadsRequested());
    },
    getPendingData: () => {
      dispatch(actions.pendingDashboardDataRequested());
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaxcenterPage));
