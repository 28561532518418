import styled from 'styled-components';

export default {
  SVG: styled.svg`
    ${({ fill }) => fill && `fill: ${fill}`};
  `,
  Image: styled.img`
    width: ${({ width = '100%' }) => width};
    display: inline-block;
    ${({ fill }) => ['#FFF', '#FFFFFF', 'white'].findIndex((i) => i === fill) > -1 && 'filter: invert(1);'}
  `,
};
