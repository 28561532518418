import React from 'react';
import PropTypes from 'prop-types';

import {
  Body,
  ExtendedFlex,
} from '../../coreui';

const InvestmentFilterButtons = ({ filters }) => {
  return (
    <>
      {
        filters.map((filter) => {
          return (
            <ExtendedFlex
              border="1px solid #202020"
              minWidth="180px"
              px="1em"
              mr="10px"
              pointer="cursor"
              alignItems="center"
            >
              <Body
                type="B5"
                onClick={filter.handleClick}
                m="0px"
              >
                x { filter.value }
              </Body>
            </ExtendedFlex>
          );
        })
      }
    </>
  );
};

InvestmentFilterButtons.propTypes = {
  filters: PropTypes.array,
};

export default InvestmentFilterButtons;
