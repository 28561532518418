import React from 'react';
import {
  Body,
  Heading,
  StyledButton,
  ExtendedBox,
  InlineTextLink,
} from '../coreui';

const FiveHundred = () => {
  function handleClick(e) {
    e.preventDefault();
    window.location.reload();
  }

  return (
    <ExtendedBox
      maxWidth={1440}
      px="10%"
      m="auto"
      mt={[25, 70, 90, 110]}
      pb={[25, 70, 90, 150]}
    >
      <Heading
        type="H2"
        color="blue.1"
        textTransform="uppercase"
        display="block"
        textAlign="left"
      >
        500: Internal Server Error.
      </Heading>
      <Body
        type="B1"
        display="block"
      >
        Please try again later, or contact us if the problem persists.
      </Body>
      <Body type="B2">
        If you need immediate assistance, please contact us:
      </Body>
      <Body type="B5">
        <p>(877) 977-2776</p>
        <InlineTextLink href="mailto:help@realtymogul.com">help@realtymogul.com</InlineTextLink>
      </Body>
      <ExtendedBox
        mt={80}
        mb={80}
      >
        <StyledButton
          onClick={handleClick}
          type="L3"
          textTransform="uppercase"
          display="block"
        >
          Refresh Page
        </StyledButton>
      </ExtendedBox>
    </ExtendedBox>
  );
};

export default FiveHundred;
