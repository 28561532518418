import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
} from '../../../coreui';

const FinancingAdvantage2 = ({
  isVisible,
}) => {
  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
    >
      <ExtendedFlex
        flexDirection="column"
      >
        <ExtendedBox
          m={0}
          pb={30}
          bg="gray.1"
        >
          <ExtendedFlex
            flexDirection="column"
            justify="center"
            maxWidth={[null, null, 520, 520, 660]}
            height={[350, 300, 400, 500, 500]}
            px={['15%', '25%', 60, 100, 120]}
          >
            <Heading
              type="H3"
              textTransform="uppercase"
              m={0}
              mb="0.7em"
              opacity={isVisible ? '1' : '0'}
              transition={`
                opacity 0.5s ${!isVisible ? '0' : '0.3'}s linear
              `}
            >
              FLEXIBLE TERMS
            </Heading>
            <Body
              type="B1"
              m={0}
              opacity={isVisible ? '1' : '0'}
              transition={`
                opacity 0.5s ${!isVisible ? '0' : '0.6'}s linear
              `}
            >
              As a source of capital for commercial real estate,
              we fund projects nationwide with Joint Venture Equity
              and Preferred Equity. Our agile structure means we are
              able to close in as few as 30 days to keep projects
              moving forward.
            </Body>
          </ExtendedFlex>
        </ExtendedBox>
        <ExtendedBox
          mb={30}
        >
          <ExtendedFlex
            flexDirection="column"
            justify="center"
            maxWidth={[null, null, 520, 520, 660]}
            height={[300, 300, 300, 375, 400]}
            px={['15%', '25%', 60, 100, 120]}
          >
            <Heading
              type="H3"
              textTransform="uppercase"
              m={0}
              mb="0.7em"
              opacity={isVisible ? '1' : '0'}
              transition={`
                opacity 0.5s ${!isVisible ? '0' : '0.3'}s linear
              `}
            >
              ELEVATED SERVICE
            </Heading>
            <Body
              type="B1"
              m={0}
              opacity={isVisible ? '1' : '0'}
              transition={`
                opacity 0.5s ${!isVisible ? '0' : '0.6'}s linear
              `}
            >
              With a community of investors ready to put their
              money to work, RealtyMogul serves as the capital
              markets arm to qualified real estate companies.
            </Body>
          </ExtendedFlex>
        </ExtendedBox>
      </ExtendedFlex>
    </Box>
  );
};

FinancingAdvantage2.propTypes = {
  isVisible: PropTypes.bool,
};

export default FinancingAdvantage2;
