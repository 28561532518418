import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Body,
  Image,
  MiniEssay,
} from '../../coreui';

import VisibilityTrigger from '../../components/VisibilityTrigger';
import FadeIn from '../../components/FadeIn';
import Divider from '../../components/Divider';

const iconWidth = [50, 73];
const iconHeight = [50, 73];

const OurStory = ({
  heading,
  body,
  insights,
}) => {
  return (
    <ExtendedFlex
      flexDirection="column"
      align="center"
      maxWidth={1500}
      my={[50, 50, 140]}
      mx="auto"
    >
      <VisibilityTrigger
        trigger="isVisible"
      >
        <MiniEssay
          header={heading}
          paragraphsOfText={body}
          animatedFadeIn
        />
      </VisibilityTrigger>
      <ExtendedFlex
        flexDirection={['column', 'column', 'row', 'row', 'row']}
        justify="space-around"
        width={[1, 1, 600, 840, 1160]}
        mt={[10, 20, 40, 50, 50]}
        mb={0}
        px={50}
      >
        {
          insights.map((insight, index) => {
            return (
              <div
                key={insight.text}
              >
                <FadeIn
                  fadeInIndex={5 + index}
                >
                  <ExtendedFlex
                    flexDirection="column"
                    align="center"
                    justifyContent="center"
                    flex="1 1 0%"
                  >
                    <Image
                      src={insight.icon}
                      w={iconWidth}
                      height={iconHeight}
                    />
                    <Body
                      type="B1"
                      textAlign="center"
                      w={1}
                    >
                      {insight.text}
                    </Body>
                  </ExtendedFlex>
                </FadeIn>
                {
                  index + 1 < insights.length &&
                  <FadeIn
                    fadeInIndex={4}
                  >
                    <Divider />
                  </FadeIn>
                }
              </div>
            );
          })
        }
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

OurStory.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  insights: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
  })),
};

export default OurStory;
