import React from 'react';

import {
  Body,
  Heading,
} from '../../coreui';

import CalloutFrame from '../../components/CalloutFrame';
import ModalWithOverlay from './ModalWithOverlay';
import ExclusiveAccessImage from '../assets/img_compare.jpg';
import ModalLink from './ModalLink';
import Graph from '../assets/table_compare.png';

class ComparingReits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      modalContent: null,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(modalContent) {
    this.setState((state) => ({
      isModalVisible: !state.isModalVisible,
      modalContent: state.isModalVisible ? null : modalContent,
    }));
  }

  render() {
    const {
      isModalVisible,
      modalContent,
    } = this.state;

    return (
      <CalloutFrame
        image={ExclusiveAccessImage}
        bg="gray.0"
        imagePosition="second"
      >
        <Heading
          type="H2"
          mt={0}
          textTransform="uppercase"
        >
          Comparing Available REITS
        </Heading>
        <Body
          type="B1"
          mb={30}
        >
          In the broader investment market, investors
          have access to three types of REITs
        </Body>
        <Body
          type="B1"
          m={0}
        >
          • Public non-traded REITs
        </Body>
        <Body
          type="B1"
          m={0}
        >
          • Public traded REITs
        </Body>
        <Body
          type="B1"
          mt={0}
          mb={[40, 60, 80, 80, 80]}
        >
          • Private REITS (Non-traded)
        </Body>
        <ModalLink
          image={Graph}
          handleClick={this.toggleModal}
          title="Compare &gt;"
        />
        <>
          <ModalWithOverlay
            isVisible={isModalVisible}
            handleClick={this.toggleModal}
          >
            {modalContent}
          </ModalWithOverlay>
        </>
      </CalloutFrame>
    );
  }
}

export default ComparingReits;
