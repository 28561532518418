/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';

import {
  ScrollToTopOnMount,
  ExtendedFlex,
  Heading,
} from '../coreui';

import DisclaimerContent from '../components/DisclaimerContent';

import DisclosureContent from './components/Disclosure';

export const Disclosure = () => {
  return (
    <ExtendedFlex
      bg="#FFFFFF"
      flexDirection="column"
      justify="center"
      align="left"
      px="10%"
      py="5%"
      w={1}
    >
      <ScrollToTopOnMount />
      <Heading type="H6">Disclosure</Heading>
      <DisclaimerContent>
        <DisclosureContent />
      </DisclaimerContent>
    </ExtendedFlex>
  );
};
export default Disclosure;
