// Collection of all the content that is used in the slides.
// Array is consumed by a .map and displayed in order by carousel.

import pictureStep1 from '../../assets/HIW_steps_1.jpg';
import pictureStep2 from '../../assets/HIW_steps_2.png';
import pictureStep3 from '../../assets/HIW_steps_1.png';
import pictureStep4 from '../../assets/HIW_steps_4.jpg';

const slideCollection = [
  {
    slideStepTitle: 'Step 1',
    mainTitle: 'Create Your Account',
    bodyContent: 'Complete your account profile at RealtyMogul.com. The process is quick and simple.',
    image: pictureStep1,
  },
  {
    slideStepTitle: 'Step 2',
    mainTitle: 'Select Your Investment',
    bodyContent: 'Once you join, you have exclusive access to current eligible investments and can view past performance for historical individual deals. Key investment details are presented at a glance.  Explore opportunities that align with your personal investment goals.',
    image: pictureStep2,
  },
  {
    slideStepTitle: 'Step 3',
    mainTitle: 'Invest in Minutes',
    bodyContent: 'When you are ready to invest, complete your transaction online.',
    image: pictureStep3,
  },
  {
    slideStepTitle: 'Step 4',
    mainTitle: 'Track Your Investments',
    bodyContent: 'You have full visibility into the performance of your investment in your account dashboard, so you can track your total current invested value and distributions. You will also receive quarterly investment reports and annual tax documents.',
    image: pictureStep4,
  },
];

export default slideCollection;
