/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Data,
} from '../../../coreui';

const TableHeader = ({
  children,
  dataProps,
  ...additionalProps
}) => {
  return (
    <ExtendedFlex
      align="center"
      {...additionalProps}
    >
      <Data
        type="D5"
        m={0}
        {...dataProps}
      >
        {children}
      </Data>
    </ExtendedFlex>
  );
};

TableHeader.propTypes = {
  children: PropTypes.node,
  dataProps: PropTypes.object,
};

export default TableHeader;
