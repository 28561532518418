import styled from 'styled-components';

export default {
  ReitOverview: styled.div`
    width: 100%;
    padding: 415px 16px 80px;
    box-sizing: border-box;
    display: block;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    color: #202020;
    background: ${({ background }) => `${background}`};
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      padding: 220px 100px 120px;
    }
  `,
  Container: styled.div`
  max-width: 1024px;
  margin: 0 auto;
  `,
  DescriptionTile: styled.div`
    h1 {
      font-family: mrs-eaves-xl-serif-narrow,serif;
      margin: 0;
      font-weight: 800;
      font-size: 28px;
      line-height: 32px;
    }
    p {
      font-size: 16px;
      line-height: 28px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      h1 {
        font-size: 44px;
        line-height: 56px;
      }
      p {
        font-size: 18px;
      line-height: 32px;
      }
    }
  `,
  ContentTile: styled.div`
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    flex-wrap: wrap;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      padding-top: 20px;
    }
  `,
  Item: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    margin-bottom: 30px;
    .icon {
      min-height: 65px;
    }
    .title {
      font-family: mrs-eaves-xl-serif-narrow,serif;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      margin-top: 20px;
      margin-bottom: 8px;
    }
    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      box-sizing: border-box;
      padding-right: 20px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      width: 50%;
      margin-bottom: 45px;
    }
  `,
  ButtonContainer: styled.div`
    width: 100%;
    margin: 0 auto;
    text-transform: uppercase;
    a {
      white-space: break-spaces;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      width: 65%;
    }
  `,
};
