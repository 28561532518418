import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';

import {
  Heading,
  ExtendedFlex,
  Accordion,
} from '../../coreui';

import actions from './actions';

class QueueFAQItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.props.getQueueFAQItemsDataRequested(this.props.queueName);
  }

  render() {
    const {
      items,
      title,
    } = this.props;
    return (
      <ExtendedFlex
        w={1}
        pb={40}
        justify="center"
        pt={80}
        bg="white"
      >
        <ExtendedFlex
          w={1}
          maxWidth={1600}
          flexDirection="column"
          justify="center"
          align="center"
          px="5%"
        >
          <Heading
            type="H3"
            color="black"
            textTransform="uppercase"
            display="block"
            textAlign="center"
          >
            {title}
          </Heading>
          <Accordion
            items={items}
            contentIsHtml={1}
          />
        </ExtendedFlex>
      </ExtendedFlex>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getQueueFAQItemsDataRequested: (queueName) => {
    dispatch(actions.getQueueFAQItemsDataRequested(queueName));
  },
});

const mapStateToProps = (state) => ({
  items: state.queueFAQItems.items,
});

QueueFAQItems.propTypes = {
  getQueueFAQItemsDataRequested: PropTypes.func,
  items: PropTypes.array,
  title: PropTypes.string,
  queueName: PropTypes.string,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QueueFAQItems));
