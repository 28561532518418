/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
} from '../coreui';

const NotificationContainer = ({
  children,
  ...props
}) => {
  return (
    <ExtendedFlex
      w={1}
      bg="blue.1"
      align="center"
      justify="center"
    >
      <ExtendedFlex
        w={1}
        align="center"
        maxWidth={1540}
        px={50}
        py={[40]}
        {...props}
      >
        {children}
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

NotificationContainer.propTypes = {
  children: PropTypes.object,
};

export default NotificationContainer;
