import React from 'react';

import {
  ExtendedFlex,
  Heading,
  Body,
  Data,
  Link,
} from '../../coreui';

import Divider from '../../components/Divider';
import IframeComponent from './IframeComponent';

const AskUs = () => {
  return (
    <ExtendedFlex
      w={1}
      bg="white"
      justify="center"
      py={[60, 80, 100, 120, 140]}
      px={[40, 80, 100, 120, 140]}
    >
      <ExtendedFlex
        w={1}
        flexDirection={['column', 'column', 'column', 'column']}
        maxWidth={1440}
      >
        <Heading
          type="H3"
          justify="center"
          align="center"
        >
          ASK US DIRECTLY
        </Heading>
        <Body
          type="B4"
          textAlign="left"
          mb={0}
        >
          General Inquiries
        </Body>
        <Divider
          type="horizontal"
        />
        <ExtendedFlex
          flexDirection={['column', 'row', 'row', 'row']}
          justifyContent="space-between"
          width={1}
          maxWidth="1440px"
          my={5}
        >
          <Body
            type="B1"
            width={[280, 750, 750, 750, 750]}
            my={[5, 'auto', 'auto', 'auto', 'auto']}
          >
            Los Angeles Headquarters
          </Body>
          <Body
            type="B1"
            width={[280, 460, 460, 460, 460]}
            my={[5, 'auto', 'auto', 'auto', 'auto']}
          >
            (877) 977-2776
          </Body>
          <Link
            type="L4"
            href="mailto:info@realtymogul.com"
            my={[5, 'auto', 'auto', 'auto', 'auto']}
            width={[280, 815, 650, 755, 450]}
            align={['left', 'right', 'right', 'right', 'right']}
          >
            <Body
              type="B1"
              color="#176DB6"
            >
              info@realtymogul.com
            </Body>
          </Link>
        </ExtendedFlex>
        <Body
          type="B4"
          textAlign="left"
          mb={0}
        >
          Investor Relations
        </Body>
        <Divider
          type="horizontal"
        />
        <ExtendedFlex
          flexDirection={['column', 'row', 'row', 'row']}
          justifyContent="space-between"
          width={1}
          maxWidth="1440px"
          my={5}
        >
          <Body
            type="B1"
            width={[280, 750, 750, 750, 750]}
            my={[5, 'auto', 'auto', 'auto', 'auto']}
          >
            Investor Relations Team
          </Body>
          <Body
            type="B1"
            width={[280, 460, 460, 460, 460]}
            my={[5, 'auto', 'auto', 'auto', 'auto']}
          >
            (877) 781-7062
          </Body>
          <Link
            type="L4"
            href="mailto:investor-help@realtymogul.com"
            my={[5, 'auto', 'auto', 'auto', 'auto']}
            width={[280, 815, 650, 755, 450]}
            align={['left', 'right', 'right', 'right', 'right']}
          >
            <Body
              type="B1"
              color="#176DB6"
            >
              investor-help@realtymogul.com
            </Body>
          </Link>
        </ExtendedFlex>
        <Body
          type="B4"
          textAlign="left"
          mb={0}
        >
          Press Inquiries
        </Body>
        <Divider
          type="horizontal"
        />
        <ExtendedFlex
          flexDirection={['column', 'row', 'row', 'row']}
          justifyContent="space-between"
          width={1}
          maxWidth="1440px"
          my={5}
        >
          <Body
            type="B1"
            width={[280, 750, 750, 750, 750]}
            my={[5, 'auto', 'auto', 'auto', 'auto']}
          >
            PR Team
          </Body>
          <Body
            type="B1"
            width={[280, 460, 460, 460, 460]}
            my={[5, 'auto', 'auto', 'auto', 'auto']}
          >
            (877) 977-2776
          </Body>
          <Link
            type="L4"
            href="mailto:press@realtymogul.com"
            my={[5, 'auto', 'auto', 'auto', 'auto']}
            width={[280, 815, 650, 755, 450]}
            align={['left', 'right', 'right', 'right', 'right']}
          >
            <Body
              type="B1"
              color="#176DB6"
            >
              press@realtymogul.com
            </Body>
          </Link>
        </ExtendedFlex>
        <ExtendedFlex
          w={1}
          flexDirection={['column', 'column', 'column', 'column']}
          maxWidth={1440}
          align="center"
          pt={[40, 40, 60, 60, 80]}
        >
          <Heading
            type="H3"
          >
            VISIT US
          </Heading>
          <Data
            type="D1"
            pb={[40, 40, 60, 60, 80]}
            textAlign="center"
          >
            Realty Mogul Co, #603
            10573 W Pico Blvd.
            Los Angeles, CA 90064
          </Data>
          <IframeComponent />
        </ExtendedFlex>
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

export default AskUs;
