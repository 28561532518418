import {
  takeEvery,
  call,
  all,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import {
  postAutoInvestApi,
} from '../../api';

export function* getEnrollmentDataFlow(action) {
  const { resolve, reject } = action.promise;
  try {
    const response = yield call(postAutoInvestApi, action.data);
    yield all([
      call(resolve, response.data),
      put(
        actions.autoInvestEnrollmentSucceeded(
          response.data,
        ),
      ),
    ]);
  } catch (error) {
    yield all([
      call(reject, error),
      put(
        actions.autoInvestEnrollmentFailed(
          error,
        ),
      ),
    ]);
  }
}

export function* watchAutoInvestEnrollmentRequested() {
  yield takeEvery(
    types.AUTOINVEST_ENROLLMENT_REQUESTED,
    getEnrollmentDataFlow,
  );
}

export default watchAutoInvestEnrollmentRequested;
