import IconProperties from './assets/icon_properties.svg';
import IconWallet from './assets/icon_wallet.svg';
import IconDiversification from './assets/icon_diversification2.svg';

export default {
  basics: {
    heading: 'Understanding the basics of a REIT',
    body: [
      'A Real Estate Investment Trust, or "REIT", is a single investment into a diversified basket of real estate properties',
    ],
    height: [
      '550px', '300px', '350px', '350px', '400px',
    ],
    more: {
      to: '/investment-options',
      children: 'Get Started',
    },
    insights: [
      {
        icon: IconWallet,
        text: 'REITs are legally required to distribute 90% of all taxable income to investors on a yearly basis',
      },
      {
        icon: IconProperties,
        text: 'REITs are often diversified by property type, geography, or multiple categories to achieve strategic objectives',
      },
      {
        icon: IconDiversification,
        text: 'REITs have historically been positively correlated with inflation, which may make them a possible hedge for inflation',
      },
    ],
  },
  performanceHistory: {
    heading: 'A PERFORMANCE HISTORY WORTH CONSIDERING',
    body: [
      'Over the long term, REITS have compared favorably to traditional investment indexes, such as the S&P 500.',
    ],
    legend: 'Annualized Returns (%) as of 12/31/20',
    disclosure: [
      'Bloomberg Barclays US Aggregate Bond TR USD data sourced from Morningstar (https://performance.morningstar.com/Performance/index-c/performance-return.action?t=XIUSA000MC). S&P 500 Index sourced from Yahoo Finance (https://finance.yahoo.com/quote/%5EGSPC/history?p=%5EGSPC). NAREIT Equity REIT Index data sourced from NCREIF (NCREIF.org).',
    ],
    backgroundColor: '#F8F8F8',
  },
  reitData: {
    heading: 'MEET THE REITS',
    paragraphs: ['The focus of The Income REIT is to provide monthly income to investors through contractually obligated interest payments.',
      'The focus of The Apartment Growth REIT is on long-term capital appreciation, in addition to providing quarterly income.',
    ],
    investments: [],
  },

};
