import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';
import { getLatestIsq } from '../../api';

export function* getLatestIsqFlow() {
  try {
    const response = yield call(getLatestIsq);

    yield put(
      actions.getLatestIsqSucceeded(response.data),
    );
  } catch (error) {
    yield put(
      actions.getLatestIsqFailed(
        error,
      ),
    );
  }
}

export function* watchGetLatestIsq() {
  yield takeLatest(
    types.GET_LATEST_ISQ_REQUESTED,
    getLatestIsqFlow,
  );
}

export default watchGetLatestIsq;
