export default [
  // {
  //   header: 'RealtyMogul news',
  //   subHeader: 'We’re making news all the time. Read our latest press releases.',
  //   linkText: 'Visit our press room >',
  //   linkUrl: '/knowledge-center',
  //   borderRight: [0, 0, 0, 1],
  //   borderColor: 'gray.10',
  // },
  {
    header: 'HOW IT WORKS',
    subHeader: 'Learn how RealtyMogul has simplified commercial real estate investing.',
    linkText: 'Learn More >',
    linkUrl: '/how-it-works',
    borderRight: [0, 0, 0, 1],
    borderColor: 'gray.10',
  },
  {
    header: 'FAQS',
    subHeader: 'Looking for more answers? Get all your questions answered here.',
    linkText: 'view all faqs >',
    linkUrl: '/knowledge-center/faqs',
    borderRight: [0, 0, 0, 1],
    borderColor: 'gray.10',
  },
  {
    header: 'Investment Glossary',
    subHeader: 'Get up to speed on investment terminology and meanings.',
    linkText: 'View Glossary >',
    linkUrl: '/knowledge-center/glossary',
  },
];
