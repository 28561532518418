import types from './types';

export default {
  userAccreditationRequested: (data, promise) => ({
    type: types.USER_ACCREDITATION_NEW_REQUESTED,
    data,
    promise,
  }),
  userAccreditationSucceeded: (data) => ({
    type: types.USER_ACCREDITATION_NEW_SUCCEEDED,
    data,
  }),
  userAccreditationFailed: (error) => ({
    type: types.USER_ACCREDITATION_NEW_FAILED,
    error,
  }),
  userGettingToKnowYouDraftRequested: () => ({
    type: types.USER_GETTING_TO_KNOW_YOU_DRAFT_REQUESTED,
  }),
  userGettingToKnowYouDraftFailed: (error) => ({
    type: types.USER_GETTING_TO_KNOW_YOU_DRAFT_FAILED,
    error,
  }),
  userGettingToKnowYouDraftSucceeded: (data) => ({
    type: types.USER_GETTING_TO_KNOW_YOU_DRAFT_SUCCEEDED,
    data,
  }),
  userGettingToKnowYouRequested: (data, promise) => ({
    type: types.USER_GETTING_TO_KNOW_YOU_REQUESTED,
    data,
    promise,
  }),
  userGettingToKnowYouFailed: (error) => ({
    type: types.USER_GETTING_TO_KNOW_YOU_FAILED,
    error,
  }),
  userGettingToKnowYouSucceeded: (data) => ({
    type: types.USER_GETTING_TO_KNOW_YOU_SUCCEEDED,
    data,
  }),
};
