import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Image,
} from '../../../coreui';

import expandIcon from '../../assets/expand.svg';
import collapseIcon from '../../assets/collapse.svg';

const AutoInvestSectionWrapperToggle = ({
  isExpanded,
  expandable,
  handleExpandChange,
  ...props
}) => {
  return (
    <ExtendedFlex
      justify="center"
      align="center"
      hover={expandable}
      // eslint-disable-next-line
      {
        ...(!expandable ? {
          opacity: 0,
        } : {})
      }
      // eslint-disable-next-line
      {...props}
    >
      <ExtendedBox
        w={10}
        h={10}
        ml={['0px', '10px']}
        mr={['10px', '10px']}
        position="relative"
        onClick={handleExpandChange}
        transition="transform 0.3s linear"
        transform={isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'}
        hover={expandable}
      >
        <Image
          position="absolute"
          top={-2}
          left={1}
          src={collapseIcon}
          opacity={isExpanded ? '1' : '0'}
          transition="opacity 0.3s linear"
          hover={expandable}
        />
        <Image
          position="absolute"
          top={-6}
          left={1}
          src={expandIcon}
          hover={expandable}
          opacity={isExpanded ? '0' : '1'}
          transition="opacity 0.3s linear"
        />
      </ExtendedBox>
    </ExtendedFlex>
  );
};

AutoInvestSectionWrapperToggle.propTypes = {
  isExpanded: PropTypes.bool,
  handleExpandChange: PropTypes.func,
  expandable: PropTypes.bool,
};

AutoInvestSectionWrapperToggle.defaultProps = {
  expandable: true,
};

export default AutoInvestSectionWrapperToggle;
