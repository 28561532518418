import getAutoInvestListSaga from './getAutoInvestListSaga';
import postAutoInvestEnrollmentSaga from './postAutoInvestEnrollmentSaga';
import patchAutoInvestmentSaga from './patchAutoInvestmentSaga';
import deleteAutoInvestConfigSaga from './deleteAutoInvestConfigSaga';

export default [
  getAutoInvestListSaga(),
  postAutoInvestEnrollmentSaga(),
  patchAutoInvestmentSaga(),
  deleteAutoInvestConfigSaga(),
];
