import {
  takeLatest,
  call,
  put,
  all,
} from 'redux-saga/effects';

import types from './types';
import actions from './actions';
import { getCareersList, getTestimonials } from '../api';

export function* getDataFlow() {
  try {
    const [careersList, testimonials] = yield all([
      call(getCareersList),
      call(getTestimonials),
    ]);
    yield all([
      put(actions.careerListSucceeded(careersList.data)),
      put(actions.testimonialsSucceeded(testimonials.data)),
    ]);
  } catch (error) {
    yield all([
      put(actions.careerListFailed(error)),
      put(actions.testimonialsFailed(error)),
    ]);
  }
}

export function* watchGetLatestCareersList() {
  yield takeLatest(
    types.CAREER_LIST_REQUESTED,
    getDataFlow,
  );
}
export default [
  watchGetLatestCareersList(),
];
