import React from 'react';
import PropTypes from 'prop-types';
import { Data, Body, InlineTextLink } from '../../../coreui';

const EnrollmentErrors = ({
  title,
  status,
  detail,
}) => {
  return (
    <>
      <Data
        type="D4"
        display="block"
        my={2}
        color="red"
      >
        {status}: {detail}. {title}
      </Data>
      <Body
        type="B2"
      >
        Please try your request again. If you continue to receive this error,
        please contact us
        at <InlineTextLink href="tel:18777817062">(877) 781-7062</InlineTextLink> or
        email: <InlineTextLink type="L4" href="mailto:investor-help@realtymogul.com">investor-help@realtymogul.com</InlineTextLink>
      </Body>
    </>
  );
};

EnrollmentErrors.propTypes = {
  title: PropTypes.string,
  status: PropTypes.number,
  detail: PropTypes.string,
};

export default EnrollmentErrors;
