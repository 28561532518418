import React from 'react';

import ArticleCardImage from '../components/ArticleCardImage';

const articleCardImageFactoryFactory = (props) => {
  const {
    imageUrl,
    id,
    type,
    url,
  } = props;

  const articleCardImageFactory = () => {
    return (
      <ArticleCardImage
        imageUrl={imageUrl}
        url={url}
        id={id}
        type={type}
      />
    );
  };

  return articleCardImageFactory;
};

export default articleCardImageFactoryFactory;
