import React from 'react';

import Icon from './Icon';
import cancelIcon from '../assets/icon_cancel.svg';

const Cancel = (props) => {
  return (
    <Icon
      src={cancelIcon}
      width={15}
      height={15}
      // eslint-disable-next-line
      {...props}
    />
  );
};

export default Cancel;
