import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Formatters,
  Link,
  Image,
  Body,
} from '../../coreui';

import Tabs from '../../components/tabs';
import HeaderComponent from './components/TableHeader';
import StyledTable from './components/StyledTable';
import TableCell from './components/TableCell';
import Modal from './components/Modal';

import dlIcon from '../../assets/icon_download.svg';

const columns = (current, onClick) => {
  return [
    {
      Header: <HeaderComponent>Investment</HeaderComponent>,
      accessor: 'name',
      Cell: TableCell(null, onClick, true),
      resizable: false,
      minWidth: 250,
    },
    {
      Header: <HeaderComponent>Location</HeaderComponent>,
      accessor: 'location',
      Cell: TableCell(),
      maxWidth: 200,
      resizable: false,
    },
    {
      Header: <HeaderComponent>Property Type</HeaderComponent>,
      accessor: 'propertyType',
      Cell: TableCell(),
      maxWidth: 180,
      minWidth: 115,
      resizable: false,
    },
    {
      Header: <HeaderComponent>Investment Type</HeaderComponent>,
      accessor: 'investmentType',
      Cell: TableCell(),
      maxWidth: 200,
      minWidth: 130,
      resizable: false,
    },
    {
      Header: <HeaderComponent>{(current ? 'Weight' : 'Invested')}</HeaderComponent>,
      accessor: (current ? 'weight' : 'amount'),
      Cell: TableCell((current ? Formatters.PERCENTAGE : Formatters.DOLLARS)),
      maxWidth: (current ? 150 : 200),
      resizable: false,
    },
  ];
};

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActiveModalVisible: false,
      isPastModalVisible: false,
    };

    this.slider = {
      active: null,
      past: null,
    };

    this.toggleActiveModal = this.toggleActiveModal.bind(this);
    this.togglePastModal = this.togglePastModal.bind(this);
    this.sliderActive = this.sliderActive.bind(this);
    this.sliderPast = this.sliderPast.bind(this);
  }

  sliderActive(slider) {
    if (this.slider.active === null) {
      this.slider.active = slider;
    }
  }

  sliderPast(slider) {
    if (this.slider.past === null) {
      this.slider.past = slider;
    }
  }

  toggleActiveModal(slide) {
    this.setState((state) => ({
      isActiveModalVisible: !state.isActiveModalVisible,
    }), () => {
      if (this.slider.active !== null && typeof this.slider.active.moveTo === 'function') {
        this.slider.active.moveTo(slide);
      }
    });
  }

  togglePastModal(slide) {
    this.setState((state) => ({
      isPastModalVisible: !state.isPastModalVisible,
    }), () => {
      if (this.slider.past !== null && typeof this.slider.past.moveTo === 'function') {
        this.slider.past.moveTo(slide);
      }
    });
  }

  render() {
    const {
      assets,
      isNew = false,
      updateCurrentSlide = () => {},
      updateCategory = () => {},
    } = this.props;

    const {
      isActiveModalVisible,
      isPastModalVisible,
    } = this.state;

    const tableProps = {
      onSortedChange: () => { },
    };
    return (
      <ExtendedFlex
        justify="center"
        align="center"
        flexDirection="column"
        height="100%"
        pb={isNew ? 0 : 80}
        bg={isNew ? 'transparent' : 'white'}
        w={1}
      >
        <Modal
          assets={assets.currentAssets}
          handleClick={this.toggleActiveModal}
          isVisible={isActiveModalVisible}
          slider={this.sliderActive}
        />
        <Modal
          assets={assets.pastAssets}
          handleClick={this.togglePastModal}
          isVisible={isPastModalVisible}
          slider={this.sliderPast}
        />
        <Tabs isNew={isNew} setTab={updateCategory}>
          <ExtendedBox
            label="Current Investments"
            w="100%"
            key="currentInv"
          >
            <StyledTable
              data={assets.currentAssets}
              columns={columns(true, isNew ? updateCurrentSlide : this.toggleActiveModal, isNew)}
              noDataText="There are no current investments."
              bg={isNew ? 'transparent' : 'white'}
              px={isNew ? 0 : 50}
              defaultSorted={[
                {
                  id: 'weight',
                  desc: true,
                },
                {
                  id: 'name',
                  desc: false,
                },
              ]}
              additionalTableProps={tableProps}
            />
          </ExtendedBox>
          <ExtendedBox label="Past Investments" w="100%" key="pastInv">
            <StyledTable
              data={assets.pastAssets}
              columns={columns(false, isNew ? updateCurrentSlide : this.togglePastModal, isNew)}
              noDataText="There are no past investments."
              bg={isNew ? 'transparent' : 'white'}
              px={isNew ? 0 : 50}
              defaultSorted={[
                {
                  id: 'amount',
                  desc: true,
                },
                {
                  id: 'name',
                  desc: false,
                },
              ]}
            />
          </ExtendedBox>
        </Tabs>
        <ExtendedFlex
          width="100%"
          align="center"
          justifyContent="center"
          flexDirection="column"
          px={[20, 50]}
          mt={30}
        >
          <ExtendedFlex
            w={1}
            px="calc(4% + 15px)"
          >
            <Body
              type="B2"
            >
              For a detailed description of the investments in the portfolio,
              please see your most recent quarterly update, as provided in the
              &quot;Resources&quot; section.
            </Body>
          </ExtendedFlex>
          {
            !isNew &&
            <ExtendedBox
              width={340}
              bg="gray.0"
              mt={30}
            >
              <Link
                type="L4"
                href={assets.snapHref}
                display="flex"
                flexDirection="row"
                alignItems="center"
                background="gray.0"
                py={20}
                px={25}
                width="100%"
                target="_blank"
              >
                <Image
                  src={dlIcon}
                  height="25px"
                  alt="Download"
                  mr={20}
                  style={{
                    cursor: 'pointer',
                  }}
                />
                Portfolio Snapshot
              </Link>
            </ExtendedBox>
          }
        </ExtendedFlex>
      </ExtendedFlex>
    );
  }
}

Portfolio.propTypes = {
  assets: PropTypes.array,
  isNew: PropTypes.bool,
  updateCurrentSlide: PropTypes.func,
  updateCategory: PropTypes.func,
};

export default Portfolio;
