import {
  LOCATION_CHANGE,
} from 'react-router-redux';
import types from './types';
import appWrapperTypes from '../../appWrapper/types';

const initialState = {
  id: 0,
  alias: '',
  label: '',
  type: '',
  image: {
    _links: {
      full: '',
    },
  },
  body: '',
  category: {},
  socialMediaItems: [],
  user: {
    isLoggedIn: false,
    firstName: 'Welcome',
    lastName: '',
  },
  status: null,
  _links: {
    alias: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case appWrapperTypes.GET_USER_DATA_SUCCEEDED:

      return {
        ...state,
        user: action.user,
      };

    case appWrapperTypes.APP_INIT: {
      const user = {
        ...state.user,
        ...action.data.user,
      };
      return {
        ...state,
        user,
      };
    }

    case types.GET_ARTICLE_REQUESTED:
      return {
        ...state,
        status: null,
        alias: action.id,
      };

    case types.GET_ARTICLE_SUCCEEDED:
      return {
        ...state,
        ...action.data.data,
        status: 200,
      };

    case types.GET_ARTICLE_FAILED:
      return {
        ...state,
        status: action.error.response ? action.error.response.status : 500,
      };

    case LOCATION_CHANGE:
      return {
        ...state,
        ...(state._links.alias &&
            action.payload.pathname !== state._links.alias ? {
            status: null,
          } : {}),
      };

    default:
      return {
        ...state,
      };
  }
};
