export const sliderMapping = {
  0: {
    min: 0,
    max: 10000,
  },
  10000: {
    min: 10000,
    mid: 18000,
    max: 25000,
  },
  25000: {
    min: 25000,
    mid: 38000,
    max: 50000,
  },
  50000: {
    min: 50000,
    max: 200000,
  },
  200000: {
    min: 200000,
    max: 1000000,
  },
  1000000: {
    min: 1000000,
    max: 3000000,
    step: 100000,
  },
  3000000: {
    min: 3000000,
    max: 5000000,
    step: 100000,
  },
  5000000: {
    min: 5000000,
    max: 10000000,
    step: 100000,
  },
};

export const incomeOptions = [
  { value: '500000', label: 'Greater than $500,000' },
  { value: '300000', label: '$300,000 - $500,000' },
  { value: '200000', label: '$200,000 - $300,000' },
  { value: '100000', label: '$100,000 - $200,000' },
  { value: '50000', label: '$50,000 - $100,000' },
  { value: '25000', label: '$25,000 - $50,000' },
  { value: '10000', label: '$10,000 - $25,000' },
  { value: '0', label: 'Less than $10,000' },
];

export const netWorthOptions = [
  { value: '10000000', label: 'Greater than $10M' },
  { value: '5000000', label: '$5M - $10M' },
  { value: '3000000', label: '$3M - $5M' },
  { value: '1000000', label: '$1M - $3M' },
  { value: '200000', label: '$200,000 - $1M' },
  { value: '50000', label: '$50,000 - $200,000' },
  { value: '25000', label: '$25,000 - $50,000' },
  { value: '10000', label: '$10,000 - $25,000' },
  { value: '0', label: 'Less than $10,000' },
];

export const netWorthSpecificPropTypeValidation = (props, propsName, componentName) => {
  const netWorth = props.netWorth;
  const netWorthSpecific = props[propsName];
  const sliderMapping = props.sliderMapping;
  if (![null, undefined, ''].includes(netWorthSpecific)
    && Object.prototype.hasOwnProperty.call(sliderMapping, netWorth)) {
    const mapping = sliderMapping[netWorth];
    const min = mapping.min;
    const max = mapping.max;
    if (netWorthSpecific < min || netWorthSpecific > max) {
      return new Error(`Invalid prop \`${propsName}\` \`${netWorthSpecific}\` supplied to ${componentName}. Min: \`${min}\` Max: \`${max}\`. `);
    }
  }
  return null;
};

export const getDefaultNetWorthSpecificValue = (props) => {
  const {
    sliderMapping: {
      [props.netWorth]: {
        min,
        mid,
        max,
        defaultValue: defaultNetWorthSpecificValue,
      } = {},
    },
  } = props;

  if (defaultNetWorthSpecificValue !== undefined) {
    return defaultNetWorthSpecificValue;
  }

  if (mid !== undefined) {
    return mid;
  }

  return (min !== undefined && max !== undefined)
    ? (min + max) / 2
    : undefined;
};

export default {
  sliderMapping,
  incomeOptions,
  netWorthOptions,
};
