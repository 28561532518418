/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Arrow,
  ExtendedFlex,
  ExtendedBox,
  Data,
  Heading,
  Link,
  ScrollToTopOnMount,
  Loader,
} from '../../coreui';

import actions from './actions';

import SearchBar from './components/SearchBar';
import Items from './components/Items';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.match.params.query,
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const propsQuery = new URLSearchParams(this.props.location.search);
    const prevPropsQuery = new URLSearchParams(prevProps.location.search);

    // Check if the page changed.
    if (propsQuery.get('page') !== prevPropsQuery.get('page')) {
      this.fetchData();
    } else if (this.props.match.params.query !== prevProps.match.params.query) {
      this.fetchData();
    }
  }

  fetchData() {
    const query = this.props.match.params.query;

    // Nothing to query?
    if (!query) {
      return;
    }

    // Determine which page number to use.
    const pageNum = (() => {
      const propsQuery = new URLSearchParams(this.props.location.search);
      const queryPage = propsQuery.get('page');

      if (queryPage) {
        const parsedPage = parseInt(queryPage, 10);
        if (!Number.isNaN(parsedPage)) {
          return parsedPage;
        }
      }

      return 0;
    })();

    // Dispatch the event to fetch the search results.
    this.props.getSearchRequested(query, pageNum);
  }

  render() {
    const {
      items,
      prevUrl,
      nextUrl,
      loaded,
      currentPage,
      totalPages,
      match,
    } = this.props;

    const showNoResults = (loaded && totalPages === 0) || !match.params.query;

    return (
      <ExtendedFlex
        w={1}
        flexDirection="column"
        align="center"
        bg="gray.0"
        {...(!loaded ? {
          minHeight: '100vh',
        } : {})}
      >
        {
          !loaded &&
          <ScrollToTopOnMount />
        }
        <ExtendedFlex
          flexDirection="column"
          w={1}
          p={40}
          align="center"
          justify="center"
          pt={0}
        >
          <Heading
            type="H2"
            color="gray.8"
            m={0}
            mt={[30, 70]}
          >
            Search Results
          </Heading>
        </ExtendedFlex>

        <ExtendedFlex
          w={1}
          px={20}
          justifyContent="center"
        >
          <SearchBar
            value={this.state.value}
          />
        </ExtendedFlex>

        {
          // Only show the loader if we're querying.
          !loaded && match.params.query &&
          <Loader />
        }
        {
          // Only display the results if we have at least 1 page.
          loaded && totalPages > 0 &&
          <>

            {/* Search results. */}
            <Items
              items={items}
              addPadding={!nextUrl}
            />

            {/* Pager */}
            <ExtendedFlex
              align="center"
              mt={40}
            >
              {
                prevUrl &&
                <Link
                  to={prevUrl}
                >
                  <Arrow
                    direction="left"
                  />
                </Link>
              }
              {
                // Add in a spacer to keep things centered.
                !prevUrl &&
                <ExtendedBox
                  opacity="0.3"
                >
                  <Arrow
                    direction="left"
                  />
                </ExtendedBox>
              }
              <Data
                type="D2"
                mx={40}
              >
                Page {currentPage + 1}/{totalPages}
              </Data>
              {
                nextUrl &&
                <Link
                  to={nextUrl}
                >
                  <Arrow
                    direction="right"
                  />
                </Link>
              }
              {
                // Add in a spacer to keep things centered.
                !nextUrl &&
                <ExtendedBox
                  opacity="0.3"
                >
                  <Arrow
                    direction="right"
                  />
                </ExtendedBox>
              }
            </ExtendedFlex>
          </>
        }
        {
          // No results condition.
          showNoResults &&
          <Heading
            type="H4"
            mt={60}
          >
            No results found.
          </Heading>
        }

        {
          loaded &&
          <ExtendedFlex
            flexDirection="column"
            w={1}
            p={100}
            align="center"
            justify="center"
            pt={60}
          >
            <Link
              to="/knowledge-center"
              type="L3"
            >
              Back to Knowledge Center
            </Link>
          </ExtendedFlex>
        }
      </ExtendedFlex>
    );
  }
}

const mapStateToProps = (state) => ({
  items: state.knowledgeCenterSearch.items,
  prevUrl: state.knowledgeCenterSearch.prevUrl,
  nextUrl: state.knowledgeCenterSearch.nextUrl,
  loaded: state.knowledgeCenterSearch.loaded,
  currentPage: state.knowledgeCenterSearch.currentPage,
  totalPages: state.knowledgeCenterSearch.totalPages,
});

const mapDispatchToProps = (dispatch) => ({
  getSearchRequested: (query, pageNum) => {
    dispatch(actions.getSearchRequested(query, pageNum));
  },
});

Search.propTypes = {
  loaded: PropTypes.bool,
  items: PropTypes.array,
  getSearchRequested: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  prevUrl: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  nextUrl: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
