import IconCRE from '../../assets/icon_cre.svg';
import IconClock from '../../assets/icon_clock.svg';
import IconCashFlow from '../../assets/icon_cashflow.svg';
import IconQuality from '../../assets/1Well_Leased.svg';
import IconGroundUp from '../../assets/2Partner.svg';
import IconTrackRecord from '../../assets/3Transparency.svg';

import LocationLA from '../../assets/locations/img_location_la.jpg';

export default {
  ourStory: {
    heading: 'Our Founding Insights',
    body: [
      'Real estate has provided generations of investors with wealth. However, it has historically been reserved for those with specialized knowledge, the right connections and access to great amounts of capital. This has kept investing in commercial real estate out of reach for most — until RealtyMogul.',
      'While working in wealth management, RealtyMogul’s CEO Jilliene Helman identified three key insights that separated the wealthiest clients from the rest. Her goal of sharing these insights would soon lead to the founding of RealtyMogul.',
    ],
    insights: [
      {
        icon: IconCRE,
        text: '1. The wealthiest clients were real estate investors',
      },
      {
        icon: IconClock,
        text: '2. Their income was not correlated to their time spent working',
      },
      {
        icon: IconCashFlow,
        text: '3. They focused on passive cash flow through real estate investments',
      },
    ],
  },
  exclusiveAccess: {
    heading: 'EXCLUSIVE ACCESS TO INSTITUTIONAL QUALITY REAL ESTATE',
    body: [
      'The RealtyMogul approach blends personalized service, proven business discipline and data-driven insights. This allows Members to create their own real estate portfolio — diversifying their holdings with investments in individual properties and Real Estate Investment Trusts ("REITs").',
    ],
  },
  wellManaged: {
    heading: 'THE SEARCH FOR BUILDINGS IN LOCATIONS WITH FAVORABLE ECONOMIC TRENDS, RUN BY OPERATORS WITH LOCAL EXPERIENCE',
    body: [
      'On the RealtyMogul platform, you’ll get access to a wide variety of commercial real estate investment opportunities – including multifamily, office, retail and ground-up development – in dozens of markets across the country.',
    ],
    more: {
      to: '/knowledge-center/due-diligence',
      children: 'Learn More',
    },
    insights: [
      {
        icon: IconQuality,
        text: 'Well-leased properties or ground up construction opportunities',
      },
      {
        icon: IconGroundUp,
        text: 'Partner with select real estate partners with proven experience',
      },
      {
        icon: IconTrackRecord,
        text: 'Prioritize transparency at every stage of the process',
      },
    ],
  },
  access: {
    heading: 'INVESTORS DESERVE EXCEPTIONAL ACCESS AND MORE',
    insights: [
      {
        heading: 'CORE BELIEFS',
        items: [
          'Savvy investors should have access to institutional-quality real estate opportunities',
          'Investing in real estate offers a smart way to meet diversified portfolio goals',
          'Investors should have access to dedicated client service professionals',
          'Human intelligence should validate technology-based insights',
        ],
      },
      {
        heading: 'KEY PROMISES',
        items: [
          'Seek to provide RealtyMogul members with a diverse pipeline of real estate investment opportunities',
          'Adhere to strict fundamentals, focusing on the sponsor and the property',
          'Maintain a fully dedicated asset management team that supports each transaction through its lifecycle',
          'Continue investing in data and technology to provide members with an easy-to-use platform',
        ],
      },
    ],
  },
  trackRecord: {
    title: 'THE PLATFORM’S REACH',
    subTitle: 'Via the RealtyMogul platform, access institutional-quality deals.  The straightforward approach focuses on offering smart diversification strategies, generating cash flow, and creating value in office, retail, industrial, self-storage, and apartment buildings.',
    highlights: [
      { description: 'PROPERTY VALUE FINANCED VIA THE REALTYMOGUL PLATFORM' },
      { description: 'REGISTERED REALTYMOGUL PLATFORM MEMBERS MEMBERSHIP BASE' },
      { description: 'APARTMENT UNITS FINANCED VIA THE REALTYMOGUL PLATFORM' },
    ],
  },
  leadership: {
    heading: 'Leadership Team',
    items: [],
  },
  locations: [
    {
      uuid: 'dff1ea88-db02-4450-a30d-87bb59288874',
      image: LocationLA,
      title: 'Los Angeles, CA',
      prefix: 'Realty Mogul, Co, #603',
      address: '10573 W Pico Blvd',
      city: 'Los Angeles',
      state: 'CA',
      zip: '90064',
      phone: '(877) 977-2776',
    },
  ],
};
