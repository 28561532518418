const frontBackStyles = {
  backfaceVisibility: 'hidden',
  display: 'block',
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
};

export default frontBackStyles;
