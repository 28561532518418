import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Image,
  Body,
  MiniEssay,
} from '../../coreui';
/* eslint-disable */

import data from './data';
import VisibilityTrigger from '../../components/VisibilityTrigger';
import Container from '../../components/Container';
import Divider from '../../components/Divider';
import FadeIn from '../../components/FadeIn';
import IconBulletCheck from '../../assets/icon_bullet_check.svg';

const paragraphsOfText = [
  'REITS are typically known for their objective of regular distributions, as well as moderate long-term capital appreciation, though they may offer other potential benefits.',
];

const InvestmentObjectives = ({
  heading,
}) => {
  return (
    <ExtendedBox
      py={[60, 80, 100, 120, 140]}
      maxWidth={1400}
      mx="auto"
    >
      <Container
        align="center"
        type="simple"
      >
        <ExtendedBox
          maxWidth={[null, 450, 600, 800, 1200]}
          pb={[20, 50]}
        >
          <VisibilityTrigger
            trigger="isVisible"
          >
            <MiniEssay
              header="Investment Objectives"
              paragraphsOfText={paragraphsOfText}
              animatedFadeIn
            />
          </VisibilityTrigger>
        </ExtendedBox>
      </Container>
      <Container
        type="simple"
        justifyContent="space-between"
      >
        {
          data.map((data, index) => {
            return (
              <FadeIn>
                <ExtendedBox
                  mt={40}
                  width={[250, 300, 300, 450, 600]}
                  mx="auto"
                >
                        <FadeIn>
                          <Divider
                            type="horizontal"
                          />
                          <ExtendedFlex
                            align="flex-start"
                          >
                            <Image
                              src={IconBulletCheck}
                              w={[11, 12, 13, 15]}
                              h="auto"
                              mt={[5, 5, 6, 7, 10]}
                              mr={13}
                              IEAspectRatioFix
                            />
                            <Body
                              type="B1"
                              display="block"
                              m={0}
                            >
                              {data.item}
                            </Body>
                          </ExtendedFlex>
                        </FadeIn>
                        <FadeIn>
                          <Divider
                            type="horizontal"
                          />
                          <ExtendedFlex
                            align="flex-start"
                          >
                            <Image
                              src={IconBulletCheck}
                              w={[11, 12, 13, 15]}
                              h="auto"
                              mt={[5, 5, 6, 7, 10]}
                              mr={13}
                              IEAspectRatioFix
                            />
                            <Body
                              type="B1"
                              display="block"
                              m={0}
                            >
                              {data.item2}
                            </Body>
                          </ExtendedFlex>
                        </FadeIn>
                        <FadeIn>
                          <Divider
                            type="horizontal"
                          />
                          <ExtendedFlex
                            align="flex-start"
                          >
                            <Image
                              src={IconBulletCheck}
                              w={[11, 12, 13, 15]}
                              h="auto"
                              mt={[5, 5, 6, 7, 10]}
                              mr={13}
                              IEAspectRatioFix
                            />
                            <Body
                              type="B1"
                              display="block"
                              m={0}
                            >
                              {data.item3}
                            </Body>
                          </ExtendedFlex>
                        </FadeIn>
                </ExtendedBox>
              </FadeIn>
            );
          })
        }
      </Container>
    </ExtendedBox>
  );
};

InvestmentObjectives.propTypes = {
  heading: PropTypes.string,

};

export default InvestmentObjectives;
