import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'aws-amplify';
import {
  Authenticator,
  Loading,
  Greetings,
  SignIn,
  SignUp,
  ForgotPassword,
  ConfirmSignUp,
  RequireNewPassword,
} from 'aws-amplify-react';

import {
  ExtendedFlex,
} from '../coreui';

import AuthContainer from './components/AuthContainer';
import AuthHandler from './components/AuthHandler';
import CustomSignIn from './components/CustomSignIn';
import CustomSignUp from './components/CustomSignUp';
import CustomForgotPassword from './components/CustomForgotPassword';
import CustomConfirmSignUp from './components/CustomConfirmSignUp';
import CustomRequireNewPassword from './components/CustomRequireNewPassword';
import CustomLoading from './components/Loading';

import {
  products,
  steps,
  signUpConfig,
  authScreenLabels,
  userPaths,
} from './data';

import actions from './actions';

I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);

const Auth = (props) => {
  const [currentAuthState, setAuthState] = useState(props.screen || 'signIn');

  const onStateChange = (state) => {
    const {
      history,
      location: {
        pathname,
      },
      is403,
      screen,
    } = props;

    if (!is403 && userPaths.includes(pathname)) {
      if (state === 'signIn' && pathname !== '/user/login' && pathname !== '/user/logout') {
        history.replace('/user/login');
      } else if (state === 'signUp' && pathname !== '/user/register') {
        history.replace('/user/register');
      } else if (state === 'forgotPassword' && pathname !== '/user/password') {
        history.replace('/user/password');
      }
    }
    if (screen) {
      setAuthState(screen);
    }
  };

  useEffect(() => {
    const {
      screen,
      location: {
        pathname,
      },
    } = props;
    let authState = 'signIn';
    if (['/user/register', '/register'].includes(pathname)) {
      authState = 'signUp';
    } else if (pathname === '/user/password') {
      authState = 'forgotPassword';
    } else if (pathname === '/user/logout') {
      authState = 'signOut';
    }
    if (screen) {
      authState = screen;
    }
    setAuthState(authState);
  }, [props]);

  useEffect(() => {
    if (currentAuthState === 'forgotPasswordSuccess') {
      Auth.changeState('signIn').then(() => {
        Auth.error('Forgot password success');
        Auth.changeState('signIn');
        setAuthState(() => 'signIn');
      });
    }
  }, [currentAuthState]);

  const {
    is403,
    session,
    authError,
    children,
    userRegisterRequested,
    screen,
    closeOverlayFunc,
  } = props;

  if (typeof session !== 'boolean') {
    return <CustomLoading />;
  }

  return (
    <ExtendedFlex
      w={1}
      align="center"
      justify="center"
      flexDirection="column"
      boxSizing="border-box"
      key={currentAuthState}
    >
      <Authenticator
        container={AuthContainer}
        hide={[
          SignIn,
          SignUp,
          ConfirmSignUp,
          ForgotPassword,
          Loading,
          Greetings,
          RequireNewPassword,
        ]}
        includeGreetings={false}
        signUpConfig={signUpConfig}
        authState={currentAuthState}
        onStateChange={onStateChange}
        key={props.screen}
      >
        <CustomSignIn
          is403={is403}
          showNew={screen === 'signIn'}
        />
        <CustomSignUp
          signUpConfig={signUpConfig}
          products={products}
          steps={steps}
          showNew={screen === 'signUp'}
          userRegisterRequested={userRegisterRequested}
        />
        <CustomRequireNewPassword />
        <CustomForgotPassword />
        <CustomConfirmSignUp />
        <AuthHandler
          is403={is403}
          session={session}
          authError={authError}
          closeOverlayFunc={closeOverlayFunc}
        />
        {
          // Output the children or a React.Fragment. The Authenticator
          // wrapper chokes on null or false values because it clones the
          // children.
          // eslint-disable-next-line
          children || <React.Fragment />
        }
      </Authenticator>
    </ExtendedFlex>
  );
};

const mapStateToProps = (state) => ({
  session: state.auth.session,
  authError: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
  userRegisterRequested: (data) => {
    dispatch(actions.userRegisterRequested(data));
  },
});

Auth.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  children: PropTypes.object,
  is403: PropTypes.bool,
  session: PropTypes.bool,
  userRegisterRequested: PropTypes.func,
  authError: PropTypes.object,
  screen: PropTypes.string,
  closeOverlayFunc: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
