import React from 'react';
import {
  withRouter,
} from 'react-router-dom';

import {
  Link,
} from '../../coreui';

import {
  dashboardUrl,
} from '../../dashboard/links';

import Section from '../../ioDetails/components/section';

const Back = () => {
  return (
    <Section
      alignSelf="flex-start"
      py={60}
    >
      <Link
        type="L4"
        to={dashboardUrl}
        ml={[40, 40, 40, 40, 0]}
      >
        &lt; Back
      </Link>
    </Section>
  );
};

export default withRouter(Back);
