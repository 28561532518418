/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import get from 'lodash/get';

import {
  enrollmentSteps as steps,
} from '../data';

import {
  ExtendedFlex,
  Heading,
} from '../../../coreui';

import StepCounter from './StepCounter';
import Actions from '../../../components/forms/Actions';
import PrimaryButton from '../../../components/forms/PrimaryButton';
import CancelButtonWithModal from './CancelButtonWithModal';
import Form from '../../../components/forms/Form';
import AutoInvestContentWrapper from './AutoInvestContentWrapper';
import EnrollmentErrors from './EnrollmentErrors';
import ConfirmModal from './ConfirmModal';
import CheckboxAuthorizeDebit from './form/CheckboxAuthorizeDebit';
import SelectBankAccount from './form/SelectBankAccount';
import RecurringAmount from './form/RecurringAmount';

class Investment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlyAmount: null,
      bankAccount: get(this.props, 'bankAccountValues[0].value'),
      isModalVisible: false,
    };

    this.amountInput = undefined;

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();

    if (this.state.isModalVisible) {
      this.props.handleSubmit({
        requestedAmount: Number.parseInt(this.state.monthlyAmount, 10),
        bankAccount: Number.parseInt(this.state.bankAccount, 10),
      });
    } else {
      this.setState({
        isModalVisible: true,
      });
    }
  }

  render() {
    const {
      minPerMonth,
      maxPerMonth,
      isAccredited,
      bankAccountValues,
      bankAccounts,
      submitting,
      enrollmentErrors,
      handleCancel,
      cancelBody,
    } = this.props;

    return (
      <AutoInvestContentWrapper>
        <StepCounter steps={steps} activeStep={2} />
        <Form onSubmit={(e) => this.handleFormSubmit(e)}>
          <ExtendedFlex flexDirection="column" w={1} maxWidth={650}>
            <Heading type="H2">Investment Information</Heading>
            <RecurringAmount
              minPerMonth={minPerMonth}
              maxPerMonth={maxPerMonth}
              isAccredited={isAccredited}
              forwardedRef={(ref) => {
                this.amountInput = ref;
              }}
              onChange={(e) => {
                this.setState({
                  monthlyAmount: e.target.value,
                });
              }}
            />
            <SelectBankAccount
              value={this.state.bankAccount}
              options={bankAccountValues}
              onChange={(e) => {
                this.setState({
                  bankAccount: e.target.value,
                });
              }}
            />
            <CheckboxAuthorizeDebit />
            {enrollmentErrors && <EnrollmentErrors {...enrollmentErrors} />}
            <Actions>
              <PrimaryButton
                loading={submitting}
                disable={submitting}
                buttonType="submit"
              >
                Continue
              </PrimaryButton>
              <CancelButtonWithModal
                handleCancel={handleCancel}
                body={cancelBody}
              />
            </Actions>
          </ExtendedFlex>
        </Form>
        <ConfirmModal
          isVisible={this.state.isModalVisible}
          amount={Number.parseInt(this.state.monthlyAmount, 10)}
          bankAccount={bankAccounts.find(
            (i) => i.id === Number.parseInt(this.state.bankAccount, 10),
          )}
          handleClick={() => {
            this.setState({
              isModalVisible: false,
            });
            this.amountInput.focus();
          }}
          handleSubmit={this.handleFormSubmit}
          submitting={submitting}
          error={
            enrollmentErrors && <EnrollmentErrors {...enrollmentErrors} />
          }
        />
      </AutoInvestContentWrapper>
    );
  }
}

Investment.propTypes = {
  minPerMonth: PropTypes.number,
  maxPerMonth: PropTypes.number,
  isAccredited: PropTypes.bool,
  bankAccountValues: PropTypes.array,
  bankAccounts: PropTypes.array,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  cancelBody: CancelButtonWithModal.propTypes.body,
  submitting: PropTypes.bool,
  enrollmentErrors: PropTypes.object,
};

Investment.defaultProps = {
  minPerMonth: 250,
  maxPerMonth: 5000,
  bankAccountValues: [
    { value: 1234, label: 'Savings 5833' },
  ],
  handleSubmit: noop,
  handleCancel: noop,
  submitting: false,
};

export default Investment;
