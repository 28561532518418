/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledCheckbox,
} from '../../coreui';

export const TaxcenterCheckbox = React.forwardRef(({
  disabled,
  ...props
}, ref) => (
  <StyledCheckbox disabled={disabled} {...props} ref={ref} />
));

TaxcenterCheckbox.propTypes = {
  disabled: PropTypes.bool,
};

export const createTaxcenterCheckbox = (row) => {
  return (
    <TaxcenterCheckbox
      disabled={(row.original.status.value !== 'uploaded')}
      handleClick={row.value.handleClick}
      checked={row.value.selected}
      data-test={`tax-center-checkbox-${row.viewIndex}`}
    />
  );
};

export default {
  createTaxcenterCheckbox,
  TaxcenterCheckbox,
};
