import watchUserRegisterRequested from './setUserRegister';
import watchUserLoginRequested, {
  watchAppInit,
} from './getUserLogin';
import watchUserLogoutRequested, {
  watchGetUserDataSucceeded,
} from './getUserLogout';
import watchUpdateUserData from './updateUserData';
import watchGetUserSession from './getUserSession';

export default [
  watchAppInit(),
  watchUserRegisterRequested(),
  watchUserLoginRequested(),
  watchUserLogoutRequested(),
  watchGetUserDataSucceeded(),
  watchUpdateUserData(),
  watchGetUserSession(),
];
