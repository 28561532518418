import styled from 'styled-components';

export default {
  ReitDescription: styled.div`
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    color: #202020;
    justify-content: center;
    box-sizing: border-box;
    background: ${({ background }) => `linear-gradient(rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.65)), url("${background}")`};
    background-size: cover;
    background-position: 63%;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      background-position: bottom;
      flex-direction: row;
    }
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    justify-content: space-between;
    padding: 30px 16px 160px 16px;
    h1 {
      font-family: mrs-eaves-xl-serif-narrow,serif;
      font-weight: 800;
      font-size: 28px;
      line-height: 32px;
    }
    p {
      font-size: 16px;
      line-height: 28px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      h1 {
        font-size: 44px;
        line-height: 56px;
        width: 100%;
      }
      p {
        font-size: 18px;
        line-height: 32px;
      }
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      flex-direction: row;
      flex-wrap: wrap;
      text-align: left;
      max-width: 1024px;
      padding-top: 75px;
      padding-bottom: 220px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
      padding-left: 0;
      padding-right: 0;
    }
  `,
  DescriptionTile: styled.div`
    p {
      font-size: 16px;
      line-height: 28px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      max-width: 325px;
      p {
        font-size: 18px;
        line-height: 32px;
      }
    }
  `,
  ContentTile: styled.div`
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    flex-wrap: wrap;
    max-width: 450px;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      padding-top: 40px;
      margin: 0 0 0 50px;
    }
  `,
  Item: styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    &:nth-child(2n+1) {
      border-right: 1px dashed #D1D1D1;
    }
    &:nth-child(1), &:nth-child(2) {
      border-bottom: 1px dashed #D1D1D1;
      padding-bottom: 20px;
    }
    &:nth-child(3), &:nth-child(4) {
      padding-top: 20px;
    }
    .value {
      font-family: mrs-eaves-xl-serif-narrow,serif;
      color: #176DB6;
      font-weight: 800;
      font-size: 24px;
      line-height: 20px;
      margin-bottom: 8px;
      text-transform: uppercase;
    }
    .label {
      font-weight: 400;
      max-width: 80%;
      font-size: 12px;
      line-height: 20px;
    }
    .tooltip {
      color: #000;
      text-align: left;
      font-size: 12px;
      line-height: 1.5;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      &:nth-child(1), &:nth-child(2) {
        padding-bottom: 0;
      }
      .value {
        font-size: 30px;
        line-height: 24px;
      }
      .label {
        font-size: 14px;
        line-height: 24px;
      }
    }
  `,
  StartWidgetWrapper: styled.div`
    background: transparent;
    position: absolute;
    top: calc(100% + 375px);
    transform: translateY(-100%);
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      width: 100%;
      margin-top: -125px;
      top: calc(100% + 275px);
      left: 50%;
      transform: translate(-50%, -100%);
    }
  `,
  StartWidget: styled.div`
    background: #FFF;
    display: block;
    width: calc(100% - 30);
    max-width: 1024px;
    text-align: center;
    padding: 32px;
    margin: 0 15px;
    box-sizing: border-box;
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, .12);
    h2 {
      font-family: mrs-eaves-xl-serif-narrow,serif;
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
      margin-top: 0;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      margin: 0 auto;
      h2 {
        font-size: 28px;
        line-height: 32px;
      }
    }
  `,
  StartWidgetStats: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 6px;
  `,
  StatItem: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    width: 100%;
    span {
      color: #202020;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
    }
    .spacer {
      flex: 1;
      margin: 0 8px;
      height: 24px;
      border-bottom: 1px dashed #D1D1D1;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      width: 48.5%;
      span {
        font-size: 14px;
        line-height: 32px;
      }
    }
  `,
  StartWidgetCTA: styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 24px;
    input {
      padding-left: 15px;
      height: 44px;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 10px;
      border: 1px solid #202020;
    }
    button {
      width: 100%;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      flex-direction: row;
      form {
        width: 100%;
      }
      input {
       width: 46%;
       margin-right: 2%;
       margin-bottom: 0;
      }
      button {
        width: ${({ isLoggedIn }) => (isLoggedIn ? '100%' : '48.5%')};
      }
    }
  `,
  StartWidgetDisclaimer: styled.div`
  font-size: 14px;
  line-height: 24px;
  a {
    text-decoration: none;
    color: #176DB6;
    font-weight: 800;
  }
  `,
};
