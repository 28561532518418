import divImg from '../assets/icon_tenants.svg';
import passImg from '../assets/icon_income.svg';
import growImg from '../assets/icon_wallet.svg';
import chartImg from '../assets/icon_chart.svg';
import talkImg from '../assets/icon_talk.svg';
import timeImg from '../assets/icon_holdperiod.svg';

export default {
  hero: {
    header: 'As Demand Grows, we seize the opportunity',
    subheader: 'Why we believe in Multifamily Investments',
  },
  tools: [
    {
      image: divImg,
      title: 'TENANTS PAY RENT',
      body: 'Tenants typically pay monthly rent to the property management company, who sends it to our Manager.',
    },
    {
      image: passImg,
      title: 'Expenses are Paid',
      body: 'Our Manager pays expenses related to the property and manages the day-to-day operation of the investment.',
    },
    {
      image: growImg,
      title: 'We Distribute Proceeds',
      body: 'After expenses are paid, any remaining dollars are distributed to investors as cash-flow. If the property is sold, there may be additional upside for investors.',
    },
  ],
  overview: {
    title: 'THE MULTIFAMILY INVESTMENT PROCESS',
    subtitle: 'A multifamily real estate investment strategy can potentially create consistent and steady cash-flow for investors.',
  },
  opportunities: {
    heading: 'Multifamily opportunities available today',
    investments: [
      {
        id: 2,
        title: 'Available to accredited investors only',
        titleToolTip: '<p>An Accredited Investor meets <em>at least one</em> of the following criteria:</p><ul><li>Net Worth of at least $1,000,000 (excluding the value of their primary residence); or</li><li>Income of at least $200,000 for this year and the previous two years; or</li><li>Joint Income (with spouse) of at least $300,000 for this year and the previous two years (Note: It is not mandatory to qualify jointly with a spouse if the investor qualifies as an individual.)</li></ul>',
        path: '/investment-opportunities',
        status: 'status-10-open',
        statusLabel: 'Open for investment',
        assetCount: 1,
        REIT: false,
        imageName: 'img_card_locked',
        propertyLocation: 'Private Placements',
        investmentType: 'REIT',
        investmentTypeDisplay: 'Equity',
        targetHoldPeriod: null,
        minimum: 'Varied',
        propertyType: 'Varied',
        targetHoldMin: null,
        targetHoldMax: 'Varied',
        payout: 1,
        includeProgress: true,
        blurb: 'Individual properties fully vetted with the investment objective of capital appreciation and income.  Apply for access to Private Placements during registration.',
        blurContent: true,
      },
    ],
  },
  insight: {
    title: 'GAIN NEW INVESTING INSIGHT',
  },
  disclosure: [
    'Bloomberg Barclays US Aggregate Bont TR USD data source from MorningStar',
    '(https://performance.morningstar.com/Performance/index-c/performance-return.action?t=XIUSA000MC).',
    'S&P 500 Index and NARIT Equity REIT Index data sourced from NCREIF (NCREIF.org)',
  ],
  smartButton: {
    // eslint-disable-next-line camelcase
    button_text: 'Get Started',
    // eslint-disable-next-line camelcase
    button_href: '/user/register',
  },
  renting: {
    title: 'Why Is Renting Becoming More Popular?',
    subtitle: 'Renters consist of the two largest generations in the U.S. - Baby Boomers who are downsizing as they enter retirement, and Millennials who have recently entered the workforce. The demographic breakdown below shows research cited from a 2017 Apartment Demand report by NMHC and NAA.',
    items: [
      {
        title: '75MM',
        body: 'Adults 18-34 entering the rental market',
      },
      {
        title: '60%',
        body: 'Increase in Adults 45+ renting in the last decade',
      },
      {
        title: '20%',
        body: 'U.S. Households are married couples with children',
      },
    ],
  },
  whyInvest: {
    heading: 'WHY INVEST IN MULTIFAMILY?',
    body: [
      'Over the 25-year period from 1992 through 2017, multifamily real estate provided the highest average annual total returns of any commercial real estate sector with the second lowest level of volatility, according to research cited in a 2018 report by CBRE, the world\'s largest commercial real estate investment firm.',
    ],
    height: [
      '550px', '400px', '450px', '500px', '550px',
    ],
    insights: [
      {
        icon: chartImg,
        text: 'Attractive, risk-adjusted returns and relatively low volatility',
      },
      {
        icon: talkImg,
        text: 'Healthy demand and favorable demographic outlook per research cited in a 2017 Apartment Demand report by NMHC and NAA',
      },
      {
        icon: timeImg,
        text: 'Shorter lease terms may allow for faster rent increases and tenant diversification',
      },
    ],
  },
};
