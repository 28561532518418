/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Slider,
  Data,
  Heading,
  Numeral,
  formatters,
} from '../coreui';

const SliderMoney = ({
  name,
  min,
  max,
  step,
  value,
  handleChange,
  ...props
}) => (
  <ExtendedFlex
    flexDirection="column"
    align="center"
    bg="gray.0"
    px={20}
    py={10}
    {...props}
  >
    <Data
      type="D7"
    >
      <Numeral
        value={value}
        format={formatters.DOLLARS}
      />
    </Data>
    <Slider
      name={name}
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={handleChange}
    />
    <ExtendedFlex
      flexDirection="row"
      justify="space-between"
      w={1}
    >
      <Heading
        type="H6"
      >
        <Numeral
          value={min}
          format={formatters.SHORTENED_DOLLARS}
        />
      </Heading>
      <Heading
        type="H6"
      >
        <Numeral
          value={max}
          format={formatters.SHORTENED_DOLLARS}
        />
      </Heading>
    </ExtendedFlex>
  </ExtendedFlex>
);

SliderMoney.propTypes = {
  name: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  handleChange: PropTypes.func,
};

SliderMoney.defaultProps = {
  handleChange: () => { },
};

export default SliderMoney;
