import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePlaidLink } from 'react-plaid-link';

import {
  StyledButton,
} from '../../coreui';

const PlaidLink = (props) => {
  const {
    onSuccess,
    onExit,
    token,
    product,
    openAutomatically,
    receivedRedirectUri,
    hideButton,
  } = props;
  const { children, ...restProps } = props;

  const config = { onSuccess, onExit, token, product, receivedRedirectUri };
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (ready && openAutomatically) {
      open();
    }
  }, [ready, open, openAutomatically]);

  if (hideButton) {
    return null;
  }

  return (
    <StyledButton
      disabled={!ready}
      {...restProps}
      type="L1"
      onClick={() => open()}
    >
      {children}
    </StyledButton>
  );
};

PlaidLink.propTypes = {
  onSuccess: PropTypes.func,
  onExit: PropTypes.func,
  token: PropTypes.string,
  product: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOf(PropTypes.object, PropTypes.string),
  openAutomatically: PropTypes.bool,
  receivedRedirectUri: PropTypes.string,
  hideButton: PropTypes.bool,
};

PlaidLink.defaultProps = {
  product: ['auth'],
};

export default PlaidLink;
