/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import {
  Box,
  Flex,
  ExtendedFlex,
  ExtendedBox,
  Data,
  TooltipIcon,
} from '../../..';

import formatters from '../../../helpers/formatters';

import InvestmentTargetHold from './InvestmentTargetHold';

const numeralFormat = (value, formatter) => {
  if (typeof value === 'string') {
    return value;
  }

  return numeral(value).format(formatter).toUpperCase();
};

const calloutPosition1Generator = (props) => {
  const {
    eligible1031,
    status,
    investmentTypeDisplay,
    minimum,
    irrObjective,
    returnObjective,
  } = props;

  if (status === 'status-20-coming-soon') {
    return {
      label: 'Minimum',
      value: numeralFormat(minimum || 'TBD', formatters.SHORTENED_DOLLARS),
    };
  }
  if (status === 'status-30-active') {
    if (eligible1031) {
      return false;
    }
    if (['Equity', 'Preferred Equity'].includes(investmentTypeDisplay) && irrObjective > 0) {
      return {
        label: 'IRR Objective',
        value: numeralFormat(irrObjective, formatters.PERCENTAGE2),
      };
    }
    if (investmentTypeDisplay === 'Debt' && returnObjective > 0) {
      return {
        label: 'Return Objective',
        value: numeralFormat(returnObjective, formatters.PERCENTAGE2),
      };
    }
  }
  if (status === 'status-40-closed') {
    return null;
  }
  return {
    label: 'Minimum',
    value: numeralFormat(minimum, formatters.SHORTENED_DOLLARS),
  };
};

const PropertyTypeData = (props) => {
  const {
    distribution,
    distributionMoreInfo,
    propertyType,
    reit,
    distributionTooltip,
    id,
    blurContent,
  } = props;

  if (reit && distribution === null) {
    return null;
  }

  const distributionOutput = (distribution === null) ?
    distributionMoreInfo :
    numeral(distribution).format(formatters.PERCENTAGE2);

  return (
    <>
      <Data
        type="D5"
        display="inline-block"
        m={0}
        textShadow={blurContent ? '4500px 1px 9px' : 'inherit'}
        textIndent={blurContent ? '-8898px' : ''}
      >
        {
          reit ? distributionOutput : propertyType
        }
      </Data>
      {
        reit && distributionTooltip &&
        <TooltipIcon
          tooltipId={`${id}_3x_position_3`}
          ml={[2, 2, 5]}
        />
      }
    </>
  );
};

PropertyTypeData.propTypes = {
  distribution: PropTypes.number,
  distributionMoreInfo: PropTypes.string,
  propertyType: PropTypes.string,
  reit: PropTypes.bool,
  distributionTooltip: PropTypes.string,
  id: PropTypes.string,
  blurContent: PropTypes.bool,
};

const PropertyTypeLabel = ({
  reit,
  distribution,
}) => {
  if (reit && distribution === null) {
    return null;
  }
  return (
    <>
      { reit ? 'Annualized Rate' : 'Property Type' }
    </>
  );
};

PropertyTypeLabel.propTypes = {
  distribution: PropTypes.number,
  reit: PropTypes.bool,
};

const InvestmentCardCalloutsX3 = (props) => {
  const {
    gtkyApproved,
    status,
    targetHoldMin,
    targetHoldMax,
    reit,
    propertyType,
    distribution,
    distributionMoreInfo,
    payoutLabel,
    id,
    payoutTooltip,
    distributionTooltip,
    blurContent,
  } = props;

  const position1 = calloutPosition1Generator(props);
  const limitedActiveCallout = status === 'status-30-active' && !gtkyApproved;

  return (
    <Box
      pt={10}
      px={10}
    >
      <Flex
        w={1}
      >
        {
          (position1 || limitedActiveCallout) &&
          <ExtendedBox
            borderRight={1}
            borderColor="gray.7"
            py={10}
            w={1}
            flexBasis="30%"
            hover
          >
            {
              !limitedActiveCallout &&
              <Data
                type="D5"
                display="inline-block"
                m={0}
                textShadow={blurContent ? '4500px 1px 9px' : 'inherit'}
                textIndent={blurContent ? '-9000px' : ''}
              >
                { position1.value }
              </Data>
            }
            {
              limitedActiveCallout &&
              <PropertyTypeData
                reit={reit}
                distribution={distribution}
                distributionMoreInfo={distributionMoreInfo}
                propertyType={propertyType}
                distributionTooltip={distributionTooltip}
                id={id}
                blurContent={blurContent}
              />
            }
            <Data
              pt={5}
              type="D3"
              display="block"
              m={0}
            >
              {
                !limitedActiveCallout &&
                position1.label
              }
              {
                limitedActiveCallout &&
                <PropertyTypeLabel
                  reit={reit}
                  distribution={distribution}
                />
              }
            </Data>
          </ExtendedBox>
        }
        <ExtendedBox
          borderRight={(limitedActiveCallout || (reit && distribution === null)) ? 0 : 1}
          borderColor="gray.7"
          py={10}
          {...(position1 ? {
            pl: [10, 10, 20, 20, 20],
          } : {})}
          w={[0.8, 0.8, 1, 1, 1]}
          position="relative"
          flexBasis="33%"
          hover
        >
          <ExtendedFlex
            align="flex-end"
            hover
            {...(payoutTooltip ? {
              justify: 'space-between',
              pr: [10, 10, 20, 20, 20],
            } : {})}
          >
            <Data
              type="D5"
              m={0}
              textShadow={blurContent ? '4500px 1px 9px' : 'inherit'}
              textIndent={blurContent ? '-8916px' : '0'}
            >
              { reit ?
                payoutLabel :
                <InvestmentTargetHold min={targetHoldMin} max={targetHoldMax} />}
            </Data>
            {
              reit && payoutTooltip &&
              <TooltipIcon
                tooltipId={`${id}_3x_position_2`}
                ml={[2, 2, 5]}
              />
            }
          </ExtendedFlex>
          <Data
            pt={5}
            type="D3"
            display="block"
            m={0}
          >
            { reit ? 'Distributions' : 'Target Hold' }
          </Data>
        </ExtendedBox>
        <ExtendedBox
          w={[0.9, 0.9, 1, 1, 1]}
          py={10}
          pl={[10, 10, 20, 20, 20]}
          flexBasis="33%"
          hover
        >
          <ExtendedFlex
            align="flex-end"
            hover
            {...(distributionTooltip ? {
              justify: 'space-between',
              pr: [10, 10, 20, 20, 20],
            } : {})}
          >
            {
              !limitedActiveCallout &&
              <PropertyTypeData
                reit={reit}
                distribution={distribution}
                distributionMoreInfo={distributionMoreInfo}
                propertyType={propertyType}
                distributionTooltip={distributionTooltip}
                id={id}
                blurContent={blurContent}
              />
            }
          </ExtendedFlex>
          <Data
            pt={5}
            type="D3"
            display="block"
            m={0}
          >
            {
              !limitedActiveCallout &&
              <PropertyTypeLabel
                reit={reit}
                distribution={distribution}
              />
            }
          </Data>
        </ExtendedBox>
      </Flex>
    </Box>
  );
};

InvestmentCardCalloutsX3.propTypes = {
  eligible1031: PropTypes.bool,
  minimum: PropTypes.number,
  propertyType: PropTypes.string,
  targetHoldMin: PropTypes.number,
  targetHoldMax: PropTypes.number,
  reit: PropTypes.bool,
  payoutLabel: PropTypes.string,
  distribution: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  distributionMoreInfo: PropTypes.string,
  status: PropTypes.string,
  investmentTypeDisplay: PropTypes.string,
  id: PropTypes.number,
  distributionTooltip: PropTypes.string,
  payoutTooltip: PropTypes.string,
  gtkyApproved: PropTypes.bool,
  actualIrr: PropTypes.number,
  irrObjective: PropTypes.number,
  returnObjective: PropTypes.number,
  blurContent: PropTypes.bool,
};

InvestmentCardCalloutsX3.defaultProps = {
  propertyType: 'Placeholder',
  minimum: 10000,
  payoutLabel: 'Monthly',
  reit: false,
  targetHoldMin: 36,
  targetHoldMax: 60,
  distribution: 0.99,
  distributionMoreInfo: 'More Info',
  distributionTooltip: 'Placeholder distribution Tooltip',
  payoutTooltip: 'Placeholder payout Tooltip',
  gtkyApproved: true,
  irrObjective: 0.12,
  returnObjective: 0.09,
  blurContent: false,
};

export default InvestmentCardCalloutsX3;
