import React from 'react';

import {
  Body,
  ExtendedBox,
} from '../../coreui';

const BuildWealth = () => {
  return (
    <ExtendedBox
      maxWidth={['90%', '90%', '100%', '1440px', '1440px']}
      textAlign="center"
      mx="auto"
    >
      <Body
        type="B1"
        textAlign="center"
        display="block"
        mx={40}
      >
        Commercial real estate is one of the most popular non-traditional asset classes for retirement investing. An investment in real estate has the potential for long-term appreciation as well as consistent cash flow. Combined with the potential tax advantages of a qualified retirement plan, an investment in real estate may present a compelling case for building a sufficient nest egg.
      </Body>
    </ExtendedBox>
  );
};

export default BuildWealth;
