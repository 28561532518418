export const enrollmentCancelBody = 'Your progress will be lost and your enrollment will be cancelled.';

// ISQ v2 props.
export const isqProps = [
  'primaryInvestmentReason',
  'primaryInvestmentReasonFollowUp',
  'expRealEstate',
  'expPrivateEquity',
  'expVentureCapital',
  'experienceFollowUp',
  'moneyAccess',
  'moneyAccessFollowUp',
  'illiquidLoseValue',
  'annualIncome',
  'netWorthSpecific',
  'over20PctNetWorth',
  'over20PctNetWorthFollowUp',
  'isFinraBdEmployee',
  'hasNotifiedFinraBdEmployer',
  'sourceOfWealth',
  'sourceOfIncome',
  'employmentType',
  'employer',
  'employmentPositionHeld',
  'employmentIndustry',
];

export default {
  enrollmentCancelBody,
};
