/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Loader,
  Link,
} from '../../coreui';

import Section from '../../ioDetails/components/section';
import Header from '../../ioDetails/components/header';
import ReitIContent from '../../dashboard/components/ReitIContent';
import ReitIIContent from '../../dashboard/components/ReitIIContent';

const Reit = ({
  loaded,
  data,
  title,
  subTitle,
}) => {
  const ReitContent = data.nid === 193744 ? ReitIContent : ReitIIContent;
  return (
    <Section
      bg="white"
      py={0}
    >
      <Header
        title={title}
        subTitle={subTitle}
      />
      {
        !loaded &&
        <ExtendedFlex
          position="relative"
          minHeight={300}
        >
          <Loader />
        </ExtendedFlex>
      }
      {
        loaded &&
        <ReitContent data={data} invested />
      }
      <ExtendedFlex
        {...(data.nid !== 193744 ? {} : {
          mt: [30, 50, 80],
        })}
        mb={[30, 50, 80]}
        justify="center"
      >
        <Link
          type="L3"
          to={`/investment-opportunity/${data.nid}`}
        >
          Investment Details
        </Link>
      </ExtendedFlex>
    </Section>
  );
};

Reit.propTypes = {
  loaded: PropTypes.bool,
  data: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default Reit;
