import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const Krumo = ({
  messages,
  load,
}) => {
  if (load || messages.filter(({ message }) => message && message.indexOf('Using Krumo') !== -1)) {
    const alreadyLoaded = typeof window !== 'undefined' && window.krumo;
    if (!alreadyLoaded) {
      return (
        <Helmet>
          <script
            type="text/javascript"
            src="/sites/all/modules/contrib/devel/krumo/krumo.js"
          />
        </Helmet>
      );
    }
  }
  return null;
};

Krumo.propTypes = {
  messages: PropTypes.array,
  load: PropTypes.bool,
};

Krumo.defaultProps = {
  messages: [],
};

export default Krumo;
