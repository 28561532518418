import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';

import {
  Heading,
  ExtendedFlex,
  ScrollToTopOnMount,
} from '../../coreui';

import actions from './actions';
import GlossarySection from '../components/glossarySection';

class Glossary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.props.getGlossaryDataRequested();
  }

  render() {
    const { sections } = this.props;
    return (
      <ExtendedFlex
        w={1}
        pb={40}
        justify="center"
        pt={80}
        bg="white"
      >
        <ScrollToTopOnMount />
        <ExtendedFlex
          w={1}
          maxWidth={1600}
          flexDirection="column"
          justify="center"
          align="center"
          px={['4%', '10%', 20]}
        >
          <Heading
            type="H3"
            color="black"
            textTransform="uppercase"
            display="block"
            textAlign="center"
          >
            Glossary
          </Heading>
          {
            sections.map(
              (section) => {
                return (
                  <GlossarySection
                    section={section}
                  />
                );
              },
            )
          }

        </ExtendedFlex>
      </ExtendedFlex>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getGlossaryDataRequested: () => {
    dispatch(actions.getGlossaryDataRequested());
  },
});

const mapStateToProps = (state) => ({
  sections: state.glossary.sections,
});

Glossary.propTypes = {
  getGlossaryDataRequested: PropTypes.func,
  sections: PropTypes.array,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Glossary));
