import queueFAQItemsTypes from './types';

const initialState = {
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case queueFAQItemsTypes.GET_FAQ_ITEMS_SUCCEEDED: {
      return {
        ...state,
        items: action.data.data,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
