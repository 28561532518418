import React from 'react';
import PropTypes from 'prop-types';

import ModalWithOverlay from './ModalWithOverlay';
import Slider from './Slider';

const Modal = ({
  assets,
  isVisible,
  handleClick,
  slider,
}) => {
  return (
    <ModalWithOverlay
      align="center"
      isVisible={isVisible}
      handleClick={handleClick}
    >
      <Slider
        parentSlider={slider}
        assets={assets}
      />
    </ModalWithOverlay>
  );
};

Modal.propTypes = {
  assets: PropTypes.array,
  isVisible: PropTypes.bool,
  handleClick: PropTypes.func,
  slider: PropTypes.func,
};

export default Modal;
