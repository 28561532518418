/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Image,
} from '../coreui';

import Container from './Container';
import FadeIn from './FadeIn';

const CalloutFrame = ({
  image,
  imagePosition,
  imageType,
  children,
  imagebg,
  imageProps,
  maxHeight,
  ...additionalProps
}) => {
  return (
    <Container
      px={0}
      maxWidth={null}
      flexDirection={
        imagePosition === 'first' ?
          ['column-reverse', 'row-reverse'] : ['column', 'row']
      }
      {...additionalProps}
    >
      <ExtendedFlex
        w={1}
        justify={imagePosition === 'first' ? 'flex-start' : 'flex-end'}
        height="100%"
      >
        <ExtendedFlex
          flexDirection="column"
          w={1}
          maxWidth={540}
          maxHeight={maxHeight}
          my={[60, 80, 100, 120, 140]}
          mx={[50, 50, '15%']}
        >
          <FadeIn>
            {children}
          </FadeIn>
        </ExtendedFlex>
      </ExtendedFlex>
      <ExtendedFlex
        {...(imageType === 'bg' ? {
          bgImage: image,
          bgRepeat: 'no-repeat',
          bgPosition: imagebg === 'center' ? 'center' : '0 0',
          rbgSize: 'cover',
        } : {
          flexDirection: 'column',
          align: 'center',
          justify: 'center',
        })}
        w={1}
        height="100%"
        minHeight={['100vw', 0]}
      >
        {
          imageType === 'image' &&
          <Image
            src={image}
            maxWidth="100%"
            {...imageProps}
          />
        }
      </ExtendedFlex>
    </Container>
  );
};

CalloutFrame.propTypes = {
  image: PropTypes.string,
  imagePosition: PropTypes.oneOf([
    'first',
    'second',
  ]),
  imageType: PropTypes.oneOf([
    'bg',
    'image',
  ]),
  children: PropTypes.node,
  imagebg: PropTypes.string,
  imageProps: PropTypes.object,
  maxHeight: PropTypes.number,
};

CalloutFrame.defaultProps = {
  imagePosition: 'second',
  imageType: 'bg',
};

export default CalloutFrame;
