/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  fontSize,
} from 'styled-system';

import {
  ExtendedFlex,
} from '../../coreui';

import Input from './Input';

const StyledPasswordInput = styled(Input)`
  width: 100%;
`;

const StyledButtonIcon = styled.button`
  outline: none;
  border: 0 none;
  background: none transparent;

  position: absolute;
  right: 0;
  top: 0;
  font-size: 0;
  color: ${(props) => (props.isVisible
    ? props.theme.colors.blue[1]
    : props.theme.colors.gray[10])};

  &:before {
    ${fontSize}
  }
`;

class PasswordInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordVisible: false,
    };
  }

  render() {
    const {
      useTogglePassword,
    } = this.props;
    return (
      <ExtendedFlex
        w={1}
        position="relative"
        flexWrap="wrap"
      >
        {
          useTogglePassword &&
          <StyledButtonIcon
            type="button"
            onClick={() => {
              this.setState(
                (prevState) => ({
                  passwordVisible: !prevState.passwordVisible,
                }),
              );
            }}
            fontSize={[24, null, 36]}
            isVisible={this.state.passwordVisible}
            className="icon-eye"
          >
            Show password
          </StyledButtonIcon>
        }

        <StyledPasswordInput
          type={this.state.passwordVisible ? 'text' : 'password'}
          {...this.props}
          {...(!this.state.passwordVisible ? {} : {
            placeholder: '',
          })}
        />
      </ExtendedFlex>
    );
  }
}

PasswordInput.propTypes = {
  useTogglePassword: PropTypes.bool,
  placeholder: PropTypes.string,
};

PasswordInput.defaultProps = {
  useTogglePassword: true,
  placeholder: '******************',
};

export default PasswordInput;
