import appWrapperTypes from '../appWrapper/types';
import footerData from './footer.json';

const initialState = {
  items: [],
  socialMediaItems: [],
  user: {
    isLoggedIn: false,
  },
};

const generateFooter = (user) => {
  const restrictedAccess = ['open_investments', 'past_investments'];
  const preAccredited = ['open_investments_pre_accredited'];
  const nonAccredited = ['past_investments'];
  const isPreAccredited = user.accreditationRoleStatus === 'Pre-Accredited';
  const items = footerData.data
    .map(
      (item) => {
        return {
          ...item,
          children: item.children.length > 0 ? item.children
            .filter(
              (child) => {
                if (preAccredited.includes(child.machineName)) {
                  return isPreAccredited;
                }
                if (!user.isLoggedIn || isPreAccredited) {
                  return !restrictedAccess.includes(child.machineName);
                }
                if (user.accreditationRoleStatus === 'Accredited') {
                  return true;
                }
                return !nonAccredited.includes(child.machineName);
              },
            ) :
            item.children,
        };
      },
    )
    .filter(
      (item) => item.machineName !== 'socialmedia',
    );
  return items;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case appWrapperTypes.GET_USER_DATA_SUCCEEDED: {
      const items = generateFooter(action.user);

      return {
        ...state,
        user: action.user,
        items,
      };
    }

    case appWrapperTypes.APP_INIT: {
      const user = state.user;
      const items = generateFooter(user);

      return {
        ...state,
        items,
        socialMediaItems: footerData.data
          .find(
            (item) => item.machineName === 'socialmedia',
          ).children
          .filter(
            (child) => child.machineName !== 'bbb',
          ),
        user: {
          ...state.user,
          ...action.data.user,
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
};
