/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import 'react-intl-tel-input/dist/main.css';
import styled, { injectGlobal } from 'styled-components';
import {
  themeGet,
} from 'styled-system';

import {
  dataTypes,
  bodyTypes,
  applyTextStyles,
} from '../../coreui';

// eslint-disable-next-line
import LoadableIntlTelInput from './LoadableIntlTelInput';

/* eslint-disable indent */
const StyledLoadableIntlTelInput = styled(({
  className,
  containerClassName,
  ...props
}) => (
  <LoadableIntlTelInput containerClassName={`${containerClassName} ${className}`} {...props} />
))`
  &&& {
    display: block;
    align-items: center;

    .flag-container {
      margin: auto;
      position: absolute;
      top: 0; left: 0; bottom: 0; right: 0;
      height: 25px;

      ${(props) => {
        const textStyles = applyTextStyles({
          ...dataTypes.D4,
          theme: props.theme,
        });

        // eslint-disable-next-line no-unused-expressions
        injectGlobal`
          .iti-mobile .intl-tel-input .country-list {
            color: ${themeGet('colors.gray.10')(props)}
            ${textStyles}
          }
        `;

        return {
          textStyles,
          ...props,
        };
      }}

      .selected-flag {
        width: 70px;

        &:focus {
          outline: none;
        }
      }
    }

    &.allow-dropdown.separate-dial-code .selected-flag {
      background: none;
    }

    input[type=tel] {
      width: 100%;
      border: 0 none;
      height: 43px;
      display: block;
      padding-top: 7px;
      padding-bottom: 5px;
      padding-left: 70px;
      border-bottom: 1px solid;
      border-color: ${themeGet('colors.gray.10')};
      border-radius: 0;

      ${(props) => applyTextStyles({
        ...bodyTypes.B1,
        ...props,
      })}

      &:focus {
        outline: none;
      }

      + .hf-warning {
        ${({ theme }) => applyTextStyles({
          theme,
          ...dataTypes.D4,
        })}
        color: ${themeGet('colors.red')};
        margin-top: 5px;
        margin-left: 12px;
      }
    }
  }
`;
/* eslint-enable indent */

const PhoneInput = ({
  id,
  name,
  onChange,
  inputProps,
  ...props
}) => {
  return (
    <StyledLoadableIntlTelInput
      containerClassName="intl-tel-input"
      inputClassName="form-control"
      preferredCountries={['us', 'ca']}
      fieldName={name}
      format
      autoPlaceholder
      separateDialCode
      onPhoneNumberChange={onChange || null}
      telInputProps={inputProps || {}}
      {...props}
    />
  );
};

PhoneInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
};

export default PhoneInput;
