export default {
  popularArticles: [
    {
      id: 0,
      url: 'http://google.com',
      label: 'Popular Article 1',
    },
    {
      id: 1,
      url: 'http://google.com',
      label: 'Popular Article 2',
    },
    {
      id: 2,
      url: 'http://google.com',
      label: 'Popular Article 3',
    },
  ],
  socialMedia: [
    {
      id: 804,
      label: 'Twitter',
      weight: '5',
      machineName: 'twitter',
      uuid: '067d4780-b242-43cc-9aec-c920026f7324',
      url: 'https://twitter.com/realty_mogul',
      parent: 803,
      children: [],
      htmlID: 'navigation-icon-twitter',
    },
    {
      id: 805,
      label: 'Facebook',
      weight: '6',
      machineName: 'facebook',
      uuid: '79e7653a-5b91-441b-9560-06b94f12a25c',
      url: 'https://www.facebook.com/RealtyMogul',
      parent: 803,
      children: [],
      htmlID: 'navigation-icon-facebook',
    },
    {
      id: 807,
      label: 'LinkedIn',
      weight: '8',
      machineName: 'linkedin',
      uuid: 'd477bc95-ade1-4193-9110-2ba25a656bb1',
      url: 'https://www.linkedin.com/company/realty-mogul',
      parent: 803,
      children: [],
      htmlID: 'navigation-icon-linkedin',
    },
  ],
};
