import React from 'react';
import PropTypes from 'prop-types';
import { injectGlobal } from 'styled-components';

import {
  ExtendedBox,
  Body,
} from '../../..';

export const accordionContentCSS = injectGlobal`

  .rm-coreui-accordion,
  .rm-coreui-accordion a,
  .rm-coreui-accordion ul li,
  .rm-coreui-accordion ol li,
  .rm-coreui-accordion p {
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    font-weight: extraLight;
    line-height: 1.75;
    font-size: 13px;
    font-size: 10px;
    @media screen and (min-width: 40em) {
      font-size: 10px;
    }
    @media screen and (min-width: 52em) {
      font-size: 12px;
    }
    @media screen and (min-width: 64em) {
      font-size: 14px;
    }
    @media screen and (min-width: 120em) {
        font-size: 14px;
    }
  }
  .rm-coreui-accordion a {
    color: #176DB6;
    text-decoration: none;
  }
`;

class AccordionReveal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isActive,
      content,
      contentIsHtml,
      bodyType,
    } = this.props;

    return contentIsHtml ?
      <ExtendedBox
        maxHeight={isActive ? 10000 : 0}
        px={['10%', 20]}
        overflowY="hidden"
        opacity={isActive ? '1' : '0'}
        transition={`opacity ${isActive ? '0.6s 0.1s' : '0s'} linear`}
        dangerouslySetInnerHTML={{ __html: content }}
        className="rm-coreui-accordion"
      /> :
      <ExtendedBox
        maxHeight={isActive ? 10000 : 0}
        px={['10%', 20]}
        overflowY="hidden"
        opacity={isActive ? '1' : '0'}
        transition={`opacity ${isActive ? '0.6s 0.1s' : '0s'} linear`}
      >
        <Body
          type={bodyType || 'B1'}
          color="black"
          m={0}
          display="block"
          mt={20}
        >
          {content}
        </Body>
      </ExtendedBox>;
  }
}

AccordionReveal.propTypes = {
  isActive: PropTypes.bool,
  content: PropTypes.string,
  contentIsHtml: PropTypes.bool,
  bodyType: PropTypes.string,
};

export default AccordionReveal;
