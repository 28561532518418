import React from 'react';
import PropTypes from 'prop-types';

import {
  Data,
  StyledButton,
  ExtendedBox,
  ExtendedFlex,
  Image,
} from '../../coreui';

import downArrow from '../assets/down_blk.svg';
import upArrow from '../assets/up_blk.svg';

class FoeDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPanel: false,
      hover: undefined,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleUnclick = this.handleUnclick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleUnHover = this.handleUnHover.bind(this);
  }

  handleClick() {
    this.setState((state) => ({
      showPanel: !state.showPanel,
    }));
  }

  handleUnclick() {
    this.setState({
      showPanel: false,
    });
  }

  handleSelect(option) {
    this.props.handleSelect(
      option,
    );
    this.handleUnclick();
  }

  handleHover(option) {
    this.setState({
      hover: option.id,
    });
  }

  handleUnHover() {
    this.setState({
      hover: undefined,
    });
  }

  render() {
    const { options } = this.props;
    const arrow = this.state.showPanel === true ? upArrow : downArrow;
    return (
      <ExtendedBox
        mt={[40, 0]}
        px={50}
        w={1}
        maxWidth={1540}
        boxSizing="border-box"
      >
        <ExtendedFlex
          width="100%"
          height="65px"
          borderTop="1px solid"
          borderColor="gray.10"
          borderBottom="1px solid"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          py="20px"
          onClick={this.handleClick}
          hover
        >
          <ExtendedFlex
            align="center"
            hover
          >
            <Data
              type="D1"
              my={0}
            >
              {`${this.props.selected.label ? `${this.props.selected.label}:` : ''} ${this.props.selected.title}`}
            </Data>
            <Image
              src={arrow}
              width={10}
              mt={-2}
              ml={5}
            />
          </ExtendedFlex>
        </ExtendedFlex>
        <ExtendedFlex
          width="100%"
          justifyContent="center"
        >
          <FoePanel
            options={options}
            show={this.state.showPanel}
            handleSelect={this.handleSelect}
            handleHover={this.handleHover}
            handleUnHover={this.handleUnHover}
            hoverId={this.state.hover}
          />
        </ExtendedFlex>
      </ExtendedBox>
    );
  }
}

FoeDropdown.propTypes = {
  handleSelect: PropTypes.func,
  selected: PropTypes.object,
  options: PropTypes.array,
};

const optionBoundRow = (handleSelect, handleHover, handleUnHover, hoverId) => {
  return (option, index) => {
    const boundRowSelect = () => {
      handleSelect(option);
    };
    const boundHover = () => {
      handleHover(option);
    };
    const label = option.label !== undefined ? `${option.label}:` : '';
    const bgColor = (() => {
      if (option.id === hoverId) {
        return 'gray.12';
      }
      return index % 2 === 0 ? 'white' : 'gray.0';
    })();
    return (
      <ExtendedFlex
        key={`foeOption:${option.id}`}
        width="100%"
        justifyContent="center"
        alignItems="center"
        height="50px"
        onMouseEnter={boundHover}
        onMouseLeave={handleUnHover}
        onClick={boundRowSelect}
        hover
        bg={bgColor}
        px={10}
      >
        <StyledButton
          type="L5"
        >
          {label} {option.title}
        </StyledButton>
      </ExtendedFlex>
    );
  };
};

const FoePanel = ({
  show,
  options,
  handleSelect,
  handleHover,
  handleUnHover,
  hoverId,
}) => {
  return (
    <ExtendedFlex
      px={30}
      width="100%"
      display={show ? 'flex' : 'none'}
      flexDirection="column"
      alignItems="center"
    >
      {
        options.map(
          optionBoundRow(handleSelect, handleHover, handleUnHover, hoverId),
        )
      }
    </ExtendedFlex>
  );
};

FoePanel.propTypes = {
  show: PropTypes.bool,
  options: PropTypes.array,
  handleSelect: PropTypes.func,
  handleHover: PropTypes.func,
  handleUnHover: PropTypes.func,
  hoverId: PropTypes.string,
};

export default FoeDropdown;
