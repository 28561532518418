import React from 'react';

import { Image } from '../../coreui';

import TimelineWrapper from '../../components/TimelineWrapper';
import TimelineContainer from '../../components/TimelineContainer';
import TimelineCard from '../../components/TimelineCard';

import iconBuildings from '../../assets/icon_buildings.svg';
import iconBuildingStar from '../../assets/icon_building_star.svg';
import iconChart from '../../assets/icon_chart.svg';
import iconShield from '../../assets/icon_shield.svg';
import iconWallet from '../../assets/icon_wallet.svg';

import mr1Assets from '../assets/mr1_assets.png';
import mr2Assets from '../assets/mr2_assets.png';

const ReitDifferences = () => {
  return (
    <TimelineWrapper>
      <TimelineContainer
        heading="Objectives"
      >
        <TimelineCard
          icon={iconShield}
          heading="The Income REIT"
          body={[
            'Pay Attractive and Stable Cash Distributions.',
            'Preserve, Protect, Increase and Return Your Capital Contributions.',
          ]}
        />
        <TimelineCard
          icon={iconChart}
          heading="The Apartment Growth REIT"
          body={[
            'Realize Long Term Capital Appreciation.',
            'Pay Attractive and Stable Cash Distributions.',
          ]}
        />
      </TimelineContainer>
      <TimelineContainer
        heading="Asset Type(s)"
      >
        <TimelineCard>
          <Image
            src={mr1Assets}
            IEAspectRatioFix
            maxWidth="100%"
          />
        </TimelineCard>
        <TimelineCard>
          <Image
            src={mr2Assets}
            IEAspectRatioFix
            maxWidth="100%"
          />
        </TimelineCard>
      </TimelineContainer>
      <TimelineContainer
        heading="Noteworthy"
      >
        <TimelineCard
          icon={iconBuildings}
          heading="Diversification"
          body="The Income REIT’s diversified investment strategy allows the REIT to hold investments in senior secured loans, mezzanine, debt, preferred equity and equity structures."
        />
        <TimelineCard
          icon={iconBuildingStar}
          heading="Private Offerings"
          body="All current multifamily properties in the The Apartment Growth REIT portfolio (open to all investors) were originally only offered to accredited investors as private, individual property offerings on our platform."
        />
      </TimelineContainer>
      <TimelineContainer
        heading="Distributions"
      >
        <TimelineCard
          icon={iconWallet}
          heading="Monthly"
          body="Since inception, The Income REIT has distributed between 6% and 8% (net of fees) annualized based on Purchase Price."
        />
        <TimelineCard
          icon={iconWallet}
          heading="Quarterly"
          body="Since inception, The Apartment Growth REIT has declared and paid distributions equating to approximately 4.50% (net of fees) annualized."
        />
      </TimelineContainer>
    </TimelineWrapper>
  );
};

export default ReitDifferences;
