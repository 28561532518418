import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  isDrupal,
} from '../../coreui';

import {
  pendingInvestmentsUrl,
} from '../links';

import NotificationWrapper from '../../components/NotificationWrapper';
import NotificationContainer from '../../components/NotificationContainer';
import NotificationItem from '../../components/NotificationItem';

const DashboardNotifications = ({
  pendingData,
  handleClick,
  history,
}) => {
  // If pending data hasn't been loaded yet from the parent component or there
  // aren't any pending investments, bail.
  if (!pendingData) {
    return null;
  }

  // Filter pending down to those with action urls.
  const filteredPendingData = pendingData.filter((item) => item.action && item.actionUrl);
  const count = filteredPendingData.length;

  // No pending investments? Nothing to display.
  if (!count) {
    return null;
  }

  return (
    <NotificationWrapper>
      <NotificationContainer>
        <NotificationItem
          header={`Pending ${count > 1 ? 'Investments' : 'Investment'}`}
          body={count > 1 ? 'You have pending investments that require additional information in order to process.' : 'You have a pending investment that requires additional information in order to process.'}
          buttonText="Continue"
          buttonProps={{
            onClick: handleClick || (() => {
              // Push it onto history ourselves because we potentially need to
              // reload the page after if we're in Drupal.
              history.push(
                pendingInvestmentsUrl,
                {
                  pendingInvestmentsMessage: true,
                },
              );
              window.scrollTo(0, 1200);

              if (isDrupal()) {
                window.location.reload();
              }
            }),
            'data-test': 'pending-notification-button',
          }}
        />
      </NotificationContainer>
    </NotificationWrapper>
  );
};

DashboardNotifications.propTypes = {
  pendingData: PropTypes.array,
  handleClick: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(DashboardNotifications);
