import React from 'react';

import {
  Box,
  Flex,
  ExtendedFlex,
  Heading,
  Body,
  Image,
  Data,
  VisibilitySensor,
  Theme,
} from '../coreui';

import graphStrategies from './assets/graph_strategies.png';
import { BalancingRiskData } from './_contentData';

class IconCallouts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible: true,
      });
    }
  }

  render() {
    const {
      isVisible,
    } = this.state;

    return (
      <Box py={[60, 80, 100, 120, 140]}>
        <Flex flexDirection={['column', 'column', 'row', 'row', 'row']}>
          <Box width={[1, 1, 0.5, 0.5, 0.5]}>
            <Flex
              flexDirection="column"
              align={['center', 'center', 'flex-end', 'flex-end', 'flex-end']}
            >
              <ExtendedFlex
                flexDirection="column"
                align={['center', 'center', 'flex-start', 'flex-start', 'flex-start']}
                mr={[0, 0, 20, 40, 60]}
                maxWidth={['100%', '100%', 320, 400, 540]}
              >
                <Heading
                  type="H2"
                  display="block"
                  textAlign={['center', 'center', 'left', 'left', 'left']}
                  maxWidth={['75%', 540, '100%', '100%', '100%']}
                  m={0}
                  mb="0.5em"
                  opacity={isVisible ? '1' : '0'}
                  transition={`opacity ${Theme.fadeInDelay}ms 0s linear`}
                >
                  {BalancingRiskData.heading}
                </Heading>
                <VisibilitySensor
                  callback={this.handleSensorChange}
                />
                {
                  BalancingRiskData.paragraphs.map(({ text }, index) => (
                    <Body
                      key={index ? 0 : 1}
                      type="B1"
                      display="block"
                      textAlign={['center', 'center', 'left', 'left', 'left']}
                      maxWidth={['75%', 540, '100%', '100%', '100%']}
                      opacity={isVisible ? '1' : '0'}
                      transition={`opacity ${Theme.fadeInDelay}ms 0.3s linear`}
                    >
                      {text}
                    </Body>
                  ))
                }
              </ExtendedFlex>
            </Flex>
          </Box>
          <Box width={[1, 1, 0.5, 0.5, 0.5]}>
            <Flex
              flexDirection="column"
              align={['center', 'center', 'flex-start', 'flex-start', 'flex-start']}
              ml={[0, 0, 20, 40, 60]}
              mt={[50, 50, 0, 0, 0]}
            >
              <Image
                src={graphStrategies}
                maxWidth="542px"
                width={['75%', 0.5, 0.85, 0.75, 542]}
                IEAspectRatioFix
                opacity={isVisible ? '1' : '0'}
                transition={`opacity ${Theme.fadeInDelay}ms 0.6s linear`}
              />
              <Data
                type="D1"
                align="center"
                pl={[0, 0, 75, 125, 150]}
                py={20}
              >
                {BalancingRiskData.imageLabel}
              </Data>
            </Flex>
          </Box>
        </Flex>
      </Box>
    );
  }
}

export default IconCallouts;
