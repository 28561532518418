import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  Box,
  ExtendedFlex,
  Heading,
  Body,
  VisibilitySensor,
  Link,
} from '../../../coreui';

import backgroundImage from '../../assets/img_retirement.jpg';
import FadeIn from '../../../components/FadeIn';

const ReitInvesting1 = ({
  handleSensorChange,
  isVisible,
}) => {
  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
    >
      <ExtendedBox
        w={1}
        bgRepeat="no-repeat"
        bgImage={backgroundImage}
        bgPosition="50% 26%"
        bgSize="cover"
      >
        <Box
          w={1}
          px={['15%', '25%', '10%', '10%', '10%']}
        >
          <ExtendedFlex
            flexDirection="column"
            align={['center', 'center', 'flex-end', 'flex-end', 'flex-end']}
          >
            <ExtendedFlex
              maxWidth={['100%', '100%', 400, 400, 540]}
              flexDirection="column"
              my={[60, 60, 100, 100, 120]}
            >
              <FadeIn>
                <Heading
                  type="H2"
                  color="white"
                  m={0}
                  mb="1em"
                  opacity={isVisible ? '1' : '0'}
                >
                  REIT INVESTING FOR RETIREMENT
                </Heading>
              </FadeIn>
              <VisibilitySensor
                callback={handleSensorChange}
              />
              <FadeIn
                fadeInIndex={2}
              >
                <Body
                  type="B1"
                  color="white"
                  fontWeight="light"
                  mb={[200, 250, 380, 440, 480]}
                  opacity={isVisible ? '1' : '0'}
                >
                  Investing in real estate may be a great way to diversify your retirement portfolio and has the potential to generate long-term tax-deferred growth.
                </Body>
                <ExtendedBox
                  opacity={isVisible ? '1' : '0'}
                >
                  <Link
                    type="L1"
                    to="/knowledge-center/retirement-investing"
                  >
                    Learn More
                  </Link>
                </ExtendedBox>
              </FadeIn>
            </ExtendedFlex>
          </ExtendedFlex>
        </Box>
      </ExtendedBox>
    </Box>
  );
};

ReitInvesting1.propTypes = {
  handleSensorChange: PropTypes.func,
  isVisible: PropTypes.bool,
};

export default ReitInvesting1;
