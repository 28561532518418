import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Link,
  Text,
  Box,
  ExtendedBox,
} from '../../coreui';

const FooterSection3 = ({
  isLimitedNav,
  isApp,
  frontendPrefix,
  is1031 = false,
}) => {
  return (
    <ExtendedBox
      w={1}
      mt={10}
      py={20}
      borderTop={isLimitedNav ? 0 : 1}
      borderColor="gray.10"
    >
      <Flex
        flexDirection={['column', 'column', 'row']}
        justify={['space-between']}
        align="center"
        w={1}
      >
        <Box
          mb={[20, 20, 0]}
        >
          <Flex
            flexDirection={['column', 'row']}
            justify={['center']}
            align={['center']}
          >
            {/* These are one-offs */}
            <Text
              color="white"
              fontFamily="sansSerif"
              fontSize={[10, 10, 10, 10, 11]}
              m={0}
              whiteSpace="nowrap"
              hoverColor="white"
            >
              &copy; {(new Date()).getFullYear()} RM Technologies LLC.
            </Text>
            &nbsp;
            <Text
              color="white"
              fontFamily="sansSerif"
              fontSize={[10, 10, 10, 10, 11]}
              m={0}
              whiteSpace="nowrap"
              hoverColor="white"
            >
              All rights reserved.
            </Text>
          </Flex>
        </Box>
        <Box
          mb={[20, 20, 0]}
        >
          <Flex
            w={1}
            align="center"
            justify={['center']}
          >
            <Link
              type="L6"
              color="white"
              m={0}
              target="_blank"
              href={`${isApp ? frontendPrefix : ''}/terms-service`}
              whiteSpace="nowrap"
              hoverColor="gray.10"
            >
              Terms of Service
            </Link>
            <ExtendedBox
              borderRight={1}
              borderColor="white"
              height={10}
              mx={10}
            />
            <Link
              type="L6"
              color="white"
              target="_blank"
              href={`${isApp ? frontendPrefix : ''}/privacy-policy`}
              hoverColor="gray.10"
              m={0}
            >
              Privacy Policy
            </Link>
            <ExtendedBox
              borderRight={1}
              borderColor="white"
              height={10}
              mx={10}
            />
            <Link
              hoverColor="gray.10"
              type="L6"
              color="white"
              target="_blank"
              href={`${isApp ? frontendPrefix : ''}/investment-disclosure`}
              m={0}
            >
              Disclosures
            </Link>
            {
              is1031 && (
                <>
                  <ExtendedBox
                    borderRight={1}
                    borderColor="white"
                    height={10}
                    mx={10}
                  />
                  <Link
                    hoverColor="gray.10"
                    type="L6"
                    color="white"
                    target="_blank"
                    href="https://www.realtymogul.com/form-crs"
                    m={0}
                  >Form CRS
                  </Link>
                  <ExtendedBox
                    borderRight={1}
                    borderColor="white"
                    height={10}
                    mx={10}
                  />
                  <Link
                    hoverColor="gray.10"
                    type="L6"
                    color="white"
                    target="_blank"
                    href="https://www.realtymogul.com/regulation-best-interest-disclosure"
                    m={0}
                  >Regulation Best Interest Disclosure
                  </Link>
                </>
              )
            }
          </Flex>
        </Box>
        <Box>
          <Link
            type="L6"
            color="white"
            whiteSpace="nowrap"
            hoverColor="gray.10"
            href="tel:8779772776"
            fontWeight={600}
            m={0}
            mb={[3, 3, 3, 0]}
          >
            (877) 977-2776
          </Link>
        </Box>
      </Flex>
    </ExtendedBox>
  );
};

FooterSection3.propTypes = {
  isLimitedNav: PropTypes.bool,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
  is1031: PropTypes.bool,
};

export default FooterSection3;
