import React from 'react';

import {
  Box,
  Flex,
  ExtendedFlex,
  Heading,
  Body,
  Image,
  Data,
  VisibilitySensor,
} from '../../coreui';

import graphStrategies from '../assets/Get_Financing_Chart.png';

class IconCallouts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible: true,
      });
    }
  }

  render() {
    const {
      isVisible,
    } = this.state;

    return (
      <Box
        py={[60, 80, 100, 120, 140]}
        bg="gray.0"
      >
        <ExtendedFlex
          flexDirection={['column', 'column', 'row', 'row', 'row']}
          maxWidth="1400px"
          mx="auto"
        >
          <Box
            width={[1, 1, 0.5, 0.5, 0.5]}
          >
            <Flex
              flexDirection="column"
              align={['center', 'center', 'center', 'center', 'center']}
            >
              <ExtendedFlex
                flexDirection="column"
                align={['center', 'center', 'flex-start', 'flex-start', 'flex-start']}
                mx={[50, 50, '15%', '15%', 50]}
                maxWidth={['100%', '100%', 540, 540, 540]}
              >
                <Heading
                  type="H2"
                  display="block"
                  textAlign={['center', 'center', 'left', 'left', 'left']}
                  maxWidth={['75%', 540, '100%', '100%', '100%']}
                  m={0}
                  mb="0.5em"
                  opacity={isVisible ? '1' : '0'}
                  transition="opacity 0.5s 0s linear"
                >
                  WE OFFER A SUITE OF FLEXIBLE FINANCING OPTIONS
                </Heading>
                <VisibilitySensor
                  callback={this.handleSensorChange}
                />
                <Body
                  type="B1"
                  display="block"
                  textAlign={['center', 'center', 'left', 'left', 'left']}
                  maxWidth={['75%', 540, '100%', '100%', '100%']}
                  opacity={isVisible ? '1' : '0'}
                  transition="opacity 0.5s 0.3s linear"
                >
                  RealtyMogul offers Co-GP equity, LP equity and preferred
                  equity to provide custom tailored solutions specific to
                  the deal and business plan. Whether it&apos;s common equity or
                  general partner equity, our business plan is to give
                  accretive terms with the versatility to adjust to possible
                  changes in market conditions.
                </Body>
              </ExtendedFlex>
            </Flex>
          </Box>
          <Box
            width={[1, 1, 0.5, 0.5, 0.5]}
          >
            <Flex
              flexDirection="column"
              align={['center', 'center', 'flex-start', 'flex-start', 'flex-start']}
              ml={[0, 0, 20, 40, 60]}
              mt={[50, 50, 0, 0, 0]}
            >
              <Image
                src={graphStrategies}
                maxWidth="610px"
                width={['75%', 0.5, 0.85, 0.75, 542]}
                IEAspectRatioFix
                opacity={isVisible ? '1' : '0'}
                transition="opacity 0.5s 0.6s linear"
              />
              <Data
                type="D1"
                align="center"
                pl={[0, 0, 75, 125, 150]}
                py={20}
              >
                AMOUNT FINANCED TO DATE
              </Data>
            </Flex>
          </Box>
        </ExtendedFlex>
      </Box>
    );
  }
}

export default IconCallouts;
