import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Data,
  Link,
} from '../coreui';

const NotificationItem = ({
  header,
  body,
  children,
  buttonText,
  buttonProps,
}) => {
  return (
    <ExtendedFlex
      w={1}
      align="center"
      justify="space-between"
      flexDirection={['column', 'row']}
    >
      <ExtendedFlex
        flexDirection="column"
      >
        {
          header &&
          <Data
            type="D5"
            m={0}
            mb={[5]}
            color="white"
          >
            {header}
          </Data>
        }
        {
          body &&
          <Data
            m={0}
            type="D3"
            color="white"
          >
            {body}
          </Data>
        }
        {children}
      </ExtendedFlex>
      {
        buttonText &&
        <Link
          type="L2"
          w={[1, 'auto']}
          ml={[0, 40]}
          mt={[40, 0]}
          // eslint-disable-next-line
          {...buttonProps}
        >
          {buttonText}
        </Link>
      }
    </ExtendedFlex>
  );
};

NotificationItem.propTypes = {
  header: PropTypes.string,
  buttonText: PropTypes.string,
  text: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  children: PropTypes.object,
  buttonProps: PropTypes.object,
};

export default NotificationItem;
