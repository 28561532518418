import autoInvestTypes from './autoinvest/types';

export default {
  ...autoInvestTypes,
  OWNERSHIP_API_REQUESTED: 'OWNERSHIP_API_REQUESTED',
  OWNERSHIP_API_SUCCEEDED: 'OWNERSHIP_API_SUCCEEDED',
  OWNERSHIP_FILTER_SET: 'OWNERSHIP_FILTER_SET',
  DASHBOARD_API_REQUESTED: 'DASHBOARD_API_REQUESTED',
  DASHBOARD_API_SUCCEEDED: 'DASHBOARD_API_SUCCEEDED',
  DASHBOARD_API_FAILED: 'DASHBOARD_API_FAILED',
  CANCEL_INVESTMENT_API_REQUESTED: 'CANCEL_INVESTMENT_API_REQUESTED',
  CANCEL_INVESTMENT_API_SUCCEEDED: 'CANCEL_INVESTMENT_API_SUCCEEDED',
  CANCEL_INVESTMENT_API_FAILED: 'CANCEL_INVESTMENT_API_FAILED',
  PENDING_DASHBOARD_API_REQUESTED: 'PENDING_DASHBOARD_API_REQUESTED',
  PENDING_DASHBOARD_API_SUCCEEDED: 'PENDING_DASHBOARD_API_SUCCEEDED',
  PENDING_DASHBOARD_API_FAILED: 'PENDING_DASHBOARD_API_FAILED',
  PENDING_DASHBOARD_WAITLIST_FLAG_REQUESTED: 'PENDING_DASHBOARD_WAITLIST_FLAG_REQUESTED',
  PENDING_DASHBOARD_WAITLIST_FLAG_SUCCEEDED: 'PENDING_DASHBOARD_WAITLIST_FLAG_SUCCEEDED',
  PENDING_DASHBOARD_WAITLIST_FLAG_FAILED: 'PENDING_DASHBOARD_WAITLIST_FLAG_FAILED',
  PENDING_DASHBOARD_WATCHLIST_FLAG_REQUESTED: 'PENDING_DASHBOARD_WATCHLIST_FLAG_REQUESTED',
  PENDING_DASHBOARD_WATCHLIST_FLAG_SUCCEEDED: 'PENDING_DASHBOARD_WATCHLIST_FLAG_SUCCEEDED',
  PENDING_DASHBOARD_WATCHLIST_FLAG_FAILED: 'PENDING_DASHBOARD_WATCHLIST_FLAG_WATCHLIST',
  PENDING_DASHBOARD_MODAL_OPEN: 'PENDING_DASHBOARD_MODAL_OPEN',
  PENDING_DASHBOARD_MODAL_CLOSE: 'PENDING_DASHBOARD_MODAL_CLOSE',
  NOTIFICATION_API_REQUESTED: 'NOTIFICATION_API_REQUESTED',
  NOTIFICATION_API_SUCCEEDED: 'NOTIFICATION_API_SUCCEEDED',
  NOTIFICATION_API_FAILED: 'NOTIFICATION_API_FAILED',
  DRIP_TOGGLE_REQUESTED: 'DRIP_TOGGLE_REQUESTED',
  DRIP_TOGGLE_SUCCEEDED: 'DRIP_TOGGLE_SUCCEEDED',
  DRIP_TOGGLE_FAILED: 'DRIP_TOGGLE_FAILED',
  TAXCENTER_UPDATE_FILTER: 'TAXCENTER_UPDATE_FILTER',
  TAXCENTER_UPDATE_FILTER_MODAL: 'TAXCENTER_UPDATE_FILTER_MODAL',
  TAXCENTER_API_REQUESTED: 'TAXCENTER_API_REQUESTED',
  TAXCENTER_API_SUCCEEDED: 'TAXCENTER_API_SUCCEEDED',
  TAXCENTER_API_FAILED: 'TAXCENTER_API_FAILED',
  TAXCENTER_DOCUMENT_SELECTED: 'TAXCENTER_DOCUMENT_SELECTED',
  TAXCENTER_DOCUMENT_TOGGLE_ALL: 'TAXCENTER_DOCUMENT_TOGGLE_ALL',
  TAXCENTER_RESET_FILTERS: 'TAXCENTER_RESET_FILTERS',
  TAXCENTER_RESET_REQUESTED: 'TAXCENTER_RESET_REQUESTED',
  TAXCENTER_FILTER_REQUESTED: 'TAXCENTER_FILTER_REQUESTED',
  TAXCENTER_APPLY_FILTERS: 'TAXCENTER_APPLY_FILTERS',
  TAXCENTER_DOWNLOADS_REQUESTED: 'TAXCENTER_DOWNLOADS_REQUESTED',
};
