import React from 'react';

import {
  Box,
  Flex,
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
  Image,
  Theme,
} from '../../coreui';

// Creates a single content slide from object of slide info (see `slideCollection`)
const createContentSlide = (slideInfo, isVisible) => {
  return (
    <Box
      key={slideInfo.slideStepTitle}
    >
      <Flex
        flexDirection={['column', 'column', 'row', 'row', 'row']}
        mx={['10%', '20%', '10%', '10%', '10%']}
        mb={[0, 0, 30, 40, 60]}
      >
        <ExtendedBox
          width={[1, 1, 0.4, 0.4, 0.4]}
          boxSizing="border-box"
          pr={[0, 0, 20, 30, 40]}
        >
          <Flex
            flexDirection="column"
            align={['center', 'center', 'flex-end', 'flex-end', 'flex-end']}
          >
            <ExtendedFlex
              maxWidth={['100%', '100%', 300, 340, 500]}
              flexDirection="column"
            >
              <Heading
                type="H8"
                m={0}
                mb="1em"
                opacity={isVisible ? '1' : '0'}
                transition={`opacity ${Theme.fadeInDelay}ms 0.3 linear`}
              >
                {slideInfo.slideStepTitle}
              </Heading>
              <Heading
                type="H4"
                color="blue.1"
                m={0}
                mb="0.8em"
                opacity={isVisible ? '1' : '0'}
                transition={`opacity ${Theme.fadeInDelay}ms 0.3s linear`}
              >
                {slideInfo.mainTitle}
              </Heading>
              <ExtendedBox
                display={['block', 'block', 'none']}
                my={[20, 40, 0, 0, 0]}
              >
                <Image
                  src={slideInfo.image}
                  width="100%"
                  maxWidth="866px"
                  IEAspectRatioFix
                  opacity={isVisible ? '1' : '0'}
                  transition={`opacity ${Theme.fadeInDelay}ms 0.9s linear`}
                />
              </ExtendedBox>
              <Body
                type="B1"
                opacity={isVisible ? '1' : '0'}
                transition={`opacity ${Theme.fadeInDelay}ms 0.6s linear`}
              >
                {slideInfo.bodyContent}
              </Body>
            </ExtendedFlex>
          </Flex>
        </ExtendedBox>
        <ExtendedBox
          width={[1, 1, 0.6, 0.6, 0.6]}
          boxSizing="border-box"
          display={['none', 'none', 'block']}
        >
          <Image
            src={slideInfo.image}
            width="100%"
            maxWidth="866px"
            IEAspectRatioFix
            opacity={isVisible ? '1' : '0'}
            transition={`opacity ${Theme.fadeInDelay}ms 0.9s linear`}
          />
        </ExtendedBox>
      </Flex>
    </Box>
  );
};

export default createContentSlide;
