import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
  ExtendedFlex,
  Body,
  InlineTextLink,
  Link,
} from '../coreui';

import {
  dashboardUrl,
} from '../dashboard/links';

const FourOhThree = ({
  isLoggedIn,
}) => {
  return (
    <ExtendedFlex
      flexDirection="column"
      align="center"
      justify="center"
    >
      <ExtendedFlex
        w={1}
        flexDirection="column"
        maxWidth={1024}
        px="1em"
        mt={75}
        mb={140}
      >
        <Heading
          type="H2"
          color="blue.1"
        >
          Access Denied
        </Heading>
        <Body
          type="B1"
        >
          Unfortunately, your current permissions do not allow access to this page.
        </Body>
        <Body
          type="B2"
        >
          If you believe this is an error, please contact Investor Relations.
        </Body>
        <Body
          type="B3"
        >
          <InlineTextLink href="tel:+18779772776">(877) 977-2776</InlineTextLink>
          <br />
          <InlineTextLink
            href="mailto:investor-help@realtymogul.com"
          >
            investor-help@realtymogul.com
          </InlineTextLink>
        </Body>
        {
          isLoggedIn &&
          <Link
            type="L1"
            href={dashboardUrl}
            mt={50}
          >
            My Dashboard
          </Link>
        }
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

FourOhThree.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default FourOhThree;
