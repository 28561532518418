import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  Box,
  ExtendedFlex,
  Heading,
  Body,
  VisibilitySensor,
} from '../../../../coreui';

import backgroundImage from '../../../../assets/multifamily_strategy.jpg';
import FadeIn from '../../../../components/FadeIn';
import BrowseLink from '../../../../components/BrowseLink';

const StrategySection1 = ({
  handleSensorChange,
  isVisible,
  isLoggedIn,
}) => {
  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
    >
      <ExtendedBox
        w={1}
        bgRepeat="no-repeat"
        bgImage={backgroundImage}
        bgPosition="50% 26%"
        bgSize="cover"
      >
        <Box
          w={1}
          px={['15%', '25%', '10%', '10%', '10%']}
        >
          <ExtendedFlex
            flexDirection="column"
            align={['center', 'center', 'flex-end', 'flex-end', 'flex-end']}
          >
            <ExtendedFlex
              maxWidth={['100%', '100%', 400, 400, 540]}
              flexDirection="column"
              my={[60, 60, 100, 100, 100]}
            >
              <FadeIn>
                <Heading
                  type="H2"
                  color="white"
                  m={0}
                  mb="1em"
                  opacity={isVisible ? '1' : '0'}
                >
                  MULTIFAMILY OPPORTUNITIES
                </Heading>
              </FadeIn>
              <VisibilitySensor
                callback={handleSensorChange}
              />
              <FadeIn
                fadeInIndex={2}
              >
                <Body
                  type="B1"
                  color="white"
                  fontWeight="light"
                  opacity={isVisible ? '1' : '0'}
                >
                  Per Real Capital Analytics, over $150B in multifamily properties were sold in
                  2017, making it the largest of the four main commercial real estate classes by
                  transaction volume (multifamily, office, retail, and industrial).
                </Body>
                <Body
                  type="B1"
                  color="white"
                  fontWeight="light"
                  opacity={isVisible ? '1' : '0'}
                >
                  The growing demand for multifamily housing combined with low supply has resulted
                  in increasing rental rates and low vacancy. We believe there is an opportunity in
                  the multifamily market, which investors on our platform can access through Real
                  Estate Investment Trusts (&quot;REITs&quot;) and individual investments.
                </Body>
                <ExtendedBox
                  opacity={isVisible ? '1' : '0'}
                >
                  <BrowseLink
                    isLoggedIn={isLoggedIn}
                  />
                </ExtendedBox>
              </FadeIn>
            </ExtendedFlex>
          </ExtendedFlex>
        </Box>
      </ExtendedBox>
    </Box>
  );
};

StrategySection1.propTypes = {
  handleSensorChange: PropTypes.func,
  isVisible: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
};

export default StrategySection1;
