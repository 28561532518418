import React from 'react';
import PropTypes from 'prop-types';

import {
  Body,
} from '../coreui';

const Disclaimer = (props) => {
  const { children, ...passedProps } = props;

  return (
    <Body
      type="B2"
      // eslint-disable-next-line
      {...passedProps}
    >
      {children}
    </Body>
  );
};

Disclaimer.propTypes = {
  children: PropTypes.any,
};

export default Disclaimer;
