import React from 'react';
import PropTypes from 'prop-types';

import Label from '../../components/forms/Label';
import Input from '../../components/forms/Input';
import { Heading, Body } from '../../coreui';

const SourceOfWealth = ({
  value,
  handleChange,
}) => (
  <>
    <Heading
      type="H6"
      textTransform="uppercase"
    >
      Source of Wealth
    </Heading>
    <Body
      type="B2"
    >
      What is the largest contributor to your net worth? Common sources of
      wealth include savings, company sale, or inheritance. This information
      helps to confirm your status as an Accredited investor.
    </Body>
    <Label
      htmlFor="sourceOfWealth"
    >
      Source of Wealth
      <Input
        id="sourceOfWealth"
        value={value}
        placeholder="Source of Wealth"
        name="sourceOfWealth"
        onChange={handleChange}
        data-test="wealth_source"
        maxLength="255"
        required
      />
    </Label>
  </>
);

SourceOfWealth.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.string,
};

export default SourceOfWealth;
