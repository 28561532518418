import React from 'react';
import PropTypes from 'prop-types';
import {
  withRouter,
} from 'react-router-dom';

import {
  ExtendedFlex,
  ExtendedBox,
  GlossaryAccordion,
} from '../../..';

import AccordionHeading from './AccordionHeading';
import ScrollToHash from '../../../../components/ScrollToHash';

class AccordionWithTopic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndexes: [],
    };

    this.checkIfToggleAccordionViaHash = this.checkIfToggleAccordionViaHash.bind(this);
    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  componentDidMount() {
    this.checkIfToggleAccordionViaHash();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.hash !== this.props.location.hash) {
      this.checkIfToggleAccordionViaHash();
    }
    if (prevProps.items !== this.props.items) {
      this.checkIfToggleAccordionViaHash();
    }
  }

  checkIfToggleAccordionViaHash() {
    if (this.props.location.hash) {
      const hash = this.props.location.hash.substr(1);
      const items = this.props.items.filter((item) => item.machine_name === hash);
      if (items.length > 0) {
        const id = items[0].id;
        if (!this.state.activeIndexes.includes(id)) {
          this.toggleAccordion(id);
        }
      }
    }
  }

  toggleAccordion(id) {
    this.setState((state) => ({
      activeIndexes: state.activeIndexes.includes(id) ?
        state.activeIndexes.filter(
          (i) => {
            return i !== id;
          },
        ) :
        [
          ...state.activeIndexes,
          id,
        ],
    }));
  }

  render() {
    const {
      items,
      contentIsHtml,
    } = this.props;

    const {
      activeIndexes,
    } = this.state;

    return (
      <ExtendedFlex
        flexDirection="column"
        justify="center"
        align="center"
        pt={20}
        w={1}
      >
        {
          items.map(
            (item) => {
              const isActive = activeIndexes.includes(item.id);

              return (
                <ExtendedFlex
                  key={item.id}
                  flexDirection="column"
                  justify="center"
                  w={1}
                  align="center"
                  mt={5}
                >
                  <ScrollToHash
                    name={item.machine_name}
                  />
                  <ExtendedBox
                    bg="white"
                    w={1}
                    py={20}
                  >
                    <AccordionHeading
                      content={item.topic}
                      id={item.id}
                      isActive={isActive}
                      handleClick={this.toggleAccordion}
                      isUppercase
                    />
                  </ExtendedBox>
                  {
                    isActive &&
                      <GlossaryAccordion
                        items={item.items}
                        contentIsHtml={contentIsHtml}
                      />
                  }
                </ExtendedFlex>
              );
            },
          )
        }
      </ExtendedFlex>
    );
  }
}

AccordionWithTopic.propTypes = {
  items: PropTypes.array,
  contentIsHtml: PropTypes.bool,
  location: PropTypes.object,
};

export default withRouter(AccordionWithTopic);
