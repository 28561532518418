import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';
import stubResponse from '../data';

import { getFAQItemsDataApi } from '../../../api';

export const mockApi = () => ({
  data: stubResponse,
});

export function* getFAQItemsDataFlow(action) {
  const id = action.id;
  try {
    const response = yield call(getFAQItemsDataApi, id);
    yield put(
      actions.getQueueFAQItemsDataSucceeded(response.data),
    );
  } catch (error) {
    yield put(
      actions.getQueueFAQItemsDataFailed(error),
    );
  }
}

export function* watchGetFAQItemsRequested() {
  yield takeLatest(
    types.GET_FAQ_ITEMS_REQUESTED,
    getFAQItemsDataFlow,
  );
}

export default watchGetFAQItemsRequested;
