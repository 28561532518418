import {
  takeEvery,
  takeLatest,
  call,
  put,
  all,
  select,
} from 'redux-saga/effects';

import { selectPendingInvestments } from './selectors';
import types from '../types';
import actions from '../actions';
import transactionsActions from '../transactions/actions';
import { dashboardDataApi, pendingDashboardDataApi, ownershipApi } from '../api';
import { callApiCallback } from '../../api';

export function* dashboardDataFlow(action) {
  try {
    const response = yield call(dashboardDataApi, action.foeId);
    const {
      transactions,
      formsOfOwnership,
      ...dashboardData
    } = response.data;
    yield all([
      put(
        actions.dashboardApiActionSucceeded(
          dashboardData,
        ),
      ),
      ...((transactions && formsOfOwnership) ? [
        put(
          transactionsActions.dashboardTransactionsApiActionSucceeded({
            ...transactions,
            formsOfOwnership,
          }),
        ),
      ] : []),
      ...(formsOfOwnership ? [
        put(
          actions.ownershipDataSucceeded(
            formsOfOwnership,
          ),
        ),
      ] : []),
    ]);
  } catch (error) {
    yield put(
      actions.dashboardApiActionFailed(
        error,
      ),
    );
  }
}

export function* pendingDashboardDataFlow() {
  try {
    const response = yield call(pendingDashboardDataApi);
    yield put(
      actions.pendingDashboardDataSucceeded(
        response.data,
      ),
    );
  } catch (error) {
    yield put(
      actions.pendingDashboardDataFailed(
        error,
      ),
    );
  }
}

export function* ownershipDataFlow() {
  try {
    const response = yield call(ownershipApi);
    yield put(
      actions.ownershipDataSucceeded(
        response.data,
      ),
    );
  } catch (error) {
    yield put(
      actions.dashboardApiActionFailed(
        error,
      ),
    );
  }
}

export function* pendingDashboardWaitListFlow(action) {
  try {
    const id = action.id;
    const callback = action.callback;
    let cancelInvestment = null;

    // If we're unflagging, then check if there's a pending investment for this
    // investment opportunity.
    if (!action.flagged) {
      const {
        pendingInvestments,
      } = yield select(selectPendingInvestments);

      cancelInvestment = pendingInvestments.reduce((accum, item) => {
        if (accum !== null) {
          return accum;
        }

        if (item.ioId === id) {
          return {
            id: item.id,
            callback: item.cancel,
          };
        }
        return accum;
      }, null);
    }

    // Call the unflagging callback and optionally call the cancel investment
    // callback.
    const [response] = yield all([
      call(callApiCallback, callback),
      ...(cancelInvestment ? [
        put(
          actions.cancelInvestmentRequested(
            cancelInvestment.id, cancelInvestment.callback,
          ),
        ),
      ] : []),
    ]);

    // Pass the success response back and update the dashboard.
    yield all([
      put(
        actions.pendingDashboardWaitlistFlagSucceeded(
          id,
          action.flagged,
          {
            response: response.data,
          },
        ),
      ),
      // Refresh the dashboard when the item was flagged.
      ...(action.flagged ? [
        put(actions.dashboardApiActionRequested()),
        put(actions.pendingDashboardDataRequested()),
      ] : []),
    ]);
  } catch (error) {
    yield put(
      actions.pendingDashboardWaitlistFlagFailed(
        error,
      ),
    );
  }
}

export function* pendingDashboardWatchListFlow(action) {
  try {
    const id = action.id;
    const callback = action.callback;
    const response = yield call(callApiCallback, callback);

    yield all([
      put(
        actions.pendingDashboardWatchlistFlagSucceeded(
          id,
          action.flagged,
          {
            response: response.data,
          },
        ),
      ),
      put(actions.dashboardApiActionRequested()),
    ]);
  } catch (error) {
    yield put(
      actions.pendingDashboardWatchlistFlagFailed(
        error,
      ),
    );
  }
}

export function* watchDashboardApiRequested() {
  yield takeLatest(
    types.DASHBOARD_API_REQUESTED,
    dashboardDataFlow,
  );
}

export function* watchPendingDashboardApiRequested() {
  yield takeLatest(
    types.PENDING_DASHBOARD_API_REQUESTED,
    pendingDashboardDataFlow,
  );
}

export function* watchOwnershipApiRequested() {
  yield takeLatest(
    types.OWNERSHIP_API_REQUESTED,
    ownershipDataFlow,
  );
}

export function* watchPendingDashboardWaitListRequested() {
  yield takeEvery(
    types.PENDING_DASHBOARD_WAITLIST_FLAG_REQUESTED,
    pendingDashboardWaitListFlow,
  );
}

export function* watchPendingDashboardWatchListRequested() {
  yield takeEvery(
    types.PENDING_DASHBOARD_WATCHLIST_FLAG_REQUESTED,
    pendingDashboardWatchListFlow,
  );
}

export default [
  watchDashboardApiRequested(),
  watchPendingDashboardApiRequested(),
  watchOwnershipApiRequested(),
  watchPendingDashboardWaitListRequested(),
  watchPendingDashboardWatchListRequested(),
];
