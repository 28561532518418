import React from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  Flex,
} from '../../coreui';

const HeaderMobileUserMenu = ({ user, mobileMenuVisible, closeMenu, handleLogout, isApp, frontendPrefix }) => {
  return (
    <Flex
      pt={40}
      flexDirection="column"
      justify="center"
      align="center"
    >
      {
        user.isLoggedIn ?
          <Link
            type="L2"
            href="/user/logout"
            onClick={handleLogout}
            display="inline-block"
            minWidth="0"
            opacity={mobileMenuVisible ? '1' : '0'}
            transition={`opacity 0.3s ${mobileMenuVisible ? '0.3s' : '0s'} linear`}
          >
            Sign Out
          </Link> :
          <Flex
            flexDirection="column"
            justify="center"
            align="center"
          >
            <Link
              type="L1"
              {...(isApp ? {
                href: `${frontendPrefix}/user/register`,
              } : {
                to: '/user/register',
              })}
              onClick={closeMenu}
              display="block"
              opacity={mobileMenuVisible ? '1' : '0'}
              transition={`opacity 0.3s ${mobileMenuVisible ? '0.3s' : '0s'} linear`}
            >
              Join Now
            </Link>
            <Link
              type="L5"
              display="block"
              mt={30}
              to="/user/login"
              onClick={closeMenu}
              color="white"
              hoverColor="blue.2"
              opacity={mobileMenuVisible ? '1' : '0'}
              transition={
                `opacity 0.3s ${mobileMenuVisible ? '.3s' : '0s'} linear,
                color 0.3s linear
              `
              }
            >
              Sign In
            </Link>
          </Flex>
      }
    </Flex>
  );
};

HeaderMobileUserMenu.propTypes = {
  user: PropTypes.object,
  mobileMenuVisible: PropTypes.bool,
  closeMenu: PropTypes.func,
  handleLogout: PropTypes.func,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

export default HeaderMobileUserMenu;
