import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import { getIOApi, getIOFAQtDataApi } from '../../../api';

export function* getReitsDataFlow(action) {
  try {
    const response = yield call(getIOApi, `${action.id1},${action.id2}`);
    yield put(actions.getReitsDataSucceeded({
      data: response.data.data,
    }));
  } catch (error) {
    yield put(
      actions.getReitsDataFailed(error),
    );
  }
  try {
    const mr1FAQ = yield call(getIOFAQtDataApi, false, true, action.id1);
    const mr2FAQ = yield call(getIOFAQtDataApi, false, true, action.id2);
    yield put(actions.getReitsFAQDataSucceeded([mr1FAQ.data.data, mr2FAQ.data.data]));
  } catch (error) {
    yield put(
      actions.getReitsFAQDataFailed(error),
    );
  }
}

export function* watchGetReitsDataRequested() {
  yield takeLatest(
    types.GET_REITS_DATA_REQUESTED,
    getReitsDataFlow,
  );
}

export default watchGetReitsDataRequested;
