import React from 'react';
import styled from 'styled-components';
import cleanElement from 'clean-element';

const CleanUl = cleanElement('ul');

const StyledUl = styled(CleanUl)`
  margin: 10px 0;
  padding: 0;
`;

const UnorderedList = (props) => (
  // eslint-disable-next-line
  <StyledUl {...props} />
);

export default UnorderedList;
