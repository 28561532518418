import React from 'react';
import PropTypes from 'prop-types';

import {
  Heading,
  Body,
  StyledButton,
  ExtendedFlex,
  Link,
} from '../../coreui';
import Actions from '../../components/forms/Actions';
import ScrollToSpanOnMount from '../../components/ScrollToSpanOnMount';
import CancelModal from '../../dashboard/autoinvest/components/CancelModal';

const BlockerPage = ({
  showCancelModal,
  handleCancelToggle,
  cancelText,
  cancelHeader,
  cancelBody,
  cancelCallback,
  submitting,
}) => {
  return (
    <>
      <ScrollToSpanOnMount offset={[-60, -110, null, -110]} />
      <ExtendedFlex
        w={[1, null, 650]}
        flexDirection="column"
      >
        <Heading
          mt={0}
          type="H2"
        >
          We&apos;re sorry, we can&apos;t continue with this order
        </Heading>
        <Body
          type="B1"
        >
          You are required to have approvals from your employer before you can continue with this investment. Once you have the approvals, you can continue with this investment by accessing it from your Dashboard.
        </Body>
        <CancelModal
          isVisible={showCancelModal}
          header={cancelHeader}
          body={cancelBody}
          handleClick={handleCancelToggle}
          handleCancel={cancelCallback}
          submitting={submitting}
        />
        <Actions>
          <StyledButton
            type="L4"
            onClick={handleCancelToggle}
          >
            {cancelText}
          </StyledButton>
          <Link type="L4" href="/investor-dashboard">
            Go to my dashboard
          </Link>
        </Actions>
      </ExtendedFlex>
    </>
  );
};

BlockerPage.propTypes = {
  showCancelModal: PropTypes.bool,
  handleCancelToggle: PropTypes.func,
  cancelText: PropTypes.string,
  cancelHeader: PropTypes.string,
  cancelBody: PropTypes.string,
  cancelCallback: PropTypes.func,
  submitting: PropTypes.bool,
};

export default BlockerPage;
