import React from 'react';
import PropTypes from 'prop-types';

import Text from './Text';
import {
  ExtendedFlex,
  Body,
  Image,
  Heading,
} from '../coreui';

const firstAnimation = 'firstTimeLine';
const secondAnimation = 'secondTimeLine';

const Container = ({
  firstPosition,
  ...props
// eslint-disable-next-line
}) => <ExtendedFlex {...props} />;

Container.propTypes = {
  firstPosition: PropTypes.bool,
};
class TimelineCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: undefined,
      animationName: undefined,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // If we've already been visible, we don't want to do the animation again.
    const isVisible = state.isVisible || props.isVisible;

    // If state says we've already become visible, lock the animation to prevent
    // it from running again.
    const name = state.isVisible ? state.animationName : (() => {
      let animationName = (props.position === 'second' && secondAnimation) || firstAnimation;

      // Check if we're a mobile size. This is really just to make the animation
      // slightly better UX, so it doesn't really impact the user if it doesn't get
      // it 100% correct.
      if (props.position !== 'second' && (typeof window !== 'undefined' || typeof document !== 'undefined') && (window.innerWidth || document.documentElement.clientWidth) < 832) {
        animationName = secondAnimation;
      }
      return animationName;
    })();

    return {
      animationName: name,
      isVisible,
    };
  }

  render() {
    const {
      position,
      icon,
      heading,
      body: bodyProp,
      children,
      isVisible,
      ...props
    } = this.props;

    const body = bodyProp && !Array.isArray(bodyProp) ? [bodyProp] : bodyProp;

    const {
      animationName,
    } = this.state;

    const animationProps = isVisible ? {
      animationName,
      duration: '1s',
      fillMode: 'backwards',
      delay: '0s',
    } : {};

    return (
      <Container
        position="relative"
        flexDirection="column"
        align="center"
        bg="white"
        mx={[0, 0, 40]}
        my={20}
        w={['calc(100% - 40px)', 350, null, 450, 480]}
        maxWidth={['100%', null, '35vw', null, '100%']}
        p={20}
        zIndex={2}
        firstPosition={animationName === firstAnimation}
        // eslint-disable-next-line
        {...props}
        // eslint-disable-next-line
        {...animationProps}
      >
        <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          {
            icon &&
            <Image
              src={icon}
            />
          }
          {
            heading &&
            <Heading
              type="H5"
              width="75%"
              textAlign={['right', 'right', 'center']}
              letterSpacing="-0.5px"
            >
              {heading}
            </Heading>
          }
        </span>
        {
          body &&
          body.map((item) => (
            <Body
              type="B1"
              textAlign="left"
              w={1}
              key={item}
            >
              <Text>{item}</Text>
            </Body>
          ))
        }
        {children}
      </Container>
    );
  }
}

TimelineCard.propTypes = {
  isVisible: PropTypes.bool,
  position: PropTypes.oneOf([
    'first',
    'second',
  ]),
  icon: PropTypes.string,
  heading: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.object,
};

export default TimelineCard;
