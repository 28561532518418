import React from 'react';
import PropTypes from 'prop-types';

import {
  Body,
  Heading,
  ExtendedBox,
  Link,
  Flex,
  InvestmentCard,
} from '../../coreui';
import MRI from '../../assets/MRI.jpg';
import MRII from '../../assets/MRII.jpg';

const MeetReits = ({ body, bottomText, investments }) => {
  return (
    <ExtendedBox
      maxWidth="1440px"
      mx={['5%', '5%', '10%', '10%', 'auto']}
    >
      <ExtendedBox
        textAlign="center"
      >
        <Body
          type="B1"
          textAlign="center"
        >
          {body}
        </Body>
      </ExtendedBox>
      <Flex
        pt={[20, 40]}
        w={1}
        justify="center"
      >
        <Flex
          wrap="wrap"
          justify="center"
        >
          {
            investments &&
            investments.map(
              (investment, index) => {
                const ltype = 'to';
                const path = investment.path;
                const linkText = 'View Details';
                let image = null;
                switch (investment.imageName) {
                  case 'MRI':
                    image = MRI;
                    break;
                  case 'MRII':
                  default:
                    image = MRII;
                }

                return (
                  <InvestmentCard
                    zIndex="0"
                    cardHeight="mid"
                    fadeIndex={investment.fadeIndex !== undefined ? investment.fadeIndex : index}
                    key={investment.id}
                    id={investment.id}
                    reit={investment.REIT}
                    statusLabel={investment.statusLabel}
                    status={investment.status}
                    minimum={investment.minimum}
                    targetHoldMin={investment.targetHoldMin}
                    targetHoldMax={investment.targetHoldMax}
                    blurb={investment.blurb}
                    propertyName={investment.label}
                    investmentTypeDisplay={investment.investmentTypeDisplay}
                    propertyType={investment.propertyType}
                    propertyLocation={investment.propertyLocation}
                    ioLabel={investment.title}
                    payoutLabel={investment.payoutLabel}
                    actualIrr={investment.actualIRR}
                    irrObjective={investment.IRRObjective}
                    returnObjective={investment.returnObjective}
                    actualHold={investment.actualHold}
                    investmentGoal={investment.IRRObjective}
                    fundedDate={investment.escrowCloseDate}
                    completedDate={investment.actualMaturityDate}
                    amountInvested={investment.amountInvested}
                    pathAlias={path}
                    imageUrl={image}
                    distribution={investment.lastDistributionDeclared}
                    distributionTooltip={investment.distributionTooltip}
                    payoutTooltip={investment.payoutTooltip}
                    watchlist={investment.watchlist}
                    noAnimate={investment.noAnimate}
                    blurContent={investment.blurContent}
                    linkText={linkText}
                    linkType={ltype}
                  />
                );
              },
            )
          }
        </Flex>
      </Flex>
      <ExtendedBox
        textAlign="center"
        borderTop="2px solid"
        borderColor="gray.7"
        mt={80}
        pt={70}
      >
        <Heading
          type="H8"
          textAlign="center"
          textTransform="uppercase"
          display="block"
        >
          {bottomText}
        </Heading>
        <Link
          type="L1"
          to="/knowledge-center/retirement-investing"
          my={75}
        >
          Learn More
        </Link>
      </ExtendedBox>
    </ExtendedBox>
  );
};

MeetReits.propTypes = {
  body: PropTypes.string,
  bottomText: PropTypes.string,
  investments: PropTypes.array,
};

export default MeetReits;
