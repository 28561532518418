import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import noop from 'lodash/noop';

import {
  isqProps,
} from '../data';

import {
  cleanUpIsqSubmission,
  updateFormDataByHook,
} from '../utility';

import {
  ExtendedFlex,
  Heading,
  Body,
  StyledButton,
} from '../../coreui';
import Stepper from '../../components/forms/Stepper';
import Actions from '../../components/forms/Actions';
import PrimaryButton from '../../components/forms/PrimaryButton';
import CancelButtonWithModal from './CancelButtonWithModal';
import Form from '../../components/forms/Form';
import PrimaryReason from './PrimaryReason';
import Experience from './Experience';
import MoneyAccess from './MoneyAccess';
import IlliquidLoseValue from './IlliquidLoseValue';
import IncomeNetWorth from './IncomeNetWorth';
import EmploymentStatus from '../../components/isq/EmploymentStatus';
import ScrollToSpanOnMount from '../../components/ScrollToSpanOnMount';
import EnrollmentErrors from '../../dashboard/autoinvest/components/EnrollmentErrors';
import CancelModal from '../../dashboard/autoinvest/components/CancelModal';
import NetWorthFollowUp from './NetWorthFollowUp';
import FINRAQuestions from './FINRAQuestions';
import BlockerPage from './BlockerPage';

// Helper function to get data to submit per step.
// If steps are re-ordered in the output, this function must also be updated as
// well as (potentially) getPreviousStep().
export const getSubmitData = (data, { step, subStep, networthFollowUpNeeded, singleStepOnly = false }) => {
  let props = [];

  if (singleStepOnly) {
    if (step === 1) {
      props = [
        'primaryInvestmentReason',
        'primaryInvestmentReasonFollowUp',
      ];
    }
    if (step === 2) {
      props = [
        'expRealEstate',
        'expPrivateEquity',
        'expVentureCapital',
        'experienceFollowUp',
      ];
    }
    if (step === 3) {
      props = [
        'moneyAccess',
        'moneyAccessFollowUp',
      ];
    }
    if (step === 4) {
      props = [
        'illiquidLoseValue',
      ];
    }
    if (step === 5) {
      props = [
        'annualIncome',
        'netWorthSpecific',
        'sourceOfWealth',
      ];
    }
    if (step === 5 && subStep === 2) {
      props = [
        'over20PctNetWorth',
        'over20PctNetWorthFollowUp',
      ];
    }
    if (step === 6) {
      props = [
        'sourceOfIncome',
        'employmentType',
        'employer',
        'employmentPositionHeld',
        'employmentIndustry',
        'isFinraBdEmployee',
        'hasNotifiedFinraBdEmployer',
      ];
    }
    return [props, pick(data, props)];
  }

  // Build up the props based on the previous steps.
  if (step >= 1) {
    props = [
      ...props,
      'primaryInvestmentReason',
      'primaryInvestmentReasonFollowUp',
    ];
  }
  if (step >= 2) {
    props = [
      ...props,
      'expRealEstate',
      'expPrivateEquity',
      'expVentureCapital',
      'experienceFollowUp',
    ];
  }
  if (step >= 3) {
    props = [
      ...props,
      'moneyAccess',
      'moneyAccessFollowUp',
    ];
  }
  if (step >= 4) {
    props = [
      ...props,
      'illiquidLoseValue',
    ];
  }
  if (step >= 5) {
    props = [
      ...props,
      'annualIncome',
      'netWorthSpecific',
      'sourceOfWealth',
    ];
  }
  if ((step === 5 && subStep === 2) || (step > 5 && networthFollowUpNeeded)) {
    props = [
      ...props,
      'over20PctNetWorth',
      'over20PctNetWorthFollowUp',
    ];
  }
  if (step >= 6) {
    props = [
      ...props,
      'sourceOfIncome',
      'employmentType',
      'employer',
      'employmentPositionHeld',
      'employmentIndustry',
      'isFinraBdEmployee',
      'hasNotifiedFinraBdEmployer',
    ];
  }
  return [props, pick(data, props)];
};

// Helper function to return previous step meta information based on context.
const getPreviousStep = (context) => {
  const { step, subStep, networthFollowUpNeeded } = context;

  if (subStep > 1) {
    return { step, subStep: subStep - 1 };
  }
  if (step === 6 && networthFollowUpNeeded) {
    return { step: step - 1, subStep: 2 };
  }
  return { step: step - 1, subStep };
};

const ISQv2 = (props) => {
  const {
    showHeader,
    showSubheader,
    showBackButton,
    showBlockerPage,
    timeHorizon,
    over20PctFollowUpTriggerAmount,
    cancelText,
    cancelHeader,
    cancelBody,
    enrollmentErrors,
    handleCancel,
    handlePartialSubmit,
    handleSubmit,
  } = props;

  const [step, setStep] = useState(1);
  const [subStep, setSubstep] = useState(1);
  const [stepOffset] = useState(0);
  const [total] = useState(6);
  const [submitting, setSubmitting] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(step === 1);
  const [cancelLinkClicked, setCancelLinkClicked] = useState();

  const [formData, setFormData] = useState(pick(props.latestIsq, isqProps));
  const {
    primaryInvestmentReason,
    primaryInvestmentReasonFollowUp,
    expRealEstate,
    expPrivateEquity,
    expVentureCapital,
    experienceFollowUp,
    moneyAccess,
    moneyAccessFollowUp,
    illiquidLoseValue,
    annualIncome,
    netWorthSpecific,
    sourceOfWealth,
    over20PctNetWorth,
    over20PctNetWorthFollowUp,
    sourceOfIncome,
    employmentType,
    employer,
    employmentPositionHeld,
    employmentIndustry,
    isFinraBdEmployee,
    hasNotifiedFinraBdEmployer,
  } = formData;

  // Step Visibility.
  // If these are re-ordered, getSubmitData() and (potentially)
  // getPreviousStep() must also be updated.
  const primaryReasonVisible = step === 1;
  const experienceVisible = step === 2;
  const moneyAccessVisble = step === 3;
  const illiquidLoseValueVisible = step === 4;
  const incomeNetWorthVisible = step === 5 && subStep === 1;
  const networthFollowUpNeeded = parseInt(netWorthSpecific, 10) > 0 && netWorthSpecific < over20PctFollowUpTriggerAmount;
  const networthFollowUpVisible = step === 5 && subStep === 2;
  const employmentStatusVisible = step === 6;
  const finraQuestionsVisible = step === 6;

  // Back button click handler.
  const handleBackClick = useCallback((e) => {
    e.preventDefault();

    const previousStep = getPreviousStep({
      step,
      subStep,
      networthFollowUpNeeded,
    });

    setStep(previousStep.step);
    setSubstep(previousStep.subStep);

    if (step === 2) {
      setDisableBackButton(true);
    }
  }, [step, subStep, setStep, setSubstep, setDisableBackButton, networthFollowUpNeeded]);

  // Cancel toggle handler.
  const handleCancelToggle = useCallback(() => {
    if (submitting) {
      // Don't toggle.
      return;
    }
    setShowCancelModal((showCancelModal) => {
      const value = !showCancelModal;
      if (value === false) {
        // Reset the flag.
        setCancelLinkClicked(false);
      }
      return value;
    });
  }, [submitting, setShowCancelModal, setCancelLinkClicked]);

  // Generic submit handler. Used for partial and full submits.
  const submitCallback = useCallback((callback, meta) => {
    const [, submitData] = getSubmitData(formData, meta);
    const isqSubmission = cleanUpIsqSubmission(
      submitData,
      { timeHorizon, isqProps, nullifyProps: true },
    );

    const promise = callback(isqSubmission);
    // We only want to pay attention to the promise if we're doing the final
    // submit or cancellation.
    if (promise && (callback === handleSubmit || callback === handleCancel)) {
      setSubmitting(true);

      promise.finally(() => {
        setSubmitting(false);
      });
    }
    return promise;
  }, [formData, timeHorizon, setSubmitting, handleSubmit, handleCancel]);

  // Cancel handler.
  const cancelCallback = useCallback(() => {
    let meta = { step, subStep, networthFollowUpNeeded };
    if (cancelLinkClicked) {
      // Treat it like we're on the previous step.
      const previousStep = getPreviousStep({
        step,
        subStep,
        networthFollowUpNeeded,
      });
      meta = { ...previousStep, networthFollowUpNeeded };
    }

    submitCallback(handleCancel, meta);
  }, [submitCallback, handleCancel, step, subStep, networthFollowUpNeeded, cancelLinkClicked]);

  // Form Submit handler.
  //
  // Controls the flow of the form as well as invoking the partial / full
  // submits when necessary.
  const handleFormSubmit = useCallback((e) => {
    e.preventDefault();

    // Meta information needed to calculate what form submit data we need.
    const meta = { step, subStep, networthFollowUpNeeded };

    if (primaryInvestmentReasonFollowUp === 'not_comfortable') {
      setShowCancelModal(true);
      return;
    }
    if (experienceFollowUp === 'not_comfortable') {
      setShowCancelModal(true);
      return;
    }
    if (moneyAccessFollowUp === 'Cancel my investment') {
      setShowCancelModal(true);
      return;
    }
    if (illiquidLoseValue === 'No, please cancel my investment') {
      setShowCancelModal(true);
      return;
    }
    if (over20PctNetWorthFollowUp === 'No, please cancel my investment') {
      setShowCancelModal(true);
      return;
    }

    // Need to go to the sub step.
    if (incomeNetWorthVisible && networthFollowUpNeeded) {
      submitCallback(handlePartialSubmit, meta);
      setSubstep(2);
      return;
    }

    // Advance the form.
    if (step < total) {
      setSubstep(1);
      setStep(step + 1);
      setDisableBackButton(false);
      submitCallback(handlePartialSubmit, meta);
    } else {
      // Call the submit handler.
      submitCallback(handleSubmit, meta);
    }
  }, [
    primaryInvestmentReasonFollowUp,
    experienceFollowUp,
    moneyAccessFollowUp,
    illiquidLoseValue,
    incomeNetWorthVisible,
    networthFollowUpNeeded,
    over20PctNetWorthFollowUp,
    setShowCancelModal,
    handleSubmit,
    handlePartialSubmit,
    setStep,
    step,
    subStep,
    total,
    setDisableBackButton,
    submitCallback]);

  // Change handler for form inputs. Updates the form data via the hook.
  const handleChange = useCallback((e) => {
    updateFormDataByHook(e, setFormData);
  }, [setFormData]);

  const handleCanceLinkClick = useCallback(() => {
    setCancelLinkClicked(true);
    handleCancelToggle();
  }, [handleCancelToggle, setCancelLinkClicked]);

  // Wait to show the blocker page until we're done submitting.
  if (showBlockerPage && !submitting) {
    return (
      <BlockerPage
        showCancelModal={showCancelModal}
        handleCancelToggle={handleCancelToggle}
        cancelText={cancelText}
        cancelHeader={cancelHeader}
        cancelBody={cancelBody}
        cancelCallback={cancelCallback}
        submitting={submitting}
      />
    );
  }

  return (
    <>
      <ScrollToSpanOnMount key={step} offset={[-60, -110, null, -110]} />
      <ExtendedFlex
        w={[1, null, 650]}
        flexDirection="column"
      >
        {
          showHeader &&
          <Heading
            mt={0}
            type="H2"
          >
            Tell Us More About Yourself
          </Heading>
        }
        {
          showSubheader &&
          <Body
            type="B1"
          >
            We need to learn more about your investment experience and
            preferences to gauge if this investment is appropriate for you.
          </Body>
        }
        <CancelModal
          isVisible={showCancelModal}
          header={cancelHeader}
          body={cancelBody}
          handleClick={handleCancelToggle}
          handleCancel={cancelCallback}
          submitting={submitting}
        />
        <Stepper
          step={step - stepOffset}
          total={total - stepOffset}
        />
        <Form onSubmit={(e) => handleFormSubmit(e)} >
          <ExtendedFlex
            flexDirection="column"
            w={1}
          >
            <PrimaryReason
              isVisible={primaryReasonVisible}
              value={primaryInvestmentReason}
              followUp={primaryInvestmentReasonFollowUp}
              handleChange={handleChange}
            />
            <Experience
              isVisible={experienceVisible}
              expRealEstate={expRealEstate}
              expPrivateEquity={expPrivateEquity}
              expVentureCapital={expVentureCapital}
              followUp={experienceFollowUp}
              handleChange={handleChange}
            />
            <MoneyAccess
              isVisible={moneyAccessVisble}
              value={moneyAccess}
              handleChange={handleChange}
              followUp={moneyAccessFollowUp}
              holdPeriod={timeHorizon}
            />
            <IlliquidLoseValue
              isVisible={illiquidLoseValueVisible}
              value={illiquidLoseValue}
              handleChange={handleChange}
            />
            <IncomeNetWorth
              isVisible={incomeNetWorthVisible}
              handleChange={handleChange}
              annualIncome={annualIncome}
              netWorthSpecific={netWorthSpecific}
              sourceOfWealth={sourceOfWealth}
            />
            <NetWorthFollowUp
              isVisible={networthFollowUpVisible}
              value={over20PctNetWorth}
              handleChange={handleChange}
              followUp={over20PctNetWorthFollowUp}
            />
            <EmploymentStatus
              isVisible={employmentStatusVisible}
              handleChange={handleChange}
              employmentType={employmentType}
              employer={employer}
              employmentPositionHeld={employmentPositionHeld}
              employmentIndustry={employmentIndustry}
              sourceOfIncome={sourceOfIncome}
              useEmployerAddress={false}
            />
            <FINRAQuestions
              isVisible={finraQuestionsVisible}
              value={isFinraBdEmployee}
              handleChange={handleChange}
              followUp={hasNotifiedFinraBdEmployer}
            />
          </ExtendedFlex>
          {
            enrollmentErrors &&
            <EnrollmentErrors
              // eslint-disable-next-line
              {...enrollmentErrors}
            />
          }
          <Actions
            mb={showBackButton ? 0 : 120}
            flexWrap="wrap"
          >
            {
              showBackButton &&
              <PrimaryButton
                type="L3"
                loading={submitting}
                disable={submitting || disableBackButton}
                onClick={handleBackClick}
                data-test="isq-back-button"
              >
                Back
              </PrimaryButton>
            }
            <PrimaryButton
              loading={submitting}
              disable={submitting}
              buttonType="submit"
              data-test="isq-submit-button"
            >
              Continue
            </PrimaryButton>
            <StyledButton
              type="L4"
              mt={showBackButton ? [null, null, 50] : undefined}
              onClick={handleCanceLinkClick}
              data-test="isq-cancel-link"
            >
              {cancelText}
            </StyledButton>
          </Actions>
        </Form>
      </ExtendedFlex>
    </>
  );
};

ISQv2.propTypes = {
  showHeader: PropTypes.bool,
  showSubheader: PropTypes.bool,
  showBackButton: PropTypes.bool,
  showBlockerPage: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handlePartialSubmit: PropTypes.func,
  timeHorizon: PropTypes.number,
  over20PctFollowUpTriggerAmount: PropTypes.number,
  latestIsq: PropTypes.shape({
    primaryInvestmentReason: PrimaryReason.propTypes.value,
    expRealEstate: Experience.propTypes.expRealEstate,
    expPrivateEquity: Experience.propTypes.expPrivateEquity,
    expVentureCapital: Experience.propTypes.expVentureCapital,
    moneyAccess: MoneyAccess.propTypes.value,
    illiquidLoseValue: IlliquidLoseValue.propTypes.value,
    annualIncome: PropTypes.number,
    netWorthSpecific: PropTypes.number,
    sourceOfWealth: IncomeNetWorth.propTypes.sourceOfWealth,
    employmentType: EmploymentStatus.propTypes.employmentType,
    employer: EmploymentStatus.propTypes.employer,
    employmentPositionHeld: EmploymentStatus.propTypes.employmentPositionHeld,
    employmentIndustry: EmploymentStatus.propTypes.employmentIndustry,
    employerAddress: EmploymentStatus.propTypes.employerAddress,
    sourceOfIncome: EmploymentStatus.propTypes.sourceOfIncome,
  }),
  handleCancel: PropTypes.func,
  cancelText: PropTypes.string,
  cancelHeader: PropTypes.string,
  cancelBody: CancelButtonWithModal.propTypes.body,
  enrollmentErrors: PropTypes.object,
};

ISQv2.defaultProps = {
  handleSubmit: noop,
  handlePartialSubmit: noop,
  handleCancel: noop,
};

export default ISQv2;
