import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledButton,
} from '../../coreui';

import withPortal from '../../withPortal';
import CancelModal from './CancelModal';

const componentRoot = typeof document !== 'undefined' ? document.getElementById('investflow-cancel-link') : null;

class CancelLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  }

  render() {
    const {
      text,
      path,
    } = this.props;
    const {
      showModal,
    } = this.state;

    return (
      <>
        <StyledButton
          type="L4"
          onClick={this.toggleModal}
        >
          {text}
        </StyledButton>
        <CancelModal
          isVisible={showModal}
          handleClick={this.toggleModal}
          path={path}
        />
      </>
    );
  }
}

CancelLink.propTypes = {
  text: PropTypes.string,
  path: PropTypes.string,
};

export default withPortal(
  CancelLink,
  componentRoot,
  {
    noRootNoRender: true,
  },
);
