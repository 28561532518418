import React from 'react';
import PropTypes from 'prop-types';

import AutoInvestContentWrapper from './AutoInvestContentWrapper';
import StepCounter from './StepCounter';
import {
  ExtendedFlex,
  Heading,
  Body,
  Link,
  Numeral,
  Formatters,
} from '../../../coreui';

import { autoInvestUrl } from '../../links';

const Confirmation = ({
  steps,
  ioLabel,
  foeType,
  foeTitle,
  amount,
  amountFormatter,
  emailRange,
  content,
  direct,
}) => {
  const amountFormatted = (
    <Numeral value={amount} format={amountFormatter} />
  );
  return (
    <AutoInvestContentWrapper>
      {
        steps &&
        <StepCounter
          steps={steps}
          activeStep={steps.length - 1}
        />
      }
      <ExtendedFlex
        flexDirection="column"
        w={1}
        maxWidth={650}
      >
        <Heading
          type="H2"
        >
          Thank you
        </Heading>
        <Heading
          type="H6"
          textTransform="uppercase"
        >
          Enrollment request received for:
        </Heading>
        <Body
          type="B1"
          textTransform="uppercase"
        >
          {ioLabel} - {foeType}: {foeTitle} - {amountFormatted}
        </Body>
        <Body
          type="B1"
        >
          {content}
          {
            !content &&
              <>
                {
                  direct ? (
                    <>
                      You will receive an email regarding your enrollment.
                    </>
                  ) : (
                    <>
                      You will receive an email regarding your status in {emailRange} business days.
                    </>
                  )
                } Feel free to check back under &quot;Auto Invest&quot; on your
                dashboard for any updates to your pending enrollment status or
                to add an additional Auto Invest enrollment.
              </>
          }
        </Body>
        <Link
          type="L4"
          to={autoInvestUrl}
        >
          Back to Auto Investments Home &gt;
        </Link>
      </ExtendedFlex>
    </AutoInvestContentWrapper>
  );
};

Confirmation.propTypes = {
  ioLabel: PropTypes.string,
  foeType: PropTypes.string,
  foeTitle: PropTypes.string,
  amount: PropTypes.number,
  emailRange: PropTypes.string,
  steps: StepCounter.propTypes.steps,
  amountFormatter: PropTypes.string,
  content: PropTypes.string,
  direct: PropTypes.bool,
};

Confirmation.defaultProps = {
  emailRange: '3-5',
  amountFormatter: Formatters.DOLLARS_DECIMALS,
};

export default Confirmation;
