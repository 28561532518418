/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ExtendedFlex, Heading } from '../coreui';
import TimelineFiller from './TimelineFiller';
import FadeIn from './FadeIn';

const BORDER_STYLE = '3px dotted #176DB6';

const HeaderContainer = styled.div`
  text-align: center;
  box-sizing: border-box;
  padding-bottom: 5px;
  width: 50%;
  p {
    letter-spacing: 1px;
  }
  ${({ underline }) => (underline ? `
    border-bottom: ${BORDER_STYLE};
  ` : '')}
  ${({ left }) => (left ? `border-left: ${BORDER_STYLE};` : '')};
  ${({ right }) => (right ? `border-right: ${BORDER_STYLE};` : '')};
  ${({ left, right }) => (left || right ? 'padding-top: 25px; min-height: 80px;' : '')};
`;

const MiddleLine = () => (
  <TimelineFiller
    mt="30px"
    mb="30px"
    dividerProps={{ borderLeft: BORDER_STYLE, borderColor: 'blue.0' }}
  />
);

const SideLines = styled.div`
  box-sizing: border-box;
  margin-top: 3px;
  width: 50%;
  height: 80px;
  ${({ left }) => (left ? `border-left: ${BORDER_STYLE};` : '')};
  ${({ right }) => (right ? `border-right: ${BORDER_STYLE};` : '')};
`;

const TimelineContainer = ({
  heading,
  children,
  dotsHeader = true,
  dotsAbove = false,
  dotsBelow = false,
  dotsLeftUp = false,
  dotsLeftDown = false,
  dotsRightUp = false,
  dotsRightDown = false,
  ...props }) => {
  const timelineFillerPadding = 30;

  return (
    <ExtendedFlex
      flexDirection="column"
      align="center"
      w={1}
    >
      {dotsAbove && <MiddleLine />}
      {
        heading &&
        <HeaderContainer
          underline={dotsHeader}
          left={dotsLeftUp}
          right={dotsRightUp}
        >
          <Heading
            type="H9"
          >
            {heading}
          </Heading>
        </HeaderContainer>
      }
      {(dotsLeftDown || dotsRightDown) && <SideLines left={dotsLeftDown} right={dotsRightDown} />}
      {dotsBelow && <MiddleLine />}
      <ExtendedFlex
        w={1}
        flexDirection={['column', null, 'row']}
        flexWrap="wrap"
        {...props}
        pl={[timelineFillerPadding, null, 0]}
        position="relative"
        overflowX="hidden"
      >
        <TimelineFiller
          position="absolute"
          left={[timelineFillerPadding, null, '50%']}
          top={0}
          bottom={0}
          zIndex={1}
        />
        {
          React.Children.map(children, (child, i) => {
            return (
              <FadeIn>
                {
                  React.cloneElement(
                    child,
                    {
                      position: i % 2 ? 'second' : 'first',
                    },
                  )
                }
              </FadeIn>
            );
          })
        }
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

TimelineContainer.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.object,
  dotsHeader: PropTypes.bool,
  dotsAbove: PropTypes.bool,
  dotsBelow: PropTypes.bool,
  dotsLeftUp: PropTypes.bool,
  dotsLeftDown: PropTypes.bool,
  dotsRightUp: PropTypes.bool,
  dotsRightDown: PropTypes.bool,
};

export default TimelineContainer;
