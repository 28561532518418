/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  ExtendedBox,
  Image,
  Link,
  StyledButton,
  Heading,
} from '../../coreui';

import downloadIcon from '../../assets/icon_download.svg';
import Download from '../../assets/Download.svg';
import Phone from '../../assets/Phone.svg';
import Calendar from '../../assets/Calendar.svg';
import Webinar from './webinar';

// eslint-disable-next-line
class QuickLinks extends React.Component {
  // eslint-disable-next-line
  showZendesk() {
    if (typeof zE !== 'undefined') {
      // eslint-disable-next-line
      zE.activate();
    }
  }

  render() {
    const {
      showKeyLine,
      webinar,
      documents,
      newStyle = false,
      io,
      ...passedProps
    } = this.props;

    return (
      <ExtendedFlex
        {...passedProps}
        flexDirection={['column', 'column', 'row']}
        justifyContent="center"
        mx={[20, 20, 20, 20, 0]}
        borderTop={showKeyLine ? '1px solid' : null}
        borderColor="gray.7"
        my={[10, 10, 0]}
        pt={[30, 30, 0]}
      >
        {webinar.length > 0 &&
          <Webinar
            {...webinar[0]}
            bg={newStyle ? '#F7F8F8' : 'gray.1'}
            boxShadow={newStyle ? '0px 12px 15px #0000000D' : null}
            flexBasis={newStyle ? null : '100%'}
            letterSpacing={newStyle ? '0' : 'initial'}
            newStyle={newStyle}
          />}
        {newStyle &&
          <ExtendedFlex
            bg="#F7F8F8"
            py={15}
            px={20}
            textAlign="center"
            my={[5, 5, 30]}
            mx={10}
            boxShadow="0px 12px 15px #0000000D"
            flexDirection="column"
            alignItems="center"
          >
            <Heading
              type="H8"
              display="block"
              textAlign="center"
              alignSelf="center"
              my="0"
              fontSize="13px"
              letterSpacing="1.56px"
              color="#202020"
              fontWeight="500"
            >
              Schedule appointment
            </Heading>
            <ExtendedBox textAlign="center">
              <Image
                src={Calendar}
                mr={10}
                height={newStyle ? '14px' : '20px'}
                position="relative"
                top="4px"
                display="inline-block"
              />
              <Link
                type="L4"
                lineHeight="28px"
                fontSize={newStyle ? '11px' : '12px'}
                target="_blank"
                href={`https://calendly.com/realtymogul-investor-relations/realty-mogul-representative-call?utm_campaign=dealpage&utm_content=${io.label.replace(/\s+/g, '-').toLowerCase()}`}
                letterSpacing={newStyle ? '0' : '0.15em'}
                data-button-id="book-appointment"
              >
                Book with Investor Relations Team
              </Link>
            </ExtendedBox>
          </ExtendedFlex>}
        {documents &&
          documents[0] &&
          documents.map((doc) => {
            return (
              <ExtendedFlex
                flexBasis={newStyle ? null : '100%'}
                p={15}
                textAlign="center"
                my={[5, 5, 30]}
                mx={10}
                key={doc.url}
                bg={newStyle ? '#F7F8F8' : 'gray.1'}
                boxShadow={newStyle ? '0px 12px 15px #0000000D' : null}
                flexDirection={newStyle ? 'column' : null}
              >
                {newStyle &&
                  <Heading
                    type="H8"
                    display="block"
                    textAlign="center"
                    alignSelf="center"
                    my="0"
                    fontSize="13px"
                    letterSpacing="1.56px"
                    color="#202020"
                    fontWeight="500"
                  >
                    PROJECT SUMMARY
                  </Heading>}
                <ExtendedBox textAlign="center">
                  <Image
                    src={newStyle ? Download : downloadIcon}
                    mr={10}
                    height={newStyle ? '14px' : '20px'}
                    position="relative"
                    top="4px"
                    display="inline-block"
                  />
                  <Link
                    href={doc.url}
                    target="_blank"
                    type="L4"
                    fontSize={newStyle ? '11px' : '12px'}
                    lineHeight="26px"
                    letterSpacing={newStyle ? '0' : '0.15em'}
                    data-button-id="download-details"
                  >
                    {newStyle ? 'DOWNLOAD DETAILS PDF' : doc.title}
                  </Link>
                </ExtendedBox>
              </ExtendedFlex>
            );
          })}
        <ExtendedFlex
          flexBasis={newStyle ? null : '100%'}
          p={15}
          textAlign="center"
          my={[5, 5, 30]}
          mx={10}
          bg={newStyle ? '#F7F8F8' : 'gray.1'}
          boxShadow={newStyle ? '0px 12px 15px #0000000D' : null}
          flexDirection={newStyle ? 'column' : null}
        >
          {newStyle &&
            <Heading
              type="H8"
              display="block"
              textAlign="center"
              alignSelf="center"
              my="0"
              fontSize="13px"
              letterSpacing="1.56px"
              color="#202020"
              fontWeight="500"
            >
              CONTACT US
            </Heading>}
          <ExtendedBox textAlign="center">
            <Image
              src={newStyle ? Phone : downloadIcon}
              mr={10}
              height={newStyle ? '14px' : '20px'}
              position="relative"
              top="4px"
              display="inline-block"
            />
            <StyledButton
              type="L4"
              lineHeight="26px"
              fontSize={newStyle ? '11px' : '12px'}
              letterSpacing={newStyle ? '0' : '0.15em'}
              onClick={this.showZendesk}
              data-button-id="contact-investor-relations"
            >
              Contact Investor Relations
            </StyledButton>
          </ExtendedBox>
        </ExtendedFlex>
      </ExtendedFlex>
    );
  }
}

QuickLinks.propTypes = {
  documents: PropTypes.array,
  webinar: PropTypes.array,
  showKeyLine: PropTypes.bool,
  newStyle: PropTypes.bool,
  io: PropTypes.object,
};

export default QuickLinks;
