/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Image,
  FlatSelect,
  Body,
  ExtendedFlex,
} from '../../coreui';

import downArrow from '../assets/down_blk.svg';

const InvestmentsFilterDropdown = ({
  options,
  investmentFilter,
  handleChange,
  ...additionalProps
}) => {
  const filteredOptions = options.filter(
    (item) => !investmentFilter.includes(item),
  );
  return (
    <>
      <FlatSelect
        options={
          filteredOptions.map((item) => ({
            value: item,
            label: <Body type="B5">{item}</Body>,
          }))
        }
        arrowRenderer={() => (
          <ExtendedFlex
            justify="flex-end"
          >
            <Image src={downArrow} w={14} height={14} />
          </ExtendedFlex>
        )}
        placeholder={<Body type="B5">Filter By Investment</Body>}
        searchable={false}
        onChange={(e) => { handleChange(e.value); }}
        noResultsText={<Body type="B5">No results found.</Body>}
        {...additionalProps}
      />
    </>
  );
};

InvestmentsFilterDropdown.propTypes = {
  options: PropTypes.array,
  investmentFilter: PropTypes.array,
  handleChange: PropTypes.func,
};

export default InvestmentsFilterDropdown;
