/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Auth, JS } from 'aws-amplify';

import LoginHandler from './LoginHandler';
import Loading from './Loading';
import LogoutHandler from './LogoutHandler';
import FourOhThree from '../../components/FourOhThree';

class AuthHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      is403,
      isLoggedIn,
      authData: user,
      authState,
      onStateChange,
      location: {
        pathname,
      },
    } = this.props;

    const {
      location: {
        pathname: oldPathname,
      },
    } = prevProps;

    if (!is403 && pathname !== oldPathname) {
      switch (pathname) {
        default:
        case '/user':
        case '/user/login':
          onStateChange('signIn', {});
          break;

        case '/user/register':
          onStateChange('signUp', {});
          break;

        case '/user/password':
          onStateChange('forgotPassword', {});
          break;
      }
    }

    if (prevProps.authState !== authState && authState === 'signedIn') {
      Auth.verifiedContact(user)
        .then((data) => {
          const verified = !JS.isEmpty(data.verified);
          if (!verified) {
            // If the user isn't verified, we want them to confirm their sign
            // up. However, if they left and came back, we want them to sign
            // in again. This is to prevent leaking their email on screen if
            // they leave the browser open.
            onStateChange('confirmSignUp', user.username);
          } else {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
              isLoggedIn: is403 ? isLoggedIn !== true : true,
            });
          }
        });
    }
  }

  render() {
    const {
      authError,
      is403,
      session,
      authState,
      closeOverlayFunc = () => {},
      location: {
        pathname,
      },
    } = this.props;

    if (pathname === '/user/logout') {
      return <LogoutHandler {...this.props} />;
    }

    if (authError) {
      return <FourOhThree />;
    }

    if (is403 && session) {
      return <FourOhThree isLoggedIn />;
    }

    if (this.state.isLoggedIn) {
      return (
        <>
          <Loading {...this.props} />
          <LoginHandler {...this.props} closeOverlayFunc={closeOverlayFunc} />
        </>
      );
    }

    switch (authState) {
      case 'signedIn':
      case 'loading':
        return <Loading {...this.props} />;

      default:
        return null;
    }
  }
}

AuthHandler.propTypes = {
  authState: PropTypes.string,
  authData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  onStateChange: PropTypes.func,
  location: PropTypes.object,
  is403: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  session: PropTypes.bool,
  authError: PropTypes.object,
  closeOverlayFunc: PropTypes.func,
};

AuthHandler.defaultProps = {
  onStateChange: () => undefined,
};

export default withRouter(AuthHandler);
