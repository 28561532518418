/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import ReactSwitch from 'react-switch';

import {
  ExtendedFlex,
  Data,
} from '..';

import {
  types,
} from './Data';

import Theme from '../Theme';

const SwitchLabel = ({
  children,
  ...additionalProps
}) => {
  return (
    <ExtendedFlex
      justifyContent="center"
      align="center"
      height="100%"
      {...additionalProps}
    >
      <Data
        type="D2"
        color="white"
        fontSize={types.D2.fontSize.slice(-1)}
        m={0}
        lineHeight="1em"
      >
        {children}
      </Data>
    </ExtendedFlex>
  );
};

SwitchLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
};

const Switch = ({
  label,
  onChange,
  checked,
  on,
  off,
  ...additionalProps
}) => {
  return (
    <ReactSwitch
      onChange={onChange}
      checked={checked}
      aria-label={label}
      handleDiameter={20}
      height={30}
      width={75}
      checkedIcon={
        on &&
        <SwitchLabel
          ml={3}
        >
          {on}
        </SwitchLabel>
      }
      uncheckedIcon={
        off &&
        <SwitchLabel>
          {off}
        </SwitchLabel>
      }
      onColor={Theme.colors.blue[0]}
      offColor={Theme.colors.gray[10]}
      onHandleColor={Theme.colors.white}
      offHandleColor={Theme.colors.white}
      {...additionalProps}
    />
  );
};

Switch.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  on: PropTypes.string,
  off: PropTypes.string,
};

Switch.defaultProps = {
  onChange: (checked) => { return checked; },
  checked: true,
};

export default Switch;
export { SwitchLabel };
