import get from 'lodash/get';
import types from '../../types';

export default (state, action) => {
  switch (action.type) {
    case types.WATCHLIST_TOGGLE_SUCCEEDED:
      return {
        isFlagged: (action.data.flagStatus === 'flagged' && action.data.flagSuccess),
      };
    case types.WATCHLIST_TOGGLED:
      return {
        isFlagged: action.opts.isFlagged,
      };
    case types.GET_IO_DATA_SUCCEEDED:
      return {
        isFlagged: get(action, 'data.data[0].watchList.flagged'),
      };
    default:
      return {
        ...state,
      };
  }
};
