import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
} from '../../../coreui';

import FadeIn from '../../../components/FadeIn';

const InvestmentPlatformSection2 = ({
  isVisible,
  variant,
}) => {
  const [strings, setStrings] = useState({
    heading1: 'Access & transparency',
    subHeading1: 'Thoughtful, transparent information enables you to make personal decisions in pursuit of your financial goals.',
    heading2: 'Get the help you need',
    subHeading2: 'Reach out and connect with a dedicated Investor Relations team member anytime you have questions about the Platform.',
  });

  useEffect(() => {
    if (variant !== 'none') {
      setStrings((oldStrings) => {
        return {
          ...oldStrings,
          heading1: 'Access & transparency',
          subHeading1: 'Thoughtful, transparent information enables you to make personal decisions in pursuit of your financial goals.',
          heading2: 'Get the help you need',
          subHeading2: 'Reach out and connect with a dedicated Investor Relations team member anytime you have questions about the Platform.',
        };
      });
    }
  }, [setStrings, variant]);

  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
    >
      <ExtendedFlex
        flexDirection="column"
      >
        <ExtendedBox
          m={0}
          pb={30}
          bg="gray.1"
        >
          <ExtendedFlex
            flexDirection="column"
            justify="center"
            maxWidth={[null, null, 520, 520, 660]}
            height={[300, 300, 400, 400, 500]}
            px={['15%', '25%', 60, 100, 120]}
          >
            <FadeIn>
              <Heading
                type="H3"
                textTransform="uppercase"
                m={0}
                mb="0.7em"
                opacity={isVisible ? '1' : '0'}
              >
                {strings.heading1}
              </Heading>
              <Body
                type="B1"
                m={0}
                mb="4em"
                opacity={isVisible ? '1' : '0'}
              >
                {strings.subHeading1}
              </Body>
            </FadeIn>
          </ExtendedFlex>
        </ExtendedBox>
        <ExtendedBox
          mb={30}
        >
          <ExtendedFlex
            flexDirection="column"
            justify="center"
            maxWidth={[null, null, 520, 520, 660]}
            height={[300, 300, 400, 400, 500]}
            px={['15%', '25%', 60, 100, 120]}
          >
            <FadeIn>
              <Heading
                type="H3"
                textTransform="uppercase"
                m={0}
                mb="0.7em"
                opacity={isVisible ? '1' : '0'}
              >
                {strings.heading2}
              </Heading>
              <Body
                type="B1"
                m={0}
                mb="4em"
                opacity={isVisible ? '1' : '0'}
              >
                {strings.subHeading2}
              </Body>
            </FadeIn>
          </ExtendedFlex>
        </ExtendedBox>
      </ExtendedFlex>
    </Box>
  );
};

InvestmentPlatformSection2.propTypes = {
  isVisible: PropTypes.bool,
  variant: PropTypes.string,
};

export default InvestmentPlatformSection2;
