import homepageTypes from '../homepage/types';
import data from './data';
import getTooltips from './helpers';

const getList = (data) => Object.keys(data).reduce((accum, key) => {
  return [
    ...accum,
    data[key],
  ];
}, []);

const replaceTooltip = (data, tokens) => {
  const tooltips = getTooltips(tokens);
  return {
    ...data,
    mr1: {
      ...data.mr1,
      distributionTooltip: tooltips.mr1.distributionTooltip,
      lastDistributionDeclared: tooltips.mr1.lastDistributionDeclared,
    },
    mr2: {
      ...data.mr2,
      distributionTooltip: tooltips.mr2.distributionTooltip,
      lastDistributionDeclared: tooltips.mr2.lastDistributionDeclared,
    },
  };
};

const initialState = {
  data: {
    ...data,
  },
  list: getList(data),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case homepageTypes.GET_TOKENS_SUCCEEDED: {
      const data = replaceTooltip(state.data, action.tokens);
      return {
        ...state,
        data,
        list: getList(data),
      };
    }

    default:
      return {
        ...state,
      };
  }
};
