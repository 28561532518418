import styled from 'styled-components';
import {
  fontSize,
  width,
  space,
  color,
  textAlign,
  lineHeight,
  fontWeight,
  letterSpacing,
  maxWidth,
  minHeight,
  maxHeight,
  zIndex,
  display,
  flex,
  flexDirection,
  flexWrap,
  flexBasis,
  alignItems,
  justifyContent,
  fontFamily,
} from 'styled-system';
import Theme from '../Theme';

export const applyTextStyles = (props) => ({
  ...props,
  ...fontSize(props),
  ...fontWeight(props),
  ...letterSpacing(props),
  ...lineHeight(props),
  ...fontFamily(props),
});

const Text = styled.p`
  ${fontSize}
  ${width}
  ${maxWidth}
  ${space}
  ${color}
  ${textAlign}
  ${lineHeight}
  ${fontWeight}
  ${letterSpacing}
  ${minHeight}
  ${maxHeight}
  ${zIndex}
  ${flex}
  ${flexDirection}
  ${flexWrap}
  ${flexBasis}
  ${alignItems}
  ${justifyContent}
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ${(props) => Theme.fontFamily[props.fontFamily] || 'Helvetica, Arial, sans-serif'};
  text-indent: ${(props) => props.textIndent || '0'};
  text-transform: ${(props) => (props.textTransform || 'none')};
  font-style: ${(props) => props.fontStyle || 'normal'};
  white-space: ${(props) => props.whiteSpace || 'normal'};
  text-shadow: ${(props) => props.textShadow || 'inherit'};
  display: ${(props) => props.display || 'inline'};
  ${(props) => (props.display ? display(props) : { display: 'inline' })};
  opacity: ${(props) => props.opacity || '1'};
  box-sizing: border-box;
  transition: ${(props) => props.transition || 'none'};
  visibility: ${(props) => props.visibility || 'visible'};
`;

export default Text;
