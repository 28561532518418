import { fetchInvestmentMetricFromData } from './helper';

export function getPrivatePlacementSummaryFromJson(jsonData) {
  return {
    title: 'Private Placements',
    items: {
      totalInvested: fetchInvestmentMetricFromData(jsonData, 'totalInvested', 'Total Invested', 'currency', 'medium', {}),
      totalDistributions: fetchInvestmentMetricFromData(jsonData, 'totalDistributions', 'Total Distributions', 'currency', 'medium', {}),
      portfolioAllocation: fetchInvestmentMetricFromData(jsonData, 'portfolioAllocation', '% of Portfolio', 'percentage', 'medium', {}),
    },
  };
}

export const privatePlacementParseFloatData = [
  'totalInvestmentAmount',
  'amountDealDaysOutstanding',
];

export function parsePrivatePlacementDataFromJson(jsonData) {
  const ppData = jsonData.privatePlacementData;
  return {
    summary: getPrivatePlacementSummaryFromJson(ppData.summary),
    ios: ppData.ios.reduce((accum, io) => ([
      ...accum,
      {
        ...io,
        ...(privatePlacementParseFloatData.reduce((ioData, dataPoint) => ({
          ...ioData,
          ...(dataPoint in io ? {
            [dataPoint]: parseFloat(io[dataPoint], 10),
          } : {}),
        }), {})),
      },
    ]), []),
  };
}

export default {
  parsePrivatePlacementDataFromJson,
};
