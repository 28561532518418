/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  themeGet,
  color,
  fontSize,
  fontStyle,
  fontWeight,
  letterSpacing,
  lineHeight,
  textTransform,
  borders,
  borderColor,
} from 'styled-system';

import {
  TextInput,
  bodyTypes,
  dataTypes,
  applyTextStyles,
} from '../../coreui';

/* eslint-disable indent */
const StyledInput = styled(TextInput)`
  text-indent: ${(props) => (props.isSmall ? 0 : '12px')};
  ${color}
  ${borders}
  ${borderColor}
  ${fontSize}
  ${fontStyle}
  ${fontWeight}
  ${letterSpacing}
  ${lineHeight}
  ${textTransform}
  box-sizing: ${(props) => props.boxSizing || 'content-box'};
  border-radius: 0;

  &:focus {
    border-color: ${themeGet('colors.blue.1')};
    outline-width: 0px;
  }

  &:disabled {
    color: ${(props) => props.disabledColor || themeGet('colors.gray.10')};
  }

  &::placeholder {
    color: ${themeGet('colors.gray.10')};
  }

  &.hf-validated:invalid {
    border-color: ${themeGet('colors.red')};
  }

  + .hf-warning {
    ${({ theme }) => applyTextStyles({
      theme,
      ...dataTypes.D4,
    })}
    color: ${themeGet('colors.red')};
    margin-top: 5px;
    margin-left: 12px;
  }
`;

const FloatLabel = styled.div`
  position: absolute;
  top: 15px;
  left: 5px;
  cursor: pointer;
  font: normal normal 400 15px/20px var(--font-sans);
  letter-spacing: 0px;
  color: #9B9B9B;
  display: inline-block;
  white-space: initial;
  transition: 0.3s;
`;

const InputField = styled.input`
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 15px 0 0 5px;
  -webkit-appearance: none;
  outline: 0;
  border: none;
  border-bottom: 1px solid ${(props) => (props.hasError ? '#fb0d1b' : '#A4C2D5')};
  font: normal normal 400 15px/20px var(--font-sans);
  letter-spacing: 0px;
  color: #202020;
  &:not(:-ms-input-placeholder) ~ ${FloatLabel} {
    top: 0;
    font: normal normal 400 10px/27px var(--font-sans);
    letter-spacing: 0px;
    color: #9B9B9B;
  }
  &:not(:placeholder-shown) ~ ${FloatLabel} {
    top: 0;
    font: normal normal 400 10px/27px var(--font-sans);
    letter-spacing: 0px;
    color: #9B9B9B;
  }
  &:focus ~ ${FloatLabel} {
    top: 0;
    font: normal normal 400 10px/27px var(--font-sans);
    letter-spacing: 0px;
    color: #9B9B9B;
  }
`;

const InputFieldWrapper = styled.label`
  display: block;
  position: relative;
  cursor: text;
  .hf-warning {
    margin-top: 8px;
    font: normal normal 500 11px/15px var(--font-sans);
    letter-spacing: 0px;
    color: #EE0000;
    padding-left: 5px;
  }
  &:hover ${FloatLabel} {
    color: #606060;
  }
  &:hover ${InputField} {
    border-bottom: 1px solid #1D89FF;
  }
  &:focus ${InputField} {
    border-bottom: 1px solid #176DB6;
  }
`;

// const Error = styled.span`
//   color: #fb0d1b;
//   font-size: 13px;
//   display: block;
//   font-family: 'nobel-regular';
//   padding: 0 0 0 20px;
// `;
/* eslint-enable indent */

const Input = ({
  required,
  forwardedRef,
  label,
  isNew = false,
  ...props
}) => {
  return (
    !isNew ?
      <StyledInput
        {...bodyTypes.B1}
        {...(forwardedRef ? {
          innerRef: forwardedRef,
        } : {})}
        color="gray.8"
        borderTop={0}
        borderLeft={0}
        borderRight={0}
        borderBottom={1}
        borderColor="gray.10"
        w={1}
        focusColor="blue.1"
        pt={7}
        pb={5}
        px={0}
        display="block"
        {...(required ? {
          required: 'required',
        } : {})}
        {...props}
      />
      :
      <InputFieldWrapper>
        <InputField
          {...(forwardedRef ? {
            innerRef: forwardedRef,
          } : {})}
          {...(required ? {
            required: 'required',
          } : {})}
          {...props}
        />
        <FloatLabel>
          {label}{required && <span style={{ color: '#B1392B' }}> *</span>}
        </FloatLabel>
      </InputFieldWrapper>
  );
};

Input.propTypes = {
  required: PropTypes.bool,
  isSmall: PropTypes.bool,
  forwardedRef: PropTypes.func,
  label: PropTypes.string,
  isNew: PropTypes.bool,
};

export default Input;
