const getTooltips = (tokens) => ({
  mr1: {
    distributionTooltip: tokens.mogulReit1DistributionTooltip,
    lastDistributionDeclared: tokens.mogulReit1LastDistributionDeclared,
  },
  mr2: {
    distributionTooltip: tokens.mogulReit2DistributionTooltip,
    lastDistributionDeclared: tokens.mogulReit2LastDistributionDeclared,
  },
});

export default getTooltips;
