/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  ScrollToTopOnMount,
  ExtendedFlex,
  ExtendedBox,
  Hero,
  Link,
} from '../coreui';

import heroJVEquity from '../assets/Hero_JVEquity.jpg';
import ImageAndCopy from '../financing/components/imageAndCopy';
import StaticContentData from './data';
import PanelJVEquity from '../assets/panel_JVEquity.jpg';
import Section from '../ioDetails/components/section';
import Overview from '../investmentOptions/components/overview';
import HowItWorks from './components/HowItWorks';

export const JVEquity = () => {
  return (
    <ExtendedBox>
      <ScrollToTopOnMount />
      <ExtendedFlex>
        <Hero
          image={heroJVEquity}
          header="Raise capital for your next real estate project"
          subHeader="FROM START TO CLOSE. HOW IT WORKS."
          bgPosition="74%"
        />
      </ExtendedFlex>
      <Section
        title={StaticContentData.overview.title}
      >
        <Overview
          title={null}
          subtitle={StaticContentData.overview.subtitle}
        />
        <ExtendedFlex>
          <Link
            type="L1"
            href="/sponsor-application"
            mx="auto"
          >
            Apply Here
          </Link>
        </ExtendedFlex>
      </Section>
      <Section
        title="How it Works"
        bg="gray.0"
      >
        <HowItWorks />
      </Section>
      <ImageAndCopy
        {...StaticContentData.dedicated}
        image={PanelJVEquity}
        isButton
      />
    </ExtendedBox>
  );
};
export default JVEquity;
