import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import {
  getDefaultNetWorthSpecificValue,
  sliderMapping,
} from '../../../../components/isq/utility';
import SliderMoney from '../../../../components/SliderMoney';

const NetWorthSpecific = (props) => {
  const {
    name,
    value,
    sliderMapping: {
      [props.netWorth]: {
        min = null,
        max = null,
        step = 1000,
      } = {},
    },
    onChange,
  } = props;

  return (
    <SliderMoney
      name={name}
      key={`slider-money-${min}-${max}`}
      min={min}
      max={max}
      step={step}
      value={
        Number.parseInt(
          value || getDefaultNetWorthSpecificValue(props),
          10,
        ) || undefined
      }
      handleChange={onChange}
    />
  );
};

NetWorthSpecific.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.number,
  netWorth: PropTypes.number,
  sliderMapping: PropTypes.object,
};

NetWorthSpecific.defaultProps = {
  name: 'netWorthSpecific',
  onChange: noop,
  sliderMapping,
};

export default NetWorthSpecific;
