import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CarouselLarge,
} from '../../coreui';

import createSlideCollection from './createSlideCollection';
import createContentSlide from './createContentSlide';

class EarnPassiveIncome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };

    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible,
      });
    }
  }

  render() {
    const { isVisible } = this.state;
    const { toggleModal, passiveIncomeNum, variant, tokens } = this.props;

    const slideCollection = createSlideCollection(passiveIncomeNum, variant, tokens);

    return (
      <Box
        bg="gray.1"
        py={[100, 100, 100, 160, 220]}
      >
        <CarouselLarge>
          {
            slideCollection.map(
              (item) => {
                return createContentSlide(
                  item,
                  isVisible,
                  this.handleSensorChange,
                  toggleModal,
                );
              },
            )
          }
        </CarouselLarge>
      </Box>
    );
  }
}

EarnPassiveIncome.propTypes = {
  toggleModal: PropTypes.func,
  passiveIncomeNum: PropTypes.number,
  variant: PropTypes.string,
  tokens: PropTypes.object,
};

export default EarnPassiveIncome;
