import React from 'react';
import PropTypes from 'prop-types';
import Manage from './Manage';
import { Heading } from '../../../coreui';
import Welcome from './Welcome';

const Landing = ({
  hasAutoInvest,
  reit1,
  reit2,
  ownershipOptions,
  handleEnrollment,
  handleCancel,
  handleActiveToggle,
}) => {
  return (
    <>
      {
        !hasAutoInvest &&
        <Welcome
          reit1={reit1 || null}
          reit2={reit2 || null}
          ownershipOptions={ownershipOptions}
        />
      }
      {
        hasAutoInvest &&
        <Heading
          type="H2"
          mx={[50, null, 0]}
          my={50}
        >
          Manage your auto investments
        </Heading>
      }
      <Manage
        ownershipOptions={ownershipOptions}
        handleEnrollment={handleEnrollment}
        handleCancel={handleCancel}
        handleActiveToggle={handleActiveToggle}
      />

    </>
  );
};

Landing.propTypes = {
  hasAutoInvest: PropTypes.bool,
  reit1: PropTypes.object,
  reit2: PropTypes.object,
  ownershipOptions: PropTypes.array,
  handleEnrollment: PropTypes.func,
  handleCancel: PropTypes.func,
  handleActiveToggle: PropTypes.func,
};

export default Landing;
