/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  Link,
} from '../../..';

const InvestmentCardViewDetails = ({
  pathAlias,
  pathProps,
  linkText,
  linkType,
  hideLink = false,
}) => {
  return hideLink ? null : (
    <ExtendedBox
      pt={20}
      px={10}
      position="absolute"
      bottom="20px"
      left="50%"
      transform="translateX(-50%)"
    >
      <Link
        type="L1"
        withActiveStyle={false}
        {...(linkType === 'to' ? { to: pathAlias } : { href: pathAlias })}
        {...pathProps}
      >
        {linkText}
      </Link>
    </ExtendedBox>
  );
};

InvestmentCardViewDetails.propTypes = {
  pathAlias: PropTypes.string,
  pathProps: PropTypes.object,
  linkText: PropTypes.string,
  linkType: PropTypes.string,
  hideLink: PropTypes.bool,
};

InvestmentCardViewDetails.defaultProps = {
  pathAlias: '',
  linkText: 'View Details',
};

export default InvestmentCardViewDetails;
