/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Heading,
  Data,
  Body,
  Theme,
} from '..';

// Note: Wondering about the `maxWidth` and `width` stuff?
// Those are there to protect against IE11 breaking with lines extending beyond 100% width.
// Forces IE to recognize the size it's in and conform accordingly.
const MiniEssay = ({
  header,
  subHeader,
  paragraphsOfText,
  animatedFadeIn,
  isVisible,
  ...additionalProps
}) => {
  // We default MiniEssay to always being visibile unless animation flag thrown
  let visibilityStatus = true;
  if (animatedFadeIn) {
    visibilityStatus = isVisible; // if thrown, we use isVisible prop
  }

  // Creates a body paragraph. Intended to be used in .map over array of text strings.
  // Note: first paragraph has no top margin. Subsequent pars spaced via top margin.
  const createBodyParagraph = (text, parIndex) => {
    const transitionDelay = (0.3 * parIndex) + 0.3;
    const transitionProp = `opacity ${Theme.fadeInDelay}ms ${transitionDelay}s linear`;
    return (
      <Body
        key={parIndex}
        type="B1"
        display="block"
        textAlign="center"
        maxWidth={['75%', 540, 600, 840, 1160]}
        mt={parIndex === 0 ? 0 : [15, 20, 20, 20, 30]}
        opacity={visibilityStatus ? '1' : '0'}
        transition={transitionProp}
      >
        {text}
      </Body>
    );
  };

  return (
    <Flex
      flexDirection="column"
      align="center"
      justify="center"
      width={1}
      {...additionalProps}
    >
      {
        header &&
        <Heading
          type="H3"
          display="block"
          textAlign="center"
          maxWidth={['75%', 540, 600, 840, 1160]}
          m={0}
          mb={subHeader ? '0.5em' : [30, 40, 40, 40, 50]}
          opacity={visibilityStatus ? '1' : '0'}
          transition={`opacity ${Theme.fadeInDelay}ms 0s linear`}
        >
          {header}
        </Heading>
      }
      {
        subHeader &&
        <Data
          type="D1"
          display="block"
          textAlign="center"
          maxWidth={['75%', 540, 600, 840, 1160]}
          m={0}
          mb={[30, 40, 40, 50, 60]}
          opacity={visibilityStatus ? '1' : '0'}
          transition={`opacity ${Theme.fadeInDelay}ms 0s linear`}
        >
          {subHeader}
        </Data>
      }
      {paragraphsOfText.map(createBodyParagraph)}
    </Flex>
  );
};

MiniEssay.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  paragraphsOfText: PropTypes.array,
  animatedFadeIn: PropTypes.bool,
  isVisible: PropTypes.bool,
};

MiniEssay.defaultProps = {
  paragraphsOfText: [],
};

export default MiniEssay;
