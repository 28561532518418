/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
} from '../../coreui';

import InvestmentPlatformSection1 from './components/InvestmentPlatformSection1';
import InvestmentPlatformSection2 from './components/InvestmentPlatformSection2';

class InvestmentPlatform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };

    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible,
      });
    }
  }

  render() {
    const { isLoggedIn, variant } = this.props;

    return (
      <ExtendedFlex
        flexDirection={['column', 'column', 'row', 'row', 'row']}
      >
        <InvestmentPlatformSection1
          handleSensorChange={this.handleSensorChange}
          isLoggedIn={isLoggedIn}
          variant={variant}
          {...this.state}
        />
        <InvestmentPlatformSection2
          {...this.state}
          variant={variant}
        />
      </ExtendedFlex>
    );
  }
}

InvestmentPlatform.propTypes = {
  isLoggedIn: PropTypes.bool,
  variant: PropTypes.string,
};

export default InvestmentPlatform;
