import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
  Image,
} from '../../coreui';

import check from '../../assets/icon_bullet_check.svg';

// eslint-disable-next-line
class InvestmentHighlights extends React.Component {
  render() {
    const {
      highlights,
    } = this.props;
    return (
      <ExtendedBox
        m="auto"
      >
        <Heading
          type="H8"
          display="block"
          textAlign="center"
          alignSelf="center"
          my={[40, 40, 60, 100, 120]}
        >
          Investment Highlights
        </Heading>
        <ExtendedFlex
          flexDirection="column"
          flexWrap="wrap"
          mx={40}
        >
          {
            highlights &&
            highlights.map((highlight, index) => {
              return (
                <ExtendedFlex
                  // eslint-disable-next-line
                  key={`investment_highlight_${index}`}
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Image
                    display="inline-block"
                    src={check}
                    mr={20}
                    mt={10}
                    w={15}
                    height={12}
                    position="relative"
                  />
                  <Body
                    mt={0}
                    type="B1"
                  >
                    {highlight}
                  </Body>
                </ExtendedFlex>

              );
            })
          }
        </ExtendedFlex>
      </ExtendedBox>
    );
  }
}

InvestmentHighlights.propTypes = {
  highlights: PropTypes.arrayOf(
    PropTypes.string,
  ),
};

export default InvestmentHighlights;
