import types from './types';

export default {
  revalidateEmailRequested: () => ({
    type: types.REVALIDATE_EMAIL_REQUESTED,
  }),
  revalidateEmailSucceeded: (data) => ({
    type: types.REVALIDATE_EMAIL_SUCCEEDED,
    data,
  }),
  revalidateEmailFailed: (error) => ({
    type: types.REVALIDATE_EMAIL_FAILED,
    error,
  }),
};
