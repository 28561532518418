import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { SignIn } from 'aws-amplify-react';
import { Auth, JS } from 'aws-amplify';
import Styled from './Auth.styled';

import {
  Heading,
  Body,
  Link,
  InlineTextLink,
} from '../../coreui';
import Container from './Container';
import FirstContainer from './FirstContainer';
import SecondContainer from './SecondContainer';

import Form from '../../components/forms/Form';
import Label from '../../components/forms/Label';
import Input from '../../components/forms/Input';
import PasswordInput from '../../components/forms/PasswordInput';
import Questions from './Questions';
import Actions from '../../components/forms/Actions';
import PrimaryButton from '../../components/forms/PrimaryButton';
import trackError from './trackError';

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      submitting: false,
    };
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp', 'forgotPasswordSuccess'];

    this.updateSubmittingStatus = this.updateSubmittingStatus.bind(this);
    this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Check if the authState changed.
    if (prevProps.authState !== this.props.authState) {
      if (this.state.submitting) {
        this.updateSubmittingStatus(false);
      }
    }
  }

  checkContact(user) {
    if (!Auth || typeof Auth.verifiedContact !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    Auth.verifiedContact(user)
      .then((data) => {
        if (!JS.isEmpty(data.verified)) {
          this.changeState('signedIn', user);
        } else {
          this.changeState('confirmSignUp', user);
        }
      });
  }

  updateSubmittingStatus(submitting) {
    this.setState({
      submitting,
    });
  }

  error(err) {
    if (this.state.submitting) {
      this.updateSubmittingStatus(false);
    }
    trackError('SignIn Error', { error: err });
    super.error(err);
  }

  handleForgotPasswordClick(e) {
    e.preventDefault();
    super.changeState('forgotPassword');
  }

  handleFormSubmit(e) {
    e.preventDefault();
    if (!this.state.submitting) {
      this.updateSubmittingStatus(true);
      super.signIn(e);
    }
  }

  showComponent() {
    return (
      this.props.showNew ? (
        <Styled.Container>
          <div className="inner-container">
            <div style={{ marginBottom: '15px' }}>
              Please enter your email and password below.
            </div>
            <Form onSubmit={this.handleFormSubmit} >
              <Input
                id="email"
                isNew
                name="username"
                onChange={(e) => {
                  e.target.value = (e.target.value || '').toLowerCase();
                  this.handleInputChange(e);
                }}
                type="email"
                label="E-mail"
                placeholder=" "
                autoComplete="email"
                required
                data-test="email"
              />
              <div style={{ width: '100%', height: '20px' }} />
              <Input
                id="password"
                isNew
                label="Password"
                name="password"
                type="password"
                onChange={this.handleInputChange}
                autoComplete="current-password"
                required
                placeholder=" "
                data-test="password"
              />
              <button
                type="button"
                onClick={() => { this.props.history.push('/user/password'); }}
                style={{ border: 'none', background: 'none', color: '#A4C2D5', fontSize: '10px', letterSpacing: '2.4px', marginTop: '15px' }}
              >
                FORGOT PASSWORD?
              </button>
              <PrimaryButton
                buttonType="submit"
                w={1}
                maxWidth={1}
                loading={this.state.submitting}
                disable={this.state.submitting}
                data-test="sign-in-submit"
              >
                SIGN IN TO REALTYMOGUL
              </PrimaryButton>
            </Form>
          </div>
          <Styled.Questions>
            <span className="questions-copy">QUESTIONS? Our Investor Relations team is available 8AM-6PM PST M-F.</span>
            <a className="questions-link" href="tel=+18779772776">(877) 977-2776</a>
          </Styled.Questions>
        </Styled.Container>
      )
        : (
          <Container>
            <FirstContainer>
              <Heading
                type="H2"
                m={0}
              >
                Sign In.
              </Heading>
              <Body type="B2">
                Don&apos;t have an account yet?<br /><InlineTextLink display="inline" to="/user/register">Register here</InlineTextLink>
              </Body>
              <Body
                type="B1"
              >
                Please enter your email and password below.
              </Body>
              <Form onSubmit={this.handleFormSubmit} >
                <Label
                  htmlFor="email"
                >
                  E-mail
                  <Input
                    id="email"
                    name="username"
                    onChange={(e) => {
                      e.target.value = (e.target.value || '').toLowerCase();
                      this.handleInputChange(e);
                    }}
                    type="email"
                    placeholder="E-mail"
                    autoComplete="email"
                    required
                    data-test="email"
                  />
                </Label>
                <Label
                  htmlFor="password"
                >
                  Password
                  <PasswordInput
                    id="password"
                    name="password"
                    onChange={this.handleInputChange}
                    autoComplete="current-password"
                    required
                    data-test="password"
                  />
                </Label>
                <Actions>
                  <PrimaryButton
                    buttonType="submit"
                    loading={this.state.submitting}
                    disable={this.state.submitting}
                    data-test="sign-in-submit"
                  >
                    Sign In
                  </PrimaryButton>
                  <Link
                    type="L4"
                    to="/user/password"
                    onClick={this.handleForgotPasswordClick}
                  >
                    Forgot password?
                  </Link>
                </Actions>
              </Form>
              <Questions />
            </FirstContainer>
            <SecondContainer />
          </Container>
        )
    );
  }
}

CustomSignIn.propTypes = {
  ...CustomSignIn.propTypes,
  location: PropTypes.object,
  is403: PropTypes.bool,
};

export default withRouter(CustomSignIn);
