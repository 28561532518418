import React from 'react';

const Messages = () => {
  /* eslint-disable */
  return (
    <g stroke="none" transform="translate(10.000000, 8.000000)" fillRule="nonzero" strokeWidth="0.7">
      <path d="M8.05,32 L32.9,32 C37.4,32 41,28.4553846 41,24.0738462 L41,7.92615385 C41,3.54461538 37.4,0 32.95,0 L8.05,0 C3.6,0 0,3.54461538 0,7.92615385 L0,24.0246154 C0,28.4553846 3.6,32 8.05,32 Z M32.95,30.0307692 L8.05,30.0307692 C5.4,30.0307692 3.15,28.3569231 2.35,26.0430769 L13.85,15.7538462 L14.55,16.3938462 C16.3,17.92 18.4,18.7076923 20.5,18.7076923 C22.6,18.7076923 24.7,17.9692308 26.4,16.4430769 L27.1,15.8030769 L38.6,26.0923077 C37.8,28.3569231 35.55,30.0307692 32.95,30.0307692 Z M2.5,5.56307692 L12.4,14.4246154 L2,23.68 L2,7.92615385 C2,7.08923077 2.2,6.30153846 2.5,5.56307692 Z M39,7.92615385 L39,23.68 L28.65,14.4246154 L38.5,5.61230769 C38.85,6.30153846 39,7.13846154 39,7.92615385 Z M8.05,1.96923077 L32.9,1.96923077 C34.7,1.96923077 36.25,2.70769231 37.4,3.93846154 L25.1,14.9169231 C22.45,17.28 18.6,17.28 15.95,14.9169231 L3.6,3.93846154 C4.7,2.70769231 6.3,1.96923077 8.05,1.96923077 Z" id="Shape"></path>
    </g>
  );
  /* eslint-enable */
};

export default Messages;
