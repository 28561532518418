import React from 'react';
import { InlineTextLink } from '../coreui';

export const parseMogulREITLinks = (text) => {
  const mrLinks = {
    'MogulREIT I': <InlineTextLink to="/investment-opportunity/193744">MogulREIT I</InlineTextLink>,
    'MogulREIT II': <InlineTextLink to="/investment-opportunity/274949">MogulREIT II</InlineTextLink>,
  };
  let splitBody1 = text.replace(/MogulREIT I{1,2}/gi, '<<<<<$&<<<<<');
  splitBody1 = splitBody1.split('<<<<<');
  let newBody = text;
  const replaceMR = function (e, i) {
    if (typeof mrLinks[e] !== 'undefined') {
      splitBody1[i] = mrLinks[e];
    }
  };
  if (splitBody1.length > 1) {
    splitBody1.forEach(replaceMR);
    newBody = splitBody1;
  }
  return newBody;
};

export default parseMogulREITLinks;
