import React from 'react';
import PropTypes from 'prop-types';

import { Heading, Body } from '../../coreui';
import CheckboxLabel from '../../components/forms/CheckboxLabel';
import CheckboxGroup from '../../components/forms/CheckboxGroup';

const Interests = ({
  value,
  handleChange,
  isValid,
}) => {
  return (
    <>
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        Preferred Property Types
      </Heading>
      <Body
        type="B2"
      >
        Select the property types that interest you.
      </Body>
      <CheckboxGroup
        name="propertyTypes"
        value={value}
        onChange={handleChange}
        inputProps={{
          'data-test': 'interests',
        }}
        required
        isValid={isValid}
      >
        <CheckboxLabel
          value="interest_residential"
          label="Residential"
        />
        <CheckboxLabel
          value="interest_multifam"
          label="Multi-Family/Apartments"
        />
        <CheckboxLabel
          value="interest_office"
          label="Office"
        />
        <CheckboxLabel
          value="interest_industrial"
          label="Industrial"
        />
        <CheckboxLabel
          value="interest_retail"
          label="retail"
        />
        <CheckboxLabel
          value="interest_hospitality"
          label="Hospitality/Hotels"
        />
        <CheckboxLabel
          value="interest_mob_home"
          label="Mobile Home Parks"
        />
        <CheckboxLabel
          value="interest_storage"
          label="Self-Storage"
        />
        <CheckboxLabel
          value="interest_other"
          label="Other"
        />
      </CheckboxGroup>
    </>
  );
};

Interests.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.oneOf([
    'interest_residential',
    'interest_multifam',
    'interest_office',
    'interest_industrial',
    'interest_retail',
    'interest_hospitality',
    'interest_mob_home',
    'interest_storage',
    'interest_other',
  ])),
  isValid: PropTypes.bool,
};

export default Interests;
