import React from 'react';
import PropTypes from 'prop-types';

import { Heading, Body } from '../../coreui';
import Select from '../../components/forms/Select';

const netWorthOptions = [
  { value: '10m_more', label: '$10 Million +' },
  { value: '5m_10m', label: '$5 Million - $10 Million' },
  { value: '3m_5m', label: '$3 Million - $5 Million' },
  { value: '1m_3m', label: '$1 Million - $3 Million' },
  { value: '200k_1m', label: '$200,000 - $1 Million' },
  { value: '50k_200k', label: '$50,000 - $200,000' },
  { value: '25k_50k', label: '$25,000 - $50,000' },
  { value: '10k_25k', label: '$10,000 - $25,000' },
  { value: 'less_10k', label: 'Less than $10,000' },
];

const NetWorth = ({ value, handleChange }) => {
  return (
    <>
      <Heading
        type="H5"
        textTransform="uppercase"
        mt={40}
        mb={10}
      >
        Net Worth
      </Heading>
      <Body
        type="B2"
        mt={0}
        color="#707070"
      >
        Your current net worth, excluding your primary residence.
      </Body>
      <Select
        name="net_worth"
        value={value}
        onChange={handleChange}
        inputProps={{
          'data-test': 'net_worth',
        }}
        placeholder="Select current net worth"
        required
        options={netWorthOptions}
      />
    </>
  );
};

NetWorth.propTypes = {
  value: PropTypes.number,
  handleChange: PropTypes.func,
};

export default NetWorth;
