/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import pick from 'lodash/pick';

import {
  ScrollToTopOnMount,
  ExtendedFlex,
  Heading,
  Body,
  isDrupalPath,
} from '../coreui';

import CompleteYourProfileQuestions from './components/CompleteYourProfileQuestions';
import DashboardLoader from '../dashboard/components/DashboardLoader';

import actions from './actions';
import { gtkyProps } from './data';

class CompleteProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirecting: false,
    };

    this.isUnmounted = false;
    this.handleAccred = this.handleAccred.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getGettingToKnowYouDraftRequested();
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  handleFormSubmit(data, accredData) {
    const {
      history,
      postGettingToKnowYouRequested,
    } = this.props;

    return (new Promise((resolve, reject) => {
      postGettingToKnowYouRequested(data, {
        resolve,
        reject,
      });
    }))
      .then(({ data: { redirect } }) => {
        if (accredData && redirect) {
          this.handleAccred(accredData).then(() => {
            if (!this.isUnmounted && redirect) {
              if (isDrupalPath(redirect)) {
                window.location = redirect;
              } else {
                history.push(redirect);
              }
              this.setState({
                redirecting: true,
              });
            }
          });
        }
      });
  }

  handleAccred(data) {
    const {
      postAccreditationRequested,
    } = this.props;

    return (new Promise((resolve, reject) => {
      /* eslint-disable camelcase */
      postAccreditationRequested({
        net_worth: data.netWorth,
        income_determination: data.incomeDetermination,
        year_current_income: data.currentIncome,
      }, {
        resolve,
        reject,
      });
    }));
  }

  render() {
    const {
      isLoggedIn,
      isPreAccred,
      isAccredViaNetWorth,
      gtkyDraft,
    } = this.props;

    const {
      redirecting,
    } = this.state;

    if (redirecting) {
      return <DashboardLoader />;
    }

    const notLoggedIn = isLoggedIn !== undefined && !isLoggedIn;

    if (isPreAccred) {
      return <Redirect to="/accreditation" />;
    }

    if (notLoggedIn) {
      return <Redirect to="/user/login" />;
    }

    return (
      <ExtendedFlex
        flexDirection="column"
        flexWrap="wrap"
        w={1}
        maxWidth={640}
        mx="auto"
        my={75}
      >
        <ScrollToTopOnMount />
        <Heading
          type="H2"
        >
          COMPLETE YOUR PROFILE
        </Heading>
        <Body
          type="B1"
        >
          This information will be used to present the best and most relevant investment opportunities.
        </Body>
        <CompleteYourProfileQuestions
          handleSubmit={this.handleFormSubmit}
          handleAccred={this.handleAccred}
          {...gtkyDraft}
          isAccredViaNetWorth={isAccredViaNetWorth}
        />
      </ExtendedFlex>
    );
  }
}

// const mapStateToProps = (state) => ({
const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.loginSuccess ||
    (state.auth.session !== null ? state.auth.session : undefined),
  isAccred: (state.app.user.loaded && state.app.user.gtkyStatus)
    ? (
      state.app.user.gtkyStatus === 'not_submitted' &&
      state.app.user.accreditationRoleStatus === 'Accredited'
    )
    : undefined,
  isPreAccred: (state.app.user.loaded && state.app.user.accreditationRoleStatus)
    ? state.app.user.accreditationRoleStatus === 'Pre-Accredited'
    : undefined,
  isAccredViaNetWorth: state.app.user.accredViaNetWorth,
  gtkyDraft: {
    ...pick(state.gtky, gtkyProps),
  },
});

const mapDispatchToProps = (dispatch) => ({
  getGettingToKnowYouDraftRequested: () => {
    dispatch(actions.userGettingToKnowYouDraftRequested());
  },
  postGettingToKnowYouRequested: (data, promise) => {
    dispatch(actions.userGettingToKnowYouRequested(data, promise));
  },
  postAccreditationRequested: (data, promise) => {
    dispatch(actions.userAccreditationRequested(data, promise));
  },
});

CompleteProfile.propTypes = {
  isLoggedIn: PropTypes.bool,
  getGettingToKnowYouDraftRequested: PropTypes.func,
  postGettingToKnowYouRequested: PropTypes.func,
  postAccreditationRequested: PropTypes.func,
  history: PropTypes.object,
  isPreAccred: PropTypes.bool,
  isAccredViaNetWorth: PropTypes.bool,
  gtkyDraft: PropTypes.object,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompleteProfile),
);
