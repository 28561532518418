import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ScrollToTopOnMount,
  Hero,
} from '../coreui';

import actions from '../dashboard/autoinvest/actions';

import heroBannerImage from './assets/hero.jpg';

import DashboardLoader from '../dashboard/components/DashboardLoader';
import OwnershipOptions from './components/OwnershipOptions';
import NoOwnershipOptions from './components/NoOwnershipOptions';

class AutoInvest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    this.props.getAutoInvestData();
  }

  render() {
    const {
      mogulReit1DistributionDuration,
      mogulReit1DistributionAmount,
      mogulReit2DistributionDuration,
      mogulReit2DistributionAmount,
      mogulReit2LastDistributionDeclared,
      hasOwnershipOptions,
    } = this.props;
    return (
      <>
        <ScrollToTopOnMount />
        <Hero
          image={heroBannerImage}
          header="Auto Invest"
          subHeader="Put Your Money to Work for You Automatically"
          bgPosition="74%"
        />
        <DashboardLoader
          loaded={hasOwnershipOptions !== null}
        >
          {
            hasOwnershipOptions &&
            <OwnershipOptions />
          }
          {
            !hasOwnershipOptions &&
            <NoOwnershipOptions
              mogulReit1DistributionDuration={mogulReit1DistributionDuration}
              mogulReit1DistributionAmount={mogulReit1DistributionAmount}
              mogulReit2DistributionDuration={mogulReit2DistributionDuration}
              mogulReit2DistributionAmount={mogulReit2DistributionAmount}
              mogulReit2LastDistributionDeclared={mogulReit2LastDistributionDeclared}
            />
          }
        </DashboardLoader>
      </>
    );
  }
}

AutoInvest.propTypes = {
  mogulReit1DistributionDuration: PropTypes.number,
  mogulReit1DistributionAmount: PropTypes.number,
  mogulReit2DistributionDuration: PropTypes.number,
  mogulReit2DistributionAmount: PropTypes.number,
  mogulReit2LastDistributionDeclared: PropTypes.number,
  getAutoInvestData: PropTypes.func,
  hasOwnershipOptions: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  mogulReit1DistributionDuration: state.tokens.mogulReit1DistributionDuration,
  mogulReit1DistributionAmount: state.tokens.mogulReit1DistributionAmount,
  mogulReit2DistributionDuration: state.tokens.mogulReit2DistributionDuration,
  mogulReit2DistributionAmount: state.tokens.mogulReit2DistributionAmount,
  mogulReit2LastDistributionDeclared: state.tokens.mogulReit2LastDistributionDeclared,
  hasOwnershipOptions: (() => {
    if (state.auth.session === false) {
      return false;
    }
    return state.autoInvest.loaded
      ? state.autoInvest.ownershipOptions.length > 0
      : null;
  })(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAutoInvestData: () => {
      dispatch(actions.autoInvestDataRequested());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoInvest);
