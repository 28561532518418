import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import {
  ExtendedFlex,
  Heading,
  Content,
  Link,
  Drawer,
  Image,
} from '../../coreui';

import Team from './team';
import ScrollToHash from '../../components/ScrollToHash';

// eslint-disable-next-line
class Management extends React.Component {
  render() {
    const {
      logo,
      title,
      overview,
      url,
      team,
      trackRecord,
      isFirst,
    } = this.props;
    return (
      <ExtendedFlex
        flexDirection="column"
        mb={40}
      >
        <ExtendedFlex
          flexDirection={['column', 'column', 'row']}
          alignItems="flex-start"
        >
          {
            logo &&
            <Image
              src={logo.url}
              w={343}
              mx={40}
              mb={40}
              alignSelf={['center', 'center', 'flex-start', 'auto']}
            />
          }
          <ExtendedFlex
            flexDirection="column"
            mx={40}
            w={['calc(100% - 80px)', 'calc(100% - 80px)', 'calc(100% - 423px)']}
          >
            <Heading
              type="H8"
              mt={0}
            >
              {title}
            </Heading>
            <Content>
              {ReactHtmlParser(overview)}
            </Content>
            {
              url &&
              <Link
                display="inline-block"
                type="L4"
                href={url}
                target="_blank"
              >
                {url}
              </Link>
            }
          </ExtendedFlex>
        </ExtendedFlex>
        {
          team &&
          <Team
            members={team}
          />
        }
        {
          trackRecord && isFirst &&
          <ScrollToHash
            name="track-record"
          >
            <Drawer
              title="Track Record"
              name="track-record"
            >
              <Content>
                {ReactHtmlParser(trackRecord)}
              </Content>
            </Drawer>
          </ScrollToHash>
        }
        {
          trackRecord && !isFirst &&
          <Drawer
            title="Track Record"
            name="track-record"
          >
            <Content>
              {ReactHtmlParser(trackRecord)}
            </Content>
          </Drawer>
        }
      </ExtendedFlex>
    );
  }
}

Management.propTypes = {
  logo: PropTypes.object,
  title: PropTypes.string,
  overview: PropTypes.string,
  url: PropTypes.string,
  team: PropTypes.array,
  trackRecord: PropTypes.string,
  isFirst: PropTypes.bool,
};

export default Management;
