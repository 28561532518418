import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Image,
  Body,
  InlineTextLink,
} from '../../../coreui';

import IconPDF from '../assets/icon_pdf.svg';

const AttachmentList = ({ attachments }) => {
  return (
    <ExtendedFlex
      flexDirection="column"
    >
      {attachments.map((item) => {
        return (
          <ExtendedFlex key={`attachment:${item.link}:${item.title}`}>
            <Image src={IconPDF} />
            <Body type="B5" px={10} color="#176DB6">
              <InlineTextLink href={item.link} target="_blank">
                {item.title}
              </InlineTextLink>
            </Body>
          </ExtendedFlex>
        );
      })}
    </ExtendedFlex>
  );
};

AttachmentList.propTypes = {
  attachments: PropTypes.array,
};

export default AttachmentList;
