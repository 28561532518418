import {
  takeLatest,
  put,
  call,
} from 'redux-saga/effects';

import {
  delay,
} from 'redux-saga';

import types from '../types';
import actions from '../actions';

import { getBrowseOpenApi } from '../../../api';

const maxRetries = 3;

export function* getBrowseOpenDataFlow() {
  let retry = true;
  let retryCount = 0;

  do {
    try {
      const response = yield call(getBrowseOpenApi);
      yield put(
        actions.getBrowseOpenDataSucceeded(
          response.data.data,
        ),
      );
      retry = false;
    } catch (error) {
      retryCount += 1;
      retry = [503, 504].includes(error.response.status) ? retryCount < maxRetries : false;
      yield put(
        actions.getBrowseOpenDataFailed(
          {
            ...error,
            retry: {
              count: retryCount,
              retry,
            },
          },
        ),
      );

      if (retry) {
        yield call(delay, 2000);
      }
    }
  } while (retry);
}

export function* watchGetBrowseOpenDataRequested() {
  yield takeLatest(
    types.GET_BROWSE_OPEN_DATA_REQUESTED,
    getBrowseOpenDataFlow,
  );
}

export default watchGetBrowseOpenDataRequested;
