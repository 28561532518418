import React from 'react';
import numeral from 'numeral';
import { icons } from 'rtmg-component-library';
import Background from '../../assets/REIT-Hero.png';
import Logo from '../../assets/RM-Logo-Building.svg';

if (!numeral.locales.en.abbreviations.million !== 'MM') {
  numeral.locales.en.abbreviations.million = 'MM';
}

export const createData = ({
  ios,
  tokens,
}) => {
  return {
    reitHero: {
      background: Background,
      logo: Logo,
      subTitle: 'Invest in Real Estate Investment Trusts (REITS)',
      title: 'ADD COMMERCIAL REAL ESTATE TO YOUR PORTFOLIO FOR AS LITTLE AS $5,000',
      buttonText: 'GET STARTED',
      buttonTextMobile: 'START',
      placeholderText: 'Please enter email address',
      placeholderTextMobile: 'Please enter email',
      disclaimer: [
        'By continuing, you accept and agree to our ',
        <a href="/terms-service">Terms of Service</a>,
        ' and ',
        <a href="/privacy-policy">Privacy Policy</a>,
        '.'],
    },
    reitCards: ios.map((item) => {
      let additionalData = {};
      if (item.id === 193744) {
        additionalData = {
          description: 'Invests in cash-flowing multifamily, retail and office properties that can potentially generate monthly cash distributions to investors.',
          ltmReturn: tokens.mogulReit1LastTwelveMonthsReturn,
          ltmReturnDate: tokens.mogulReit1LastTwelveMonthsReturnDate,
          annualDistributionRate: (tokens.mogulReit1LastDistributionDeclared * 100).toFixed(1),
          distributionTooltip: tokens.mogulReit1DistributionTooltip,
          distributionDuration: tokens.mogulReit1DistributionDuration,
          reitStrategyLabel: 'Passive Income',
          tavTooltip: 'Aggregate value of properties owned by The Income REIT, LLC based on the most recent internal valuations as of the end of the fiscal quarter upon which our most recently announced NAV per share is based pursuant to our valuation policies. The aggregate value of the properties underlying loans made by The Income REIT, LLC is based on independent appraisals dated within six months of the original acquisition dates by RM Adviser, Realty Mogul, Co. or Realty Mogul Commercial Capital, Co., as applicable.  As with any methodology used to estimate value, the methodology employed by our affiliates’ internal accountants or asset managers is based upon a number of estimates and assumptions about future events that may not be accurate or complete.  For more information, see the section of our Offering Circular captioned “Description of Our Common Shares – Valuation Policies.”',
          cdpTooltip: 'Although The Income REIT has historically made monthly distributions, there is no guarantee that the REIT will make distributions, and if we do, we may fund such distributions from sources other than cash flow from operations, including, without limitation, the sale of assets, borrowings, return of capital or offering proceeds, and we have no limits on the amounts we may pay from such sources.',
          factsheet: 'https://www.realtymogul.com/sites/default/files/io/income_reit_factsheet_0.pdf',
          scheduleCall: 'https://calendly.com/realtymogul-investor-relations/realty-mogul-representative-call?utm_campaign=reits',
        };
      } else {
        additionalData = {
          description: 'Invests in value-add apartment buildings that can potentially generate long-term capital appreciation and quarterly cash distributions.',
          ltmReturn: tokens.mogulReit2LastTwelveMonthsReturn,
          ltmReturnDate: tokens.mogulReit2LastTwelveMonthsReturnDate,
          annualDistributionRate: (tokens.mogulReit2LastDistributionDeclared * 100).toFixed(1),
          distributionTooltip: tokens.mogulReit2DistributionTooltip,
          distributionDuration: tokens.mogulReit2DistributionDuration,
          reitStrategyLabel: 'Capital Appreciation',
          tavTooltip: 'Aggregate value of all underlying properties in The RealtyMogul Apartment Growth REIT, Inc. based on the most recent internal valuations as of the end of the fiscal quarter upon which our most recently announced NAV per share is based pursuant to our valuation policies. As with any methodology used to estimate value, the methodology employed by our affiliates’ internal accountants or asset managers is based upon a number of estimates and assumptions about future events that may not be accurate or complete.  For more information, see the “Description of Our Common Stock – Valuation Policies” section of our offering circular.',
          cdpTooltip: 'Although The RealtyMogul Apartment Growth REIT has historically made quarterly distributions, there is no guarantee that the REIT will make distributions, and if we do, we may fund such distributions from sources other than cash flow from operations, including, without limitation, the sale of assets, borrowings, return of capital or offering proceeds, and we have no limits on the amounts we may pay from such sources.',
          factsheet: 'https://www.realtymogul.com/sites/default/files/io/apartment_growth_reit_factsheet.pdf',
          scheduleCall: 'https://calendly.com/realtymogul-investor-relations/realty-mogul-representative-call?utm_campaign=reits',
        };
      }
      return {
        ...item,
        ...additionalData,
      };
    }),
    whatIsReit: {
      title: 'What is a REIT?',
      copy: `A Real Estate Investment Trust or “REIT’ is a special corporate structure for a business that owns, develops or manages real estate.
    
      Investors can purchase shares in a REIT to gain exposure to large-scale commercial real estate assets like apartment buildings, offices, retail, and industrial.`,
      keyBenefits: {
        title: 'KEY BENEFITS',
        benefits: [
          'A quick and easy way to diversify with real estate',
          'Potentially achieve passive income and inflation protection',
          'Greater liquidity compared to buying and selling properties',
        ],
        disclaimer:
          'Source: https://www.reit.com/investing/financial-benefits-reits',
      },
      schedule: {
        icon: <icons.Calendar />,
        title: 'SCHEDULE APPOINTMENT',
        href: 'https://calendly.com/realtymogul-investor-relations/realty-mogul-representative-call?utm_campaign=reits',
        ctaText: 'Book with Investor Relations Team',
      },
    },
  };
};

export default createData;
