import types from './types';

export default {
  appInit: (data) => ({
    type: types.APP_INIT,
    data,
  }),
  getUserDataRequested: (payload = {}) => ({
    type: types.GET_USER_DATA_REQUESTED,
    payload,
  }),
  getUserDataFailed: (error) => ({
    type: types.GET_USER_DATA_FAILED,
    error,
  }),
  getUserDataSucceeded: (user) => ({
    type: types.GET_USER_DATA_SUCCEEDED,
    user,
  }),
  setUserDataRequested: (payload) => ({
    type: types.SET_USER_DATA_REQUESTED,
    payload,
  }),
  setUserDataFailed: (error) => ({
    type: types.SET_USER_DATA_FAILED,
    error,
  }),
  setUserDataSucceeded: (user) => ({
    type: types.SET_USER_DATA_SUCCEEDED,
    user,
  }),
  userLogoutRequested: () => ({
    type: types.USER_LOGOUT_REQUESTED,
  }),
  userLogoutFailed: (error) => ({
    type: types.USER_LOGOUT_FAILED,
    error,
  }),
  userLogoutSucceeded: (data) => ({
    type: types.USER_LOGOUT_SUCCEEDED,
    data,
  }),
};
