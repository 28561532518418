import types from './types';

const initialState = {
  jobs: undefined,
  quotes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CAREER_LIST_SUCCEEDED: {
      return {
        ...state,
        jobs: action.data.departments.reduce((jobs, department) => ([
          ...jobs,
          ...department.jobs.map((job) => ({
            title: job.title,
            location: job.location.name,
            department: department.name,
            url: job.absolute_url,
          })),
        ]), []),
      };
    }
    case types.TESTIMONIAL_LIST_SUCCEEDED: {
      return {
        ...state,
        quotes: action.data.result[0].items.map((person) => ({
          ...person,
          joined: person.joined.split('-')[0],
        })),
      };
    }
    default:
      return {
        ...state,
      };
  }
};
