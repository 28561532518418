import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';
import stubResponse from '../data';

import { getPopularContentDataApi } from '../../../../api';

export const mockApi = () => ({
  data: stubResponse,
});

export function* getPopularContentDataFlow() {
  try {
    const response = yield call(getPopularContentDataApi);
    yield put(
      actions.getPopularContentDataSucceeded(response.data),
    );
  } catch (error) {
    yield put(
      actions.getPopularContentsDataFailed(error),
    );
  }
}

export function* watchGetPopularContentsDataRequested() {
  yield takeLatest(
    types.GET_POPULAR_CONTENT_REQUESTED,
    getPopularContentDataFlow,
  );
}

export default watchGetPopularContentsDataRequested;
