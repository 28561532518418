/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { ActionBox, icons } from 'rtmg-component-library';

const CTATailWrapper = styled.div`
  padding: 30px;
  box-sizing: border-box;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  @media (min-width: 768px) {
    padding: 60px;
    flex-direction: row;
  }
  .content {
    max-width: 964px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: center;
    }
  }
`
const First = {
  icon: <icons.Calendar width="10" height="10" />,
  title: 'SCHEDULE APPOINTMENT',
  ctaText: 'Book With Investor Relations Team',
  href: 'https://calendly.com/realtymogul-investor-relations/realty-mogul-representative-call?utm_campaign=reits',
};

const Second = {
  icon: <icons.Phone width="10" height="10" />,
  title: 'CALL TODAY (877) 781-7062',
  ctaText: 'Contact Investor Relations',
  href: 'tel:+18777817062',
}

export const CTATail = () => {
  return (
    <CTATailWrapper>
      <div className="content">
        <ActionBox {...First} />
          <div style={{ width: '30px', height: '30px' }} />
        <ActionBox {...Second} />
      </div>
    </CTATailWrapper>
  );
};

export default CTATail;
