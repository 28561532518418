import React from 'react';

import {
  ExtendedFlex,
} from '../../coreui';

const Actions = (props) => {
  return (
    <ExtendedFlex
      flexDirection={['column', null, null, 'row']}
      justify={['flex-start', null, null, 'space-between']}
      align="center"
      mt={80}
      mb={120}
      // eslint-disable-next-line
      {...props}
    />
  );
};

export default Actions;
