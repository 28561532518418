import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import {
  ExtendedFlex,
  Heading,
  Text,
  Formatters,
  ExtendedBox,
} from '../../coreui';

import PendingDashboardActionCell from './PendingDashboardActionCell';
import PendingDashboardCancelCell from './PendingDashboardCancelCell';

function dateFormat(inputDate) {
  const date = new Date(inputDate);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

const Row = ({ label, value }) => (
  <ExtendedFlex
    flexDirection="row"
    justifyContent="space-between"
    my={12}
  >
    <Heading type="DashboardLabel" my={6}>{label}</Heading>
    <Heading type="DashboardLabel" my={6}>{value}</Heading>
  </ExtendedFlex>
);

Row.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

const IOCardPledged = ({ data }) => {
  return (
    <ExtendedFlex
      flexDirection={['column', 'row']}
      width={1}
      maxWidth={['100%', 960]}
      mx="auto"
      my={6}
      border="1px solid #C9C9C9"
      borderRadius="5px"
    >
      {
        data.image &&
        <ExtendedFlex
          w={['100%', '100%', '250px', '250px', '250px']}
          minWidth={['100%', '100%', '250px', '250px', '250px']}
        >
          <img src={data.image} alt={data.title} style={{ width: '100%', objectFit: 'cover' }} />
        </ExtendedFlex>
      }
      <ExtendedFlex
        flexDirection={['column', 'row']}
        w={1}
        justifyContent="space-between"
      >
        <ExtendedFlex
          flexDirection="column"
          p={24}
          justifyContent="center"
        >
          <Text fontFamily="serif" fontSize={[14, 14, 16, 18, 20]} my={6}>{data.type}</Text>
          <Text fontFamily="serif" fontSize={[14, 14, 16, 18, 20]} my={6}>{data.location}</Text>
          <Text fontFamily="serif" fontSize={[14, 14, 16, 18, 20]} my={6}>{data.investment}</Text>
        </ExtendedFlex>
        <ExtendedFlex
          flexDirection="column"
          w="300px"
          px={24}
          justifyContent="center"
        >
          <ExtendedBox>
            <Row label="Amount" value={numeral(data.amount).format(Formatters.DOLLARS_DECIMALS)} />
            <Row label="Date" value={dateFormat(data.date)} />
            {
              data.action &&
              <Row
                label="Action"
                value={<PendingDashboardActionCell
                  original={{ ...data }}
                  value={data.action}
                />}
              />
            }
            <Row label="Cancel" value={<PendingDashboardCancelCell original={data} />} />
          </ExtendedBox>
        </ExtendedFlex>
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

export default IOCardPledged;

IOCardPledged.propTypes = {
  data: PropTypes.object,
};
