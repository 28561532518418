/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import Full from './modals/Full';
import Limited from './modals/Limited';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      top: props.isVisible ? '50%' : '-10000vh',
    };

    this.updateTopPosition = this.updateTopPosition.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isVisible !== prevProps.isVisible) {
      if (this.props.isVisible) {
        if (this.topTimeout) {
          clearTimeout(this.topTimeout);
        }
        this.updateTopPosition('50%');
      } else {
        this.topTimeout = setTimeout(() => {
          this.updateTopPosition('-10000vh');
        }, 1000);
      }
    }
  }

  updateTopPosition(top) {
    this.setState({ top });
  }

  render() {
    const {
      full,
      ...additionalProps
    } = this.props;

    const Component = full ? Full : Limited;

    return <Component {...additionalProps} top={this.state.top} />;
  }
}

Modal.propTypes = {
  full: PropTypes.bool,
  isVisible: PropTypes.bool,
};

export default Modal;
