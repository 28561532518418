/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedFlex,
} from '../../coreui';

import ReitInvesting1 from './components/ReitInvesting';
import IraInvesting2 from './components/IraInvesting';

class ReitInvesting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible,
      });
    }
  }

  render() {
    const { isLoggedIn } = this.props;
    return (
      <ExtendedFlex
        flexDirection={['column', 'column', 'row', 'row', 'row']}
      >
        <ReitInvesting1
          handleSensorChange={this.handleSensorChange}
          isLoggedIn={isLoggedIn}
          {...this.state}
        />
        <IraInvesting2
          {...this.state}
        />
      </ExtendedFlex>
    );
  }
}

ReitInvesting.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default ReitInvesting;
