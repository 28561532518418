export default (...args) => {
  try {
    if (window && window.analytics && window.analytics.track) {
      window.analytics.track(...args);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
