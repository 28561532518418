import types from './types';

const initialState = {
  revalidateEmailSent: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REVALIDATE_EMAIL_REQUESTED: {
      return {
        ...state,
        revalidateEmailSent: undefined,
      };
    }

    case types.REVALIDATE_EMAIL_SUCCEEDED: {
      return {
        ...state,
        revalidateEmailSent: true,
      };
    }

    case types.REVALIDATE_EMAIL_FAILED: {
      return {
        ...state,
        revalidateEmailSent: false,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
