import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  ResponsiveTabFilter,
} from '../../coreui';

import SearchBar from '../search/components/SearchBar';

const Filters = ({
  topics,
  updateSelectedFilterValue,
  selectedCategory,
}) => {
  return (
    <ExtendedBox w={1}>
      <ExtendedFlex
        flexDirection="column"
        w={1}
        p={40}
        align="center"
        justify="center"
        pt={[60, 140]}
        maxWidth={1600}
        m="auto"
      >
        <Heading type="H3" color="gray.8" m={0}>
          Choose a topic
        </Heading>

        {/* search */}
        <SearchBar placeholder="Search" mt={[20, 40]} />

        {/* categories */}
        <ResponsiveTabFilter
          id="topics"
          filterItems={topics}
          updateSelectedFilterValue={updateSelectedFilterValue}
          selectedFilterValue={selectedCategory}
        />
      </ExtendedFlex>
    </ExtendedBox>
  );
};

Filters.propTypes = {
  topics: PropTypes.array,
  updateSelectedFilterValue: PropTypes.func,
  selectedCategory: PropTypes.string,
};

export default Filters;
