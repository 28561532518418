/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import Slider from 'react-slick';

import {
  ExtendedBox,
} from '..';

const CarouselQuotes = (props) => {
  const {
    children,
  } = props;

  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
  };

  return (
    <ExtendedBox
      position="relative" // not strictly necessary, kept to ensure match with Large
    >
      <Slider {...settings} >
        {children}
      </Slider>
    </ExtendedBox>
  );
};

CarouselQuotes.propTypes = {
  children: PropTypes.any,
};

export default CarouselQuotes;
