import styled from 'styled-components';

export default {
  ReitHistorical: styled.div`
    width: 100%;
    padding: 70px 16px 80px;
    box-sizing: border-box;
    display: block;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    color: #202020;
    background: ${({ background }) => `${background}`};
    h2 {
      font-family: mrs-eaves-xl-serif-narrow,serif;
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      padding: 110px 100px 120px;
    }
  `,
  Container: styled.div`
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      flex-direction: row;
      justify-content: space-between;
      align-items: space-between;
    }
  `,
  DescriptionTile: styled.div`
    width: 100%;
    h1 {
      font-family: mrs-eaves-xl-serif-narrow,serif;
      margin: 0 0 10px;
      font-weight: 800;
      font-size: 28px;
      line-height: 32px;
    }
    p {
      font-size: 16px;
      line-height: 28px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      h1 {
        font-size: 44px;
        line-height: 56px;
      }
      p {
        font-size: 18px;
      line-height: 32px;
      }
    }
  `,
  Column: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:nth-child(2) {
      margin-bottom: 20px;
    }
    &:nth-child(3) {
      flex-direction: column-reverse;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      width: 45%;
      margin-bottom: 0 !important;
    }
    .value {
      font-family: mrs-eaves-xl-serif-narrow,serif;
      color: #176DB6;
      font-weight: 800;
      font-size: 36px;
      line-height: 40px;
    }
  `,
  ContentTiles: styled.div`
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    flex-wrap: wrap;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      padding-top: 20px;
    }
  `,
  Item: styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    .title {
      font-family: mrs-eaves-xl-serif-narrow,serif;
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
    }
    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      padding-right: 10%;
      box-sizing: border-box;
    }
  `,
  ButtonContainer: styled.div`
    width: 100%;
    margin: 50px auto 0;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      width: 65%;
      margin-top: 60px;
    }
  `,
};
