import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
} from '../../../../coreui';

import FadeIn from '../../../../components/FadeIn';

const StrategySection2 = ({
  isVisible,
}) => {
  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
      height="100%"
    >
      <ExtendedFlex
        flexDirection="column"
        height="100%"
      >
        <ExtendedBox
          m={0}
          pb={30}
          bg="gray.1"
          height={[500, 500, '50%', '50%', '50%']}
        >
          <ExtendedFlex
            flexDirection="column"
            justify="center"
            maxWidth={[null, null, 520, 520, 660]}
            height="100%"
            px={['15%', '25%', 60, 100, 120]}
          >
            <FadeIn>
              <Heading
                type="H3"
                textTransform="uppercase"
                m={0}
                mb="0.7em"
                opacity={isVisible ? '1' : '0'}
              >
                RENOVATING AND IMPROVING PROPERTIES
              </Heading>
              <Body
                type="B1"
                m={0}
                opacity={isVisible ? '1' : '0'}
              >
                A value-add strategy means finding and
                sourcing underperforming multifamily
                properties and attempting to increase the
                net operating income, generally through
                capital improvements that permit rent
                increases and through operational
                efficiencies that decrease expenses.
              </Body>
            </FadeIn>
          </ExtendedFlex>
        </ExtendedBox>
        <ExtendedBox
          mb={30}
          height={[500, 500, '50%', '50%', '50%']}
        >
          <ExtendedFlex
            flexDirection="column"
            justify="center"
            maxWidth={[null, null, 520, 520, 660]}
            height="100%"
            px={['15%', '25%', 60, 100, 120]}
          >
            <FadeIn>
              <Heading
                type="H3"
                textTransform="uppercase"
                m={0}
                mb="0.7em"
                opacity={isVisible ? '1' : '0'}
              >
                GENERATING POTENTIAL INCOME AND APPRECIATON
              </Heading>
              <Body
                type="B1"
                m={0}
                mb="4em"
                opacity={isVisible ? '1' : '0'}
              >
                During the investment, distributions may be
                collected via rent paid by the tenants, after
                expenses have been paid. Investors could
                also potentially realize a gain on the sale of
                the property.
              </Body>
            </FadeIn>
          </ExtendedFlex>
        </ExtendedBox>
      </ExtendedFlex>
    </Box>
  );
};

StrategySection2.propTypes = {
  isVisible: PropTypes.bool,
};

export default StrategySection2;
