import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Flex,
  Text,
} from '../../..';

const InvestmentCardInvestmentTypeDisplay = ({
  eligible1031,
}) => {
  return (
    <Box
      pt={[5, null, 0, null, 0]}
      px={10}
    >
      <Flex
        justify="flex-end"
      >
        {
          eligible1031 &&
          <Text
            color="blue.0"
            fontSize={[12, 12, 14, 14, 18]}
            m={0}
            textTransform="uppercase"
            fontWeight={700}
            letterSpacing="normal"
          >
            1031 Eligible
          </Text>
        }
      </Flex>
    </Box>
  );
};

InvestmentCardInvestmentTypeDisplay.propTypes = {
  eligible1031: PropTypes.bool,
};

InvestmentCardInvestmentTypeDisplay.defaultProps = {
  eligible1031: false,
};

export default InvestmentCardInvestmentTypeDisplay;
