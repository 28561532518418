import {
  takeLatest,
  call,
  all,
  put,
  select,
} from 'redux-saga/effects';

import getUserId from '../../appWrapper/selectors/getUserId';

import types from '../types';
import actions from '../actions';
import dsmActions from '../../dsm/actions';

import { getTriggerRevalidateEmail } from '../../api';

export function* getEmailValidateFlow() {
  try {
    const userId = yield select(getUserId);
    const response = yield call(getTriggerRevalidateEmail, userId);
    yield all([
      put(actions.revalidateEmailSucceeded()),
      put(dsmActions.getDSMSucceeded(response.data)),
    ]);
  } catch (error) {
    yield put(
      actions.revalidateEmailFailed(error),
    );
  }
}

export function* watchGetEmailValidateFlow() {
  yield takeLatest(
    types.REVALIDATE_EMAIL_REQUESTED,
    getEmailValidateFlow,
  );
}

export default watchGetEmailValidateFlow;
