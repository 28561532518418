import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Heading,
  Body,
  StyledButton,
} from '../../coreui';

const WaitlistModal = ({ handleClick, waitListRef }) => {
  return (
    <ExtendedFlex
      flexDirection="column"
      align="center"
      justify="center"
    >
      <Heading
        type="H3"
      >
        Added to Waitlist
      </Heading>
      <Body
        type="B1"
        textAlign="center"
      >
        This property has been added to your waitlist. If a current investor
        drops out, or if additional funds are needed, you will be contacted with
        the opportunity to participate.
      </Body>
      <ExtendedFlex
        align="center"
        justify="center"
        mt={20}
      >
        <StyledButton
          type="L3"
          onClick={handleClick}
          mx={10}
        >
          Close
        </StyledButton>
        <StyledButton
          type="L1"
          mx={10}
          onClick={() => {
            handleClick();
            const scrollTo = {
              top: waitListRef.current.getBoundingClientRect().top + window.scrollY,
              behavior: 'smooth',
            };
            window.scrollTo(scrollTo);
          }}
        >
          View Waitlist
        </StyledButton>
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

WaitlistModal.propTypes = {
  handleClick: PropTypes.func,
  waitListRef: PropTypes.object,
};

export default WaitlistModal;
