import types from './types';

export default {
  getReitsDataRequested: (id1, id2) => ({
    type: types.GET_REITS_DATA_REQUESTED,
    id1,
    id2,
  }),
  getReitsDataSucceeded: (data) => ({
    type: types.GET_REITS_DATA_SUCCEEDED,
    data,
  }),
  getReitsDataFailed: (error) => ({
    type: types.GET_REITS_DATA_FAILED,
    error,
  }),
  getReitsFAQDataSucceeded: (data) => ({
    type: types.GET_REITS_FAQ_DATA_SUCCEEDED,
    data,
  }),
  getReitsFAQDataFailed: (error) => ({
    type: types.GET_REITS_FAQ_DATA_FAILED,
    error,
  }),
};
