import get from 'lodash/get';

import types from '../types';
import appWrapperTypes from '../../appWrapper/types';

import {
  enrollmentSteps,
  enrollmentCancelBody,
} from './data';

const initialState = {
  configs: [],
  ownershipOptions: [],
  bankAccounts: [],
  formsOfOwnership: [],
  loaded: false,
  enrollmentErrors: undefined,
  editErrors: undefined,
  enrollmentSteps,
  enrollmentCancelBody,
};

const sortConfigs = ({ io: { id: idA } }, { io: { id: idB } }) => {
  if (idA === idB) {
    return 0;
  }
  return idA < idB ? -1 : 1;
};

const upsertTypes = [
  types.AUTOINVEST_ENROLLMENT_SUCCEEDED,
  types.AUTOINVEST_UPDATE_SUCCEEDED,
];

const deserializeConfigs = ({
  formsOfOwnership,
  configs,
}) => (
  // Loop over the FOEs to get their configs.
  formsOfOwnership.reduce((accum, foe) => {
    // First, we look at the investments on the FOE.
    const eligibleConfigs = foe.investments
      .reduce((accum, investment) => {
        // If we already found this one, skip it.
        if (accum.filter((item) => item.io.id === investment.io.id).length) {
          return accum;
        }
        // Otherwise, find the config object that matches our IO and FOE.
        return [
          ...accum,
          configs.find((config) => config.foe.id === foe.id && config.io.id === investment.io.id)
          // Or just a stub object if the user hasn't created a config for this
          // pair yet.
          || {
            ...investment,
            id: null,
            uuid: null,
            status: 'inactive',
            foe,
            bankAccount: {
              accountTypeLabel: null,
              lastFour: null,
            },
            requestedStartDate: null,
            requestedAmount: null,
            totalAutoInvested: null,
            lastWithdrawalAmount: null,
            isNew: true,
          },
        ];
      }, [])
      .sort((a, b) => {
        // If the status for both are pending or neither are pending, do a
        // normal comparison.
        if ((a.status === 'pending' && b.status === 'pending')
            || (a.status !== 'pending' && b.status !== 'pending')) {
          return sortConfigs(a, b);
        }
        // Otherwise, if A is pending, it should go before.
        if (a.status === 'pending') {
          return -1;
        }
        // And if B is pending, it should definitely go after.
        if (b.status === 'pending') {
          return 1;
        }
        // Or just do the normal comparison.
        return sortConfigs(a, b);
      });
    return [
      ...accum,
      ...eligibleConfigs,
    ];
  }, [])
);

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AUTOINVEST_ENROLLMENT_REQUESTED: {
      return {
        ...state,
        enrollmentErrors: undefined,
      };
    }

    case types.AUTOINVEST_UPDATE_REQUESTED: {
      return {
        ...state,
        editErrors: undefined,
      };
    }

    case types.AUTOINVEST_CANCEL_SUCCEEDED: {
      const configs = state.configs.filter((config) => config.id !== action.data.id);
      return {
        ...state,
        configs,
        ownershipOptions: deserializeConfigs({
          formsOfOwnership: state.formsOfOwnership,
          configs,
        }),
      };
    }

    case types.AUTOINVEST_UPDATE_SUCCEEDED:
    case types.AUTOINVEST_ENROLLMENT_SUCCEEDED:
    case types.AUTOINVEST_API_SUCCEEDED: {
      const {
        data,
        _meta: {
          bankAccounts,
          formsOfOwnership,
        },
      } = action.data;

      let configs = data;

      // If this was an upsert type, upsert it into the data.
      if (upsertTypes.includes(action.type)) {
        const newConfig = data[0];
        configs = [
          ...state.configs.filter((config) => config.id !== newConfig.id),
          newConfig,
        ];
      }
      return {
        ...state,
        loaded: true,
        configs,
        ownershipOptions: deserializeConfigs({
          formsOfOwnership,
          configs,
        }),
        bankAccounts,
        formsOfOwnership,
      };
    }

    case types.AUTOINVEST_ENROLLMENT_FAILED: {
      return {
        ...state,
        enrollmentErrors: get(action.error, 'response.data'),
      };
    }

    case types.AUTOINVEST_UPDATE_FAILED: {
      return {
        ...state,
        editErrors: get(action.error, 'response.data'),
      };
    }

    case appWrapperTypes.USER_LOGOUT_REQUESTED: {
      return {
        ...state,
        ...initialState,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
