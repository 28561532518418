import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Headroom from 'react-headroom';
// FIXME: When we merge all these projects into 1 application, we should
// move browser detection as high up in the application as possible in
// order to prevent additional calls to detect() as users navigate
// from page to page.
import { detect } from 'detect-browser';
import {
  withRouter,
} from 'react-router-dom';
import {
  ExtendedBox,
  ExtendedFlex,
  HeaderNav,
  ClickableLogo,
  HeaderHamburger,
} from '../coreui';
import withPortal from '../withPortal';

import authActions from '../auth/actions';

import HeaderMobileMenu from './components/HeaderMobileMenu';
import HeaderUserMenu from './components/HeaderUserMenu';
import HeaderMenu from './components/HeaderMenu';
import { transformMenuItem } from './helpers';

const headerRoot = typeof document !== 'undefined' ? document.getElementById('react-header') : null;

class Header extends Component {
  constructor(props) {
    super(props);
    const browser = detect();

    this.state = {
      mobileMenuVisible: false,
      browser: browser.name === 'ie' && browser.version === '11.0.0' ? 'ie11' : 'notie11',
    };

    this.hamburgerOnClickHandler = this.hamburgerOnClickHandler.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  hamburgerOnClickHandler() {
    this.setState((state) => ({
      mobileMenuVisible: !state.mobileMenuVisible,
    }));
  }

  UNSAFE_componentWillMount() {
    this.setState({
      mobileMenuVisible: false,
    });
  }

  handleLogout(e) {
    e.preventDefault();
    this.props.userLogoutRequested();
    this.setState({
      mobileMenuVisible: false,
    });
  }

  componentDidMount() {
    this.setState({
      speechText: 'Questions about RealtyMogul? Talk directly with our Investor Relations team at (646) 921-8716. We would be happy to give you a personalized walkthrough of our platform.',
    });
  }

  render() {
    const { mobileMenuVisible, browser, speechText } = this.state;
    const { items: propItems, user, initialized, isApp, frontendPrefix } = this.props;

    const items = propItems
      .map((item) => ({
        ...item,
        ...transformMenuItem(item, { isApp, frontendPrefix }),
        children: item.children.length > 0
          ? item.children.map((item) => ({ ...item, ...transformMenuItem(item, { isApp, frontendPrefix }) }))
          : item.children,
      }));

    return (
      <>
        <div role="alert" aria-live="assertive" style={{ position: 'absolute', height: '1px', width: '1px', overflow: 'hidden' }}>{speechText}</div>
        <Headroom style={{ zIndex: 12 }}>
          <HeaderNav>
            <ExtendedBox
              boxSizing="border-box"
              display={['block', 'block', 'block', 'none']}
              w="1px"
              flex="1"
            />
            <HeaderHamburger
              display={['block', 'block', 'block', 'none']}
              mobileMenuVisible={mobileMenuVisible}
              handleClick={this.hamburgerOnClickHandler}
              flex="1"
            />
            <ExtendedFlex
              flex="1"
              justify={['center', 'center', 'center', 'flex-start']}
            >
              <ClickableLogo
                logoColor="black"
                w={['auto']}
                isApp={isApp}
                frontendPrefix={frontendPrefix}
              />
            </ExtendedFlex>
            <HeaderMenu
              items={items}
              browser={browser}
              isLimitedNav={false}
              isApp={isApp}
              frontendPrefix={frontendPrefix}
            />
            {
              initialized &&
              <HeaderUserMenu
                browser={browser}
                isLimitedNav={false}
                isRegFlow={false}
                user={user}
                display={['none', 'none', 'block']}
                zIndex={9993}
                handleLogout={this.handleLogout}
                userSwitch={user.userSwitch}
                isApp={isApp}
                frontendPrefix={frontendPrefix}
              />
            }
            {
              !initialized &&
              <ExtendedBox
                flex="1"
              />
            }
          </HeaderNav>
        </Headroom>
        <HeaderHamburger
          mobileMenuVisible={mobileMenuVisible}
          handleClick={this.hamburgerOnClickHandler}
          visibility={mobileMenuVisible ? 'visible' : 'hidden'}
          position="fixed"
          display={['block', 'block', 'block', 'none']}
          isApp={isApp}
          frontendPrefix={frontendPrefix}
        />
        <HeaderMobileMenu
          mobileMenuVisible={mobileMenuVisible}
          closeMenu={this.hamburgerOnClickHandler}
          handleLogout={(e) => {
            this.handleLogout(e);
            this.hamburgerOnClickHandler(e);
          }}
          items={items}
          user={user}
          isApp={isApp}
          frontendPrefix={frontendPrefix}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  items: state.header.items,
  user: state.header.user,
  initialized: state.header.initialized,
  isApp: state.app.isApp,
  frontendPrefix: state.app.frontendPrefix,
});

const mapDispatchToProps = (dispatch) => ({
  userLogoutRequested: () => {
    dispatch(authActions.userLogoutRequested());
  },
});

Header.propTypes = {
  items: PropTypes.array,
  user: PropTypes.object,
  userLogoutRequested: PropTypes.func,
  initialized: PropTypes.bool,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

export default withPortal(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)),
  headerRoot,
);
