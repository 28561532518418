import types from './types';

const initialState = {
  loginSuccess: null,
  loginRedirect: null,
  session: null,
  error: null,
  register: {},
  registerAccred: null,
  pageVariant: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.USER_LOGIN_REQUESTED: {
      return {
        ...state,
        loginSuccess: null,
      };
    }
    case types.USER_LOGIN_SUCCEEDED: {
      return {
        ...state,
        loginSuccess: true,
        loginRedirect: action.data.destination || '/',
      };
    }
    case types.USER_LOGIN_FAILED: {
      return {
        ...state,
        loginSuccess: false,
        error: action.error.response,
      };
    }
    case types.USER_LOGOUT_REQUESTED: {
      return {
        ...state,
        ...initialState,
        session: false,
      };
    }
    case types.USER_SESSION_SUCCEEDED: {
      return {
        ...state,
        session: true,
      };
    }
    case types.USER_SESSION_FAILED: {
      return {
        ...state,
        session: false,
      };
    }
    case types.USER_REGISTER_SUCCEEDED: {
      return {
        ...state,
        register: {
          ...action?.data?.data,
        },
      };
    }
    case types.USER_REGISTER_ACCRED_SUCCEEDED: {
      return {
        ...state,
        registerAccred: action?.data?.data?.accredited,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
