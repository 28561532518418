import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ExtendedFlex,
  ExtendedBox,
  Box,
  Heading,
  Body,
  Numeral,
} from '../../coreui';

import StatBox from './components/StatBox';
import DynamicBrowseJoinLink from '../../helpers';
import FadeIn from '../../components/FadeIn';

const RightOpportunities = ({
  isLoggedIn,
  totalMembers,
  totalAmountFinanced,
  totalPropertyValueFinanced,
  investmentsMade = '28935',
  sinceInceptionDisclaimer = '*SINCE INCEPTION AS OF MAY 31, 2022',
}) => {
  return (
    <ExtendedFlex
      bg="black"
      py={100}
      flexDirection={['column', 'column', 'row', 'row', 'row']}
      alignItems="center"
      px={['5%', '5%', '5%', '10%', '15%']}
    >
      <Box
        boxSizing="border-box"
        width={[1, 1, 0.5, 0.5, 0.5]}
      >
        <ExtendedBox w={1}>
          <Box>
            <ExtendedFlex
              maxWidth={['100%', '100%', 400, 400, 540]}
              flexDirection="column"
            >
              <FadeIn>
                <Heading
                  type="H2"
                  mt={0}
                  color="white"
                >
                  The Future of Real Estate Investing
                </Heading>
                <Body
                  type="B1"
                  mb={0}
                  color="white"
                  display="block"
                >
                  It has always been our mission to give investors access to diverse, institutional-quality real estate opportunities, and we’re proud of the diversity that has been hosted on the Platform.
                </Body>
                <ExtendedBox
                  width={1}
                  pt={60}
                  textAlign={['center', 'center', 'center', 'left', 'left']}
                >
                  <DynamicBrowseJoinLink
                    isLoggedIn={isLoggedIn}
                    anonText="Get Started"
                    type="L1"
                  />
                </ExtendedBox>
              </FadeIn>
            </ExtendedFlex>
          </Box>
        </ExtendedBox>
      </Box>
      <ExtendedBox
        width={[1, 1, 0.5, 0.5, 0.5]}
      >
        <ExtendedFlex
          justifyContent="space-between"
          alignItems="center"
          flexDirection="column"
          w={1}
          mt={[50, 50, 0, 0, 0]}
        >
          <ExtendedFlex
            justifyContent="center"
            alignItems="center"
            w={1}
          >
            <StatBox value={<Numeral value={totalMembers} format="0a+" />} label="MEMBERS*" />
            <div style={{ height: '100px', background: '#FFF', width: '1px' }} />
            <StatBox value={<Numeral value={investmentsMade} format="0,0+" />} label="INVESTMENTS MADE*" />
          </ExtendedFlex>
          <ExtendedFlex
            justifyContent="center"
            alignItems="center"
            w={1}
            mt={60}
          >
            <StatBox value={<Numeral value={totalAmountFinanced} format="$0,0a+" />} label="RAISED*" />
            <div style={{ height: '100px', background: '#FFF', width: '1px' }} />
            <StatBox prefix=">" value={<Numeral value={totalPropertyValueFinanced} format="0.0a" />} label="IN REAL ESTATE NATIONWIDE*" />
          </ExtendedFlex>
          <Body
            type="B5"
            mt={40}
            color="white"
            display="block"
          >
            {sinceInceptionDisclaimer}
          </Body>
        </ExtendedFlex>
      </ExtendedBox>
    </ExtendedFlex>
  );
};

const mapStateToProps = (state) => ({
  totalMembers: state.tokens.totalMembers,
  investmentsMade: state.tokens.investmentsMade,
  totalAmountFinanced: state.tokens.totalAmountFinanced,
  totalPropertyValueFinanced: state.tokens.totalPropertyValueFinanced,
  sinceInceptionDisclaimer: state.tokens.sinceInceptionDisclaimer,
});

RightOpportunities.propTypes = {
  isLoggedIn: PropTypes.bool,
  totalMembers: PropTypes.string,
  investmentsMade: PropTypes.string,
  totalAmountFinanced: PropTypes.string,
  totalPropertyValueFinanced: PropTypes.string,
  sinceInceptionDisclaimer: PropTypes.string,
};

export default connect(mapStateToProps)(RightOpportunities);
