import styled from 'styled-components';

const Styled = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font: normal normal 400 15px/28px var(--font-sans);
    letter-spacing: 0px;
    color: #202020;
    button {
      max-width: 100%;
    }
    .inner-container {
      background: #FFF;
      padding: 30px 30px 0;
    }
  `,

  Questions: styled.div`
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    background: #F7F8F8;
    margin-top: 18px;
    font: normal normal 400 12px/20px var(--font-sans);
    letter-spacing: 0;
    color: #606060;
    .questions-link {
      color: #176DB6;
      font: normal normal 500 12px/20px var(--font-sans);
      text-decoration: none;
      margin-left: 10px;
    }
  `,
};

export default Styled;
