/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { ReitTabs } from 'rtmg-component-library';
import DLIcon from '../../assets/icon_download.svg';

const Wrapper = styled.div`
`;

const StyledPDFItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-bottom: 18px;

  font: normal normal 400 16px/28px var(--font-sans);
  letter-spacing: 0px;
  color: #323232;

  .spacer {
    flex: 1;
    margin: 0 8px;
    height: 24px;
    border-bottom: 1px dashed #D1D1D1;
  }
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    font: normal normal 500 14px/28px var(--font-sans);
    letter-spacing: 1.68px;
    color: #176DB6;
  }
  img {
    margin-left: 4px;
    width: 20px;
  }
`;

const PDFItem = ({
  label,
  link,
}) => (
  <StyledPDFItem>
    <span>{label}</span>
    <div className="spacer" />
    <a href={link} target="_blank" rel="noopener noreferrer">DOWNLOAD <img src={DLIcon} alt="download PDF" /></a>
  </StyledPDFItem>
);

const Slide = styled.div`
  .title {
    font: normal normal 500 14px/28px var(--font-sans);
    letter-spacing: 3.36px;
    color: #176DB6;
    margin-bottom: 15px;
    &:not(:first-child) {
      margin-top: 25px;
    }
  }
`;

const OFFERING_DOCUMENTS = ({
  mr1Data = [],
  mr2Data = [],
  generalData = [],
}) => (
  <Slide>
    <div className="title">THE INCOME REIT</div>
    <div className="pdf-items">
      {
        mr1Data.map((item) => (<PDFItem label={item.title} link={item.url} />))
      }
    </div>
    <div className="title">THE APARTMENT GROWTH REIT</div>
    <div className="pdf-items">
      {
        mr2Data.map((item) => (<PDFItem label={item.title} link={item.url} />))
      }
    </div>
    {
      generalData.length > 0 &&
      <>
        <div className="title">GENERAL INFORMATION</div>
        <div className="pdf-items">
          {
            generalData.map((item) => (<PDFItem label={item.title} link={item.url} />))
          }
        </div>
      </>
    }
  </Slide>
);

const DisclaimersWrapper = styled.div`
  text-align: left;
  font: normal normal 300 16px/28px var(--font-sans);
  letter-spacing: 0px;
  color: #202020;
  li {
    padding-left: 10px;
    &::marker {
      display: inline-block;
      content: '⚬';
      font-size: 20px;
    }
  }
`;

const Disclaimers = () => (
  <DisclaimersWrapper>
    <p>Investing in the Company&apos;s common shares is speculative and involves substantial risks. The Company cannot assure you that it will attain its objectives or that the value of its assets will not decrease. Therefore, you should purchase these securities only if you can afford a complete loss of your investment.</p>
    <p>You should carefully review the “Risk Factors” section of this offering circular, beginning on page 26, which contains a detailed discussion of the material risks that you should consider before you invest in our common shares. These risks include the following:</p>

    <ul>
      <li>The RealtyMogul Income REIT has limited operating history.</li>
      <li>Because no public trading market for shares of our common stock currently exists, it will be difficult for an investor to sell their shares and, if an investor is able to sell their shares, they will likely sell them at a substantial discount to the public offering price.</li>
      <li>We may be unable to pay or maintain cash distributions or increase distributions over time.</li>
      <li>The REIT&apos;s ability to implement its investment strategy is dependent, in part, upon its ability to successfully conduct this offering through the Realty Mogul Platform, which makes an investment in it more speculative.</li>
      <li>Future disruptions in the financial markets or deteriorating economic conditions could adversely impact the commercial real estate market as well as the market for debt-related investments generally, which could hinder our ability to implement our business strategy and generate returns to you.</li>
      <li>This is a blind pool offering, and the REIT is not committed to acquiring any particular investments with the net proceeds of this offering.</li>
      <li>There are conflicts of interest between the REIT, its Manager and its affiliates.</li>
      <li>Our investments may be concentrated and will be subject to risk of default.</li>
      <li>We are dependent on our Manager and Realty Mogul, Co.&apos;s key personnel for our success.</li>
      <li>Failure to qualify as a REIT would cause the Company to be taxed as a regular corporation, which would substantially reduce funds available for distributions to our shareholders.</li>
      <li>The REIT may allocate the net proceeds from this offering to investments with which you may not agree.</li>
    </ul>
    <p>Note: The foregoing statements may contain forward-looking statements and are based on our current expectations, plans, estimates, assumptions and beliefs that involve numerous risks and uncertainties.  Although we believe that the expectations reflected in such forward-looking statements are based on reasonable assumptions, actual results and performance could differ materially from those set forth in the forward-looking statements.</p>
  </DisclaimersWrapper>
);

export const Resources = ({
  resources = [],
}) => {
  return (
    <Wrapper>
      {
        resources.length > 0 &&
        <ReitTabs
          title="Resources"
          background="#F7F8F8"
          tabs={[
            {
              title: 'Offering Documents',
              content: <OFFERING_DOCUMENTS mr1Data={resources[0].investmentDocuments} mr2Data={resources[1].investmentDocuments} />,
            },
            {
              title: 'Reports',
              content: <OFFERING_DOCUMENTS mr1Data={resources[0].investmentDocumentsForms} mr2Data={resources[1].investmentDocumentsForms} />,
            },
            {
              title: 'Other',
              content: <OFFERING_DOCUMENTS mr1Data={resources[0].investmentDocumentsOther.filter((item) => item.title.includes('Income'))} mr2Data={resources[1].investmentDocumentsOther.filter((item) => item.title.includes('Apartment Growth'))} generalData={resources[1].investmentDocumentsOther.filter((item) => !item.title.includes('Apartment Growth'))} />,
            },
            {
              title: 'Disclaimers',
              content: <Disclaimers />,
            },
          ]}
        />
      }
    </Wrapper>
  );
};

export default Resources;
