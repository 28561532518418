import React from 'react';

import ArticleCardHeadline from '../components/ArticleCardHeadline';

const section2FactoryFactory = (props) => {
  const {
    title,
    url,
  } = props;

  const section2Factory = () => {
    return (
      <ArticleCardHeadline
        title={title}
        url={url}
      />
    );
  };

  return section2Factory;
};

export default section2FactoryFactory;
