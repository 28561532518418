/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedFlex,
  Data,
} from '../../coreui';

export const Step = ({
  children,
  active,
  count,
  ...props
}) => (
  <ExtendedFlex
    flex="0 0 auto"
    align="center"
    flexDirection={['column-reverse', null, 'row']}
    {...props}
  >
    <ExtendedFlex
      border={[0, null, 1]}
      borderColor="currentColor"
      borderRadius="50%"
      align="center"
      justify="center"
      w={[10, null, 30]}
      height={[10, null, 30]}
      color="gray.10"
      ml={[null, null, 5]}
      mr={[null, null, 10]}
      {...(active ? {
        color: 'white',
        bg: 'blue.0',
      } : {
        bg: ['gray.12', null, 'transparent'],
      })}
    >
      <Data
        type="D4"
        color="inherit"
        m={0}
        {...(count < 10 ? {
          letterSpacing: 0,
        } : {})}
        display={['none', null, 'block']}
      >
        {count}
      </Data>
    </ExtendedFlex>
    <Data
      type="D4"
      m={0}
      letterSpacing={3}
      display={['none', null, 'block']}
      {...(active ? {
        color: 'blue.0',
      } : {})}
    >
      {children}
    </Data>
  </ExtendedFlex>
);

Step.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  count: PropTypes.number,
};

export default Step;
