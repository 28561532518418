import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  Box,
  ExtendedFlex,
  Heading,
  Body,
  VisibilitySensor,
  Link,
} from '../../../coreui';

import backgroundImage from '../../../assets/HP_more_boat.jpg';
import v1Img from '../../../assets/v1-sub.png';
import v2Img from '../../../assets/v2-sub.jpg';
import FadeIn from '../../../components/FadeIn';

const InvestmentPlatformSection1 = ({
  handleSensorChange,
  isVisible,
  variant,
}) => {
  const [strings, setStrings] = useState({
    heading: 'You Deserve More Than Just an Investment Platform.',
    subHeading: 'Join RealtyMogul and Navigate Through Uncertain Markets.',
    image: backgroundImage,
  });

  useEffect(() => {
    if (variant !== 'none') {
      setStrings((oldStrings) => {
        return {
          ...oldStrings,
          heading: 'Your personal real estate investment pipeline',
          subHeading: 'Create an account, easily review and compare institutional-quality real estate deals and invest in the ones that meet your criteria.',
          image: variant === 'v1' ? v1Img : v2Img,
        };
      });
    }
  }, [setStrings, variant]);

  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
    >
      <ExtendedBox
        w={1}
        bgRepeat="no-repeat"
        bgImage={strings.image}
        bgPosition={variant === 'v1' ? '0 100%' : '50% 26%'}
        bgSize={variant === 'v1' ? '75%' : 'cover'}
      >
        <Box
          w={1}
          px={['15%', '25%', '10%', '10%', '10%']}
        >
          <ExtendedFlex
            flexDirection="column"
            align={['center', 'center', 'flex-end', 'flex-end', 'flex-end']}
          >
            <ExtendedFlex
              maxWidth={['100%', '100%', 400, 400, 540]}
              flexDirection="column"
              my={[60, 60, 100, 100, 100]}
            >
              <FadeIn>
                <Heading
                  type="H2"
                  color={variant === 'v1' ? 'black' : 'white'}
                  m={0}
                  mb="1em"
                  opacity={isVisible ? '1' : '0'}
                >
                  {strings.heading}
                </Heading>
              </FadeIn>
              <VisibilitySensor
                callback={handleSensorChange}
              />
              <FadeIn
                fadeInIndex={2}
              >
                <Body
                  type="B1"
                  color={variant === 'v1' ? 'black' : 'white'}
                  fontWeight="light"
                  mb={[200, 200, 360, 360, 440]}
                  opacity={isVisible ? '1' : '0'}
                >
                  {strings.subHeading}
                </Body>
                <ExtendedBox
                  opacity={isVisible ? '1' : '0'}
                >
                  <Link type="L1" to="/investment-opportunities">
                    View Investments
                  </Link>
                </ExtendedBox>
              </FadeIn>
            </ExtendedFlex>
          </ExtendedFlex>
        </Box>
      </ExtendedBox>
    </Box>
  );
};

InvestmentPlatformSection1.propTypes = {
  handleSensorChange: PropTypes.func,
  isVisible: PropTypes.bool,
  variant: PropTypes.string,
};

export default InvestmentPlatformSection1;
