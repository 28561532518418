export default {
  mr1: {
    freq: 'The frequency with which you can expect to receive distributions.  Distributions may be automatically reinvested or deposited to a bank account. Distributions are not guaranteed.',
    min: 'Minimum for non-retirement accounts.  The minimum for retirement accounts is $5,000.  You have the option to add in increments of $1,000 or greater to your initial investment.',
    type: 'All U.S. based, commercial real estate properties.',
    struct: 'This is a public, non-exchange traded REIT.  REITs generate one Form 1099-DIV at the end of each year for tax purposes.',
    propertyType: 'Diverse',
  },
  mr2: {
    freq: 'The frequency with which you can expect to receive distributions. Distributions may be automatically reinvested or deposited to a bank account. Distributions are not guaranteed.',
    min: 'Minimum for non-retirement accounts. The minimum for retirement accounts is $5,000. You have the option to add in increments of $1,000 or greater to your initial investment.',
    type: 'All U.S. based, commercial real estate properties.',
    struct: 'This is a public, non-exchange traded REIT.  REITs generate one Form 1099-DIV at the end of each year for tax purposes.',
    propertyType: 'Multifamily',
  },
};
