import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import RMButton from '../RMButton';
import Styled from './index.styled';

const StartWidget = ({
  title = 'Start Your Investment Now',
  items = [],
  oldInvestLink = '',
  id,
  buttonLabel = 'GET STARTED',
  loggedInButtonLabel,
  isLoggedIn = false,
  cookies,
  history,
}) => {
  const emailRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await cookies.set('rm_captured_email', emailRef.current.value, { maxAge: 3600, path: '/' });
      await cookies.set('rm_io_id', id, { maxAge: 3600, path: '/' });
      history.push('/user/register');
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  };

  return (
    <Styled.StartWidget>
      <h2>{title}</h2>
      <Styled.StartWidgetStats>
        {items.map((item) => (
          <Styled.StatItem>
            <span>{cookies.rm_captured_email}</span>
            <span>{item.label}</span>
            <div className="spacer" />
            <span>{item.value}</span>
          </Styled.StatItem>
        ))}
      </Styled.StartWidgetStats>
      <Styled.StartWidgetCTA isLoggedIn={isLoggedIn}>
        {
          !isLoggedIn ?
            <form onSubmit={handleSubmit}>
              <input
                ref={emailRef}
                type="email"
                placeholder="Please enter email address"
                required
              />
              <RMButton type="submit">{buttonLabel}</RMButton>
            </form>
            :
            <RMButton href={oldInvestLink}>{loggedInButtonLabel}</RMButton>
        }
      </Styled.StartWidgetCTA>
      {
        !isLoggedIn &&
        <Styled.StartWidgetDisclaimer>
          By continuing, you accept and agree to our <a href="/terms-service">Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a>.
        </Styled.StartWidgetDisclaimer>
      }
    </Styled.StartWidget>
  );
};

StartWidget.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  oldInvestLink: PropTypes.string,
  buttonLabel: PropTypes.string,
  loggedInButtonLabel: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  cookies: PropTypes.object,
  history: PropTypes.object,
  id: PropTypes.number,
};

export default withCookies(withRouter(StartWidget));
