import React from 'react';

import {
  Redirect,
} from 'react-router-dom';

import {
  investmentsUrl,
} from '../links';

const Redirects = () => {
  return (
    <>
      <Redirect
        from="/investor-dashboard"
        to={investmentsUrl}
      />
    </>
  );
};

export default Redirects;
