import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import {
  Body,
  ExtendedFlex,
  Heading,
  InlineTextLink,
  Text,
  Tooltip,
  TooltipIcon,
  Formatters,
} from '../../coreui';

function dateFormat(inputDate) {
  const date = new Date(inputDate);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

const Row = ({ label, value, tooltip }) => (
  <ExtendedFlex
    flexDirection="row"
    justifyContent="space-between"
  >
    <Heading type="DashboardLabel" my={6}>
      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
        {label}
        {tooltip &&
          <div style={{ marginLeft: '6px' }} >
            <TooltipIcon
              tooltipId={`${label}-tooltip`}
            />
            <Tooltip id={`${label}-tooltip`}>
              <Body type="B5">
                {tooltip}
              </Body>
            </Tooltip>
          </div>}
      </span>
    </Heading>
    <Heading type="DashboardLabel" my={6}>{value}</Heading>
  </ExtendedFlex>
);

Row.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  tooltip: PropTypes.string,
};

const IOCardCompleted = ({ data }) => {
  return (
    <ExtendedFlex
      flexDirection={['column', 'column', 'row', 'row', 'row']}
      w={1}
      mx="auto"
      maxWidth={960}
      my={15}
      border="1px solid #C9C9C9"
      borderRadius="5px"
    >
      <ExtendedFlex
        w={['100%', '100%', '250px', '250px', '250px']}
        minWidth={['100%', '100%', '250px', '250px', '250px']}
        position="relative"
      >
        <img src={data.image} alt={data.title} style={{ width: '100%', objectFit: 'cover' }} />
      </ExtendedFlex>
      <ExtendedFlex
        flexDirection={['column', 'row']}
        w={1}
        mt={[24, 0]}
        justifyContent="space-between"
      >
        <ExtendedFlex
          flexDirection="column"
          w={1}
          px={24}
          justifyContent="center"
        >
          <Text fontFamily="serif" fontSize={[14, 14, 16, 18, 20]} my={6}>{data.propertyType}</Text>
          <Text fontFamily="serif" fontSize={[14, 14, 16, 18, 20]} my={6}>{data.city}, {data.state}</Text>
          <InlineTextLink fontSize={[14, 14, 16, 18, 20]} my={6} to={`/invested/${data.nid}`}>
            <div style={{ margin: '6px 0' }}>{data.title}</div>
          </InlineTextLink>
        </ExtendedFlex>
        <ExtendedFlex
          flexDirection="column"
          w={1}
          px={24}
          py={6}
          justifyContent="center"
        >
          <Row label="Actual IRR / Return" value={Number(data.annualizedReturn) === 0 ? '' : `${(data.annualizedReturn * 100).toFixed(1)}%`} tooltip="The return figure represents the offering's annualized return. Your investment's annualized return may be slightly higher or lower depending on the timing of your investment and other factors." />
          <Row label="Accrual Date" value={dateFormat(data.accrualDate)} />
          <Row label="Maturity Date" value={dateFormat(data.maturityDate)} tooltip="The date of sale for the property." />
          <Row label="Invested Amount" value={numeral(data.totalInvestmentAmount).format(Formatters.DOLLARS_DECIMALS)} />
          <Row label="Total Returned" value={numeral(data.totalDistributionsPaid).format(Formatters.DOLLARS_DECIMALS)} />
        </ExtendedFlex>
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

export default IOCardCompleted;

IOCardCompleted.propTypes = {
  data: PropTypes.object,
};
