import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Heading, Body } from '../../coreui';
import Select from '../forms/Select';
import SliderMoney from '../SliderMoney';
import Label from '../forms/Label';
import Input from '../forms/Input';

import {
  incomeOptions,
  netWorthOptions,
  sliderMapping,
  netWorthSpecificPropTypeValidation,
  getDefaultNetWorthSpecificValue,
} from './utility';

class IncomeNetWorth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      netWorthSpecific: undefined,
    };
  }

  componentWillUnmount() {
    const {
      netWorthSpecific,
    } = this.state;

    // We need to update the parent on the new default value.
    if (netWorthSpecific === undefined && !this.props.netWorthSpecific) {
      const defaultNetWorthSpecific = getDefaultNetWorthSpecificValue(this.props);
      if (defaultNetWorthSpecific) {
        this.props.handleChange({
          target: {
            name: 'netWorthSpecific',
            value: defaultNetWorthSpecific,
            type: 'range',
          },
        });
      }
    }
  }

  render() {
    const {
      handleChange,
      annualIncome,
      netWorth,
      netWorthSpecific,
      sourceOfWealth,
      sliderMapping: {
        [this.props.netWorth]: {
          min = null,
          max = null,
          step = 1000,
        } = {},
      },
    } = this.props;

    const defaultNetWorthSpecificValue = getDefaultNetWorthSpecificValue(this.props);

    return (
      <>
        <Heading
          type="H6"
          textTransform="uppercase"
        >
          What is your current income?
        </Heading>
        <Select
          name="annualIncome"
          value={annualIncome}
          onChange={handleChange}
          placeholder="Select income"
          required
          showEmpty={!annualIncome}
          options={incomeOptions}
        />
        <Heading
          type="H6"
          textTransform="uppercase"
          mt={50}
        >
          What is your current net worth?
        </Heading>
        <Select
          name="netWorth"
          value={netWorth}
          onChange={handleChange}
          placeholder="Select net worth"
          showEmpty={!netWorth}
          required
          options={netWorthOptions}
        />
        {
          min !== null &&
          <>
            <Heading
              type="H6"
              textTransform="uppercase"
              mt={50}
            >
              Please specify your net worth within this range:
            </Heading>
            <SliderMoney
              name="netWorthSpecific"
              key={`slider-money-${min}-${max}`}
              min={min}
              max={max}
              step={step}
              value={parseInt(netWorthSpecific || defaultNetWorthSpecificValue, 10) || undefined}
              handleChange={(e) => {
                this.setState({
                  netWorthSpecific: e.target.value,
                });
                return handleChange(e);
              }}
            />
          </>
        }
        <Heading
          type="H6"
          textTransform="uppercase"
          mt={50}
        >
          Source of wealth
        </Heading>
        <Body
          type="B2"
        >
          What is the largest contributor to your net worth? Common sources of
          wealth include savings, company sale, or inheritance. This information
          helps us to confirm your status as an accredited investor.
        </Body>
        <Label
          htmlFor="sourceOfWealth"
        >
          Source of Wealth
          <Input
            id="sourceOfWealth"
            placeholder="Source of Wealth"
            name="sourceOfWealth"
            onChange={handleChange}
            data-test="sourceOfWealth"
            maxLength="80"
            value={sourceOfWealth || ''}
            required
          />
        </Label>
      </>
    );
  }
}

IncomeNetWorth.propTypes = {
  handleChange: PropTypes.func,
  annualIncome: PropTypes.oneOf(incomeOptions.reduce((accum, option) => {
    return [
      ...accum,
      option.value,
    ];
  }, [''])),
  netWorth: PropTypes.oneOf(netWorthOptions.reduce((accum, option) => {
    return [
      ...accum,
      option.value,
    ];
  }, [''])),
  netWorthSpecific: netWorthSpecificPropTypeValidation,
  sliderMapping: PropTypes.object,
  sourceOfWealth: PropTypes.string,
};

IncomeNetWorth.defaultProps = {
  sliderMapping,
};

export default IncomeNetWorth;
