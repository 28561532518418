import React, { useState, useEffect } from 'react';

import DrupalWrapper from './DrupalWrapper';
import InvestmentQualification from '../../isq/components/InvestmentQualification';

const InvestFlowISQDrupalWrapper = () => {
  const [timeHorizon, setTimeHorizon] = useState(null);
  const [over20PctFollowUpTriggerAmount, setOver20PctFollowUpTriggerAmount] = useState(null);
  const [ir, setIrId] = useState(null);

  const drupal = typeof window !== 'undefined' && window?.Drupal;
  useEffect(() => {
    if (drupal) {
      // Get the initial setting from Drupal.
      // eslint-disable-next-line camelcase
      const passedTimeHorizon = drupal?.settings?.rm_invest_flow?.isq_time_horizon;
      setTimeHorizon(passedTimeHorizon);
    }
  }, [drupal, setTimeHorizon]);
  useEffect(() => {
    if (drupal) {
      // eslint-disable-next-line camelcase
      const value = drupal?.settings?.rm_invest_flow?.isq_20_pct_trigger_amount;
      setOver20PctFollowUpTriggerAmount(parseInt(value, 10));
    }
  }, [drupal, setOver20PctFollowUpTriggerAmount]);
  useEffect(() => {
    if (drupal) {
      // eslint-disable-next-line camelcase
      const value = drupal?.settings?.rm_invest_flow?.ir_id;
      setIrId(parseInt(value, 10));
    }
  }, [drupal, setIrId]);

  //
  return (
    <DrupalWrapper drupalPortalId="isq_portal_id">
      <InvestmentQualification
        ir={ir}
        showHeader
        showSubheader
        showBackButton
        timeHorizon={timeHorizon}
        over20PctFollowUpTriggerAmount={over20PctFollowUpTriggerAmount}
        cancelText="Cancel investment"
        cancelHeader="Are you sure you want to cancel your investment?"
        cancelBody="Your progress will be lost and your investment will be canceled."
      />
    </DrupalWrapper>
  );
};

export default InvestFlowISQDrupalWrapper;
