import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import { getUserSessionApi } from '../../api';

export function* getUserSession(action) {
  try {
    const {
      data: {
        accessToken,
        idToken,
        refreshToken,
        ...data
      },
    } = yield call(getUserSessionApi);
    // Verify we got back tokens.
    if (!(accessToken && idToken && refreshToken)) {
      throw new Error('Not authorized');
    }
    if (typeof action.callback === 'function') {
      action.callback({
        mail: data.mail,
        accessToken,
        idToken,
        refreshToken,
      });
    }
    yield put(
      actions.userSessionSucceeded(data),
    );
  } catch (error) {
    if (typeof action.callback === 'function') {
      action.callback();
    }
    yield put(
      actions.userSessionFailed(error),
    );
  }
}

export function* watchGetUserSession() {
  yield takeLatest(
    types.USER_SESSION_REQUESTED,
    getUserSession,
  );
}

export default watchGetUserSession;
