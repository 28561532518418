/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import animateScrollTo from 'animated-scroll-to';
import { injectGlobal } from 'styled-components';

import {
  StyledButton,
  ExtendedBox,
  ExtendedFlex,
  Body,
} from '../../coreui';

import SmartButton from '../SmartButton';

export const navCSS = injectGlobal`
  .responsiveNav {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
`;

class SubNav extends React.Component {
  constructor(props) {
    super(props);
    this.stickyStyles = {};
    this.state = {
      active: null,
    };
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    // Determine if subnav should be sticky.  If so, compute offset.
    const menu = window.document.getElementsByClassName('headroom')[0];
    if (menu) {
      // eslint-disable-next-line
      const menuEl = ReactDOM.findDOMNode(menu);
      // eslint-disable-next-line
      const containerEl = ReactDOM.findDOMNode(this._container);
      if (containerEl.getBoundingClientRect().top < menuEl.getBoundingClientRect().height) {
        let menuOffset = menuEl.getBoundingClientRect().top
          + menuEl.getBoundingClientRect().height;
        menuOffset = (menuOffset > 0) ? menuOffset : 0;
        this.stickyStyles = {
          position: 'fixed',
          top: menuOffset,
          zIndex: 11,
        };
      } else {
        this.stickyStyles = {
          position: 'relative',
          top: 0,
          zIndex: 11,
        };
      }
    }
    // Set active item
    if (this.props.items[0]) {
      const activeItem = this.props.items.reduce((accumItem, currentItem) => {
        const activeEl = document.getElementsByName(accumItem.name)[0];
        const currentEl = document.getElementsByName(currentItem.name)[0];
        const activeElOffset = (activeEl) ? activeEl.getBoundingClientRect().top : 0;
        const currentElOffset = (currentEl) ? currentEl.getBoundingClientRect().top : 0;
        // eslint-disable-next-line
        const subNavEL =  ReactDOM.findDOMNode(this._nav);
        const subNavHeight = subNavEL.getBoundingClientRect().height;

        let activeItem = accumItem;
        if (currentElOffset < subNavHeight && currentElOffset > activeElOffset) {
          activeItem = currentItem;
        }
        return activeItem;
      }, this.props.items[0]);
      this.setState({ active: activeItem });
    }
  }

  render() {
    const {
      items,
      smartButton,
      label,
      newStyle = false,
    } = this.props;
    const {
      active,
    } = this.state;

    const chooseColor = (newStyle, isActive) => {
      if (newStyle) {
        return isActive ? '#202020' : '#176DB6';
      }
      return isActive ? 'white' : 'gray.10';
    };

    return (
      <ExtendedBox
        position="relative"
        ref={(el) => { this._container = el; }}
        display={['none', 'none', 'block']}
      >
        <ExtendedBox
          bg={newStyle ? '#F7F8F8' : 'black'}
          margin="auto"
          w={1}
          ref={(el) => { this._nav = el; }}
          style={this.stickyStyles}
          textAlign="center"
        >
          <ExtendedFlex
            flexDirection="row"
            maxWidth="1920px"
            mx="auto"
          >
            { label &&
              <IOTitle label={label} /> }
            <ExtendedFlex
              style={{
                maxWidth: '1100px',
              }}
              flexDirection="row"
              justifyContent="center"
              align="center"
              m="auto"
              height="100%"
            >
              {
                items &&
                items.map((item) => {
                  let currentlyActive = false;
                  if (item === active) {
                    currentlyActive = true;
                  }
                  return (
                    <StyledButton
                      key={item.name}
                      type="L1"
                      style={{
                        minWidth: 'auto',
                        width: 'auto',
                        maxWidth: 'auto',
                        whiteSpace: 'nowrap',
                      }}
                      mb={0}
                      color={chooseColor(newStyle, currentlyActive)}
                      bg={newStyle ? '#F7F8F8' : 'black'}
                      textTransform="uppercase"
                      to={null}
                      className={(item.responsiveHide) ? 'responsiveNav' : null}
                      hoverColor={newStyle ? '#1D89FF' : 'white'}
                      hoverBackgroundColor={newStyle ? '#F7F8F8' : 'black'}
                      borderTop={newStyle ? null : currentlyActive ? '3px solid white' : '3px solid #202020'}
                      transition="border 500ms ease-out"
                      onClick={() => {
                        const el = document.getElementsByName(item.name)[0];
                        // eslint-disable-next-line
                        const navEl = ReactDOM.findDOMNode(this._nav);
                        const offSet = 1 - navEl.getBoundingClientRect().height;
                        const opts = {
                          onComplete: () => {
                            this.onScroll();
                          },
                          passive: true,
                          offset: offSet,
                        };
                        animateScrollTo(el, opts);
                      }}
                    >
                      {item.title}
                    </StyledButton>
                  );
                })
              }
            </ExtendedFlex>
            <ExtendedFlex>
              {
                smartButton &&
                <SmartButton
                  type="L1"
                  label={smartButton.button_text}
                  buttonHref={smartButton.button_href}
                  my={0}
                  ml="auto"
                  height="100%"
                  lineHeight="1.8em"
                />
              }
            </ExtendedFlex>
          </ExtendedFlex>
        </ExtendedBox>
      </ExtendedBox>
    );
  }
}

const IOTitle = ({
  label,
  ...passedProps
}) => {
  if (label) {
    return (
      <ExtendedFlex
        flexDirection="row"
        alignItems="center"
        alignSelf="center"
      >
        <ExtendedBox
          mr="auto"
          ml={[0, 0, 5, 20, 40]}
          height="100%"
          display={['none', 'none', 'none', 'flex', 'flex']}
          {...passedProps}
        >
          <Body
            type="B4"
            color="white"
            my={0}
          >
            {label}
          </Body>
        </ExtendedBox>
        <ExtendedBox
          mx={[0, 0, 5, 20, 40]}
          display={['none', 'none', 'none', 'flex', 'flex']}
          width="1px"
          minHeight="46px"
          bg="gray.7"
          borderTop="3px solid"
          borderBottom="3px solid"
          borderColor="gray.7"
        />
      </ExtendedFlex>
    );
  }
  return null;
};

IOTitle.propTypes = {
  label: PropTypes.string,
};

SubNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  smartButton: PropTypes.object,
  label: PropTypes.string,
  newStyle: PropTypes.bool,
};

SubNav.defaultProps = {
  items: [],
  smartButton: null,
  label: null,
};

export default SubNav;
