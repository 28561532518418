import styled, { css } from 'styled-components';

const styles = {
  Primary: css`
    color: #fff;
    border: 2px solid var(--color-rm-main);
    background-color: var(--color-rm-main);
  `,
  Secondary: css`
    color: var(--color-rm-main);
    border: 2px solid var(--color-rm-main);
    background-color: transparent;
  `,
};

const hoverStyles = {
  Primary: css`
    color: #fff;
    background-color: var(--color-rm-secondary);
  `,
  Secondary: css`
    color: #fff;
    border: 2px solid var(--color-rm-secondary);
    background-color: var(--color-rm-secondary);
  `,
};

const ButtonStyles = css`
  display: inline-flex;
  height: var(--min-tap-target-height);
  padding: 0 30px;
  box-sizing: border-box;
  font-family: var(--font-sans);
  font-weight: 500;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  letter-spacing: 3px;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: all 150ms cubic-bezier(0.335, 0.015, 0.46, 0.995);
  cursor: pointer;
  text-transform: uppercase;
  ${(props) => styles[props.styleType] || styles.Primary}
  ${({ width }) => `width: ${width || '100%'};`}

  @media (min-width: 992px) {
    letter-spacing: 3px;
    &:hover {
      ${(props) => hoverStyles[props.styleType] || hoverStyles.Primary}
    }
  }
`;

export const StyledButton = (component) => styled(component)`
    ${ButtonStyles}
  `;

export default {
  A: styled.a`
    ${ButtonStyles}
  `,
  Button: styled.button`
    ${ButtonStyles}
  `,
};
