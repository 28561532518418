import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  Hero,
  ExtendedBox,
  UnlockOpportunities,
  ScrollToTopOnMount,
} from '../coreui';

import DynamicBrowseJoinLink from '../helpers';
import SubNav from '../components/subNav';

import Reits from './components/reits';
import MeetReits from './components/meetReits';
import PrivatePlacement from './components/pp';
import Section from '../ioDetails/components/section';
import Overview from './components/overview';
import RecentInvestments from '../homepage/recentInvestments';
import ScrollToHash from '../components/ScrollToHash';
import browseActions from '../browse/browsePage/actions';

import heroBannerImage from '../assets/Img_hero_investOpts.jpg';
import data from './data';

class InvestmentOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { title: 'Overview', name: 'overview' },
        { title: 'REITs', name: 'reits' },
        { title: 'Individual Properties', name: 'pp' },
      ],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getBrowsePageDataRequested();
  }

  render() {
    const {
      user,
      reitData,
      recentInvestmentsData,
    } = this.props;

    const isLoggedIn = user.isLoggedIn;

    const BrowseLink = () => {
      return (
        <ExtendedBox
          textAlign="center"
          mx="auto"
          mt={[25, 50, 75, 100, 100]}
        >
          <DynamicBrowseJoinLink
            isLoggedIn={isLoggedIn}
            anonText="Get Started"
            type="L1"
          />
        </ExtendedBox>
      );
    };

    return (
      <>
        <ScrollToTopOnMount />
        <SubNav
          items={this.state.navItems}
        />
        <Hero
          image={heroBannerImage}
          header={data.hero.header}
          subHeader={data.hero.subheader}
        />
        <Section
          title={data.overview.title}
          bg="gray.0"
          name="overview"
        >
          <Overview
            title={null}
            subtitle={data.overview.subtitle}
            items={data.tools}
          />
          <BrowseLink />
        </Section>
        <span name="reits" />
        <ScrollToHash name="reits" />
        <Reits />
        <Section
          title={data.meet.title}
          bg="gray.0"
        >
          <MeetReits
            body={data.meet.body}
            bottomText={data.meet.bottom}
            investments={reitData}
          />
        </Section>
        <span name="pp" />
        <ScrollToHash name="pp" />
        <PrivatePlacement />
        <RecentInvestments
          isLoggedIn={isLoggedIn}
          title="Recent Individual Properties"
          recentInvestmentsData={recentInvestmentsData}
          subtitle="A SAMPLE OF THE INVESTMENTS WE’VE MADE"
          smartBtn
        />
        <UnlockOpportunities
          isLoggedIn={isLoggedIn}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user,
  reitData: state.reitData.list,
  recentInvestmentsData: _.orderBy(state.pastInvestments.completedEquityInvestments, ({ actualMaturityDate }) => actualMaturityDate || '', ['desc']).slice(0, 8),
});

const mapDispatchToProps = (dispatch) => ({
  getBrowsePageDataRequested: () => {
    dispatch(browseActions.getBrowseClosedDataRequested());
  },
});

InvestmentOptions.propTypes = {
  user: PropTypes.object,
  reitData: PropTypes.array,
  recentInvestmentsData: PropTypes.array,
  getBrowsePageDataRequested: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentOptions);
