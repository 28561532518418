/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedBox,
  TextInput,
  Image,
  Theme,
} from '..';

import {
  types,
} from './Body';

import image from '../assets/icon_search.svg';

const Search = ({
  placeholder,
  value,
  mt,
  handleChange,
  onKeyDown,
  iconOnClick,
}) => {
  return (
    <ExtendedBox
      position="relative"
      w={[1, 400, 400, 600]}
      mt={mt}
    >
      <ExtendedBox
        position="absolute"
        right="10px"
        top="6px"
        {...(iconOnClick ? { onClick: iconOnClick } : {})}
      >
        <Image
          src={image}
          {...(iconOnClick ? { hover: true } : {})}
        />
      </ExtendedBox>
      <TextInput
        {...types.B1}
        data-test="search"
        fontSize={18}
        lineHeight={1}
        value={value}
        placeholder={placeholder}
        placeholderColor="gray.10"
        fontFamily={Theme.fontFamily.sansSerif}
        textIndent="15px"
        pr={30}
        bg="transparent"
        border={0}
        borderBottom={1}
        borderColor="gray.8"
        borderRadius={0}
        w={1}
        onChange={handleChange}
        {...(onKeyDown ? { onKeyDown } : {})}
      />
    </ExtendedBox>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  mt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  handleChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  iconOnClick: PropTypes.func,
};

export default Search;
