import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';

import {
  InlineTextLink,
  Link,
} from '../../coreui';

import StatusBars from './StatusBars';
import DashboardTableCellWrapper from './DashboardTableCellWrapper';

const DashboardTableCell = (formatter) => {
  const Component = (props) => {
    let value;
    const containerProps = {};
    const bodyProps = {};
    if (props.column.id === 'date' || props.column.id === 'closeOfEscrowDate') {
      value = formatter ? moment(props.value, 'YYYY-MM-DD').format(formatter) : props.value;
    } else if (props.column.id === 'nextDistribution') {
      value = (formatter && props.value) ? moment(props.value, 'YYYY-MM-DD').format(formatter) : 'TBD';
    } else if (props.column.id === 'title') {
      value = <InlineTextLink to={`/invested/${props.original.nid}`}>{props.value}</InlineTextLink>;
    } else if (props.column.id === 'investmentUrl') {
      value = (
        <InlineTextLink to={props.original.investmentUrl}>{props.value}</InlineTextLink>
      );
    } else if (props.column.id === 'pendingAmount') {
      value = formatter ? numeral(props.value).format(formatter) : props.value;
      if (props.original.pending === true) {
        bodyProps.color = 'gray.10';
      }
    } else if (props.column.id === 'amount' || props.column.id === 'totalReturned') {
      value = formatter ? numeral(props.value).format(formatter) : props.value;
    } else if (props.column.id === 'purchasePrice') {
      value = (formatter && props.value !== null) ? numeral(props.value).format(formatter) : 'N/A';
    } else if (props.column.id === 'transactionsShares') {
      value = (formatter && props.value !== null) ?
        numeral(props.value).format(formatter) : <>&mdash;</>;
    } else if (props.column.id === 'tbdAmount') {
      value = (formatter && props.value !== null) ? numeral(props.value).format(formatter) : 'TBD';
    } else if (props.column.id === 'status') {
      return (
        <StatusBars
          id={props.original.uuid}
          status={props.value}
          statusTooltip={props.original.statusMessage}
          statuses={props.original.statuses}
        />
      );
    } else if (props.column.id === 'action') {
      value = (
        (props.value && props.original.actionUrl) ? (
          <Link
            to={props.original.actionUrl}
            type={props.original.actionUrlType}
          >
            {props.value}
          </Link>
        ) : (props.value || 'N/A')
      );
    } else if (props.column.id === 'investment') {
      value = (
        <InlineTextLink to={`/invested/${props.original.ioId}`}>
          { (props.value.length > 16) ? `${props.value.substring(0, 16).trim()}...` : props.value }
        </InlineTextLink>
      );
    } else {
      value = formatter ? numeral(props.value).format(formatter) : props.value;
    }
    return (
      <DashboardTableCellWrapper
        containerProps={containerProps}
        bodyProps={bodyProps}
      >
        {value}
      </DashboardTableCellWrapper>
    );
  };

  Component.propTypes = {
    value: PropTypes.string,
    column: PropTypes.object,
    original: PropTypes.object,
  };

  return Component;
};

export default DashboardTableCell;
