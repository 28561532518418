import React from 'react';

import {
  ExtendedFlex,
  Body,
  Link,
  InlineTextLink,
  Heading,
  Drawer,
  Content,
} from '../../coreui';

import {
  autoInvestUrl,
} from '../../dashboard/links';

import Section from '../../ioDetails/components/section';
import FadeIn from '../../components/FadeIn';
import Chart from '../../reit/components/chart';

const faqs = [
  {
    header: 'What is the auto invest program?',
    content: (
      <>
        Existing The Income REIT and The Apartment Growth REIT investors who desire to purchase additional
        shares of either or both offerings at regular intervals may be able to
        do so by electing to participate in the auto investment program. The
        auto investment program allows an investor to choose a recurring
        investment amount that will be added to an existing REIT investment
        month-after-month automatically. The minimum periodic investment is $250
        per month. If you elect to participate in both the auto investment
        program and our distribution reinvestment plan, distributions earned
        from shares purchased pursuant to the auto investment program will
        automatically be reinvested pursuant to our distribution reinvestment
        plan.
      </>
    ),
  },
  {
    header: 'How do I enroll in the auto invest program?',
    content: (
      <>
        Existing The Income REIT and The Apartment Growth reit investors can click on the{' '}
        {<InlineTextLink to={autoInvestUrl}>“Auto Invest”</InlineTextLink>} tab
        on their investor Dashboard to begin the Auto Invest enrollment process.
        Once an enrollment form is submitted, the Auto Invest enrollment request
        will need to be processed before it will become active on your
        Dashboard. Please visit the{' '}
        {<InlineTextLink to={autoInvestUrl}>“Auto Invest”</InlineTextLink>} tab
        on your investor Dashboard to get started and for more information.
      </>
    ),
  },
  {
    header: 'Can I cancel my auto investment at any time?',
    content: (
      <>
        Yes. Once your auto investment enrollment is active, you will have the
        option to either pause, edit, or cancel your enrollment right from the{' '}
        {<InlineTextLink to={autoInvestUrl}>“Auto Invest”</InlineTextLink>} tab
        on your investor Dashboard.
      </>
    ),
  },
];

const OwnershipOptions = () => {
  return (
    <>
      <Section title="Available to The Income REIT and The Apartment Growth REIT Investors" bg="gray.0">
        <ExtendedFlex
          maxWidth={['90%', '90%', '100%', '1440px', '1440px']}
          mx="auto"
          flexDirection="column"
          align="center"
        >
          <FadeIn>
            <Body type="B1" textAlign="center" display="block" mx={40} w={1}>
              You asked - we delivered. Now, you have the ability to enroll
              your REIT investments into Auto Invest under the{' '}
              {
                <InlineTextLink to={autoInvestUrl}>
                  “Auto Invest” tab
                </InlineTextLink>
              }{' '}
              on your investor Dashboard. Once your enrollment is active, sit
              back and watch your investments grow month-after-month for you
              automatically.
            </Body>
            <ExtendedFlex flexDirection={['column', null, 'row']}>
              <Link type="L1" to={autoInvestUrl} mt={40}>
                Get Started
              </Link>
            </ExtendedFlex>
          </FadeIn>
        </ExtendedFlex>
      </Section>
      <Section
        bg="white"
        title="See how much an Auto investment could be worth"
        titleProps={{
          type: 'H9',
        }}
      >
        <Chart
          showAutoInvest
          useDisclosureModal
          amountInvestedLabel="Amount Invested into The Income REIT"
        />
      </Section>
      <Section bg="gray.0" title="Frequently Asked Questions">
        <ExtendedFlex
          justify="center"
          align="center"
          flexDirection="column"
          w={1}
        >
          <FadeIn>
            {faqs.map(({ header, content }) => (
              <Drawer key={header} title={header}>
                <Content>
                  <Body type="B1">{content}</Body>
                </Content>
              </Drawer>
            ))}
          </FadeIn>
        </ExtendedFlex>
      </Section>
      <ExtendedFlex
        w={1}
        align="center"
        justify="center"
        flexDirection="column"
        py={[60, 80, 100, 120, 140]}
        px={[60, 80, 100, 120, 140]}
      >
        <Heading type="H2" color="black" m={0}>
          Have Questions? Contact Us.
        </Heading>
        <ExtendedFlex mt={[40, 40, 60, 60, 80]} mb={40}>
          <Link type="L1" to={autoInvestUrl}>
            Get Started
          </Link>
        </ExtendedFlex>
        <Heading m={0} mb={10} type="H6" textTransform="uppercase">
          Our Investor Relations Team is Here to Help
        </Heading>
        <Body type="B1" m={0}>
          Call (877) 781-7062
        </Body>
        <Link
          type="L4"
          mt={20}
          display="block"
          href="mailto:investor-relations@realtymogul.com"
        >
          Email Investor Relations
        </Link>
      </ExtendedFlex>
    </>
  );
};

OwnershipOptions.propTypes = {

};

export default OwnershipOptions;
