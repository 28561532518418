import React from 'react';

import {
  Link,
  ExtendedBox,
} from '../../coreui';

const Back = () => {
  return (
    <ExtendedBox
      mt={40}
      px="3%"
    >
      <Link
        type="L4"
        to="/investment-opportunities"
      >
        &lt; Back
      </Link>
    </ExtendedBox>
  );
};

export default Back;
