import React from 'react';
import PropTypes from 'prop-types';

import {
  Body,
  Data,
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Image,
  PieChart,
  Theme,
} from '../../coreui';

import expandIcon from '../assets/expand.svg';
import collapseIcon from '../assets/collapse.svg';

const DashboardSectionToggle = ({ isExpanded, handleExpandChange }) => {
  return (
    <ExtendedFlex
      justify="center"
      align="center"
      hover
    >
      <ExtendedBox
        w={10}
        h={10}
        ml={['0px', '10px']}
        mr={['10px', '10px']}
        position="relative"
        onClick={handleExpandChange}
        transition="transform 0.3s linear"
        transform={isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'}
        hover
      >
        <Image
          position="absolute"
          top={-2}
          left={1}
          src={collapseIcon}
          opacity={isExpanded ? '1' : '0'}
          transition="opacity 0.3s linear"
          hover
        />
        <Image
          position="absolute"
          top={-6}
          left={1}
          src={expandIcon}
          hover
          opacity={isExpanded ? '0' : '1'}
          transition="opacity 0.3s linear"
        />
      </ExtendedBox>
    </ExtendedFlex>
  );
};

class DashboardSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: props.initialIsExpanded,
    };
    this.handleExpandChange = this.handleExpandChange.bind(this);
  }

  handleExpandChange() {
    this.setState((state) => ({
      isExpanded: !state.isExpanded,
    }));
  }

  render() {
    const {
      children,
      totalInvested,
      totalDistributions,
      currentValue,
      allocation,
      title,
      contentWidth,
    } = this.props;
    return (
      <ExtendedBox width={1} maxWidth={1700}>
        <ExtendedFlex
          width="auto"
          borderTop={`3px solid ${Theme.colors.blue[0]}`}
          borderBottom={
            this.state.isExpanded === true
              ? `1px solid ${Theme.colors.gray[7]}`
              : 'medium none'
          }
          py={['10px']}
          px={[0, 0, 20]}
          mx={[24, 50]}
        >
          <ExtendedFlex
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            minHeight={[45, 63, 65, 67, 73]}
          >
            <ExtendedFlex
              width={[145, '30%']}
              flexWrap="wrap"
              boxSizing="border-box"
              onClick={this.handleExpandChange}
              hover
            >
              <DashboardSectionToggle isExpanded={this.state.isExpanded} />
              <Heading type="H8" maxWidth={[100, 'none']} flex={[null, 1]}>
                {title}
              </Heading>
            </ExtendedFlex>

            {totalInvested && (
              <ExtendedFlex
                flexDirection="column"
                justifyContent="center"
                w={[null, '13%']}
                pl={[20, 10]}
                pr={[10, 10, 20]}
              >
                <Body type="B3" m="0">
                  Total Invested
                </Body>
                <Data type="D5" my="0.2em">
                  {totalInvested}
                </Data>
              </ExtendedFlex>
            )}

            {totalDistributions && (
              <ExtendedFlex
                flexDirection="column"
                justifyContent="center"
                display={['none', 'flex']}
                w="13%"
                pl={[20, 0]}
                pr={[10, 0, 20]}
              >
                <Body type="B3" m="0">
                  Total Distributions
                </Body>
                <Data type="D5" my="0.2em">
                  {totalDistributions}
                </Data>
              </ExtendedFlex>
            )}

            {currentValue && (
              <ExtendedFlex
                flexDirection="column"
                justifyContent="center"
                display={['none', 'flex']}
                w="13%"
                pl={[20, 0]}
                pr={[10, 0, 20]}
              >
                <Body type="B3" m="0">
                  Current Value
                </Body>
                <Data type="D5" my="0.2em">
                  {currentValue}
                </Data>
              </ExtendedFlex>
            )}

            {'allocation' in this.props && (
              <ExtendedFlex
                alignItems="center"
                justifyContent="flex-end"
                display={['none', 'flex']}
                mr={[0, 0, 0, 40]}
                w="17%"
              >
                <ExtendedFlex
                  flexDirection="column"
                  justifyContent="center"
                  display={['none', 'flex']}
                >
                  <PieChart
                    percentage={allocation}
                    m="auto"
                    top="0"
                    bottom="0"
                    left="0"
                    right="0"
                    w={[60, 60, 60, 60, 60]}
                  />
                </ExtendedFlex>
                <ExtendedFlex
                  flexDirection="column"
                  justifyContent="center"
                  display={['none', 'flex']}
                >
                  <Body type="B3" m="0">
                    Portfolio Allocation
                  </Body>
                </ExtendedFlex>
              </ExtendedFlex>
            )}
          </ExtendedFlex>
        </ExtendedFlex>
        {this.state.isExpanded && (
          <ExtendedFlex
            width="auto"
            mx={[24, 50]}
            flexDirection="column"
            alignItems="center"
          >
            <ExtendedFlex
              width={[contentWidth || 1, 1]}
              flexDirection="column"
              alignItems="center"
            >
              {children}
            </ExtendedFlex>
          </ExtendedFlex>
        )}
      </ExtendedBox>
    );
  }
}

DashboardSectionToggle.propTypes = {
  isExpanded: PropTypes.bool,
  handleExpandChange: PropTypes.func,
};

DashboardSection.propTypes = {
  children: PropTypes.any,
  totalInvested: PropTypes.string,
  totalDistributions: PropTypes.string,
  currentValue: PropTypes.string,
  title: PropTypes.string,
  allocation: PropTypes.number,
  contentWidth: PropTypes.string,
  initialIsExpanded: PropTypes.bool,
};

DashboardSection.defaultProps = {
  initialIsExpanded: true,
};

export default DashboardSection;
