/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  Image,
} from '../..';

import image from '../../assets/closeX_blk.svg';

const Full = ({
  top,
  isVisible,
  showClose,
  handleClick,
  children,
}) => (
  <ExtendedBox
    position="fixed"
    top={top === '50%' ? 0 : top}
    right={0}
    {...(top === '50%' ? {
      bottom: 0,
    } : {})}
    left={0}
    bg="white"
    boxSizing="border-box"
    overflowY="scroll"
    overscrollBehavior="contain"
    opacity={isVisible ? '1' : '0'}
    transition={`
      opacity 0.5s linear,
      visibility 0.5s linear
    `}
    zIndex={10}
    visibility={isVisible ? 'visible' : 'hidden'}
  >
    <ExtendedBox
      p={40}
      overflowX="auto"
      overflowY="auto"
      overscrollBehavior="contain"
      position="relative"
    >
      {
        showClose &&
        <ExtendedBox
          position="absolute"
          top={20}
          left={20}
          visibility={isVisible ? 'visible' : 'hidden'}
        >
          <Image
            src={image}
            onClick={handleClick}
            visibility={isVisible ? 'visible' : 'hidden'}
            hover
          />
        </ExtendedBox>
      }
      {children}
    </ExtendedBox>
  </ExtendedBox>
);

Full.propTypes = {
  top: PropTypes.number,
  children: PropTypes.any,
  isVisible: PropTypes.bool,
  showClose: PropTypes.bool,
  handleClick: PropTypes.func,
};

Full.defaultProps = {
  showClose: true,
};

export default Full;
