import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import styled from 'styled-components';

import {
  ExtendedFlex,
  Heading,
  InlineTextLink,
  Text,
  Formatters,
} from '../../coreui';

function dateFormat(inputDate) {
  // Split the date into an array of year, month, and day.
  const dateArray = inputDate.split('-');
  return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  width: calc(100% - 48px);
  justify-content: center;
  @media (max-width: 1023px) {
    p:nth-child(1) { order: 4; }
    p:nth-child(2) { order: 2; }
    p:nth-child(3) { order: 3; }
    p:nth-child(4) { order: 1; }
  }
`;

const Row = ({ label, value }) => (
  <ExtendedFlex
    flexDirection="row"
    justifyContent="space-between"
  >
    <Heading type="DashboardLabel" my={6}>{label}</Heading>
    <Heading type="DashboardLabel" my={6}>{value}</Heading>
  </ExtendedFlex>
);

Row.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

const IOCard = ({ data }) => {
  return (
    <ExtendedFlex
      flexDirection={['column', 'row']}
      w={1}
      maxWidth={960}
      my={12}
      mx="auto"
      border="1px solid #C9C9C9"
      borderRadius="5px"
    >
      <ExtendedFlex
        w={[1, '250px']}
        position="relative"
      >
        <img src={data.image} alt={data.title} style={{ width: '100%', objectFit: 'cover' }} />
      </ExtendedFlex>
      <ExtendedFlex
        flexDirection={['column', 'row']}
        w={[1, '80%']}
        py={24}
        justifyContent="space-between"
      >
        <Container>
          <Text fontFamily="serif" fontSize={[14, 14, 16, 18, 20]} my={6}>{data.propertyType}</Text>
          <Text fontFamily="serif" fontSize={[14, 14, 16, 18, 20]} my={6}>{data.city}, {data.state}</Text>
          <InlineTextLink fontSize={[14, 14, 16, 18, 20]} my={6} to={`/invested/${data.nid}`}>
            <div style={{ margin: '6px 0' }}>{data.title}</div>
          </InlineTextLink>
        </Container>
        <ExtendedFlex
          flexDirection="column"
          w={1}
          px={24}
          justifyContent="center"
        >
          <Row label="Date" value={dateFormat(data.date)} />
          <Row label="Amount" value={numeral(data.totalInvestmentAmount).format(Formatters.DOLLARS_DECIMALS)} />
          <Row label="Cash Distribution Paid" value={numeral(data.totalDistributionsPaid).format(Formatters.DOLLARS_DECIMALS)} />
          <Row label="Distribution Frequency" value={data.distributionFrequency} />
        </ExtendedFlex>
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

export default IOCard;

IOCard.propTypes = {
  data: PropTypes.object,
};
