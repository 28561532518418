import types from './types';

export default {
  getIODataRequested: (id) => ({
    type: types.GET_IO_DATA_REQUESTED,
    id,
  }),
  getIODataSucceeded: (data) => ({
    type: types.GET_IO_DATA_SUCCEEDED,
    data,
  }),
  getIODataFailed: (error) => ({
    type: types.GET_IO_DATA_FAILED,
    error,
  }),
  getFAQDataRequested: (opts) => ({
    type: types.GET_IO_FAQ_DATA_REQUESTED,
    opts,
  }),
  getFAQDataSucceeded: (data) => ({
    type: types.GET_IO_FAQ_DATA_SUCCEEDED,
    data,
  }),
  getFAQDataFailed: (error) => ({
    type: types.GET_IO_FAQ_DATA_FAILED,
    error,
  }),
  unsetState: () => ({
    type: types.UNSET_IO_STATE,
  }),
  watchListToggleApiActionRequested: (opts) => ({
    type: types.WATCHLIST_TOGGLE_REQUESTED,
    opts,
  }),
  watchListToggleApiActionSucceeded: (data) => ({
    type: types.WATCHLIST_TOGGLE_SUCCEEDED,
    data,
  }),
  watchListToggled: (opts) => ({
    type: types.WATCHLIST_TOGGLED,
    opts,
  }),
  watchListToggleApiActionFailed: (error) => ({
    type: types.WATCHLIST_TOGGLE_FAILED,
    error,
  }),
};
