export const dashboardUrl = '/investor-dashboard';
export const investmentsUrl = '/investor-dashboard/active-investments';
export const autoInvestUrl = '/investor-dashboard/auto-invest';
export const autoInvestDirectEnrollmentUrl = '/investor-dashboard/auto-invest/enrollment/:io/:foe';
export const autoInvestEditUrl = '/investor-dashboard/auto-invest/:id';
export const autoInvestEditConfirmationUrl = '/investor-dashboard/auto-invest/:id/confirmation';
export const autoInvestEnrollmentUrl = '/investor-dashboard/auto-invest/enrollment';
export const autoInvestIsqUrl = '/investor-dashboard/auto-invest/investor-suitability/:id';
export const autoInvestEnrollmentInfoUrl = '/investor-dashboard/auto-invest/enrollment-info';
export const autoInvestEnrollmentSuitabilityUrl = '/investor-dashboard/auto-invest/enrollment-suitability';
export const autoInvestEnrollmentInvestmentUrl = '/investor-dashboard/auto-invest/enrollment-investment';
export const autoInvestEnrollmentConfirmationUrl = '/investor-dashboard/auto-invest/enrollment-confirmation';
export const autoInvestEnrollmentIneligibleUrl = '/investor-dashboard/auto-invest/ineligible';
export const completedInvestmentsUrl = '/investor-dashboard/completed-investments';
export const pendingInvestmentsUrl = '/investor-dashboard/pending-investments';
export const transactionsUrl = '/investor-dashboard/transactions';
export const messagesUrl = '/investor-dashboard/messages';
export const taxcenterUrl = '/investor-dashboard/tax-center';
export const profileUrl = '/investor-dashboard/profile';
export const pledgesUrl = '/investor-dashboard/pending-investments/pledges';
export const watchlistUrl = '/investor-dashboard/pending-investments/watchlist';
export const waitlistUrl = '/investor-dashboard/pending-investments/waitlist';

export const transactionsCompletedUrl = '/investor-dashboard/transactions/completed';
export const transactionsActiveUrl = '/investor-dashboard/transactions/active';

export default {
  investmentsUrl,
  completedInvestmentsUrl,
  transactionsUrl,
  pendingInvestmentsUrl,
  links: [
    {
      id: 'investments',
      value: investmentsUrl,
      external: false,
      label: 'INVESTMENTS',
      sublinks: [
        investmentsUrl,
        completedInvestmentsUrl,
        pendingInvestmentsUrl,
        pledgesUrl,
        watchlistUrl,
        waitlistUrl,
      ],
    },
    {
      id: 'autoInvest',
      value: autoInvestUrl,
      external: false,
      label: 'AUTO INVEST',
      sublinks: [
        autoInvestUrl,
        autoInvestEditUrl,
        autoInvestEnrollmentInfoUrl,
        autoInvestEnrollmentSuitabilityUrl,
        autoInvestEnrollmentInvestmentUrl,
        autoInvestEnrollmentConfirmationUrl,
        autoInvestEnrollmentIneligibleUrl,
      ],
    },
    {
      id: 'transactions',
      value: transactionsUrl,
      external: false,
      label: 'TRANSACTIONS',
      sublinks: [
        transactionsUrl,
        transactionsCompletedUrl,
        transactionsActiveUrl,
      ],
    },
    {
      id: 'messages',
      value: messagesUrl,
      external: false,
      label: 'MESSAGES',
      sublinks: [
        messagesUrl,
      ],
    },
    {
      id: 'taxcenter',
      value: taxcenterUrl,
      external: false,
      label: 'TAX CENTER',
      sublinks: [
        taxcenterUrl,
      ],
    },
    {
      id: 'profile',
      value: profileUrl,
      external: true,
      label: 'PROFILE',
      sublinks: [
        profileUrl,
      ],
    },
  ],
};
