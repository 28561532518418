import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import stubResponse from '../data';

import { getKnowledgeCenterArticleApi } from '../../../api';

export const mockApi = () => ({
  data: stubResponse,
});

export function* getArticleFlow(action) {
  const id = action.id;

  try {
    const response = yield call(
      getKnowledgeCenterArticleApi,
      id,
    );
    yield put(
      actions.getArticleSucceeded(response.data),
    );
  } catch (error) {
    yield put(
      actions.getArticleFailed(error),
    );
  }
}

export function* watchGetArticleRequested() {
  yield takeLatest(
    types.GET_ARTICLE_REQUESTED,
    getArticleFlow,
  );
}

export default watchGetArticleRequested;
