/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  InlineTextLink,
} from '../../coreui';

import types from './types';
import browsePageTypes from '../browsePage/types';
import dashboardTypes from '../../dashboard/types';
import appWrapperTypes from '../../appWrapper/types';
import authTypes from '../../auth/types';
import passImg from '../../assets/Img_card_PP.jpg';

const initialState = {
  loaded: false,
  userLoaded: false,
  dataLoaded: false,
  ioTypes: [
    {
      value: 'all',
      label: 'ALL',
      count: null,
    },
    {
      value: 'multi_asset',
      label: 'MULTI-ASSET',
      count: null,
    },
    {
      value: 'single_property',
      label: 'SINGLE PROPERTY',
      count: null,
    },
    {
      value: '1031_eligible',
      label: '1031 ELIGIBLE',
      count: null,
    },
  ],
  gtkyAccessPendingIO: {
    id: 2,
    title: 'Individual Property Investments',
    path: '/investment-opportunities',
    pathProps: {},
    linkType: 'to',
    status: 'status-10-open',
    statusLabel: 'Access Pending',
    assetCount: 1,
    REIT: false,
    image: passImg,
    propertyLocation: 'Private Placements',
    investmentType: 'REIT',
    investmentTypeDisplay: 'Equity',
    targetHoldPeriod: null,
    minimum: 'Varied',
    targetHoldMin: null,
    targetHoldMax: 'Varied',
    payout: 1,
    payoutLabel: 'Varied',
    payoutTooltip: false,
    propertyType: 'Varied',
    includeProgress: true,
    blurb: 'Once approved, you will be able to view our full suite of investment opportunities including private placements. Call (424) 325-5160.',
    blurContent: true,
    linkText: 'Schedule a call >',
  },
  accreditationRoleStatus: '',
  gtkySubmittedApproved: false,
  gtkyStatus: '',
  isSelfAccredCooledOff: null,
  selectedIoType: 'all',
  // The currently visible investments.
  investments: [],
  investmentsCount: 0,
  // Copies of the investment to switch to via filtering.
  allInvestments: [],
  allInvestmentsCount: 0,
  multiAssetInvestments: [],
  singlePropertyInvestments: [],
  eligible1031Investments: [],
  // Should another type for filtering be added, update the array below to add
  // the key of the set of investments.
  investmentKeys: [
    'investments',
    'allInvestments',
    'multiAssetInvestments',
    'singlePropertyInvestments',
    'eligible1031Investments',
  ],
  filteredInvestments: [],
};

export default (state = initialState, action) => {
  let investments;

  switch (action.type) {
    case types.WATCH_INVESTMENT_FLAG_REQUESTED:
    case types.WATCH_INVESTMENT_FLAG_SUCCEEDED:
    case types.WATCH_INVESTMENT_FLAG_FAILED:
    case dashboardTypes.PENDING_DASHBOARD_WATCHLIST_FLAG_REQUESTED:
    case dashboardTypes.PENDING_DASHBOARD_WATCHLIST_FLAG_SUCCEEDED: {
      const loading = action.type === types.WATCH_INVESTMENT_FLAG_REQUESTED ||
        action.type === dashboardTypes.PENDING_DASHBOARD_WATCHLIST_FLAG_REQUESTED;
      const id = (action.type === dashboardTypes.PENDING_DASHBOARD_WATCHLIST_FLAG_REQUESTED ||
        action.type === dashboardTypes.PENDING_DASHBOARD_WATCHLIST_FLAG_SUCCEEDED) ?
        action.id : action.investment.id;
      return {
        ...state,
        ...(state.investmentKeys.reduce((items, key) => {
          return {
            ...items,
            [key]: state[key].reduce((currentInvestments, item) => {
              return [
                ...currentInvestments,
                {
                  ...(
                    item.id === id ? {
                      ...item,
                      watchlist: {
                        ...item.watchlist,
                        loading,
                        flagged: (() => {
                          if (action.type === types.WATCH_INVESTMENT_FLAG_SUCCEEDED) {
                            return action.result.flagStatus === 'flagged';
                          // eslint-disable-next-line no-else-return
                          } else if (
                            action.type ===
                              dashboardTypes.PENDING_DASHBOARD_WATCHLIST_FLAG_SUCCEEDED
                          ) {
                            return action.flagged;
                          }
                          return item.watchlist ? item.watchlist.flagged : null;
                        })(),
                      },

                      // We don't want to animate on the current set of investments.
                      ...(key === 'investments' ? {
                        noAnimate: true,
                      } : {}),
                    } : item
                  ),
                },
              ];
            }, []),
          };
        }, {})),
      };
    }

    case types.UPDATE_SELECTED_CURRENT_INVESTMENT_FILTER_VALUE:
      investments = state.allInvestments;

      switch (action.value) {
        case 'multi_asset':
          investments = state.multiAssetInvestments;
          break;

        case 'single_property':
          investments = state.singlePropertyInvestments;
          break;

        case '1031_eligible':
          investments = state.eligible1031Investments;
          break;

        default:
        case 'all':
          // Default case above.
          break;
      }
      return {
        ...state,
        selectedIoType: action.value,
        investments,
        investmentsCount: investments.length,
      };

    case browsePageTypes.GET_BROWSE_OPEN_DATA_SUCCEEDED: {
      investments = action.data;

      const multiAssetInvestments = investments.filter(
        (investment) => {
          return investment.assetCount > 1;
        },
      );
      const singlePropertyInvestments = investments.filter(
        (investment) => {
          return investment.assetCount === 1;
        },
      );
      const eligible1031Investments = investments.filter(
        (investment) => {
          return investment['1031Eligible'];
        },
      );

      return {
        ...state,
        loaded: true,
        dataLoaded: true,
        ioTypes: state.ioTypes.map(
          (label) => {
            let count;
            switch (label.value) {
              case 'all':
                count = investments.length;
                break;

              case 'multi_asset':
                count = multiAssetInvestments.length;
                break;

              case 'single_property':
                count = singlePropertyInvestments.length;
                break;

              case '1031_eligible':
                count = eligible1031Investments.length;
                break;

              default:
                count = null;
                break;
            }
            return {
              ...label,
              count,
            };
          },
        ),
        investments,
        investmentsCount: investments.length,
        allInvestments: investments,
        allInvestmentsCount: investments.length,
        multiAssetInvestments,
        singlePropertyInvestments,
        eligible1031Investments,
        filteredInvestments: investments,
      };
    }

    case appWrapperTypes.GET_USER_DATA_SUCCEEDED: {
      const gtkyContactLink = action.user.gtkyContactLink || '';
      return {
        ...state,
        accreditationRoleStatus: action.user.accreditationRoleStatus,
        gtkySubmittedApproved: action.user.gtkySubmittedApproved,
        gtkyAccessPendingIO: {
          ...state.gtkyAccessPendingIO,
          blurb: (
            <span data-test="gtky-message">
              Once approved, you will be able to view our full suite of
              investment opportunities including private placements. Call{' '}
              {
                <InlineTextLink href="tel:+14243255160" color="black">
                  (424) 325-5160
                </InlineTextLink>
              }{' '}
              (M-F 8am-6pm PST) or{' '}
              {
                <InlineTextLink
                  color="black"
                  href={gtkyContactLink}
                  {...(!gtkyContactLink.startsWith('mailto:')
                    ? {
                      target: '_blank',
                    }
                    : {})}
                >
                  click here to schedule a call
                </InlineTextLink>
              }
              .
            </span>
          ),
          path: action.user.gtkyContactLink,
          pathProps: {
            ...(!gtkyContactLink.startsWith('mailto:')
              ? {
                target: '_blank',
              }
              : {}),
          },
          linkType: 'href',
        },
        gtkyStatus: action.user.gtkyStatus,
        isSelfAccredCooledOff: action.user.isSelfAccredCooledOff,
        userLoaded: true,
        loaded: state.dataLoaded,
      };
    }

    case authTypes.USER_LOGOUT_REQUESTED: {
      return {
        ...state,
      };
    }

    case types.UPDATE_FILTERED_INVESTMENTS: {
      return {
        ...state,
        filteredInvestments: action.filteredInvestments,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
