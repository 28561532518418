import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import {
  Body,
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Link,
  Formatters,
  TooltipIcon,
  Tooltip,
} from '../../coreui';

import DistributionsToggle from './DistributionsToggle';

const Row = ({ nid, label, value, tooltip }) => (
  <ExtendedFlex
    flexDirection="row"
    justifyContent="space-between"
  >
    <Heading type="DashboardLabel" my={6}>
      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
        {label}
        {tooltip &&
        <div style={{ marginLeft: '6px' }} >
          <TooltipIcon
            tooltipId={`${nid}-${label}-tooltip`}
          />
          <Tooltip id={`${nid}-${label}-tooltip`}>
            <Body type="B5">
              {tooltip}
            </Body>
          </Tooltip>
        </div>}
      </span>
    </Heading>
    <Heading type="DashboardLabel" my={6}>{value}</Heading>
  </ExtendedFlex>
);

Row.propTypes = {
  nid: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  value: PropTypes.string,
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};

const ReitCard = ({ data, invested }) => {
  return (
    <ExtendedFlex
      flexDirection="column"
      w={1}
      maxWidth={600}
      my={15}
      mx={12}
      px={24}
      py={18}
      border="1px solid #C9C9C9"
      borderRadius="5px"
      flexWrap="wrap"
    >
      <ExtendedFlex
        flexDirection="column"
        w={1}
        maxWidth={['100%']}
        justifyContent="center"
      >
        <ExtendedFlex w={1}>
          <Heading type="DashboardLabel" fontSize={[18, 18, 26, 26, 32]} fontWeight="800" my={6}>{data.title}</Heading>
        </ExtendedFlex>
        <Row nid={data.nid} label="Shares" value={numeral(data.overview.totalShares.value).format('0,0.00')} />
        <Row
          nid={data.nid}
          label="Current Investment Value"
          value={numeral(data.overview.currentInvestmentValue.value).format(Formatters.DOLLARS_DECIMALS)}
          tooltip="The product of the number of REIT shares that you currently own and their most recent available Net Asset Value (NAV) per share. This does not take into account any repurchase discounts or costs. Please not that the calculation of NAV includes estimates. Review the terms of the Offering Circular for more information."
        />
        <Row label="Annualized Distribution" value={numeral(data.overview.distributionYieldToDate.value).format(Formatters.PERCENTAGE2)} />
        <Row
          nid={data.nid}
          label="NAV Per Share"
          value={numeral(data.overview.currentNav.value).format(Formatters.DOLLARS_DECIMALS)}
          tooltip="The goal of the Net Asset Value (NAV) calculation is to provide a reasonable estimate of the value of the REIT's common shares. This valuation is subject to a number of subjective judgements and assumptions. Review the terms of the Offering Circular for more information."
        />
        <Row
          nid={data.nid}
          label="Current Total Invested"
          value={numeral(data.overview.currentTotalInvested.value).format(Formatters.DOLLARS_DECIMALS)}
          tooltip="Total amount invested in the REIT by you, as well as any reinvested REIT distributions."
        />
        <Row
          nid={data.nid}
          label="Distributions Reinvested"
          value={numeral(data.overview.distributionsReinvested.value).format(Formatters.DOLLARS_DECIMALS)}
          tooltip="Total dollar value of distributions that were used to purchase new shares at the time of reinvestment. In the event that shares marked as reinvested distributions are redeems, the redeemed dollar amount will be deducted from this value."
        />
        <Row
          nid={data.nid}
          label="Cash Distributions Paid"
          value={numeral(data.overview.cashDistributionsPaid.value).format(Formatters.DOLLARS_DECIMALS)}
          tooltip="Distributions represent the cash distributions authorized and distributed by the Company and may be inclusive of returned principal."
        />
        {
          data?.currentWorkflow &&
          <Row
            nid={data.nid}
            label="Share Repurchase"
            value={((workflowStatus, quarter, year) => {
              if (workflowStatus === 'pending_approval') {
                return 'Submitted for Approval';
              }
              if (workflowStatus === 'approved') {
                return `Approved - Q${quarter} ${year}`;
              }
              return `Requested for Q${quarter} ${year}`;
            })(data.currentWorkflow?.stage?.value, data.currentWorkflow.quarter, data.currentWorkflow.year)}
            tooltip={
              <>
                Please note that all approved repurchases transpire within{' '}
                {} days of the end of the applicable quarter.
                Please see the {data.title} Offering Circular for additional details.
                <br />
                <br />
                If you submitted multiple Share Repurchase requests for this
                investment, please contact sharerepurchaserequests@realtymogul.com for
                specific information on the status of each request.
              </>
            }
          />
        }
        {
          // Add a spacer if there's some share repurchase workflow displayed
          // but it's not this current REIT.
          data?.hasShareRepurchaseWorkflow && !data?.currentWorkflow &&
          <Row nid={data.nid} label="" value="" />
        }
      </ExtendedFlex>
      <ExtendedFlex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        w={1}
        py={24}
      >
        {
          data.smartButtonUrl &&
          <ExtendedFlex
            w={1}
          >
            <ExtendedFlex
              flexDirection="column"
              w={1}
            >
              <ExtendedBox
                w={1}
              >
                <Link
                  type="L1"
                  width={1}
                  href={data.smartButtonUrl}
                  maxWidth={null}
                >
                  {data.smartButtonText}
                </Link>
              </ExtendedBox>
            </ExtendedFlex>
          </ExtendedFlex>
        }
        {
          invested &&
          <ExtendedFlex
            w={1}
            my={12}
          >
            <ExtendedFlex
              flexDirection="column"
              w={1}
            >
              <ExtendedBox
                w={1}
              >
                <Link
                  type="L1"
                  width={1}
                  href="/investor-dashboard/auto-invest"
                  maxWidth={null}
                >
                  Update Auto Invest
                </Link>
              </ExtendedBox>
            </ExtendedFlex>
          </ExtendedFlex>
        }
        {
          invested &&
          <DistributionsToggle
            id={data.nid}
            reinvestDistributionsURL={data.reinvestDistributionsURL}
            receiveCashDistributionsURL={data.receiveCashDistributionsURL}
            dripSetting={data.dripSetting}
            isDisabled={data.dripToggleDisabled ? true : !invested}
          />
        }
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

export default ReitCard;

ReitCard.propTypes = {
  data: PropTypes.object,
  invested: PropTypes.bool,
};
