import React from 'react';

import {
  StyledButton,
} from '../../coreui';

const SecondaryButton = (props) => {
  return (
    <StyledButton
      type="L4"
      // eslint-disable-next-line
      {...props}
    />
  );
};

export default SecondaryButton;
