/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { ExtendedFlex, ScrollToTopOnMount } from '../../coreui';

const Container = ({
  children,
  ...props
}) => {
  return (
    <ExtendedFlex
      flexDirection="row"
      justify="center"
      my={50}
      mx="auto"
      maxWidth={1200}
      width="90%"
      {...props}
    >
      <ScrollToTopOnMount />
      {children}
    </ExtendedFlex>
  );
};

Container.propTypes = {
  children: PropTypes.object,
};

export default Container;
