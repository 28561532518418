import React from 'react';
import PropTypes from 'prop-types';

import {
  withRouter,
} from 'react-router-dom';
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';

import {
  ExtendedFlex,
  ExtendedBox,
  Data,
} from '../../coreui';

const KnowledgeCenterSocial = (props) => {
  const metaTags = props.metaTags;
  return (
    <ExtendedBox>
      {
        metaTags.link && metaTags.link.canonical &&
        metaTags.meta && metaTags.meta.title &&
        <ExtendedFlex
          w={1}
          align="center"
          py={20}
        >
          <Data
            type="D1"
            m={0}
            mr={20}
          >
            Share to:
          </Data>
          <ExtendedBox
            mr={10}
            hover
          >
            <TwitterShareButton
              url={metaTags.link.canonical}
              via="realty_mogul"
              title={metaTags.meta.title}
            >
              <TwitterIcon
                size={32}
                round
              />
            </TwitterShareButton>
          </ExtendedBox>
          <ExtendedBox
            mr={10}
            hover
          >
            <LinkedinShareButton
              url={metaTags.link.canonical}
              via="realty_mogul"
              title={metaTags.meta.title}
            >
              <LinkedinIcon
                size={32}
                round
              />
            </LinkedinShareButton>
          </ExtendedBox>
          <ExtendedBox
            mr={10}
            hover
          >
            <FacebookShareButton
              url={metaTags.link.canonical}
              quote={metaTags.meta.title}
            >
              <FacebookIcon
                size={32}
                round
              />
            </FacebookShareButton>
          </ExtendedBox>
        </ExtendedFlex>
      }
    </ExtendedBox>
  );
};

KnowledgeCenterSocial.propTypes = {
  metaTags: PropTypes.object,
};

KnowledgeCenterSocial.defaultProps = {
  metaTags: { title: 'Realtymogul.com', url: 'https://www.realtymogul.com' },
};

export default withRouter(KnowledgeCenterSocial);
