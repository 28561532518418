import {
  takeEvery,
  call,
  put,
  all,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';
import { setLatestIsq } from '../../api';

export function* setIsqFlow(action) {
  const {
    data,
    promise: {
      resolve,
      reject,
    } = {},
  } = action;
  try {
    const response = yield call(setLatestIsq, data);
    yield all([
      call(resolve, { data: response.data }),
      put(
        actions.setLatestIsqSucceeded(
          response.data,
        ),
      ),
    ]);
  } catch (error) {
    yield all([
      call(reject, error),
      put(
        actions.setLatestIsqFailed(
          error,
        ),
      ),
    ]);
  }
}

export function* watchSetIsq() {
  yield takeEvery(
    types.SET_LATEST_ISQ_REQUESTED,
    setIsqFlow,
  );
}

export default watchSetIsq;
