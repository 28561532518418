/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ReactDOM from 'react-dom';

const defaultOptions = {
  noRootNoRender: false,
};

function withPortal(WrappedComponent, reactRoot, passedOptions = defaultOptions) {
  const options = {
    ...defaultOptions,
    ...passedOptions,
  };
  const {
    noRootNoRender,
  } = options;
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {

      };
      if (typeof document !== 'undefined') {
        this.el = document.createElement('div');
      }
    }

    componentDidMount() {
      if (reactRoot) {
        reactRoot.appendChild(this.el);
      }
    }

    componentWillUnmount() {
      if (reactRoot) {
        reactRoot.removeChild(this.el);
      }
    }

    render() {
      if (reactRoot) {
        return ReactDOM.createPortal(
          <WrappedComponent {...this.props} />,
          this.el,
        );
      }
      if (noRootNoRender) {
        return null;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withPortal;
