import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Portal from './Portal';

const DrupalWrapper = ({
  drupalPortalId,
  children,
}) => {
  const [portalId, setPortalId] = useState(null);

  // Determine if we should be showing Plaid.
  const drupal = typeof window !== 'undefined' && window?.Drupal;
  useEffect(() => {
    if (drupal) {
      // Get the initial setting from Drupal.
      // eslint-disable-next-line camelcase
      const passedPortalId = drupal?.settings?.rm_invest_flow?.[drupalPortalId];
      setPortalId(passedPortalId);
    }
  }, [drupal, portalId, drupalPortalId]);

  if (!portalId) {
    return null;
  }

  return (
    <Portal id={portalId}>
      {children}
    </Portal>
  );
};

DrupalWrapper.propTypes = {
  drupalPortalId: PropTypes.string,
  children: PropTypes.any,
};

export default DrupalWrapper;
