import React from 'react';

import {
  StyledButton,
} from '../../coreui';

const PrimaryButton = (props) => {
  return (
    <StyledButton
      mt={20}
      mr={[0, null, null, 20]}
      mb={20}
      // eslint-disable-next-line
      {...props}
    />
  );
};

export default PrimaryButton;
