import React from 'react';
import PropTypes from 'prop-types';

import Country from './address/Country';
import State from './address/State';
import Label from './Label';
import Input from './Input';

const Address = ({
  onChange,
  name,
  country,
  address1,
  address2,
  city,
  state,
  zip,
  required,
}) => {
  return (
    <>
      <Country
        name={`${name}.country`}
        onChange={onChange}
        value={country}
      />
      <Label
        htmlFor="address1"
      >
        Address Line 1
        <Input
          id="address1"
          name={`${name}.thoroughfare`}
          value={address1}
          placeholder="Address Line 1"
          onChange={onChange}
          data-test="address1"
          maxLength="255"
          required={required}
        />
      </Label>
      <Label
        htmlFor="address2"
      >
        Address Line 2
        <Input
          id="address1"
          name={`${name}.premise`}
          value={address2}
          placeholder="Address Line 2"
          onChange={onChange}
          maxLength="255"
          data-test="address2"
        />
      </Label>
      <Label
        htmlFor="city"
      >
        City
        <Input
          id="city"
          name={`${name}.locality`}
          value={city}
          placeholder="City"
          onChange={onChange}
          data-test="city"
          maxLength="255"
          required={required}
        />
      </Label>
      {
        country === 'US' &&
        <State
          name={`${name}.administrative_area`}
          onChange={onChange}
          value={state}
          required={required}
        />
      }
      <Label
        htmlFor="zip"
      >
        Zip Code
        <Input
          id="zip"
          name={`${name}.postal_code`}
          value={zip}
          placeholder="Zip"
          onChange={onChange}
          data-test="zip"
          maxLength="255"
          required={required}
        />
      </Label>
    </>
  );
};

Address.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  country: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  required: PropTypes.bool,
};

export default Address;
