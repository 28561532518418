import types from './types';

export default {
  getLeadershipList: () => ({
    type: types.GET_LEADERSHIP_LIST_REQUESTED,
  }),
  getLeadershipListFailed: (error) => ({
    type: types.GET_LEADERSHIP_LIST_FAILED,
    error,
  }),
  getLeadershipListSucceeded: (data) => ({
    type: types.GET_LEADERSHIP_LIST_SUCCEEDED,
    data,
  }),
};
