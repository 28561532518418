import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Heading,
  Body,
} from '../../coreui';
import RadioGroup from '../forms/RadioGroup';
import RadioLabel from '../forms/RadioLabel';

const FollowUp = ({
  header,
  subheader,
  name,
  value,
  options,
  handleChange,
}) => {
  return (
    <ExtendedFlex
      flexDirection="column"
      mt={50}
      borderTop={1}
      borderColor="red"
      pt={50}
    >
      <Heading
        type="H6"
        color="red"
        textTransform="uppercase"
      >
        {header}
      </Heading>
      <Body
        type="B2"
      >
        {subheader}
      </Body>
      <Heading
        type="H6"
      >
        Please select one of the following:
      </Heading>
      <RadioGroup
        name={name}
        onChange={handleChange}
        inputProps={{
          required: true,
        }}
        value={value}
      >
        {
          options.map((option) => (
            <RadioLabel
              key={option.value}
              value={option.value}
              label={option.label}
            />
          ))
        }
      </RadioGroup>
    </ExtendedFlex>
  );
};

FollowUp.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
};

FollowUp.defaultProps = {
  header: 'Your protection is our top priority and we want to be sure you are comfortable with this investment.',
  options: [
    {
      value: 'comfortable',
      label: 'I am comfortable with the risks inherent in this investment.',
    },
    {
      value: 'not_comfortable',
      label: 'I am not comfortable with the risks inherent in this investment.',
    },
  ],
};

export default FollowUp;
