import styled from 'styled-components';

const Prev = styled.button`
  position: absolute;
  box-sizing: border-box;
  padding: 0 14px;
  top: 50%;
  left: -40px;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
  svg {
    width: 12px;
  }
  :active {
    outline: 2px solid #FFF;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: 10px 15px;
    left: -54px;
    svg {
      width: 24px;
    }
  }
`;

export default {
  SubpageOverlayContainer: styled.div`
    position: fixed;
    z-index: -100;
    transform: scale3d(1,1,1);
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s;
    ${({ isShowingPage }) => isShowingPage && `
      opacity: 1;
      z-index: 12;
    `}
  `,
  SubpageOverlayBackground: styled.button`
    text-indent: -9999px;
    display: block;
    background: rgba(0, 0, 0, 0.75);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    border: none;
  `,
  SubpageOverlay: styled.div`
    display: flex;
    position: absolute;
    background: ${({ background }) => background || '#FFF'}; 
    transform: scale3d(1, 1, 1);
    ${({ fit }) => (fit !== 'child' ? `
      width: 100%;
      height: 100%;
    ` : `
      max-height: 90%;
      @media (max-width: ${({ theme }) => theme.breakpoints[1]})
        width: 90%;
      }
    `)}

    ${({ fit }) => (fit === 'lightbox' && `
      max-width: 900px;
      width: 80%;
      height: 80%;
      @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
        width: calc(100% - 200px);
      }
    `)}

    ${({ fit }) => (fit === 'cover' ? `
      top: 0;
      left: 0;
      transform: scale3d(1, 1, 1);
    `
    :
    `
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
    `)}
  `,
  PageContent: styled.div`
    width: 100%;
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
  `,
  ChildrenWrapper: styled.div`
    ${({ pageSpacing }) => pageSpacing && 'padding-top: 65px;'}
    height: 100%;
  `,
  Prev,
  Next: styled(Prev)`
    left: initial;
    right: -40px;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      right: -54px;
    }
    transform: rotate(180deg);
  `,
  CloseButton: styled.button`
    height: 60px;
    width: 60px;
    cursor: pointer;
    background: transparent;
    border: none;
    position: fixed;
    right: 20px;
    top: 0;
    z-index: 100;
    svg {
      width: 20px;
      top: 50%;
      lefT: 50%;
    }
    ${({ theme }) => theme === 'light' && `
      background: transparent;
      svg {
        fill: #000;
      }
    `}
  `,
};
