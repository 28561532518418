import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import authTypes from '../../auth/types';
import actions from '../actions';

import { getTokensApi } from '../../api';

export function* getUserDataFlow() {
  yield put(
    actions.getTokensRequested(),
  );
  try {
    const TokensData = yield call(getTokensApi);
    const tokens = {};
    TokensData.data.map((item) => {
      tokens[item.id] = item.tokenValue;
      return null;
    });

    yield put(
      actions.getTokensSucceeded(tokens),
    );
  } catch (error) {
    yield put(
      actions.getTokensFailed(error),
    );
  }
}

export function* watchAppInit() {
  yield takeLatest(
    authTypes.USER_SESSION_REQUESTED,
    getUserDataFlow,
  );
}

export default watchAppInit;
