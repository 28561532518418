import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import numeral from 'numeral';

import Input from '../../components/forms/Input';

import {
  formatters,
} from '../../coreui';

const cleanValue = (value) => {
  const cleanValue = typeof value === 'string' ? value
    .replace(/\..*$/, '')
    // Replace non-digits.
    .replace(/[\D]/g, '') : value;
  const parsedValue = parseInt(cleanValue, 10);
  return (parsedValue > 0 ? parsedValue : '');
};

const formatValue = (value, isFocused) => {
  if (parseInt(value, 10) > 0) {
    return numeral(value).format(
      formatters.DOLLARS,
    );
  }
  return isFocused ? '$' : '';
};

const DollarInput = ({
  name,
  value,
  onFocus,
  onBlur,
  onChange,
  onInput,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState();
  const [localValue, setLocalValue] = useState(formatValue(cleanValue(value), isFocused));

  const handleFocus = useCallback((e) => {
    setIsFocused(true);
    if (value === '') {
      setLocalValue('$');
    }
    onFocus(e);
  }, [onFocus, value, setLocalValue]);

  const handleBlur = useCallback((e) => {
    if (value === '') {
      setLocalValue('');
    }
    setIsFocused(false);
    onBlur(e);
  }, [onBlur, value, setLocalValue]);

  const handleInput = useCallback((e) => {
    const value = cleanValue(e.target.value);
    const formattedValue = formatValue(value, true);
    setLocalValue(formattedValue);

    onChange({
      ...e,
      target: {
        ...e.target,
        name,
        value,
      },
    });
    onInput(e);
  }, [onInput, name, onChange]);

  return (
    <Input
      name={name}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={localValue}
      onChange={noop}
      onInput={handleInput}
      maxLength="20"
      {...props}
    />
  );
};

DollarInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
};

DollarInput.defaultProps = {
  onFocus: noop,
  onBlur: noop,
  onChange: noop,
  onInput: noop,
};

export default DollarInput;
