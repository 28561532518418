import {
  takeEvery,
  take,
  call,
  put,
  all,
  actionChannel,
} from 'redux-saga/effects';
import {
  buffers,
} from 'redux-saga';
import { noop } from 'lodash';

import types from '../types';
import actions from '../actions';
import { setIrIsq } from '../../api';

export function* setIsqFlow(action) {
  const {
    ir,
    data,
    promise: {
      resolve,
      reject,
    } = {},
  } = action;
  try {
    const response = yield call(setIrIsq, ir, data);
    yield all([
      call(resolve || noop, { data: response.data }),
      put(
        actions.setIrIsqSucceeded(
          response.data,
        ),
      ),
    ]);
  } catch (error) {
    yield all([
      call(reject || noop, error),
      put(
        actions.setIrIsqFailed(
          error,
        ),
      ),
    ]);
  }
}

export function* watchSetIrIsqOld() {
  yield takeEvery(
    types.SET_IR_ISQ_REQUESTED,
    setIsqFlow,
  );
}

export function* watchSetIrIsq() {
  // Watch for IR ISQ updates. If we get many requests, e.g., user rapidly
  // completes ISQ, drop the earlier requests and only keep the latest.
  // This means that we'll at the very least process the first click of continue
  // and the last click of continue.
  const chan = yield actionChannel(types.SET_IR_ISQ_REQUESTED, buffers.sliding(1));
  while (true) {
    const action = yield take(chan);
    yield call(setIsqFlow, action);
  }
}

export default watchSetIrIsq;
