/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ModalWithOverlay,
  Data,
  Body,
  ExtendedFlex,
  Numeral,
  Formatters,
  StyledButton,
} from '../../../coreui';

const ConfirmModal = (passedProps) => {
  const {
    submitting,
    header,
    subtext,
    body,
    amount,
    bankAccount,
    handleSubmit,
    handleClick,
    error,
    ...props
  } = passedProps;

  return (
    <ModalWithOverlay handleClick={handleClick} {...props}>
      <ExtendedFlex
        flexDirection="column"
        align="center"
        m={[null, null, 30]}
        data-test="confirm-cancel-popup"
      >
        <Data type="D2" textTransform="uppercase">
          {header}
        </Data>
        {subtext && (
          <Body type="B1" textAlign="center" textTransform="uppercase">
            {subtext}
          </Body>
        )}
        {body}
        {!body && (
          <Body type="B1" textAlign="center">
            Would you like to continue with the recurring investment amount of{' '}
            {<Numeral value={amount} format={Formatters.DOLLARS_DECIMALS} />}{' '}
            from your Bank Account {bankAccount?.label}?
          </Body>
        )}
        <ExtendedFlex
          w={1}
          my={30}
          justify="center"
          align="center"
          flexDirection={['column', null, 'row']}
        >
          <StyledButton
            type="L4"
            onClick={handleClick}
            color="gray.7"
            mt={[30, null, 0]}
            loading={submitting}
            disable={submitting}
          >
            No, edit this amount.
          </StyledButton>
          <ExtendedFlex w="15%" display={['none', null, 'block']} />
          <StyledButton
            type="L4"
            onClick={handleSubmit}
            loading={submitting}
            disable={submitting}
          >
            Yes, confirm this amount.
          </StyledButton>
        </ExtendedFlex>
        {(['string', 'boolean'].includes(typeof error) && (
          <ExtendedFlex
            w={1}
            my={30}
            justify="center"
            align="center"
            flexDirection={['column', null, 'row']}
          >
            <Data type="D4" display="block" my={2} color="red">
              {error === true && 'An unknown error has occurred.'}
              {error !== true && error}
            </Data>
          </ExtendedFlex>
        )) ||
          error}
      </ExtendedFlex>
    </ModalWithOverlay>
  );
};

ConfirmModal.propTypes = {
  submitting: PropTypes.bool,
  header: PropTypes.string,
  subtext: PropTypes.string,
  body: PropTypes.string,
  amount: PropTypes.number,
  bankAccount: PropTypes.shape({
    label: PropTypes.string,
  }),
  handleSubmit: PropTypes.func,
  handleClick: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

ConfirmModal.defaultProps = {
  header: 'Confirm requested investment amount',
  submitting: false,
};

export default ConfirmModal;
