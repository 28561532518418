import React from 'react';
import PropTypes from 'prop-types';
import {
  SocialMediaIconBase,
} from '..';

import TwitterIconBlue from '../assets/social_twitter_blue.svg';
import YoutubeIconBlue from '../assets/social_youtube_blue.svg';
import LinkedInIconBlue from '../assets/social_linkedin_blue.svg';
import InstagramIconBlue from '../assets/social_ig_blue.svg';
import FacebookIconBlue from '../assets/social_fb_blue.svg';

import TwitterIconGray from '../assets/social_twitter_gray.svg';
import YoutubeIconGray from '../assets/social_youtube_gray.svg';
import LinkedInIconGray from '../assets/social_linkedin_gray.svg';
import InstagramIconGray from '../assets/social_ig_gray.svg';
import FacebookIconGray from '../assets/social_fb_gray.svg';

export const socialMediaIcons = {
  blue: {
    twitter: TwitterIconBlue,
    facebook: FacebookIconBlue,
    youtube: YoutubeIconBlue,
    linkedin: LinkedInIconBlue,
    instagram: InstagramIconBlue,
  },
  gray: {
    twitter: TwitterIconGray,
    facebook: FacebookIconGray,
    youtube: YoutubeIconGray,
    linkedin: LinkedInIconGray,
    instagram: InstagramIconGray,
  },
};

const SocialMediaIcon = ({ icon, url, color }) => {
  return (
    <SocialMediaIconBase
      bgImage={socialMediaIcons[color][icon]}
      bgRepeat="no-repeat"
      bgPosition="center center"
      bgSize="auto 18px"
      w={25}
      height={20}
      href={url}
      ml={5}
    />
  );
};

SocialMediaIcon.defaultProps = {
  color: 'gray',
};

SocialMediaIcon.propTypes = {
  icon: PropTypes.oneOf([
    'twitter',
    'youtube',
    'facebook',
    'instagram',
    'linkedin',
  ]),
  url: PropTypes.string,
  color: PropTypes.oneOf([
    'blue',
    'gray',
  ]),
};

export default SocialMediaIcon;
