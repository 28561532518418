import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import numeral from 'numeral';
import Styled from './index.styled';

const StatItem = ({
  label,
  value,
}) => (
  <Styled.StatItem>
    <span>{label}</span>
    <div className="spacer" />
    <span>{value}</span>
  </Styled.StatItem>
);

const PortfolioSlide = ({
  name,
  image,
  active,
  location,
  investmentType,
  propertyType,
  amount,
  dateOfInvestment,
  whyWeLikeIt,
  investmentHighlights,
  displayRow,
  issuer,
  totalValue,
  dateCompleted,
}) => (
  <Styled.PortfolioSlide>
    <Styled.SlideHeader>
      <div className="name">{name}</div>
      <div className="location">{location}</div>
    </Styled.SlideHeader>
    <div style={{ background: '#FFF' }}>
      <div>
        <img src={image} width="100%" alt={name} />
      </div>
      <Styled.Info>
        <Styled.StatsContainer>
          {investmentType && <StatItem label="Investment Type" value={investmentType} />}
          {propertyType && <StatItem label="Property Type" value={propertyType} />}
          {amount && <StatItem label="Invested" value={numeral(amount).format('$0,0')} />}
          {dateOfInvestment && <StatItem label="Date of Investment" value={dateOfInvestment} />}
          {
            displayRow && ((totalValue && active === '1') || (dateCompleted && active !== '1')) &&
            <StatItem
              label={active === '1' ? 'Total Property Value*' : 'Date Completed'}
              value={active === '1' ? numeral(totalValue).format('$0,0') : dateCompleted}
            />
          }
        </Styled.StatsContainer>
        {
          whyWeLikeIt && active === '1' &&
          <Styled.BodyCopy>
            <h2>Highlights</h2>
            {whyWeLikeIt.map((paragraph) => (ReactHtmlParser(paragraph.value)))}
          </Styled.BodyCopy>
        }
        {
          investmentHighlights && active !== '1' &&
          <Styled.BodyCopy>
            <h2>Investment Summary</h2>
            {ReactHtmlParser(investmentHighlights)}
          </Styled.BodyCopy>
        }
        {
          displayRow && active === '1' &&
          <Styled.Disclaimer>
            {`*Value of underlying property in ${issuer} based on appraisals dated within 6 months of the original acquisition by Realty Mogul, Co. or Realty Mogul Commercial Capital, Co., as applicable.`}
          </Styled.Disclaimer>
        }
      </Styled.Info>
    </div>
  </Styled.PortfolioSlide>
);

PortfolioSlide.propTypes = {
  name: PropTypes.string,
  active: PropTypes.bool,
  image: PropTypes.string,
  location: PropTypes.string,
  investmentType: PropTypes.string,
  propertyType: PropTypes.string,
  amount: PropTypes.string,
  dateOfInvestment: PropTypes.string,
  whyWeLikeIt: PropTypes.array,
  issuer: PropTypes.string,
  totalValue: PropTypes.string,
  dateCompleted: PropTypes.string,
  displayRow: PropTypes.bool,
  investmentHighlights: PropTypes.string,
};

StatItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export default PortfolioSlide;
