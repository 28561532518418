import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  ExtendedBox,
  Link,
  Heading,
  Data,
} from '../../coreui';
/* eslint-disable */

import Divider from '../../components/Divider';
import FadeIn from '../../components/FadeIn';

const FinancingCards = ({
  heading,
  range,
  leverage,
  value1,
  pricing,
  value2,
  term,
  value3,
  timetoclose,
  value4,
  value5,
  cashoncash,
  link,
}) => {
  return (
    <FadeIn>
      <ExtendedFlex
        w={[315, 355, 400, 450, 520]}
        height={[550, 550, 575, 650, 680]}
        position="relative"
        p={30}
        mx={5}
        mt={30}
        bg="white"
        flexDirection="column"
        transition="opacity 0.5s 0.2s linear"
        hoverShadow
        hoverRemoveFilter
      >
        <Heading
          type="H6"
          textAlign="center"
        >
          {heading}
        </Heading>
        <Data
          type="D2"
          m={10}
          textAlign="center"
        >
          {range}
        </Data>
        <ExtendedBox
          height={[400, 400, 450, 500, 550]}
        >
        <Divider
          type="horizontal"
          opacity={0.3}
        />
        <ExtendedFlex
          flexDirection={['row', 'row', 'row', 'row', 'row']}
          justifyContent="space-between"
          alignItems="center"
          maxWidth={450}
        >
          <Data
            type="D2"
            m={0}
            width={225}
          >
            {leverage}
          </Data>
          <Data
            type="D3"
            m={0}
            width={225}
            textAlign="left"
          >
            {value1}
          </Data>
        </ExtendedFlex>
        <Divider
          type="horizontal"
          opacity={0.3}
        />
        <ExtendedFlex
          flexDirection={['row', 'row', 'row', 'row', 'row']}
          justifyContent="space-between"
          alignItems="center"
          maxWidth={450}
        >
          <Data
            type="D2"
            m={0}
            width={225}
          >
            {pricing}
          </Data>
          <Data
            type="D3"
            m={0}
            width={225}
            textAlign="left"
          >
            {value2}
          </Data>
        </ExtendedFlex>
        <Divider
          type="horizontal"
          opacity={0.3}
        />
        { cashoncash &&
          value5 &&
          <ExtendedBox>
            <ExtendedFlex
              flexDirection={['row', 'row', 'row', 'row', 'row']}
              justifyContent="space-between"
              alignItems="center"
              maxWidth={450}
            >
              <Data
                type="D2"
                m={0}
                width={225}
                pr={25}
              >
                {cashoncash}
              </Data>
              <Data
                type="D3"
                m={0}
                width={225}
                textAlign="left"
              >
                {value5}
              </Data>
            </ExtendedFlex>
            <Divider
              type="horizontal"
              opacity={0.3}
            />
          </ExtendedBox>
        }
        <ExtendedFlex
          flexDirection={['row', 'row', 'row', 'row', 'row']}
          justifyContent="space-between"
          alignItems="center"
          maxWidth={450}
        >
          <Data
            type="D2"
            m={0}
            width={225}
          >
            {term}
          </Data>
          <Data
            type="D3"
            m={0}
            width={225}
            textAlign="left"
          >
            {value3}
          </Data>
        </ExtendedFlex>
        <Divider
          type="horizontal"
          opacity={0.3}
        />
        <ExtendedFlex
          flexDirection={['row', 'row', 'row', 'row', 'row']}
          justifyContent="space-between"
          alignItems="center"
          maxWidth={450}
        >
          <Data
            type="D2"
            m={0}
            width={225}
          >
            {timetoclose}
          </Data>
          <Data
            type="D3"
            m={0}
            width={225}
            textAlign="left"
          >
            {value4}
          </Data>
        </ExtendedFlex>
      </ExtendedBox>
        <ExtendedBox>
          <ExtendedFlex
            mx="auto"
            justifyContent="center"
          >
            <Link
              type="L1"
              href={link}
            >
              APPLY
            </Link>
          </ExtendedFlex>
        </ExtendedBox>
      </ExtendedFlex>
    </FadeIn>
  );
};

FinancingCards.propTypes = {
  heading: PropTypes.string,
  range: PropTypes.string,
  leverage: PropTypes.string,
  value1: PropTypes.string,
  pricing: PropTypes.string,
  value2: PropTypes.string,
  term: PropTypes.string,
  value3: PropTypes.string,
  timetoclose: PropTypes.string,
  value4: PropTypes.string,
  value5: PropTypes.string,
  cashoncash: PropTypes.string,
  link: PropTypes.string,
};

export default FinancingCards;
