import types from './types';

const initialState = {
};

const getImage = (images) => {
  const image = {};
  if (!images) {
    return image;
  }
  if (images.thumbnail) {
    image.thumb = images.thumbnail.url;
  }
  if (images.modal) {
    image.modal = images.modal.url;
  }
  if (images.modal_large) {
    image.modalLarge = images.modal_large.url;
  }
  return image;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REITS_DATA_SUCCEEDED: {
      const data = action.data.data.map((item) => {
        const io = { ...item };
        if (io.reitPriceInfo) {
          io.historical = {
            purchasePrice: io.reitPriceInfo.cost.value,
            purchasePriceDate: io.reitPriceInfo.cost.effectiveDate,
            navPrice: io.reitPriceInfo.nav.value,
            navPriceDate: io.reitPriceInfo.nav.effectiveDate,
          };
        }
        const propTypeKey = 'propertyType';
        const propTypeKeyStat = 'propertyTypeMain';

        if (io.id === 274949) {
          io.shortLabel = 'The Apartment Growth REIT';
        } else {
          io.shortLabel = 'The Income REIT';
        }
        const currentTotal = io.assets
          .filter(({ active, currentBalance }) => Number(active) === 1 && currentBalance)
          .reduce(((currentTotal, { currentBalance }) => currentTotal + currentBalance), 0.0);

        const currentAssets = io.assets
          .filter((asset) => Number(asset.active) === 1)
          .map((({ amountInvested: amount = 0.0, currentBalance = 0.0, ...asset }, i) => ({
            key: asset.id,
            i,
            name: asset.label,
            location: `${asset.address.locality}, ${asset.address.administrative_area}`,
            propertyType: asset[propTypeKey],
            propertyTypeStat: asset[propTypeKeyStat],
            investmentType: asset.investmentType,
            weight: currentBalance / currentTotal,
            amount,
            image: getImage(asset.images),
            dateOfInvestment: asset.dateOfInvestment,
            totalValue: asset.totalValue,
            highlights: asset.investmentHighlights,
            disclaimer: asset.disclaimer,
            whyWeLikeIt: asset.whyWeLikeIt,
            active: asset.active,
            displayRow: (asset.displayValue && asset.totalValue > 0 && 1) || null,
            region: asset.region,
            marketTier: asset.marketTier,
            issuer: io.issuer,
            investment: asset.investment,
          })));

        const pastAssets = io.assets
          .filter((asset) => Number(asset.active) !== 1)
          .map((({ amountInvested: amount = null, ...asset }, i) => ({
            key: asset.id,
            i,
            name: asset.label,
            location: `${asset.address.locality}, ${asset.address.administrative_area}`,
            propertyType: asset[propTypeKey],
            investmentType: asset.investmentType,
            amount,
            image: getImage(asset.images),
            dateOfInvestment: asset.dateOfInvestment,
            dateCompleted: asset.dateCompleted,
            totalValue: asset.totalValue,
            highlights: asset.investmentHighlights,
            disclaimer: asset.disclaimer,
            whyWeLikeIt: asset.whyWeLikeIt,
            active: asset.active,
            investmentHighlights: asset.investmentHighlights.value,
            displayRow: 1,
          })));

        io.assets = {
          pastAssets,
          currentAssets,
          snapHref: io.id === 274949
            ? '/materials/mr2/portfolio_snapshot.pdf'
            : '/materials/mr1/portfolio_snapshot.pdf',
        };

        return io;
      });

      return {
        ...state,
        data,
      };
    }
    case types.GET_REITS_DATA_FAILED:
      return {
        ...state,
        status: action.error.response.status.toString(),
      };
    case types.GET_REITS_FAQ_DATA_SUCCEEDED:
      return {
        ...state,
        faqItems: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};
