import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledButton,
  Body,
  ScrollToTopOnMount,
  InlineTextLink,
  TooltipLink,
  Tooltip,
} from '../../coreui';

import Form from '../../components/forms/Form';
import Actions from '../../components/forms/Actions';
import PrimaryButton from '../../components/forms/PrimaryButton';
import CheckboxLabel from '../../components/forms/CheckboxLabel';

import ShareRepurchaseHeading from './ShareRepurchaseHeading';
import ShareRepurchaseWrapper from './ShareRepurchaseWrapper';
import EnrollmentErrors from '../../dashboard/autoinvest/components/EnrollmentErrors';

const ShareRepurchaseAgreements = ({
  offeringCircular,
  percentLimitFormatted,
  daysProcessedEndOfQuarter,
  submitting,
  repurchaseRequestErrors,
  handleFormSubmit,
  handelCancelRequest,
}) => {
  return (
    <Form
      onSubmit={(e) => {
        handleFormSubmit(e);
      }}
    >
      <ScrollToTopOnMount />
      <ShareRepurchaseWrapper>
        <ShareRepurchaseHeading>
          Share Repurchase Request Form
        </ShareRepurchaseHeading>
        <Body type="B1">
          Please read the information below and check the boxes to confirm.
        </Body>
        <CheckboxLabel
          label={
            <Body type="B1" display="block" mt={3} mb={0}>
              You are able to request repurchase of up to{' '}
              {percentLimitFormatted} of your shares on a quarterly basis after
              your one year hold period, up to quarterly and annual limits
              outlined in the{' '}
              <InlineTextLink href={offeringCircular} target="_blank">
                offering circular
              </InlineTextLink>
              .
            </Body>
          }
          mb={50}
          inputProps={{
            'data-test': 'repurchase-request-limits',
            required: true,
          }}
        />
        <CheckboxLabel
          label={
            <Body type="B1" display="block" mt={3} mb={0}>
              If you are requesting a full share repurchase and are enrolled in
              our Distribution Reinvestment Program (DRIP) or Auto Invest, we will disable the DRIP and/or Auto Invest
              program(s) upon approval of your share repurchase.
            </Body>
          }
          mb={50}
          inputProps={{
            'data-test': 'disable-drip-autoinvest',
            required: true,
          }}
        />
        <CheckboxLabel
          label={
            <Body type="B1" display="block" mt={3} mb={0}>
              Approved repurchase requests transpire within{' '}
              {daysProcessedEndOfQuarter} days of the end of the applicable
              quarter. Upon submission of your repurchase request, please visit
              your Investor Dashboard for more information on the status of your
              repurchase.
            </Body>
          }
          mb={50}
          inputProps={{
            'data-test': 'approved-requests-processed',
            required: true,
          }}
        />
        <CheckboxLabel
          label={
            <Body type="B1" display="block" mt={3} mb={0}>
              Your repurchase request requires a signature for all authorized
              parties. We will email you a DocuSign Repurchase Package to
              execute within three business days upon submission of this
              repurchase request
            </Body>
          }
          mb={50}
          inputProps={{
            'data-test': 'docusign',
            required: true,
          }}
        />
        <CheckboxLabel
          label={
            <Body type="B1" display="block" mt={3} mb={0}>
              The Company will repurchase the shares at the most recently
              announced NAV per share, multiplied by the Effective Repurchase
              Rate. Please see the{' '}
              <InlineTextLink href={offeringCircular} target="_blank">
                Offering Circular
              </InlineTextLink>{' '}
              for additional information regarding repurchase rates.
            </Body>
          }
          mb={50}
          inputProps={{
            'data-test': 'repurchase-rate',
            required: true,
          }}
        />
        <CheckboxLabel
          label={
            <Body type="B1" display="block" mt={3} mb={0}>
              We intend to{' '}
              <TooltipLink
                component={<InlineTextLink />}
                tooltipId="limitedSharesTooltip"
              >
                limit the number of shares
              </TooltipLink>{' '}
              to be repurchased during any calendar year to 5.0% of the weighted
              average number of common shares outstanding during the prior
              calendar year (or 1.25% per quarter, with excess capacity carried
              over to later quarters in the calendar year). In the event that we
              do not have sufficient funds available to repurchase all of the
              common shares for which share repurchase requests have been
              submitted in any quarter, such pending requests will be honored on
              a pro rata basis.
            </Body>
          }
          mb={50}
          inputProps={{
            'data-test': 'limit-shares',
            required: true,
          }}
        />
        <Tooltip id="limitedSharesTooltip">
          <Body type="B3">
            While we designed our share repurchase program to allow shareholders
            to request share repurchases on a quarterly basis (subject to the
            holding period). we need to impose limitations on the total amount
            of net repurchase per quarter in order to maintain sufficient
            sources of liquidity to satisfy share repurchase requests without
            impacting our ability to invest in commercial real estate assets and
            maximize investor returns.
          </Body>
        </Tooltip>
        {repurchaseRequestErrors && (
          <EnrollmentErrors {...repurchaseRequestErrors} />
        )}
        <Actions>
          <PrimaryButton
            disabled={submitting}
            loading={submitting}
            buttonType="submit"
          >
            Continue
          </PrimaryButton>
          <StyledButton
            disabled={submitting}
            type="L4"
            onClick={handelCancelRequest}
          >
            Cancel request
          </StyledButton>
        </Actions>
      </ShareRepurchaseWrapper>
    </Form>
  );
};

ShareRepurchaseAgreements.propTypes = {
  offeringCircular: PropTypes.string,
  percentLimitFormatted: PropTypes.number,
  daysProcessedEndOfQuarter: PropTypes.number,
  repurchaseRequestErrors: PropTypes.object,
  submitting: PropTypes.bool,
  handleFormSubmit: PropTypes.func,
  handelCancelRequest: PropTypes.func,
};

export default ShareRepurchaseAgreements;
