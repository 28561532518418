import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Heading } from '../../coreui';
import RadioLabel from '../../components/forms/RadioLabel';
import RadioGroupInline from '../../components/forms/RadioGroupInline';
import FollowUp from '../../components/isq/FollowUp';

const Experience = (props) => {
  const [dirty, setDirty] = useState(false);
  const {
    isVisible,
    expRealEstate,
    expPrivateEquity,
    expVentureCapital,
    followUp,
    handleChange,
  } = props;

  const onChange = useCallback((e) => {
    setDirty(true);
    handleChange(e);
  }, [setDirty, handleChange]);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        Please indicate your level of experience with each of the following
        investment types:
      </Heading>
      <Heading
        type="H6"
      >
        Real Estate
      </Heading>
      <RadioGroupInline
        name="expRealEstate"
        onChange={onChange}
        inputProps={{
          required: true,
        }}
        value={expRealEstate}
      >
        <RadioLabel
          value="none"
          label="None"
        />
        <RadioLabel
          value="limited"
          label="Limited"
        />
        <RadioLabel
          value="extensive"
          label="Extensive"
        />
      </RadioGroupInline>
      <Heading
        type="H6"
      >
        Private Equity
      </Heading>
      <RadioGroupInline
        name="expPrivateEquity"
        onChange={onChange}
        inputProps={{
          required: true,
        }}
        value={expPrivateEquity}
      >
        <RadioLabel
          value="none"
          label="None"
        />
        <RadioLabel
          value="limited"
          label="Limited"
        />
        <RadioLabel
          value="extensive"
          label="Extensive"
        />
      </RadioGroupInline>
      <Heading
        type="H6"
      >
        Venture Capital
      </Heading>
      <RadioGroupInline
        name="expVentureCapital"
        onChange={onChange}
        inputProps={{
          required: true,
        }}
        value={expVentureCapital}
      >
        <RadioLabel
          value="none"
          label="None"
        />
        <RadioLabel
          value="limited"
          label="Limited"
        />
        <RadioLabel
          value="extensive"
          label="Extensive"
        />
      </RadioGroupInline>
      {
        expRealEstate === 'none'
        && expPrivateEquity
        && expVentureCapital
        && dirty &&
        <FollowUp
          subheader="This investment is an illiquid investment into real estate which is high risk. Do you confirm you have such knowledge and experience in financial and business matters that you can evaluate the risks of this investment and can bear the economic risk of this investment?"
          name="experienceFollowUp"
          value={followUp}
          handleChange={onChange}
          options={[
            {
              value: 'comfortable',
              label: 'Yes',
            },
            {
              value: 'not_comfortable',
              label: 'No, please cancel my investment',
            },
          ]}
        />
      }
    </>
  );
};

const options = [
  'none',
  'limited',
  'extensive',
];

Experience.propTypes = {
  isVisible: PropTypes.bool,
  handleChange: PropTypes.func,
  expRealEstate: PropTypes.oneOf(options),
  expPrivateEquity: PropTypes.oneOf(options),
  expVentureCapital: PropTypes.oneOf(options),
  followUp: PropTypes.string,
};

export default Experience;
