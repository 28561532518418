export const reverseHeaderMenuChildrenTransitionDelay = (
  items,
  isReversed = false,
) => {
  const updatedItems = items.map(
    (item) => {
      if (item.children.length > 0) {
        return {
          ...item,
          children: item.children.map(
            (child, index) => {
              const transitionDelay = isReversed ?
                Math.abs(index - item.children.length) :
                index;
              return {
                ...child,
                transitionDelay,
              };
            },
          ),
        };
      }
      return item;
    },
  );
  return updatedItems;
};

export const transformMenuItem = (item, state) => ({
  ...item,
  ...(state.isApp ? {
    // Only these states are part of the SPA in the isApp context.
    ...(item.machineName === 'my_dashboard' ? {
      // Do nothing.
    } : {
      url: `${state.frontendPrefix}${item.url}`,
      // Set Drupal to true so that it renders as an href.
      drupal: true,
    }),
  } : {}),
});

export default {
  reverseHeaderMenuChildrenTransitionDelay,
  transformMenuItem,
};
