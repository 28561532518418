import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReitCard from './ReitCard';

const ReitIIContent = ({ data, isApp, frontendPrefix }) => {
  const cashInvested = data.overview.cashInvested.value;
  const investedPage = cashInvested > 0 ?
    'invested' : 'investment-opportunity';
  const recurringMonthly = data.autoinvestConfigs.reduce((accum, item) => {
    if (item.status !== 'active') {
      return accum;
    }
    return accum + item.requestedAmount;
  }, 0);
  return (
    <ReitCard
      data={data}
      invested={data.transactions.length !== 0}
      investedPage={investedPage}
      isApp={isApp}
      frontendPrefix={frontendPrefix}
      recurringMonthly={recurringMonthly}
    />
  );
};

ReitIIContent.propTypes = {
  data: PropTypes.object,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    isApp: state.app.isApp,
    frontendPrefix: state.app.frontendPrefix,
  };
};

export default connect(mapStateToProps)(ReitIIContent);
