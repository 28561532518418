/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ScrollToTopOnMount,
  ExtendedBox,
  ExtendedFlex,
  Heading,
} from '../../coreui';

import DashboardNavLink from '../components/DashboardNavLink';
import HeaderFields from './components/HeaderFields';
import actions from './actions';
import dashboardActions from '../actions';
import MessagesTable from './components/MessagesTable';
import DashboardNotifications from '../components/DashboardNotifications';
import DashboardLoader from '../components/DashboardLoader';
import RedirectToLoginOnLoggedOut from '../../components/RedirectToLoginOnLoggedOut';

class Messages extends React.Component {
  constructor() {
    super();
    this.state = {
      filterVal: null,
    };
    this.onFilterChange = this.onFilterChange.bind(this);
    this.handleFilterReset = this.handleFilterReset.bind(this);
  }

  componentDidMount() {
    this.props.getMessageDataRequested();
    this.props.getPendingData();
  }

  onFilterChange(e) {
    if (!e) {
      return;
    }
    const { value } = e;
    this.setState({
      filterVal: value === 'Filter By Investment' ? null : value,
    });
  }

  handleFilterReset() {
    this.setState({
      filterVal: null,
    });
  }

  render() {
    const {
      loaded,
      messageValues,
      pendingData,
    } = this.props;
    const filterMessages = this.state.filterVal === null ?
      messageValues : messageValues.reduce((accum, item) => {
        if (item.investment !== this.state.filterVal) {
          return accum;
        }
        return [
          ...accum,
          item,
        ];
      },
      []);
    const headerFilterMessages = this.props.messageValues.reduce((accum, item) => {
      if (!accum.includes(item.investment)) {
        accum.push(item.investment);
      }
      return accum;
    }, []).sort();
    return (
      <ExtendedBox
        {...(!loaded ? {
          justify: 'flex-start',
          minHeight: [650, 700, 900, 1000, '100vh'],
        } : {})}
      >
        <ScrollToTopOnMount />
        <RedirectToLoginOnLoggedOut />
        <ExtendedFlex
          flexDirection="column"
          justify="center"
          width="100%"
          alignItems="center"
        >
          <DashboardNotifications pendingData={pendingData} />
          <Heading
            type="H2"
            my="0px"
            pt="100px"
            pb="0px"
          >
            My Dashboard
          </Heading>
          <DashboardNavLink />
        </ExtendedFlex>
        <ExtendedBox
          w={[1, 'auto']}
          px={0}
          pt={0}
          pb={140}
          maxWidth={1600}
          mx="auto"
        >
          <HeaderFields
            onChange={this.onFilterChange}
            investments={headerFilterMessages}
            selected={this.state.filterVal}
            onReset={this.handleFilterReset}
          />
          {
            loaded &&
            <MessagesTable
              mt={60}
              messages={filterMessages.map((item) => ({
                ...item,
                subject: `${item.investment}: ${item.subject}`,
              }))}
              showInvestmentLabel={false}
            />
          }
          <DashboardLoader loaded={loaded} />
        </ExtendedBox>
      </ExtendedBox>
    );
  }
}

Messages.propTypes = {
  loaded: PropTypes.bool,
  messageValues: PropTypes.array,
  getMessageDataRequested: PropTypes.func,
  getPendingData: PropTypes.func,
  pendingData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  loaded: state.messages.loaded,
  messageValues: state.messages.messageValues,
  pendingData: state.dashboard.pendingData.pendingData.investments,
});

const mapDispatchToProps = (dispatch) => ({
  getMessageDataRequested: () => {
    dispatch(actions.getMessageDataRequested());
  },
  getPendingData: () => {
    dispatch(dashboardActions.pendingDashboardDataRequested());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
