import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from './types';
import actions from './actions';
import { getLeadershipList } from '../api';

export function* getDataFlow() {
  try {
    const response = yield call(getLeadershipList);

    yield put(
      actions.getLeadershipListSucceeded(response.data),
    );
  } catch (error) {
    yield put(
      actions.getLeadershipListFailed(
        error,
      ),
    );
  }
}

export function* watchGetLeadershipList() {
  yield takeLatest(
    types.GET_LEADERSHIP_LIST_REQUESTED,
    getDataFlow,
  );
}
export default [
  watchGetLeadershipList(),
];
