/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  color,
  fontSize,
  fontWeight,
  fontFamily,
  letterSpacing,
  lineHeight,
} from 'styled-system';

import {
  bodyTypes,
} from '../coreui';

const StyledContent = styled.div`
  a,
  p a {
    color: #176DB6;
    text-decoration: none;
  }

  p {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  a,
  ul li,
  ol li,
  p,
table td {
    ${color}
    ${fontSize}
    ${fontWeight}
    ${fontFamily}
    ${letterSpacing}
    ${lineHeight}
  }
`;

const DisclaimerContent = ({ type, children }) => {
  return (
    <StyledContent
      {...bodyTypes[type]}
      color="black"
    >
      {children}
    </StyledContent>
  );
};

DisclaimerContent.propTypes = {
  children: PropTypes.object,
  type: PropTypes.string,
};

DisclaimerContent.defaultProps = {
  type: 'B3',
};

export default DisclaimerContent;
