import React from 'react';
import PropTypes from 'prop-types';
import RMButton from '../RMButton';
import Styled from './index.styled';
import Text from '../Text';

const Item = ({
  icon,
  title,
  description,
}) => (
  <Styled.Item>
    <div className="icon">{icon}</div>
    <div className="title">{title}</div>
    <div className="description"><Text>{description}</Text></div>
  </Styled.Item>
);

const ReitOverview = ({
  background = '#F2F2F2',
  title = 'Overview',
  description = [],
  items = [],
  cta,
}) => {
  return (
    <Styled.ReitOverview background={background}>
      <Styled.Container>
        <div style={{ width: '100%' }}>
          <h1><Text>{title}</Text></h1>
        </div>
        <Styled.DescriptionTile>
          {description.map((paragraph) => <p key={paragraph.slice(0, 10)}><Text>{paragraph}</Text></p>)}
        </Styled.DescriptionTile>
        <Styled.ContentTile>
          {items.map((item) => (<Item
            key={item.title}
            icon={item.icon}
            title={item.title}
            description={item.description}
          />))}
        </Styled.ContentTile>
        {
          cta &&
          <Styled.ButtonContainer>
            <RMButton href={cta.href} styleType={cta?.type ? cta.type : 'Primary'} target="_blank">{cta.label}</RMButton>
          </Styled.ButtonContainer>
        }
      </Styled.Container>
    </Styled.ReitOverview>
  );
};

ReitOverview.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.array,
  items: PropTypes.array,
  cta: PropTypes.object,
};

Item.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ReitOverview;
