import reitTooltips from '../reit/toolTipData';

export default {
  mr1: {
    id: 1,
    title: 'Nationwide',
    path: '/investment-opportunity/193744',
    status: 'status-10-open',
    statusLabel: 'Open for investment',
    REIT: true,
    imageName: 'MRI',
    propertyLocation: 'The Income REIT',
    investmentType: 'REIT',
    investmentTypeDisplay: null,
    minimum: 5000,
    payout: 1,
    payoutLabel: 'Monthly',
    blurb: 'A Real Estate Investment Trust with the objectives to pay attractive and consistent cash distributions; and to preserve, protect, increase and return your capital contribution.',
    payoutTooltip: reitTooltips.mr1.freq,
    lastDistributionDeclared: null,
  },
  mr2: {
    id: 3,
    title: 'Nationwide',
    path: '/investment-opportunity/274949',
    status: 'status-10-open',
    statusLabel: 'Open for investment',
    REIT: true,
    imageName: 'MRII',
    propertyLocation: 'The Apartment Growth REIT',
    investmentType: 'REIT',
    investmentTypeDisplay: null,
    minimum: 5000,
    payout: 1,
    payoutLabel: 'Quarterly',
    blurb: 'A Real Estate Investment Trust with the objectives to realize capital appreciation in the value of our investments over the long term; and to pay attractive and stable cash distributions to stockholders.',
    payoutTooltip: reitTooltips.mr1.freq,
    lastDistributionDeclared: null,
  },
};
