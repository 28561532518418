import React from 'react';

import {
  ExtendedFlex,
  Heading,
  Body,
  Link,
} from '../coreui';

export const EquitySuccess = () => {
  return (
    <ExtendedFlex
      bg="#FFFFFF"
      flexDirection="column"
      justify="center"
      align="left"
      px="10%"
      pt="8%"
      pb="15%"
      maxWidth={1024}
      w={1}
    >
      <Heading
        type="H2"
        textTransform="uppercase"
        color="black"
        mb={20}
      >
        application received
      </Heading>
      <Body
        type="B1"
      >
        Thank you for your interest in partnering with RealtyMogul to finance your project.
      </Body>
      <Body
        type="B2"
      >
        We will contact you soon regarding your inquiry.
      </Body>
      <ExtendedFlex>
        <Link
          type="L4"
          to="/"
          mt={60}
          textTransform="uppercase"
        >
          RealtyMogul Home &gt;
        </Link>
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

export default EquitySuccess;
