import React from 'react';
import PropTypes from 'prop-types';
import { ForgotPassword } from 'aws-amplify-react';
import {
  Auth,
} from 'aws-amplify';

import {
  Heading,
  Body,
} from '../../coreui';

import Container from './Container';
import FirstContainer from './FirstContainer';
import SecondContainer from './SecondContainer';

import Form from '../../components/forms/Form';
import Label from '../../components/forms/Label';
import Input from '../../components/forms/Input';
import PasswordInput from '../../components/forms/PasswordInput';
import Questions from './Questions';
import PrimaryButton from '../../components/forms/PrimaryButton';
import SecondaryButton from '../../components/forms/SecondaryButton';
import Actions from '../../components/forms/Actions';
import trackError from './trackError';

class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      submitting: false,
      requiredPasswordReset: false,
      resendSubmitting: false,
      email: undefined,
    };
    this._validAuthStates = ['forgotPassword'];

    this.updateState = this.updateState.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleResend = this.handleResend.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if changed delivery state.
    if (prevState.delivery !== this.state.delivery) {
      this.updateState({ submitting: false });
    }
    // Check if the authState changed.
    if (prevProps.authState !== this.props.authState) {
      // If we're on the forgotPassword flow...
      if (this.props.authState === 'forgotPassword') {
        // A password reset might be required. We detect this by seeing if there
        // is a username (email) passed in with the props.
        // If there isn't, the flag in state will be reset.
        const requiredPasswordReset = !!(this.props.authData && this.props.authData.username);
        if (requiredPasswordReset !== this.state.requiredPasswordReset) {
          this.updateState({
            requiredPasswordReset,
          });
        }
      } else if (this.state.submitting) {
        this.updateState({ submitting: false });
      }
    }
  }

  error(err) {
    if (this.state.submitting) {
      this.updateState({ submitting: false });
    }
    trackError('Forgot Password Error', { error: err });
    super.error(err);
  }

  updateState(state) {
    this.setState(state);
  }

  handleResend(...args) {
    this.error('Code resent to your email. Please check your spam/junk folder.');
    this.send(...args);
    this.updateState({
      resendSubmitting: true,
    });

    setTimeout(() => {
      this.updateState({
        resendSubmitting: false,
      });
    }, 5000);
  }

  handleFormSubmit(e, callback) {
    e.preventDefault();
    if (!this.state.submitting) {
      this.updateState({ submitting: true });
      callback();
    }
  }

  submit() {
    const { authData = {} } = this.props;
    const { code, password } = this.inputs;
    const username = this.inputs.username || authData.username;

    if (!Auth || typeof Auth.forgotPasswordSubmit !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    Auth.forgotPasswordSubmit(username, code, password)
      .then(() => {
        this.changeState('forgotPasswordSuccess');
        this.setState({ delivery: null });
        super.error('Your password reset was successful.');
      })
      .catch((err) => this.error(err));
  }

  sendView() {
    return (
      <>
        <Label
          htmlFor="email"
        >
          E-mail
          <Input
            id="email"
            key="username"
            name="username"
            onChange={(e) => {
              e.target.value = (e.target.value || '').toLowerCase();
              const { value } = e.target;
              this.updateState({ email: value });
              this.handleInputChange(e);
            }}
            type="email"
            placeholder="E-mail"
            autoComplete="email"
            data-test="email"
            required
          />
        </Label>
      </>
    );
  }

  submitView() {
    return (
      <>
        <input
          id="email"
          key="username"
          name="username"
          value={(this.props.authData && this.props.authData.username) || this.state.email}
          type="hidden"
          disabled
          autoComplete="email"
        />
        <Label
          htmlFor="code"
        >
          Code
          <Input
            id="code"
            key="code"
            name="code"
            autoComplete="off"
            onChange={this.handleInputChange}
            placeholder="Code"
            onInput={(e) => {
              const value = e.target.value
                // Replace non-digits.
                .replace(/[^\S]/g, '');
              e.target.value = value;
            }}
            pattern="^[\S]+$"
            data-test="code"
            required
          />
        </Label>
        <Label
          htmlFor="password"
        >
          New Password
          <PasswordInput
            id="password"
            placeholder="******************"
            key="password"
            name="password"
            autoComplete="new-password"
            onChange={this.handleInputChange}
            data-test="password"
            required
          />
        </Label>
      </>
    );
  }

  showComponent() {
    const { authData = {} } = this.props;

    return (
      <Container>
        <FirstContainer>
          <Heading
            type="H2"
          >
            Forgot Password.
          </Heading>
          {
            this.state.requiredPasswordReset &&
            <Body
              type="B1"
            >
              A password reset is required.
            </Body>
          }
          <Body
            type="B1"
          >
            {
              this.state.delivery || authData.username
                ? 'Enter the code sent to your email address below and your new password.'
                : 'Enter your email address to receive a code to reset your password.'
            }
          </Body>
          <Form
            onSubmit={(e) => this.handleFormSubmit(
              e,
              this.state.delivery || authData.username
                ? this.submit
                : this.send,
            )}
          >
            {this.state.delivery || authData.username ? this.submitView() : this.sendView()}
            <Actions>
              {this.state.delivery || authData.username
                ? (
                  <PrimaryButton
                    buttonType="submit"
                    loading={this.state.submitting}
                    disable={this.state.submitting}
                  >
                    Submit
                  </PrimaryButton>
                )
                : (
                  <PrimaryButton
                    buttonType="submit"
                    loading={this.state.submitting}
                    disable={this.state.submitting}
                  >
                    Send Code
                  </PrimaryButton>
                )}
              {this.state.delivery || authData.username
                ? (
                  <SecondaryButton
                    type="L4"
                    onClick={this.handleResend}
                    disabled={this.state.resendSubmitting}
                  >
                    Resend Code
                  </SecondaryButton>
                )
                : (
                  <SecondaryButton
                    type="L4"
                    onClick={() => this.changeState('signIn')}
                  >
                    Back to Sign In
                  </SecondaryButton>
                )}
            </Actions>
          </Form>
          <Questions />
        </FirstContainer>
        <SecondContainer />
      </Container>
    );
  }
}

CustomForgotPassword.propTypes = {
  is403: PropTypes.bool,
};

export default CustomForgotPassword;
