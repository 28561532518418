import React from 'react';
import PropTypes from 'prop-types';

import { CarouselQuotes } from '../../coreui';
import CarouselQuote from '../../components/CarouselQuote';

const CareersQuotes = ({ quotes }) => {
  return (
    <CarouselQuotes>
      {
        quotes.map(({ image, title, name, joined, quote }) => (
          <CarouselQuote
            key={name}
            image={image}
            body={`“${quote}”`}
            name={`${name}, joined RealtyMogul in ${joined}`}
            title={title}
          />
        ))
      }
    </CarouselQuotes>
  );
};

CareersQuotes.propTypes = {
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      title: PropTypes.string,
      joined: PropTypes.number,
      quote: PropTypes.string,
    }),
  ),
};

export default CareersQuotes;
