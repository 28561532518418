/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';

import {
  Link,
  Body,
  Image,
  ExtendedBox,
  ExtendedFlex,
  TooltipIcon,
  Tooltip,
} from '../coreui';

import FlagButton from './FlagButton';

import icon from '../assets/icon_smart_message.svg';

const SmartButton = (props) => {
  const {
    label,
    buttonHref,
    helpText,
    tooltipText,
    flag,
    testid,
    ...additionalProps
  } = props;
  const uniqueToolTipId = tooltipText && uniqueId('smartButtonToolTipText');
  return (
    <>
      {
        helpText &&
        <ExtendedFlex
          alignSelf="flex-start"
          m="auto"
          flexDirection="row"
          mt={40}
          maxWidth={340}
          justifyContent="center"
          alignItems="flex-start"
        >
          {
            !tooltipText &&
            <Image
              src={icon}
              mt={3}
              mr={10}
            />
          }
          <ExtendedBox
            display="inline-block"
          >
            <Body
              type="B3"
              m={0}
            >
              {helpText}
            </Body>
            {
              tooltipText &&
              <>
                <TooltipIcon
                  tooltipId={uniqueToolTipId}
                  ml={5}
                  display="inline-block"
                />
                <Tooltip id={uniqueToolTipId} >
                  <Body type="B3">
                    {tooltipText}
                  </Body>
                </Tooltip>
              </>
            }
          </ExtendedBox>
        </ExtendedFlex>
      }
      {
        buttonHref &&
        label &&
        !flag &&
        <Link
          mt={(helpText) ? 20 : 40}
          href={buttonHref}
          data-testid={testid || 'rm-smart-button'}
          {...additionalProps}
        >
          {label}
        </Link>
      }
      {
        flag &&
        <FlagButton
          flag={flag}
          flagged={(flag.flagged) ? flag.flagged : false}
          data-testid={testid || 'rm-smart-button'}
        />
      }
    </>
  );
};

SmartButton.propTypes = {
  label: PropTypes.string,
  buttonHref: PropTypes.string,
  helpText: PropTypes.string,
  tooltipText: PropTypes.string,
  flag: PropTypes.object,
  testid: PropTypes.string,
};

export default SmartButton;
