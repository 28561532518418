import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ExtendedFlex,
  Text,
  Switch,
  Body,
  Tooltip,
  TooltipIcon,
} from '../../coreui';

import actions from '../actions';

class DistributionsToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.dripSetting,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.setState({
      checked,
    });
    const callback = checked ?
      this.props.reinvestDistributionsURL : this.props.receiveCashDistributionsURL;
    this.props.dripToggleRequested({ id: this.props.id, callback });
  }

  render() {
    const {
      isDisabled,
    } = this.props;

    return (
      <ExtendedFlex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        w={1}
        mt={10}
      >
        <Text
          type="D8"
          m={0}
          mr={[5, 5, 15]}
        >
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            Reinvest Distributions?
            <div style={{ marginLeft: '6px' }} >
              <TooltipIcon
                tooltipId="reinvest-tooltip"
              />
              <Tooltip id="reinvest-tooltip">
                <Body type="B5">
                  <p>
                    All funds sent to investors during the year will initially be classified as distributions. After year end, earnings and profits calculations will be performed to determine the break out between income and return of capital and will be reported through a Form 1099.
                  </p>
                  <p>
                    By selecting to participate in RealtyMogul.com&lsquo;s Distribution Reinvestment Plan (DRIP), you are agreeing to monitor and notify RealtyMogul.com in advance of the event that your continued participation in the DRIP would cause you to exceed applicable qualified purchaser limits as set forth in Regulation A of the Securities Act, and that you will terminate your participation in the DRIP.
                  </p>
                </Body>
              </Tooltip>
            </div>
          </span>
        </Text>
        <Switch
          disabled={isDisabled}
          label="Reinvest Distributions?"
          checked={this.state.checked}
          onChange={this.handleChange}
          on="Yes"
          off="No"
        />
      </ExtendedFlex>
    );
  }
}

DistributionsToggle.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isDisabled: PropTypes.bool,
  dripSetting: PropTypes.bool,
  reinvestDistributionsURL: PropTypes.string,
  receiveCashDistributionsURL: PropTypes.string,
  dripToggleRequested: PropTypes.func,
};

const mapStateToProps = () => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dripToggleRequested: ({ id, callback }) => {
      dispatch(actions.dripToggleApiActionRequested({ id, callback }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DistributionsToggle);
