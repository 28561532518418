import React from 'react';

import {
  Tooltip,
  TooltipLink,
  Body,
} from '../../coreui';

import {
  ACCREDITED_INVESTOR_TOOLTIP,
} from './tooltips';

export const PRIVATE_PLACEMENT_ONLY = 'PRIVATE_PLACEMENT_ONLY';
export const REIT_ONLY = 'REIT_ONLY';
export const PP_AND_REIT = 'PP_AND_REIT';
export const NO_INVESTMENTS = 'NO_INVESTMENTS';

const preAccreditationMessage = (
  <>
    You currently don&#39;t have access to Private Placement investment
    opportunities. Private Placement investment opportunities can only be
    invested in by{' '}
    <TooltipLink tooltipId="accreditedInvestor" type="L4">
      accredited investors
    </TooltipLink>
    . Check your accreditation status now to see if you can access these
    investments.
    <Tooltip id="accreditedInvestor">
      <Body type="B5">{ACCREDITED_INVESTOR_TOOLTIP}</Body>
    </Tooltip>
  </>
);

export function checkIfReitsHaveInvestments(reits) {
  return reits.filter((reit) => {
    return reit.totalInvested > 0;
  }).length > 0;
}

export function getActiveInvestmentStatus(data) {
  const hasPrivatePlacementInvestment =
        data.activeInvestmentsData.privatePlacementData.ios.length > 0;
  const hasReitInvestment = checkIfReitsHaveInvestments(data.activeInvestmentsData.reitData.reits);

  if (hasPrivatePlacementInvestment && hasReitInvestment) {
    return PP_AND_REIT;
  }
  if (hasPrivatePlacementInvestment) {
    return PRIVATE_PLACEMENT_ONLY;
  }
  if (hasReitInvestment) {
    return REIT_ONLY;
  }
  return NO_INVESTMENTS;
}

export function applyStateAccreditedInvestorPrivatePlacementOnly(initialState) {
  return {
    ...initialState,
    showManageDistributionsAccountsLink: true,
    showReits: true,
    showReitAccordion: false,
    showReitAccordionRecentTransactionsTable: false,
    manageDistributionsButtonsDisabled: true,
    showPrivatePlacementAccordion: true,
  };
}

export function applyStateAccreditedInvestorReitOnly(initialState) {
  return {
    ...initialState,
    showPrivatePlacementCoC: true,
    showManageDistributionsAccountsLink: true,
    showReitAccordion: true,
    showReitAccordionRecentTransactionsTable: true,
    noPrivatePlacementText: `
            You currently have no investments in Private Placements. As an
            Accredited investor, you have access to our full portfolio of
            Private Placement Investment opportunities.`,
    noPrivatePlacementButtonContent: 'Browse Opportunities',
    noPrivatePlacementButtonURL: `${initialState.isApp ? initialState.frontendPrefix : ''}/investment-opportunities`,
  };
}

export function applyStateAccreditedInvestorPPAndReit(initialState) {
  return {
    ...initialState,
    showManageDistributionsAccountsLink: true,
    showReitAccordion: true,
    showReitAccordionRecentTransactionsTable: true,
    showPrivatePlacementAccordion: true,
  };
}

export function applyStateAccreditedInvestorNoInvestment(initialState) {
  return {
    ...initialState,
    showManageDistributionsAccountsLink: false,
    showReitAccordion: true,
    showReitAccordionRecentTransactionsTable: false,
    manageDistributionsButtonsDisabled: true,
    showPrivatePlacementAccordion: true,
    noPrivatePlacementText: `
            You currently have no investments in Private Placements. As an
            Accredited investor, you have access to our full portfolio of
            Private Placement Investment opportunities.`,
    noPrivatePlacementButtonContent: 'Browse Opportunities',
    noPrivatePlacementButtonURL: `${initialState.isApp ? initialState.frontendPrefix : ''}/investment-opportunities`,
  };
}

export function applyDefaultState(initialState) {
  return initialState;
}

export function setAccreditedInvestorState(initialState, jsonData) {
  const investmentStatus = getActiveInvestmentStatus(jsonData);
  let handler = {};
  switch (investmentStatus) {
    case PP_AND_REIT:
      handler = applyStateAccreditedInvestorPPAndReit;
      break;
    case REIT_ONLY:
      handler = applyStateAccreditedInvestorReitOnly;
      break;
    case PRIVATE_PLACEMENT_ONLY:
      handler = applyStateAccreditedInvestorPrivatePlacementOnly;
      break;
    case NO_INVESTMENTS:
      handler = applyStateAccreditedInvestorNoInvestment;
      break;
    default:
      handler = applyDefaultState;
      break;
  }

  return handler(initialState);
}

export function applyStateNonAccreditedInvestorReitOnly(initialState) {
  return {
    ...initialState,
    activeInvestmentsData: {
      ...initialState.activeInvestmentsData,
      privatePlacementData: undefined,
    },
    showPrivatePlacementCoC: false,
    showManageDistributionsAccountsLink: true,
    showReits: true,
    showReitAccordion: true,
    showReitAccordionRecentTransactionsTable: true,
    showPledgesAndWatchList: false,
    showPrivatePlacement: false,
  };
}

export function applyStateNonAccreditedInvestorNoInvestment(initialState) {
  return {
    ...initialState,
    activeInvestmentsData: {
      ...initialState.activeInvestmentsData,
      privatePlacementData: undefined,
    },
    showPrivatePlacementCoC: false,
    showManageDistributionsAccountsLink: false,
    showReitAccordion: true,
    showReitAccordionRecentTransactionsTable: false,
    manageDistributionsButtonsDisabled: true,
    showPledgesAndWatchList: false,
    showPrivatePlacement: false,
  };
}

export function setNonAccreditedInvestorState(initialState, jsonData) {
  const investmentStatus = getActiveInvestmentStatus(jsonData);

  let handler;
  switch (investmentStatus) {
    case REIT_ONLY:
      handler = applyStateNonAccreditedInvestorReitOnly;
      break;

    case NO_INVESTMENTS:
      handler = applyStateNonAccreditedInvestorNoInvestment;
      break;

    default:
      handler = applyDefaultState;
      break;
  }
  return handler(initialState);
}

export function applyStatePreAccreditedInvestorReitOnly(initialState) {
  return {
    ...initialState,
    showManageDistributionsAccountsLink: true,
    showReitAccordion: true,
    showReitAccordionRecentTransactionsTable: true,
    showPrivatePlacementAccordion: true,
    noPrivatePlacementText: preAccreditationMessage,
    noPrivatePlacementButtonContent: 'Browse Opportunities',
    noPrivatePlacementButtonURL: `${initialState.isApp ? initialState.frontendPrefix : ''}/investment-opportunities`,
  };
}

export function applyStatePreAccreditedInvestorNoInvestment(initialState) {
  return {
    ...initialState,
    showManageDistributionsAccountsLink: false,
    showReitAccordion: true,
    showReitAccordionRecentTransactionsTable: false,
    manageDistributionsButtonsDisabled: true,
    showPrivatePlacementAccordion: true,
    noPrivatePlacementText: preAccreditationMessage,
    noPrivatePlacementButtonContent: 'Browse Opportunities',
    noPrivatePlacementButtonURL: `${initialState.isApp ? initialState.frontendPrefix : ''}/investment-opportunities`,
  };
}

export function setPreAccreditedInvestorState(initialState, jsonData) {
  const investmentStatus = getActiveInvestmentStatus(jsonData);

  let handler;
  switch (investmentStatus) {
    case REIT_ONLY:
      handler = applyStatePreAccreditedInvestorReitOnly;
      break;

    case NO_INVESTMENTS:
      handler = applyStatePreAccreditedInvestorNoInvestment;
      break;

    default:
      handler = applyDefaultState;
      break;
  }
  return handler(initialState);
}

export default {
  setAccreditedInvestorState,
  setNonAccreditedInvestorState,
  setPreAccreditedInvestorState,
};
