/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  propTypes,
} from 'styled-system';

import { detect } from 'detect-browser';

import {
  FlipCore,
} from '..';

const none = {};
const zeroRotation = {
  transform: 'rotateY(0)',
};
const halfRotation = {
  transform: 'rotateY(180deg)',
};

class FlipCard extends React.Component {
  constructor(props) {
    super(props);
    const browser = detect();
    this.state = {
      showFront: true,
      browser: browser.name === 'ie' ? 'ie' : 'notie',
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState((state) => ({
      showFront: !state.showFront,
    }));
  }

  render() {
    const {
      height,
      front,
      back,
    } = this.props;

    const isIE = this.state.browser === 'ie';

    const w = this.props.w || this.props.width;

    return (
      <FlipCore.Container
        w={w}
        height={height}
        onClick={this.handleClick}
      >
        <FlipCore.Flipper
          {...(!this.state.showFront && !isIE ? halfRotation : none)}
        >
          <FlipCore.Front
            w={w}
            height={height}
            {...(!this.state.showFront && isIE ? halfRotation : none)}
          >
            {front}
          </FlipCore.Front>
          <FlipCore.Back
            w={w}
            height={height}
            {...(!this.state.showFront && isIE ? zeroRotation : none)}
          >
            {back}
          </FlipCore.Back>
        </FlipCore.Flipper>
      </FlipCore.Container>
    );
  }
}

FlipCard.propTypes = {
  ...propTypes.width,
  ...propTypes.height,
  front: PropTypes.node,
  back: PropTypes.node,
};

export default FlipCard;
