import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedFlex,
  ExtendedBox,
  Image,
  Flex,
  Link,
  StyledButton,
} from '../../coreui';

import {
  profileUrl,
} from '../../dashboard/links';

import HeaderMenuChildrenContainer from './HeaderMenuChildrenContainer';

import carat from '../../assets/Triangle.svg';

const HeaderUserMenuLoggedIn = ({
  user,
  handleMouseEnter,
  handleMouseLeave,
  handleLogout,
  hoveredItem,
  browser,
}) => {
  return (
    <ExtendedFlex
      flexDirection="column"
      display={['none', 'none', 'none', 'flex']}
      align="center"
      position="relative"
      justify="center"
    >
      <StyledButton
        type="L5"
        height={[70, 70, 80, 90]}
        display="block"
        id="user"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        whiteSpace="nowrap"
      >
        <ExtendedFlex
          id="user"
          height="inherit"
          align="center"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          hover
        >
          {
            user.firstName && user.lastName &&
            `${user.firstName} ${user.lastName.slice(0, 1)}.`
          }
          <Image
            src={carat}
            ml={2}
            id="user"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </ExtendedFlex>
      </StyledButton>

      {/* Fading in Active Nav Underline */}
      <ExtendedBox
        position="absolute"
        height={1}
        bg="black"
        w={1}
        bottom={[10, 25]}
        left={0}
        opacity={
          hoveredItem === 'user' ?
            '1' :
            '0'
        }
        transition="opacity 0.5s ease-in-out"
      />

      {/* Slide in / out container */}
      <HeaderMenuChildrenContainer
        handleMouseLeave={handleMouseLeave}
        handleMouseEnter={handleMouseEnter}
        firstLevelHoveredItem={hoveredItem}
        machineName="user"
        browser={browser}
      >
        <Flex
          flexDirection="column"
          align="center"
          justify="flex-start"
          id="user"
          onMouseEnter={handleMouseEnter}
        >
          <Link
            type="L5"
            opacity={hoveredItem === 'user' ? '1' : '0'}
            w={1}
            id="user"
            p={20}
            href={profileUrl}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            hoverBG="gray.0"
            display="block"
            whiteSpace="nowrap"
            textAlign="center"
            transition={
              `background 0.3s ease-in-out,
              opacity 0.3s ${hoveredItem === 'user' ? '0.10' : '0.25'}s ease-in-out`
            }
          >
            Profile
          </Link>
          <Link
            type="L5"
            opacity={hoveredItem === 'user' ? '1' : '0'}
            w={1}
            id="user"
            p={20}
            href="/user/logout"
            onClick={handleLogout}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            hoverBG="gray.0"
            display="block"
            whiteSpace="nowrap"
            textAlign="center"
            transition={
              `background 0.3s ease-in-out,
              opacity 0.3s ${hoveredItem === 'user' ? '0.25' : '0.10'}s ease-in-out`
            }
          >
            Sign Out
          </Link>
          {
            user.userSwitch &&
            <Link
              type="L5"
              opacity={hoveredItem === 'user' ? '1' : '0'}
              w={1}
              id="user"
              p={20}
              href={user.userSwitch}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              hoverBG="gray.0"
              display="block"
              whiteSpace="nowrap"
              textAlign="center"
              transition={
                `background 0.3s ease-in-out,
                opacity 0.3s ${hoveredItem === 'user' ? '0.25' : '0.10'}s ease-in-out`
              }
            >
              Switch Back
            </Link>
          }
        </Flex>
      </HeaderMenuChildrenContainer>
    </ExtendedFlex>
  );
};

HeaderUserMenuLoggedIn.propTypes = {
  user: PropTypes.object,
  handleLogout: PropTypes.func,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  hoveredItem: PropTypes.string,
  browser: PropTypes.string,
};

export default HeaderUserMenuLoggedIn;
