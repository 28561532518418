/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Link,
} from '..';

const TooltipLink = ({
  tooltipId,
  component,
  ...additionalProps
}) => {
  const tooltipProps = {
    'data-for': tooltipId,
    'data-tip': true,
    'data-event': 'touchstart focus mouseover',
    'data-event-off': 'mouseout',
    ...additionalProps,
  };
  if (component && React.isValidElement(component)) {
    return React.cloneElement(component, tooltipProps);
  }
  const Element = component || Link;
  return (
    <Element
      {...tooltipProps}
    />
  );
};

TooltipLink.propTypes = {
  tooltipId: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]),
};

export default TooltipLink;
