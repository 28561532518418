import React from 'react';
import { ExtendedFlex } from '../../../coreui';

const AutoInvestContentWrapper = (props) => {
  return (
    <ExtendedFlex
      w={1}
      flexDirection="column"
      px={50}
      maxWidth={1500}
      align="flex-start"
      // eslint-disable-next-line
      {...props}
    />
  );
};

export default AutoInvestContentWrapper;
