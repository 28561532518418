export default {
  GET_BROWSE_PAGE_DATA_REQUESTED: 'GET_BROWSE_PAGE_DATA_REQUESTED',
  GET_BROWSE_PAGE_DATA_SUCCEEDED: 'GET_BROWSE_PAGE_DATA_SUCCEEDED',
  GET_BROWSE_PAGE_DATA_FAILED: 'GET_BROWSE_PAGE_DATA_FAILED',
  CLOSE_WELCOME_MESSAGE_REQUESTED: 'CLOSE_WELCOME_MESSAGE_REQUESTED',
  CLOSE_WELCOME_MESSAGE_FAILED: 'CLOSE_WELCOME_MESSAGE_FAILED',
  CLOSE_WELCOME_MESSAGE_SUCCEEDED: 'CLOSE_WELCOME_MESSAGE_SUCCEEDED',
  UPDATE_BROWSE_NAV_ANIMATION_SETTINGS: 'UPDATE_BROWSE_NAV_ANIMATION_SETTINGS',
  GET_BROWSE_OPEN_DATA_REQUESTED: 'GET_BROWSE_OPEN_DATA_REQUESTED',
  GET_BROWSE_OPEN_DATA_SUCCEEDED: 'GET_BROWSE_OPEN_DATA_SUCCEEDED',
  GET_BROWSE_OPEN_DATA_FAILED: 'GET_BROWSE_OPEN_DATA_FAILED',
  GET_BROWSE_CLOSED_DATA_REQUESTED: 'GET_BROWSE_CLOSED_DATA_REQUESTED',
  GET_BROWSE_CLOSED_DATA_SUCCEEDED: 'GET_BROWSE_CLOSED_DATA_SUCCEEDED',
  GET_BROWSE_CLOSED_DATA_FAILED: 'GET_BROWSE_CLOSED_DATA_FAILED',
};
