import React from 'react';

import InvestmentCardInvestmentTypeDisplay from '../components/InvestmentCardInvestmentTypeDisplay';

const section1FactoryFactory = (props) => {
  const {
    investmentTypeDisplay,
    reit,
  } = props;

  const section1Factory = () => {
    return (
      <InvestmentCardInvestmentTypeDisplay
        investmentTypeDisplay={investmentTypeDisplay}
        reit={reit}
      />
    );
  };

  return section1Factory;
};

export default section1FactoryFactory;
