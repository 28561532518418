import types from './types';

const initialState = {
  id: null,
  success: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.POST_WEB_TO_SALESFORCE_REQUESTED: {
      return {
        ...state,
        success: null,
        id: action.data.id,
      };
    }

    case types.POST_WEB_TO_SALESFORCE_SUCCEEDED: {
      return {
        ...state,
        success: true,
      };
    }

    case types.POST_WEB_TO_SALESFORCE_FAILED: {
      return {
        ...state,
        success: false,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
