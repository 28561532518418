import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import types from '../types';
import actions from '../actions';

import { getIOFAQtDataApi } from '../../api';

import { privatePlacementFaqVersion2Id } from '../data';
import faqVersion1 from '../fixtures/faq_version_1.json';
import faqVersion2 from '../fixtures/faq_version_2.json';

const getDefaultIds = (data) => get(data, 'data', [])
  .reduce((accum, item) => ([
    ...accum,
    item.id,
  ]), []);

const getDefaultFaqs = (id) => {
  if (id < privatePlacementFaqVersion2Id) {
    return faqVersion1;
  }

  return faqVersion2;
};

export function* getFAQDataFlow(action) {
  // Check if we should resolve the FAQs locally.
  try {
    const response = yield call(getIOFAQtDataApi,
      action.opts.is1031,
      action.opts.isReit,
      action.opts.id);

    // Check the response to see if we need to just supply the new default FAQ.
    if (!action.opts.isReit && action.opts.id >= privatePlacementFaqVersion2Id) {
      const faqIds = getDefaultIds(response.data);

      // Determine which default to use.
      const defaultFaq = getDefaultFaqs(action.opts.id);

      // We need to compare the API response with what we expect has been the
      // default FAQ. This means always comparing it to FAQ version 1. If the
      // API responds with something different, this means that it was
      // overwritten with content and we need to respond with that now.
      const defaultFaqIds = getDefaultIds(faqVersion1);

      // Compare the IDs. If it matches our previous default ID, we make the
      // assumption that it should actually use the new default FAQ.
      if (isEqual(faqIds, defaultFaqIds)) {
        yield put(
          actions.getFAQDataSucceeded(defaultFaq),
        );
        return;
      }
    }

    yield put(
      actions.getFAQDataSucceeded(response.data),
    );
  } catch (error) {
    yield put(
      actions.getFAQDataFailed(error),
    );
  }
}

export function* watchGetFAQDataRequested() {
  yield takeLatest(
    types.GET_IO_FAQ_DATA_REQUESTED,
    getFAQDataFlow,
  );
}

export default watchGetFAQDataRequested;
