import axios from 'axios';

import { getIdToken } from './auth/api';

export const BROWSE_PAGE_URL = '/api/browse';
export const BROWSE_OPEN_PAGE_URL = '/api/v1.0/browse/open';
export const BROWSE_CLOSED_PAGE_URL = '/api/v1.0/browse/closed';
export const BROWSE_COMPLETED_EQUITY_PAGE_URL = '/api/v1.0/browse/completed-equity';
export const BROWSE_COMPLETED_DEBT_PAGE_URL = '/api/v1.0/browse/completed-debt';
export const BROWSE_FUNDED_ACTIVE_PAGE_URL = '/api/v1.0/browse/active';
export const KNOWLEDGE_CENTER_URL = '/api/v1.0/knowledge-center';
// FIXME: KNOWLEDGE_CENTER_ARTICLE_URL, KNOWLEDGE_CENTER_VIDEO_URL and all
// other knowledge center related content api's (guide, whitepapers, etc.)
// can probably be combined into 1 single api that takes a 'type' and 'id'.
// At the time of building this functionality, the api is constantly changing
// so I am opting to build them as separate api's until the dust settles.
export const KNOWLEDGE_CENTER_ARTICLE_URL = '/api/v1.0/alias/article';
export const KNOWLEDGE_CENTER_VIDEO_URL = '/api/v1.0/alias/video';
export const KNOWLEDGE_CENTER_SEARCH_URL = '/api/v1.0/search';
export const KNOWLEDGE_CENTER_POP_QUESTIONS_URL = '/api/v1.0/faqs';
export const KNOWLEDGE_CENTER_RELATED_CONTENT_URL = '/api/v1.0/related-content';
export const KNOWLEDGE_CENTER_SUBQUEUE_URL = '/api/v1.0/subqueue';
export const POPULAR_CONTENT_SUBQUEUE_ID = 'popular_articles';
export const PRIVATE_PLACEMENT_SUBQUEUE_ID = 'private_placement_page_faqs';
export const PRIVATE_PLACEMENT_1031_SUBQUEUE_ID = '1031_private_placement_page_faqs';
export const MOGULREIT_1_SUBQUEUE_ID = 'mogul_reit_1_faq';
export const MOGULREIT_2_SUBQUEUE_ID = 'mogul_reit_ii_faq';
export const KNOWLEDGE_CENTER_PAGE_SIZE = 6;
export const KNOWLEDGE_CENTER_PAGE_START = 0;
export const IO_DATA_URL = '/api/v1.0/node/investment_opportunity';
export const INVESTED_URL = '/api/v1.0/invested';
export const LOGOUT_USER_URL = '/user/logout';
export const ADMIN_MENU_URL = '/api/v1.0/admin-menu';
export const TOKEN_URL = 'https://api.realtymogul.com/webtokens';
export const USER_URL = '/api/v1.0/user';
export const USER_SESSION_URL = '/api/v1.0/session';
export const USER_REGISTER_URL = '/api/v1.0/register';
export const USER_LOGIN_URL = '/api/v1.0/login';
export const USER_LOGOUT_URL = '/api/v1.0/logout';
export const USER_ACCREDITATION_URL = '/api/v1.0/accreditation';
export const USER_ACCREDITATION_URL_NEW = '/api/v2.0/accreditation';
export const USER_GTKY_URL = '/api/v1.0/gtky';
export const FAQ_URL = '/api/v1.0/faqs';
export const GLOSSARY_URL = '/api/v1.0/glossary';
export const DSM_URL = '/api/v1.0/dsm';
export const REVALIDATE_EMAIL_URL = '/toboggan/revalidate';
export const WEB_TO_SALESFORCE_URL = '/api/v1.0/webtosalesforce';
export const ISQ_API = '/api/v1.0/isq';
export const INVESTMENTS_IN_PROGRESS_API = '/api/v1.0/investments-in-progress';
export const SHARE_REPURCHASE_REQUEST_API = '/api/v1.0/repurchase/request';
export const CAREERS_API = 'https://api.greenhouse.io/v1/boards/realtymogul/embed/departments';
export const LEADERSHIP_LIST_API = 'https://apis.realtymogul.com/sanity/lists/leadership';
export const CRE_TEAM_LIST_API = 'https://apis.realtymogul.com/sanity/lists/cre-team';
export const TESTIMONIAL_API = 'https://apis.realtymogul.com/sanity/lists/careers-quotes';
export const INVESTFLOW_API = typeof window !== 'undefined' && window?._env_?.REACT_APP_INVESTFLOW_API;
export const PLAID_API_ENDPOINT = '/api/v1.0/plaid';

export const getBrowseApi = () => {
  return axios.get(`${BROWSE_PAGE_URL}`, {
    withCredentials: true,
  });
};

export const getBrowseOpenApi = () => {
  return axios.get(`${BROWSE_OPEN_PAGE_URL}`, {
    withCredentials: true,
  });
};

export const getBrowseClosedApi = () => {
  return axios.get(`${BROWSE_CLOSED_PAGE_URL}`, {
    withCredentials: true,
  });
};

export const getBrowseCompletedEquityApi = () => {
  return axios.get(`${BROWSE_COMPLETED_EQUITY_PAGE_URL}`, {
    withCredentials: true,
  });
};

export const getBrowseCompletedDebtApi = () => {
  return axios.get(`${BROWSE_COMPLETED_DEBT_PAGE_URL}`, {
    withCredentials: true,
  });
};

export const getBrowseFundedApi = () => {
  return axios.get(`${BROWSE_FUNDED_ACTIVE_PAGE_URL}`, {
    withCredentials: true,
  });
};

export const getIOApi = (id) => {
  return axios.get(`${IO_DATA_URL}/${id}`, {
    withCredentials: true,
    params: {
      _format: 'json',
    },
  });
};

export const getInvestedApi = (id) => {
  return axios.get(`${INVESTED_URL}/${id}`, {
    withCredentials: true,
  });
};

export const getAdminMenuContent = () => {
  return axios.get(`${ADMIN_MENU_URL}`, {
    withCredentials: true,
  });
};

export const getKnowledgeCenterDataApi = (
  topic,
) => {
  return axios.get(`${KNOWLEDGE_CENTER_URL}`, {
    params: {
      _format: 'json',
      // eslint-disable-next-line camelcase
      content_type: 'all',
      category: topic,
      pageStart: KNOWLEDGE_CENTER_PAGE_START,
      pageSize: KNOWLEDGE_CENTER_PAGE_SIZE,
    },
  });
};

export const getGlossaryDataAPI = () => {
  return axios.get(`${GLOSSARY_URL}`, {
    params: {
      _format: 'json',
    },
  });
};

export const getFAQItemsDataApi = (queueId) => {
  return axios.get(`${KNOWLEDGE_CENTER_SUBQUEUE_URL}/${queueId}`, {
    params: {
      _format: 'json',
    },
  });
};

export const getPopularContentDataApi = () => {
  return axios.get(`${KNOWLEDGE_CENTER_SUBQUEUE_URL}/${POPULAR_CONTENT_SUBQUEUE_ID}`, {
    params: {
      _format: 'json',
    },
  });
};

export const getIOFAQtDataApi = (is1031, isReit, id) => {
  let subqueue = `${PRIVATE_PLACEMENT_SUBQUEUE_ID}_${id}`;
  if (is1031) { subqueue = PRIVATE_PLACEMENT_1031_SUBQUEUE_ID; }
  if (isReit && id === 274949) {
    subqueue = MOGULREIT_2_SUBQUEUE_ID;
  } else if (isReit) { subqueue = MOGULREIT_1_SUBQUEUE_ID; }

  return axios.get(`${KNOWLEDGE_CENTER_SUBQUEUE_URL}/${subqueue}`, {
    params: {
      _format: 'json',
    },
  });
};

export const getRelatedContentDataApi = () => {
  return axios.get(`${KNOWLEDGE_CENTER_RELATED_CONTENT_URL}`, {
    params: {
      _format: 'json',
    },
  });
};

export const callApiCallback = (callback, options = {}) => {
  return axios.get(`${callback}`, {
    withCredentials: true,
    ...options,
  });
};

export const setUserRegisterApi = (token, data) => {
  return axios({
    method: 'post',
    url: USER_REGISTER_URL,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postAccreditationApi = (data) => (
  getIdToken()
    .then((token) => (
      axios.post(USER_ACCREDITATION_URL, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ))
);

export const postAccreditationApiNew = (data) => (
  getIdToken()
    .then((token) => (
      axios.post(USER_ACCREDITATION_URL_NEW, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ))
);

export const getGettingToKnowYouApi = () => (
  getIdToken()
    .then((token) => (
      axios.get(USER_GTKY_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ))
);

export const postGettingToKnowYouApi = (data) => (
  getIdToken()
    .then((token) => (
      axios.post(USER_GTKY_URL, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ))
);

export const getUserLoginApi = (token, destination) => {
  return axios.get(USER_LOGIN_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: destination && {
      destination,
    },
  });
};

export const getUserLogoutApi = () => {
  return axios.get(USER_LOGOUT_URL);
};

export const getUserSessionApi = () => {
  return axios.get(`${USER_SESSION_URL}`, {
    withCredentials: true,
  });
};

export const getUserApi = () => {
  return axios.get(`${USER_URL}`, {
    withCredentials: true,
  });
};

export const setUserApi = (id, token, data) => {
  return axios.patch(`${USER_URL}/${id}`, data, {
    withCredentials: true,
    headers: {
      'X-CSRF-Token': token,
    },
  });
};

export const logoutUserApi = () => {
  return axios.get(LOGOUT_USER_URL);
};

export const getTokensApi = () => {
  return axios.get(`${TOKEN_URL}`);
};

export const getFaqApi = () => {
  return axios.get(`${FAQ_URL}`, {
    params: {
      _format: 'json',
    },
  });
};

export const getKnowledgeCenterArticleApi = (id) => {
  return axios.get(`${KNOWLEDGE_CENTER_ARTICLE_URL}/${id}`, {
    params: {
      _format: 'json',
    },
  });
};

export const getKnowledgeCenterVideoApi = (id) => {
  return axios.get(`${KNOWLEDGE_CENTER_VIDEO_URL}/${id}`, {
    params: {
      _format: 'json',
    },
  });
};

export const getKnowledgeCenterSearchApi = (query, pageNum) => {
  return axios.get(`${KNOWLEDGE_CENTER_SEARCH_URL}`, {
    params: {
      keys: query,
      pageStart: pageNum,
      _format: 'json',
    },
  });
};

export const getRetirementFAQDataApi = () => {
  return axios.get(`${KNOWLEDGE_CENTER_SUBQUEUE_URL}/retirement_questions`, {
    params: {
      _format: 'json',
    },
  });
};

export const getQueueArticleDataApi = (queue) => {
  return axios.get(`${KNOWLEDGE_CENTER_SUBQUEUE_URL}/${queue}`, {
    params: {
      _format: 'json',
    },
  });
};

export const getTriggerRevalidateEmail = (userId) => {
  return axios.get(`${REVALIDATE_EMAIL_URL}/${userId}`, {
    params: {
      destination: DSM_URL.substr(1),
    },
  });
};

export const getStatusMessages = () => {
  return axios.get(`${DSM_URL}`, {
    withCredentials: true,
  });
};

export const postWebToSalesforce = (type, data) => {
  return axios.post(`${WEB_TO_SALESFORCE_URL}/${type}`, data);
};

export const getLatestIsq = () => {
  return getIdToken()
    .then((token) => (
      axios.get(ISQ_API, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ));
};

export const setLatestIsq = (data) => {
  return getIdToken()
    .then((token) => (
      axios.post(ISQ_API, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ));
};

export const setIrIsq = (id, data) => {
  return getIdToken()
    .then((token) => (
      axios.patch(`${INVESTMENTS_IN_PROGRESS_API}/${id}`, {
        qualification: {
          body: data,
          request: {
            method: 'POST',
          },
        },
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: false,
      })
    ));
};

export const getCareersList = () => (
  axios.get(CAREERS_API)
);

export const getLeadershipList = () => (
  axios.get(LEADERSHIP_LIST_API)
);

export const getCRETeamList = () => (
  axios.get(CRE_TEAM_LIST_API)
);

export const getTestimonials = () => (
  axios.get(TESTIMONIAL_API)
);

export const setIdentity = (email) => {
  return axios.post(`${INVESTFLOW_API}identity`, { email }, { withCredentials: true });
};

export const getShareRepurchaseList = () => (
  getIdToken()
    .then((token) => (
      axios.get(SHARE_REPURCHASE_REQUEST_API, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ))
);

export const postShareRepurchaseRequest = (data) => (
  getIdToken()
    .then((token) => (
      axios.post(SHARE_REPURCHASE_REQUEST_API, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ))
);

export const createPlaidLinkToken = (data = undefined) => (
  getIdToken()
    .then((token) => (
      axios.post(`${PLAID_API_ENDPOINT}/create_link_token`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ))
);
