/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  InvestmentPercentageRaised,
  Body,
  Data,
  Link,
  Tooltip,
  TooltipIcon,
} from '../../coreui';

import SmartButton from '../../components/SmartButton';
import WatchListToggle from './watchListToggle';
import data from '../data';

// eslint-disable-next-line
class OverviewDetails extends React.Component {
  render() {
    const {
      details,
      smartButton,
      watchList,
      progress,
      isLoggedIn,
      id,
      isReit,
      link,
      reit,
      additionalDetails,
      isApp,
      frontendPrefix,
      ...passedProps
    } = this.props;
    const dataStyle = {
      lineHeight: '2.1',
    };

    let objective1 = {};
    let objective2 = {};

    switch (id) {
      case 274949:
        objective1 = data.mr2.objective1;
        objective2 = data.mr2.objective2;
        break;
      case 193744:
        objective1 = data.mr1.objective1;
        objective2 = data.mr1.objective2;
        break;
      default:
        objective1 = null;
        objective2 = null;
    }

    return (
      <ExtendedBox
        my={[0, 0, 0, 30]}
        width={['100%', '100%', '100%', '500px']}
        {...passedProps}
      >
        {
          details.map((item, index) => {
            return (
              <ExtendedFlex
                // eslint-disable-next-line
                key={`detail_${index}`}
                flexDirection="row"
                justifyContent="space-between"
                borderBottom="1px solid"
                borderColor="gray.7"
                mr={20}
                ml={20}
                px={20}
                w="calc(100% - 40)"
              >
                <ExtendedFlex
                  flexDirection="row"
                >
                  <Data type="D3" style={dataStyle}>{item.label}</Data>
                  {
                    item.tooltip &&
                    <>
                      <TooltipIcon
                        // eslint-disable-next-line
                        tooltipId={`${index}-tooltip`}
                        m="auto"
                        display="inline-block"
                        ml={5}
                      />
                      <Tooltip
                        // eslint-disable-next-line
                        id={`${index}-tooltip`}
                        multiline
                      >
                        <Body
                          type="B5"
                          mb={20}
                          color="gray.8"
                        >
                          {item.tooltip}
                        </Body>
                      </Tooltip>
                    </>
                  }
                </ExtendedFlex>
                <Data type="D2">{item.value}</Data>
              </ExtendedFlex>
            );
          })
        }
        {
          objective1 &&
          objective2 &&
          <ExtendedBox>
            <ExtendedFlex
              flexDirection="row"
              justifyContent="space-between"
              mr={20}
              ml={20}
              px={20}
              my={0}
              w="calc(100% - 40)"
            >
              <Data
                type="D2"
                style={dataStyle}
                color="black"
                mb={0}
              >
                Objectives
              </Data>
            </ExtendedFlex>
            <ExtendedFlex
              flexDirection="column"
              justifyContent="space-between"
              mr={0}
              ml={20}
              px={20}
              my={0}
              w="calc(100% - 40)"
            >
              <Data
                type="D3"
                color="black"
                lineHeight={1.5}
                m={5}
              >
                {objective1}
              </Data>
              <Data
                type="D3"
                lineHeight={1.5}
                color="black"
                mb={5}
                mx={5}
                mt={0}
              >
                {objective2}
              </Data>
            </ExtendedFlex>
          </ExtendedBox>
        }
        {
          additionalDetails &&
          <ExtendedFlex
            flexDirection="row"
            justifyContent="space-between"
            mr={20}
            ml={20}
            px={20}
            w="calc(100% - 40)"
          >
            <Data
              type="D3"
              style={dataStyle}
              color="gray.7"
            >
              {additionalDetails}
            </Data>
          </ExtendedFlex>
        }
        <ExtendedBox
          textAlign="center"
          my={20}
          mx="auto"
          width="100%"
        >
          {
            smartButton &&
            <SmartButton
              width="100%"
              type="L1"
              label={smartButton.button_text}
              buttonHref={smartButton.button_href}
              helpText={smartButton.help_text}
              tooltipText={smartButton.tooltip_text}
              flag={(smartButton.flag) ? smartButton : null}
              data-test="smart-button-link-overview-details"
            />
          }
          {
            link &&
            <Link
              href={`${isApp ? frontendPrefix : ''}${link.href}`}
              type="L3"
            >
              {link.text}
            </Link>
          }
          {
            watchList &&
            <WatchListToggle
              isFlagged={watchList.flagged}
              flagURL={watchList.flag}
              unflagURL={watchList.unflag}
              isLoggedIn={isLoggedIn}
              id={id}
            />
          }
          {
            progress &&
            <ExtendedBox
              maxWidth={400}
              m="auto"
              mt={watchList ? undefined : 40}
            >
              <InvestmentPercentageRaised
                progressBar={progress}
              />
            </ExtendedBox>
          }
        </ExtendedBox>
      </ExtendedBox>
    );
  }
}

OverviewDetails.propTypes = {
  id: PropTypes.number,
  isReit: PropTypes.bool,
  details: PropTypes.array,
  smartButton: PropTypes.object,
  reit: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  watchList: PropTypes.object,
  progress: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  link: PropTypes.object,
  additionalDetails: PropTypes.node,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

OverviewDetails.defaultProps = {
  additionalDetails: null,
};

export default OverviewDetails;
