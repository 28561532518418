/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
} from '../../coreui';

import ExploreOpportunitiesSection1 from './components/ExploreOpportunitiesSection1';
import ExploreOpportunitiesSection2 from './components/ExploreOpportunitiesSection2';

class ExploreOpportunities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      linkButtonText: 'Open Investments',
      isVisible: false,
    };

    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible,
      });
    }
  }

  render() {
    const {
      totalMembers,
      totalAmountFinanced,
      amountPaidOut,
      totalPropertyValueFinanced,
      variant,
    } = this.props;

    return (
      <Flex
        flexDirection={['column', 'column', 'row', 'row', 'row']}
      >
        <ExploreOpportunitiesSection1
          handleSensorChange={this.handleSensorChange}
          variant={variant}
          {...this.state}
        />
        <ExploreOpportunitiesSection2
          totalMembers={totalMembers}
          totalAmountFinanced={totalAmountFinanced}
          amountPaidOut={amountPaidOut}
          totalPropertyValueFinanced={totalPropertyValueFinanced}
          variant={variant}
          {...this.state}
        />
      </Flex>
    );
  }
}

ExploreOpportunities.propTypes = {
  totalMembers: PropTypes.number,
  totalAmountFinanced: PropTypes.number,
  amountPaidOut: PropTypes.number,
  totalPropertyValueFinanced: PropTypes.number,
  variant: PropTypes.string,
};

export default ExploreOpportunities;
