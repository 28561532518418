import React from 'react';
import PropTypes from 'prop-types';
import Styled from './index.styled';

import StartWidget from './StartWidget';
import Text from '../Text';

import {
  Tooltip,
  TooltipIcon,
} from '../../coreui';

const Item = ({
  label,
  value,
  index,
  tooltip,
}) => (
  <Styled.Item>
    <div className="value"><Text>{value}</Text></div>
    <div className="label">
      <Text>{label}</Text>
      {
        tooltip &&
        <>
          <TooltipIcon
            tooltipId={`${index}-tooltip`}
            m="auto"
            display="inline-block"
            ml={5}
          />
          <Tooltip
            id={`${index}-tooltip`}
            multiline
            place="bottom"
          >
            <div className="tooltip">
              {tooltip}
            </div>
          </Tooltip>
        </>
      }
    </div>
  </Styled.Item>
);

const ReitDescription = ({
  background,
  title,
  subTitle = '',
  description,
  items = [],
  startInvestment,
  id,
  isLoggedIn = false,
}) => {
  return (
    <Styled.ReitDescription background={background}>
      <Styled.Container>
        <div style={{ width: '100%' }}>
          <h1><Text>{title}</Text></h1>
          {
            subTitle &&
            subTitle.map((paragraph) => <p key={paragraph.slice(0, 10)}><Text>{paragraph}</Text></p>)
          }
        </div>
        <Styled.DescriptionTile>
          {description.map((paragraph) => <p key={paragraph.slice(0, 10)}><Text>{paragraph}</Text></p>)}
        </Styled.DescriptionTile>
        <Styled.ContentTile>
          {items.map((item, index) => <Item key={item.label} label={item.label} value={item.value} index={index} tooltip={item.tooltip} />)}
        </Styled.ContentTile>
      </Styled.Container>
      <Styled.StartWidgetWrapper>
        <StartWidget
          isLoggedIn={isLoggedIn}
          id={id}
          title={startInvestment.title}
          items={startInvestment.items}
          investLink={startInvestment.investLink}
          oldInvestLink={startInvestment.oldInvestLink}
          buttonLabel={startInvestment.buttonLabel}
          loggedInButtonLabel={startInvestment.loggedInButtonLabel}
        />
      </Styled.StartWidgetWrapper>
    </Styled.ReitDescription>
  );
};

ReitDescription.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.array,
  startInvestment: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  id: PropTypes.number,
};

Item.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  index: PropTypes.number,
  tooltip: PropTypes.string,
};

export default ReitDescription;
