import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import {
  ExtendedFlex,
  Data,
  Image,
  Body,
  Heading,
  Link,
} from '../../../coreui';

import FadeIn from '../../../components/FadeIn';
import CardContainer from './cardContainer';

// eslint-disable-next-line
class OverView extends React.Component {
  render() {
    const {
      title,
      subtitle,
      items,
    } = this.props;

    return (
      <ExtendedFlex
        flexDirection="column"
        align="center"
        maxWidth={1440}
        px={[0, 0, '10%', '10%', '10%']}
        mx="auto"
      >
        <FadeIn>
          <Data
            type="D4"
            textAlign="center"
            my={0}
            mx={40}
          >
            {title}
          </Data>
          <Body
            type="B1"
            textAlign="center"
            m={0}
            p={40}
            maxWidth="100%"
          >
            {ReactHtmlParser(subtitle)}
          </Body>
        </FadeIn>
        {
          items &&
          <ExtendedFlex
            flexDirection={['column', 'column', 'row', 'row', 'row']}
            justify="space-around"
            alignItems="center"
            width={[1, 1, 1, 1, 1]}
            mt={[10, 20, 40, 50, 50]}
            mb={0}
            mx={40}
          >
            {
              items &&
                Object.keys(items).map(
                  (idx) => {
                    const item = items[idx];
                    return (
                      <FadeIn>
                        <CardContainer
                          // eslint-disable-next-line
                          key={`${idx}_overview_item`}
                          height={[325, 360, 400, 400, 500]}
                          flexDirection="column"
                          align="center"
                          justifyContent="flex-start"
                          flex="1 1 0%"
                          mx={15}
                          mt={17}
                          textAlign="center"
                        >
                          <Link
                            to={`#${item.section}`}
                          >
                            <Image
                              src={item.image}
                              maxWidth="100%"
                              display="block"
                              hover
                            />
                          </Link>
                          <Heading
                            type="H6"
                            textAlign="center"
                            w={1}
                            textTransform="uppercase"
                          >
                            {item.title}
                          </Heading>
                          <Body
                            type="B3"
                            textAlign="center"
                            w={1}
                            px={5}
                            mb={20}
                          >
                            {ReactHtmlParser(item.body)}
                          </Body>
                        </CardContainer>
                      </FadeIn>
                    );
                  },
                )
              }
          </ExtendedFlex>
          }
      </ExtendedFlex>
    );
  }
}

OverView.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  items: PropTypes.any,
};

export default OverView;
