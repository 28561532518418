/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import {
  ExtendedFlex,
  Data,
  Image,
  Body,
  Heading,
} from '../coreui';

import FadeIn from './FadeIn';

// eslint-disable-next-line
class Graphic3ColCallout extends React.Component {
  render() {
    const {
      title,
      subtitle,
      description,
      disclaimer,
      items,
      imgProps,
      containerProps,
    } = this.props;

    return (
      <ExtendedFlex
        flexDirection="column"
        align="center"
        maxWidth={1500}
        mx="auto"
      >
        <FadeIn>
          {
            title &&
            <Heading
              type="H3"
              display="block"
              textAlign="center"
              alignSelf="center"
              m={0}
              mb={[30, 30, 40, 40, 60]}
            >
              {title}
            </Heading>
          }
          {
            subtitle &&
            <Data
              type="D4"
              textAlign="center"
              my={0}
              mx={40}
            >
              {subtitle}
            </Data>
          }
          {
            description &&
            <Body
              type="B1"
              textAlign="center"
              my={40}
              mx={40}
            >
              {description}
            </Body>
          }
        </FadeIn>
        <ExtendedFlex
          flexDirection={['column', 'column', 'row', 'row', 'row']}
          justify="space-around"
          width={[1, 1, 1, 1, 1]}
          mt={[10, 20, 40, 50, 50]}
          mb={40}
          mx={40}
          {...containerProps}
        >
          <FadeIn>
            {
              items &&
              Object.keys(items).map(
                (idx) => {
                  const item = items[idx];
                  return (
                    <ExtendedFlex
                      // eslint-disable-next-line
                      key={`${idx}_overview_item`}
                      flexDirection="column"
                      align="center"
                      justifyContent="center"
                      flex="1 1 0%"
                      mx={35}
                      mt={30}
                      height="100%"
                    >
                      <Image
                        src={item.image}
                        {...imgProps}
                      />
                      <Heading
                        type="H6"
                        textAlign="center"
                        w={1}
                        textTransform="uppercase"
                      >
                        {item.title}
                      </Heading>
                      <Body
                        type="B3"
                        textAlign="center"
                        w={1}
                      >
                        {ReactHtmlParser(item.body)}
                      </Body>
                    </ExtendedFlex>
                  );
                },
              )
            }
          </FadeIn>
        </ExtendedFlex>
        {
          disclaimer &&
            <Body
              type="B2"
              textAlign="center"
              my={40}
              mx={40}
            >
              {disclaimer}
            </Body>
        }
      </ExtendedFlex>
    );
  }
}

Graphic3ColCallout.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  disclaimer: PropTypes.string,
  items: PropTypes.any,
  imgProps: PropTypes.object,
  containerProps: PropTypes.object,
};

Graphic3ColCallout.defaultProps = {
  imgProps: {},
};

export default Graphic3ColCallout;
