import types from './types';
import browsePageTypes from '../browsePage/types';
import appWrapperTypes from '../../appWrapper/types';
import authTypes from '../../auth/types';

const initialState = {
  loaded: false,
  userLoaded: false,
  dataLoaded: false,
  ioStatuses: [
    {
      value: 'completed',
      label: 'COMPLETED INVESTMENTS',
    },
    {
      value: 'funded',
      label: 'FUNDED INVESTMENTS',
    },
  ],
  selectedIoStatusFilterValue: 'completed',
  ioTypes: [
    {
      value: 'funded',
      label: 'FUNDED',
      count: null,
    },
    {
      value: 'completed_equity',
      label: 'COMPLETED EQUITY',
      count: null,
    },
    {
      value: 'completed_debt',
      label: 'COMPLETED DEBT',
      count: null,
    },
  ],
  selectedIoTypeFilterValue: 'funded',
  definitions: {
    funded: 'investments are active investments that have received full funding and are not currently accepting further investment.',
    completed: 'investments have concluded and final distributions have been processed.',
  },
  accreditationRoleStatus: '',
  gtkyApproved: false,
  investments: [],
  investmentsCount: 0,
  investmentsVisible: 12,
  investmentsPerPage: 12,
  completedEquityInvestments: [],
  completedEquityInvestmentsCount: 0,
  completedEquityInvestmentsPage: 1,
  completedDebtInvestments: [],
  completedDebtInvestmentsCount: 0,
  completedDebtInvestmentsPage: 1,
  fundedInvestments: [],
  fundedInvestmentsCount: 0,
  fundedInvestmentsPage: 1,
  variants: { pastInvestments: { v: '' } },
};

const updateInvestmentFadeIndex = (investments, investmentsPerPage) => {
  let i = 1;
  return investments.map(
    (investment) => {
      const newInvestment = {
        ...investment,
        fadeIndex: i,
      };
      if (i < investmentsPerPage) {
        i += 1;
      } else {
        i = 1;
      }
      return newInvestment;
    },
  );
};

export default (state = initialState, action) => {
  const currentTypeFilter = state.selectedIoTypeFilterValue;

  // Fold in the browse page data, or just expand the existing state.
  const newState = action.type === browsePageTypes.GET_BROWSE_CLOSED_DATA_SUCCEEDED ? {
    ...state,
    completedEquityInvestments: action.data.completed_equity.data.map((item) => {
      return { ...item, status: 'status-40-closed' };
    }),
    completedEquityInvestmentsCount: action.data.completed_equity.data.length,
    completedDebtInvestments: action.data.completed_debt.data.map((item) => {
      return { ...item, status: 'status-40-closed' };
    }),
    completedDebtInvestmentsCount: action.data.completed_debt.data.length,
    fundedInvestments: action.data.funded.data,
    fundedInvestmentsCount: action.data.funded.data.length,
  } : { ...state };

  // Determine the (somewhat) final state based on the action.
  let finalState = {
    ...newState,
  };
  switch (action.type) {
    case types.UPDATE_SELECTED_PAST_INVESTMENT_IO_TYPE_FILTER_VALUE:
      finalState = {
        ...newState,
        selectedIoTypeFilterValue: action.value,
      };
      break;

    case types.UPDATE_SHOW_MORE_PAST_INVESTMENTS:
      if (currentTypeFilter === 'completed_equity') {
        finalState = {
          ...newState,
          completedEquityInvestmentsPage: finalState.completedEquityInvestmentsPage + 1,
        };
      } else if (currentTypeFilter === 'completed_debt') {
        finalState = {
          ...newState,
          completedDebtInvestmentsPage: finalState.completedDebtInvestmentsPage + 1,
        };
      } else if (currentTypeFilter === 'funded') {
        finalState = {
          ...newState,
          fundedInvestmentsPage: finalState.fundedInvestmentsPage + 1,
        };
      }
      break;

    case appWrapperTypes.GET_USER_DATA_SUCCEEDED: {
      finalState = {
        ...newState,
        userLoaded: true,
        loaded: newState.dataLoaded,
        accreditationRoleStatus: action.user.accreditationRoleStatus,
        gtkyApproved: action.user.gtkyApproved,
      };
      break;
    }

    case browsePageTypes.GET_BROWSE_CLOSED_DATA_SUCCEEDED:
      finalState = {
        ...newState,
        dataLoaded: true,
        loaded: true,
        ioTypes: newState.ioTypes.map(
          (label) => {
            let count;
            switch (label.value) {
              case 'completed_equity':
                count = newState.completedEquityInvestmentsCount;
                break;

              case 'completed_debt':
                count = newState.completedDebtInvestmentsCount;
                break;

              case 'funded':
                count = newState.fundedInvestmentsCount;
                break;

              default:
                count = null;
                break;
            }
            return {
              ...label,
              count,
            };
          },
        ),
      };
      break;

    case authTypes.USER_LOGOUT_REQUESTED: {
      return {
        ...state,
        ...initialState,
      };
    }

    default:
      // Default case assigned above.
      break;
  }

  // Determine the current set of investments based on changes from the actions.
  let investments = updateInvestmentFadeIndex(
    finalState.completedDebtInvestments,
    state.investmentsPerPage,
  );
  let investmentsCount = finalState.completedDebtInvestmentsCount;
  let investmentsPage = finalState.completedDebtInvestmentsPage;
  switch (finalState.selectedIoTypeFilterValue) {
    case 'completed_equity':
      investments = updateInvestmentFadeIndex(
        finalState.completedEquityInvestments,
        state.investmentsPerPage,
      );
      investmentsCount = finalState.completedEquityInvestmentsCount;
      investmentsPage = finalState.completedEquityInvestmentsPage;
      break;

    case 'funded':
      investments = updateInvestmentFadeIndex(
        finalState.fundedInvestments,
        state.investmentsPerPage,
      );
      investmentsCount = finalState.fundedInvestmentsCount;
      investmentsPage = finalState.fundedInvestmentsPage;
      break;

    default:
    case 'completed_debt':
      // Default case assigned above.
      break;
  }

  return {
    ...finalState,
    investments,
    investmentsCount,
    investmentsVisible: investmentsPage * state.investmentsPerPage,
  };
};
