/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import actions from '../actions';

import RelatedContent from '../../components/RelatedContent';
import { POPULAR_CONTENT_SUBQUEUE_ID } from '../../api';

class RelatedQueueContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getArticleQueueRequested(this.props.queue);
  }

  render() {
    const {
      queue,
      items,
      ...additionalProps
    } = this.props;

    // Don't output unless we have some items.
    if (items.length === 0) {
      return null;
    }

    return (
      <RelatedContent
        items={items.slice(0, 3)}
        {...additionalProps}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getArticleQueueRequested: (queue) => {
    dispatch(actions.getArticleQueueRequested(queue));
  },
});

const mapStateToProps = (state) => ({
  items: state.knowledgeCenter.items,
});

RelatedQueueContent.propTypes = {
  items: PropTypes.array,
  queue: PropTypes.string,
  getArticleQueueRequested: PropTypes.func,
};

RelatedQueueContent.defaultProps = {
  queue: POPULAR_CONTENT_SUBQUEUE_ID,
};

export default connect(mapStateToProps, mapDispatchToProps)(RelatedQueueContent);
