import React from 'react';

import {
  ExtendedFlex,
  Body,
  Image,
  MiniEssay,
  Heading,
  ExtendedBox,
  Theme,
} from '../coreui';

import VisibilityTrigger from '../components/VisibilityTrigger';
import FadeIn from '../components/FadeIn';
import { InvestmentStrategiesData } from './_contentData';

const InvestmentStrategies = () => {
  return (
    <ExtendedBox
      width={1}
      bg="gray.0"
    >
      <ExtendedFlex
        flexDirection="column"
        align="center"
        maxWidth={1500}
        py={[50, 50, 140]}
        mx="auto"
        bg="gray.0"
      >
        <VisibilityTrigger
          trigger="isVisible"
        >
          <MiniEssay
            header={InvestmentStrategiesData.heading}
            paragraphsOfText={InvestmentStrategiesData.subHeading}
            animatedFadeIn
          />
        </VisibilityTrigger>
        <ExtendedFlex
          flexDirection={['column', 'column', 'row', 'row', 'row']}
          justify="space-around"
          width={[1, 1, 1, 1, 1]}
          mt={[10, 20, 40, 50, 50]}
          mb={0}
          bg="gray.0"
          transition={`opacity ${Theme.fadeInDelay}ms 0s linear`}
        >
          <FadeIn>
            {
              InvestmentStrategiesData.cards.map(
                (data) => {
                  return (
                    <ExtendedFlex
                      flexDirection="column"
                      align="center"
                      justifyContent="center"
                      flex="1 1 0%"
                      bg="gray.0"
                      mx={35}
                      mt={30}
                      height="100%"
                    >
                      <Image
                        src={data.image}
                      />
                      <Heading
                        type="H6"
                        textAlign="center"
                        w={1}
                      >
                        {data.label}
                      </Heading>
                      <Body
                        type="B3"
                        textAlign="center"
                        w={1}
                      >
                        {data.blurb}
                      </Body>
                    </ExtendedFlex>
                  );
                },
              )
            }
          </FadeIn>
        </ExtendedFlex>
      </ExtendedFlex>
    </ExtendedBox>
  );
};

export default InvestmentStrategies;
