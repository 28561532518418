export const privatePlacementFaqVersion2Id = 1335370;

export default {
  mr1: {
    objective1: '• Pay Attractive and Stable Cash Distributions',
    objective2: '• Preserve, Protect, Increase and Return Your Capital Contributions',
  },
  mr2: {
    objective1: '• Realize Long Term Capital Appreciation',
    objective2: '• Pay Attractive and Stable Cash Distributions',
  },
  privatePlacementFaqVersion2Id,
};
