import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import {
  getAutoInvestApi,
} from '../../api';

export function* getDataFlow() {
  try {
    const response = yield call(getAutoInvestApi);
    yield put(
      actions.autoInvestDataSucceeded(
        response.data,
      ),
    );
  } catch (error) {
    yield put(
      actions.autoInvestDataFailed(
        error,
      ),
    );
  }
}

export function* watchAutoInvestApiRequested() {
  yield takeLatest(
    types.AUTOINVEST_API_REQUESTED,
    getDataFlow,
  );
}

export default watchAutoInvestApiRequested;
