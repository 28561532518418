export default {
  PERCENTAGE: '0%',
  PERCENTAGE2: '0.00%',
  DOLLARS: '$0,0',
  DOLLARS_DECIMALS: '$0,0.00',
  SHORTENED_DOLLARS: '$0a',
  SHORTENED_DOLLARS_WRITTEN: '$0 aw',
  SHORTENED_DOLLARS_POINTS: '$0.0a',
  SHORTENED_DOLLARS_POINTS_WRITTEN: '$0.[0] aw',
  MYYYY: 'M/YYYY',
  MMYYYY: 'MM/YYYY',
  MMDDYYYY: 'MM/DD/YYYY',
  MMDDYY: 'MM/DD/YY',
  MMMMYYYY: 'MMMM YYYY',
  SHARES: '0,0[.]0000',
  INTEGER: '0,0',
};
