export default {
  GET_DSM_REQUESTED: 'GET_DSM_REQUESTED',
  GET_DSM_SUCCEEDED: 'GET_DSM_SUCCEEDED',
  GET_DSM_FAILED: 'GET_DSM_FAILED',
  RESET_DSM_REQUESTED: 'RESET_DSM_REQUESTED',
  ADD_STATUS_MESSAGE_REQUESTED: 'ADD_STATUS_MESSAGE_REQUESTED',
  ADD_STATUS_MESSAGE_SUCCEEDED: 'ADD_STATUS_MESSAGE_SUCCEEDED',
  ADD_STATUS_MESSAGE_FAILED: 'ADD_STATUS_MESSAGE_FAILED',
  HIDE_STAY_CONNECTED_MESSAGE: 'HIDE_STAY_CONNECTED_MESSAGE',
};
