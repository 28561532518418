import {
  takeLatest,
  put,
  call,
  all,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import {
  getBrowseCompletedEquityApi,
  getBrowseCompletedDebtApi,
  getBrowseFundedApi,
} from '../../../api';

export function* getBrowseClosedDataFlow() {
  try {
    const [
      completedEquity,
      completedDebt,
      funded,
    ] = yield all([
      call(getBrowseCompletedEquityApi),
      call(getBrowseCompletedDebtApi),
      call(getBrowseFundedApi),
    ]);

    yield put(
      actions.getBrowseClosedDataSucceeded({
        // eslint-disable-next-line camelcase
        completed_equity: completedEquity.data,
        // eslint-disable-next-line camelcase
        completed_debt: completedDebt.data,
        funded: funded.data,
      }),
    );
  } catch (error) {
    yield put(
      actions.getBrowseClosedDataFailed(
        error,
      ),
    );
  }
}

export function* watchGetBrowseClosedDataRequested() {
  yield takeLatest(
    types.GET_BROWSE_CLOSED_DATA_REQUESTED,
    getBrowseClosedDataFlow,
  );
}

export default watchGetBrowseClosedDataRequested;
