import types from './types';

export default {
  postWebToSalesforceRequested: (data) => ({
    type: types.POST_WEB_TO_SALESFORCE_REQUESTED,
    data,
  }),
  postWebToSalesforceSucceeded: (data) => ({
    type: types.POST_WEB_TO_SALESFORCE_SUCCEEDED,
    data,
  }),
  postWebToSalesforceFailed: (error) => ({
    type: types.POST_WEB_TO_SALESFORCE_FAILED,
    error,
  }),
};
