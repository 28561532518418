import React from 'react';
import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  CarouselRecentInvestments,
  VisibilitySensor,
  Data,
  Flex,
  Image,
} from '../../coreui';

import recentTransactionsData from './RecentTransactionsData';

class RecentTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible,
      });
    }
  }

  render() {
    const { isVisible } = this.state;
    return (
      <ExtendedBox
        bg="gray.1"
        py={[60, 80, 100, 120, 140]}
      >
        <ExtendedFlex
          flexDirection="column"
          align="Center"
          mb={[20, 30, 40, 60, 80]}
        >
          <VisibilitySensor
            callback={this.handleSensorChange}
          />
          <Heading
            type="H9"
            m={0}
            display="block"
            textAlign="center"
            opacity={isVisible ? '1' : '0'}
            transition={`
              opacity 0.5s ${!isVisible ? '0' : '0.3'}s linear
            `}
          >
            Recent Transactions
          </Heading>
          <Heading
            type="H8"
            display="block"
            textAlign="center"
            mx={[30, 20, 0, 0, 0]}
            opacity={isVisible ? '1' : '0'}
            transition={`
              opacity 0.5s ${!isVisible ? '0' : '0.6'}s linear
            `}
          >
            Funded By Our Community Of Members
          </Heading>
        </ExtendedFlex>
        <ExtendedBox>
          <CarouselRecentInvestments>
            {
              recentTransactionsData.map(
                (data) => {
                  return (
                    <ExtendedBox
                      key={data.id}
                      mx={5}
                    >
                      <ExtendedFlex
                        flexDirection="column"
                        boxSizing="border-box"
                        bg="white"
                        width={[315, 315, 315, 400, 525]}
                        height={[400, 400, 440, 480, 600]}
                        justify="center"
                        align="center"
                        p={10}
                      >
                        <Image
                          width={1}
                          maxWidth={492}
                          IEAspectRatioFix
                          src={data.image}
                          hoverColorize
                        />
                        <ExtendedFlex
                          flexDirection="column"
                          align="center"
                          p={10}
                          pt={20}
                          maxWidth="100%"
                        >
                          <Heading
                            type="H6"
                            m={[10, 10, 10, 20, 20]}
                          >
                            {data.label}
                          </Heading>
                          <Data
                            type="D3"
                            m={10}
                          >
                            {data.location}
                          </Data>
                          <Data
                            type="D3"
                            m={0}
                            mb={5}
                            textAlign="center"
                          >
                            {data.type}
                          </Data>
                          <Flex
                            align="center"
                            pb={30}
                          >
                            <ExtendedFlex
                              flexDirection="column"
                              textAlign="center"
                            >
                              <Heading
                                type="H5"
                                m={20}
                                textAlign="center"
                              >
                                {data.value}
                              </Heading>
                            </ExtendedFlex>
                          </Flex>
                        </ExtendedFlex>
                      </ExtendedFlex>
                    </ExtendedBox>
                  );
                },
              )
            }
          </CarouselRecentInvestments>
        </ExtendedBox>
      </ExtendedBox>
    );
  }
}

export default RecentTransactions;
