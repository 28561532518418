/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  MiniEssay,
} from '../../coreui';

import Bio from './Bio';
import FadeIn from '../../components/FadeIn';

const scrollPadding = ['15vw', '15vw', 0];

const Leadership = ({
  heading,
  items,
}) => {
  return (
    <ExtendedBox
      pt={[60, 80, 100, 120, 140]}
      pb={[10, 30, 50, 70, 50]}
    >
      <FadeIn>
        <ExtendedBox>
          <MiniEssay
            header={heading}
            mb={-30}
          />
        </ExtendedBox>
      </FadeIn>
      <ExtendedBox
        overflowX="auto"
        overflowY="visible"
        py={[50, 50, 50, 50, 90]}
      >
        <ExtendedFlex
          align="center"
          justify={['flex-start', 'flex-start', 'center']}
          flexDirection="row"
          flexWrap={['nowrap', 'nowrap', 'wrap']}
          maxWidth={[null, null, 1650]}
          mx="auto"
        >
          <FadeIn
            fadeInIndex={1}
            speed="fast"
          >
            {
              items.map((item, index) => {
                return (
                  <Bio
                    key={item.name}
                    {...(index === 0) ? {
                      pl: scrollPadding,
                    } : {}}
                    {...(index + 1 === items.length) ? {
                      pr: scrollPadding,
                    } : {}}
                    {...item}
                  />
                );
              })
            }
          </FadeIn>
        </ExtendedFlex>
      </ExtendedBox>
    </ExtendedBox>
  );
};

Leadership.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(Bio.propTypes)),
};

export default Leadership;
