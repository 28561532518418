import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Heading,
  Body,
  FlatSelect,
  StyledButton,
  Image,
} from '../../../coreui';

import downArrow from '../../assets/down_blk.svg';

const DropDown = ({ data, onChange, selected }) => {
  return (
    <FlatSelect
      value={selected}
      options={data.map((item) => ({
        value: item,
        label: <Body type="B5">{item}</Body>,
      }))}
      searchable={false}
      clearable={false}
      placeholder={<Body type="B5">Filter By Investment</Body>}
      onChange={onChange}
      arrowRenderer={() => (
        <ExtendedFlex
          justify="flex-end"
        >
          <Image src={downArrow} w={14} height={14} />
        </ExtendedFlex>
      )}
    />
  );
};

const HeaderFields = (props) => {
  return (
    <ExtendedFlex
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <ExtendedFlex
        w={1}
        pl={50}
        pr={50}
        maxWidth={1700}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <ExtendedFlex
          w={1}
          borderBottom="3px solid #176DB6"
          justifyContent="center"
        >
          <ExtendedFlex
            width="90%"
            my="20px"
            justifyContent="space-between"
            flexWrap="wrap"
            alignItems="center"
          >
            <ExtendedFlex
              alignItems="center"
              justify="flex-start"
              flexWrap="wrap"
            >
              <Heading
                type="H3"
                pr={[0, 30, 30, 30, 30]}
                my={[5, 5, 10]}
                mx={['auto', 0, 0, 0, 0]}
              >
                Messages
              </Heading>
              <ExtendedFlex
                flexDirection="column"
                pr={10}
                my={[5, 5, 10]}
                mx={['auto', 0, 0, 0, 0]}
              >
                <DropDown
                  data={['Filter By Investment', ...props.investments]}
                  onChange={props.onChange}
                  selected={props.selected}
                />
              </ExtendedFlex>
            </ExtendedFlex>
            <StyledButton
              type="L4"
              onClick={props.onReset}
              mt={[20, 0, 0, 0, 0]}
              mx={['auto', 0, 0, 0, 0]}
            >
              Reset
            </StyledButton>
          </ExtendedFlex>
        </ExtendedFlex>
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

DropDown.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  selected: PropTypes.func,
};

HeaderFields.propTypes = {
  onChange: PropTypes.func,
  investments: PropTypes.array,
  selected: PropTypes.func,
  onReset: PropTypes.func,
};

export default HeaderFields;
