import React from 'react';
import PropTypes from 'prop-types';

import {
  Heading,
  ExtendedFlex,
  ArticleCard,
} from '../coreui';

const RelatedContent = ({
  items,
  title,
  bg,
}) => {
  return (
    <ExtendedFlex
      w={1}
      flexDirection="column"
      bg={bg}
      align="center"
      py={80}
    >
      <ExtendedFlex
        w={1}
        justify="center"
      >
        <Heading
          type="H2"
          m={0}
          textTransform="uppercase"
          display="block"
          w={1}
          align="center"
          color="gray.8"
          weight="light"
        >
          {title}
        </Heading>
      </ExtendedFlex>
      <ExtendedFlex
        w={1}
        maxWidth={1600}
        justify="center"
        align="center"
        flexDirection="row"
        wrap="wrap"
      >
        {
          items.map(
            (item) => {
              return (
                <ArticleCard
                  key={item.id}
                  id={item.id}
                  type={item.bundle}
                  imageUrl={item.image._links.listing}
                  title={item.label}
                  topic={item.category.label}
                  url={item._links.alias}
                  promo={item.promo_sentence}
                />
              );
            },
          )
        }
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

RelatedContent.propTypes = {
  title: PropTypes.node,
  bg: PropTypes.string,
  items: PropTypes.array,
};

RelatedContent.defaultProps = {
  title: 'Related Articles',
  bg: 'gray.0',
};

export default RelatedContent;
