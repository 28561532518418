import types from './types';
import appWrapperTypes from '../../appWrapper/types';

const initialState = {
  id: 0,
  label: '',
  type: '',
  video: '',
  body: '',
  category: {},
  socialMediaItems: [],
  user: {
    isLoggedIn: false,
    firstName: 'Welcome',
    lastName: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case appWrapperTypes.GET_USER_DATA_SUCCEEDED:

      return {
        ...state,
        user: action.user,
      };

    case appWrapperTypes.APP_INIT: {
      const user = {
        ...state.user,
        ...action.data.user,
      };
      return {
        ...state,
        user,
      };
    }

    case types.GET_VIDEO_SUCCEEDED:
      return {
        ...state,
        ...action.data.data,
      };

    default:
      return {
        ...state,
      };
  }
};
