import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
  Theme,
} from '../../../coreui';

const BODYCOPY1 = 'While the real estate market can fluctuate, it has been demonstrated over time to be less volatile than some other asset classes. The potential for this stability can be a distinct advantage to investors.';

const BODYCOPY2 = 'Historically, real estate investments have offered a path to wealth through property appreciation and generation of regular income. On the RealtyMogul platform, we strive to offer both paths, with Private Placements and Real Estate Investment Trusts ("REITs").';

const InvestmentAlternativesSection2 = ({
  isVisible,
}) => {
  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
    >
      <ExtendedFlex
        flexDirection="column"
      >
        <ExtendedBox
          m={0}
          pb={30}
          bg="gray.1"
        >
          <ExtendedFlex
            flexDirection="column"
            justify="center"
            maxWidth={[null, null, 520, 520, 660]}
            height={[300, 300, 400, 400, 500]}
            px={['15%', '25%', 60, 100, 120]}
          >
            <Heading
              type="H3"
              textTransform="uppercase"
              m={0}
              mb="0.7em"
              opacity={isVisible ? '1' : '0'}
              transition={`
                opacity ${Theme.fadeInDelay}ms ${!isVisible ? '0' : '0.3'}s linear
              `}
            >
              MORE THAN JUST DIVERSIFICATION
            </Heading>
            <Body
              type="B1"
              m={0}
              opacity={isVisible ? '1' : '0'}
              transition={`
                opacity ${Theme.fadeInDelay}ms ${!isVisible ? '0' : '0.6'}s linear
              `}
            >
              {BODYCOPY1}
            </Body>
          </ExtendedFlex>
        </ExtendedBox>
        <ExtendedBox
          mt={30}
          mb={30}
        >
          <ExtendedFlex
            flexDirection="column"
            justify="center"
            maxWidth={[null, null, 520, 520, 660]}
            height={[300, 300, 400, 400, 500]}
            px={['15%', '25%', 60, 100, 120]}
          >
            <Heading
              type="H3"
              textTransform="uppercase"
              m={0}
              mb="0.7em"
              opacity={isVisible ? '1' : '0'}
              transition={`
                opacity ${Theme.fadeInDelay}ms ${!isVisible ? '0' : '0.3'}s linear
              `}
            >
              APPRECIATION AND INCOME
            </Heading>
            <Body
              type="B1"
              m={0}
              opacity={isVisible ? '1' : '0'}
              transition={`
                opacity ${Theme.fadeInDelay}ms ${!isVisible ? '0' : '0.6'}s linear
              `}
            >
              {BODYCOPY2}
            </Body>
          </ExtendedFlex>
        </ExtendedBox>
      </ExtendedFlex>
    </Box>
  );
};

InvestmentAlternativesSection2.propTypes = {
  isVisible: PropTypes.bool,
};

export default InvestmentAlternativesSection2;
