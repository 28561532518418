/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  ExtendedFlex,
} from '../../../coreui';

import TriPanelCard from './TriPanelCard';

const TriPanelSection2 = (props) => {
  const {
    topCardBg,
    bottomCardBg,
    topCard,
    bottomCard,
    wrapperProps,
    topWrapperProps,
    bottomWrapperProps,
  } = props;

  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
      height="100%"
    >
      <ExtendedFlex
        flexDirection="column"
        height="100%"
      >
        <ExtendedFlex
          m={0}
          pb={30}
          bg={topCardBg}
          height={[null, null, '50%', '50%', '50%']}
          minHeight={[350, 500, '50%']}
          w={1}
          {...wrapperProps}
          {...topWrapperProps}
        >
          <TriPanelCard {...topCard} />
        </ExtendedFlex>
        <ExtendedFlex
          height={[null, null, '50%', '50%', '50%']}
          minHeight={[350, 500, '50%']}
          bg={bottomCardBg}
          w={1}
          {...wrapperProps}
          {...bottomWrapperProps}
        >
          <TriPanelCard {...bottomCard} />
        </ExtendedFlex>
      </ExtendedFlex>
    </Box>
  );
};

TriPanelSection2.propTypes = {
  topCardBg: PropTypes.string,
  bottomCardBg: PropTypes.string,
  topCard: PropTypes.object,
  bottomCard: PropTypes.object,
  wrapperProps: PropTypes.object,
  topWrapperProps: PropTypes.object,
  bottomWrapperProps: PropTypes.object,
};

TriPanelSection2.defaultProps = {
  topCardBg: 'gray.1',
  bottomCardBg: 'white',
};

export default TriPanelSection2;
