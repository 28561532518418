/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Data,
} from '../../coreui';

const Stepper = ({
  step,
  total,
  ...props
}) => {
  return (
    <ExtendedFlex
      flexDirection="column"
      mb={80}
      {...props}
    >
      <Data
        type="D3"
        mt={0}
        mb={10}
      >
        {step} of {total}
      </Data>
      <ExtendedFlex
        w={1}
        bg="gray.12"
        height={4}
      >
        <ExtendedFlex
          w={`${(step / total) * 100}%`}
          bg="blue.0"
        />
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

Stepper.propTypes = {
  step: PropTypes.number,
  total: PropTypes.number,
};

export default Stepper;
