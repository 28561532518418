/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import {
  space,
  width,
  height,
  display,
  propTypes,
} from 'styled-system';
import CleanElement from 'clean-element';

const CleanSVG = CleanElement('svg');

CleanSVG.propTypes = {
  ...propTypes.width,
  ...propTypes.height,
};

const Image = styled(CleanSVG)`
  ${space}
  ${width}
  ${height}
  ${display}
`;

const getDimensions = () => ({
  height,
  width,
});

const SvgStyled = Object.assign(Image, {
  getDimensions,
  displayName: 'SvgStyled',
});

SvgStyled.propTypes = {
  ...propTypes.w,
  ...propTypes.height,
  ...propTypes.space,
  ...propTypes.display,
  viewbox: PropTypes.string,
};

const Svg = (props) => {
  const {
    children,
    ...additionalProps
  } = props;

  return (
    <SvgStyled
      {...additionalProps}
    >
      {children}
    </SvgStyled>
  );
};

Svg.propTypes = {
  children: PropTypes.node,
};

export default Svg;
