/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';
import Gallery from 'react-grid-gallery';

import {
  ExtendedBox,
  ExtendedFlex,
  Loader,
  Drawer,
  ScrollToTopOnMount,
} from '../coreui';

import actions from './actions';
import dashboardActions from '../dashboard/actions';
import messagesActions from '../dashboard/messages/actions';

import Back from './components/Back';
import Reit from './components/Reit';
import {
  getReitData,
} from '../dashboard/components/Reits';
import PrivatePlacement from './components/PrivatePlacement';
import InvestmentUpdates from './components/InvestmentUpdates';
import Section from '../ioDetails/components/section';
import DocumentItem from '../ioDetails/components/documentItem';
import FourOhOne from '../components/FourOhOne';
import FourOhThree from '../components/FourOhThree';
import FourOhFour from '../components/FourOhFour';
import FiveHundred from '../components/FiveHundred';

class Invested extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.getDashboardData = this.getDashboardData.bind(this);
    this.filterIoData = this.filterIoData.bind(this);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getInvestedData(id);
  }

  getDashboardData() {
    const dashboardData = {
      totalInvestmentAmount: null,
      totalDistributionsPaid: null,
    };

    const isReit = this.props.isReit;

    // Only get the data if it's loaded.
    if (!this.props.dashboardLoaded) {
      return {
        ...dashboardData,
      };
    }

    const activeInvestments = this.props.dashboardData.activeInvestmentsData;
    const completedInvestments = this.props.dashboardData.completedInvestmentsData;

    // If it's a REIT, grab the data out of the active investments.
    if (isReit) {
      const reitData = getReitData(this.props.dashboardData, true);
      return {
        ...reitData.filter(this.filterIoData)[0],
      };
    }

    // If it's private placement, we need to look for active or completed.
    const activeIoData = activeInvestments.privatePlacementData.ios.filter(this.filterIoData);

    if (activeIoData.length) {
      return {
        ...dashboardData,
        ...activeIoData[0],
      };
    }

    const completedIoData = completedInvestments.privatePlacementData.ios.filter(this.filterIoData);
    return {
      ...dashboardData,
      ...completedIoData[0],
    };
  }

  filterIoData(io) {
    const id = this.props.match.params.id;
    const parsedId = parseInt(id, 10);
    return io.nid === id || io.nid === parsedId;
  }

  render() {
    const {
      loaded,
      status,
      isReit,
      ioDetails,
      privatePlacement,
      dashboardLoaded,
      messagesLoaded,
      signedDocuments,
      isApp,
      frontendPrefix,
    } = this.props;

    if (!loaded) {
      return (
        <ExtendedFlex
          minHeight="75vh"
          position="relative"
        >
          <ScrollToTopOnMount />
          <Loader />
        </ExtendedFlex>
      );
    }

    if (status >= 400) {
      switch (true) {
        case status === 401:
          return <FourOhOne />;
        case status === 403:
          return <FourOhThree />;
        default:
          return <FourOhFour />;
        case status >= 500:
          return <FiveHundred />;
      }
    }

    // Filter the messages down to the current investment.
    const messages = messagesLoaded ? this.props.messages.filter((message) => {
      return message.investment === ioDetails.label;
    }) : [];

    const dashboardData = this.getDashboardData();
    return (
      <ExtendedBox>
        <ScrollToTopOnMount />
        <Back />
        {
          isReit &&
          <Reit
            loaded={dashboardLoaded}
            data={dashboardData}
            title={privatePlacement.io.label}
            subTitle={privatePlacement.io.blurb}
          />
        }
        {
          !isReit &&
          <PrivatePlacement
            gallery={privatePlacement.gallery}
            totalInvested={dashboardData.totalInvestmentAmount}
            totalDistributions={dashboardData.totalDistributionsPaid}
            isApp={isApp}
            frontendPrefix={frontendPrefix}
            {...privatePlacement.io}
          />
        }
        <Section
          bg="gray.1"
        >
          <Drawer
            w={1}
            title="Signed Documents"
            name="documents"
          >
            <ExtendedBox
              mt={10}
              mb={40}
              mx={40}
            >
              {
                signedDocuments &&
                signedDocuments.map((doc) => {
                  return (
                    <DocumentItem
                      doc={doc}
                      key={`ir_${doc.id}`}
                      linkTarget="_blank"
                    />
                  );
                })
              }
            </ExtendedBox>
          </Drawer>
          {
            !isReit &&
            <Drawer
              w={1}
              title="Photos"
              name="photos"
            >
              &nbsp;
              <div>
                <Gallery
                  images={privatePlacement.gallery}
                  margin={10}
                  enableImageSelection={false}
                  showImageCount={false}
                />
              </div>
            </Drawer>
          }
        </Section>
        <InvestmentUpdates
          loaded={messagesLoaded}
          messages={messages}
        />
      </ExtendedBox>
    );
  }
}

Invested.propTypes = {
  loaded: PropTypes.bool,
  status: PropTypes.number,
  match: PropTypes.object,
  getInvestedData: PropTypes.func,
  isReit: PropTypes.bool,
  dashboardData: PropTypes.object,
  dashboardLoaded: PropTypes.bool,
  ioDetails: PropTypes.object,
  privatePlacement: PropTypes.object,
  messages: PropTypes.array,
  messagesLoaded: PropTypes.bool,
  signedDocuments: PropTypes.array,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

const mapStateToProps = (state) => {
  const loaded = state.invested.loaded;
  const isReit = loaded && state.ioDetails.ioDetailType === 'REIT';

  return {
    loaded,
    isReit,
    status: state.invested.status,
    dashboardData: state.dashboard.data,
    dashboardLoaded: state.dashboard.data.loaded,
    messages: state.messages.messageValues,
    messagesLoaded: state.messages.loaded,
    ioDetails: state.ioDetails,
    privatePlacement: state.privatePlacement,
    signedDocuments: state.invested.signedDocuments,
    isApp: state.app.isApp,
    frontendPrefix: state.app.frontendPrefix,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInvestedData: (id) => {
      dispatch(actions.investedPageRequested(id));
      dispatch(dashboardActions.dashboardApiActionRequested());
      dispatch(messagesActions.getMessageDataRequested());
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invested));
