import React from 'react';
import {
  Auth,
  JS,
} from 'aws-amplify';
import { RequireNewPassword } from 'aws-amplify-react';

import {
  Heading,
  Body,
  Link,
} from '../../coreui';

import Container from './Container';
import FirstContainer from './FirstContainer';
import SecondContainer from './SecondContainer';

import Form from '../../components/forms/Form';
import Label from '../../components/forms/Label';
import PasswordInput from '../../components/forms/PasswordInput';
import Questions from './Questions';
import PrimaryButton from '../../components/forms/PrimaryButton';
import Actions from '../../components/forms/Actions';
import trackError from './trackError';

class CustomRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      submitting: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  checkContact(user) {
    if (!Auth || typeof Auth.verifiedContact !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    Auth.verifiedContact(user)
      .then((data) => {
        if (!JS.isEmpty(data.verified)) {
          this.changeState('signedIn', user);
        } else {
          this.changeState('signIn');
          super.error('Your password has been updated and you may now log in with it.');
        }
      });
  }

  error(err) {
    this.setState({ submitting: false });
    trackError('Require New Password Error', { error: err });
    super.error(err);
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.change();
  }

  showComponent() {
    const symbols = '\\^$*.\\[\\]{}()?\\-"!@#%&\\/\\\\,><\':;\\|_~`';

    return (
      <Container>
        <FirstContainer>
          <Heading
            type="H2"
          >
            Change Password.
          </Heading>
          <Body
            type="B1"
          >
            A password change is required. Please enter your new password below.
          </Body>
          <Form onSubmit={this.handleFormSubmit} >
            <Label
              htmlFor="password"
            >
              Password
              <PasswordInput
                id="password"
                name="password"
                onChange={this.handleInputChange}
                autoComplete="new-password"
                placeholder="******************"
                pattern={`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[${symbols}])[A-Za-z\\d${symbols}]{8,}$`}
                required
                data-test="password"
              />
            </Label>
            <Actions>
              <PrimaryButton
                buttonType="submit"
                loading={this.state.submitting}
                disable={this.state.submitting}
                data-test="require-new-password-submit"
              >
                Continue
              </PrimaryButton>
              <Link
                type="L4"
                to="/user/login"
                onClick={() => this.changeState('signIn')}
              >
                Back to sign in
              </Link>
            </Actions>
          </Form>
          <Questions />
        </FirstContainer>
        <SecondContainer />
      </Container>
    );
  }
}

CustomRequireNewPassword.propTypes = {
};

export default CustomRequireNewPassword;
