/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Link,
} from '../../coreui';

const FilterButtons = ({
  options,
  active,
  linkProps,
  ...additionalOptions
}) => {
  return (
    <ExtendedFlex
      flexDirection={['column', 'row']}
      justifyContent="center"
      alignItems="center"
      {...additionalOptions}
    >
      {
        options.map((option) => {
          const bgColor = option.id === active ? 'black' : 'white';
          const color = option.id === active ? 'white' : 'black';
          return (
            <Link
              key={`filterButton:${option.label}`}
              type="L7"
              bg={bgColor}
              color={color}
              border="1px solid #202020"
              textTransform="uppercase"
              onClick={option.handleClick}
              mr="5px"
              {...linkProps}
            >
              { option.label }
            </Link>
          );
        })
      }
    </ExtendedFlex>
  );
};

FilterButtons.propTypes = {
  options: PropTypes.array,
  active: PropTypes.string,
  linkProps: PropTypes.object,
};

export default FilterButtons;
