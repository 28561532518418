import types from './types';

export default {
  updateKnowledgeCenterFilterRequested: (category) => ({
    type: types.UPDATE_KNOWLEDGE_CENTER_FILTER_REQUESTED,
    category,
  }),
  updateKnowledgeCenterFilterSucceeded: (data) => ({
    type: types.UPDATE_KNOWLEDGE_CENTER_FILTER_SUCCEEDED,
    data,
  }),
  updateKnowledgeCenterFilterFailed: (error) => ({
    type: types.UPDATE_KNOWLEDGE_CENTER_FILTER_FAILED,
    error,
  }),
  getMoreArticlesRequested: (url) => ({
    type: types.GET_MORE_ARTICLES_REQUESTED,
    url,
  }),
  getMoreArticlesSucceeded: (data) => ({
    type: types.GET_MORE_ARTICLES_SUCCEEDED,
    data,
  }),
  getMoreArticlesFailed: (error) => ({
    type: types.GET_MORE_ARTICLES_FAILED,
    error,
  }),
  getArticleQueueRequested: (queue) => ({
    type: types.GET_ARTICLE_QUEUE_REQUESTED,
    queue,
  }),
  getArticleQueueSucceeded: (data) => ({
    type: types.GET_ARTICLE_QUEUE_SUCCEEDED,
    data,
  }),
  getArticleQueueFailed: (error) => ({
    type: types.GET_ARTICLE_QUEUE_FAILED,
    error,
  }),
};
