import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedBox,
} from '../../coreui';

const HeaderMenuChildrenContainer = ({
  handleMouseLeave,
  handleMouseEnter,
  firstLevelHoveredItem,
  machineName,
  browser,
  children,
}) => {
  return (
    <ExtendedBox
      position="absolute"
      maxHeight={
        machineName === firstLevelHoveredItem ?
          '1000px' :
          '0px'
      }
      top="90px"
      bg="white"
      left="inherit"
      transform={browser === 'ie11' ? 'translateX(-50%)' : 'none'}
      px={40}
      pb={
        machineName === firstLevelHoveredItem ?
          40 :
          0
      }
      boxSizing="border-box"
      id={machineName}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      visibility={
        machineName === firstLevelHoveredItem ?
          'visible' :
          'hidden'
      }
    >
      {children}
    </ExtendedBox>
  );
};

HeaderMenuChildrenContainer.propTypes = {
  handleMouseLeave: PropTypes.func,
  handleMouseEnter: PropTypes.func,
  firstLevelHoveredItem: PropTypes.string,
  machineName: PropTypes.string,
  browser: PropTypes.string,
  children: PropTypes.any,
};

export default HeaderMenuChildrenContainer;
