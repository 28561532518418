/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Body,
} from '../../../coreui';

const TableCell = (props) => {
  const {
    column,
    value,
    original,
  } = props;
  return (
    <ExtendedFlex
      alignItems="center"
      m={0}
    >
      <Body
        type="B3"
        m={0}
        {...(!original.isRead ? {
          fontWeight: 'bold',
        } : {})}
      >
        {column.id === 'status' ? value.status : value}
      </Body>
    </ExtendedFlex>
  );
};

TableCell.propTypes = {
  column: PropTypes.object,
  value: PropTypes.node,
  original: PropTypes.object,
};

export default TableCell;
