/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  ExtendedFlex,
  formatters,
} from '../../coreui';

import Section from '../../ioDetails/components/section';
import Header from '../../ioDetails/components/header';
import HeaderOverview from '../../privatePlacement/components/headerOverview';
import Summary from './Summary';

const PrivatePlacement = (props) => {
  const gallery = props.gallery;
  const id = props.id;
  const subTitle = (props.management) ? `Sponsored by ${props.management[0].label}` : null;
  const details = (props.statusValue !== 'status-40-closed' ? [
    { label: 'Start Date', value: props.escrowCloseDate },
    { label: 'Investment Entity', value: props.issuer },
    { label: 'Property Type', value: props.propertyType },
    { label: 'Expected Distribution Date', value: props.expectedNextDistribution },
    { label: 'Investment Type', value: props.investmentType },
    props.propertyClass && { label: 'Property Class', value: props.propertyClass },
  ] : [
    { label: 'Start Date', value: props.escrowCloseDate },
    {
      label: 'Maturity Date',
      value: props.actualMaturityDate ?
        moment(props.actualMaturityDate).format(formatters.MYYYY) : null,
    },
    { label: 'Investment Entity', value: props.issuer },
    { label: 'Investment Type', value: props.investmentType },
    { label: 'Property Type', value: props.propertyType },
    props.propertyClass && { label: 'Property Class', value: props.propertyClass },
  ]).filter(Boolean);
  return (
    <Section
      bg="white"
      py={0}
    >
      <Header
        title={props.label}
        subTitle={subTitle}
        mb={40}
      />
      <HeaderOverview
        gallery={gallery}
        details={details}
        id={id}
        isApp={props.isApp}
        frontendPrefix={props.frontendPrefix}
        {...(props.statusValue !== 'status-40-closed' ? {
          link: {
            href: `/investment-opportunity/${id}`,
            text: 'View Investment',
          },
        } : {})}
      />
      <ExtendedFlex
        flexDirection="column"
        align="center"
        mx={[40, 40, 0]}
      >
        <Summary
          totalInvested={props.totalInvested}
          totalDistributions={props.totalDistributions}
        />
      </ExtendedFlex>
    </Section>
  );
};

PrivatePlacement.propTypes = {
  gallery: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  management: PropTypes.array,
  investmentType: PropTypes.string,
  propertyType: PropTypes.string,
  propertyClass: PropTypes.string,
  issuer: PropTypes.string,
  escrowCloseDate: PropTypes.string,
  actualMaturityDate: PropTypes.string,
  expectedNextDistribution: PropTypes.string,
  totalInvested: PropTypes.number,
  totalDistributions: PropTypes.number,
  statusValue: PropTypes.string,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

export default PrivatePlacement;
