/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types';

const configureHyperform = (hyperform, options) => {
  hyperform.addTranslation('en', {
    TextTooLong: 'Please shorten this text to %l characters or less (you are currently using %l characters).',
    ValueMissing: 'This field is required.',
    CheckboxMissing: 'This field is required.',
    RadioMissing: 'Please select one of these options.',
    FileMissing: 'Please select a file.',
    SelectMissing: 'Please select an item in the list.',
    InvalidEmail: 'Please enter a valid email.',
    InvalidURL: 'Please enter a URL.',
    PatternMismatch: 'Please match the requested format.',
    PatternMismatchWithTitle: 'Please match the requested format: %l.',
    NumberRangeOverflow: 'Please select a value that is no more than %l.',
    DateRangeOverflow: 'Please select a value that is no later than %l.',
    TimeRangeOverflow: 'Please select a value that is no later than %l.',
    NumberRangeUnderflow: 'Please select a value that is no less than %l.',
    DateRangeUnderflow: 'Please select a value that is no earlier than %l.',
    TimeRangeUnderflow: 'Please select a value that is no earlier than %l.',
    StepMismatch: 'Please select a valid value. The two nearest valid values are %l and %l.',
    StepMismatchOneValue: 'Please select a valid value. The nearest valid value is %l.',
    BadInputNumber: 'Please enter a number.',
  });
  if (options?.confirmPassword) {
    hyperform.addValidator(
      document.getElementById('password_confirm'),
      (element) => {
        const valid = element.value === document.getElementById('password').value;
        element.setCustomValidity(
          valid ?
            '' :
            'Password confirmation does not match',
        );
        return valid;
      },
    );
    document.getElementById('password').addEventListener('keyup', () => {
      document.getElementById('password_confirm').reportValidity();
    });
  }
};

class Form extends Component {
  constructor(props) {
    super(props);

    this.formRef = null;
    this.setFormRef = (element) => {
      this.formRef = element;
    };
  }

  componentDidMount() {
    if (window && window.hyperform && !window.Cypress) {
      configureHyperform(window.hyperform, this.props.validationOptions);
      window.hyperform(this.formRef, {
        ...this.props.validationOptions,
      });
    }
  }

  render() {
    const {
      validationOptions,
      ...props
    } = this.props;
    return (
      <form
        {...props}
        ref={this.setFormRef}
      />
    );
  }
}

Form.propTypes = {
  validate: PropTypes.bool,
  validationOptions: PropTypes.object,
};

Form.defaultProps = {
  validationOptions: {
    revalidate: 'hybrid',
    validateNameless: true,
  },
};

export default Form;
