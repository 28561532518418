import {
  takeEvery,
  call,
} from 'redux-saga/effects';
import { Auth } from 'aws-amplify';

import appTypes from '../../appWrapper/types';
import { isMockSession } from '../api';

const attrMap = {
  firstName: 'given_name',
  lastName: 'family_name',
  email: 'email',
  phoneNumber: 'phone_number',
};

const getChangedAttributes = (apiUser, user) => {
  return Object.keys(apiUser).reduce((changedProps, prop) => {
    if (attrMap[prop]) {
      const propMap = attrMap[prop];
      const value = apiUser[prop];
      if (value !== user[propMap]) {
        return {
          ...changedProps,
          [propMap]: value,
        };
      }
    }
    return changedProps;
  }, {});
};

export function* getUserDataFlow(action) {
  try {
    if (isMockSession()) {
      return;
    }
    const apiUser = action.user;
    const cognitoUser = yield call(() => Auth.currentAuthenticatedUser());
    const changedProps = yield call(getChangedAttributes, apiUser, cognitoUser.attributes);

    if (Object.keys(changedProps).length) {
      yield call(() => Auth.updateUserAttributes(cognitoUser, changedProps));
    }
  } catch (error) {
    // Do nothing.
  }
}

export function* watchUpdateUserRequested() {
  yield takeEvery(
    appTypes.GET_USER_DATA_SUCCEEDED,
    getUserDataFlow,
  );
}

export default watchUpdateUserRequested;
