import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';

import Numeral from '../../coreui/components/Numeral';

const ErrorContainer = styled.span`
  background: red;
  color: #FFF;
  animation: fadein 2s;
  
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
`;

const Token = ({
  code,
  format,
  dateFormat,
  tokens = undefined,
}) => {
  if (tokens[code]) {
    if (format) {
      if (format === 'date') {
        return moment.unix(tokens[code]).format(dateFormat);
      }
      return <Numeral value={tokens[code]} format={format} />;
    }
    return tokens[code];
  }
  return <ErrorContainer>[TOKEN ERROR]: {code} does not exist</ErrorContainer>;
};

const mapStateToProps = (state) => ({
  tokens: state.tokens,
});

Token.propTypes = {
  code: PropTypes.string,
  format: PropTypes.string,
  dateFormat: PropTypes.string,
  tokens: PropTypes.object,
};

export default connect(mapStateToProps)(Token);
