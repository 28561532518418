import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Image,
} from '../../..';

import collapseIcon from '../../../assets/icon_collapse.svg';
import expandIcon from '../../../assets/icon_expand.svg';
import downIcon from '../../../assets/down.svg';

class AccordionHeading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.handleClick(this.props.id);
  }

  render() {
    const {
      isActive,
      content,
      isUppercase,
      isReitLP,
    } = this.props;

    const getTransform = (active, reit) => {
      if (reit) {
        return active ? 'rotate(180deg)' : 'rotate(0deg)';
      }
      return active ? 'rotate(0deg)' : 'rotate(-90deg)';
    };

    return (
      <ExtendedFlex
        w={1}
        justify="flex-start"
        align="center"
        onClick={this.handleClick}
        px={isReitLP ? '' : ['10%', 20]}
        hover
      >
        <ExtendedBox
          w={15}
          height={15}
          position="relative"
          transition={isReitLP ? 'transform 0.2s linear' : 'transform 0.3s linear'}
          transform={getTransform(isActive, isReitLP)}
        >
          <Image
            position="absolute"
            top="0"
            left="0"
            w={15}
            fill={isReitLP ? 'blue' : '#202020'}
            src={isReitLP ? downIcon : collapseIcon}
            hover
          />
          <Image
            position="absolute"
            top="0"
            left="0"
            w={15}
            src={isReitLP ? downIcon : expandIcon}
            hover
            opacity={isActive ? '0' : '1'}
            transition="opacity 0.3s linear"
          />
        </ExtendedBox>
        <Heading
          color="black"
          type={isReitLP ? 'ReitLP' : 'H6'}
          m={0}
          ml={isReitLP ? 24 : 10}
          textTransform={isUppercase ? 'uppercase' : 'none'}
        >
          {content}
        </Heading>
      </ExtendedFlex>
    );
  }
}

AccordionHeading.propTypes = {
  isActive: PropTypes.bool,
  content: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleClick: PropTypes.func,
  isUppercase: PropTypes.bool,
  isReitLP: PropTypes.bool,
};

export default AccordionHeading;
