/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
} from '..';

import smallLeftArrow from '../assets/carousel-arrow-small-left.svg';
import smallRightArrow from '../assets/carousel-arrow-small-right.svg';
import bigLeftArrow from '../assets/carousel-arrow-large-left.svg';
import bigRightArrow from '../assets/carousel-arrow-large-right.svg';

const Arrow = (props) => {
  const {
    direction,
    size,
    ...additionalProps
  } = props;

  const imgSrc = (() => {
    const arrows = {
      large: {
        right: bigRightArrow,
        left: bigLeftArrow,
      },
      small: {
        right: smallRightArrow,
        left: smallLeftArrow,
      },
    };
    return arrows[size][direction];
  })();

  return (
    <Image
      src={imgSrc}
      width={[15, 20, 15, 20, 25]}
      height={[15, 20, 15, 20, 25]}
      hover
      dimOnButtonParentHover
      transition="all 0.4s cubic-bezier(.25, .46, .45, .94)"
      {...additionalProps}
    />
  );
};

Arrow.propTypes = {
  direction: PropTypes.oneOf([
    'left',
    'right',
  ]),
  size: PropTypes.oneOf([
    'large',
    'small',
  ]),
};

Arrow.defaultProps = {
  direction: 'left',
  size: 'small',
};

export default Arrow;
