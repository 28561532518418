import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Body,
  Heading,
  Data,
  InlineTextLink,
  StyledButton,
  Tooltip,
  TooltipLink,
  TooltipIcon,
} from '../../coreui';

import Form from '../../components/forms/Form';
import Input from '../../components/forms/Input';
import Label from '../../components/forms/Label';
import Select from '../../components/forms/Select';
import RadioGroup from '../../components/forms/RadioGroup';
import RadioLabel from '../../components/forms/RadioLabel';

import ShareRepurchaseHeading from './ShareRepurchaseHeading';
import ShareRepurchaseWrapper from './ShareRepurchaseWrapper';
import Actions from '../../components/forms/Actions';
import PrimaryButton from '../../components/forms/PrimaryButton';

const ShareRepurchaseForm = ({
  options,
  accounts,
  investmentOpportunity,
  account,
  quarter,
  year,
  percentLimit,
  percentLimitFormatted,
  offeringCircular,
  eligibleShares,
  handleSetInvestmentOpportunity,
  handleSetFoe,
  handleSetRepeat,
  handleSetSharesPercent,
  handleFormSubmit,
  handelCancelRequest,
  daysPriorEndOfQuarter,
  daysProcessedEndOfQuarter,
}) => {
  const [showOther, setShowOther] = useState();

  return (
    <Form onSubmit={handleFormSubmit}>
      <ShareRepurchaseWrapper>
        <ShareRepurchaseHeading>
          Share Repurchase Request Form
        </ShareRepurchaseHeading>
        <Body type="B1">
          Thank you for notifying us of your request. Please fill out the form
          below. Once submitted, please check your inbox for an email regarding
          important next steps.
        </Body>
        <Heading type="H6" textTransform="uppercase">
          Which investment opportunity is the request for?
        </Heading>
        <Select
          placeholder="Investment Opportunity"
          value={investmentOpportunity}
          onChange={(e) => {
            if (investmentOpportunity !== e.target.value) {
              handleSetInvestmentOpportunity(parseInt(e.target.value, 10));
            }
          }}
          name="io"
          required
          showEmpty
          options={options}
          data-test="select-investment"
        />
        {accounts.length > 0 && (
          <>
            <Heading type="H6" textTransform="uppercase">
              Which investment account is the request for?
            </Heading>
            <Select
              placeholder="Account"
              value={account}
              onChange={(e) => {
                handleSetFoe(parseInt(e.target.value, 10));
              }}
              name="account"
              required
              showEmpty
              options={accounts}
              data-test="select-account"
            />
          </>
        )}
        {account && (
          <>
            <Heading type="H6" textTransform="uppercase">
              Next Eligible Quarter:
            </Heading>
            <Body type="B1">
              Please note that repurchase requests must be submitted at least{' '}
              {daysPriorEndOfQuarter} days prior to the end of the applicable
              quarter. Approved repurchases will transpire within{' '}
              {daysProcessedEndOfQuarter} days of the end of the applicable
              quarter.
            </Body>
            <Data type="D1">Your next eligible quarter is:</Data>
            <Body type="B1">
              Q{quarter} {year}
            </Body>
            <Heading type="H6" textTransform="uppercase">
              Amount of Shares
            </Heading>
            <Body type="B1">
              Please note you are able to request repurchase of{' '}
              {percentLimitFormatted} of your shares on a quarterly basis after
              your one year hold period on your investment. Dependent on the
              length of time you have held your shares, the effective repurchase
              rate may vary. We intend to{' '}
              <TooltipLink
                component={<InlineTextLink />}
                tooltipId="limitedSharesTooltip"
              >
                limit the number of shares
              </TooltipLink>{' '}
              to be repurchased during any calendar year to 5.0% of the weighted
              average number of common shares outstanding during the prior
              calendar year (or 1.25% per quarter, with excess capacity carried
              over to later quarters in the calendar year). In the event that we
              do not have sufficient funds available to repurchase all of the
              common shares for which share repurchase requests have been
              submitted in any quarter, such pending requests will be honored on
              a pro rata basis. Please view the{' '}
              <InlineTextLink href={offeringCircular} target="_blank">
                Offering Circular
              </InlineTextLink>{' '}
              for more information.{' '}
            </Body>
            <Tooltip id="limitedSharesTooltip">
              <Body type="B3">
                While we designed our share repurchase program to allow
                shareholders to request share repurchases on a quarterly basis
                (subject to the holding period). we need to impose limitations
                on the total amount of net repurchase per quarter in order to
                maintain sufficient sources of liquidity to satisfy share
                repurchase requests without impacting our ability to invest in
                commercial real estate assets and maximize investor returns.
              </Body>
            </Tooltip>
            <Data type="D1">Eligible Shares</Data>
            <Body type="B1">{eligibleShares}</Body>
            <RadioGroup
              name="sharesPercent"
              onChange={(e) => {
                const value = e.target.value;
                const showOther = value === 'other';
                setShowOther(showOther);
                const sharesPercent = !showOther
                  ? parseFloat(e.target.value)
                  : undefined;
                handleSetRepeat(sharesPercent === 1 ? 1 : 0);
                handleSetSharesPercent(
                  sharesPercent === 1 ? percentLimit : sharesPercent,
                );
              }}
              inputProps={{
                required: true,
              }}
              data-test="select-shares-percent"
            >
              <RadioLabel
                value={`${percentLimit}`}
                label={`${percentLimitFormatted} - One time repurchase of shares`}
                data-test="redeem-twenty-five"
              />
              <RadioLabel
                value="1"
                label={
                  <>
                    100% - Recurring Quarterly repurchase of shares
                    <TooltipIcon tooltipId="repurchaseAllTooltip" ml={5} />
                  </>
                }
                data-test="redeem-all"
              />
              <RadioLabel
                value="other"
                label="Other"
                data-test="redeem-other"
              />
            </RadioGroup>
            <Tooltip id="repurchaseAllTooltip">
              <Body type="B3">
                Upon approval, shareholders are eligible to request up to{' '}
                {percentLimitFormatted} of their shares be repurchased quarterly
                until 100% of shares have been repurchased. In the event there
                are insufficient funds to honor all requested share repurchases
                or total requests exceed the maximum share repurchase amount our
                REIT is able to repurchase for the applicable quarter, we will
                use the funds available and honor the repurchase requests on a
                pro rata basis.
              </Body>
            </Tooltip>
            {showOther && (
              <>
                <Label htmlFor="otherAmount">
                  <Body type="B2">
                    Please note that the &quot;Other Amount&quot; percentage
                    below is only available for a one time repurchase of under{' '}
                    {percentLimitFormatted}
                  </Body>
                  <Input
                    id="otherAmount"
                    placeholder="10%"
                    type="number"
                    min="1"
                    max={percentLimit * 100}
                    step="1"
                    name="otherAmount"
                    onChange={(e) => {
                      handleSetRepeat(0);
                      handleSetSharesPercent(parseInt(e.target.value, 10) / 100);
                    }}
                    data-test="otherAmount"
                    maxLength={3}
                    required
                  />
                </Label>
              </>
            )}
            <Actions>
              <PrimaryButton buttonType="submit">Continue</PrimaryButton>
              <StyledButton type="L4" onClick={handelCancelRequest}>
                Cancel request
              </StyledButton>
            </Actions>
          </>
        )}
      </ShareRepurchaseWrapper>
    </Form>
  );
};

ShareRepurchaseForm.propTypes = {
  options: PropTypes.array,
  accounts: PropTypes.array,
  investmentOpportunity: PropTypes.number,
  account: PropTypes.number,
  quarter: PropTypes.number,
  year: PropTypes.number,
  percentLimit: PropTypes.number,
  percentLimitFormatted: PropTypes.string,
  offeringCircular: PropTypes.string,
  eligibleShares: PropTypes.number,
  handleSetInvestmentOpportunity: PropTypes.func,
  handleSetFoe: PropTypes.func,
  handleSetRepeat: PropTypes.func,
  handleSetSharesPercent: PropTypes.func,
  handleFormSubmit: PropTypes.func,
  handelCancelRequest: PropTypes.func,
  daysPriorEndOfQuarter: PropTypes.number,
  daysProcessedEndOfQuarter: PropTypes.number,
};

export default ShareRepurchaseForm;
