import React from 'react';
import { Link } from 'react-router-dom';

import Styled from './index.styled';

import Background from './header_buildings.jpg';
import Graph from './graph_mid.png';
import Skyline from './mid_skyline_2.jpg';

const MultifamilyInvestments = () => {
  return (
    <Styled.Pages>
      <header className="text-image-header" style={{ backgroundImage: `url(${Background})` }}>
        <h1 className="heading wide">How You Can Potentially<br />Benefit From Multifamily<br />Investments</h1>
      </header>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p>Investing in multifamily properties has potential benefits for those looking to build wealth and generate passive income.</p>
            <p>Over the 25-year period from 1992 through 2017, multifamily real estate provided the highest average annual total returns (9.75%) of any commercial real estate sector with the second lowest level of volatility (7.75%), according to research cited in a 2018 report by CBRE, the world’s largest commercial real estate investment firm.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <img src={Graph} style={{ maxWidth: '1000px', display: 'block', margin: 'auto' }} alt="graph" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>Another potential reason to pursue multifamily investing lies in the fact that renting represents the most common form of housing for the millennial generation, the largest generation in U.S. history. </p>
            <p>Apartment List’s 2021 Millennial Homeownership Report reveals that just 42% percent of millennials own homes, compared to 48% of generation Xers and 51% of baby boomers.</p>
            <p>More and more baby boomers are also trending toward renting vs. owning property. According to U.S. Census data, between 2009 and 2015, the biggest changes in the renting population came from those aged 55 and older.</p>
            <p>Another strength of multifamily property investments is their short-term lease agreements. Multifamily leases are typically one year compared to five-year or more leases that you’d typically have with other forms of commercial real estate. This allows multifamily property owners to more easily raise rents at the time of renewal or tenant turnover.</p>
            <p>Data also suggests an increased demand for workforce housing. Workforce housing generally refers to multifamily properties for middle-income households, although it can include families earning anywhere from 60% to 120% of their area’s median income. These properties are often Class B and Class C apartments that do not have the amenities of the higher-end complexes, and serve people who are being priced out of the market for units in more attractive buildings.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <img src={Skyline} style={{ marginTop: '16px' }} alt="skyline" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>Here at RealtyMogul, our mission is to empower you to live the life you want by potentially generating wealth through real estate investing. Since 2012, RealtyMogul Members have collectively invested over $800 million into over $4.1 billion of real estate nationwide.</p>
            <p>To view our investment opportunities, click on the button below.</p>
          </div>
          <div className="col-12">
            <Link to="/investment-opportunities"><button type="button" style={{ fontSize: '142%', margin: '0 auto' }}>VIEW INVESTMENTS</button></Link>
          </div>
          <p style={{ position: 'relative', fontSize: '12px', width: '100%' }}>*As of December 31st, 2021</p>
          <p style={{ left: '5px', margin: '0 0 8px', position: 'relative', fontSize: '12px' }}><span style={{ fontSize: '8px', display: 'block', position: 'absolute', left: '-6px', top: '-3px' }}>1</span> Tenure of occupied housing units, age groups, education attainment, and household types were obtained from Census ACS 5-year estimates for 2009-2015.</p>
        </div>
      </div>
    </Styled.Pages>
  );
};

export default MultifamilyInvestments;
