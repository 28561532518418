import moment from 'moment';
import types from './types';
import authTypes from '../../auth/types';

const initialState = {
  loaded: false,
  others: false,
  renderSearch: [],
  messageValues: [],
  selected: null,
  filterVal: null,
  activeIndex: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MESSAGE_DATA_SUCCEEDED:
      return {
        ...state,
        loaded: true,
        prevPage: action.data.previous ? action.data.previous.href : null,
        nextPage: action.data.next ? action.data.next.href : null,
        messageValues: action.data.data.reduce((accum, item) => {
          // Ignore it if this is already in state.
          if (accum.filter((msg) => msg.id === item.id).length) {
            return accum;
          }
          return [
            ...accum,
            {
              ...item,
              date: moment.unix(item.date).format('YYYY/MM/DD'),
              isRead: !item.unread,
              status: item.unread ? 'Unread' : 'Read',
              attachments: item.attachments,
            },
          ];
        }, action.data.page === 0 ? [] : state.messageValues),
      };
    case types.GET_MESSAGE_DATA_READ_REQUESTED:
      return {
        ...state,
        messageValues: state.messageValues.map((item) => ({
          ...item,
          ...(item.id === action.id ? {
            isRead: true,
            status: 'Read',
          } : {}),
        })),
      };
    case authTypes.USER_LOGOUT_REQUESTED: {
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
