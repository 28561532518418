import React from 'react';
import {
  Route,
  withRouter,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BrowsePage } from '../browse';
import Homepage from '../homepage';
import Auth from '../auth';
import InvestFlow from '../investflow';
import KnowledgeCenter from '../knowledgeCenter';
import KnowledgeCenterArticle from '../knowledgeCenter/article';
import KnowledgeCenterVideo from '../knowledgeCenter/video';
import KnowledgeCenterSearch from '../knowledgeCenter/search';
import KnowledgeCenterGlossary from '../knowledgeCenter/glossary';
import HowItWorks from '../howItWorks';
import Faq from '../faq';
import IoDetails from '../ioDetails';
import InvestedPage from '../invested';
import Accreditation from '../accreditation';
import CompleteProfile from '../gtky/CompleteProfile';
import GettingToKnowYou from '../gtky';
import Privacy from '../privacy';
import TermsOfService from '../termsOfService';
import Disclosure from '../disclosure';
import OurStory from '../ourStory';
import Benefits from '../benefits';
import ContactUs from '../contactUs';
import WhyReits from '../whyReits';
import FourOhFour from '../components/FourOhFour';
import Retirement from '../retirement';
import GetFinancing from '../financing';
import InvestmentOptions from '../investmentOptions';
import WhyMultiFamily from '../whyMultiFamily';
import JVEquity from '../jvEquity';
import AutoInvest from '../autoinvest';
import TenThirtyOneExchange from '../1031Exchange';
import ReitLanding from '../components/ReitLanding';
import MultifamilyInvestments from '../565LandingPages/MultifamilyInvestments';
import FiveReasons from '../565LandingPages/FiveReasons';
import {
  DashboardRedirects,
  ProfilePage,
  InvestmentsPage,
  AutoInvestPage,
  TransactionsPage,
  TaxcenterPage,
  MessagesPage,
} from '../dashboard';
import {
  dashboardUrl,
  profileUrl,
  investmentsUrl,
  completedInvestmentsUrl,
  pendingInvestmentsUrl,
  autoInvestUrl,
  transactionsUrl,
  taxcenterUrl,
  messagesUrl,
} from '../dashboard/links';

import BorrowerSuccess from '../borrowerSuccess';
import EquitySuccess from '../equitySuccess';
import WhyCommercialRealEstate from '../whyCommercialRealEstate';

import ConnectedSwitch from './components/ConnectedSwitch';

import {
  determineIsDrupal403,
} from '../appWrapper/helpers';
import Careers from '../careers';
import ShareRepurchase from '../shareRepurchase';
import AppRedirect from './components/AppRedirect';

const isDrupal403 = determineIsDrupal403();

class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { match, isApp } = this.props;

    if (isApp) {
      return (
        <ConnectedSwitch>
          <Redirect exact path="/" to="/investor-dashboard" />
          {
            !isDrupal403 &&
            <Route
              path="/user"
              component={Auth}
            />
          }
          <Route path="/invest/:id" component={InvestFlow} />
          <Route path="/invested/:id" component={InvestedPage} />
          <Route path={profileUrl} component={ProfilePage} />
          <Route path={investmentsUrl} component={InvestmentsPage} />
          <Route path={completedInvestmentsUrl} component={InvestmentsPage} />
          <Route path={pendingInvestmentsUrl} component={InvestmentsPage} />
          <Route path={autoInvestUrl} component={AutoInvestPage} />
          <Route path={transactionsUrl} component={TransactionsPage} />
          <Route path={taxcenterUrl} component={TaxcenterPage} />
          <Route path={messagesUrl} component={MessagesPage} />
          <Route path={dashboardUrl} component={DashboardRedirects} />
          <Route path="/404" component={FourOhFour} />
          <Route path="/sharerepurchaserequest" component={ShareRepurchase} />

          {/* Do we need to support these still? */}
          <Route path="/accreditation" component={Accreditation} />
          <Route path="/complete-profile" component={CompleteProfile} />
          <Route path="/getting-to-know-you" component={GettingToKnowYou} />

          <Route path="/past-investments" component={AppRedirect} />
          <Route path="/investment-opportunity/*" component={AppRedirect} />
          <Route exact path="/investment-opportunities" component={AppRedirect} />
          <Route path="/contact-us" component={AppRedirect} />
          <Route path="/investment-options/*" component={AppRedirect} />
          <Route path="/investment-options" component={AppRedirect} />
          <Route
            path="/electronic-consent-and-delivery-of-documents"
            component={AppRedirect}
          />
        </ConnectedSwitch>
      );
    }

    return (
      <ConnectedSwitch>
        <Route exact path="/" component={Homepage} />
        {
          !isDrupal403 &&
          <Route
            path="/user"
            component={Auth}
          />
        }
        <Route path="/benefit-from-multifamily-investments" component={MultifamilyInvestments} />
        <Route path="/reasons-to-invest-in-commercial-real-estate" component={FiveReasons} />
        <Route path="/invest/:id" component={InvestFlow} />
        <Route path="/investment-opportunities" component={BrowsePage} />
        <Route path="/past-investments" component={BrowsePage} />
        <Route path="/knowledge-center/due-diligence">
          <Redirect to="/" />
        </Route>
        <Route path="/knowledge-center/article/:id" component={KnowledgeCenterArticle} />
        <Route path="/knowledge-center/video/:id" component={KnowledgeCenterVideo} />
        <Route path="/knowledge-center/glossary" component={KnowledgeCenterGlossary} />
        <Route path="/knowledge-center/why-reits" component={WhyReits} />
        <Route
          path="/knowledge-center/search/:query(.+)?"
          component={KnowledgeCenterSearch}
        />
        <Route path="/knowledge-center/retirement-investing" component={Retirement} />
        <Route path="/knowledge-center/faqs" component={Faq} />
        <Route
          path="/knowledge-center/why-commercial-real-estate"
          component={WhyCommercialRealEstate}
        />
        <Route
          path="/knowledge-center/why-multifamily"
          component={WhyMultiFamily}
        />
        <Route path="/knowledge-center/:topic" component={KnowledgeCenter} />
        <Route path="/knowledge-center" component={KnowledgeCenter} />
        <Route path="/how-it-works" component={HowItWorks} />
        <Route path="/investment-opportunity/reits" component={ReitLanding} />
        <Route path="/investment-opportunity/193744">
          <Redirect to="/investment-options/the-income-reit" />
        </Route>
        <Route path="/investment-opportunity/274949">
          <Redirect to="/investment-options/the-apartment-growth-reit" />
        </Route>
        <Route path="/investment-opportunity/:id" component={IoDetails} />
        <Route path="/benefits-of-investing" component={Benefits} />
        <Route path="/invested/:id" component={InvestedPage} />
        <Route path={profileUrl} component={ProfilePage} />
        <Route path={investmentsUrl} component={InvestmentsPage} />
        <Route path={completedInvestmentsUrl} component={InvestmentsPage} />
        <Route path={pendingInvestmentsUrl} component={InvestmentsPage} />
        <Route path={autoInvestUrl} component={AutoInvestPage} />
        <Route path={transactionsUrl} component={TransactionsPage} />
        <Route path={taxcenterUrl} component={TaxcenterPage} />
        <Route path={messagesUrl} component={MessagesPage} />
        <Route path={dashboardUrl} component={DashboardRedirects} />
        <Route path="/accreditation" component={Accreditation} />
        <Route path="/complete-profile" component={CompleteProfile} />
        <Route path="/sharerepurchaserequest" component={ShareRepurchase} />
        <Route path="/getting-to-know-you" component={GettingToKnowYou} />
        <Route path="/careers" component={Careers} />
        <Route path="/privacy-policy" component={Privacy} />
        <Route path="/terms-service" component={TermsOfService} />
        <Route path="/investment-disclosure" component={Disclosure} />
        <Route path="/our-story" component={OurStory} />
        <Route path="/404" component={FourOhFour} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/financing/overview" component={GetFinancing} />
        <Route path="/borrower-application/success" component={BorrowerSuccess} />
        <Route path="/equity-application/success" component={EquitySuccess} />
        <Route path="/investment-options/the-income-reit" component={IoDetails} key={match.path} />
        <Route path="/investment-options/the-apartment-growth-reit" component={IoDetails} />
        <Route path="/investment-options" component={InvestmentOptions} />
        <Route path="/financing/jv-equity" component={JVEquity} />
        <Route path="/auto-invest" component={AutoInvest} />
        <Route path="/1031-exchange" component={TenThirtyOneExchange} />
        <Route
          path="/electronic-consent-and-delivery-of-documents"
          component={AppRedirect}
        />
      </ConnectedSwitch>
    );
  }
}

const mapStateToProps = (state) => ({
  isApp: state.app.isApp,
});

AppRouter.propTypes = {
  match: PropTypes.object.isRequired,
  isApp: PropTypes.bool,
};

export default withRouter(
  connect(mapStateToProps)(AppRouter),
);
