import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  ExtendedBox,
  Loader,
  ScrollToTopOnMount,
} from '../coreui';

import ioFactory from './ioFactory';
import FourOhOne from '../components/FourOhOne';
import FourOhThree from '../components/FourOhThree';
import FourOhFour from '../components/FourOhFour';
import FiveHundred from '../components/FiveHundred';

import actions from './actions';
import browseActions from '../browse/browsePage/actions';
import RedirectToLoginOnLoggedOut from '../components/RedirectToLoginOnLoggedOut';

class IoDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.props.unsetState();
    let id = this.props.match.params.id;
    if (this.props.match.path.includes('the-income-reit')) id = 193744;
    if (this.props.match.path.includes('the-apartment-growth-reit')) id = 274949;
    window.scrollTo(0, 0);
    this.props.getIODataRequested({ id });
    if (this.props.filteredInvestments?.length === 0) {
      this.props.getBrowsePageDataRequested();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.unsetState();
      this.props.getIODataRequested({ id: this.props.match.params.id });
    }

    if (this.props.id !== prevProps.id) {
      this.props.getFAQDataRequested({
        is1031: this.props.is1031,
        isReit: (this.props.ioDetailType === 'REIT'),
        id: this.props.id,
      });
    }
  }

  componentWillUnmount() {
    this.props.unsetState();
  }

  render() {
    const {
      isLoggedIn,
      status,
    } = this.props;
    let Io = null;
    if (isLoggedIn && status === '404') {
      return <FourOhFour />;
    }
    switch (status) {
      case '401':
        return (<FourOhOne />);
      case '403':
        return (<FourOhThree />);
      case '500':
        return (<FiveHundred />);
      case '200':
        Io = ioFactory(this.props);
        return (
          <>
            <ScrollToTopOnMount />
            <Io />
          </>
        );
      default:
        return (
          <ExtendedBox
            position="relative"
            maxHeight={674}
            height={['32vh', '50vh']}
          >
            <ScrollToTopOnMount />
            <Loader />
            {
              status === '404' &&
              <RedirectToLoginOnLoggedOut />
            }
          </ExtendedBox>
        );
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  getIODataRequested: (id) => {
    dispatch(actions.getIODataRequested(id));
  },
  getBrowsePageDataRequested: () => {
    dispatch(browseActions.getBrowseOpenDataRequested());
  },
  getFAQDataRequested: (opts) => {
    dispatch(actions.getFAQDataRequested(opts));
  },
  unsetState: () => {
    dispatch(actions.unsetState());
  },
});

const mapStateToProps = (state) => ({
  isLoggedIn: state.app.user.isLoggedIn,
  id: state.ioDetails.id,
  is1031: state.ioDetails.is1031,
  ioDetailType: state.ioDetails.ioDetailType,
  investments: state.activeInvestments.investments,
  filteredInvestments: state.activeInvestments?.filteredInvestments,
  status: state.ioDetails.status,
});

IoDetails.propTypes = {
  isLoggedIn: PropTypes.bool,
  match: PropTypes.any,
  id: PropTypes.number,
  getIODataRequested: PropTypes.func,
  getFAQDataRequested: PropTypes.func,
  getBrowsePageDataRequested: PropTypes.func,
  status: PropTypes.string,
  filteredInvestments: PropTypes.array,
  ioDetailType: PropTypes.string,
  is1031: PropTypes.bool,
  unsetState: PropTypes.func,
  location: PropTypes.string,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IoDetails));
