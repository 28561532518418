import types from './types';

const initialState = {
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.PAGE_VARIANT_REQUESTED: {
      const {
        type,
        ...rest
      } = action?.data || {};
      if (type) {
        return {
          ...state,
          [type]: {
            ...rest,
          },
        };
      }
      return {
        ...state,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
