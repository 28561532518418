import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RMButton from '../RMButton';
import Styled from './index.styled';
import PortfolioSlide from './PortfolioSlide';

import Portfolio from '../../reit/portfolio';
import OverlayComponent from '../OverlayComponent';

const ReitPortfolio = ({
  background = '#FFF',
  title = 'Portfolio',
  cta,
  assets,
}) => {
  const [showOverlay, toggleOverlay] = useState(false);
  const [activeAsset, setActiveAsset] = useState(0);
  const [isCurrentCategory, setIsCurrentCategory] = useState(true);

  const updateCurrentSlide = (index) => {
    setActiveAsset(index);
    toggleOverlay(true);
  };

  const updateCategory = (tab) => {
    setActiveAsset(0);
    setIsCurrentCategory(tab === 'Current Investments');
  };

  const currentAsset = assets[isCurrentCategory ? 'currentAssets' : 'pastAssets'][activeAsset];

  const handlePrevClick = () => {
    if (activeAsset > 0) setActiveAsset(activeAsset - 1);
    else setActiveAsset(assets[isCurrentCategory ? 'currentAssets' : 'pastAssets'].length - 1);
  };

  const handleNextClick = () => {
    if (activeAsset === assets[isCurrentCategory ? 'currentAssets' : 'pastAssets'].length - 1) setActiveAsset(0);
    else setActiveAsset(activeAsset + 1);
  };

  return (
    <>
      <OverlayComponent
        isShowingPage={showOverlay}
        isCarousel
        lightbox
        background="transparent"
        onClose={() => toggleOverlay(false)}
        onNextClick={handleNextClick}
        onPrevClick={handlePrevClick}
      >
        <PortfolioSlide
          active={currentAsset.active}
          name={currentAsset.name}
          location={currentAsset.location}
          image={currentAsset.image.modalLarge || currentAsset.image.modal}
          investmentType={currentAsset.investmentType}
          propertyType={currentAsset.propertyType}
          amount={currentAsset.amount}
          dateOfInvestment={currentAsset.dateOfInvestment}
          highlights={currentAsset.highlights}
          whyWeLikeIt={currentAsset.whyWeLikeIt}
          displayRow={currentAsset.displayRow}
          dateCompleted={currentAsset.dateCompleted}
          totalValue={currentAsset.totalValue}
          investmentHighlights={currentAsset.investmentHighlights}
          issuer={currentAsset.issuer}
        />
      </OverlayComponent>
      <Styled.ReitPortfolio background={background}>
        <Styled.Container>
          <h1>{title}</h1>
          <Portfolio
            assets={assets}
            updateCurrentSlide={updateCurrentSlide}
            updateCategory={updateCategory}
            isNew
          />
          {
            cta &&
            <Styled.ButtonContainer>
              <RMButton href={cta.href} type={cta?.type || 'Primary'} target={cta.target || '_blank'}>{cta.label}</RMButton>
            </Styled.ButtonContainer>
          }
        </Styled.Container>
      </Styled.ReitPortfolio>
    </>
  );
};

ReitPortfolio.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string,
  cta: PropTypes.object,
  assets: PropTypes.object,
};

export default ReitPortfolio;
