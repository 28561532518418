import types from './types';

export default {
  investedPageRequested: (id) => ({
    type: types.INVESTED_PAGE_REQUESTED,
    id,
  }),
  investedPageSucceeded: (id, data) => ({
    type: types.INVESTED_PAGE_SUCCEEDED,
    id,
    data,
  }),
  investedPageFailed: (id, error) => ({
    type: types.INVESTED_PAGE_FAILED,
    id,
    error,
  }),
};
