import React from 'react';

import CheckboxLabel from '../../../../components/forms/CheckboxLabel';

const CheckboxAuthorizeDebit = (props) => {
  return (
    <CheckboxLabel
      label="I authorize RealtyMogul or its affiliates, and my bank to automatically debit payments and deposit distributions into my account listed above (this includes my authorization to correct entries made in error, to debit my account of any distributions deposited in error, to retain future distributions until the erroneous deposits are recovered). This authorization will remain in effect until I give written notice to cancel it, or until terminated by RealtyMogul or its affiliates."
      labelProps={{
        textTransform: 'none',
      }}
      my={50}
      inputProps={{
        required: true,
        'data-test': 'agreement',
      }}
      // eslint-disable-next-line
      {...props}
    />
  );
};

export default CheckboxAuthorizeDebit;
