import types from './types';

export default {
  getArticleRequested: (id) => ({
    type: types.GET_ARTICLE_REQUESTED,
    id,
  }),
  getArticleSucceeded: (data) => ({
    type: types.GET_ARTICLE_SUCCEEDED,
    data,
  }),
  getArticleFailed: (error) => ({
    type: types.GET_ARTICLE_FAILED,
    error,
  }),
};
