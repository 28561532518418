import React from 'react';
import PropTypes from 'prop-types';

import Label from '../../components/forms/Label';
import Input from '../../components/forms/Input';
import { Heading, Body } from '../../coreui';

const SourceOfIncome = ({
  value,
  handleChange,
}) => (
  <>
    <Heading
      type="H6"
      textTransform="uppercase"
    >
      Source of Income
    </Heading>
    <Body
      type="B2"
    >
      Please explain your current source of income.
    </Body>
    <Label
      htmlFor="sourceOfIncome"
    >
      Source of Income
      <Input
        id="sourceOfIncome"
        value={value}
        placeholder="Source of Income"
        name="sourceOfIncome"
        onChange={handleChange}
        data-test="income_source"
        maxLength="255"
        required
      />
    </Label>
  </>
);

SourceOfIncome.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.string,
};

export default SourceOfIncome;
