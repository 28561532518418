/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
  Link,
  Image,
  Data,
  StyledButton,
} from '../coreui';
import FadeIn from './FadeIn';
import BigNumber from './BigNumber';

const SlideWrapper = styled(ExtendedBox)`
  .slick-current & {
    position: relative;
    z-index: 1;
  }
`;

const CarouselContent = ({
  heading,
  subheading,
  body,
  bigNumber = undefined,
  cta,
  ctaProps,
  fadeIn,
  image,
  imageWrapperProps,
  imageWide,
  imageContainerProps,
  caption,
  disclaimer,
  handleOnClick,
  ...props
}) => {
  const Wrapper = fadeIn ? FadeIn : React.Fragment;
  const fadeInIndex = [
    heading,
    subheading,
    body,
    cta,
  ].filter((i) => i).length || 1;

  const imageComponent = (
    <Image
      src={image}
      width="100%"
      maxWidth="866px"
      IEAspectRatioFix
    />
  );

  return (
    <SlideWrapper
      {...props}
    >
      <ExtendedFlex
        flexDirection={['column', null, 'row']}
        mx={['10%', '20%', '10%']}
        mb={[0, null, 30, 40, 60]}
      >
        <ExtendedFlex
          flexDirection="column"
          width={imageWide ? [1, 1, 0.4] : [1, 1, 0.5]}
          boxSizing="border-box"
        >
          <Wrapper>
            {
              subheading &&
              <Heading
                type="H8"
                m={0}
                mb="3em"
                wordWrap="break"
              >
                {subheading}
              </Heading>
            }
            {
              heading &&
              <Heading
                type="H2"
                m={0}
                mb="1em"
              >
                {heading}
              </Heading>
            }
            {
              body &&
              <Body
                type="B1"
              >
                {body}
              </Body>
            }
            {
              (cta && typeof cta === 'string' &&
              <ExtendedFlex
                justifySelf="flex-end"
                flex={1}
                flexDirection="column"
                justifyContent="flex-end"
              >
                <ExtendedFlex
                  pt={40}
                >
                  <Link
                    type="L1"
                    {...ctaProps}
                  >
                    {cta}
                  </Link>
                </ExtendedFlex>
              </ExtendedFlex>
              ) || cta
            }
          </Wrapper>
        </ExtendedFlex>
        <ExtendedFlex
          w={imageWide ? [0, null, 20, 30, 40] : '10%'}
          display={['none', null, 'flex']}
        />
        <ExtendedFlex
          mt={['1em', null, 0]}
          width={imageWide ? [1, 1, 0.6] : [1, 1, 0.5]}
          flexDirection="column"
          align="center"
          {...imageContainerProps}
        >
          <Wrapper
            fadeInIndex={fadeInIndex}
          >
            {
              imageWrapperProps ? (
                <ExtendedFlex
                  {...imageWrapperProps}
                >
                  {imageComponent}
                </ExtendedFlex>
              ) : (
                imageComponent
              )
            }
            {
              bigNumber &&
              <BigNumber
                number={bigNumber.number}
                type={bigNumber.type}
              />
            }
            {
              caption &&
              <Data
                type="D4"
                mt="2em"
                letterSpacing={1.9}
                textAlign="center"
              >
                {caption}
              </Data>
            }
            {
              disclaimer &&
              <StyledButton
                type="L4"
                onClick={handleOnClick}
              >
                Disclaimer
              </StyledButton>
            }
          </Wrapper>
        </ExtendedFlex>
      </ExtendedFlex>
    </SlideWrapper>
  );
};

CarouselContent.propTypes = {
  heading: PropTypes.node,
  subheading: PropTypes.node,
  body: PropTypes.node,
  cta: PropTypes.node,
  ctaProps: PropTypes.object,
  fadeIn: PropTypes.bool,
  image: PropTypes.string.isRequired,
  imageWrapperProps: PropTypes.object,
  imageWide: PropTypes.bool,
  imageContainerProps: PropTypes.object,
  handleOnClick: PropTypes.func,
  caption: PropTypes.string,
  disclaimer: PropTypes.bool,
  bigNumber: PropTypes.object,
};

CarouselContent.defaultProps = {
  fadeIn: true,
  imageWide: false,
  handleOnClick: () => { },
};

export default CarouselContent;
