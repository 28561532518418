import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  isDrupal,
} from '../coreui';

class RedirectToLoginOnLoggedOut extends React.Component {
  constructor(props) {
    super(props);

    this.isRedirecting = false;
    this.doLoginRedirect = this.doLoginRedirect.bind(this);
  }

  componentDidMount() {
    this.doLoginRedirect();
  }

  componentDidUpdate() {
    this.doLoginRedirect();
  }

  doLoginRedirect() {
    if (this.props.isLoggedIn === false && !this.isRedirecting) {
      const pathname = window.location.pathname.substr(1);
      const query = window.location.search;
      const destination = encodeURIComponent(`${pathname}${query}`);
      const redirect = `/user/login?destination=${destination}`;
      if (isDrupal()) {
        window.location = redirect;
      } else {
        this.props.history.push(redirect);
      }
      this.isRedirecting = true;
    }
  }

  render() {
    return null;
  }
}

RedirectToLoginOnLoggedOut.propTypes = {
  isLoggedIn: PropTypes.bool,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.app.user.isLoggedIn,
});

export default withRouter(connect(mapStateToProps)(RedirectToLoginOnLoggedOut));
