/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  Heading,
  ExtendedFlex,
} from '../../coreui';

// eslint-disable-next-line
class Header extends React.Component {
  render() {
    const {
      title,
      subTitle,
      highlights,
      ...passedProps
    } = this.props;
    return (
      <ExtendedBox
        m="auto"
        maxWidth={1440}
        mt={[20, 20, 60]}
        {...passedProps}
      >
        <ExtendedFlex
          flexDirection={['column', 'column', 'column', 'row', 'row']}
          justifyContent={['center', 'center', 'center', 'space-between', 'space-between']}
        >
          <ExtendedBox
            my={0}
            ml={[0, 0, 40, 40, 0]}
            pb={[40, 40, 40, 0]}
            maxWidth={900}
            width={['100%', '100%', '100%', 900]}
            textAlign={['center', 'center', 'center', 'left', 'left']}
          >
            <Heading
              type="H2"
              display="block"
              m={0}
            >
              {title}
            </Heading>
            <Heading
              type="H8"
              display="block"
              m={0}
              px={[20, 40, 0, 0, 0]}
              mt={[20, 20, 20]}
            >
              {subTitle}
            </Heading>
          </ExtendedBox>
          <ExtendedFlex
            my={0}
            mx={40}
            maxWidth={500}
            justifyContent="center"
            width={['100%', '100%', '100%', 500]}
            flexDirection="row"
            alignSelf={['center', 'center', 'center', 'left']}
          >
            {highlights}
          </ExtendedFlex>
        </ExtendedFlex>
      </ExtendedBox>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  highlights: PropTypes.node,
};

export default Header;
