/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import pick from 'lodash/pick';

import {
  ExtendedFlex,
} from '../../coreui';
import Stepper from '../../components/forms/Stepper';
import Actions from '../../components/forms/Actions';
import PrimaryButton from '../../components/forms/PrimaryButton';
import Form from '../../components/forms/Form';
import ScrollToSpanOnMount from '../../components/ScrollToSpanOnMount';
import EnrollmentErrors from '../../dashboard/autoinvest/components/EnrollmentErrors';
import RiskTolerance from './RiskTolerance';
import HoldPeriod from './HoldPeriod';
import SecondaryButton from '../../components/forms/SecondaryButton';
import CurrentInvestments from './CurrentInvestments';
import Interests from './Interests';
import Need1031Exchange from './Need1031Exchange';
import NetWorth from './NetWorth';
import AnnualIncome from './AnnualIncome';
import { gtkyProps } from '../data';

class CompleteYourProfileQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      prevStep: undefined,
      total: 7,
      submitting: false,
      risk: [],
      riskIsValid: undefined,
      holdPeriod: [],
      holdPeriodIsValid: undefined,
      currentInvestments: [],
      currentInvestmentsIsValid: undefined,
      propertyTypes: [],
      propertyTypesIsValid: undefined,
      need1031: undefined,
      employmentType: 'employed',
      netWorth: '',
      currentIncome: '',
      incomeDetermination: 'individual',
      ...pick(props, gtkyProps),
    };

    if (typeof props.need1031 !== 'undefined') {
      this.state.need1031 = props.need1031 ? '1' : '0';
    }

    this.isUnmounted = false;

    this.checkIsValid = this.checkIsValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleNetWorth = this.handleNetWorth.bind(this);
    this.handleCurrentIncome = this.handleCurrentIncome.bind(this);
    this.handleIncomeDetermination = this.handleIncomeDetermination.bind(this);
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  handleChange(e) {
    const { name, value, type, checked } = e.target;
    let additionalStateChanges = {};

    if (name === 'employmentType') {
      // Reset the employer data when it's changing.
      additionalStateChanges = {
        employer: '',
        employmentPositionHeld: '',
        employmentIndustry: '',
        sourceOfIncome: '',
      };
    }

    const checkType = ['checkbox'].includes(type);
    this.setState((state) => {
      let startingSet = state[name];

      // Special handling for 'none' option.
      if (name === 'currentInvestments' && (
        value === 'invest_none' ||
        state.currentInvestments.includes('invest_none')
      )) {
        // Reset the set since it should always be a starting set of empty
        // under the above conditions.
        // Then, whether the target is now checked will be added below under
        // the normal handling.
        startingSet = [];
      }
      return {
        ...additionalStateChanges,
        [name]: (checkType && Array.isArray(state[name]))
          // Handle the case where we have a group of checkboxes.
          ? ((reducedSet) => (
            // Start with a reduced set that definitely excludes the value.
            // Then we just add it back in if the value is checked.
            checked ? [
              ...reducedSet,
              value,
            ] : reducedSet
          ))(startingSet.filter((val) => val !== value))
          // Otherwise, it's just 1:1 with name to value.
          : (() => (checkType ? checked : value))(),
      };
    }, this.checkIsValid);
  }

  checkIsValid(state = this.state) {
    const {
      step,
      risk,
      holdPeriod,
      currentInvestments,
      propertyTypes,
    } = state;

    let isValid;
    if (step === 3) {
      isValid = risk.length > 0;
      this.setState({
        riskIsValid: isValid,
      });
    } else if (step === 4) {
      isValid = holdPeriod.length > 0;
      this.setState({
        holdPeriodIsValid: isValid,
      });
    } else if (step === 5) {
      isValid = currentInvestments.length > 0;
      this.setState({
        currentInvestmentsIsValid: isValid,
      });
    } else if (step === 6) {
      isValid = propertyTypes.length > 0;
      this.setState({
        propertyTypesIsValid: isValid,
      });
    }

    return isValid;
  }

  handleFormSubmit(e) {
    e.preventDefault();

    const {
      step,
      total,
    } = this.state;

    const submission = {
      ...pick(this.state, gtkyProps),
    };

    if (step < total) {
      if (this.checkIsValid() === false) {
        return;
      }
      this.setState(((state) => ({
        step: state.step + 1,
        prevStep: state.step,
      })));

      // Only submit on the actual GTKY steps.
      if (step >= 2) {
        submission.draft = 1;

        this.props.handleSubmit(submission);
      }
    } else {
      // Submit GTKY first, then submit accreditation.
      // Otherwise, both can end up saving the user colliding with each other.
      this.props.handleSubmit(submission, {
        netWorth: this.state.netWorth,
        incomeDetermination: this.state.incomeDetermination,
        currentIncome: this.state.currentIncome,
      });
    }
  }

  handleNetWorth(e) {
    this.setState({
      netWorth: e.target.value,
    });
  }

  handleIncomeDetermination(e) {
    this.setState({
      incomeDetermination: e.target.value,
    });
  }

  handleCurrentIncome(e) {
    this.setState({
      currentIncome: e.target.value,
    });
  }

  render() {
    const {
      enrollmentErrors,
    } = this.props;
    const {
      step,
      prevStep,
      total,
      risk,
      riskIsValid,
      holdPeriod,
      holdPeriodIsValid,
      currentInvestments,
      currentInvestmentsIsValid,
      propertyTypes,
      propertyTypesIsValid,
      need1031,
      submitting,
      netWorth,
      incomeDetermination,
      currentIncome,
    } = this.state;

    return (
      <>
        {
          (step > 1 || prevStep) &&
          <ScrollToSpanOnMount key={step} offset={[-60, -110, null, -110]} />
        }
        <ExtendedFlex
          w={[1, null, 650]}
          flexDirection="column"
        >
          <Stepper
            step={step}
            total={total}
          />
          <Form onSubmit={(e) => this.handleFormSubmit(e)} >
            <ExtendedFlex
              flexDirection="column"
              w={1}
            >
              {
                // NOTE: if you reorder the steps, update the checkIsValid
                // method above.
              }
              {
                step === 1 && (
                  <NetWorth
                    value={netWorth}
                    handleChange={this.handleNetWorth}
                  />
                )
              }
              {
                step === 2 && (
                  <AnnualIncome
                    value={currentIncome}
                    handleChange={this.handleCurrentIncome}
                    handleIncomeDetermination={this.handleIncomeDetermination}
                    incomeDetermination={incomeDetermination}
                  />
                )
              }
              {step === 3 && (
                <RiskTolerance
                  value={risk}
                  handleChange={this.handleChange}
                  isValid={riskIsValid}
                />
              )}
              {step === 4 && (
                <HoldPeriod
                  value={holdPeriod}
                  handleChange={this.handleChange}
                  isValid={holdPeriodIsValid}
                />
              )}
              {step === 5 && (
                <CurrentInvestments
                  value={currentInvestments}
                  handleChange={this.handleChange}
                  isValid={currentInvestmentsIsValid}
                />
              )}
              {step === 6 && (
                <Interests
                  value={propertyTypes}
                  handleChange={this.handleChange}
                  isValid={propertyTypesIsValid}
                />
              )}
              {step === 7 && (
                <Need1031Exchange
                  value={need1031}
                  handleChange={this.handleChange}
                />
              )}
            </ExtendedFlex>
            {
              enrollmentErrors &&
              <EnrollmentErrors
                {...enrollmentErrors}
              />
            }
            <Actions>
              {
                step > 1 &&
                <SecondaryButton
                  onClick={() => {
                    this.setState((state) => ({
                      step: state.step - 1,
                      prevStep: state.step,
                    }));
                  }}
                  type="L3"
                >
                  Back
                </SecondaryButton>

              }
              <PrimaryButton
                loading={submitting}
                disable={submitting}
                buttonType="submit"
                data-test="continue-button"
              >
                Continue
              </PrimaryButton>

            </Actions>
          </Form>
        </ExtendedFlex>
      </>
    );
  }
}

CompleteYourProfileQuestions.propTypes = {
  handleSubmit: PropTypes.func,
  isAccredViaNetWorth: PropTypes.bool,
  need1031: PropTypes.bool,
  enrollmentErrors: PropTypes.object,
  handleAccred: PropTypes.func,
};

CompleteYourProfileQuestions.defaultProps = {
  handleSubmit: noop,
};

export default CompleteYourProfileQuestions;
