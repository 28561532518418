import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  withRouter,
  Redirect,
} from 'react-router-dom';

import {
  ExtendedFlex,
  Link,
  ExtendedBox,
  UnlockOpportunities,
  ScrollToTopOnMount,
  Content,
  Hero,
  Loader,
} from '../../coreui';

import actions from './actions';

import RelatedCategoryContent from '../components/RelatedCategoryContent';
import KnowledgeCenterSocial from '../components/KnowledgeCenterSocial';
import KnowledgeCenterPopularContent from '../components/popularContent';
import MetaTags from '../../metaTags';
import FourOhFour from '../../components/FourOhFour';
import FiveHundred from '../../components/FiveHundred';

import data from './data';

class Article extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== this.props.alias || (this.props.id === 0)) {
      this.props.getArticleRequested(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.getArticleRequested(this.props.match.params.id);
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      location,
      links,
      label,
      category,
      image,
      body,
      user,
      metaTags,
      status,
      id,
    } = this.props;

    switch (true) {
      case status >= 400 && status < 500:
        return (<FourOhFour
          linkPath="/knowledge-center"
          linkText="Go to the Knowledge Center"
          description="Sorry, we can’t find the article you’re looking for."
        />);
      case status >= 500:
        return (<FiveHundred />);
      case status >= 200 && status < 400:
        if (location.pathname !== links?.alias && links?.alias) {
          return (
            <Redirect to={links?.alias} />
          );
        }

        return (
          <ExtendedFlex
            w={1}
            flexDirection="column"
            align="center"
          >
            {
              metaTags === Object(metaTags) &&
              <MetaTags metaData={metaTags} />
            }
            <ScrollToTopOnMount />
            <Hero
              image={image._links.hero}
              header={label}
              subHeader={category.label}
              bgOpacity={0.2}
            />
            <ExtendedFlex
              maxWidth={1600}
              w={1}
              flexDirection={['column', 'column', 'column', 'row']}
            >
              <ExtendedFlex
                height="auto"
                w={[1, 1, 1, 0.7]}
                px="10%"
                py="5%"
                boxSizing="border-box"
                flexDirection="column"
              >
                <Content
                  w={1}
                  height="100%"
                  dangerouslySetInnerHTML={{ __html: body }}
                />
                {
                  !user.isLoggedIn &&
                  <ExtendedBox
                    w={1}
                    mt={20}
                  >
                    <Link
                      type="L1"
                      to="/user/register"
                    >
                      Join now to get started
                    </Link>
                  </ExtendedBox>
                }
                {
                  user.isLoggedIn &&
                  <ExtendedBox
                    w={1}
                    mt={20}
                  >
                    <Link
                      type="L1"
                      to="/investment-opportunities"
                    >
                      Invest Now
                    </Link>
                  </ExtendedBox>
                }
                <KnowledgeCenterSocial
                  metaTags={metaTags}
                />
              </ExtendedFlex>
              <ExtendedBox
                height={300}
                w={[1, 1, 1, 0.3]}
                py="5%"
                px={['10%', '10%', '10%', '5%']}
                boxSizing="border-box"
              >
                <KnowledgeCenterPopularContent data={data.popularArticles} />
              </ExtendedBox>
            </ExtendedFlex>
            <RelatedCategoryContent
              category={category.machine_name}
              exclude={id}
            />
            <UnlockOpportunities
              isLoggedIn={user.isLoggedIn}
            />
          </ExtendedFlex>
        );
      default:
        return (
          <ExtendedBox
            position="relative"
            maxHeight={674}
            height={['32vh', '50vh']}
          >
            <Loader />
          </ExtendedBox>
        );
    }
  }
}

const mapStateToProps = (state) => ({
  id: state.article.id,
  alias: state.article.alias,
  label: state.article.label,
  type: state.article.type,
  image: state.article.image,
  body: state.article.body,
  category: state.article.category,
  user: state.article.user,
  metaTags: state.article.metatags,
  status: state.article.status,
  links: state.article._links,
});

const mapDispatchToProps = (dispatch) => ({
  getArticleRequested: (id) => {
    dispatch(actions.getArticleRequested(id));
  },
});

Article.propTypes = {
  label: PropTypes.string,
  image: PropTypes.object,
  body: PropTypes.string,
  category: PropTypes.object,
  getArticleRequested: PropTypes.func,
  match: PropTypes.object,
  user: PropTypes.object,
  metaTags: PropTypes.object,
  status: PropTypes.string,
  id: PropTypes.string,
  alias: PropTypes.string,
  location: PropTypes.object,
  links: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Article));
