import axios from 'axios';
import { getIdToken } from '../auth/api';

// import dashboardResponse from './dashboardResponse';

export const DASHBOARD_DATA_URL = '/api/v1/dashboard';

export const AUTOINVEST_DATA_URL = '/api/v1.0/autoinvest';

const OWNERSHIP_URL = '/api/v1/dashboard/ownership';

const INVESTMENTS_URL = '/api/v1/dashboard/investments';
export const PENDING_INVESTMENTS_URL = '/api/v1.0/pending';

const NOTIFICATION_URL = '/api/v1.0/alerts';

export const TAXCENTER_URL = '/api/v1.0/node/tax_document';
const DASHBOARD_TRANSACTIONS_URL = '/api/v1/dashboard/transactions';

const MESSAGES_URL = '/api/v1.0/messages';
const MESSAGES_READ_URL = '/realty_mogul_core/read';

export const notificationApi = () => {
  return axios.get(NOTIFICATION_URL, {});
};

export const ownershipApi = () => {
  return axios.get(OWNERSHIP_URL, {});
};

export const getAutoInvestApi = () => {
  return getIdToken()
    .then((token) => (
      axios.get(AUTOINVEST_DATA_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ));
};

export const postAutoInvestApi = (data) => {
  return Promise.all([
    getIdToken(),
  ])
    .then(([token]) => (
      axios.post(AUTOINVEST_DATA_URL, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ));
};

export const patchAutoInvestApi = (id, data) => {
  return Promise.all([
    getIdToken(),
  ])
    .then(([token]) => (
      axios.patch(`${AUTOINVEST_DATA_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ));
};

export const deleteAutoInvestApi = (id) => {
  return getIdToken()
    .then((token) => (
      axios.delete(`${AUTOINVEST_DATA_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ));
};

export const dashboardDataApi = (foeId) => {
  const requestUrl = ((foeId) => {
    if (foeId) {
      return `${INVESTMENTS_URL}/${foeId}`;
    }
    return DASHBOARD_DATA_URL;
  })(foeId);
  return axios.get(requestUrl, {});
};

export const pendingDashboardDataApi = () => {
  return axios.get(PENDING_INVESTMENTS_URL, {});
};

export const taxcenterApi = (params) => {
  return axios.get(`${TAXCENTER_URL}`, {
    params: {
      fields: [
        'id',
        'label',
        'uuid',
        'status._options',
        'status._optionsText.awaiting',
        'status._optionsText.reviewing',
        'status._optionsText.preparing',
        'status._optionsText.uploaded',
        'status.value',
        'taxYear',
        'type,download',
        'investments.id',
        'investments.io.id',
        'investments.io.issuer',
        'investments.io.label',
        'investments.ownershipEntity.id',
        'investments.ownershipEntity.label',
        'investments.ownershipEntity.type.label',
      ].join(','),
      ...params,
    },
  });
};

export const taxcenterDocumentDownloadApi = (ids) => {
  window.open(`${TAXCENTER_URL}/${ids.join(',')}?download`, '_blank');
};

export const getMessagesApi = () => {
  return axios.get(`${MESSAGES_URL}`, {
    params: {
    },
  });
};

export const getMessagesReadApi = (id) => {
  return axios.get(`${MESSAGES_READ_URL}/${id}`);
};

export const transactionsApi = () => {
  return axios.get(DASHBOARD_TRANSACTIONS_URL, {});
};

export default {
  dashboardDataApi,
  ownershipApi,
  taxcenterApi,
  getMessagesApi,
  transactionsApi,
};
