import styled from 'styled-components';
import {
  space,
  width,
  height,
  alignSelf,
  justifySelf,
  top,
  right,
  bottom,
  left,
} from 'styled-system';

import {
  Button,
} from '..';

const hoverColorizeGray = 'filter: grayscale(30%)';
const desaturate = 'filter: grayscale(100%)';

// IE11 does not behave well with image aspect ratios that are in nested flexboxes.
// Below is a weird, hacky fix found after much searching.
// stackoverflow.com/questions/41489798/keep-image-ratio-using-max-width-and-max-height-in-ie-11
const IEAspectRatioFix = 'flex: 0 0 auto';

const Image = styled.img`
  ${space}
  ${width}
  ${height}
  ${alignSelf}
  ${justifySelf}
  display: ${(props) => props.display || 'block'};
  position: ${(props) => props.position || 'static'};
  ${(props) => (props.top ? top(props) : { top: 'auto' })};
  ${(props) => (props.right ? right(props) : { right: 'auto' })};
  ${(props) => (props.bottom ? bottom(props) : { bottom: 'auto' })};
  ${(props) => (props.left ? left(props) : { left: 'auto' })};
  transition: ${(props) => props.transition || 'all 0s 0s ease'};
  z-index: ${(props) => props.zIndex || 'auto'};
  transform: ${(props) => props.transform || 'none'};
  max-width: ${(props) => props.maxWidth || 'none'};
  opacity: ${(props) => props.opacity || '1'};
  visibility: ${(props) => props.visibility || 'visible'};
  max-height: ${(props) => props.maxHeight || 'none'};
  ${(props) => (props.hoverColorize ? hoverColorizeGray : '')};
  ${(props) => (props.desaturate ? desaturate : '')};
  ${(props) => (props.fill ? `fill: ${props.fill};` : '')}
  backface-visibility: ${(props) => props.backfaceVisibility || 'hidden'};

  &:hover {
    cursor: ${(props) => (props.hover ? 'pointer' : 'auto')};
  }

  ${Button}:hover & {
    opacity: ${(props) => (props.dimOnButtonParentHover ? '0.4' : '1')};
  }

  ${(props) => (props.IEAspectRatioFix ? IEAspectRatioFix : '')};
`;

export default Image;
