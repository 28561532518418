import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedFlex,
} from '../../coreui';

import HeaderUserMenuLoggedOut from './HeaderUserMenuLoggedOut';
import HeaderUserMenuLoggedIn from './HeaderUserMenuLoggedIn';
import HeaderMessages from './HeaderMessages';

class HeaderUserMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredItem: '',
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseEnter(e) {
    const hoveredItem = e.target.id;
    this.setState({
      hoveredItem,
    });
  }

  handleMouseLeave() {
    this.setState({
      hoveredItem: '',
    });
  }

  render() {
    const { hoveredItem } = this.state;
    const {
      user,
      browser,
      isLimitedNav,
      isRegFlow,
      handleLogout,
      isApp,
      frontendPrefix,
    } = this.props;

    return (
      <ExtendedFlex
        height={1}
        align="center"
        justify="flex-end"
        flex="1"
      >
        {
          user.isLoggedIn ?
            <ExtendedFlex
              align="center"
              justify={['flex-end', 'flex-end', 'flex-end', 'center']}
              w="auto"
              display={isLimitedNav ? 'none' : 'flex'}
              minWidth={[0, 0, 0, 200]}
            >
              <HeaderMessages
                messages={user.unreadMessagesCount}
              />
              <HeaderUserMenuLoggedIn
                user={user}
                browser={browser}
                handleMouseEnter={this.handleMouseEnter}
                handleMouseLeave={this.handleMouseLeave}
                hoveredItem={hoveredItem}
                handleLogout={(...args) => {
                  this.handleMouseLeave();
                  handleLogout(...args);
                }}
                isApp={isApp}
                frontendPrefix={frontendPrefix}
              />
            </ExtendedFlex> :
            <HeaderUserMenuLoggedOut
              handleMouseEnter={this.handleMouseEnter}
              handleMouseLeave={this.handleMouseLeave}
              hoveredItem={hoveredItem}
              isLimitedNav={isLimitedNav}
              isRegFlow={isRegFlow}
              isApp={isApp}
              frontendPrefix={frontendPrefix}
            />
        }
      </ExtendedFlex>
    );
  }
}

HeaderUserMenu.propTypes = {
  user: PropTypes.object,
  browser: PropTypes.string,
  isLimitedNav: PropTypes.bool,
  isRegFlow: PropTypes.bool,
  handleLogout: PropTypes.func,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

export default HeaderUserMenu;
