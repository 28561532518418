import React from 'react';
import PropTypes from 'prop-types';
import Styled from './index.styled';

const DEFAULT_STYLE = 'Primary';

const RMButton = ({
  href,
  children = '',
  type = '',
  styleType = DEFAULT_STYLE,
  width = '',
  target = '_self',
  onClick,
}) => {
  const isLink = typeof href !== 'undefined';
  const DynamicTag = isLink ? Styled.A : Styled.Button;

  const handleOnClick = (e) => {
    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <DynamicTag
      type={type}
      styleType={styleType}
      width={width}
      href={href}
      target={target}
      onClick={handleOnClick}
    >
      {children}
    </DynamicTag>
  );
};

RMButton.propTypes = {
  href: PropTypes.string,
  children: PropTypes.string,
  type: PropTypes.string,
  styleType: PropTypes.string,
  width: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
};

export default RMButton;
