import { fetchInvestmentMetricFromData } from './helper';

export function parseProgressBarData(jsonData) {
  return {
    title: 'Pending Investments',
    items: {
      totalPledged: fetchInvestmentMetricFromData(
        jsonData.pledgesInvestmentsData,
        'totalInvested',
        'Total Pledged',
        'currency',
        'large0',
        {},
      ),
      totalProcessing: fetchInvestmentMetricFromData(jsonData.pendingInvestmentsData, 'totalInvested', 'Total Processing', 'currency', 'large0', {}),
    },
  };
}

export default {
  parseProgressBarData,
};
