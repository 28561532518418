import styled from 'styled-components';
import {
  space,
} from 'styled-system';

const ChildrenWrapper = styled.div`
  ${space}
`;

export default ChildrenWrapper;
