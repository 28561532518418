import types from './types';
import authTypes from '../auth/types';

const initialState = {
  messages: [],
  // Temporarily disable the message per #1055.
  showStayConnectedMessage: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DSM_SUCCEEDED:
      return {
        ...state,
        messages: [
          ...state.messages,
          ...action.data.data,
        ],
      };

    case authTypes.USER_LOGIN_SUCCEEDED:
    case authTypes.USER_SESSION_SUCCEEDED:
      return {
        ...state,
        messages: [
          ...state.messages,
          ...(action.data.statusMessages || []),
        ],
      };

    case types.RESET_DSM_REQUESTED:
      return {
        ...state,
        messages: [],
        showStayConnectedMessage: false,
      };

    case types.HIDE_STAY_CONNECTED_MESSAGE:
      return {
        ...state,
        showStayConnectedMessage: false,
      };

    case types.ADD_STATUS_MESSAGE_SUCCEEDED: {
      return {
        ...state,
        messages: [
          ...state.messages,
          action.data,
        ],
      };
    }

    default:
      return {
        ...state,
      };
  }
};
