/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  ExtendedFlex,
  ExtendedBox,
  Hero,
  ModalWithOverlay,
  ScrollToTopOnMount,
  CarouselLarge,
  Body,
  Numeral,
} from '../coreui';

import ConstantStaticData from './data';
import DynamicBrowseJoinLink from '../helpers';
import heroBannerImage from './assets/img_hero_whyreits.jpg';
import ReitInvesting from './reitInvesting';
import ChartAndCopy from '../benefits/components/chartAndCopy';
import RelatedQueueContent from '../knowledgeCenter/components/RelatedQueueContent';
import ComparingReits from './comparingReits';
import InvestmentObjectives from './investmentObjectives';
import InvestToday from './components/InvestToday';
import IconCallouts from '../components/IconCallouts';
import Graph from '../assets/chart_growth.png';
import AvailableToday from '../benefits/components/availableToday';
import Section from '../ioDetails/components/section';

import CarouselContent from '../components/CarouselContent';
import BrowseLink from '../components/BrowseLink';

class WhyReits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      modalContent: null,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(modalContent) {
    this.setState((state) => ({
      isModalVisible: !state.isModalVisible,
      modalContent: state.isModalVisible ? null : modalContent,
    }));
  }

  render() {
    const {
      isModalVisible,
      modalContent,
    } = this.state;
    const {
      user,
      isLoggedIn,
      reitData,
      mogulReit1DistributionDuration,
      mogulReit1DistributionTooltip,
      mogulReit1LastDistributionDeclared,
      mogulReit2DistributionDuration,
      mogulReit2DistributionTooltip,
      mogulReit2LastDistributionDeclared,
      mogulReit1DistributionAmount,
      mogulReit2DistributionAmount,
      mogulReit1EffectiveDistributionDate,
      mogulReit2EffectiveDistributionDate,
    } = this.props;

    return (
      <ExtendedBox>
        <ScrollToTopOnMount />
        <ExtendedFlex>
          <Hero
            image={heroBannerImage}
            header="Access the full potential of commercial real estate"
            subHeader="Investing in real estate investment trusts (REITs)"
            bgPosition="74%"
          />
        </ExtendedFlex>
        <IconCallouts
          {...ConstantStaticData.basics}
          moreComponent={(
            <ExtendedFlex>
              <DynamicBrowseJoinLink
                isLoggedIn={isLoggedIn}
                anonText="Get Started"
                type="L1"
              />
            </ExtendedFlex>
          )}
          bg="gray.0"
        />
        <InvestmentObjectives />
        <ComparingReits
          toggleModal={this.toggleModal}
        />
        <ChartAndCopy
          toggleModal={this.toggleModal}
          {...ConstantStaticData.performanceHistory}
          chart={Graph}
          backgroundColor="white"
          isLoggedIn={user.isLoggedIn}
        />
        {
          mogulReit1LastDistributionDeclared && mogulReit2LastDistributionDeclared &&
          <AvailableToday
            heading={ConstantStaticData.reitData.heading}
            paragraphs={ConstantStaticData.reitData.paragraphs}
            investments={[reitData.mr1, reitData.mr2]}
            user={user}
          />
        }
        <Section>
          <CarouselLarge>
            <CarouselContent
              heading="The Income REIT"
              subheading="Our REIT distributions to date"
              body={
                (
                  <>
                    Since The Income REIT’s inception in 2016, investors have received {mogulReit1DistributionDuration} of consecutive distributions totaling <Numeral
                      value={mogulReit1DistributionAmount}
                      format="$0.0[0]a"
                    />.
                  </>
                )
              }
              cta={(
                <BrowseLink
                  isLoggedIn={isLoggedIn}
                />
              )}
              bigNumber={{ number: mogulReit1LastDistributionDeclared * 100, type: 'percentage' }}
              caption={`Annualized Distributions (Net of Fees) As Of ${(
                moment.unix(mogulReit1EffectiveDistributionDate).format('M/D/YY')
              )}`}
              disclaimer
              handleOnClick={() => {
                this.toggleModal((
                  <Body
                    type="B1"
                  >
                    {mogulReit1DistributionTooltip}
                  </Body>
                ));
              }}
            />
            <CarouselContent
              heading="The Apartment Growth REIT"
              subheading="Our REIT distributions to date"
              body={
                (
                  <>
                    Since The Apartment Growth REIT’s inception in 2017, investors have received {mogulReit2DistributionDuration} of consecutive distributions to investors totalling over <Numeral
                      value={mogulReit2DistributionAmount}
                      format="$0.0[0]a"
                    />.
                  </>
                )
              }
              cta={(
                <BrowseLink
                  isLoggedIn={isLoggedIn}
                />
              )}
              bigNumber={{ number: mogulReit2LastDistributionDeclared * 100, type: 'percentage' }}
              caption={`Annualized Distributions (Net of Fees) As Of ${(
                moment.unix(mogulReit2EffectiveDistributionDate).format('M/D/YY')
              )}`}
              disclaimer
              handleOnClick={() => {
                this.toggleModal((
                  <Body
                    type="B1"
                  >
                    {mogulReit2DistributionTooltip}
                  </Body>
                ));
              }}
            />
          </CarouselLarge>
        </Section>
        <ReitInvesting />
        <RelatedQueueContent
          title="Gain New Investing Insight"
          queue="why_reits_articles"
        />
        <InvestToday
          isLoggedIn={user.isLoggedIn}
          showPhone={false}
        />
        <ModalWithOverlay
          isVisible={isModalVisible}
          handleClick={this.toggleModal}
        >
          {modalContent}
        </ModalWithOverlay>
      </ExtendedBox>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.homepage.user,
  isLoggedIn: state.app.user.isLoggedIn,
  reitData: state.reitData.data,
  mogulReit1DistributionDuration: state.tokens.mogulReit1DistributionDuration,
  mogulReit1DistributionAmount: state.tokens.mogulReit1DistributionAmount,
  mogulReit1DistributionTooltip: state.tokens.mogulReit1DistributionTooltip,
  mogulReit1EffectiveDistributionDate: state.tokens.mogulReit1EffectiveDistributionDate,
  mogulReit2DistributionDuration: state.tokens.mogulReit2DistributionDuration,
  mogulReit2DistributionAmount: state.tokens.mogulReit2DistributionAmount,
  mogulReit2DistributionTooltip: state.tokens.mogulReit2DistributionTooltip,
  mogulReit2EffectiveDistributionDate: state.tokens.mogulReit2EffectiveDistributionDate,
  mogulReit1LastDistributionDeclared: state.tokens.mogulReit1LastDistributionDeclared,
  mogulReit2LastDistributionDeclared: state.tokens.mogulReit2LastDistributionDeclared,
});

WhyReits.propTypes = {
  user: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  reitData: PropTypes.object,
  mogulReit1DistributionDuration: PropTypes.string,
  mogulReit1DistributionAmount: PropTypes.number,
  mogulReit1DistributionTooltip: PropTypes.string,
  mogulReit1EffectiveDistributionDate: PropTypes.number,
  mogulReit2DistributionDuration: PropTypes.string,
  mogulReit2DistributionAmount: PropTypes.number,
  mogulReit2DistributionTooltip: PropTypes.string,
  mogulReit2EffectiveDistributionDate: PropTypes.number,
  mogulReit1LastDistributionDeclared: PropTypes.number,
  mogulReit2LastDistributionDeclared: PropTypes.number,
};

export default connect(mapStateToProps)(WhyReits);
