import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Heading } from '../../coreui';
import RadioGroup from '../forms/RadioGroup';
import RadioLabel from '../forms/RadioLabel';
import FollowUp from './FollowUp';

class Exit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dirty: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({
      dirty: true,
    });
    return this.props.handleChange(e);
  }

  render() {
    const {
      timeHorizon,
    } = this.props;
    return (
      <>
        <Heading
          type="H6"
          textTransform="uppercase"
        >
          When would you need to exit this investment?
        </Heading>
        <RadioGroup
          name="exit"
          value={this.props.value}
          onChange={this.handleChange}
          inputProps={{
            'data-test': 'exit',
            required: true,
          }}
        >
          <RadioLabel
            value="0"
            label="Less than 1 year"
          />
          <RadioLabel
            value="1"
            label="1-3 years"
          />
          <RadioLabel
            value="3"
            label="3-5 years"
          />
          <RadioLabel
            value="5"
            label="5-7 years"
          />
          <RadioLabel
            value="7"
            label="7-10 years"
          />
          <RadioLabel
            value="10"
            label="10+ Years"
          />
        </RadioGroup>
        {
          Number.parseInt(this.props.value, 10) < timeHorizon && this.state.dirty &&
          <FollowUp
            subheader="You selected an investment time horizon indicated above. The investment you have selected has an anticipated hold period of at least 5 years, and as this is an illiquid investment, you may not be able to sell or otherwise exit your investment for some time."
            name="exitFollowUp"
            value={this.props.followUp}
            handleChange={this.props.handleChange}
            options={[
              {
                value: 'comfortable',
                label: 'I am comfortable with the investment time horizon.',
              },
              {
                value: 'not_comfortable',
                label: 'I am not comfortable with having my money locked up for the investment time horizon.',
              },
            ]}
          />
        }
      </>
    );
  }
}

Exit.propTypes = {
  handleChange: PropTypes.func,
  timeHorizon: PropTypes.number.isRequired,
  value: PropTypes.oneOf([
    '0',
    '1',
    '3',
    '5',
    '7',
    '10',
  ]),
  followUp: PropTypes.string,
};

export default Exit;
