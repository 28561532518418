import React from 'react';
import PropTypes from 'prop-types';

import { Heading, Body } from '../../coreui';
import CheckboxLabel from '../../components/forms/CheckboxLabel';
import CheckboxGroup from '../../components/forms/CheckboxGroup';

const HoldPeriod = ({
  value,
  handleChange,
  isValid,
}) => {
  return (
    <>
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        Desired Hold Period
      </Heading>
      <Body
        type="B2"
      >
        Select the hold periods you are comfortable with.
      </Body>
      <CheckboxGroup
        name="holdPeriod"
        value={value}
        onChange={handleChange}
        inputProps={{
          'data-test': 'holding_period',
        }}
        required
        isValid={isValid}
      >
        <CheckboxLabel
          value="hold_0_2"
          label="Short-term (0-2 years)"
        />
        <CheckboxLabel
          value="hold_2_5"
          label="Medium-term (2-5 years)"
        />
        <CheckboxLabel
          value="hold_5_7"
          label="Longer-term (5-7 years)"
        />
        <CheckboxLabel
          value="hold_7_"
          label="More than 7 years"
        />
      </CheckboxGroup>
    </>
  );
};

HoldPeriod.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.oneOf([
    'hold_0_2',
    'hold_2_5',
    'hold_5_7',
    'hold_7_',
  ])),
  isValid: PropTypes.bool,
};

export default HoldPeriod;
