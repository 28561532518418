import React from 'react';
import PropTypes from 'prop-types';
import {
  ClickableLogo,
  Flex,
} from '../../coreui';

import FooterSiteMapList from './FooterSiteMapList';

const FooterSection1 = ({
  items,
  isApp,
  frontendPrefix,
}) => {
  return (
    <Flex
      w={[1, 1, 0.8]}
      flexDirection={['column']}
      align={['center', 'center', 'flex-start', 'flex-start', 'flex-start']}
    >
      <ClickableLogo
        logoColor="white"
        w={[1, 1, 'auto', 'auto']}
        isApp={isApp}
        frontendPrefix={frontendPrefix}
      />
      <Flex
        align={['stretch', 'stretch', 'stretch']}
        w={1}
        justify={['space-between', 'space-between', 'flex-start', 'flex-start']}
        mt={40}
        wrap={['wrap', 'nowrap', 'nowrap', 'nowrap', 'nowrap']}
      >
        {
          items.map(
            (item) => {
              return (
                <FooterSiteMapList
                  key={item.id}
                  data={item}
                />
              );
            },
          )
        }
      </Flex>
    </Flex>
  );
};

FooterSection1.propTypes = {
  items: PropTypes.array,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

export default FooterSection1;
