import React from 'react';
import {
  ExtendedFlex,
} from '../../coreui';

const style = {
  borderWidth: '0px',
};

const IframeComponent = () => {
  return (
    <ExtendedFlex
      maxWidth="1440px"
      w={1}
      align="center"
      height={[300, 300, 300, 471, 471]}
    >
      <iframe
        title="GoogleMaps"
        style={style}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.7502847434357!2d-118.4360334836137!3d34.05027618060628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bb9b58891fdf%3A0xbb5b78c59e95453b!2s10573+W+Pico+Blvd+Los+Angeles%2C+CA+90064!5e0!3m2!1sen!2sus!4v1449706750970"
        height="100%"
        width="1440px"
        className="fullheight"
      />
    </ExtendedFlex>
  );
};

export default IframeComponent;
