import React from 'react';
// import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReitTabs, Carousel, PortfolioCard } from 'rtmg-component-library';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3.66,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2.75,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const buildPortfolioTabs = (mr1Assets, mr2Assets, isCurrent) => {
  const incomeREIT = {
    title: 'INCOME REIT',
    content: (
      <Carousel settings={settings}>
        {mr1Assets[isCurrent ? 'currentAssets' : 'pastAssets'].map((item) => (
          <PortfolioCard {...item} isCurrent={isCurrent} />
        ))}
      </Carousel>
    ),
  };
  const apartmentREIT = {
    title: 'APARTMENT GROWTH REIT',
    content: (
      <Carousel settings={settings}>
        {mr2Assets[isCurrent ? 'currentAssets' : 'pastAssets'].map((item) => (
          <PortfolioCard {...item} isCurrent={isCurrent} />
        ))}
      </Carousel>
    ),
  };
  return [incomeREIT, apartmentREIT];
};

const Portfolio = ({
  assets = [],
  isCurrent = true,
}) => {
  return (
    <>
      <ReitTabs title={isCurrent ? 'Current Portfolio' : 'Past Investments'} background={isCurrent ? '#F7F8F8' : '#FFF'} tabs={buildPortfolioTabs(assets[0].assets, assets[1].assets, isCurrent)} />
    </>
  );
};

Portfolio.propTypes = {
  assets: PropTypes.array,
  isCurrent: PropTypes.bool,
};

export default Portfolio;
