/* eslint-disable react/jsx-indent */
import React from 'react';

import {
  Box,
  Flex,
  ExtendedFlex,
  Heading,
  Body,
  Image,
  VisibilitySensor,
  Theme,
} from '../coreui';

import imageSelectProcess from '../assets/img_panel_opportunity1.jpg';

class HowWeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible: true,
      });
    }
  }

  render() {
    const {
      isVisible,
    } = this.state;

    return (
      <Box
        py={[60, 80, 100, 120, 140]}
      >
        <Flex
          flexDirection={['column', 'column', 'row', 'row', 'row']}
          alignItems="center"
        >
          <Box
            width={[1, 1, 0.5, 0.5, 0.5]}
          >
            <Flex
              flexDirection="column"
              align={['center', 'center', 'flex-end', 'flex-end', 'flex-end']}
            >
              <ExtendedFlex
                flexDirection="column"
                align={['center', 'center', 'flex-start', 'flex-start', 'flex-start']}
                mr={[0, 0, 20, 40, 60]}
                maxWidth={['100%', '100%', 320, 400, 540]}
              >
                <Heading
                  type="H2"
                  display="block"
                  textAlign={['center', 'center', 'left', 'left', 'left']}
                  maxWidth={['75%', 540, '100%', '100%', '100%']}
                  m={0}
                  mb="0.5em"
                  opacity={isVisible ? '1' : '0'}
                  transition={`opacity ${Theme.fadeInDelay}ms 0s linear`}
                >
                  BUILD THE REAL ESTATE PORTFOLIO THAT’S RIGHT FOR YOU
                </Heading>
                <VisibilitySensor
                  callback={this.handleSensorChange}
                />
                <Body
                  type="B1"
                  display="block"
                  textAlign={['center', 'center', 'left', 'left', 'left']}
                  maxWidth={['75%', 540, '100%', '100%', '100%']}
                  opacity={isVisible ? '1' : '0'}
                  transition={`opacity ${Theme.fadeInDelay}ms 0.3s linear`}
                >
                Sort deals based on property type, investment timeline, projected return metrics and other criteria.
                </Body>
                <Body
                  type="B1"
                  display="block"
                  textAlign={['center', 'center', 'left', 'left', 'left']}
                  maxWidth={['75%', 540, '100%', '100%', '100%']}
                  opacity={isVisible ? '1' : '0'}
                  transition="opacity 0.5s 0.3s linear"
                >
                  Each deal includes detailed financials, plus you can attend a live webinar with the real estate company to get your questions answered, all designed to empower you to make informed decisions in pursuit of your financial goals.
                </Body>
              </ExtendedFlex>
            </Flex>
          </Box>
          <Box
            width={[1, 1, 0.5, 0.5, 0.5]}
          >
            <Flex
              flexDirection="column"
              align={['center', 'center', 'flex-start', 'flex-start', 'flex-start']}
              ml={[0, 0, 20, 40, 60]}
            >
              <Image
                src={imageSelectProcess}
                maxWidth="600px"
                IEAspectRatioFix
                opacity={isVisible ? '1' : '0'}
                transition={`opacity ${Theme.fadeInDelay}ms 0.6s linear`}
              />
            </Flex>
          </Box>
        </Flex>
        <VisibilitySensor
          callback={this.handleSensorChange}
        />
      </Box>
    );
  }
}

export default HowWeSelect;
