import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  StyledButton,
  Body,
} from '../../../coreui';

import SmartButton from '../../../components/SmartButton';

class InvestToday extends React.Component {
  // eslint-disable-next-line
  showZendesk() {
    if (typeof zE !== 'undefined') {
      // eslint-disable-next-line
      zE.activate();
    }
  }

  render() {
    const {
      smartButton,
    } = this.props;

    if (smartButton.button_text && smartButton.button_href) {
      return (
        <ExtendedFlex
          w={1}
          align="center"
          justify="center"
          flexDirection="column"
        >
          <Heading
            type="H2"
            color="black"
            m={0}
          >
            Invest Today
          </Heading>
          <ExtendedBox>
            <SmartButton
              type="L1"
              label={smartButton.button_text}
              buttonHref={smartButton.button_href}
            />
          </ExtendedBox>
          <Body
            type="B1"
            m={0}
            pt={20}
          >
            Questions?
          </Body>
          <Heading
            m={0}
            type="H6"
          >
            (877) 781-7062
          </Heading>
          <StyledButton
            type="L4"
            mt={20}
            display="block"
            onClick={this.showZendesk}
          >
            Contact Investor Relations
          </StyledButton>
        </ExtendedFlex>
      );
    }
    return null;
  }
}

InvestToday.propTypes = {
  smartButton: PropTypes.object,
};

InvestToday.defaultProps = {
  smartButton: {
    label: 'SmartButton',
    buttonHref: '/',
  },
};

export default InvestToday;
