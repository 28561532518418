import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  Box,
  ExtendedFlex,
  Heading,
  Body,
  VisibilitySensor,
} from '../../../../coreui';

import backgroundImage from '../../../../assets/retire_distinct_strategies.jpg';
import FadeIn from '../../../../components/FadeIn';
import BrowseLink from '../../../../components/BrowseLink';

const StrategySection1 = ({
  handleSensorChange,
  isVisible,
  isLoggedIn,
}) => {
  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
    >
      <ExtendedBox
        w={1}
        bgRepeat="no-repeat"
        bgImage={backgroundImage}
        bgPosition="50% 26%"
        bgSize="cover"
      >
        <Box
          w={1}
          px={['15%', '25%', '10%', '10%', '10%']}
        >
          <ExtendedFlex
            flexDirection="column"
            align={['center', 'center', 'flex-end', 'flex-end', 'flex-end']}
          >
            <ExtendedFlex
              maxWidth={['100%', '100%', 400, 400, 540]}
              flexDirection="column"
              my={[60, 60, 100, 100, 100]}
            >
              <FadeIn>
                <Heading
                  type="H2"
                  color="white"
                  m={0}
                  mb="1em"
                  opacity={isVisible ? '1' : '0'}
                >
                  STRATEGIC APPROACHES TO RETIREMENT
                </Heading>
              </FadeIn>
              <VisibilitySensor
                callback={handleSensorChange}
              />
              <FadeIn
                fadeInIndex={2}
              >
                <Body
                  type="B1"
                  color="white"
                  fontWeight="light"
                  mb={[200, 200, 360, 360, 440]}
                  opacity={isVisible ? '1' : '0'}
                >
                  Many investors would agree that successful retirement planning requires a holistic strategy – one that seeks both income and appreciation. To accommodate this approach, each of the following REITs offer a different objective.
                </Body>
                <ExtendedBox
                  opacity={isVisible ? '1' : '0'}
                >
                  <BrowseLink
                    isLoggedIn={isLoggedIn}
                    anonText="Get Started"
                  />
                </ExtendedBox>
              </FadeIn>
            </ExtendedFlex>
          </ExtendedFlex>
        </Box>
      </ExtendedBox>
    </Box>
  );
};

StrategySection1.propTypes = {
  handleSensorChange: PropTypes.func,
  isVisible: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
};

export default StrategySection1;
