import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import numeral from 'numeral';

import {
  ExtendedFlex,
  formatters,
} from '../../coreui';

import {
  sliderMapping,
} from '../../components/isq/utility';
import NetWorthSpecificInput from './NetWorthSpecificInput';

const InvestFlowSpecificNetWorthWithPortal = (props) => {
  const sliderMappings = props?.sliderMapping || {};

  const [incomeValue, setIncomeValue] = useState(null);
  const [netWorthSpecific, setNetWorthSpecific] = useState(null);
  const [netWorthSpecificValue, setNetWorthSpecificValue] = useState(null);
  const [isEnabledSubmit, setIsEnabledSubmit] = useState(false);

  const jQueryRef = useRef(get(window, 'jQuery', noop));
  const $ = jQueryRef.current;

  const investorIncomeRef = useRef();
  const investorNetWorthRef = useRef();
  const netWorthSpecificRef = useRef();

  // Get the specific net worth ref from the form.
  useEffect(() => {
    if (typeof netWorthSpecificRef.current === 'undefined') {
      const netWorthElement = $(':input[name="isq[field_specific_net_worth]"]');
      if (netWorthElement?.length === 1) {
        netWorthSpecificRef.current = netWorthElement;
        const netWorthSpecific = netWorthElement.val();

        // Set the initial value passed from the form.
        if (netWorthSpecific) {
          const netWorthValue = numeral(netWorthSpecific);
          const value = netWorthValue.value();
          const formatted = netWorthValue.format(formatters.DOLLARS);
          setNetWorthSpecific(
            value >= 0 ? formatted : '',
          );
          setNetWorthSpecificValue(
            value >= 0 ? value : 0,
          );
        }
      }
    }
  }, [$, netWorthSpecificRef]);

  // Get the reference to the income select list.
  useEffect(() => {
    if (typeof investorIncomeRef.current === 'undefined') {
      investorIncomeRef.current = $(':input[name="isq[field_investor_annual_income]"]');

      if (investorIncomeRef.current?.length === 1) {
        const investorIncome = investorIncomeRef.current;

        // Keep track of when the value changes so that we can mark the form as
        // valid or invalid.
        investorIncome.bind('change', () => {
          setIncomeValue(investorIncome.val());
        });
      }
    }
  }, [$, investorIncomeRef, setIncomeValue]);

  // Get the reference to the net worth select list.
  useEffect(() => {
    if (typeof investorNetWorthRef.current === 'undefined') {
      investorNetWorthRef.current = $(':input[name="isq[field_investor_net_worth]"]');

      if (investorNetWorthRef.current?.length === 1) {
        const netWorthElement = investorNetWorthRef.current;
        netWorthElement.closest('.form-item-isq-field-investor-net-worth').hide();
      }
    }
  }, [$, investorNetWorthRef]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce((value) => {
      const parsedNetWorth = parseInt(value, 10);
      const parsedNetWorthIsNumber = !Number.isNaN(parsedNetWorth);

      // Find the corresponding investor net worth select list item.
      let valueSet = false;
      if (parsedNetWorthIsNumber && parsedNetWorth >= 0) {
        Object.keys(sliderMappings).forEach((key) => {
          // Get the mapping from the key.
          const {
            min,
            max,
          } = sliderMappings[key];

          // If our value is in between the min and max, we're done.
          if (parsedNetWorth >= min && parsedNetWorth < max) {
            valueSet = true;
            investorNetWorthRef.current.val(key)
              .trigger('change');
          }
        });
      }

      // If we didn't find a value, set it to the largest key.
      if (!valueSet) {
        const selectList = investorNetWorthRef.current;
        if (parsedNetWorthIsNumber && parsedNetWorth >= 0) {
          const largestKey = selectList.find('option').get().reduce((accum, option) => {
            const accumInt = parseInt(accum, 10);
            const optionValue = option.value;
            if (Number.isNaN(accumInt)) {
              return optionValue;
            }
            const optionValueInt = parseInt(optionValue, 10);
            if (optionValueInt > accumInt) {
              return optionValue;
            }
            return accum;
          }, '');

          if (largestKey) {
            selectList.val(largestKey)
              .trigger('change');
          }
        } else {
          selectList.val('')
            .trigger('change');
        }
      }

      $('.investflow__question-group').trigger('state:visible');
    }, 1000),
    [$, investorNetWorthRef, sliderMappings],
  );

  // Keep track to disable the submit button.
  useEffect(() => {
    setIsEnabledSubmit(incomeValue !== '' && netWorthSpecificValue >= 0);
  }, [incomeValue, netWorthSpecificValue, setIsEnabledSubmit]);

  useEffect(() => {
    if (isEnabledSubmit) {
      $('.investflow__submit').removeClass('disabled');
    } else {
      $('.investflow__submit').addClass('disabled');
    }
  }, [$, isEnabledSubmit]);

  const onChange = useCallback((e) => {
    const netWorthSpecific = numeral(e.target.value);
    let value = netWorthSpecific.value();
    let formatted = netWorthSpecific.format(formatters.DOLLARS);
    if (value < 0 || Object.is(value, -0)) {
      value *= -1;
      formatted = numeral(value).format(formatters.DOLLARS);
    }
    if (value === null) {
      formatted = '$';
    }

    setNetWorthSpecific(formatted);
    netWorthSpecificRef.current.val(value);
    setNetWorthSpecificValue(value);
    debounceCallback(`${value}`);
  }, [debounceCallback, netWorthSpecificRef, setNetWorthSpecificValue]);

  const onBlur = useCallback(() => {
    debounceCallback.flush();
  }, [debounceCallback]);

  return (
    <ExtendedFlex
      flexDirection="column"
    >
      <NetWorthSpecificInput
        value={netWorthSpecific}
        onChange={onChange}
        onBlur={onBlur}
      />
    </ExtendedFlex>
  );
};

InvestFlowSpecificNetWorthWithPortal.propTypes = {
  sliderMapping: PropTypes.object,
};

InvestFlowSpecificNetWorthWithPortal.defaultProps = {
  sliderMapping,
};

export default InvestFlowSpecificNetWorthWithPortal;
