import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedBox,
  Flex,
  ExtendedFlex,
  NavLink,
  Image,
} from '../../coreui';

import {
  reverseHeaderMenuChildrenTransitionDelay,
} from '../helpers';

import HeaderMenuChildrenContainer from './HeaderMenuChildrenContainer';

import carat from '../../assets/Triangle.svg';

class HeaderMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstLevelHoveredItem: '',
      items: [],
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleSecondLevelNavMouseLeave = this.handleSecondLevelNavMouseLeave.bind(this);
    this.handleFirstLevelNavMouseLeave = this.handleFirstLevelNavMouseLeave.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.items !== nextProps.items) {
      this.setState({
        items: nextProps.items,
      });
    }
  }

  // FIXME: Refactor later, lots of copy paste going on here
  handleMouseEnter(e) {
    const mainHoveredItem = e.target.id;
    const mainHoveredItemParent = e.target.getAttribute('data-parent');

    this.setState((state) => {
      const items = reverseHeaderMenuChildrenTransitionDelay(
        state.items,
        false,
      );

      return {
        firstLevelHoveredItem: mainHoveredItem,
        items,
      };
    });

    if (mainHoveredItemParent) {
      this.setState({
        firstLevelHoveredItem: mainHoveredItemParent,
      });
    }
  }

  handleSecondLevelNavMouseLeave() {
    this.setState((state) => {
      const items = reverseHeaderMenuChildrenTransitionDelay(
        state.items,
        true,
      );
      return {
        items,
      };
    });

    this.setState({
      firstLevelHoveredItem: '',
    });
  }

  handleFirstLevelNavMouseLeave() {
    this.setState((state) => {
      const items = reverseHeaderMenuChildrenTransitionDelay(
        state.items,
        true,
      );
      return {
        items,
        firstLevelHoveredItem: '',
      };
    });
  }

  render() {
    const {
      items,
      firstLevelHoveredItem,
    } = this.state;

    const {
      browser,
      isLimitedNav,
    } = this.props;

    return (
      <ExtendedFlex
        align="center"
        justify="center"
        display={['none', 'none', 'none', `${!isLimitedNav ? 'flex' : 'none'}`]}
        flex={[1, 1, 1, 3]}
      >
        {
          items.map(
            (item) => {
              return (
                <ExtendedFlex
                  key={item.id}
                  flexDirection="column"
                  align="center"
                  position="relative"
                  pr={5}
                  pl={10}
                  ml={10}
                  mr={15}
                  justify="center"
                >
                  <NavLink
                    id={item.machineName}
                    to={item.url}
                    drupal={item.drupal}
                    type="L5"
                    height={[70, 70, 80, 90]}
                    display="block"
                    onMouseEnter={this.handleMouseEnter}
                    withActiveStyle={false}
                  >
                    <ExtendedFlex
                      id={item.machineName}
                      height="inherit"
                      align="center"
                      onMouseLeave={this.handleFirstLevelNavMouseLeave}
                      hover
                    >
                      { item.label }
                      {
                        item.children.length > 0 &&
                        <Image
                          src={carat}
                          ml={2}
                          id={item.machineName}
                        />
                      }
                    </ExtendedFlex>
                  </NavLink>

                  {/* Fading in Active Nav Underline */}
                  <ExtendedBox
                    position="absolute"
                    height={1}
                    bg="black"
                    w={1}
                    bottom={25}
                    left={0}
                    opacity={
                      item.machineName === firstLevelHoveredItem ?
                        '1' :
                        '0'
                    }
                    transition="opacity 0.5s ease-in-out"
                  />

                  {/* Slide in / out container */}
                  {
                    item.children.length > 0 &&
                    <HeaderMenuChildrenContainer
                      handleMouseLeave={this.handleFirstLevelNavMouseLeave}
                      handleMouseEnter={this.handleMouseEnter}
                      firstLevelHoveredItem={firstLevelHoveredItem}
                      machineName={item.machineName}
                      browser={browser}
                    >
                      <Flex
                        flexDirection="column"
                        align="center"
                        justify="flex-start"
                      >
                        {
                          item.children.map(
                            (child) => {
                              return (
                                <NavLink
                                  key={child.id}
                                  type="L5"
                                  opacity="1"
                                  w={1}
                                  id={child.machineName}
                                  p={20}
                                  data-parent={item.machineName}
                                  onMouseEnter={this.handleMouseEnter}
                                  onMouseLeave={this.handleSecondLevelNavMouseLeave}
                                  bg="white"
                                  hoverBG="gray.0"
                                  to={child.url}
                                  drupal={child.drupal}
                                  display="block"
                                  whiteSpace="nowrap"
                                  textAlign="center"
                                  withActiveStyle={false}
                                >
                                  { child.label }
                                </NavLink>
                              );
                            },
                          )
                        }
                      </Flex>
                    </HeaderMenuChildrenContainer>
                  }
                </ExtendedFlex>
              );
            },
          )
        }
      </ExtendedFlex>
    );
  }
}

HeaderMenu.propTypes = {
  items: PropTypes.array,
  isLimitedNav: PropTypes.bool,
  browser: PropTypes.string,
};

export default HeaderMenu;
