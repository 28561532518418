import data from './data';
import homepageTypes from '../homepage/types';

const initialState = {
  ...data,
  totalPropertyValueFinanced: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case homepageTypes.GET_TOKENS_SUCCEEDED:
      return {
        ...state,
        totalPropertyValueFinanced: action.tokens.totalPropertyValueFinanced,
      };

    default:
      return {
        ...state,
      };
  }
};
