/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import {
  Data,
  ExtendedBox,
  ExtendedFlex,
} from '../../../coreui';

// eslint-disable-next-line
class RMCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.updateCurrentSlide = this.updateCurrentSlide.bind(this);
  }

  next() {
    this.setState((state) => ({
      currentSlide: state.currentSlide + 1,
    }));
  }

  prev() {
    this.setState((state) => ({
      currentSlide: state.currentSlide - 1,
    }));
  }

  updateCurrentSlide(index) {
    const { currentSlide } = this.state;
    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
  }

  render() {
    const {
      gallery,
      title,
    } = this.props;
    const leftStyle = {
      // eslint-disable-next-line
      background: 'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTlweCIgaGVpZ2h0PSIzNnB4IiB2aWV3Qm94PSIwIDAgMTkgMzYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUxLjIgKDU3NTE5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5kZXNrdG9wL2ljb24vZnVuY3Rpb25hbC9hcnJvd3MvbmV4dC9tZWQvYmxrIGNvcHk8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iSU9fRGV0YWlsc19QUCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IklPX0RldGFpbHNfVjNhIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjEwLjAwMDAwMCwgLTEwMDguMDAwMDAwKSIgZmlsbD0iIzIwMjAyMCI+CiAgICAgICAgICAgIDxnIGlkPSJUb3BfU2VjdGlvbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDEyNi4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJsYW5kaW5nLXBpYyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjUxLjAwMDAwMCwgMzY0LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxnIGlkPSJHcm91cC05IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNTkuMDAwMDAwLCA1MTYuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgIDxnIGlkPSJkZXNrdG9wL2ljb24vZnVuY3Rpb25hbC9hcnJvd3MvbmV4dC9tZWQvYmxrIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMC41ODkwNDEsIDIwLjAwMDAwMCkgc2NhbGUoLTEsIDEpIHRyYW5zbGF0ZSgtMTAuNTg5MDQxLCAtMjAuMDAwMDAwKSB0cmFuc2xhdGUoMC4xNzgwODIsIDAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iUGFnZS0xIiBwb2ludHM9IjMuMTQyNDQ3NDMgMi4xOTE3ODA4MiAyLjIxMDUyNjMyIDMuMDkyMTE5MDUgMTkuMTgyNjE5MyAxOS41MTM3NTQxIDE5LjE4MjYxOTMgMTkuNzI2MDI3NCAyLjIxMDUyNjMyIDM2LjM1OTkzNTcgMy4xNDI0NDc0MyAzNy4yNjAyNzQgMjEgMTkuNzI2MDI3NCI+PC9wb2x5Z29uPgogICAgICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=")',
      backgroundRepeat: 'no-repeat',
      height: '36px',
      width: '19px',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
    };
    const rightStyle = {
      // eslint-disable-next-line
      background: 'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTlweCIgaGVpZ2h0PSIzNnB4IiB2aWV3Qm94PSIwIDAgMTkgMzYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUxLjIgKDU3NTE5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5kZXNrdG9wL2ljb24vZnVuY3Rpb25hbC9hcnJvd3MvbmV4dC9tZWQvYmxrPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IklPX0RldGFpbHNfUFAiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJJT19EZXRhaWxzX1YzYSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc3My4wMDAwMDAsIC0xMDA4LjAwMDAwMCkiIGZpbGw9IiMyMDIwMjAiPgogICAgICAgICAgICA8ZyBpZD0iVG9wX1NlY3Rpb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAxMjYuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0ibGFuZGluZy1waWMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI1MS4wMDAwMDAsIDM2NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iR3JvdXAtOSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzU5LjAwMDAwMCwgNTE2LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iZGVza3RvcC9pY29uL2Z1bmN0aW9uYWwvYXJyb3dzL25leHQvbWVkL2JsayIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTYxLjAwMDAwMCwgMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYWdlLTEiIHBvaW50cz0iMy4xNDI0NDc0MyAyLjE5MTc4MDgyIDIuMjEwNTI2MzIgMy4wOTIxMTkwNSAxOS4xODI2MTkzIDE5LjUxMzc1NDEgMTkuMTgyNjE5MyAxOS43MjYwMjc0IDIuMjEwNTI2MzIgMzYuMzU5OTM1NyAzLjE0MjQ0NzQzIDM3LjI2MDI3NCAyMSAxOS43MjYwMjc0Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==")',
      backgroundRepeat: 'no-repeat',
      height: '36px',
      width: '19px',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
    };
    let currentSlide = this.state.currentSlide + 1;
    const maxSlides = gallery.length;
    if (maxSlides === 1) {
      currentSlide = 1;
    }

    return (
      <ExtendedBox>
        {
          title &&
          <ExtendedBox
            position="relative"
            top={50}
            zIndex={1}
            mx={[0, 0, 40, 40, 0]}
          >
            <Data
              type="D4"
              color="white"
              lineHeight="30px"
              bg="black"
              display="inline-block"
              px={30}
              m={0}
            >
              {title}
            </Data>
          </ExtendedBox>
        }
        <ExtendedBox
          mx={[0, 0, 40, 40, 0]}
        >
          <Carousel
            selectedItem={this.state.currentSlide}
            onChange={this.updateCurrentSlide}
            showArrows={false}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            infiniteLoop
          >
            {
              gallery &&
              gallery.map((item, index) => {
                return (
                  <ExtendedBox
                    // eslint-disable-next-line
                    key={`gallery_item_${index}`}
                  >
                    <img src={item.carousel} alt="" />
                  </ExtendedBox>
                );
              })
            }
          </Carousel>
          <ExtendedFlex
            justifyContent="center"
            alignItems="flex-start"
            my={30}
            flexDirection="row"
          >
            <button
              onClick={this.prev}
              style={leftStyle}
              aria-label="Previous"
            />
            <Data
              type="D2"
              display="block"
              lineHeight="36px"
              m={0}
              width="120px"
              textAlign="center"
            >
              Image {currentSlide}/{maxSlides}
            </Data>
            <button
              onClick={this.next}
              style={rightStyle}
              aria-label="Next"
            />
          </ExtendedFlex>
        </ExtendedBox>
      </ExtendedBox>
    );
  }
}

RMCarousel.propTypes = {
  gallery: PropTypes.array,
  title: PropTypes.string,
};

RMCarousel.defaultProps = {
  gallery: [],
};

export default RMCarousel;
