/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Body,
  ExtendedFlex,
  TooltipLink,
  Tooltip,
  TooltipIcon,
} from '../../coreui';

import ProgressIndicator from './ProgressIndicator';

export const StatusBars = (props) => {
  const {
    id,
    status,
    statuses,
    statusTooltip,
    progressProps,
    ...additionalProps
  } = props;

  const label = statuses?.reduce((accum, item) => {
    return item.status === status ? item.message : accum;
  }, props.label);
  const maxStages = props.maxStages || statuses.length;
  const Wrapper = statusTooltip ? TooltipLink : React.Fragment;
  return (
    <ExtendedFlex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      m={0}
      height="30px"
      {...additionalProps}
    >
      <Wrapper
        {...(statusTooltip) ? {
          tooltipId: `statusTooltip:${id}`,
          component: ExtendedFlex,
          w: 1,
          hover: true,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        } : {}}
      >
        <ExtendedFlex
          flexDirection="row"
          align="center"
          justify="center"
          hover={!!statusTooltip}
        >
          <Body
            type="B5"
            m={0}
            whiteSpace="nowrap"
          >
            {label}
          </Body>
          {
            statusTooltip &&
            <TooltipIcon
              ml={3}
            />
          }
        </ExtendedFlex>
        <ProgressIndicator
          stage={status}
          maxStages={maxStages}
          hover={!!statusTooltip}
          {...progressProps}
        />
      </Wrapper>
      {
        statusTooltip &&
        <Tooltip
          id={`statusTooltip:${id}`}
        >
          <Body type="B5">
            {statusTooltip}
          </Body>
        </Tooltip>
      }
    </ExtendedFlex>
  );
};

StatusBars.propTypes = {
  id: PropTypes.string,
  label: PropTypes.node,
  status: PropTypes.number,
  maxStages: PropTypes.number,
  statusTooltip: PropTypes.node,
  statuses: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.number,
    message: PropTypes.node,
  })),
  progressProps: PropTypes.object,
};

export default StatusBars;
