import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import {
  Hero,
  UnlockOpportunities,
  ScrollToTopOnMount,
} from '../coreui';

import BuildWealth from './components/buildWealth';
import Funds from './components/funds';
import Section from '../ioDetails/components/section';
import Graphic3ColCallout from '../components/Graphic3ColCallout';

import Investing from './components/investing';
import Strategy from './components/strategy';
import SimpleSteps from '../howItWorks/simpleSteps';

import heroBannerImage from '../assets/retire_hero.jpg';
import chartImg from '../assets/chart_investing_in_reits.png';
import data from './data';
import slideCollection from './slideCollection';
import actions from './actions';
import QueueFAQItems from '../components/queueFAQItems';
import './css/slider.css';
import RelatedQueueContent from '../knowledgeCenter/components/RelatedQueueContent';
import BrowseLink from '../components/BrowseLink';
import parseMogulREITLinks from '../utils/parseMogulREITLinks';

class Retirement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      user,
    } = this.props;

    const isLoggedIn = user.isLoggedIn;

    const parsedSlides = slideCollection.map(
      (item) => {
        return {
          slideStepTitle: item.slideStepTitle,
          mainTitle: item.mainTitle,
          bodyContent: parseMogulREITLinks(item.bodyContent),
          image: item.image,
        };
      },
    );

    return (
      <>
        <ScrollToTopOnMount />
        <Hero
          image={heroBannerImage}
          header={data.hero.header}
          subHeader={data.hero.subheader}
        />
        <Section
          title={data.buildWealth.title}
          bg="gray.0"
        >
          <BuildWealth />
          <BrowseLink
            isLoggedIn={isLoggedIn}
            anonText="Get Started"
          />
        </Section>
        <Funds />
        <Section
          bg="gray.0"
        >
          <Graphic3ColCallout
            title={data.overview.title}
            description={ReactHtmlParser(data.overview.subtitle)}
            items={data.tools}
          />
          <BrowseLink
            isLoggedIn={isLoggedIn}
            anonText="Get Started"
          />
        </Section>
        <Investing
          disclosure={data.disclosure}
          chartImg={chartImg}
          isLoggedIn={isLoggedIn}
        />
        <Strategy
          isLoggedIn={isLoggedIn}
        />
        <Section
          title={data.getStarted.title}
        >
          <SimpleSteps
            isLoggedIn={isLoggedIn}
            hideTitle
            anonText="Get Started"
            slideSet={parsedSlides}
          />
        </Section>
        <QueueFAQItems
          queueName="retirement_questions"
          title="Frequently Asked Questions About Retirement Investing"
        />
        <RelatedQueueContent
          title="Gain New Investing Insight"
          queue="retirement_articles"
        />
        <UnlockOpportunities
          isLoggedIn={isLoggedIn}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFAQDataRequested: (opts) => {
    dispatch(actions.getFAQDataRequested(opts));
  },
});

const mapStateToProps = (state) => ({
  user: state.retirement.user,
});

Retirement.propTypes = {
  user: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Retirement));
