/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  withRouter,
  Route,
  Switch,
} from 'react-router-dom';

import {
  ExtendedFlex,
  Heading,
  NavLink,
  ScrollToTopOnMount,
} from '../../coreui';

import actions from '../actions';
import autoInvestActions from '../autoinvest/actions';
import ActiveInvestmentsContainer from './activeInvestments/container';
import CompletedInvestmentsContainer from './completedInvestments/container';
import PendingInvestmentsContainer from './pendingInvestments/container';
import NotificationBar from '../components/NotificationBar';
import FoeDropdown from '../components/FoeDropdown';
import DashboardSummary from '../components/DashboardSummary';
import DashboardNavLink from '../components/DashboardNavLink';
import { investmentsUrl, completedInvestmentsUrl, pendingInvestmentsUrl } from '../links';
import DashboardNotifications from '../components/DashboardNotifications';
import DashboardLoader from '../components/DashboardLoader';
import RedirectToLoginOnLoggedOut from '../../components/RedirectToLoginOnLoggedOut';

const renderActive = (selected) => {
  return () => {
    return <ActiveInvestmentsContainer ownershipOptions={selected} />;
  };
};

const renderCompleted = (selected) => {
  return () => {
    return <CompletedInvestmentsContainer ownershipOptions={selected} />;
  };
};

const renderPending = (selected, pendingRef) => {
  return () => {
    return (
      <PendingInvestmentsContainer
        pendingRef={pendingRef}
        ownershipOptions={selected}
      />
    );
  };
};

const NULL_FOE = {
  id: null,
  title: 'ALL ACCOUNTS',
};

class InvestmentsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFoe: (props.ownershipFilter && props.ownershipFilter.id) ?
        props.ownershipFilter : NULL_FOE,
    };
    this.selectFoeOption = this.selectFoeOption.bind(this);

    // Keep a ref to the pending container.
    this.pendingInvestments = null;
    this.setPendingRef = (component) => {
      this.pendingInvestments = component;
    };
  }

  componentDidMount() {
    const selectedFoe = this.state.selectedFoe !== NULL_FOE ? this.state.selectedFoe : undefined;
    this.props.getDashboardApiData(selectedFoe);
    this.props.getNotificationApiData();
  }

  selectFoeOption(foeObject) {
    this.setState({ selectedFoe: foeObject });
    this.props.setOwnershipFilter(foeObject);
  }

  render() {
    const {
      loaded,
      pendingData,
      data,
      user,
      isApp,
      frontendPrefix,
    } = this.props;
    const hasNotification = this.props.notifications.length > 0;
    const adjustedOptions = this.state.selectedFoe.id !== null ? [
      NULL_FOE,
      ...this.props.ownershipOptions,
    ] : this.props.ownershipOptions;

    return (
      <ExtendedFlex
        flexDirection="column"
        justifyContent="center"
        width="100%"
        alignItems="center"
        pb={140}
        {...(!loaded ? {
          justifyContent: 'flex-start',
          minHeight: this.props.ownershipOptions.length !== 0 ?
            [850, 900, 1100, 1200, '100vh'] :
            [650, 700, 900, 1000, '100vh'],
          position: 'relative',
        } : {})}
      >
        <ScrollToTopOnMount />
        <RedirectToLoginOnLoggedOut />
        {
          hasNotification &&
          <NotificationBar data={this.props.notifications} />
        }
        <DashboardNotifications
          pendingData={pendingData}
          {...(this.props.location.pathname === pendingInvestmentsUrl ? {
            handleClick: () => {
              this.pendingInvestments.scrollToPending();
            },
          } : {})}
        />
        <Heading
          type="H2"
          my="0px"
          pt="100px"
          pb="0px"
        >
          My Dashboard
        </Heading>
        <DashboardNavLink />
        {
          this.props.ownershipOptions.length !== 0 &&
          <FoeDropdown
            options={adjustedOptions}
            selected={this.state.selectedFoe}
            handleSelect={this.selectFoeOption}
          />
        }
        <DashboardSummary data={data} user={user} pendingData={pendingData} isApp={isApp} frontendPrefix={frontendPrefix} />
        <DashboardInvestmentsNav />
        {
          loaded &&
          <Switch>
            <Route
              path={investmentsUrl}
              render={renderActive(this.state.selectedFoe)}
            />
            <Route
              path={completedInvestmentsUrl}
              render={renderCompleted(this.state.selectedFoe)}
            />
            <Route
              path={pendingInvestmentsUrl}
              render={renderPending(this.state.selectedFoe, this.setPendingRef)}
            />
          </Switch>
        }
        <DashboardLoader loaded={loaded} />
      </ExtendedFlex>
    );
  }
}

const DashboardInvestmentsNav = () => {
  return (
    <ExtendedFlex
      py={[45, 45, 60]}
      px={[0, 50]}
      justifyContent="center"
      width={[1, 'auto']}
    >
      <NavLink
        to={investmentsUrl}
        type="L9"
        mr={[2.5, 10]}
        textAlign="center"
      >
        ACTIVE INVESTMENTS
      </NavLink>
      <NavLink
        to={completedInvestmentsUrl}
        type="L9"
        mr={[2.5, 10]}
        textAlign="center"
      >
        COMPLETED INVESTMENTS
      </NavLink>
      <NavLink
        to={pendingInvestmentsUrl}
        type="L9"
        textAlign="center"
        textTransform="uppercase"
      >
        Pending Investments
      </NavLink>
    </ExtendedFlex>
  );
};

InvestmentsPage.propTypes = {
  data: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  ownershipFilter: PropTypes.object,
  ownershipOptions: PropTypes.array,
  getDashboardApiData: PropTypes.func,
  setOwnershipFilter: PropTypes.func,
  getNotificationApiData: PropTypes.func,
  notifications: PropTypes.array,
  loaded: PropTypes.bool,
  pendingData: PropTypes.array,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

const mapStateToProps = (state) => {
  const loaded = state.dashboard.data.loaded;

  return {
    data: state.dashboard.data,
    user: state.app.user,
    ownershipFilter: state.dashboard.ownershipFilter,
    ownershipOptions: state.dashboard.ownershipOptions,
    notifications: state.dashboard.notifications.data,
    pendingData: state.dashboard.pendingData.pendingData.investments,
    loaded,
    isApp: state.app.isApp,
    frontendPrefix: state.app.frontendPrefix,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardApiData: (foeObject) => {
      const foeId = (foeObject && foeObject.id) ? parseInt(foeObject.id, 10) : undefined;
      dispatch(actions.dashboardApiActionRequested(foeId));
      dispatch(actions.pendingDashboardDataRequested());
      dispatch(autoInvestActions.autoInvestDataRequested());
    },
    setOwnershipFilter: (foeObject) => {
      const foeId = parseInt(foeObject.id, 10);
      dispatch(actions.dashboardApiActionRequested(foeId));
      dispatch(actions.ownershipFilterSet(foeObject));
    },
    getNotificationApiData: () => {
      dispatch(actions.notificationApiActionRequested());
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvestmentsPage));
