import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';

import {
  countries,
} from '../../data';

const Country = ({
  name,
  value,
  onChange,
}) => {
  return (
    <Select
      name={name}
      value={value}
      onChange={onChange}
      placeholder="Select Country"
      required
      options={Object.keys(countries).reduce((accum, countryCode) => [
        ...accum,
        { value: countryCode, label: countries[countryCode] },
      ], [])}
    />
  );
};

Country.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Country;
