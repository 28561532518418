import React from 'react';
import PropTypes from 'prop-types';
import { injectGlobal } from 'styled-components';

import {
  ExtendedBox,
  ExtendedFlex,
  Data,
  Body,
  Heading,
  Slider,
  Numeral,
  Formatters,
  ModalWithOverlay,
  StyledButton,
} from '../../coreui';
import LoadableHighcharts from '../../components/LoadableHighcharts';

export const chartCSS = injectGlobal`
  .highcharts-axis-line {
    display: none;
  }
  .highcharts-container text  {
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    color: #202020;
  }
`;

const DisclosureWrapper = ({
  showModal,
  handleClick,
  children,
}) => {
  return showModal !== undefined
    ? (
      <>
        <ExtendedFlex
          w={1}
          justifyContent="center"
          my={20}
        >
          <StyledButton
            type="L4"
            onClick={handleClick}
          >
            Disclosure
          </StyledButton>
        </ExtendedFlex>
        <ModalWithOverlay
          isVisible={showModal}
          handleClick={handleClick}
        >
          {children}
        </ModalWithOverlay>
      </>
    ) : children;
};

DisclosureWrapper.propTypes = {
  showModal: PropTypes.bool,
  handleClick: PropTypes.func,
  children: PropTypes.node,
};

// eslint-disable-next-line
class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amountInvested: 5000,
      returnPercentage: props.defaultReturnPercentage || 8,
      autoInvestAmount: props.showAutoInvest ? 250 : undefined,
      showModal: props.useDisclosureModal ? false : undefined,
    };
    this.handleSliderAmountChange = this.handleSliderAmountChange.bind(this);
    this.handleSliderReturnChange = this.handleSliderReturnChange.bind(this);
    this.handleSliderAutoInvestChange = this.handleSliderAutoInvestChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this._chart = React.createRef();
  }

  componentDidUpdate() {
    if (typeof this._chart.chart === 'object') {
      if (this.props.showAutoInvest) {
        this._chart.chart.series[0].setData(this.getAutoInvestData(), true);
        this._chart.chart.series[1].setData(this.getData(), true);
      } else {
        this._chart.chart.series[0].setData(this.getData(), true);
      }
    }
  }

  getData() {
    const data = [];
    for (let i = 0; i < 11; i += 1) {
      if (i === 0) {
        data[i] = parseFloat(this.state.amountInvested);
      } else {
        let basePrincipal = data[i - 1];
        const rate = ((this.state.returnPercentage / 100) / 12);
        for (let m = 0; m < 12; m += 1) {
          // Calculate interest on a monthly basis.
          basePrincipal = (basePrincipal * rate) + basePrincipal;
        }
        data[i] = basePrincipal;
      }
    }
    return data;
  }

  getAutoInvestData() {
    const data = [];
    for (let i = 0; i < 11; i += 1) {
      if (i === 0) {
        data[i] = parseFloat(this.state.amountInvested);
      } else {
        let basePrincipal = data[i - 1] + 12 * this.state.autoInvestAmount;
        const rate = ((this.state.returnPercentage / 100) / 12);
        for (let m = 0; m < 12; m += 1) {
          // Calculate interest on a monthly basis.
          basePrincipal = (basePrincipal * rate) + basePrincipal;
        }
        data[i] = basePrincipal;
      }
    }
    return data;
  }

  handleSliderAmountChange(event) {
    this.setState({
      amountInvested: event.target.value,
    });
  }

  handleSliderReturnChange(event) {
    this.setState({
      returnPercentage: event.target.value,
    });
  }

  handleSliderAutoInvestChange(event) {
    this.setState({
      autoInvestAmount: event.target.value,
    });
  }

  toggleModal() {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  }

  render() {
    const {
      amountInvestedLabel,
      showAutoInvest,
    } = this.props;

    const options = {
      title: {
        text: undefined,
      },
      chart: {
        renderTo: 'chart-column',
        backgroundColor: null,
      },
      xAxis: {
        categories: [
          'Today',
          'Y1',
          'Y2',
          'Y3',
          'Y4',
          'Y5',
          'Y6',
          'Y7',
          'Y8',
          'Y9',
          'Y10',
        ],
        tickWidth: 0,
        tickmarkPlacement: 'on',
        gridLineWidth: 1,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          // eslint-disable-next-line
          format: '${value}',
          formatter() {
            let val = this.value;
            if (!Number.isInteger(val)) {
              val = parseInt(val, 10);
            }

            if (val > 999) {
              val /= 1000;
              val = `${val}K`;
            }

            return `$${val}`;
          },
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter() {
          const value = this.y.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
          const label = (() => {
            if (!showAutoInvest) {
              return 'Investment Value';
            }
            return this.color === '#9B9B9B'
              ? 'Without Auto Invest'
              : 'With Auto Invest';
          })();

          return `${label}<br/><strong>${value}</strong>`;
        },
        headerFormat: null,
        shared: !showAutoInvest,
      },
      series: showAutoInvest
        ? [
          {
            name: null,
            data: this.getAutoInvestData(),
            color: '#176DB6',
          },
          {
            name: null,
            data: this.getData(),
            color: '#9B9B9B',
          },
        ]
        : [
          {
            name: null,
            data: this.getData(),
            color: '#176DB6',
          },
        ],
    };

    const {
      amountInvested,
      returnPercentage,
      autoInvestAmount,
      showModal,
    } = this.state;

    const sliderStyle = {
      width: '100%',
    };

    return (
      <ExtendedBox
        mx={['5%', '10%']}
        maxWidth="1440px"
      >
        <LoadableHighcharts
          options={options}
          ref={(el) => { this._chart = el; }}
        />
        <ExtendedFlex
          flexDirection={['column', 'column', 'column', 'row']}
          width={1}
          justifyContent="space-between"
        >
          <ExtendedFlex
            flexDirection="column"
            width="80%"
            mx="40px"
          >
            <Data
              type="D7"
              textAlign="center"
              alignSelf="center"
              display="block"
            >
              <Numeral
                value={amountInvested}
                format={Formatters.DOLLARS}
              />
            </Data>
            <Slider
              name="invest"
              min="1000"
              max="50000"
              step="100"
              defaultValue={amountInvested}
              style={sliderStyle}
              onChange={this.handleSliderAmountChange}
            />
            <Heading
              type="H8"
              textAlign="center"
              alignSelf="center"
              display="block"
              my="20px"
            >
              {amountInvestedLabel}
            </Heading>
          </ExtendedFlex>
          <ExtendedFlex
            flexDirection="column"
            width="80%"
            mx="40px"
          >
            <Data
              type="D7"
              textAlign="center"
              alignSelf="center"
              display="block"
            >
              <Numeral
                value={returnPercentage / 100}
                format="0.[0]%"
              />
            </Data>
            <Slider
              name="return"
              min="-10"
              max="10"
              step="0.1"
              defaultValue={returnPercentage}
              style={sliderStyle}
              onChange={this.handleSliderReturnChange}
            />
            <Heading
              type="H8"
              textAlign="center"
              alignSelf="center"
              display="block"
              my="20px"
            >
              Annual Return
            </Heading>
          </ExtendedFlex>
          {
            showAutoInvest &&
            <ExtendedFlex
              flexDirection="column"
              width="80%"
              mx="40px"
            >
              <Data
                type="D7"
                textAlign="center"
                alignSelf="center"
                display="block"
              >
                <Numeral
                  value={autoInvestAmount}
                  format={Formatters.DOLLARS}
                />
              </Data>
              <Slider
                name="autoinvest-amount"
                min="250"
                max="10050"
                step="100"
                defaultValue={autoInvestAmount}
                style={sliderStyle}
                onChange={this.handleSliderAutoInvestChange}
              />
              <Heading
                type="H8"
                textAlign="center"
                alignSelf="center"
                display="block"
                my="20px"
              >
                Amount Auto Invested
              </Heading>
            </ExtendedFlex>
          }
        </ExtendedFlex>
        <DisclosureWrapper
          showModal={showModal}
          handleClick={this.toggleModal}
        >
          <ExtendedBox
            my="20px"
          >
            <Body
              type="B5"
              color="gray.7"
            >
              For ease of use, this Return Calculator is limited to a range of -10% and 10%
              annual return, and does not reflect any variance to a $10.00 per share Net Asset Value
              in its results.  Actual results may vary. Assumes participation in the
              Distribution Reinvestment Program.  Results may vary with each use and over time.
              IMPORTANT: The projections or other information generated by this calculator regarding
              the likelihood of various investment outcomes are hypothetical in nature, do not
              reflect actual investment results and are not guarantees of future results.
            </Body>
          </ExtendedBox>
        </DisclosureWrapper>
      </ExtendedBox>
    );
  }
}

Chart.propTypes = {
  defaultReturnPercentage: PropTypes.number,
  amountInvestedLabel: PropTypes.string,
  showAutoInvest: PropTypes.bool,
  useDisclosureModal: PropTypes.bool,
};

Chart.defaultProps = {
  amountInvestedLabel: 'Amount Invested',
  showAutoInvest: false,
};

export default Chart;
