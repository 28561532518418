import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  ArticleCard,
} from '../../coreui';

const Cards = ({
  items,
  addPadding,
}) => {
  return (
    <ExtendedFlex
      w={1}
      pt={10}
      pb={addPadding ? 80 : 10}
      flexDirection="row"
      maxWidth={1600}
      wrap="wrap"
      justify="center"
      align="center"
    >
      {
        items.map(
          (item, index) => {
            return (
              <ArticleCard
                key={item.id}
                id={item.id}
                fadeIndex={(index % 6)}
                type={item.bundle}
                imageUrl={item.image._links.listing}
                title={item.label}
                topic={item.category.label}
                url={item._links.alias}
                promo={item.promo_sentence}
              />
            );
          },
        )
      }
    </ExtendedFlex>
  );
};

Cards.propTypes = {
  items: PropTypes.array,
  addPadding: PropTypes.bool,
};

export default Cards;
