import React from 'react';
import PropTypes from 'prop-types';

import {
  Body,
  Heading,
} from '../../coreui';

import CalloutFrame from '../../components/CalloutFrame';
import CalloutImage from '../../assets/img_panel_passive.jpg';

class PassiveIncome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      heading,
      body,
      ...passedProps
    } = this.props;
    return (
      <CalloutFrame
        image={CalloutImage}
        bg="gray.1"
        imagePosition="second"
        // eslint-disable-next-line
        {...passedProps}
      >
        <Heading
          type="H2"
          mt={0}
        >
          {heading}
        </Heading>
        {
          body &&
          body.map((item) => {
            return (
              <Body
                key={item}
                type="B1"
                mb={0}
              >
                {item}
              </Body>
            );
          })
        }
      </CalloutFrame>
    );
  }
}

PassiveIncome.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
};

export default PassiveIncome;
