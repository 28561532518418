/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Hero,
  ScrollToTopOnMount,
} from '../coreui';

import Section from '../ioDetails/components/section';
import Graphic3ColCallout from '../components/Graphic3ColCallout';
import Strategy from './components/strategy';
import Investing from './components/investing';
import Strategy2 from './components/strategy2';
import AvailableToday from '../benefits/components/availableToday';
import InvestToday from '../ioDetails/components/investToday';
import RelatedQueueContent from '../knowledgeCenter/components/RelatedQueueContent';
import IconCallouts from '../components/IconCallouts';
import BrowseLink from '../components/BrowseLink';

import heroBannerImage from '../assets/multifamily_hero.jpg';
import data from './data';
import Highlighted3ColCallout from '../components/Highlighted3ColCallout';

const WhyMultiFamily = ({
  user,
  reitData,
}) => {
  const isLoggedIn = user.isLoggedIn;

  return (
    <>
      <ScrollToTopOnMount />
      <Hero
        image={heroBannerImage}
        header={data.hero.header}
        subHeader={data.hero.subheader}
      />
      <Section
        bg="gray.0"
      >
        <Graphic3ColCallout
          title={data.overview.title}
          description={data.overview.subtitle}
          items={data.tools}
        />
        <BrowseLink
          anonText="Join Now"
          isLoggedIn={isLoggedIn}
        />
      </Section>
      <Strategy
        isLoggedIn={isLoggedIn}
      />
      <AvailableToday
        heading={data.opportunities.heading}
        investments={[...reitData, ...data.opportunities.investments]}
        user={user}
      />
      <IconCallouts
        {...data.whyInvest}
      />
      <Section
        bg="gray.0"
      >
        <Investing
          disclosure={data.disclosure}
        />
      </Section>
      <Section>
        <Highlighted3ColCallout
          title={data.renting.title}
          subTitle={data.renting.subtitle}
          highlights={[
            { highlight: data.renting.items[0].title, description: data.renting.items[0].body },
            { highlight: data.renting.items[1].title, description: data.renting.items[1].body },
            { highlight: data.renting.items[2].title, description: data.renting.items[2].body },
          ]}
          cta={<BrowseLink isLoggedIn={isLoggedIn} />}
        />
      </Section>
      <Strategy2
        isLoggedIn={isLoggedIn}
      />
      <Section
        bg="gray.0"
      >
        <RelatedQueueContent
          queue="multifamily_articles"
          title={data.insight.title}
        />
      </Section>
      <Section>
        <InvestToday
          smartButton={data.smartButton}
        />
      </Section>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.app.user,
  reitData: state.reitData.list,
});

WhyMultiFamily.propTypes = {
  user: PropTypes.object,
  reitData: PropTypes.array,
};

export default connect(mapStateToProps)(WhyMultiFamily);
