export const products = [
  {
    label: 'Up to $7M',
    value: 'Joint Venture Equity and preferred Equity',
  },
  {
    label: 'Up to $25M',
    value: 'Commercial Bridge Loans',
  },
  {
    label: 'Up to $5M',
    value: 'Mezzanine Debt',
  },
];

export const steps = [
  {
    label: 'Create Account',
  },
  {
    label: 'Investment Profile',
  },
  {
    label: 'View Open Opportunities',
  },
];

export const signUpConfig = {
  hideAllDefaults: true,
  header: 'Provide your account details below.',
  signUpFields: [
    {
      label: 'Account Purpose',
      key: 'custom:account_purpose',
      required: true,
      placeholder: 'Account Purpose',
      type: 'text',
      displayOrder: 1,
    },
    {
      label: 'First Name',
      key: 'given_name',
      required: true,
      placeholder: 'First Name',
      type: 'text',
      displayOrder: 1,
    },
    {
      label: 'Last Name',
      key: 'family_name',
      required: true,
      placeholder: 'Last Name',
      type: 'text',
      displayOrder: 2,
    },
    {
      label: 'Email',
      key: 'username',
      required: true,
      placeholder: 'Email',
      type: 'email',
      displayOrder: 3,
    },
    {
      label: 'Phone',
      key: 'phone_number',
      required: false,
      placeholder: 'Phone Number',
      type: 'phone',
      displayOrder: 4,
    },
    {
      label: 'Are you a US Citizen?',
      key: 'custom:us_citizen',
      placeholder: 'US Citizen',
      type: 'text',
      displayOrder: 5,
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      placeholder: 'Password',
      type: 'password',
      displayOrder: 6,
    },
  ],
};

export const userPaths = [
  '/user',
  '/user/login',
  '/user/logout',
  '/user/register',
  '/user/password',
];

export const authScreenLabels = {
  en: {
    Username: 'E-mail',
    'Enter your username': 'E-mail',
    'Enter your password': 'Password',
    'Sign Up': 'Create new account',
    'Sign Up Account': 'Create a new account',
    'Create Account': 'Continue',
    'Phone Number': 'Phone',
    Email: 'E-mail',
    'Sign in to your account': 'Please enter your email and password below.',
    'UserMigration failed with error Bad password': 'Incorrect email or password.',
    'PreSignUp failed with error account already exists.': 'An account with the given email already exists.',
    'UserMigration failed with error incorrect username.': 'Incorrect email or password.',
    'UserMigration failed with error incorrect password.': 'Incorrect email or password.',
    'Incorrect username or password': 'Incorrect email or password.',
    'Cannot reset password for the user as there is no registered/verified email or phone_number': 'Cannot reset password for the user as there is no verified email.',
  },
};

export default {
  products,
  steps,
  signUpConfig,
  userPaths,
  authScreenLabels,
};
