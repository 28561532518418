export default {
  USER_REGISTER_REQUESTED: 'USER_REGISTER_REQUESTED',
  USER_REGISTER_SUCCEEDED: 'USER_REGISTER_SUCCEEDED',
  USER_REGISTER_FAILED: 'USER_REGISTER_FAILED',
  USER_LOGIN_SUCCEEDED: 'USER_LOGIN_SUCCEEDED',
  USER_LOGIN_REQUESTED: 'USER_LOGIN_REQUESTED',
  USER_LOGIN_FAILED: 'USER_LOGIN_FAILED',
  USER_LOGOUT_REQUESTED: 'USER_LOGOUT_REQUESTED',
  USER_LOGOUT_SUCCEEDED: 'USER_LOGOUT_SUCCEEDED',
  USER_LOGOUT_FAILED: 'USER_LOGOUT_FAILED',
  USER_SESSION_REQUESTED: 'USER_SESSION_REQUESTED',
  USER_SESSION_SUCCEEDED: 'USER_SESSION_SUCCEEDED',
  USER_SESSION_FAILED: 'USER_SESSION_FAILED',
  USER_REGISTER_ACCRED_SUCCEEDED: 'USER_REGISTER_ACCRED_SUCCEEDED',
};
