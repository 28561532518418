/* eslint-disable react/jsx-props-no-spreading */
// FIXME: This is where we would implement clickable
// investment card image
import React from 'react';

import {
  Link,
} from '../../..';

import InvestmentCardImage from '../components/InvestmentCardImage';

const investmentCardImageFactoryFactory = (props) => {
  const {
    imageUrl,
    pathAlias,
    status,
    waitlist,
    hideLink,
  } = props;

  const hasLink = !(status === 'status-20-coming-soon' ||
    status === 'status-40-closed');

  const imageProps = {
    imageUrl,
    ImageWrapper: hasLink && !hideLink ? Link : React.Fragment,
    imageWrapperProps: hasLink ? {
      [!pathAlias?.startsWith('mailto:') ? 'to' : 'href']: pathAlias,
    } : {},
    hover: hasLink,
    desaturate: waitlist,
  };

  const investmentCardImageFactory = () => {
    return (
      <InvestmentCardImage
        {...imageProps}
      />
    );
  };

  return investmentCardImageFactory;
};

export default investmentCardImageFactoryFactory;
