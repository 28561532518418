/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedFlex,
} from '../../coreui';

import FinancingAdvantage1 from './components/financingAdvantage1';
import FinancingAdvantage2 from './components/financingAdvantage2';

class FinancingAdvantage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible,
      });
    }
  }

  render() {
    const { isLoggedIn } = this.props;
    return (
      <ExtendedFlex
        flexDirection={['column', 'column', 'row', 'row', 'row']}
      >
        <FinancingAdvantage1
          handleSensorChange={this.handleSensorChange}
          isLoggedIn={isLoggedIn}
          {...this.state}
        />
        <FinancingAdvantage2
          {...this.state}
        />
      </ExtendedFlex>
    );
  }
}

FinancingAdvantage.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default FinancingAdvantage;
