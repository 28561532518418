/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Heading,
  Body,
} from '../../../coreui';

import FadeIn from '../../FadeIn';

const TriPanelCard = ({
  header,
  body,
  link,
  ...props
}) => {
  return (
    <ExtendedFlex
      flexDirection="column"
      justify="center"
      maxWidth={[null, null, 520, 520, 660]}
      height="100%"
      px={['15%', '25%', 60, 100, 120]}
      py="4em"
      {...props}
    >
      <FadeIn>
        <Heading
          type="H3"
          textTransform="uppercase"
          m={0}
          mb="0.7em"
        >
          {header}
        </Heading>
        <Body
          type="B1"
          m={0}
          mb={link ? '4em' : undefined}
        >
          {body}
        </Body>
        {link}
      </FadeIn>
    </ExtendedFlex>
  );
};

TriPanelCard.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
  link: PropTypes.node,
};

export default TriPanelCard;
