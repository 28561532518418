/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
} from '../coreui';

const columnConfig = {
  auto: {
    wrapper: {
      mx: 'auto',
      flexDirection: ['column', 'row'],
      align: 'start',
      alignItems: 'stretch',
      maxWidth: 1440,
      px: [50, 50, '10%', '5%', 0],
    },
    container: {
      flex: '1',
    },
  },
};

columnConfig.simple = {
  ...columnConfig.auto,
  container: {
    ...columnConfig.auto.container,
    flex: '0 1 auto',
  },
};

const Container = ({
  type,
  children,
  containerProps,
  ...additionalProps
}) => {
  const config = columnConfig[type] || columnConfig.auto;

  return (
    <ExtendedFlex
      justifyContent="center"
      w={1}
      {...config.wrapper}
      {...additionalProps}
    >
      {
        React.Children.map(children, (child) => {
          // Skip children that aren't rendered.
          if (!child) {
            return null;
          }

          return (
            <ExtendedBox
              {...config.container}
              {...containerProps}
            >
              {child}
            </ExtendedBox>
          );
        })
      }
    </ExtendedFlex>
  );
};

Container.propTypes = {
  type: PropTypes.oneOf(Object.keys(columnConfig)),
  containerProps: PropTypes.object,
  children: PropTypes.node,
};

Container.defaultProps = {
  type: 'auto',
};

export default Container;
