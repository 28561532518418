import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Image,
} from '../../../coreui';

// import collapseIcon from '../assets/icon_collapse.svg';
// import expandIcon from '../assets/icon_expand.svg';
import expandIcon from '../../assets/expand.svg';
import collapseIcon from '../../assets/collapse.svg';

const Expander = ({ isExpanded }) => {
  return (
    <ExtendedFlex
      alignItems="center"
      justify="center"
      m={0}
      hover
      height="100%"
    >

      <ExtendedBox
        w={10}
        position="relative"
        transition="transform 0.2s linear"
        transform={isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'}
        hover
      >
        <Image
          position="absolute"
          top={-2}
          left={1}
          src={collapseIcon}
          opacity={isExpanded ? '1' : '0'}
          transition="opacity 0.2s linear"
          hover
        />
        <Image
          position="absolute"
          top={-6}
          left={1}
          src={expandIcon}
          hover
          opacity={isExpanded ? '0' : '1'}
          transition="opacity 0.2s linear"
        />
      </ExtendedBox>
    </ExtendedFlex>
  );
};

Expander.propTypes = {
  isExpanded: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
};

export default Expander;
