export default [
  {
    header: 'FAQS',
    subHeader: 'Have a question? You may find the answer here.',
    linkText: 'VIEW ALL FAQS >',
    linkUrl: '/knowledge-center/faqs',
    borderRight: [0, 0, 0, 1],
    borderColor: 'gray.7',
  },
  {
    header: 'KNOWLEDGE CENTER',
    subHeader: 'Learn about investing through articles written by industry professionals.',
    linkText: 'GO TO KNOWLEDGE CENTER >',
    linkUrl: '/knowledge-center',
    borderRight: [0, 0, 0, 1],
    borderColor: 'gray.7',
  },
  {
    header: 'OUR STORY',
    subHeader: 'Want to know more about RealtyMogul? Meet our leadership here.',
    linkText: 'SEE OUR STORY >',
    linkUrl: '/our-story',
  },
];
