import {
  takeLatest,
  call,
  all,
  put,
} from 'redux-saga/effects';
import noop from 'lodash/noop';

import types from '../types';
import actions from '../actions';
import appActions from '../../appWrapper/actions';
import authActions from '../../auth/actions';

import { postAccreditationApi } from '../../api';

export function* postDataFlow(action) {
  const {
    data,
    promise: {
      resolve = noop,
      reject = noop,
    } = {},
  } = action;

  try {
    const {
      data: responseData,
    } = yield call(postAccreditationApi, data);

    yield all([
      call(resolve, responseData),
      put(
        actions.userAccreditationSucceeded(responseData),
      ),
      put(
        appActions.getUserDataRequested(),
      ),
    ]);

    // Fire off the accredited event if the user passed self accreditation.
    if (responseData?.data?.accredited) {
      yield put(
        authActions.accredRegisterSucceeded(responseData),
      );
    }
  } catch (error) {
    yield all([
      call(reject, error),
      put(
        actions.userAccreditationFailed(error),
      ),
    ]);
  }
}

export function* watchUserAccreditationRequested() {
  yield takeLatest(
    types.USER_ACCREDITATION_REQUESTED,
    postDataFlow,
  );
}

export default watchUserAccreditationRequested;
