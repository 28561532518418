import React from 'react';
import PropTypes from 'prop-types';

import {
  Heading,
  Body,
  ExtendedFlex,
  Numeral,
} from '../../coreui';

import CalloutFrame from '../../components/CalloutFrame';
import backgroundImage from '../../assets/StressUncertaintyPanel.png';
import BrowseLink from '../../components/BrowseLink';

const ImageFrame = ({
  total1031InvestmentAmount,
  isLoggedIn,
}) => {
  return (
    <CalloutFrame image={backgroundImage} imagePosition="second" bg="gray.0">
      <Heading type="H2" display="block">
        Removing The Stress and Uncertainty
      </Heading>
      <Body type="B1" display="block">
        The RealtyMogul platform offers professionally managed, investment grade real estate opportunities with full due diligence packets.
      </Body>
      <Body type="B1" display="block">
        Since inception in 2012, Members have used the RealtyMogul platform to invest over <Numeral value={total1031InvestmentAmount} format="$0a" /> into 1031 Exchanges. The RealtyMogul platform makes the process simple.
      </Body>
      <ExtendedFlex mr="auto">
        <BrowseLink anonText="Get Started" isLoggedIn={isLoggedIn} />
      </ExtendedFlex>
    </CalloutFrame>
  );
};

ImageFrame.propTypes = {
  total1031InvestmentAmount: PropTypes.number,
  isLoggedIn: PropTypes.bool,
};

export default ImageFrame;
