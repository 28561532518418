import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  withRouter,
} from 'react-router-dom';

import { Button } from 'rtmg-component-library';
import {
  Box,
  Formatters,
  ExtendedFlex,
} from '../../../coreui';

import SummaryItem from '../../components/SummaryItem';
import IOCardCompleted from '../../components/IOCardCompleted';

const CompletedInvestmentsContainer = ({ data }) => {
  const [investmentsShown, setInvestmentsShown] = useState(25);
  const [avgIRR, setAvgIRR] = useState(0);

  useEffect(() => {
    let weight = 0;
    const totalInvested = data.privatePlacementData?.summary?.items?.totalInvested?.value;
    data.privatePlacementData?.ios.map((item) => {
      // don't count weighted if IRR is 0
      if (Number(item.annualizedReturn) === 0) return item;
      weight += Number(item.annualizedReturn) * Number(item.totalInvestmentAmount);
      return item;
    });
    setAvgIRR(weight / totalInvested);
  }, [data]);

  return (
    <ExtendedFlex
      flexDirection="column"
      width="100%"
      alignItems="center"
    >
      <ExtendedFlex
        flexDirection="column"
        width={1}
        justifyContent="center"
        alignItems="center"
        maxWidth={['75%', 1540]}
        px={[0, 50]}
        pb={[40, 50]}
      >
        <ExtendedFlex
          flexDirection={['column', 'column', 'column', 'row', 'row']}
          justifyContent="center"
          alignItems="center"
          w={1}
          pb={48}
        >
          <SummaryItem
            value={data.totalInvested.value}
            label="TOTAL INVESTED-COMPLETED"
            dataType="D8"
            valueFormat={Formatters.DOLLARS_DECIMALS}
            tooltipText={data.totalInvested.tooltip}
          />
          <Box m={8} mx={[0, 0, 0, 16, 24]} />
          <SummaryItem
            value={data.totalReturned.value}
            label="TOTAL RETURNED-COMPLETED"
            dataType="D8"
            valueFormat={Formatters.DOLLARS_DECIMALS}
            tooltipText={data.totalReturned.tooltip}
          />
          <Box m={8} mx={[0, 0, 0, 16, 24]} />
          <SummaryItem
            value={avgIRR}
            label="WEIGHTED AVG IRR"
            dataType="D8"
            valueFormat={Formatters.PERCENTAGE2}
            tooltipText="Internal Rate of Return (IRR) represents an offering's annualized return. The Weighted Average IRR for your completed investments is determined by summing the products of each investment's IRR by it's principal balance, and dividing the sum by the total amount originally invested across all completed opportunities."
          />
        </ExtendedFlex>
        {
        data.privatePlacementData?.ios?.length !== 0 &&
          <ExtendedFlex
            w={1}
            flexDirection="column"
            justifyContent="center"
          >
            {data.privatePlacementData?.ios.slice(0, investmentsShown).map((io) => (
              <IOCardCompleted data={{ ...io }} />
            ))}
            {
              (investmentsShown < data.privatePlacementData?.ios.length) &&
              <div style={{ maxWidth: '300px', margin: '36px auto 0' }}>
                <Button onClick={() => setInvestmentsShown(investmentsShown + 25)}>
                  Show More
                </Button>
              </div>
            }
          </ExtendedFlex>
      }
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

CompletedInvestmentsContainer.propTypes = {
  data: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    data: state.dashboard.data.completedInvestmentsData,
  };
};

export default withRouter(
  connect(mapStateToProps)(CompletedInvestmentsContainer),
);
