/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Heading, Body } from '../../coreui';
import Select from '../forms/Select';
import Label from '../forms/Label';
import Input from '../forms/Input';
import Address from '../forms/Address';
import CheckboxLabel from '../forms/CheckboxLabel';

const getEmployerHeading = ({ employmentType }) => {
  if (employmentType === 'retired') {
    return 'Most Recent Employer Info';
  }
  if (employmentType === 'student') {
    return 'Student Info';
  }
  if (employmentType === 'not_employed') {
    return 'Source of Income';
  }
  return 'Employment Info';
};

const getEmployerSubLabel = ({ employmentType }) => {
  if (employmentType === 'retired') {
    return 'Provide your previous employment information.';
  }
  if (employmentType === 'student') {
    return 'Provide the name of your school.';
  }
  if (employmentType === 'not_employed') {
    return 'Please explain your current source of income.';
  }
  return 'Provide your employment information';
};

const getEmployerLabel = ({ employmentType }) => {
  if (employmentType === 'self_employed') {
    return 'Business Name';
  }
  if (employmentType === 'student') {
    return 'School';
  }
  return 'Employer';
};

const getPositionLabel = ({ employmentType }) => {
  if (employmentType === 'self_employed') {
    return 'Your Title';
  }
  if (employmentType === 'retired') {
    return 'Most Recent Position Held';
  }
  return 'Current Position Held';
};

const getAddressLabel = ({ employmentType }) => {
  if (employmentType === 'self_employed') {
    return 'Work Address';
  }
  return 'Employer Address';
};

// eslint-disable-next-line
class EmploymentStatus extends Component {
  render() {
    const {
      isVisible,
      handleChange,
      employmentType,
      employer,
      employmentPositionHeld,
      employmentIndustry,
      useEmployerAddress,
      sameWorkAddress,
      employerAddress,
      sourceOfIncome,
    } = this.props;

    const employerHeading = getEmployerHeading(this.props);
    const employerLabel = getEmployerLabel(this.props);
    const employerSubLabel = getEmployerSubLabel(this.props);
    const positionLabel = getPositionLabel(this.props);
    const addressLabel = getAddressLabel(this.props);

    const addressProps = employerAddress ? {
      address1: employerAddress.thoroughfare,
      address2: employerAddress.premise,
      city: employerAddress.locality,
      state: employerAddress.administrative_area,
      zip: employerAddress.postal_code,
      country: employerAddress.country,
    } : {};

    if (!isVisible) {
      return null;
    }

    return (
      <>
        <Heading
          type="H6"
          textTransform="uppercase"
        >
          Employment Status
        </Heading>
        <Body
          type="B2"
        >
          Select your employment status.
        </Body>
        <Select
          name="employmentType"
          value={employmentType}
          onChange={handleChange}
          placeholder="Employment Status"
          required
          showEmpty={!employmentType}
          options={[
            { value: 'employed', label: 'Employed' },
            { value: 'self_employed', label: 'Self-Employed' },
            { value: 'retired', label: 'Retired' },
            { value: 'student', label: 'Student' },
            { value: 'not_employed', label: 'Not Employed' },
          ]}
        />
        <Heading
          type="H6"
          textTransform="uppercase"
        >
          {employerHeading}
        </Heading>
        <Body
          type="B2"
        >
          {employerSubLabel}
        </Body>
        {
          employmentType === 'self_employed' &&
          <Body
            type="B5"
          >
            Business Name can be your DBA, LLC, or you can write &quot;Self.&quot;
          </Body>
        }
        {
          !['not_employed'].includes(employmentType) &&
          <Label
            htmlFor="employer"
          >
            {employerLabel}
            <Input
              id="employer"
              value={employer}
              placeholder={employerLabel}
              name="employer"
              onChange={handleChange}
              data-test="employer"
              maxLength="255"
              required
            />
          </Label>
        }
        {
          !['student', 'not_employed'].includes(employmentType) &&
          <Label
            htmlFor="position"
          >
            {positionLabel}
            <Input
              id="position"
              value={employmentPositionHeld}
              placeholder={positionLabel}
              name="employmentPositionHeld"
              onChange={handleChange}
              data-test="position"
              maxLength="255"
              required
            />
          </Label>
        }
        {
          ['self_employed', 'retired'].includes(employmentType) &&
          <Label
            htmlFor="employmentIndustry"
          >
            Industry / Business Type
            <Input
              id="employmentIndustry"
              value={employmentIndustry}
              placeholder="Industry / Business Type"
              name="employmentIndustry"
              onChange={handleChange}
              data-test="employmentIndustry"
              maxLength="80"
              required
            />
          </Label>
        }
        {
          ['not_employed', 'retired', 'student'].includes(employmentType) &&
          <Label
            htmlFor="sourceOfIncome"
          >
            Source of Income
            <Input
              id="sourceOfIncome"
              value={sourceOfIncome}
              placeholder="Source of Income"
              name="sourceOfIncome"
              onChange={handleChange}
              data-test="sourceOfIncome"
              maxLength="255"
              required
            />
          </Label>
        }
        {
          useEmployerAddress && [null, undefined, 'employed', 'self_employed'].includes(employmentType) &&
          <>
            <Heading
              type="H6"
              textTransform="uppercase"
            >
              {addressLabel}
            </Heading>
            {
              employmentType === 'self_employed' &&
              <CheckboxLabel
                label="Check this box if your work address is the same as your home address"
                mb={50}
                inputProps={{
                  'data-test': 'same-address',
                }}
                checked={sameWorkAddress}
                name="sameWorkAddress"
                onChange={handleChange}
              />
            }
            {
              !(employmentType === 'self_employed' && sameWorkAddress) &&
              <Address
                name="employerAddress"
                onChange={handleChange}
                {...addressProps}
                required
              />
            }
          </>
        }
      </>
    );
  }
}

EmploymentStatus.propTypes = {
  isVisible: PropTypes.bool,
  handleChange: PropTypes.func,
  employmentType: PropTypes.string,
  employer: PropTypes.string,
  employmentPositionHeld: PropTypes.string,
  employmentIndustry: PropTypes.string,
  useEmployerAddress: PropTypes.bool,
  sameWorkAddress: PropTypes.bool,
  employerAddress: PropTypes.shape({
    country: PropTypes.string,
    thoroughfare: PropTypes.string,
    premise: PropTypes.string,
    locality: PropTypes.string,
    administrative_area: PropTypes.string,
    postal_code: PropTypes.string,
  }),
  sourceOfIncome: PropTypes.string,
};

EmploymentStatus.defaultProps = {
  isVisible: true,
  useEmployerAddress: true,
};

export default EmploymentStatus;
