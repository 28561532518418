import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import stubResponse from '../data';

import { getKnowledgeCenterSearchApi } from '../../../api';

export const mockApi = () => ({
  data: stubResponse,
});

export function* getSearchFlow(action) {
  const query = action.query;
  const pageNum = action.pageNum;

  try {
    const response = yield call(
      getKnowledgeCenterSearchApi,
      query,
      pageNum,
    );
    yield put(
      actions.getSearchSucceeded(response.data),
    );
  } catch (error) {
    yield put(
      actions.getSearchFailed(error),
    );
  }
}

export function* watchGetSearchRequested() {
  yield takeLatest(
    types.GET_KC_SEARCH_REQUESTED,
    getSearchFlow,
  );
}

export default watchGetSearchRequested;
