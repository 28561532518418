/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { ExtendedFlex } from '../coreui';

const TimelineFiller = ({ dividerProps, ...props }) => {
  return (
    <ExtendedFlex
      {...props}
    >
      <ExtendedFlex
        height="100%"
        pt={30}
        pb={50}
        {...dividerProps}
      />
    </ExtendedFlex>
  );
};

TimelineFiller.propTypes = {
  dividerProps: PropTypes.object,
};

export default TimelineFiller;
