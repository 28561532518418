/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';

const PrivacyPolicy = () => (
  <>
    <p>Updated July 14, 2021<br />
      <br />
      The website <strong><em>("Site")</em></strong> for Realty Mogul, Co. <strong><em>("RealtyMogul," "we," or "us")</em></strong>, was created to provide information about our company, our services and the investment opportunities available on our platform (the <strong><em>"Services"</em></strong>). We value the privacy of our Site visitors (<strong><em>"you," "your," or "user"</em></strong>), and in order to protect your personal information, we have implemented the following Privacy Policy with provisions that apply to the collection of data by RealtyMogul, its subsidiaries, and its affiliates. If you have questions or concerns regarding this statement, you should first contact us at <a href="mailto:info@realtymogul.com">info@realtymogul.com</a>.
      <br /><br />
      Our Privacy Policy discloses the type and nature of information we collect and how we use it, as well as the choices you can make about the way your information is collected and used. We also explain how any requests for personal or personally identifiable information will be used. Finally, we present our security policy, which outlines how your personal information is protected.<br />
      <br />
    </p>

    <p><strong>Information We Collect</strong></p>

    <p>
    RealtyMogul collects personal and financial information from you when you visit the Site. The majority of information is collected during the registration and/or application process and, while some items are optional, most are required for legal or security purposes. You may choose to provide additional information during subsequent visits to the Site, but keep in mind that some of this information will be required if you wish to partake in the Services.<br />
      <br />
      In general, the type of information we collect includes, but is not limited to:
    </p>

    <ul>
      <li>Personal data required to verify your identity;</li>
      <li>Personal and financial data necessary to assess your eligibility to utilize the Services on the RealtyMogul platform; and</li>
      <li>Non-identifiable and aggregated personal data pertaining to your Site visits that help us maintain the appropriate features, functionality and user experience</li>
    </ul>

    <p><strong>Personal Data</strong></p>

    <p>
    Investor registration and lending applications will require you to provide basic personal data. Such information typically includes your name, email address, home address and phone number, date of birth, social security number and/or tax identification number, employer and job title. RealtyMogul uses this data to: (i) enable you to log in to the Site; (ii) establish your ability to view offering materials and to make investments through the Site (for U.S. residents, such ability is restricted to "accredited investors" with a certain level of annual income or net worth); (iii) determine your credibility to partake in lending opportunities; (iv) verify that you are at least 18 years of age; (v) guard against potential fraud; (vi) contact you if there is a problem with your account; and (vii) maintain regular communications with you as may be necessary to inform you of upcoming investment opportunities and other company updates, including SMS communications to users who have opted-in to RealtyMogul’s SMS Communications program.<br />
      <br />
      RealtyMogul will also gather the names and email addresses of users who contact us through the Site with questions about our company or lending platform. We collect this information for the sole purpose of responding to such inquiries and do not store the contact information unless requested to do so, as in the case of job applicants who submit resumes.<br />
    </p>

    <p><strong>Financial Data (for Investors)</strong></p>

    <p>
    When you subscribe to make an investment in one of the investment opportunities available on the platform, you will again be asked for your personal data. You will also be required to provide financial data, such as your bank routing number and bank account number, to enable us to utilize your bank account to originate funds transfers and make subsequent investment disbursements to you.<br />
    </p>

    <p><strong>Financial Data (for Borrowers)</strong></p>

    <p>
    You will be asked to provide and disclose financial data when filling out an application to partake in lending services. Such information may be shared with third parties. See <strong><em>"How and When Your Information Is Shared With Other Parties"</em></strong> for more details.<br />
    </p>

    <p><strong><em>Non-Identifiable Data and Aggregated Personal Data</em></strong></p>

    <p>
    Regardless of whether you register for an account or submit an application, RealtyMogul may send one or more "cookies" to your computer. Cookies are small text files placed on your web browser when you visit our Site that store information on your computer, such as your Site preferences. We use cookies when you sign in to keep track of your personal session, including some account identifiers to ensure that you are the only person making changes to your account. We also use cookies to track your activity on the Site as a unique person. For security purposes, all of this information is stored in encrypted form. No personal information about you is stored.<br />
      <br />
      You can set your web browser to inform you when cookies are set, or to prevent cookies from being set altogether. Please note, however, that if you decline to use cookies, you may experience reduced functionality and slower site response times. In some instances, declining to use our authentication-related cookies may prevent you from using the website entirely.<br />
      <br />
      RealtyMogul (or our service providers, such as Google Analytics) may also collect web surfing data related to your use of the Site. Such information may include: your Internet Protocol (IP) address, browser type, and internet service provider (ISP); your operating system; which of our web pages you access and how frequently you access them; referral or exit pages; click stream data; and the dates and times that you visit the Site. This data may be collected using cookies, web beacons, page tags or similar tools. As with cookies, the web surfing information is anonymous, "click stream" transactional data that is not associated with any users as individuals.<br />
      <br />
      Web surfing data and similar information may be aggregated for administrative purposes. RealtyMogul may, for example, use this aggregated information in the administration of the Site to improve its usability and to evaluate the success of particular marketing and advertising campaigns, search engine optimization strategies, and other marketing activities. We also use it to help optimize the Site based on the needs of our users.<br />
    </p>

    <p><strong>How and When Your Information Is Shared With Other Parties</strong></p>

    <p>
      RealtyMogul does not sell or rent personal information about its users for marketing purposes. We do, however, work with a number of trusted partners who perform vital functions as part of our operations.
    </p>
    <p><strong><em>Sharing of Investor Information</em></strong></p>
    <p>
    If you indicate an interest in making an investment through the Services, such as indicating an interest to buy equity or make a loan in a particular private real estate company, we will notify the party or parties offering the investment (the “Issuer”) of your interest and share your personal information with the Issuer, and may notify other users with whom you have chosen to share information. By indicating an interest in an investment and/or sharing your profile or content with other users, you consent to this disclosure of your information. Since we do not have direct control over the policies and practices of the Issuer or other third parties, we are not responsible for their privacy policies or practices.<br />
      <br />
      RealtyMogul also works with (among others) providers of hosting services for the Site, electronic signature providers, and electronic payment service providers. We may engage third parties to help us carry out certain other internal functions such as account processing, client services, marketing research or other data collection relevant to our business. Examples of third parties might include those that perform data processing, reporting, tax documentation, custody or escrow services. Information is shared with these third parties only to the extent necessary for us to process the transactions you initiate or perform other specific services vital to the operations of our business. Our partners are legally required to keep your information private and secure.<br />
      <br />
      We may share your information with law enforcement or other government agencies as required by law or for the purposes of limiting fraud. We reserve the right to disclose your personally identifiable information when we believe that disclosure is necessary to protect our rights or to comply with a judicial proceeding, court order or legal process. We further reserve the right to disclose any of your personal information that we believe, in good faith, appropriate or necessary to take precautions against liability, to investigate and defend against any third-party claims or allegations, to assist government enforcement agencies, to protect the security or integrity of the Site or our services, or to protect the rights, property or personal safety of RealtyMogul, its users, issuers, or others.
    </p>

    <p><strong>Notifications and Communications from Our Site</strong></p>

    <p>
    RealtyMogul will send you email notifications from time to time. Some notifications are required elements of your transactions on our platform, such as confirmations of particular actions you have taken. These mandatory notices are sent typically to notify you of a change in status. For example, you will receive a notice when you are confirmed as an investor.<br />
      <br />
      We also send out notices that are required for legal or security purposes. For example, certain notifications are sent for your own protection to ensure that another person cannot make a change to your account without your knowledge. In other cases, these notifications involve changes to various legal agreements or Site policies. Generally, you may not opt out of such service-related emails.<br />
      <br />
      When you register as an investor, you will receive emails that confirm specific actions you requested. These will include emails to which you must respond to complete your registration and notifications confirming your registration. Thereafter, you will receive emails when a new investment opportunity is available on our platform, as well as updates concerning the progress of the funding of such investment and/or other relevant information. If you make an investment through the Site, RealtyMogul will also send you confirmations of the investment as well as occasional updates as to the status of that investment and the timing of distributions relating to that investment.<br />
      <br />
      When you register as an investor and opt-in to RealtyMogul’s SMS Communications program, you are consenting to receive text messages sent by an automatic telephone dialing system and acknowledge that data usage charges may apply by your cell phone company. You may receive text messages regarding system notifications, investment opportunity updates, and special offers and promotions, and can manage your opt-in status in your user profile.<br />
      <br />
      We may also send you responses to emails you send us, if appropriate. From time to time, we will also send user surveys, requests for user feedback regarding user experience and Site operations, or marketing offers from us or from us on behalf of our marketing partners. Completing these surveys, answering requests for feedback, or accepting any offer is strictly voluntary. If you do not wish to receive these surveys, user feedback emails, and/or marketing offers, please opt out in any offer email you receive from us.<br />
    </p>

    <p><strong>Where You Can View and Correct Your Information</strong></p>

    <p>
    We urge you to review your information regularly to ensure that it is correct and complete. If you believe that any of your information is incorrect, or if you have any questions regarding this Privacy Policy, please contact us at <a href="mailto:info@realtymogul.com">info@realtymogul.com</a>..<br />
    </p>

    <p><strong>Links to Other Sites</strong></p>

    <p>
    If you follow any links that direct you away from the Site, this privacy policy will not apply to your activity on the other websites you visit. We do not control the privacy policies or the privacy practices of any third parties.<br />
    </p>

    <p><strong>International Users</strong></p>

    <p>
    The Site may be accessed by users located outside the United States. If you choose to use the Service from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring your personal information outside of those regions to the United States, and that by providing your personal information on or through the Site then you consent to that transfer.<br />
    </p>

    <p><strong>Security Policy</strong></p>

    <p><strong><em>Access Limitations</em></strong></p>

    <p>
    We limit access to the personal information we have about you to those employees who have a legitimate business need to access such information. In keeping with industry standards and practices, we maintain appropriate physical, electronic and procedural safeguards and controls to protect your information. The Site is built upon a secure infrastructure with multiple layers of protection, and we use industry-standard encryption technologies to safeguard your information.<br />
      <br />
      Users are required to identify and authenticate themselves prior to accessing sensitive portions of the Site. Generally, identification and authentication take place through the use of your username and a password and/or while logging in with one of our technical support staff.<br />
    </p>

    <p><strong><em>Notifications of Security Systems Breach</em></strong></p>

    <p>
    If we learn of a security systems breach, then we may attempt to notify you electronically so that you can take appropriate protective steps. We may also post a notice on or through the Site in the event of a security breach. Depending on where you live, you may have a legal right to receive notice of a security breach in writing.<br />
    </p>

    <p><strong><em>Take Precautionary Measures</em></strong></p>

    <p>
    You can take several precautions to protect the security of your computer and personal information. For instance, you can use a well-chosen password and restrict access to your email account. You can also install and regularly update antivirus and firewall software to protect your computer from external attacks by malicious users. When you are finished with a session on the Site, be sure that you log out and close the browser window.<br />
      <br />
      You should also be aware of fraudulent attempts to gain access to your account information through "phishing," whereby scammers try to bring unsuspecting people to a website by using a genuine-looking email purporting to be from a legitimate company. Sometimes, either in the email itself or on this fake site, scammers will ask for login information to gain access to people's accounts and withdraw their money.<br />
      <br />
      RealtyMogul will never send you an email asking you for your login information. In general, you can protect yourself against phishing by never providing personal or login information via a link contained in an email; instead, go the website directly. You might also make it a habit to check the URL of a website to be sure that it begins with the correct domain. In the case of RealtyMogul, you should always ensure the URL begins with <a href="https://realtymogul.com">https://realtymogul.com</a> or <a href="https://www.realtymogul.com">https://www.realtymogul.com</a>.
    </p>
  </>
);

export default PrivacyPolicy;
