import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Portal from './Portal';

const DripPercentageDrupalWrapper = ({
  dripPercentageMR1 = '69',
  dripPercentageMR2 = '79',
}) => {
  const [portalId, setPortalId] = useState(null);
  const [ioId, setIoId] = useState(193744);

  const drupal = typeof window !== 'undefined' && window?.Drupal;
  useEffect(() => {
    if (drupal) {
      // Get the initial setting from Drupal.
      // eslint-disable-next-line camelcase
      const initialPortalId = drupal?.settings?.rm_invest_flow?.amount_drip_percentage_portal_id;
      // eslint-disable-next-line camelcase
      const id = drupal?.settings?.rm_invest_flow?.io_id;
      setPortalId(initialPortalId);
      setIoId(id);
    }
  }, [drupal, portalId]);

  if (!portalId) {
    return null;
  }

  return (
    <Portal id={portalId}>
      <span>{ioId === 193744 ? dripPercentageMR1 : dripPercentageMR2}% of investors choose to reinvest their distributions.</span>
    </Portal>
  );
};

const mapStateToProps = (state) => ({
  dripPercentageMR1: state.tokens.dripPercentageMR1,
  dripPercentageMR2: state.tokens.dripPercentageMR2,
});

DripPercentageDrupalWrapper.propTypes = {
  dripPercentageMR1: PropTypes.string,
  dripPercentageMR2: PropTypes.string,
};

export default connect(mapStateToProps)(DripPercentageDrupalWrapper);
