/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import close from '../../assets/close_wht.svg';

import {
  ExtendedFlex,
  dataTypes,
  applyTextStyles,
} from '../../coreui';

/* eslint-disable indent */
const StyledAuthContainer = styled(ExtendedFlex)`
  [class^="Toast__toast__"] {
    color: ${themeGet('colors.white')};
    background: ${themeGet('colors.blue.0')};
    position: fixed;
    justify-content: center;

    > span {
      width: 90%;
      max-width: 1024px;

      ${({ theme }) => applyTextStyles({
        ...dataTypes.D5,
        theme,
      })}
    }
  }

  [class^="Toast__toastClose__"] {
    background: url(${close});
    width: 32px;
    height: 28px;
    position: absolute;
    right: 40px;

    &::before,
    &::after {
      display: none;
    }
  }
`;
/* eslint-enable indent */

const AuthContainer = ({
  // eslint-disable-next-line react/prop-types
  theme,
  ...props
}) => {
  return (
    <StyledAuthContainer
      flexDirection="column"
      w={1}
      {...props}
    />
  );
};

export default AuthContainer;
