export const selectFilters = (state) => {
  return {
    taxYear: state.taxcenter.taxYear,
    docType: state.taxcenter.docType,
    account: state.taxcenter.account,
  };
};

export const selectDocumentsChecked = (state) => {
  return state.taxcenter.data.reduce((accum, item) => {
    return [
      ...accum,
      ...(item.selectBox.selected ? [item.selectBox.id] : []),
    ];
  }, []);
};

export default {
  selectFilters,
  selectDocumentsChecked,
};
