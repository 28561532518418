const PRIVATE_PLACEMENT = [
  {
    title: 'Available to accredited investors only',
    titleToolTip: true,
    path: '/investment-opportunities',
    status: 'status-10-open',
    statusLabel: 'Open for investment',
    assetCount: 1,
    REIT: false,
    imageName: 'img_card_locked',
    propertyLocation: 'Private Placements',
    investmentType: 'REIT',
    investmentTypeDisplay: 'Equity',
    targetHoldPeriod: null,
    minimum: 'Varied',
    propertyType: 'Varied',
    targetHoldMin: null,
    targetHoldMax: 'Varied',
    payout: 1,
    includeProgress: true,
    blurb: 'Individual properties fully vetted with the investment objective of capital appreciation and income.  Apply for access to Private Placements during registration.',
    blurContent: true,
  },
];

export default {
  hero: {
    header: 'YOUR MONEY DESERVES MORE',
    subHeader: 'Commercial Real Estate Can Augment Your Investing Strategy',
  },
  passiveIncome: {
    heading: 'Adding Passive Income To Your Portfolio',
    body: [
      'Passive income is exactly what it sounds like: periodic disbursements without significant effort beyond your initial investment.',
      'While real estate has traditionally been considered a passive investment strategy, actively owning rental properties generally requires considerable effort, including overseeing the purchase, performing inspections, finding tenants and ongoing property management and maintenance.',
      'On the RealtyMogul platform, investors can invest in a variety of commercial real estate deals located across the country. The real estate companies manage the properties for you, providing a truly passive real estate investing experience.',
    ],
  },
  passiveIos: {
    heading: 'Passive Income Opportunities Available Today',
    investments: PRIVATE_PLACEMENT,
  },
  distributions: {
    heading: 'STEADY MONTHLY RENTAL INCOME MAY MAKE REAL ESTATE AN IDEAL CASH-FLOW INVESTMENT',
    body: [
      'The potential production of steady monthly rental income can make real estate an ideal cash-flow investment.',
    ],
    legend: 'PROPERTY VALUE FINANCED VIA THE REALTYMOGUL PLATFORM',
  },
  growWealth: {
    heading: 'A STRATEGY TO GROW WEALTH',
    body: [
      'A traditional growth strategy leverages investments that may increase in value over the long haul. For buy-and-hold real estate investors, this means investing in properties with potential for appreciation, with the intention of profiting at the time of sale. Additionally, ideal properties may even offer quarterly distributions over the course of ownership.',
    ],
  },
  growthIos: {
    heading: 'Growth Opportunities Available Today',
    investments: PRIVATE_PLACEMENT,
  },
  growthGoals: {
    heading: 'SEE HOW REAL ESTATE CAN HELP ACHIEVE GROWTH GOALS',
    body: [
      'With potentially lower volatility than the stock market and higher historic long-term gains, real estate investing can be attractive to growth investors.  In select investments offered on the RealtyMogul platform, distributions can be reinvested to leverage the potential of compound interest.',
    ],
    legend: 'Annualized Returns (%) as of 12/31/20',
    disclosure: [
      'Bloomberg Barclays US Aggregate Bont TR USD data source from MorningStar',
      '(https://performance.morningstar.com/Performance/index-c/performance-return.action?t=XIUSA000MC).',
      'S&P 500 Index and NARIT Equity REIT Index data sourced from NCREIF (NCREIF.org)',
    ],
  },
  diversifiedPortfolio: {
    heading: 'CREATE A DIVERSIFIED PORTFOLIO',
    body: [
      'Many investors believe that they are protected against public market volatility since their portfolio includes a range of asset classes, such as stocks and bonds. However, to achieve genuine diversification, investors may consider adding primary alternatives like real estate. RealtyMogul offers both real estate investment trusts (REITs) and private placement deals — assets not traded on--nor tied to--the stock market.',
    ],
  },
  diversifyIos: {
    heading: 'Diversification Opportunities Available Today',
    investments: PRIVATE_PLACEMENT,
  },
  diversified: {
    heading: 'WHAT DOES A DIVERSIFIED PORTFOLIO LOOK LIKE?',
    body: [
      'There are a variety of methods investors may use to determine their ideal portfolio allocation. But too often portfolios leave out an important asset class – commercial real estate. While the appropriate allocation of CRE will vary by investor based on their age, financial goals, or income, real estate is an important part of a diversified portfolio.',
    ],
    legend: 'Efficient Frontier: Traditional Investments With & Without Alternatives',
    disclosure: [
      'Based on the returns of the S&P 500 Index, Barclays U.S. Aggregate Bond Index, and the NCREIF (National Council of Real Estate Investment Fiduciaries) Index, and with and without an asset allocation to direct real estate, over an 11-year time period (2006-2016).',
      'Each index provides a broad representation of a particular asset class and is not indicative of any investment or a projection of performance.  Asset allocation does not ensure a profit or protect against a loss. The rates of returns shown do not reflect the deduction of fees and expenses inherent in investing.',
    ],
  },
  recentInvestments: {
    subTitle: 'CONFIDENT INVESTING IS BUILT ON TRANSPARENCY AND DUE DILIGENCE',
  },
};
