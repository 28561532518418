import {
  call,
  takeEvery,
} from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import { datadogRum } from '@datadog/browser-rum';

import appTypes from '../../appWrapper/types';
import { isMockSession } from '../../auth/api';

export function* getUserDataFlow(action) {
  yield call(async () => {
    const {
      id: drupalId,
      firstName,
      lastName,
      email,
      isNonAuthenticated,
      isLoggedIn,
      isStaff,
      accreditationRoleStatus,
      userSwitch,
    } = action.user;

    if (isLoggedIn) {
      let cognitoSub;

      if (!isMockSession()) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        cognitoSub = cognitoUser?.attributes?.sub;
      }

      datadogRum.setUser({
        id: cognitoSub || drupalId,
        drupalId,
        name: `${firstName} ${lastName}`,
        email,
        emailVerified: !isNonAuthenticated,
        isStaff,
        accreditationRoleStatus,
        masquerading: !!userSwitch,
      });
    } else {
      datadogRum.clearUser();
    }
  });
}

export function* watchUpdateUserRequested() {
  yield takeEvery(
    appTypes.GET_USER_DATA_SUCCEEDED,
    getUserDataFlow,
  );
}

export default watchUpdateUserRequested;
