export const enrollmentSteps = [
  {
    label: 'Info',
  },
  {
    label: 'Suitability',
  },
  {
    label: 'Investment',
  },
  {
    label: 'Confirmation',
  },
];

export const enrollmentCancelBody = 'Your progress will be lost and your enrollment will be cancelled.';

export const isqProps = [
  'primaryInvestmentReason',
  'primaryInvestmentReasonFollowUp',
  'expStocks',
  'expBonds',
  'expRealEstate',
  'expPrivateEquity',
  'expVentureCapital',
  'expSmallBiz',
  'experienceFollowUp',
  'exit',
  'exitFollowUp',
  'risk',
  'riskFollowUp',
  'annualIncome',
  'netWorth',
  'netWorthSpecific',
  'sourceOfWealth',
  'sourceOfIncome',
  'employmentType',
  'employer',
  'employmentPositionHeld',
  'employmentIndustry',
  'sameWorkAddress',
  'employerAddress',
];

export default {
  enrollmentSteps,
  enrollmentCancelBody,
};
