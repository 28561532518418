import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, matchPath } from 'react-router-dom';

import {
  ExtendedFlex,
  NavIcon,
  Svg,
  isDrupal,
} from '../../coreui';

import DashboardDropdown from './DashboardDropdown';
import InvestmentsNavIcon from './nav/Investments';
import AutoInvestNavIcon from './nav/AutoInvest';
import TransactionsNavIcon from './nav/Transactions';
import MessagesNavIcon from './nav/Messages';
import TaxcenterNavIcon from './nav/TaxCenter';
import ProfileNavIcon from './nav/Profile';
import links from '../links';

const linkToIcon = {
  investments: InvestmentsNavIcon,
  autoInvest: AutoInvestNavIcon,
  transactions: TransactionsNavIcon,
  messages: MessagesNavIcon,
  taxcenter: TaxcenterNavIcon,
  profile: ProfileNavIcon,
};

const getRouterProp = (item) => {
  return (item.external || isDrupal()) ? 'href' : 'to';
};

const DashboardNavLink = ({
  location,
}) => {
  const linksWithIcons = links.links.map((item) => {
    return {
      ...item,
      icon: linkToIcon[item.id],
    };
  });
  return (
    <ExtendedFlex
      width="100%"
    >
      <ExtendedFlex
        justifyContent="space-between"
        width="100%"
        py={[0, 30, 55]}
        display={['none', 'flex']}
        maxWidth={1440}
        mx="auto"
      >
        {
          linksWithIcons.map((item) => {
            const Image = item.icon;

            const active = item.sublinks.filter((sublink) => {
              return sublink === location.pathname || matchPath(location.pathname, {
                path: sublink,
              });
            }).length > 0;

            return (
              <NavIcon
                key={`DashboardNavIcon:${item.label}:${item.value}`}
                active={active}
                {...{
                  [getRouterProp(item)]: item.value,
                }}
                label={item.label}
                icon={
                  <Svg>
                    <Image />
                  </Svg>
                }
              />
            );
          })
        }
      </ExtendedFlex>
      <ExtendedFlex
        width="100%"
        display={['flex', 'none']}
        justifyContent="center"
      >
        <DashboardDropdown
          links={linksWithIcons}
        />
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

DashboardNavLink.propTypes = {
  location: PropTypes.object,
};

export default withRouter(DashboardNavLink);
