import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  Data,
} from '../../..';

const InvestmentCardDetails = ({
  text,
}) => {
  return (
    <ExtendedBox
      pt={20}
      px={10}
      position="absolute"
      bottom="20px"
    >
      <Data
        type="D4"
      >
        {text}
      </Data>
    </ExtendedBox>
  );
};

InvestmentCardDetails.propTypes = {
  text: PropTypes.string,
};

InvestmentCardDetails.defaultProps = {
  text: '',
};

export default InvestmentCardDetails;
