/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  ScrollToTopOnMount,
  UnlockOpportunities,
  Hero,
  ModalWithOverlay,
} from '../coreui';

import SubNav from '../components/subNav';
import PassiveIncome from './components/passiveIncome';
import DiversifiedPortfolio from './components/diversifiedPortfolio';
import ChartAndCopy from './components/chartAndCopy';
import GrowWealth from './components/growWealth';
import RecentInvestments from '../homepage/recentInvestments';
import RelatedQueueContent from '../knowledgeCenter/components/RelatedQueueContent';
import AvailableToday from './components/availableToday';
import heroBannerImage from '../assets/BOI_hero.jpg';
import chartGrowth from '../assets/chart_growth.png';
import chartDiversified from '../assets/chart_diversified.jpg';
import ScrollToHash from '../components/ScrollToHash';
import browseActions from '../browse/browsePage/actions';

class Benefits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      modalContent: null,
      navItems: [
        { title: 'Earn Passive Income', name: 'passive-income' },
        { title: 'Grow Value', name: 'grow-value' },
        { title: 'Diversify Your Holdings', name: 'diversified-portfolio' },
      ],
    };

    this._subNav = React.createRef();
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(modalContent) {
    this.setState((state) => ({
      isModalVisible: !state.isModalVisible,
      modalContent: state.isModalVisible ? null : modalContent,
    }));
  }

  componentDidMount() {
    this.props.getBrowsePageDataRequested();
  }

  render() {
    const {
      user,
      hero,
      passiveIncomeData,
      distributionsData,
      diversifiedPortfolioData,
      growWealthData,
      growthGoalsData,
      diversifiedData,
      passiveIoData,
      growthIoData,
      diversifyIoData,
      reitData,
      recentInvestments,
      totalPropertyValueFinanced,
      recentInvestmentsData,
    } = this.props;

    const {
      isModalVisible,
      modalContent,
      navItems,
    } = this.state;

    return (
      <>
        <ScrollToTopOnMount />
        <Hero
          image={heroBannerImage}
          header={hero.header}
          subHeader={hero.subHeader}
        />
        <SubNav
          ref={(el) => { this._subNav = el; }}
          items={navItems}
        />
        <ScrollToHash
          name="passive-income"
        >
          <PassiveIncome
            {...passiveIncomeData}
            name="passive-income"
          />
        </ScrollToHash>
        <AvailableToday
          heading={passiveIoData.heading}
          investments={[reitData.mr1, ...passiveIoData.investments]}
          user={user}
        />
        <ChartAndCopy
          {...distributionsData}
          bigNumber={{ number: totalPropertyValueFinanced, type: 'money' }}
          toggleModal={this.toggleModal}
          isLoggedIn={user.isLoggedIn}
        />
        <ScrollToHash
          name="grow-value"
        >
          <GrowWealth
            {...growWealthData}
            name="grow-value"
          />
        </ScrollToHash>
        <AvailableToday
          heading={growthIoData.heading}
          investments={[reitData.mr2, ...growthIoData.investments]}
          user={user}
        />
        <ChartAndCopy
          {...growthGoalsData}
          chart={chartGrowth}
          toggleModal={this.toggleModal}
          isLoggedIn={user.isLoggedIn}
        />
        <ScrollToHash
          name="diversified-portfolio"
        >
          <DiversifiedPortfolio
            {...diversifiedPortfolioData}
            name="diversified-portfolio"
          />
        </ScrollToHash>
        <AvailableToday
          heading={diversifyIoData.heading}
          investments={[reitData.mr1, reitData.mr2, ...diversifyIoData.investments]}
          user={user}
        />
        <ChartAndCopy
          {...diversifiedData}
          chart={chartDiversified}
          toggleModal={this.toggleModal}
          isLoggedIn={user.isLoggedIn}
        />
        <RelatedQueueContent
          title="Gain New Investing Insight"
          bg="gray.1"
          queue="benefits_articles"
        />
        <RecentInvestments
          subtitle={recentInvestments.subTitle}
          recentInvestmentsData={recentInvestmentsData}
        />
        <UnlockOpportunities
          isLoggedIn={user.isLoggedIn}
        />
        <ModalWithOverlay
          isVisible={isModalVisible}
          handleClick={this.toggleModal}
        >
          {modalContent}
        </ModalWithOverlay>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user,
  hero: state.benefits.hero,
  distributionsData: state.benefits.distributions,
  growthGoalsData: state.benefits.growthGoals,
  diversifiedData: state.benefits.diversified,
  passiveIncomeData: state.benefits.passiveIncome,
  diversifiedPortfolioData: state.benefits.diversifiedPortfolio,
  growWealthData: state.benefits.growWealth,
  passiveIoData: state.benefits.passiveIos,
  growthIoData: state.benefits.growthIos,
  diversifyIoData: state.benefits.diversifyIos,
  reitData: state.reitData.data,
  recentInvestments: state.benefits.recentInvestments,
  totalPropertyValueFinanced: state.tokens.totalPropertyValueFinanced,
  recentInvestmentsData: _.orderBy(state.pastInvestments.completedEquityInvestments, ({ actualMaturityDate }) => actualMaturityDate || '', ['desc']).slice(0, 8),
});

const mapDispatchToProps = (dispatch) => ({
  getBrowsePageDataRequested: () => {
    dispatch(browseActions.getBrowseClosedDataRequested());
  },
});

Benefits.propTypes = {
  user: PropTypes.object,
  hero: PropTypes.shape(Hero.propTypes),
  distributionsData: PropTypes.shape(ChartAndCopy.propTypes),
  passiveIncomeData: PropTypes.shape(PassiveIncome.propTypes),
  diversifiedPortfolioData: PropTypes.shape(DiversifiedPortfolio.propTypes),
  growWealthData: PropTypes.shape(GrowWealth.propTypes),
  growthGoalsData: PropTypes.shape(ChartAndCopy.propTypes),
  diversifiedData: PropTypes.shape(ChartAndCopy.propTypes),
  passiveIoData: PropTypes.shape(AvailableToday.propTypes),
  growthIoData: PropTypes.shape(AvailableToday.propTypes),
  diversifyIoData: PropTypes.shape(AvailableToday.propTypes),
  reitData: PropTypes.object,
  recentInvestments: PropTypes.object,
  totalPropertyValueFinanced: PropTypes.number,
  recentInvestmentsData: PropTypes.array,
  getBrowsePageDataRequested: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Benefits);
