/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Heading,
  Data,
} from '../../coreui';

class CheckboxGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: undefined,
    };

    this.formRef = undefined;
    this.handleFormValidate = this.handleFormValidate.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      valid: state.valid === undefined ? undefined : props.value.length > 0,
    };
  }

  componentWillUnmount() {
    if (this.formRef) {
      this.formRef.removeEventListener('validate', this.handleFormValidate);
    }
  }

  handleFormValidate(event) {
    const {
      value,
    } = this.props;

    if (!value.length) {
      this.setState({
        valid: false,
      });
      event.preventDefault();
    } else {
      this.setState({
        valid: true,
      });
    }
  }

  render() {
    const {
      name,
      value,
      children,
      inputProps,
      required,
      onChange,
      isValid,
      ...props
    } = this.props;
    const {
      valid,
    } = this.state;
    let counter = 0;

    return (
      <ExtendedFlex
        flexDirection="column"
        {...props}
      >
        {
          React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
              return child;
            }

            const {
              type: {
                displayName,
              },
            } = child;
            if (displayName === 'CheckboxLabel') {
              counter += 1;
              return React.cloneElement(child, {
                key: child.props.value,
                zebra: counter % 2 ? 'odd' : 'even',
                py: [15, null, 21, null, 28],
                display: 'flex',
                alignItems: 'center',
                checked: value
                  && Array.isArray(value)
                  && !!value.find((val) => val === child.props.value),
                onChange,
                inputProps: {
                  name,
                  ...inputProps,
                  ref: required ? (ref) => {
                    // Workaround to get a reference to the form.
                    if (ref && this.formRef === undefined) {
                      this.formRef = ref.form;
                      this.formRef.addEventListener('validate', this.handleFormValidate);
                    }
                  } : ((inputProps && inputProps.ref) || undefined),
                },
                checkboxProps: {
                  my: 0,
                  ml: [10, null, 30],
                  mr: [15, null, 25],
                },
                ...child.props,
                label: typeof child.props.label === 'string' ? (
                  <Heading
                    type="H7"
                  >
                    {child.props.label}
                  </Heading>
                ) : child.props.label,
              });
            }
            return child;
          })
        }
        {
          required && (valid === false || isValid === false) &&
          <Data
            type="D4"
            display="block"
            my={2}
            color="red"
          >
            Please select a value.
          </Data>
        }
      </ExtendedFlex>
    );
  }
}

CheckboxGroup.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.array,
  required: PropTypes.bool,
  children: PropTypes.array,
  inputProps: PropTypes.object,
  isValid: PropTypes.bool,
};

CheckboxGroup.defaultProps = {
  value: [],
};

export default CheckboxGroup;
