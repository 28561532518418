import React, { useEffect, useState, useCallback } from 'react';
import { createPlaidLinkToken } from '../../api';

import PlaidLinkWrapper from './PlaidLinkWrapper';
import Portal from './Portal';

const PlaidLinkDrupalWrapper = () => {
  const [plaidId, setPlaidId] = useState();
  const [token, setToken] = useState(null);
  const [noRedirectUri, setNoRedirectUri] = useState();
  const [receivedRedirectUri, setReceivedRedirectUri] = useState();
  const [openAutomatically, setOpenAutomatically] = useState();
  const [submitting, setSubmitting] = useState(false);

  const generateToken = useCallback(async () => {
    const data = noRedirectUri ? { noRedirectUri: true } : undefined;
    const response = await createPlaidLinkToken(data);
    setToken(response.data.link_token);
  }, [noRedirectUri]);

  // Determine if we should be showing Plaid.
  const drupal = typeof window !== 'undefined' && window?.Drupal;
  useEffect(() => {
    if (drupal) {
      // Get the initial setting from Drupal.
      // eslint-disable-next-line camelcase
      const initialPlaidId = drupal?.settings?.rm_invest_flow?.plaid_id;
      setPlaidId(initialPlaidId);

      // eslint-disable-next-line camelcase
      const noRedirectUri = drupal?.settings?.rm_invest_flow?.plaid_no_redirect_uri;
      setNoRedirectUri(noRedirectUri);

      // Bind to Drupal behavior's to get the settings updates.
      drupal.behaviors.plaidLinkDrupalWrapper = {
        attach: (context, settings) => {
          if (typeof settings !== 'undefined') {
            // eslint-disable-next-line camelcase
            const noRedirectUri = settings?.rm_invest_flow?.plaid_no_redirect_uri;
            setNoRedirectUri(noRedirectUri);

            // eslint-disable-next-line camelcase
            const newPlaidId = typeof settings !== 'undefined' && settings?.rm_invest_flow?.plaid_id;

            // Make sure the element is available in the context. This is after
            // the HTML updates have already been run. Without this, we can
            // sometimes run before the HTML element is on page because it's in
            // a subsequent Drupal.attachBehaviors().
            if (newPlaidId && !plaidId) {
              const $ = window.jQuery;
              const existingParent = $(context).find(`#${newPlaidId}`);
              if (!existingParent.length) {
                return;
              }
            }

            setPlaidId(newPlaidId);

            // eslint-disable-next-line camelcase
            const newReceivedRedirectUri = typeof settings !== 'undefined' && settings?.rm_invest_flow?.plaid_redirect_uri;
            setReceivedRedirectUri(newReceivedRedirectUri === false ? undefined : newReceivedRedirectUri);

            // eslint-disable-next-line camelcase
            const newPlaidToken = typeof settings !== 'undefined' && settings?.rm_invest_flow?.plaid_link_token_data;
            // eslint-disable-next-line camelcase
            setToken(newPlaidToken === false ? undefined : newPlaidToken?.link_token);

            // If the root changes, we can't be submitting anymore.
            if (plaidId && plaidId !== newPlaidId) {
              setSubmitting(false);
            }
          }
        },
      };
    }

    return () => {
      if (drupal) {
        delete drupal.behaviors.plaidLinkDrupalWrapper;
      }
    };
  }, [drupal, plaidId]);

  useEffect(() => {
    if (typeof plaidId !== 'undefined' && !token) {
      // Check if Drupal passed us the token.
      // eslint-disable-next-line camelcase
      const initialTokenData = drupal?.settings?.rm_invest_flow?.plaid_link_token_data?.link_token;
      if (initialTokenData) {
        // Check if Drupal passed us the redirect_uri.
        // eslint-disable-next-line camelcase
        const initialReceivedRedirectUri = drupal?.settings?.rm_invest_flow?.plaid_redirect_uri;

        if (initialReceivedRedirectUri) {
          setReceivedRedirectUri(initialReceivedRedirectUri);
          setToken(initialTokenData);
          setOpenAutomatically(true);
          return;
        }
      }

      generateToken();
    }
  }, [drupal, plaidId, token, generateToken]);

  const onSuccess = useCallback((publicToken, { account_id: accountId }) => {
    // Pass the public token back to Drupal by triggering a Drupal AJAX form
    // submit.
    const $ = window.jQuery;
    $('.rtml-plaid-public-token').val(publicToken);
    $('.rtml-plaid-account-id').val(accountId);
    $('.rtml-plaid-iav-submit').trigger('mousedown');
    setSubmitting(true);
    setOpenAutomatically(undefined);
  }, []);

  const onExit = useCallback((error) => {
    if (error) {
      // Start over.
      setReceivedRedirectUri(undefined);
      setOpenAutomatically(undefined);

      generateToken();
    }
  }, [generateToken]);

  return (plaidId ? (
    <Portal id={plaidId}>
      <PlaidLinkWrapper
        onSuccess={onSuccess}
        onExit={onExit}
        loading={submitting}
        disabled={submitting}
        token={token}
        receivedRedirectUri={receivedRedirectUri}
        openAutomatically={openAutomatically}
      />
    </Portal>
  ) : null);
};

export default PlaidLinkDrupalWrapper;
