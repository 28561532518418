/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Theme,
} from '../../../coreui';

import AutoInvestSectionWrapperToggle from './AutoInvestSectionWrapperToggle';

class AutoInvestSectionWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: props.initialIsExpanded && props.expandable,
    };
    this.handleExpandChange = this.handleExpandChange.bind(this);
  }

  handleExpandChange() {
    if (this.props.expandable) {
      this.setState((state) => ({
        isExpanded: !state.isExpanded,
      }));
    }
  }

  render() {
    const {
      children,
      title,
      toggleProps,
      contentWidth,
      expandable,
    } = this.props;
    return (
      <ExtendedBox
        width={1}
        maxWidth={1700}
      >
        <ExtendedFlex
          width="auto"
          borderTop={`3px solid ${Theme.colors.blue[0]}`}
          borderBottom={(this.state.isExpanded === true) ? `1px solid ${Theme.colors.gray[7]}` : 'medium none'}
          py={['10px']}
          px={[0, 0, 20]}
          mx={50}
        >
          <ExtendedFlex
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            minHeight={[45, 63, 65, 67, 73]}
          >
            <ExtendedFlex
              flexWrap="wrap"
              boxSizing="border-box"
              onClick={this.handleExpandChange}
              hover={expandable}
            >
              <AutoInvestSectionWrapperToggle
                {...toggleProps}
                expandable={expandable}
                isExpanded={this.state.isExpanded}
              />
              <Heading
                type="H8"
                flex={[null, 1]}
              >
                {title}
              </Heading>
            </ExtendedFlex>
          </ExtendedFlex>
        </ExtendedFlex>
        {
          this.state.isExpanded &&
          <ExtendedFlex
            width="auto"
            mx={50}
            flexDirection="column"
            alignItems="center"
          >
            <ExtendedFlex
              width={[
                contentWidth || 1,
                1,
              ]}
              flexDirection="column"
              alignItems="center"
            >
              {children}
            </ExtendedFlex>
          </ExtendedFlex>
        }
      </ExtendedBox>
    );
  }
}

AutoInvestSectionWrapper.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  contentWidth: PropTypes.string,
  initialIsExpanded: PropTypes.bool,
  toggleProps: PropTypes.object,
  expandable: PropTypes.bool,
};

AutoInvestSectionWrapper.defaultProps = {
  initialIsExpanded: true,
  expandable: true,
};

export default AutoInvestSectionWrapper;
