import Select from 'react-select';
import styled from 'styled-components';
import { Theme } from '..';

const FlatSelect = styled(Select)`
  .Select-control {
    border: 1px solid #202020;
    width: 200px;
    border-radius: 0px;
    font-family: ${(props) => Theme[props.fontFamily] || 'Helvetica, Arial, sans-serif'};
    height: 23px;
  }

  .Select-placeholder,
  &.Select--single > .Select-control .Select-value {
    line-height: 21px;
  }

  .Select-input {
    height: 21px;
  }

  .Select-placeholder {
    color: #202020;
  }
`;

export default FlatSelect;
