import React from 'react';
import PropTypes from 'prop-types';

import {
  Data,
  ExtendedBox,
  ExtendedFlex,
  Image,
  Theme,
} from '../../coreui';

import expandIcon from '../assets/images/icon_expand_wht.svg';
import collapseIcon from '../assets/images/icon_collapse_wht.svg';

import NotificationItem from './NotificationItem';

class NotificationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: true,
    };
    this.handleExpandChange = this.handleExpandChange.bind(this);
  }

  handleExpandChange() {
    this.setState((state) => ({
      isExpanded: !state.isExpanded,
    }));
  }

  render() {
    const numItems = this.props.data.length;
    const itemText = this.props.data.length > 1 ? 'items' : 'item';
    const notificationText = `You have ${numItems} ${itemText} that may require your attention.`;
    return (
      <ExtendedBox
        width="100%"
      >
        <ExtendedBox
          width="100%"
          bg={Theme.colors.blue[1]}
        >
          <ExtendedFlex
            height="117px"
            width={['280px', '100%']}
            maxWidth="1440px"
            justifyContent="space-between"
            mx="auto"
            alignItems="center"
            px={[0, 50]}
          >
            <Data
              type="D5"
              color={Theme.colors.white}
            >
              Notifications: {notificationText}
            </Data>
            <ExtendedFlex>
              <ExtendedFlex
                display={['none', 'flex']}
              >
                <Data
                  type="D1"
                  color={Theme.colors.white}
                  pr={10}
                >
                  SHOW
                </Data>
              </ExtendedFlex>
              {
                this.state.isExpanded &&
                <Image
                  src={expandIcon}
                  onClick={this.handleExpandChange}
                />
              }
              {
                !this.state.isExpanded &&
                <Image
                  src={collapseIcon}
                  onClick={this.handleExpandChange}
                />
              }
            </ExtendedFlex>
          </ExtendedFlex>
        </ExtendedBox>
        <ExtendedBox
          display={this.state.isExpanded === false ? 'block' : 'none'}
          bg={Theme.colors.gray[0]}
        >
          <ExtendedFlex
            width="100%"
            justifyContent="center"
          >
            {
              this.props.data.map((item) => {
                return (
                  <NotificationItem
                    label={item.title}
                    message={item.msg}
                    url={item.url}
                    action={item.action}
                    cancel={item.cancel}
                    isLast={false}
                  />
                );
              })
            }
          </ExtendedFlex>
        </ExtendedBox>
      </ExtendedBox>
    );
  }
}

NotificationBar.propTypes = {
  data: PropTypes.array,
};

export default NotificationBar;
