import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  Flex,
  MiniEssay,
  VisibilitySensor,
  Theme,
} from '../coreui';

import DynamicBrowseJoinLink from '../helpers';

const paragraphsOfText = [
  'RealtyMogul members get access to institutional-quality multifamily, office, retail and other commercial real estate deals in dozens of markets across the country.',
];

class BeyondTraditionalInvesting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible: true,
      });
    }
  }

  render() {
    const {
      isVisible,
    } = this.state;

    const {
      isLoggedIn,
    } = this.props;

    return (
      <ExtendedBox
        bg="gray.0"
        py={[60, 80, 100, 120, 140]}
      >
        <Flex
          flexDirection="column"
          align="center"
          justify="center"
        >
          <VisibilitySensor
            callback={this.handleSensorChange}
          />
          <MiniEssay
            header="Beyond Traditional Investing"
            paragraphsOfText={paragraphsOfText}
            animatedFadeIn
            isVisible={isVisible}
          />
          <ExtendedBox
            mt={[30, 40, 50, 60, 80]}
            opacity={isVisible ? '1' : '0'}
            transition={`opacity ${Theme.fadeInDelay}ms 1.5s linear`}
          >
            <DynamicBrowseJoinLink
              isLoggedIn={isLoggedIn}
              type="L1"
            />
          </ExtendedBox>
        </Flex>
        <VisibilitySensor
          callback={this.handleSensorChange}
        />
      </ExtendedBox>
    );
  }
}

BeyondTraditionalInvesting.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default BeyondTraditionalInvesting;
