export default {
  overview: {
    subtitle: `By using the Realty Mogul technology and platform, you get access to a network of accredited investors looking for institutional-quality commercial real estate offerings. 
    <br/><br/>
    The RealtyMogul team will support you throughout the lifecycle of your project, giving you the resources you need to promote your offer as well as providing administrative services to help you manage the investors. 
    `,
  },
  dedicated: {
    heading: 'A DEDICATED PARTNER',
    body: 'After closing, a RealtyMogul affiliate stays on for the life of the transaction to provide administrative services to help you manage the investors - from cash distributions to incoming questions to annual tax document distribution. We will assign a dedicated asset management professional to be your primary point of contact. Should any decision-making items arise over the course of property operations, this person will be a resource to help answer any questions and help you manage your new investors.',
    link: 'LAUNCH YOUR NEXT PROJECT >',
    href: '/sponsor-application',
  },
};
