import React from 'react';
import PropTypes from 'prop-types';
import {
  withRouter,
} from 'react-router-dom';

import ErrorBoundary from './ErrorBoundary';
import AppWrapper from './appWrapper';

const App = ({
  children,
}) => {
  return (
    <ErrorBoundary>
      <AppWrapper>
        {children}
      </AppWrapper>
    </ErrorBoundary>
  );
};

App.propTypes = {
  children: PropTypes.object,
};

export default withRouter(App);
