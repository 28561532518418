/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { injectGlobal } from 'styled-components';

import { ExtendedBox } from '..';

export const contentCSS = injectGlobal`
  @import url("https://use.typekit.net/zuh8ztw.css");
  @import url("https://cloud.typography.com/7455036/7907792/css/fonts.css");

  .rm-coreui-content h1,
  .rm-coreui-content h2,
  .body4 {
    margin-top: 40px;
    margin-bottom: 40px;
    color: #202020;
    font-weight: normal;
    text-transform: none;
    font-style: normal;
    letterSpacing: normal;
    font-family: mrs-eaves-xl-serif-narrow, serif;
    line-height: 1.5;
    font-size: 20px;
    @media screen and (min-width: 40em) {
      font-size: 20px;
    }
    @media screen and (min-width: 52em) {
      font-size: 26px;
    }
    @media screen and (min-width: 64em) {
      font-size: 26px;
    }
    @media screen and (min-width: 120em) {
      font-size: 30px;
    }
  }

  .rm-coreui-content h3,
  .rm-coreui-content table caption,
  .headline8 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    letterSpacing: 0.150em;
    font-size: 13px;
    @media screen and (min-width: 40em) {
      font-size: 13px;
    }
    @media screen and (min-width: 52em) {
      font-size: 14px;
    }
    @media screen and (min-width: 64em) {
      font-size: 16px;
    }
    @media screen and (min-width: 120em) {
      font-size: 18px;
    }
  }

  .rm-coreui-content table th {
    text-align: left;
  }

  .rm-coreui-content a,
  .rm-coreui-content p a {
    color: #176DB6;
    text-decoration: none;
  }

  .rm-coreui-content p,
  .body1 {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .rm-coreui-content a,
  .rm-coreui-content ul li,
  .rm-coreui-content ol li,
  .rm-coreui-content p,
  .rm-coreui-content table td,
  .body1 {
    color: #202020;
    font-weight: 300;
    text-transform: none;
    font-style: normal;
    letterSpacing: normal;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    line-height: 1.75;
    font-size: 13px;
    @media screen and (min-width: 40em) {
      font-size: 13px;
    }
    @media screen and (min-width: 52em) {
      font-size: 14px;
    }
    @media screen and (min-width: 64em) {
      font-size: 16px;
    }
    @media screen and (min-width: 120em) {
      font-size: 18px;
    }
  }

  .rm-coreui-content h4,
  .rm-coreui-content h5,
  .rm-coreui-content h6,
  .rm-coreui-content table th,
  .rm-coreui-content table tbody tr.summary-row td,
  .data5 {
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    font-weight: 700;
    text-transform: none;
    letter-spacing: normal;
    line-height: 2.1;
    font-size: 11px;
    @media screen and (min-width: 40em) {
      font-size: 11px;
    }
    @media screen and (min-width: 52em) {
      font-size: 12px;
    }
    @media screen and (min-width: 64em) {
      font-size: 13px;
    }
    @media screen and (min-width: 120em) {
      font-size: 16px;
    }
   }

  .rm-coreui-content table tr.summary-row td {
    background-color: #EDEDED;
  }

  .rm-coreui-content glossary a,
  .rm-coreui-content glossary ul li,
  .rm-coreui-content glossary ol li,
  .rm-coreui-content glossary p {

  }

  .rm-coreui-content table {
    @media (min-width: 768px) {
        overflow-x: scroll;
      }
  }
  .rm-coreui-content table tbody {}
  .rm-coreui-content table td,
  .rm-coreui-content table th {
    padding: 16px;
  }
  .rm-coreui-content table head th {}
  .rm-coreui-content table caption {}
  .rm-coreui-content table tr {
    min-height: 50px;
  }

  .rm-coreui-content table caption {
    text-align: left;
    padding-left: 40px;
  }
  .rm-coreui-content table {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    border-collapse: collapse;
  }
  .rm-coreui-content table th,
  .rm-coreui-content table tr:nth-child(even) {
    background-color: rgb(248, 248, 248);
  }

  .rm-coreui-content table tr th:first-child,
  .rm-coreui-content table tr td:first-child {
    padding-left: 40px;
  }
  .rm-coreui-content table tr th:last-child,
  .rm-coreui-content table tr td:last-child {
    padding-right: 40px;
  }
`;

const Content = (props) => {
  const { children, ...passedProps } = props;
  return (
    <ExtendedBox
      w={1}
      {...passedProps}
      className="rm-coreui-content"
    >
      { children }
    </ExtendedBox>
  );
};

Content.propTypes = {
  children: PropTypes.any,
};

export default Content;
