import React from 'react';
import { ExtendedFlex } from '../coreui';

const TimelineWrapper = (props) => {
  return (
    <ExtendedFlex
      justify="center"
      w={1}
    >
      <ExtendedFlex
        flexDirection="column"
        maxWidth={1200}
        // eslint-disable-next-line
        {...props}
      />
    </ExtendedFlex>
  );
};

export default TimelineWrapper;
