export default {
  USER_ACCREDITATION_NEW_REQUESTED: 'USER_ACCREDITATION_NEW_REQUESTED',
  USER_ACCREDITATION_NEW_SUCCEEDED: 'USER_ACCREDITATION_NEW_SUCCEEDED',
  USER_ACCREDITATION_NEW_FAILED: 'USER_ACCREDITATION_NEW_FAILED',
  USER_GETTING_TO_KNOW_YOU_DRAFT_REQUESTED: 'USER_GETTING_TO_KNOW_YOU_DRAFT_REQUESTED',
  USER_GETTING_TO_KNOW_YOU_DRAFT_SUCCEEDED: 'USER_GETTING_TO_KNOW_YOU_DRAFT_SUCCEEDED',
  USER_GETTING_TO_KNOW_YOU_DRAFT_FAILED: 'USER_GETTING_TO_KNOW_YOU_DRAFT_FAILED',
  USER_GETTING_TO_KNOW_YOU_REQUESTED: 'USER_GETTING_TO_KNOW_YOU_REQUESTED',
  USER_GETTING_TO_KNOW_YOU_SUCCEEDED: 'USER_GETTING_TO_KNOW_YOU_SUCCEEDED',
  USER_GETTING_TO_KNOW_YOU_FAILED: 'USER_GETTING_TO_KNOW_YOU_FAILED',
};
