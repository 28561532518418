import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
  NavLink,
} from '../../../coreui';

import FadeIn from '../../../components/FadeIn';

const IraInvesting2 = ({
  isVisible,
}) => {
  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
    >
      <ExtendedFlex
        flexDirection="column"
      >
        <ExtendedBox
          m={0}
          pb={30}
          bg="gray.1"
        >
          <ExtendedFlex
            flexDirection="column"
            justify="center"
            maxWidth={[null, null, 520, 520, 660]}
            height={[400, 300, 400, 400, 500]}
            px={['15%', '25%', 60, 100, 120]}
          >
            <FadeIn>
              <Heading
                type="H3"
                textTransform="uppercase"
                m={0}
                mb="0.7em"
                opacity={isVisible ? '1' : '0'}
              >
                IRA INVESTING
              </Heading>
              <Body
                type="B1"
                m={0}
                mb="4em"
                opacity={isVisible ? '1' : '0'}
              >
                On the RealtyMogul platform, you have the option to invest into one of the REITs using a Self-Directed Individual Retirement Account (SD-IRA) either by rolling over funds from an existing IRA or opening a new IRA, directly with us.
              </Body>
              <NavLink
                type="L4"
                to="/knowledge-center/retirement-investing"
                opacity={isVisible ? '1' : '0'}
              >
                Learn more &gt;
              </NavLink>
            </FadeIn>
          </ExtendedFlex>
        </ExtendedBox>
        <ExtendedBox
          mb={10}
        >
          <ExtendedFlex
            flexDirection="column"
            justify="center"
            maxWidth={[null, null, 520, 520, 660]}
            height={[400, 400, 400, 500, 500]}
            px={['15%', '25%', 60, 100, 120]}
          >
            <FadeIn>
              <Heading
                type="H3"
                textTransform="uppercase"
                m={0}
                mb="0.7em"
                opacity={isVisible ? '1' : '0'}
              >
                LONG-TERM STRATEGIES
              </Heading>
              <Body
                type="B1"
                m={0}
                mb="4em"
                opacity={isVisible ? '1' : '0'}
              >
                For investors looking to grow their retirement savings with a buy-and-hold investment strategy, The Apartment Growth REIT offers multifamily properties with value-add opportunities and appropriate risk-adjusted returns as well as the potential for value appreciation.
              </Body>
              <NavLink
                type="L4"
                to="/investment-opportunity/274949"
                opacity={isVisible ? '1' : '0'}
              >
                Learn more &gt;
              </NavLink>
            </FadeIn>
          </ExtendedFlex>
        </ExtendedBox>
      </ExtendedFlex>
    </Box>
  );
};

IraInvesting2.propTypes = {
  isVisible: PropTypes.bool,
};

export default IraInvesting2;
