/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DealPageHero } from 'rtmg-component-library';
import { Carousel } from 'react-responsive-carousel';
import Vimeo from '@u-wave/react-vimeo';
import ReactHtmlParser from 'react-html-parser';
import Gallery from 'react-grid-gallery';
import get from 'lodash/get';
import SmartButton from '../components/SmartButton';

import {
  GlossaryAccordion,
  ExtendedBox,
  ExtendedFlex,
  Content,
  Drawer,
  Image,
  Link,
} from '../coreui';
import Arrow from '../assets/Arrow.svg';
import ArrowHover from '../assets/ArrowHover.svg';
import { PlayButton } from '../IconLibrary';

import { privatePlacementFaqVersion2Id } from '../ioDetails/data';

import Graphic3ColCallout from '../components/Graphic3ColCallout';
import InvestToday from '../ioDetails/components/investToday';
import AtAGlance from './components/atAGlance';
import InvestmentHighlights from './components/investmentHighlights';
import Management from './components/management';
import Section from '../ioDetails/components/section';
import SubNav from '../components/subNav';
import DocumentItem from '../ioDetails/components/documentItem';
import QuickLinks from '../ioDetails/components/quicklinks';
import Back from '../ioDetails/components/back';
import PrimaryLinks from '../ioDetails/components/primaryLinks';
import ScrollToHash from '../components/ScrollToHash';
import WebinarModal from './components/WebinarModal';
import OverlayComponent from '../components/OverlayComponent';

const Wrapper = styled.div`
  sup {
    top: 0;
    vertical-align: top;
    line-height: unset;
  }
`;

class PrivatePlacement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isWebinarVisible: false,
      showOverlay: false,
      currentVideo: null,
      currentImage: null,
      isOverlayVideo: false,
    };

    this.setRef = this.setRef.bind(this);
    this.toggleWebinar = this.toggleWebinar.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  setRef(el, name) {
    if (!this[name] && el) {
      this[name] = el;
    }
  }

  toggleWebinar() {
    this.setState(({ isWebinarVisible }) => ({
      isWebinarVisible: !isWebinarVisible,
    }));
  }

  toggleDrawer(el) {
    this[el].toggleDrawer();
  }

  render() {
    const io = this.props.io;
    const management = io.management;
    const location = io.locationInformation;
    const businessPlan = io.businessPlan;
    const propertyDetails = io.propertyInformation;
    const SourcesAndUses = io.sourcesAndUses;
    const DebtAssumptions = io.debtAssumptions;
    const Distributions = io.distributions;
    const Comparables = io.comparables;
    const Fees = io.fees;
    const gallery = this.props.gallery;
    const documents = (io.investmentDocuments && io.investmentDocuments[0])
      ? io.investmentDocuments[0] : null;
    const user = this.props.user;
    const perks = io.perks;
    const financials = io.financials;
    const financialLiquitity = io.financialLiquitity;
    const webinar = io?.webinar;
    const webinarUrl = get(io, 'webinar[0].Video.video_url', null);

    const showOverview = ((io.highlights && io.highlights.length > 0) || io.blurbAdditional ||
      io.investmentObjective ||
      (io.investmentHighlights && io.investmentHighlights.length > 0));
    const showProperty = (io.propertySummary || propertyDetails || Comparables
      || location || perks);
    const showGallery = (gallery && gallery.length > 1);
    const showFinancials = (io.capStackImage || SourcesAndUses || financials ||
      DebtAssumptions || financialLiquitity ||
      io.financialDisclaimer);
    const showDistributions = Distributions;
    const showFees = Fees;
    const showDocs = (io.investmentDocuments || io.financialDocuments);
    const showFAQ = io.id < privatePlacementFaqVersion2Id
      && (io.riskSynopsis || (this.props.faqItems &&
      this.props.faqItems.length > 0));
    const showFAQv2 = io.id >= privatePlacementFaqVersion2Id
      && (io.riskSynopsis || (this.props.faqItems &&
      this.props.faqItems.length > 0));
    const showGalleryInBusinessPlan = !showProperty && showGallery;
    const showDistributionsInBusinessPlan = !showFinancials && showDistributions;
    const showFeesInBusinessPlan = !showFinancials && showFees;
    const showBusinessPlan = businessPlan || showGalleryInBusinessPlan ||
      showDistributionsInBusinessPlan || showFeesInBusinessPlan;

    const PrivatePlacementList = this.props.filteredInvestments.filter((i) => !i.REIT) || [];
    const currentIndex = PrivatePlacementList.findIndex((i) => i.id === this.props.io.id);
    const previousDeal = this.props.filteredInvestments.length > 1 ? [<Link to={PrivatePlacementList[currentIndex === 0 || currentIndex === -1 ? PrivatePlacementList.length - 1 : currentIndex - 1]?.path}>{`< ${PrivatePlacementList[currentIndex === 0 || currentIndex === -1 ? PrivatePlacementList.length - 1 : currentIndex - 1]?.title}`}</Link>] : null;
    const nextDeal = this.props.filteredInvestments.length > 1 ? [<Link to={PrivatePlacementList[currentIndex === PrivatePlacementList.length - 1 ? 0 : currentIndex + 1]?.path}>{`${PrivatePlacementList[currentIndex === PrivatePlacementList.length - 1 ? 0 : currentIndex + 1]?.title} >`}</Link>] : null;
    const fullList = [<Link to="/investment-opportunities">Browse Full List</Link>];

    const showOverlay = this.state.showOverlay;

    const setShowOverlay = (shouldShow, url, type) => {
      if (!shouldShow) {
        this.setState({ showOverlay: false, currentVideo: null, currentImage: null });
        return;
      }

      const state = {
        showOverlay: true,
        [type === 'video' ? 'currentVideo' : 'currentImage']: url,
        isOverlayVideo: type === 'video',
      };
      this.setState(state);
    };

    const VIDEOS = io.carousel?.filter((item) => item.type === 'vimeo_video') || [];

    const navItems = [
      ...(showOverview ? [{ title: 'Overview', name: 'overview' }] : []),
      ...((management && management.filter((m) => !!m).length > 0) ? [{ title: 'Management', name: 'management', responsiveHide: true }] : []),
      ...(showBusinessPlan ? [{ title: 'Business Plan', name: 'business' }] : []),
      ...(showProperty ? [{ title: 'Property', name: 'property' }] : []),
      ...(showFinancials ? [{ title: 'Financials', name: 'financials' }] : []),
      ...(showDocs ? [{ title: 'Documents', name: 'documents', responsiveHide: true }] : []),
      ...(showFAQ ? [{ title: 'Risks/FAQs', name: 'risks', responsiveHide: true }] : []),
      ...(showFAQv2 ? [{ title: 'Disclaimers/FAQs', name: 'disclaimers-faqs', responsiveHide: true }] : []),
    ];

    const galleryComponent = (
      <>
        {
          showGallery &&
          <ScrollToHash
            name="photos"
            onComplete={() => this.toggleDrawer('photos')}
            offset={-60}
          >
            <Drawer
              w={1}
              title="Photos"
              name="photos"
              ref={(el) => { this.setRef(el, 'photos'); }}
              drawerColor={showGalleryInBusinessPlan ? 'gray.1' : undefined}
            >
              &nbsp;
              <div>
                <Gallery
                  images={gallery}
                  margin={10}
                  enableImageSelection={false}
                  showImageCount={false}
                />
              </div>
            </Drawer>
          </ScrollToHash>
        }
      </>
    );

    const distributionsComponent = (
      <>
        {
          showDistributions &&
          <ScrollToHash
            name="distributions"
            onComplete={() => this.toggleDrawer('distributions')}
            offset={-60}
          >
            <Drawer
              title="Distributions"
              name="distributions"
              ref={(el) => { this.setRef(el, 'distributions'); }}
              drawerColor={showDistributionsInBusinessPlan ? 'gray.1' : undefined}
            >
              <Content>
                {ReactHtmlParser(Distributions)}
              </Content>
            </Drawer>
          </ScrollToHash>
        }
      </>
    );

    const feesComponent = (
      <>
        {
          showFees &&
          <ScrollToHash
            name="fees"
            onComplete={() => this.toggleDrawer('fees')}
            offset={-60}
          >
            <Drawer
              title="Fees"
              name="fees"
              ref={(el) => { this.setRef(el, 'fees'); }}
              drawerColor={showFeesInBusinessPlan ? 'gray.1' : undefined}
            >
              <Content>
                {ReactHtmlParser(Fees)}
              </Content>
            </Drawer>
          </ScrollToHash>
        }
      </>
    );

    const handleWatchlistClick = (flagged, watchlist) => {
      if (!user.isLoggedIn) {
        this.props.history.push(`/user/login?destination=${this.props.location.pathname}`);
        return;
      }
      if (flagged) {
        axios.post(watchlist.unflagLink);
      } else {
        axios.post(watchlist.flagLink);
      }
    };

    const handleWaitListClick = (flagged, waitList) => {
      if (!user.isLoggedIn) {
        this.props.history.push(`/user/login?destination=${this.props.location.pathname}`);
        return;
      }
      if (flagged) {
        axios.post(waitList.unflag);
      } else {
        axios.post(waitList.flag);
      }
    };

    const CarouselWrapper = styled.div`
      background: transparent;
      max-width: 1440px;
      margin: 10px auto 25px;
      .slide {
        background: transparent !important;
        min-width: 100% !important;
        max-width: 100% !important;
        button {
          cursor: pointer;
        }
        img {
          height: 154px;
          border-radius: 5px;
        }
      }
      @media (min-width: 768px) {
        .slide {
          min-width: 50% !important;
          max-width: 50% !important;
        }
      }
      @media (min-width: 968px) {
        .slide {
          min-width: 25% !important;
          max-width: 25% !important;
        }
      }
      .video-thumb {
        position: relative;
        .play-button {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #000000CC;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      }
      .carousel.carousel-slider .control-arrow {
        background-color: #F7F8F8;
        box-shadow: 0px 0px 20px #0000001A;
        border-radius: 2px 0px 0px 2px;
        background-image: url("${Arrow}");
        opacity: 1;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        &.control-prev {
          transform: translateY(-50%) rotate(-90deg);
        }
        &:hover {
          background-color: #F7F8F8;
          box-shadow: 0px 0px 20px #0000001A;
          border-radius: 2px 0px 0px 2px;
          background-image: url("${ArrowHover}");
        }
        &:before {
          border: none;
        }
      }
    `;

    const VimeoWrapper = styled.div`
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      div[data-vimeo-initialized=true], iframe {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    `;

    const handleNextClick = () => {
      if (this.state.isOverlayVideo) {
        if (this.state.currentVideo + 1 >= VIDEOS.length) {
          this.setState({
            currentVideo: null,
            currentImage: 0,
            isOverlayVideo: false,
          });
        } else {
          this.setState(({ currentVideo }) => ({
            currentVideo: currentVideo + 1,
          }));
        }
      } else if (this.state.currentImage + 1 >= gallery.length) {
        if (VIDEOS.length > 0) {
          this.setState({
            currentImage: null,
            currentVideo: 0,
            isOverlayVideo: true,
          });
        } else {
          this.setState({
            currentImage: 0,
          });
        }
      } else {
        this.setState(({ currentImage }) => ({
          currentImage: currentImage + 1,
        }));
      }
    };

    const handlePrevClick = () => {
      if (this.state.isOverlayVideo) {
        if (this.state.currentVideo - 1 < 0) {
          this.setState({
            currentVideo: null,
            currentImage: gallery.length - 1,
            isOverlayVideo: false,
          });
        } else {
          this.setState(({ currentVideo }) => ({
            currentVideo: currentVideo - 1,
          }));
        }
      } else if (this.state.currentImage - 1 <= 0) {
        if (VIDEOS.length > 0) {
          this.setState({
            currentImage: null,
            currentVideo: VIDEOS.length - 1,
            isOverlayVideo: true,
          });
        } else {
          this.setState({
            currentImage: gallery.length - 1,
          });
        }
      } else {
        this.setState(({ currentImage }) => ({
          currentImage: currentImage - 1,
        }));
      }
    };

    return (
      <>
        <OverlayComponent
          isShowingPage={showOverlay}
          isCarousel
          lightbox
          background="transparent"
          onNextClick={handleNextClick}
          onPrevClick={handlePrevClick}
          onClose={() => setShowOverlay(false, null, null)}
        >
          <VimeoWrapper>
            {
              this.state.isOverlayVideo ?
                <Vimeo
                  video={VIDEOS?.[this.state.currentVideo]?.video_id}
                  autoplay
                />
                :
                <img src={gallery[this.state.currentImage]?.src} style={{ width: '100%' }} alt="current" />
            }
          </VimeoWrapper>
        </OverlayComponent>
        <ExtendedBox
          w={1}
          overflowX="hidden"
        >
          <WebinarModal
            isVisible={this.state.isWebinarVisible}
            handleClick={this.toggleWebinar}
            url={webinarUrl}
            playing={this.state.isWebinarVisible}
          />
          <ExtendedBox
            bg="white"
          >
            <ExtendedFlex
              flexDirection="column"
            >
              {
                user && user.isStaff &&
                <>
                  <PrimaryLinks
                    items={[
                      { path: `/node/${io.id}/edit`, text: 'Edit' },
                      { path: `/node/${io.id}/salesforce_activity`, text: 'Salesforce Activity' },
                      { path: `/node/${io.id}/documents`, text: 'Documents' },
                    ]}
                  />
                  <Back />
                </>
              }
            </ExtendedFlex>
            <Wrapper>
              <div style={{ width: '100%', backgroundColor: '#F7F8F8', paddingBottom: '40px' }}>
                <DealPageHero
                  {...io}
                  statusLabel={io.fullyPledged ? 'Fully Pledged' : io.statusLabel}
                  previousDeal={previousDeal}
                  nextDeal={nextDeal}
                  fullList={fullList}
                  waitListCallback={handleWaitListClick}
                  watchlistCallback={handleWatchlistClick}
                  smartButton={
                    io.smartButton &&
                    <div className="rm-smartbutton-dealpagehero" style={{ margin: '0 auto', padding: '0 15px', boxSizing: 'border-box', justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }}>
                      <SmartButton
                        width="100%"
                        type="L1"
                        mw={100}
                        label={io.smartButton.button_text}
                        buttonHref={io.smartButton.button_href}
                        helpText={io.fullyPledged ? 'This opportunity is fully pledged at this time.' : io.smartButton.help_text}
                        tooltipText={io.smartButton.tooltip_text}
                        flag={(io.smartButton.flag) ? io.smartButton : null}
                        maxWidth="calc(100% - 30px)"
                        ml={15}
                        mr={15}
                        boxSizing="border-box"
                        testid="rm-button"
                      />
                    </div>
                  }
                  disclaimer={['*Please carefully review the ', <a href="#disclaimers-faqs">Disclaimers</a>, ' section below, including regarding Sponsor’s assumptions and target returns']}
                />
                <QuickLinks
                  io={io}
                  webinar={webinar?.length > 0 ? webinar : []}
                  documents={[documents]}
                  ref={(el) => { this.setRef(el, 'quickLinks'); }}
                  newStyle
                  statusLabel={io.fullyPledged ? 'Fully Pledged' : io.statusLabel}
                />
                {
                  showGallery &&
                  <CarouselWrapper>
                    <Carousel
                      swipeable={false}
                      showArrows
                      showIndicators={false}
                      showThumbs={false}
                      showStatus={false}
                      background="#FFF"
                    >
                      {
                        gallery && gallery.map((item, index) => (
                          <button type="button" className="image-thumb" onClick={() => setShowOverlay(true, index, 'image')} style={{ border: 'none', background: 'transparent' }}>
                            <img
                              src={item.carousel}
                              alt={`carousel item ${index}`}
                            />
                          </button>
                        ))
                      }
                      {
                        VIDEOS.map((video, index) => (
                          <button type="button" className="video-thumb" style={{ border: 'none', background: 'transparent' }} onClick={() => setShowOverlay(true, index, 'video')}>
                            <img
                              src={video.images[0].find((image) => image.style === 'carousel').url}
                              alt={`vimeo video ${video?.video_id}`}
                            />
                            <div className="play-button">
                              <PlayButton width="36" height="36" />
                            </div>
                          </button>
                        ))
                      }
                    </Carousel>
                  </CarouselWrapper>
                }
              </div>
              <div style={{ background: '#FFF', margin: '40px auto 15px', maxWidth: '1024px', color: '#202020', fontSize: '24px', letterSpacing: '8.64px', fontFamily: 'var(--font-sans)' }}>
                EXPLORE THIS PROJECT
              </div>
            </Wrapper>
          </ExtendedBox>
          <SubNav
            items={navItems}
            smartButton={io.smartButton}
            newStyle
          />
          { showOverview &&
            <ScrollToHash
              name="overview"
            >
              <Section
                bg="white"
                title="Overview"
                name="overview"
              >
                {
                  io.highlights && Object.keys(io.highlights).length > 0 &&
                  <Graphic3ColCallout
                    description={io.blurb}
                    items={io.highlights}
                  />
                }
                {
                  io.blurbAdditional &&
                  <Content
                    height="100%"
                  >
                    {ReactHtmlParser(io.blurbAdditional.value)}
                  </Content>
                }
                {
                  io.quickLook && io.quickLook.length > 0 &&
                  <AtAGlance
                    items={io.quickLook}
                  />
                }
                {
                  io.investmentObjective &&
                  <ScrollToHash
                    name="info"
                  >
                    <ExtendedBox
                      mx={20}
                      my={60}
                      height={['300px', '300px', '400px', '500px']}
                    >
                      <Content
                        height="100%"
                      >
                        {ReactHtmlParser(io.investmentObjective)}
                      </Content>
                    </ExtendedBox>
                  </ScrollToHash>
                }
                {
                  io.investmentHighlights && io.investmentHighlights.length > 0 &&
                  <InvestmentHighlights
                    highlights={io.investmentHighlights}
                  />
                }
              </Section>
            </ScrollToHash>}
          {
            management && management.filter((m) => !!m).length > 0 &&
            <ScrollToHash
              name="management"
            >
              <Section
                bg="gray.0"
                title="Management"
                name="management"
              >
                {
                  management.filter((m) => !!m).map((managementTeam, index) => {
                    const managementUrl = (managementTeam.url) ? managementTeam.url.url : null;
                    return (
                      <Management
                        logo={managementTeam.logo}
                        title={managementTeam.label}
                        overview={managementTeam.Overview}
                        url={managementUrl}
                        team={managementTeam.team}
                        trackRecord={managementTeam.trackRecord}
                        isFirst={(index === 0)}
                      />
                    );
                  })
                }
              </Section>
            </ScrollToHash>
          }
          {
            showBusinessPlan &&
            <ScrollToHash
              name="business"
            >
              <Section
                bg="white"
                title="Business Plan"
                name="business"
              >
                <ExtendedBox
                  mx={40}
                >
                  <Content>
                    {ReactHtmlParser(businessPlan)}
                  </Content>
                </ExtendedBox>
                {
                  showGalleryInBusinessPlan &&
                  galleryComponent
                }
                {
                  showDistributionsInBusinessPlan &&
                  distributionsComponent
                }
                {
                  showFeesInBusinessPlan &&
                  feesComponent
                }
              </Section>
            </ScrollToHash>
          }
          { showProperty &&
            <ScrollToHash
              name="property"
            >
              <Section
                bg="gray.0"
                title="Property"
                name="property"
              >
                { io.propertySummary &&
                  <ExtendedBox
                    mx={40}
                  >
                    <Content>
                      {ReactHtmlParser(io.propertySummary)}
                    </Content>
                  </ExtendedBox>}
                {
                  propertyDetails &&
                  <ScrollToHash
                    name="property-details"
                    onComplete={() => this.toggleDrawer('property-details')}
                    offset={-60}
                  >
                    <Drawer
                      title="Property Details"
                      name="property-details"
                      ref={(el) => { this.setRef(el, 'property-details'); }}
                    >
                      <Content>
                        {ReactHtmlParser(propertyDetails)}
                      </Content>
                    </Drawer>
                  </ScrollToHash>
                }
                {
                  Comparables &&
                  <ScrollToHash
                    name="comparables"
                    onComplete={() => this.toggleDrawer('comparables')}
                    offset={-60}
                  >
                    <Drawer
                      title="Comparables"
                      name="comparables"
                      ref={(el) => { this.setRef(el, 'comparables'); }}
                    >
                      <Content>
                        {ReactHtmlParser(Comparables)}
                      </Content>
                    </Drawer>
                  </ScrollToHash>
                }
                {
                  location &&
                  <ScrollToHash
                    name="location"
                    onComplete={() => this.toggleDrawer('location')}
                    offset={-60}
                  >
                    <Drawer
                      title="Location"
                      name="location"
                      ref={(el) => { this.setRef(el, 'location'); }}
                    >
                      <Content>
                        {ReactHtmlParser(location)}
                      </Content>
                    </Drawer>
                  </ScrollToHash>
                }
                {
                  perks &&
                  <ScrollToHash
                    name="perks"
                    onComplete={() => this.toggleDrawer('perks')}
                    offset={-60}
                  >
                    <Drawer
                      title="perks"
                      name="perks"
                      ref={(el) => { this.setRef(el, 'perks'); }}
                    >
                      <Content>
                        {ReactHtmlParser(perks)}
                      </Content>
                    </Drawer>
                  </ScrollToHash>
                }
                {galleryComponent}
              </Section>
            </ScrollToHash>}
          { showFinancials &&
            <ScrollToHash
              name="financials"
            >
              <Section
                bg="gray.1"
                title="Financials"
                name="financials"
              >
                {
                  io.capStackImage &&
                  <ExtendedBox
                    m="auto"
                    textAlign="center"
                    style={{
                      maxWidth: '800px',
                    }}
                    px={40}
                    py={40}
                  >
                    <Image
                      src={io.capStackImage.url}
                      w={1}
                    />
                  </ExtendedBox>
                }
                {
                  SourcesAndUses &&
                  <ScrollToHash
                    name="sources"
                    offset={-60}
                    onComplete={() => this.toggleDrawer('sources')}
                  >
                    <Drawer
                      title="Sources and Uses"
                      name="sources-uses"
                      ref={(el) => { this.setRef(el, 'sources'); }}
                    >
                      <Content>
                        {ReactHtmlParser(SourcesAndUses)}
                      </Content>
                    </Drawer>
                  </ScrollToHash>
                }
                {
                  financials &&
                  <ScrollToHash
                    name="financials-details"
                    offset={-60}
                    onComplete={() => this.toggleDrawer('financials-details')}
                  >
                    <Drawer
                      title="Financials"
                      name="financials-details"
                      ref={(el) => { this.setRef(el, 'financials-details'); }}
                    >
                      <Content>
                        {ReactHtmlParser(financials)}
                      </Content>
                    </Drawer>
                  </ScrollToHash>
                }
                {
                  DebtAssumptions &&
                  <ScrollToHash
                    name="debt"
                    onComplete={() => this.toggleDrawer('debt')}
                    offset={-60}
                  >
                    <Drawer
                      title="Debt Assumptions"
                      name="debt-assumptions"
                      ref={(el) => { this.setRef(el, 'debt'); }}
                    >
                      <Content>
                        {ReactHtmlParser(DebtAssumptions)}
                      </Content>
                    </Drawer>
                  </ScrollToHash>
                }
                {distributionsComponent}
                {feesComponent}
                {
                  financialLiquitity &&
                  <ScrollToHash
                    name="financial-liquidity"
                    onComplete={() => this.toggleDrawer('financial-liquidity')}
                    offset={-60}
                  >
                    <Drawer
                      title="Financial Liquitidy"
                      name="financial-liquidity"
                      ref={(el) => { this.setRef(el, 'financial-liquidity'); }}
                    >
                      <Content>
                        {ReactHtmlParser(financialLiquitity)}
                      </Content>
                    </Drawer>
                  </ScrollToHash>
                }
                <ExtendedBox
                  mx={40}
                >
                  <Content>
                    {ReactHtmlParser(io.financialDisclaimer)}
                  </Content>
                </ExtendedBox>
              </Section>
            </ScrollToHash>}
          {
            (io.investmentDocuments || io.financialDocuments) &&
            <ScrollToHash
              name="documents"
            >
              <Section
                bg="gray.0"
                title="Documents"
                name="documents"
              >

                {
                  io.investmentDocuments &&
                  <ScrollToHash
                    name="docs"
                    onComplete={() => this.toggleDrawer('docs')}
                    offset={-60}
                  >
                    <Drawer
                      title="Offering Documentation"
                      name="offering-documentation"
                      ref={(el) => { this.setRef(el, 'docs'); }}
                    >
                      <ExtendedBox
                        mt={10}
                        mb={40}
                        mx={40}
                      >
                        {
                          io.investmentDocuments.map((doc) => {
                            return (
                              <DocumentItem
                                linkTarget="_blank"
                                doc={doc}
                                key={`fid_${doc.fid}`}
                              />
                            );
                          })
                        }
                      </ExtendedBox>
                    </Drawer>
                  </ScrollToHash>
                }
                {
                  io.financialDocuments &&
                  <ScrollToHash
                    name="financial-documents"
                    onComplete={() => this.toggleDrawer('financial-documents')}
                    offset={-60}
                  >
                    <Drawer
                      title="Financial Documents"
                      name="financial-documents"
                      ref={(el) => { this.setRef(el, 'financial-documents'); }}
                    >
                      <ExtendedBox
                        mt={10}
                        mb={40}
                        mx={40}
                      >
                        {
                          io.financialDocuments.map((doc) => {
                            return (
                              <DocumentItem
                                linkTarget="_blank"
                                doc={doc}
                                key={`fid_${doc.fid}`}
                              />
                            );
                          })
                        }
                      </ExtendedBox>
                    </Drawer>
                  </ScrollToHash>
                }
              </Section>
            </ScrollToHash>
          }
          { showFAQ &&
            <ScrollToHash
              name="risks"
            >
              <Section
                bg="gray.0"
                title="Risks/FAQs"
                name="risks"
              >
                {
                  io.riskSynopsis &&
                  <ScrollToHash
                    name="risk-factors"
                    onComplete={() => this.toggleDrawer('risk-factors')}
                    offset={-60}
                  >
                    <Drawer
                      title="Risk Factors"
                      name="risk-factors"
                      ref={(el) => { this.setRef(el, 'risk-factors'); }}
                    >
                      <Content>
                        {ReactHtmlParser(io.riskSynopsis)}
                      </Content>
                    </Drawer>
                  </ScrollToHash>
                }
                { this.props.faqItems && this.props.faqItems.length > 0 &&
                  <ScrollToHash
                    name="faqs"
                    onComplete={() => this.toggleDrawer('faqs')}
                    offset={-60}
                  >
                    <Drawer
                      title="FAQS"
                      name="faqs"
                      ref={(el) => { this.setRef(el, 'faqs'); }}
                    >
                      {
                        this.props.faqItems &&
                        <GlossaryAccordion
                          items={this.props.faqItems}
                          my={40}
                          contentIsHtml
                        />
                      }
                    </Drawer>
                  </ScrollToHash>}
              </Section>
            </ScrollToHash> }
          { showFAQv2 &&
            <ScrollToHash
              name="disclaimers-faqs"
            >
              <Section
                bg="gray.0"
                title="Disclaimers/FAQs"
                name="disclaimers-faqs"
              >
                {
                  io.riskSynopsis &&
                  <ScrollToHash
                    name="disclaimers"
                    onComplete={() => this.toggleDrawer('disclaimers')}
                    offset={-60}
                  >
                    <Drawer
                      title="Disclaimers"
                      name="disclaimers"
                      ref={(el) => { this.setRef(el, 'disclaimers'); }}
                    >
                      <Content>
                        {ReactHtmlParser(io.riskSynopsis)}
                      </Content>
                    </Drawer>
                  </ScrollToHash>
                }
                { this.props.faqItems && this.props.faqItems.length > 0 &&
                  <ScrollToHash
                    name="faqs"
                    onComplete={() => this.toggleDrawer('faqs')}
                    offset={-60}
                  >
                    <Drawer
                      title="FAQS"
                      name="faqs"
                      ref={(el) => { this.setRef(el, 'faqs'); }}
                    >
                      {
                        this.props.faqItems &&
                        <GlossaryAccordion
                          items={this.props.faqItems}
                          my={40}
                          contentIsHtml
                        />
                      }
                    </Drawer>
                  </ScrollToHash> }
              </Section>
            </ScrollToHash> }
          { io.smartButton && io.smartButton.button_text && io.smartButton.button_href &&
            <Section
              bg="white"
            >
              <InvestToday smartButton={io.smartButton} />
            </Section> }
        </ExtendedBox>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  io: state.privatePlacement.io,
  gallery: state.privatePlacement.gallery,
  details: state.privatePlacement.details,
  faqItems: state.privatePlacement.faqItems,
  investments: state.activeInvestments.investments,
  pageVariant: state.variants?.privatePlacement?.v,
  user: state.app.user,
  filteredInvestments: state.activeInvestments?.filteredInvestments,
});

PrivatePlacement.propTypes = {
  io: PropTypes.object,
  gallery: PropTypes.array,
  details: PropTypes.array,
  faqItems: PropTypes.array,
  investments: PropTypes.array,
  pageVariant: PropTypes.string,
  user: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  filteredInvestments: PropTypes.array,
};

export default withRouter(connect(mapStateToProps)(PrivatePlacement));
