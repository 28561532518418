/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  ModalWithOverlay,
  Data,
  Body,
  ExtendedFlex,
  StyledButton,
} from '../../../coreui';

const CancelModal = ({
  submitting,
  header,
  subtext,
  body,
  handleCancel,
  handleClick,
  error,
  nextWithdrawalDate,
  ...props
}) => {
  const isNextWithdrawalToday = nextWithdrawalDate
    ? moment().isSame(nextWithdrawalDate, 'day') : false;

  return (
    <ModalWithOverlay
      handleClick={handleClick}
      {...props}
    >
      <ExtendedFlex
        flexDirection="column"
        align="center"
        m={[null, null, 30]}
        data-test="confirm-cancel-popup"
      >
        <Data
          type="D2"
          textTransform="uppercase"
        >
          {header}
        </Data>
        {
          subtext &&
          <Body
            type="B1"
            textAlign="center"
            textTransform="uppercase"
          >
            {subtext}
          </Body>
        }
        {
          body &&
          <Body
            type="B1"
            textAlign="center"
          >
            {
              isNextWithdrawalToday &&
              `Enrollment cancellation requests made today, the ${moment().format('Do')} of the month (withdrawal day), may have funds withdrawn one last cycle before cancellation is finalized. `
            }
            {body}
          </Body>
        }
        <ExtendedFlex
          w={1}
          my={30}
          justify="center"
          align="center"
          flexDirection={['column', null, 'row']}
        >
          <StyledButton
            type="L4"
            onClick={handleClick}
            loading={submitting}
            disable={submitting}
          >
            Back to page
          </StyledButton>
          <ExtendedFlex
            w="15%"
            display={['none', null, 'block']}
          />
          <StyledButton
            type="L4"
            onClick={handleCancel}
            color="gray.7"
            mt={[30, null, 0]}
            loading={submitting}
            disable={submitting}
          >
            Confirm Cancellation
          </StyledButton>
        </ExtendedFlex>
        {
          error &&
          <ExtendedFlex
            w={1}
            my={30}
            justify="center"
            align="center"
            flexDirection={['column', null, 'row']}
          >
            <Data
              type="D4"
              display="block"
              my={2}
              color="red"
            >
              {
                error === true &&
                'An unknown error has occurred.'
              }
              {
                error !== true &&
                error
              }
            </Data>
          </ExtendedFlex>
        }
      </ExtendedFlex>
    </ModalWithOverlay>
  );
};

CancelModal.propTypes = {
  submitting: PropTypes.bool,
  header: PropTypes.string,
  subtext: PropTypes.string,
  body: PropTypes.string,
  handleCancel: PropTypes.func,
  handleClick: PropTypes.func,
  nextWithdrawalDate: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

CancelModal.defaultProps = {
  header: 'Cancel recurring investment?',
  submitting: false,
  body: 'Re-enrollment will require a new application and will be subject to broker- dealer re - approval.',
};

export default CancelModal;
