import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import {
  ExtendedFlex,
  Heading,
  Body,
} from '../../../coreui';

import StepCounter from './StepCounter';
import Actions from '../../../components/forms/Actions';
import PrimaryButton from '../../../components/forms/PrimaryButton';
import CancelButtonWithModal from './CancelButtonWithModal';
import AutoInvestContentWrapper from './AutoInvestContentWrapper';
import CheckboxLabel from '../../../components/forms/CheckboxLabel';

const EnrollmentInfo = ({
  steps,
  selectedOffering,
  selectedAccount,
  nextDate,
  handleSubmit,
  handleCancel,
  cancelBody,
}) => {
  return (
    <AutoInvestContentWrapper>
      <StepCounter
        steps={steps}
        activeStep={0}
      />
      <ExtendedFlex
        flexDirection="column"
        w={1}
        maxWidth={650}
      >
        <Heading
          type="H2"
        >
          Auto Invest Enrollment
        </Heading>
        <Heading
          type="H6"
          textTransform="uppercase"
        >
          Current offering selected:
        </Heading>
        <CheckboxLabel
          label={selectedOffering}
          mb={50}
          checkboxProps={{
            checked: true,
            disabled: true,
          }}
        />
        <Heading
          type="H6"
          textTransform="uppercase"
        >
          Current account selected:
        </Heading>
        <CheckboxLabel
          label={selectedAccount}
          mb={50}
          checkboxProps={{
            checked: true,
            disabled: true,
          }}
        />
        <Heading
          type="H6"
          textTransform="uppercase"
        >
          Next available enrollment date:
        </Heading>
        <Body
          type="B2"
        >
          Funds are withdrawn on a monthly basis on the 10th of every month or the
          next business day. The date below reflects the month of your first auto
          investment withdrawal upon enrollment.
        </Body>
        <CheckboxLabel
          label={nextDate}
          mb={50}
          checkboxProps={{
            checked: true,
            disabled: true,
          }}
        />
        <Actions>
          <PrimaryButton
            onClick={handleSubmit}
          >
            Continue
          </PrimaryButton>
          <CancelButtonWithModal
            handleCancel={handleCancel}
            body={cancelBody}
          />
        </Actions>
      </ExtendedFlex>
    </AutoInvestContentWrapper>
  );
};

EnrollmentInfo.propTypes = {
  steps: StepCounter.propTypes.steps,
  selectedOffering: PropTypes.string,
  selectedAccount: PropTypes.string,
  nextDate: PropTypes.string,
  cancelBody: CancelButtonWithModal.propTypes.body,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
};

EnrollmentInfo.defaultProps = {
  selectedOffering: 'Test REIT',
  selectedAccount: 'Individual: Anderson',
  nextDate: 'December 2019',
  handleSubmit: noop,
  handleCancel: noop,
};

export default EnrollmentInfo;
