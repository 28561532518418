import styled from 'styled-components';

export default {
  ReitRiskFactors: styled.div`
    width: 100%;
    padding: 70px 16px 80px;
    box-sizing: border-box;
    display: block;
    font-family: mrs-eaves-xl-serif-narrow,serif;
    color: #202020;
    background: ${({ background }) => `${background}`};
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      padding: 110px 100px 120px;
    }
  `,
  Container: styled.div`
    max-width: 1024px;
    margin: 0 auto;
    h1 {
      margin: 0 0 15px 0;
      font-weight: 800;
      font-size: 28px;
      line-height: 32px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      h1 {
        font-size: 44px;
        line-height: 56px;
      }
    }
  `,
  DisclaimerCopy: styled.div`
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-style: italic;
    line-height: 24px;
  `,
};
