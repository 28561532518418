import React from 'react';

const Transactions = () => {
  /* eslint-disable */
  return (
    <g id="desktop/icon/med/wallet/blk" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-6" transform="translate(9.000000, 1.000000)" fill="#000000" stroke="#FFFFFF">
        <path d="M32.8801875,11.25 L29.5314375,4.5525 C29.3626875,4.216875 29.0758125,3.965625 28.7195625,3.8475 C28.5733125,3.79875 28.4233125,3.774375 28.2714375,3.774375 C28.0539375,3.774375 27.8401875,3.825 27.6451875,3.924375 L12.9883125,11.25 L32.8801875,11.25 Z M33.7501875,22.5 C30.6489375,22.5 28.1251875,25.02375 28.1251875,28.125 C28.1251875,31.22625 30.6489375,33.75 33.7501875,33.75 L40.7814375,33.75 C41.5576875,33.75 42.1876875,33.12 42.1876875,32.34375 L42.1876875,23.90625 C42.1876875,23.131875 41.5576875,22.5 40.7814375,22.5 L33.7501875,22.5 Z M7.0314375,14.0625 C4.7045625,14.0625 2.8126875,15.954375 2.8126875,18.28125 L2.8126875,37.96875 C2.8126875,40.295625 4.7045625,42.1875 7.0314375,42.1875 L35.1564375,42.1875 C37.4833125,42.1875 39.3751875,40.295625 39.3751875,37.96875 L39.3751875,36.5625 L33.7501875,36.5625 C29.0964375,36.5625 25.3126875,32.776875 25.3126875,28.125 C25.3126875,23.473125 29.0964375,19.6875 33.7501875,19.6875 L39.3751875,19.6875 L39.3751875,18.28125 C39.3751875,15.95625 37.4833125,14.0625 35.1583125,14.0625 L7.0314375,14.0625 Z M7.0314375,45 C3.1539375,45 0.000187500002,41.84625 0.000187500002,37.96875 L0.000187500002,18.28125 C0.000187500002,14.50875 2.9158125,11.45625 6.6676875,11.266875 L26.3870625,1.408125 C26.9814375,1.111875 27.6133125,0.96 28.2714375,0.96 C28.7233125,0.96 29.1733125,1.033125 29.6083125,1.1775 C30.6770625,1.53375 31.5433125,2.285625 32.0476875,3.294375 L36.0583125,11.315625 C39.5270625,11.76375 42.1876875,14.746875 42.1876875,18.28125 L42.1876875,19.929375 C43.8470625,20.518125 45.0001875,22.108125 45.0001875,23.90625 L45.0001875,32.34375 C45.0001875,34.14375 43.8470625,35.73375 42.1876875,36.320625 L42.1876875,37.96875 C42.1876875,41.84625 39.0339375,45 35.1564375,45 L7.0314375,45 Z M34,26 C35.104,26 36,26.896 36,28 C36,29.104 35.104,30 34,30 C32.896,30 32,29.104 32,28 C32,26.896 32.896,26 34,26" id="Combined-Shape"></path>
      </g>
    </g>
  );
  /* eslint-enable */
};

export default Transactions;
