/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { TextLink, StyledLink } from '..';

import loadingAnimation from '../assets/loader-white.svg';
import loadingAnimationBlack from '../assets/loader-black.svg';

// FIXME: Move this into helpers/style since this is being used by
// NavLink
export const types = {
  // Blue background, white font
  L1: {
    fontSize: [10, 10, 10, 10, 12],
    fontFamily: 'sansSerif',
    fontWeight: 500,
    bg: 'blue.1',
    color: 'white',
    hoverBG: 'blue.3',
    hoverColor: 'white',
    letterSpacing: '0.250em',
    textTransform: 'uppercase',
    textDecoration: 'none',
    border: '0px none',
    minWidth: [200, 200, 230, 230, 260],
    maxWidth: [260, 260, 300, 300, 340],
    px: [20, 20, 20, 20, 20], // Padding X (horizontal) -- from styled-system
    py: [15, 15, 17, 17, 24], // Padding Y (vertical) -- from styled-system
    lineHeight: '1.5em',
    display: 'inline-block',
    textAlign: 'center',
    loadingAnimation,
    hoverLoadingAnimation: loadingAnimation,
  },
  L1mini: {
    fontSize: [10, 10, 10, 10, 12],
    fontFamily: 'sansSerif',
    fontWeight: 500,
    bg: 'blue.1',
    color: 'white',
    hoverBG: 'blue.3',
    hoverColor: 'white',
    letterSpacing: '0.250em',
    textTransform: 'uppercase',
    textDecoration: 'none',
    border: '0px none',
    minWidth: [70, 100],
    height: ['30px', '45px'],
    width: ['70px', 'auto'],
    px: 10, // Padding X (horizontal) -- from styled-system
    py: [10, 15, 17, 17, 17], // Padding Y (vertical) -- from styled-system
    display: 'inline-block',
    textAlign: 'center',
    loadingAnimation,
    hoverLoadingAnimation: loadingAnimation,
  },
  // Transparent background, white font, white border
  L2: {
    fontSize: [10, 10, 10, 10, 12],
    fontFamily: 'sansSerif',
    fontWeight: 500,
    bg: 'transparent',
    color: 'white',
    hoverColor: 'black',
    hoverBG: 'white',
    letterSpacing: '0.250em',
    textTransform: 'uppercase',
    textDecoration: 'none',
    border: '1px solid',
    hoverBorderColor: 'white',
    minWidth: [200, 200, 230, 230, 260],
    maxWidth: [260, 260, 300, 300, 340],
    px: [20, 20, 20, 20, 20], // Padding X (horizontal) -- from styled-system
    py: [15, 15, 17, 17, 24], // Padding Y (vertical) -- from styled-system
    lineHeight: '1.5em',
    display: 'inline-block',
    textAlign: 'center',
    loadingAnimation,
    hoverLoadingAnimation: loadingAnimationBlack,
  },
  // Transparent background, black font, black border
  L3: {
    fontSize: [10, 10, 10, 10, 12],
    fontFamily: 'sansSerif',
    fontWeight: 500,
    bg: 'transparent',
    color: 'black',
    hoverColor: 'white',
    hoverBG: 'black',
    letterSpacing: '0.250em',
    textTransform: 'uppercase',
    textDecoration: 'none',
    border: '1px solid',
    hoverBorderColor: 'black',
    minWidth: [200, 200, 230, 230, 260],
    maxWidth: [260, 260, 300, 300, 340],
    px: [20, 20, 20, 20, 20], // Padding X (horizontal) -- from styled-system
    py: [15, 15, 17, 17, 24], // Padding Y (vertical) -- from styled-system
    lineHeight: '1.5em',
    display: 'inline-block',
    textAlign: 'center',
    loadingAnimation: loadingAnimationBlack,
    hoverLoadingAnimation: loadingAnimation,
  },
  L3mini: {
    fontSize: [10, 10, 10, 10, 12],
    fontFamily: 'sansSerif',
    fontWeight: 500,
    bg: 'transparent',
    color: 'black',
    hoverColor: 'white',
    hoverBG: 'black',
    letterSpacing: '0.250em',
    textTransform: 'uppercase',
    textDecoration: 'none',
    border: '1px solid',
    hoverBorderColor: 'black',
    minWidth: [70, 100],
    maxWidth: [70, 100],
    height: ['30px', '45px'],
    width: ['70px', 'auto'],
    px: 10, // Padding X (horizontal) -- from styled-system
    py: [10, 15, 17, 17, 17], // Padding Y (vertical) -- from styled-system
    display: 'inline-block',
    textAlign: 'center',
    loadingAnimation: loadingAnimationBlack,
    hoverLoadingAnimation: loadingAnimation,
  },
  // Blue font link
  L4: {
    fontSize: [10, 10, 12, 12, 12],
    fontFamily: 'sansSerif',
    fontWeight: 500,
    bg: 'transparent',
    color: 'blue.0',
    letterSpacing: '0.150em',
    textTransform: 'uppercase',
    textDecoration: 'none',
    border: '0px none',
  },
  // global nav
  L5: {
    fontSize: [10, 10, 10, 10, 12],
    fontFamily: 'sansSerif',
    fontWeight: 500,
    bg: 'transparent',
    color: 'black',
    letterSpacing: '0.250em',
    textTransform: 'uppercase',
    textDecoration: 'none',
    border: '0px none',
  },
  // footer links
  L6: {
    fontSize: [10, 10, 10, 10, 11],
    fontFamily: 'sansSerif',
    fontWeight: 'normal',
    bg: 'transparent',
    color: 'black',
    letterSpacing: 'normal',
    textTransform: 'none',
    textDecoration: 'none',
    border: '0px none',
  },
  L7: {
    fontSize: [10, 10, 10, 10, 12],
    fontFamily: 'sansSerif',
    fontWeight: 'normal',
    bg: 'transparent',
    color: 'black',
    letterSpacing: 'normal',
    textTransform: 'none',
    textDecoration: 'none',
    px: 20,
    py: 5,
    border: '1px solid',
    borderColor: 'black',
    hoverBG: 'black',
    hoverColor: 'white',
    loadingAnimation: loadingAnimationBlack,
    hoverLoadingAnimation: loadingAnimation,
  },
  L8: {
    fontSize: [10, 10, 12, 12, 12],
    fontFamily: 'sansSerif',
    fontWeight: 500,
    bg: 'none',
    color: 'black',
    letterSpacing: [0, '0.150em'],
    textTransform: 'uppercase',
    textDecoration: 'none',
    border: '0px none',
  },
  L9: {
    fontSize: [10, 16, 16, 16, 16],
    fontFamily: 'sansSerif',
    fontWeight: 'normal',
    bg: 'transparent',
    color: 'black',
    letterSpacing: 'normal',
    textTransform: 'none',
    textDecoration: 'none',
    px: [3, 12, 12, 18, 18],
    py: [6, 12, 12, 18, 18],
    border: '1px solid',
    borderColor: 'black',
    hoverBG: 'black',
    hoverColor: 'white',
    loadingAnimation: loadingAnimationBlack,
    hoverLoadingAnimation: loadingAnimation,
  },
};

const determineElement = ({
  component,
  to,
}) => {
  if (component) {
    return component;
  }
  // if (!to && !href) {
  //   return Button;
  // }

  return to ? StyledLink : TextLink;
};

const Link = (props) => {
  const {
    children,
    type: styleType,
    to,
    href,
    component,
    buttonType: type,
    disable,
    ...passedProps } = props;

  const typeProps = {
    ...(types[styleType]
      ? {
        hoverColor: types[styleType].hoverColor
          ? types[styleType].hoverColor
          : 'black',
        ...types[styleType],
      }
      : {}),
  };

  const Element = determineElement(props);

  return (
    <Element
      {...typeProps}
      {...(type ? { type } : {})}
      {...(to ? { to } : { href })}
      {...(disable ? { disable: 'disable', disabled: 'disabled' } : {})}
      {...passedProps}
    >
      { children }
    </Element>
  );
};

Link.propTypes = {
  buttonType: PropTypes.string,
  children: PropTypes.any,
  display: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  type: PropTypes.oneOf(Object.keys(types)),
  to: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  href: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disable: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

export default Link;
