import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Heading } from '../../coreui';
import RadioGroup from '../../components/forms/RadioGroup';
import RadioLabel from '../../components/forms/RadioLabel';
import FollowUp from '../../components/isq/FollowUp';

const NetWorthFollowUp = ({
  isVisible,
  handleChange,
  value,
  followUp,
}) => {
  const [dirty, setDirty] = useState(false);

  const onChange = useCallback((e) => {
    setDirty(true);
    handleChange(e);
  }, [setDirty, handleChange]);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        Will this investment result in over 20% of your net worth being invested in illiquid investments?
      </Heading>
      <RadioGroup
        name="over20PctNetWorth"
        value={value}
        onChange={onChange}
        inputProps={{
          'data-test': 'over_20_pct_net_worth',
          required: true,
        }}
      >
        <RadioLabel
          value="Yes"
          label="Yes"
        />
        <RadioLabel
          value="No"
          label="No"
        />
      </RadioGroup>
      {
        value === 'Yes' && dirty &&
        <FollowUp
          subheader="Investing over 20% of your net worth into illiquid offerings is very risky. Are you comfortable with your concentration of illiquid assets?"
          name="over20PctNetWorthFollowUp"
          value={followUp}
          handleChange={handleChange}
          options={[
            {
              value: 'Yes',
              label: 'Yes',
            },
            {
              value: 'No, please cancel my investment',
              label: 'No, please cancel my investment',
            },
          ]}
        />
      }
    </>
  );
};

NetWorthFollowUp.propTypes = {
  isVisible: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.oneOf([
    'Yes',
    'No',
  ]),
  followUp: PropTypes.string,
};

export default NetWorthFollowUp;
