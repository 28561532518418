import types from './types';

export default {
  getPopularContentDataRequested: () => ({
    type: types.GET_POPULAR_CONTENT_REQUESTED,
  }),
  getPopularContentDataSucceeded: (data) => ({
    type: types.GET_POPULAR_CONTENT_SUCCEEDED,
    data,
  }),
  getPopularContentDataFailed: (error) => ({
    type: types.GET_POPULAR_CONTENT_FAILED,
    error,
  }),
};
