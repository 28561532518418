import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ScrollToTopOnMount,
  Hero,
  ExtendedFlex,
  Body,
  Loader,
  Link,
  Numeral,
  Formatters,
} from '../coreui';

import heroBannerImage from './assets/hero.jpg';
import imageLeftPanel from './assets/img_leftpanel.jpg';
import iconBalloons from '../assets/icon_balloons.svg';
import iconHoldPeriod from '../assets/icon_holdperiod.svg';
import iconCRE from '../assets/icon_cre.svg';
import iconHeart from '../assets/icon_heart.svg';
import iconLadder from '../assets/icon_ladder.svg';
import iconWallet from '../assets/icon_wallet.svg';

import Section from '../ioDetails/components/section';
import TriPanelSection from '../components/TriPanelSection';
import TriPanelSection1 from '../components/TriPanelSection/components/TriPanelSection1';
import TriPanelSection2 from '../components/TriPanelSection/components/TriPanelSection2';
import Culture from './components/Culture';
import CareersQuotes from './components/CareersQuotes';
import Graphic3ColCallout from '../components/Graphic3ColCallout';
import actions from './actions';
import JobListings from './components/JobListings';
import ScrollToHash from '../components/ScrollToHash';
import FadeIn from '../components/FadeIn';

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    this.props.careersListRequested();
  }

  render() {
    const {
      jobs,
      totalMembers,
      totalAmountFinanced,
      quotes,
    } = this.props;
    return (
      <>
        <ScrollToTopOnMount />
        <Hero
          image={heroBannerImage}
          header="Careers"
          subHeader="What it Means to be a Part of the RealtyMogul Team"
          bgPosition="74%"
        />
        <Section
          title="Join the team that's changing real estate investing"
        >
          <ExtendedFlex
            maxWidth={['90%', '90%', '100%', '1440px', '1440px']}
            mx="auto"
            flexDirection="column"
            align="center"
          >
            <FadeIn>
              <Body
                type="B1"
                textAlign="center"
                display="block"
                mx={40}
              >
                We are looking for smart, passionate individuals who want to join a
                dynamic team. We believe results speak for themselves. RealtyMogul
                was founded in 2012 with one mission: simplify real estate investing by
                connecting commercial real estate companies to capital from
                individual investors through an industry leading online platform.
                Today we are one of the largest, most recognizable brands in online
                real estate crowdfunding, with over <Numeral
                  value={totalMembers}
                  format={Formatters.INTEGER}
                /> users investing
                over <Numeral
                  value={totalAmountFinanced}
                  format={Formatters.SHORTENED_DOLLARS_WRITTEN}
                  upper={false}
                /> in capital into hundreds of real estate projects
                nationwide.
              </Body>
              <Link
                type="L1"
                to="#jobs"
                mt={40}
              >
                View Open Positions
              </Link>
            </FadeIn>
          </ExtendedFlex>
        </Section>
        <TriPanelSection>
          <TriPanelSection2
            topCardBg="gray.12"
            bottomCardBg="white"
            wrapperProps={{
              justify: 'flex-end',
            }}
            topCard={{
              header: 'Who we are',
              body: ['Founded in 2012, RealtyMogul has expert real estate teams located across the country actively looking for high-growth potential investment opportunities.', <br />, <br />, 'Our belief is that local knowledge of markets and opportunities allows us to forge partnerships with sponsors capable of taking advantage of the specific micro-market themes that will play out this year and beyond.'],
            }}
            bottomCard={{
              header: 'What we do',
              body: ['Our mission is to educate and empower investors to make smart investment decisions that help them reach their financial goals.', <br />, <br />, 'On the RealtyMogul Platform, members can invest in diverse, institutional-quality commercial real estate or in two Real Estate Investment Trusts (REITs), all of which have the potential to deliver growth and passive income.'],
            }}
          />
          <TriPanelSection1
            bgImage={imageLeftPanel}
          />
        </TriPanelSection>
        <Section
          title="Our Culture Derives from our Core Values"
          bg="gray.0"
        >
          <Culture />
        </Section>
        {
          quotes &&
          <Section
            title="What it’s like to work at Realtymogul"
          >
            {
            (quotes.length === 0 &&
            <ExtendedFlex
              position="relative"
              minHeight={300}
            >
              <Loader />
            </ExtendedFlex>
            ) || (
              <CareersQuotes quotes={quotes} />
            )
          }
          </Section>
        }
        <Section
          bg="gray.0"
          title="Benefits we offer"
        >
          <Graphic3ColCallout
            containerProps={{
              mt: 0,
            }}
            items={[
              {
                title: 'Medical, dental, & vision',
                image: iconHeart,
                body: 'We offer multiple flexible plans from top insurance providers. Coverage begins on day one.',
              },
              {
                title: 'Outstanding compensation',
                image: iconWallet,
                body: 'Top talent gets top pay, top salary, equity, 401K, and benefits.',
              },
              {
                title: 'Ground floor opportunity',
                image: iconLadder,
                body: 'You’ll be a key member of a well-capitalized, VC backed company as we build a sustainable, market leading brand.',
              },
            ]}
          />
          <Graphic3ColCallout
            items={[
              {
                title: 'Insights into real estate investing',
                image: iconCRE,
                body: 'Get a behind the scenes look at commercial real estate investing from the experts on our team.',
              },
              {
                title: 'Time Off',
                image: iconHoldPeriod,
                body: 'We offer a generous Flexible Time Off plan so employees can take the time off they deserve, two Floating Holidays because we recognize diversity, paid Parental Leave because family is very important to us, and six paid sick days.',
              },
              {
                title: 'Company events',
                image: iconBalloons,
                body: 'We make time to celebrate our successes with company-sponsored virtual parties, events, and happy hours.',
              },
            ]}
          />
        </Section>
        <ScrollToHash
          name="jobs"
        />
        <Section
          title="Open Positions"
        >
          {
            (jobs === undefined &&
            <ExtendedFlex
              position="relative"
              minHeight={300}
            >
              <Loader />
            </ExtendedFlex>
            ) || (
              <JobListings jobs={jobs} />
            )
          }
          <ExtendedFlex>
            <Body
              mt={60}
              mx={[20, 50]}
              fontSize={12}
              type="B2"
              lineHeight="3em"
              style={{
                textAlign: 'center',
              }}
            >
              RealtyMogul is an equal employment opportunity employer. Qualified
              candidates are considered for employment without regard to race,
              color, religion, gender (including gender identity, gender
              expression, change of sex, and transgender status), sexual
              orientation, national origin, ancestry, age, military or veteran
              status, physical or mental disability, medical condition, pregnancy,
              marital status, genetic information, or any other characteristic
              protected by applicable law. If you need assistance or an
              accommodation during the application process because of a
              disability, it is available upon request.
            </Body>
          </ExtendedFlex>
        </Section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  jobs: state.careers.jobs,
  quotes: state.careers.quotes,
  totalMembers: state.tokens.totalMembers,
  totalAmountFinanced: state.tokens.totalAmountFinanced,
});

const mapDispatchToProps = (dispatch) => ({
  careersListRequested: () => {
    dispatch(actions.careerListRequested());
  },
});

Careers.propTypes = {
  jobs: PropTypes.array,
  quotes: PropTypes.array,
  totalMembers: PropTypes.number,
  totalAmountFinanced: PropTypes.number,
  careersListRequested: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Careers);
