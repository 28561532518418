/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import {
  themeGet,
  space,
  width,
} from 'styled-system';

import {
  ExtendedBox,
} from '..';

const StyledTable = styled(ReactTable)`
  display: block;
  font-family: ${themeGet('fontFamily.sansSerif')};
  width: ${(props) => props.width || 'auto'};

  &.ReactTable {
    border: none;
    div.rt-table { overflow: visible; }
    div.rt-thead {
      &.-header {
        box-shadow: none;
        background: white;
        background-color: ${themeGet('colors.gray.0')};
      }


      .rt-th {
        border-right: none;
        text-align: left;
        ${space({ p: [10, 15] })};

        &:focus {
          outline: unset;
        }

        &.-sort-asc {
          position: relative;
          box-shadow: none;

          & > div > div > :last-child::after {
            _border-color: #000000 #000000 #505759 #000000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
            border-color: transparent transparent #505759 transparent;
            border-style: solid;
            border-width: 0 3.5px 4px 3.5px;
            content: '';
            height: 0;
            line-height: 0px;
            position: relative;
            right: -10px;
            bottom: calc(1em / 2 + 4px);
            width: 0;
          }
        }

        &.-sort-desc {
          position: relative;
          box-shadow: none;

          & > div > div > :last-child::after {
            _border-color: #505759 #000000 #000000 #000000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
            border-color: #505759 transparent transparent transparent;
            border-style: solid;
            border-width: 4px 3.5px 0 3.5px;
            content: '';
            height: 0;
            line-height: 0px;
            position: relative;
            right: -10px;
            top: calc(1em / 2 + 4px);
            width: 0;
          }
        }
      }

      .rt-resizer {
        z-index: 1;
      }
    }


    div.rt-thead .rt-th:first-child,
    div.rt-tbody .rt-td:first-child,
    div.rt-tfoot .rt-td:first-child {
      padding-left: calc(4% + 15px);
    }

    div.rt-thead .rt-th:last-child,
    div.rt-tbody .rt-td:last-child,
    div.rt-tfoot .rt-td:last-child  {
      padding-right: calc(4% + 15px);
    }
  }

  div.rt-tbody,
  div.rt-tfoot {
    overflow: hidden;
    box-shadow: none;

    .rt-td {
      border-right: none;
      min-height: 50px;
      justify-content: center;
      align-items: center;
      ${space({ p: [10, 15] })};
    }
  }

  .-even {
    background-color: ${themeGet('colors.gray.0')};
  }

  div.rt-tfoot {
    background-color: ${themeGet('colors.gray.12')};
  }

  .pagination-bottom .-pagination {
    padding-top: 20px;
    box-shadow: none;
    border-top: 0 none;
    ${width({ width: [320, 700, 'auto'] })};
    margin: 0 auto;
  }

  ${(props) => (
    // Apply responsive widths to the columns if defined.
    props.columns.map((column) => {
      if (typeof column.responsiveWidth === 'undefined') {
        return '';
      }
      // Crash the app if these values aren't set in development.
      if (process.env.NODE_ENV === 'development' && (typeof column.headerClassName === 'undefined' || typeof column.className === 'undefined')) {
        throw new Error('Both headerClassName and className must be set when using responsiveWidth.');
      }
      return column.responsiveWidth.map((value, index) => {
        if (value === null) {
          return '';
        }
        return `
          @media (min-width: ${props.theme.breakpoints[index]}) {
            .rt-th.${column.headerClassName},
            .rt-td.${column.className} {
              flex: ${value} 0 auto !important;
              width: ${value}px !important;
              max-width: ${value}px !important;
            }
          }
        `;
      });
    })
  )}
`;

const getDisplayValue = (types, value) => {
  return types.includes(value) ? 'block' : 'none';
};

const translateSizesToDisplay = (types) => {
  return [
    getDisplayValue(types, 'xs'),
    getDisplayValue(types, 's'),
    getDisplayValue(types, 'm'),
    getDisplayValue(types, 'l'),
    getDisplayValue(types, 'xl'),
  ];
};

const StyledTableWrapper = ({
  tableOutputs,
  columns,
  ...props
}) => {
  if (tableOutputs.length === 0) {
    return <StyledTable columns={columns} {...props} />;
  }

  return (
    tableOutputs.map((item) => (
      <ExtendedBox
        key={item.types.join('-')}
        display={translateSizesToDisplay(item.types)}
        {...(item.props ? item.props : {})}
      >
        <StyledTable
          {...props}
          columns={
            columns.filter(({ hidden = [] }) => item.types.reduce(
              (accum, type) => (accum && !hidden.includes(type)), true,
            ))
          }
        />
      </ExtendedBox>
    ))
  );
};

StyledTableWrapper.propTypes = {
  tableOutputs: PropTypes.arrayOf(PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl'])).isRequired,
    props: PropTypes.object,
  })),
  columns: PropTypes.array,
};

StyledTableWrapper.defaultProps = {
  tableOutputs: [],
};

export default StyledTableWrapper;
