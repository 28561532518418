import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import ReactHtmlParser from 'react-html-parser';
import { injectGlobal } from 'styled-components';

import {
  ExtendedFlex,
  Heading,
  ExtendedBox,
  Image,
  Data,
  Formatters,
  Body,
  Content,
} from '../../../coreui';

export const contentCSS = injectGlobal`
  .rm-coreui-content .asset-highlights  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const AssetInfo = ({
  asset,
}) => {
  return (
    <ExtendedBox
      w={1}
      pr={10}
      bg="white"
    >
      <Heading
        type="H3"
        m={0}
        display="block"
      >
        {asset.name}
      </Heading>
      <Data
        type="D1"
        m={0}
        display="block"
      >
        {asset.location}
      </Data>
      <ExtendedFlex
        align="center"
        flexDirection={['column', 'column', 'row', 'row', 'row']}
        justifyContent="space-between"
      >
        <ExtendedBox
          pl={(asset.active === '0') ? 9 : 0}
          position="relative"
          w={['100%', '100%', '45%', '45%', '45%']}
        >
          { (asset.image && asset.image.modal) ?
            <Image
              src={asset.image.modal}
              w="100%"
              maxWidth="492px"
              m={['0 auto', '0 auto', 0, 0, 0]}
            />
            : ''}
          {(asset.active === '0') &&
            <ExtendedBox
              position="absolute"
              bg="black"
              width="50%"
              textAlign="center"
              py={5}
              top={9}
              left={0}
            >
              <Data
                type="D4"
                color="white"
              >
                Completed
              </Data>
            </ExtendedBox> }
        </ExtendedBox>
        <ExtendedFlex
          flexDirection="column"
          justifyContent="flex-start"
          minWidth={['100%', '100%', '350px', '450px', '500px']}
          display="block"
          ml={[0, 0, 5, 10, 15]}
        >
          <ExtendedFlex
            flexDirection="row"
            justifyContent="space-between"
            borderBottom="1px solid black"
            py={0}
            px={10}
            alignItems="center"
          >
            <Data
              type="D3"
            >
              Investment Type
            </Data>
            <Data
              type="D2"
            >
              {asset.investmentType}
            </Data>
          </ExtendedFlex>
          <ExtendedFlex
            flexDirection="row"
            justifyContent="space-between"
            borderBottom="1px solid black"
            py={0}
            px={10}
            alignItems="center"
          >
            <Data
              type="D3"
            >
              Invested
            </Data>
            <Data
              type="D2"
            >
              {numeral(asset.amount).format(Formatters.DOLLARS)}
            </Data>
          </ExtendedFlex>
          <ExtendedFlex
            flexDirection="row"
            justifyContent="space-between"
            borderBottom="1px solid black"
            py={0}
            px={10}
            alignItems="center"
          >
            <Data
              type="D3"
            >
              Property Type
            </Data>
            <Data
              type="D2"
            >
              {asset.propertyType}
            </Data>
          </ExtendedFlex>
          <ExtendedFlex
            flexDirection="row"
            justifyContent="space-between"
            borderBottom={asset.displayRow ? '1px solid black' : undefined}
            py={0}
            px={10}
            alignItems="center"
          >
            <Data
              type="D3"
            >
              Date of Investment
            </Data>
            <Data
              type="D2"
            >
              {asset.dateOfInvestment}
            </Data>
          </ExtendedFlex>
          { asset.displayRow &&
            <ExtendedFlex
              flexDirection="row"
              justifyContent="space-between"
              py={0}
              px={10}
              alignItems="center"
            >
              <Data
                type="D3"
              >
                { (asset.active === '1') ? 'Total Property Value*' : 'Date Completed'}
              </Data>
              <Data
                type="D2"
              >
                { (asset.active === '1') ?
                  numeral(asset.totalValue).format(Formatters.DOLLARS) :
                  asset.dateCompleted }
              </Data>
            </ExtendedFlex> }
        </ExtendedFlex>
      </ExtendedFlex>
      <ExtendedBox
        display="block"
        pb={20}
        mt={30}
      >
        {
          (asset.whyWeLikeIt.length > 0) &&
          (asset.active === '1') &&
          <ExtendedBox>
            <Heading
              type="H6"
              m={0}
              display="block"
            >
              Highlights
            </Heading>
            {
              asset.whyWeLikeIt.map(
                (item) => {
                  return (
                    <ExtendedFlex
                      alignItems="estimatedReturnLow"
                      mt={20}
                    >
                      <ExtendedBox>
                        <Content>
                          <div className="asset-highlights">
                            {ReactHtmlParser(item.value)}
                          </div>
                        </Content>
                      </ExtendedBox>
                    </ExtendedFlex>
                  );
                },
              )
            }
          </ExtendedBox>
        }
        {
          (asset.active !== '1') &&
          <ExtendedBox>
            <Heading
              type="H6"
              m={0}
              display="block"
            >
              Investment Summary
            </Heading>
            <ExtendedFlex
              alignItems="estimatedReturnLow"
              mt={20}
            >
              <ExtendedBox>
                <Content>
                  {ReactHtmlParser(asset.investmentHighlights)}
                </Content>
              </ExtendedBox>
            </ExtendedFlex>
          </ExtendedBox>
        }
        { (asset.active === '1' && asset.displayRow) &&
          <Body
            type="B5"
            mt={15}
            display="block"
            color="gray.7"
          >
            *Value of underlying property in {asset.issuer} based on appraisals
            dated within 6 months of the original acquisition by Realty Mogul,
            Co. or Realty Mogul Commercial Capital, Co., as applicable.
          </Body> }
      </ExtendedBox>
    </ExtendedBox>
  );
};

AssetInfo.propTypes = {
  asset: PropTypes.object,
};

export default AssetInfo;
