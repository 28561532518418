/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';

import {
  ScrollToTopOnMount,
  ExtendedFlex,
  Heading,
} from '../coreui';
import PrivacyPolicy from './components/PrivacyPolicy';
import DisclaimerContent from '../components/DisclaimerContent';

export const Privacy = () => {
  return (
    <ExtendedFlex
      bg="#FFFFFF"
      flexDirection="column"
      justify="center"
      align="left"
      px="10%"
      py="5%"
      w={1}
    >
      <ScrollToTopOnMount />
      <Heading type="H6">Privacy Policy</Heading>
      <DisclaimerContent>
        <PrivacyPolicy />
      </DisclaimerContent>
    </ExtendedFlex>
  );
};
export default Privacy;
