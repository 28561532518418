import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import noop from 'lodash/noop';

import {
  Heading, Numeral, formatters, Body, InlineTextLink,
} from '../../../../coreui';
import Label from '../../../../components/forms/Label';
import Input from '../../../../components/forms/Input';

class RecurringAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlyAmount: null,
      monthlyAmountFocus: '',
    };

    this.addAmountValidator = this.addAmountValidator.bind(this);
  }

  addAmountValidator() {
    if (this.amountInput && window && window.hyperform) {
      window.hyperform.addValidator(this.amountInput, () => {
        const {
          monthlyAmount,
        } = this.state;
        const {
          minPerMonth,
          maxPerMonth,
        } = this.props;

        const valid = monthlyAmount >= minPerMonth
          && (maxPerMonth === null || monthlyAmount <= maxPerMonth);

        const errorMessage = maxPerMonth === null
          ? `Please enter at least ${numeral(minPerMonth).format(formatters.DOLLARS)}.`
          : `Please enter an amount between ${numeral(minPerMonth).format(formatters.DOLLARS)} and ${numeral(maxPerMonth).format(formatters.DOLLARS)}.`;
        this.amountInput.setCustomValidity(valid ? '' : errorMessage);
        return valid;
      });
    }
  }

  render() {
    const {
      heading,
      body,
      minPerMonth,
      maxPerMonth,
      isAccredited,
      forwardedRef,
      onChange,
    } = this.props;

    const inputName = 'monthly_amount';

    return (
      <>
        <Heading type="H6" textTransform="uppercase">
          {heading}
        </Heading>
        <Body type="B2">
          {body || (
            <>
              Congratulations, you are eligible to join the auto-invest
              program. Please input your desired monthly auto-invest amount{' '}
              <>
                {maxPerMonth !== null && (
                  <>
                    between{' '}
                    <Numeral
                      value={minPerMonth}
                      format={formatters.DOLLARS}
                    />{' '}
                    and{' '}
                    <Numeral
                      value={maxPerMonth}
                      format={formatters.DOLLARS}
                    />
                  </>
                )}
                {maxPerMonth === null && (
                  <>
                    of at least{' '}
                    <Numeral
                      value={minPerMonth}
                      format={formatters.DOLLARS}
                    />
                  </>
                )}{' '}
              </>{' '}
              in the field below.
            </>
          )}
        </Body>
        <Body
          type="B2"
        >
          By selecting to participate in the Auto Invest
          Program, you are agreeing to monitor and notify us in
          advance in the event that your continued participation in the Auto
          Invest Program would cause you to exceed applicable qualified
          purchaser limits as set forth in Regulation A of the Securities
          Act, and that you will terminate your participation in the
          program.
        </Body>
        <Label htmlFor="monthlyAmount">
          Monthly Auto Investment Amount
          {!isAccredited && (
            <Body type="B2" display="block">
              If you would like to invest more than the maximum amount per
              month, please submit a monthly withdrawal amount of{' '}
              <Numeral value={maxPerMonth} format={formatters.DOLLARS} />{' '}
              and contact us via phone at{' '}
              <InlineTextLink href="tel:+18777817062">
                877-781-7062
              </InlineTextLink>{' '}
              or via email at{' '}
              <InlineTextLink href="mailto:investor-help@realtymogul.com">
                investor-help@realtymogul.com
              </InlineTextLink>
              .
            </Body>
          )}
          <Input
            forwardedRef={(ref) => {
              this.amountInput = ref;
              this.addAmountValidator();
              if (forwardedRef) {
                forwardedRef(ref);
              }
            }}
            id="monthlyAmount"
            placeholder="$0"
            name={inputName}
            onFocus={() => {
              if (!this.state.monthlyAmountFocus) {
                this.setState({
                  monthlyAmountFocus: '$',
                });
              }
            }}
            onBlur={() => {
              if (!this.state.monthlyAmount) {
                this.setState({
                  monthlyAmountFocus: '',
                });
              }
            }}
            value={
              (this.state.monthlyAmount
                ? numeral(this.state.monthlyAmount).format(
                  formatters.DOLLARS,
                )
                : this.state.monthlyAmountFocus) || ''
            }
            onChange={
              // This is to prevent a warning. We handle it via onInput.
              noop
            }
            onInput={(e) => {
              const value = e.target.value
                // Replace everything after the period.
                .replace(/\..*$/, '')
                // Replace non-digits.
                .replace(/[\D]/g, '');
              if (value <= maxPerMonth || maxPerMonth === null) {
                const monthlyAmount =
                  value && parseInt(value, 10) > 0
                    ? value.substring(0, 12)
                    : '';
                this.setState({
                  monthlyAmount,
                });
                onChange({
                  ...e,
                  target: {
                    ...e.target,
                    name: inputName,
                    value: monthlyAmount,
                  },
                });
              }
            }}
            data-test="monthly_amount"
            required
          />
        </Label>
      </>
    );
  }
}

RecurringAmount.propTypes = {
  heading: PropTypes.node,
  body: PropTypes.node,
  minPerMonth: PropTypes.number.isRequired,
  maxPerMonth: PropTypes.number,
  isAccredited: PropTypes.bool,
  forwardedRef: PropTypes.func,
  onChange: PropTypes.func,
};

RecurringAmount.defaultProps = {
  heading: 'Enter your recurring investment amount',
  onChange: noop,
};

export default RecurringAmount;
