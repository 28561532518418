import React from 'react';
import PropTypes from 'prop-types';
import BigNumber from '../../../components/BigNumber';

import {
  Box,
  ExtendedBox,
  NavLink,
  Data,
  Flex,
  Image,
} from '../../../coreui';

import ClickableEnvironmentInCarousel from '../../components/ClickableEnvironmentInCarousel';
import ModalLink from './ModalLink';
import FadeIn from '../../../components/FadeIn';

const EarnPassiveIncomeSection2 = ({
  slideInfo,
  opacity,
  toggleModal,
}) => {
  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
    >
      <Box
        w={1}
        mt={[20, 40, 0, 0, 0]}
        px={['15%', '25%', '10%', '10%', '9.9%']}
      >
        <Box
          w={0.8}
          ml={[0, 0, 0, 0, 0]}
          mb={[30, 40, 0, 0, 0]}
        >
          <Flex
            flexDirection="column"
            align="center"
            justify="center"
          >
            <FadeIn
              fadeInIndex={4}
            >
              {
              slideInfo.image &&
                <Image
                  src={slideInfo.image}
                  width="100%"
                  maxWidth="610px"
                  IEAspectRatioFix
                  mb={[10, 15, 15, 20, 20]}
                  opacity={opacity}
                />
              }
              {
              slideInfo.number &&
              <BigNumber
                number={slideInfo.number}
                type="money"
              />
              }
            </FadeIn>
            <FadeIn
              fadeInIndex={4}
            >
              <Data
                type="D1"
                maxWidth="100%"
                display="block"
                textAlign="center"
                lineHeight="2em"
                opacity={opacity}
              >
                {slideInfo.captionText}
              </Data>
            </FadeIn>
            {
              slideInfo.disclosureTooltipText ?
                <ModalLink
                  handleClick={toggleModal}
                  slideInfo={slideInfo}
                /> :
                null
            }
          </Flex>
        </Box>
        <ExtendedBox
          display={['block', null, 'none']}
        >
          <ClickableEnvironmentInCarousel>
            <FadeIn
              fadeInIndex={5}
            >
              <NavLink
                type="L1"
                to={slideInfo.linkButtonDestination}
                opacity={opacity}
                mb={[10, 20, 0, 0, 0]}
              >
                Learn more
              </NavLink>
            </FadeIn>
          </ClickableEnvironmentInCarousel>
        </ExtendedBox>
      </Box>
    </Box>
  );
};

EarnPassiveIncomeSection2.propTypes = {
  opacity: PropTypes.string,
  slideInfo: PropTypes.object,
  toggleModal: PropTypes.func,
};

export default EarnPassiveIncomeSection2;
