import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledLabel,
  dataTypes,
} from '../../coreui';

const Label = ({
  id,
  ...props
}) => {
  return (
    <StyledLabel
      htmlFor={props.id}
      // eslint-disable-next-line
      {...dataTypes.D4}
      color="gray.10"
      my={25}
      w={1}
      maxWidth={800}
      pb={15}
      // eslint-disable-next-line
      {...props}
    />
  );
};

Label.propTypes = {
  id: PropTypes.string,
};

export default Label;
