import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Body,
  InlineTextLink,
} from '../../coreui';

const TableCell = (props) => {
  const {
    column,
    value,
    original,
  } = props;
  return (
    <ExtendedFlex
      alignItems="center"
      justify={column.id === 'department' ? 'center' : undefined}
      m={0}
    >
      <Body
        type="B3"
        m={0}
      >
        {column.id === 'title' ? (
          <InlineTextLink
            href={original.url}
            target="_blank"
          >
            {value}
          </InlineTextLink>
        ) : value}
      </Body>
    </ExtendedFlex>
  );
};

TableCell.propTypes = {
  column: PropTypes.object,
  value: PropTypes.node,
  original: PropTypes.object,
};

export default TableCell;
