import divImg from '../assets/icon_diversification.svg';
import passImg from '../assets/icon_income.svg';
import growImg from '../assets/icon_growth.svg';

export default {
  hero: {
    header: 'Leveraging Commercial Real Estate For Retirement',
    subheader: 'Strategies for diversification and passive income',
  },
  tools: [
    {
      image: divImg,
      title: 'DIVERSIFICATION',
      body: 'Real estate has a low (or negative) correlation to other asset classes, which means REITs may shield IRAs from market volatility.',
    },
    {
      image: passImg,
      title: 'PASSIVE INCOME',
      body: 'REITs are designed to generate monthly or quarterly distributions, which investors may elect to automatically reinvest.',
    },
    {
      image: growImg,
      title: 'LONG-TERM GROWTH',
      body: 'Over time, real estate has the potential to grow in value and may even outpace inflation.',
    },
  ],
  overview: {
    title: 'REITS: A RETIREMENT INVESTMENT TOOL',
    subtitle: 'REITs are known for their objective of regular, passive income, because they are <em>required</em> to distribute at least 90% of their annual taxable income to investors on a yearly basis through distributions. Another characteristic of commercial real estate is the potential to protect against inflation. This benefit arises because property values and rents have historically been correlated with growth in inflation.',
  },
  buildWealth: {
    title: 'Build Wealth For Your Future',
  },
  getStarted: {
    title: 'GET STARTED QUICKLY',
  },
  faq: {
    title: 'FREQUENTLY ASKED QUESTIONS ABOUT RETIREMENT INVESTING',
  },
  insight: {
    title: 'GAIN NEW INVESTING INSIGHT',
  },
  disclosure: [
    'Bloomberg Barclays US Aggregate Bont TR USD data source from MorningStar',
    '(https://performance.morningstar.com/Performance/index-c/performance-return.action?t=XIUSA000MC).',
    'S&P 500 Index and NARIT Equity REIT Index data sourced from NCREIF (NCREIF.org)',
  ],
};
