/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import {
  ScrollToTopOnMount,
  ExtendedFlex,
  ExtendedBox,
  Hero,
} from '../coreui';

import hero1031 from '../assets/1031_Hero.jpg';
import Section from '../ioDetails/components/section';
import Graphic3ColCallout from '../components/Graphic3ColCallout';
import data from './data';
import Overview from '../investmentOptions/components/overview';
import Access from './components/accessNoCheckmark';
import ImageFrame from './components/imageFrame';
import FundProperty from './components/fundProperty';
import BrowseLink from '../components/BrowseLink';
import RelatedCategoryContent from '../knowledgeCenter/components/RelatedCategoryContent';

const TenThirtyOneExchange = ({
  total1031InvestmentAmount,
  isLoggedIn,
}) => {
  return (
    <ExtendedBox>
      <ScrollToTopOnMount />
      <Helmet>
        <meta name="description" content="Invest immediately in a 1031 exchange with flexible amounts. We offer professionally managed, investment-grade real estate opportunities that include full due diligence packets, with no 30-day escrow period. We are here to make the process simple." />
      </Helmet>
      <ExtendedFlex>
        <Hero
          image={hero1031}
          header="TAKE ADVANTAGE OF TAX-DEFFERED INVESTING"
          subHeader="1031 EXCHANGE OPPORTUNITIES ARE AVAILABLE FOR IMMEDIATE INVESTMENT"
          bgPosition="74%"
        />
      </ExtendedFlex>
      <Section>
        <Graphic3ColCallout
          title="Why Consider Our 1031 Platform"
          items={data.platform}
        />
        <BrowseLink
          isLoggedIn={isLoggedIn}
          anonText="Get Started"
        />
      </Section>
      <ImageFrame
        isLoggedIn={isLoggedIn}
        total1031InvestmentAmount={total1031InvestmentAmount}
      />
      <Section
        title={data.overview.title}
      >
        <Overview
          subtitle={data.overview.subtitle}
        />
        <BrowseLink
          anonText="Get Started"
          isLoggedIn={isLoggedIn}
        />
      </Section>
      <Access
        {...data.access}
        bg="gray.0"
      />
      <RelatedCategoryContent
        title="LEARN MORE ABOUT 1031 INVESTING"
        topic="1031_exchange"
        category="1031_exchange"
        bg="white"
      />
      <FundProperty
        isLoggedIn={isLoggedIn}
        bg="gray.0"
      />
    </ExtendedBox>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.app.user.isLoggedIn,
  total1031InvestmentAmount: state.tenThirtyOneExchange.total1031InvestmentAmount,
});

TenThirtyOneExchange.propTypes = {
  isLoggedIn: PropTypes.bool,
  total1031InvestmentAmount: PropTypes.number,
};

export default connect(mapStateToProps)(TenThirtyOneExchange);
