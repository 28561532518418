import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import uniqueId from 'lodash/uniqueId';

import {
  ExtendedFlex,
  Heading,
  ExtendedBox,
  VisibilitySensor,
  Link,
} from '../../coreui';

import salesforceActions from '../../salesforce/actions';

import FadeIn from '../../components/FadeIn';
import heroBannerImage from '../../assets/HP_hero_LA.jpg';
import v1Hero from '../../assets/v1-hero.jpg';
import v2Hero from '../../assets/v2-hero.jpg';

export const redirectToUserRegister = (history) => {
  history.push('/investment-opportunities');
};

class Hero extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      email: '',
      formSubmitted: false,
      strings: {
        heading: 'Invest in Real Estate',
        subHeading: 'Build the future you want',
        copy: 'Access Institutional Quality Investments',
        image: heroBannerImage,
        link: '/investment-opportunities',
        linkCopy: 'VIEW INVESTMENTS',
        color: 'white',
        fontSize: [18, 18, 20, 20, 28],
        maxWidth: '100%',
        textAlign: 'center',
      },
    };

    this.id = uniqueId('salesforceWebToLeadSubmission-');

    this.handleSensorChange = this.handleSensorChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  updateStrings() {
    const variations = {
      heading: '',
      subHeading: 'Build the real estate portfolio that’s right for you',
      copy: 'Invest in institutional-quality commercial real estate.',
      fontSize: [28, 36, 36, 44, 52],
    };

    if (this.props.variant === 'v1') {
      this.setState((state) => {
        return {
          strings: {
            ...state.strings,
            ...variations,
            image: v1Hero,
            maxWidth: '65%',
          },
        };
      });
    } else {
      this.setState((state) => {
        return {
          strings: {
            ...state.strings,
            ...variations,
            image: v2Hero,
            color: 'black',
            textAlign: 'left',
            maxWidth: '40%',
          },
        };
      });
    }
  }

  componentDidMount() {
    if (this.props.variant === 'none') return;
    this.updateStrings();
  }

  componentDidUpdate(prevProps) {
    if (this.state.formSubmitted
        && this.props.success !== prevProps.success
        && this.props.id === this.id) {
      redirectToUserRegister(this.props.history);
    }
    if (prevProps.variant !== this.props.variant) this.updateStrings();
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible,
      });
    }
  }

  handleEmailChange({ target: { value: email } }) {
    this.setState({ email });
    this.props.cookies.set(
      'rm_captured_email',
      email, {
        maxAge: 3600,
      },
    );
  }

  handleClick() {
    if (!this.state.formSubmitted) {
      this.submitForm();
    }
  }

  handleKeyDown({
    key,
    target,
  }) {
    if (key === 'Enter') {
      target.blur();
      if (!this.state.formSubmitted) {
        this.submitForm();
      }
    }
  }

  submitForm() {
    this.setState({
      formSubmitted: true,
    });

    // If an email wasn't set, there's no need to POST the form.
    if (!this.state.email) {
      redirectToUserRegister(this.props.history);
      return;
    }

    // Attempt to POST the form.
    this.props.postWebToSalesforceRequested({
      id: this.id,
      data: {
        email: this.state.email,
        recordType: '0120f000001NTSZ',
      },
    });
  }

  render() {
    const { isVisible, strings } = this.state;
    const { variant } = this.props;

    return (
      <ExtendedBox
        background={variant === 'v1' ? `linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, 0.3)), url(${strings.image})` : `url(${strings.image})`}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="center center"
        height="100vh"
      >
        <ExtendedFlex
          justify="center"
          height="100%"
          align={strings.textAlign}
          flexDirection="column"
          px={20}
        >
          <FadeIn>
            <Heading
              type="H1"
              color="white"
              mb={20}
              opacity={isVisible ? '1' : '0'}
              textAlign="center"
            >
              {strings.heading}
            </Heading>
            <Heading
              type="H5"
              color={strings.color}
              fontSize={strings.fontSize}
              fontWeight={variant === 'v1' ? 800 : 400}
              fontFamily={variant === 'v2' ? 'serif' : 'sansSerif'}
              display="block"
              maxWidth={strings.maxWidth}
              textAlign={strings.textAlign}
              mt={0}
              mb={20}
              ml={variant === 'v2' ? [0, 0, 350, 350, 400] : null}
              textTransform="uppercase"
              opacity={isVisible ? '1' : '0'}
            >
              {strings.subHeading}
            </Heading>
          </FadeIn>
          <VisibilitySensor
            callback={this.handleSensorChange}
          />
          <FadeIn
            fadeInIndex={3}
          >
            <Heading
              type="H6"
              color={strings.color}
              display="block"
              maxWidth={variant === 'v2' ? '20%' : '100%'}
              textAlign={strings.textAlign}
              mt={0}
              mb={[40, 40, 100, 100, 100]}
              ml={variant === 'v2' ? [0, 0, 350, 350, 400] : null}
              opacity={isVisible ? '1' : '0'}
            >
              {strings.copy}
            </Heading>
            <ExtendedFlex
              mb={[60, 60, 120, 120, 120]}
              opacity={isVisible ? '1' : '0'}
              flexDirection={['column', null, null, 'row']}
              align="center"
              ml={variant === 'v2' ? [0, 0, 350, 350, 400] : null}
              justify={strings.textAlign}
            >
              <Link
                to={strings.link}
                type="L1"
              >
                {strings.linkCopy}
              </Link>
            </ExtendedFlex>
          </FadeIn>
        </ExtendedFlex>
      </ExtendedBox>
    );
  }
}

const mapStateToProps = (state) => ({
  id: state.salesforce.id,
  success: state.salesforce.success,
});

const mapDispatchToProps = (dispatch) => ({
  postWebToSalesforceRequested: (data) => {
    dispatch(salesforceActions.postWebToSalesforceRequested(data));
  },
});

Hero.propTypes = {
  postWebToSalesforceRequested: PropTypes.func,
  id: PropTypes.string,
  success: PropTypes.bool,
  history: PropTypes.object,
  cookies: PropTypes.object,
  variant: PropTypes.string,
};

export default withCookies(withRouter(connect(mapStateToProps, mapDispatchToProps)(Hero)));
