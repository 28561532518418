import React from 'react';
import PropTypes from 'prop-types';

import GlossaryAccordionHeading from './GlossaryAccordionHeading';
import AccordionReveal from './AccordionReveal';

import {
  ExtendedFlex,
} from '../../..';

import ScrollToHash from '../../../../components/ScrollToHash';

class GlossaryAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndexes: [],
    };

    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  toggleAccordion(id) {
    this.setState((state) => ({
      activeIndexes: state.activeIndexes.includes(id) ?
        state.activeIndexes.filter(
          (i) => {
            return i !== id;
          },
        ) :
        [
          ...state.activeIndexes,
          id,
        ],
    }));
  }

  render() {
    const {
      items,
      contentIsHtml,
    } = this.props;

    const {
      activeIndexes,
    } = this.state;

    return (
      <ExtendedFlex
        flexDirection="column"
        justify="center"
        align="center"
        w={1}
        mt={0}
        pt={20}
        pb={20}
        bg="white"
      >
        {
          items.map(
            (item) => {
              let isActive = activeIndexes.includes(item.id);
              const nameAttr = item.header.replace(/\s+/g, '-').toLowerCase();
              const activeTerm = window.location.hash.replace('#', '');
              isActive = (nameAttr === activeTerm) ? true : isActive;
              return (
                <ExtendedFlex
                  key={item.id}
                  w={1}
                  flexDirection="column"
                  py={5}
                >
                  <div name={nameAttr} />
                  <ScrollToHash
                    name={nameAttr}
                  />
                  <GlossaryAccordionHeading
                    content={item.header}
                    id={item.id}
                    isActive={isActive}
                    handleClick={this.toggleAccordion}
                  />
                  <AccordionReveal
                    content={item.content}
                    bodyType="B5"
                    isActive={isActive}
                    contentIsHtml={contentIsHtml}
                  />
                </ExtendedFlex>
              );
            },
          )
        }
      </ExtendedFlex>
    );
  }
}

GlossaryAccordion.propTypes = {
  items: PropTypes.array,
  contentIsHtml: PropTypes.bool,
};

export default GlossaryAccordion;
