import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  NavLink,
} from '../../..';

const ArticleCardViewDetails = ({
  url,
}) => {
  return (
    <ExtendedBox
      pt={20}
      px={10}
      position="absolute"
      bottom="20px"
    >
      <NavLink
        data-test="article-more-link"
        type="L4"
        to={url}
        withActiveStyle={false}
      >
        Read More &gt;
      </NavLink>
    </ExtendedBox>
  );
};

ArticleCardViewDetails.propTypes = {
  url: PropTypes.string,
};

ArticleCardViewDetails.defaultProps = {
  url: '/',
};

export default ArticleCardViewDetails;
