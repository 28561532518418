import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Body,
  Numeral,
  Formatters,
  Link,
  InlineTextLink,
} from '../../coreui';

import Section from '../../ioDetails/components/section';
import FadeIn from '../../components/FadeIn';

import TriPanelSection from '../../components/TriPanelSection';
import TriPanelSection1 from '../../components/TriPanelSection/components/TriPanelSection1';
import TriPanelSection2 from '../../components/TriPanelSection/components/TriPanelSection2';
import ReitDifferences from './ReitDifferences';

import imageLeftPanel from '../assets/img_leftpanel.jpg';

const NoOwnershipOptions = ({
  mogulReit1DistributionDuration,
  mogulReit1DistributionAmount,
  mogulReit2DistributionDuration,
  mogulReit2DistributionAmount,
  mogulReit2LastDistributionDeclared,
}) => {
  return (
    <>
      <Section title="Available when you Invest in The Income REIT or The Apartment Growth REIT">
        <ExtendedFlex
          maxWidth={['90%', '90%', '100%', '1440px', '1440px']}
          mx="auto"
          flexDirection="column"
          align="center"
        >
          <FadeIn>
            <Body type="B1" textAlign="center" display="block" mx={40} w={1}>
              Invest into one of our Real Estate Investment Trusts (REITs) to
              get started. Upon active enrollment, The Income REIT and The Apartment Growth REIT
              investors on our platform can sit back and watch their
              investments grow month-after-month automatically. If you’re not
              sure which of our REITs may be right for you, tell us what
              you’re looking for below.
            </Body>
            <ExtendedFlex flexDirection={['column', null, 'row']}>
              <Link
                type="L3"
                to="/investment-opportunity/193744"
                mt={40}
                mr={[0, null, 60]}
              >
                I&apos;m looking for income
              </Link>
              <Link type="L1" to="/investment-opportunity/274949" mt={40}>
                I&apos;m looking for growth
              </Link>
            </ExtendedFlex>
          </FadeIn>
        </ExtendedFlex>
      </Section>
      <TriPanelSection>
        <TriPanelSection1
          minHeight={[460, 550, 900, 1200]}
          bgImage={imageLeftPanel}
          header="Investing in Real estate Investment Trusts (REITS)"
          body={[
            'A single investment into a REIT can provide investors with an instant, diversified portfolio of multiple properties through just a single investment.',
            'Here at RealtyMogul, we offer two Real Estate Investment Trusts with different but complementary strategies.',
          ]}
        />
        <TriPanelSection2
          topCardBg="gray.0"
          bottomCardBg="white"
          topCard={{
            header: 'The Income REIT',
            body: (
              <>
                Since inception, The Income REIT has distributed between 6% and 8%
                (net of fees) annualized based on purchase price. There have
                been {mogulReit1DistributionDuration} of consecutive
                distributions to investors totaling over{' '}
                {
                  <Numeral
                    value={mogulReit1DistributionAmount}
                    format={Formatters.SHORTENED_DOLLARS_POINTS_WRITTEN}
                    upper={false}
                  />
                }
                .
              </>
            ),
            link: (
              <Link type="L4" to="/investment-opportunity/193744">
                Learn More &gt;
              </Link>
            ),
          }}
          bottomCard={{
            header: 'The Apartment Growth REIT',
            body: (
              <>
                The Apartment Growth REIT deploys a value-add strategy focused on the
                multifamily asset class. We aim to implement capital
                improvements that allow for potential increases to rents. The
                net effect is potential increases in property value and upside
                for investors. Since inception, The Apartment Growth REIT has distributed{' '}
                {
                  <Numeral
                    value={mogulReit2LastDistributionDeclared}
                    format={Formatters.PERCENTAGE2}
                  />
                }{' '}
                (net of fees) annualized based on purchase price. There have
                been {mogulReit2DistributionDuration} of consecutive
                distributions to investors totaling over{' '}
                {
                  <Numeral
                    value={mogulReit2DistributionAmount}
                    format={Formatters.SHORTENED_DOLLARS_POINTS_WRITTEN}
                    upper={false}
                  />
                }
                .
              </>
            ),
            link: (
              <Link type="L4" to="/investment-opportunity/274949">
                Learn More &gt;
              </Link>
            ),
          }}
        />
      </TriPanelSection>
      <Section
        title="What are the Differences between our REITs?"
        bg="gray.1"
      >
        <ReitDifferences />
      </Section>
      <Section title="Ready to Make your money work for you?  ">
        <ExtendedFlex
          maxWidth={['90%', '90%', '100%', '1440px', '1440px']}
          mx="auto"
          flexDirection="column"
          align="center"
        >
          <FadeIn>
            <Body type="B1" textAlign="center" display="block" mx={40} w={1}>
              Invest into one of our REITs to gain access to Auto Invest and
              watch your investments grow month-after-month for you
              automatically. If you have any questions, feel free to contact a
              member of our Investor Relations team at{' '}
              {
                <InlineTextLink href="to:8777817062">
                  877-781-7062
                </InlineTextLink>
              }{' '}
              or email{' '}
              {
                <InlineTextLink href="mailto:investor-help@realtymogul.com">
                  investor-help@realtymogul.com
                </InlineTextLink>
              }
              .
            </Body>
            <ExtendedFlex flexDirection={['column', null, 'row']}>
              <Link
                type="L3"
                to="/investment-opportunity/193744"
                mt={40}
                mr={[0, null, 60]}
              >
                Get Started with The Income REIT
              </Link>
              <Link type="L1" to="/investment-opportunity/274949" mt={40}>
                Get Started with The Apartment Growth REIT
              </Link>
            </ExtendedFlex>
          </FadeIn>
        </ExtendedFlex>
      </Section>
    </>
  );
};

NoOwnershipOptions.propTypes = {
  mogulReit1DistributionDuration: PropTypes.number,
  mogulReit1DistributionAmount: PropTypes.number,
  mogulReit2DistributionDuration: PropTypes.number,
  mogulReit2DistributionAmount: PropTypes.number,
  mogulReit2LastDistributionDeclared: PropTypes.number,
};

export default NoOwnershipOptions;
