import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Heading,
  Link,
} from '../../..';

const ArticleCardHeadline = ({
  title,
  url,
}) => {
  return (
    <Box
      px={10}
    >
      <Link
        data-test="article-heading-link"
        to={url}
        m={0}
      >
        <Heading
          type="H6"
          display="block"
          m={0}
        >
          { title }
        </Heading>
      </Link>
    </Box>
  );
};

ArticleCardHeadline.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};

ArticleCardHeadline.defaultProps = {
  title: '',
  url: '/',
};

export default ArticleCardHeadline;
