import watchUpdateKnowledgeCenterFilterRequested from './updateKnowledgeCenterFilterSaga';
import watchGetMoreArticlesRequested from './getMoreArticlesSaga';
import watchGetArticleQueueDataRequested from './getArticleQueue';

import articleSagas from '../article/sagas';
import videoSagas from '../video/sagas';
import searchSagas from '../search/sagas';

export default [
  watchUpdateKnowledgeCenterFilterRequested(),
  watchGetMoreArticlesRequested(),
  watchGetArticleQueueDataRequested(),
  ...articleSagas,
  ...videoSagas,
  ...searchSagas,
];
