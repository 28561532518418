import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  ExtendedBox,
  Heading,
  Data,
  Body,
  Link,
  Image,
  ScrollToTopOnMount,
} from '../../../coreui';

const Items = ({
  items,
}) => {
  return (
    <ExtendedFlex
      w={1}
      mt={80}
      px={20}
      flexDirection="row"
      maxWidth={['auto', 'auto', 'auto', 1240, 1640]}
      wrap="wrap"
      justify="flex-start"
      align="center"
    >
      <ScrollToTopOnMount />
      {
        items.map(
          (item) => {
            return (
              <ExtendedBox
                w={1}
                key={item.id}
              >
                <ExtendedFlex
                  p={[20, 20, 20, 20, 30]}
                  position="relative"
                  flexDirection="row"
                  transition="box-shadow 0.3s linear"
                  w={1}
                  bg="white"
                  mb={10}
                >
                  {/* First container. */}
                  <ExtendedBox
                    display={['none', 'block']}
                    w={[1, 220, 220, 220, 275]}
                    height={[1, 120, 120, 120, 150]}
                  >
                    <Link
                      display="block"
                      to={item._links.alias}
                      withActiveStyle={false}
                      width="100%"
                      height="100%"
                    >
                      {
                        item.image._links && item.image._links.listing &&
                        <Image
                          w={1}
                          height="100%"
                          src={item.image._links.listing}
                          hoverColorize
                          hover
                          transition="0.2s linear filter"
                          zIndex={2}
                        />
                      }
                    </Link>
                  </ExtendedBox>

                  {/* Spacer. */}
                  <ExtendedBox
                    w={[20, 20, 40]}
                    display={['none', 'block']}
                  />

                  {/* Second container. */}
                  <ExtendedFlex
                    flexDirection="column"
                    flex="1 1 325px"
                  >
                    <Data
                      type="D4"
                      m={0}
                    >
                      {item.type} {item.category.label && ` / ${item.category.label}`}
                    </Data>
                    <Heading
                      type="H6"
                      mt={20}
                      mb={5}
                    >
                      <Link
                        to={item._links.alias}
                        color="inherit"
                      >
                        {item.title}
                      </Link>
                    </Heading>
                    {
                      item.excerpt &&
                      <Body
                        type="B3"
                        dangerouslySetInnerHTML={{ __html: item.excerpt }}
                        m={0}
                      />
                    }
                    <Link
                      type="L4"
                      to={item._links.alias}
                      mt={20}
                    >
                      {item.type === 'video' ? 'Watch Video' : 'Read More'}
                    </Link>
                  </ExtendedFlex>
                </ExtendedFlex>
              </ExtendedBox>
            );
          },
        )
      }
    </ExtendedFlex>
  );
};

Items.propTypes = {
  items: PropTypes.array,
};

export default Items;
