import types from './types';

export default {
  getVideoRequested: (id) => ({
    type: types.GET_VIDEO_REQUESTED,
    id,
  }),
  getVideoSucceeded: (data) => ({
    type: types.GET_VIDEO_SUCCEEDED,
    data,
  }),
  getVideoFailed: (error) => ({
    type: types.GET_VIDEO_FAILED,
    error,
  }),
};
