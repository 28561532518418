import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Body,
  Image,
  Heading,
} from '../../coreui';

import SliderContainer from '../../components/SliderContainer';
import FadeIn from '../../components/FadeIn';

const LocationSlide = (props) => {
  const {
    image,
    title,
    prefix,
    address,
    city,
    state,
    zip,
    phone,
  } = props;

  return (
    <SliderContainer
      py={['5%', '10%', '10%', '10%', '10%']}
    >
      <Image
        src={image}
        w={[1]}
        height="100%"
      />
      <ExtendedFlex
        flexDirection="column"
        boxSizing="border-box"
        p="6.25%"
        pr="2%"
        pb={0}
      >
        <FadeIn>
          <Heading
            type="H8"
            my={0}
          >
            Our Headquarters
          </Heading>
          <Heading
            type="H2"
            my="8.333%"
          >
            {title}
          </Heading>
          <Body
            type="B1"
            m={0}
          >
            {prefix && (
              <>
                {prefix}
                <br />
              </>
            )}
            {address}<br />
            {city}, {state} {zip}
            <br />
            <br />
            {phone}
          </Body>
        </FadeIn>
      </ExtendedFlex>
    </SliderContainer>
  );
};

LocationSlide.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  prefix: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  phone: PropTypes.string,
};

export default LocationSlide;
