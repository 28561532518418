import React from 'react';
import PropTypes from 'prop-types';

import {
  Heading,
  TooltipIcon,
  Tooltip,
  ExtendedFlex,
  Body,
} from '../../coreui';
import RadioGroup from '../../components/forms/RadioGroup';
import RadioLabel from '../../components/forms/RadioLabel';

const Need1031Exchange = ({
  value,
  handleChange,
}) => {
  return (
    <>
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        1031 EXCHANGE
      </Heading>
      <ExtendedFlex
        w={1}
        flexDirection="row"
        align="center"
      >

        <Body
          type="B2"
        >
          Are you in the process of, or will you be needing a 1031 Exchange?
        </Body>
        <TooltipIcon
          tooltipId="need1031Tooltip"
          display="inline-block"
          ml={5}
        />
      </ExtendedFlex>
      <Tooltip id="need1031Tooltip">
        <ExtendedFlex
          flexDirection="column"
        >
          <Body
            type="B3"
          >
            A 1031 exchange is a strategy for deferring the capital gains tax
            from the sale of a property. By exchanging a relinquished property
            for like-kind real estate, owners may be able to defer their federal
            taxes and use the proceeds for the purchase of replacement property.
          </Body>
        </ExtendedFlex>
      </Tooltip>
      <RadioGroup
        name="need1031"
        value={value}
        onChange={handleChange}
        inputProps={{
          'data-test': '1031_exchange',
          required: true,
        }}
      >
        <RadioLabel
          value="1"
          label="Yes"
        />
        <RadioLabel
          value="0"
          label="No"
        />
      </RadioGroup>
    </>
  );
};

Need1031Exchange.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.oneOf([
    '1',
    '0',
  ]),
};

export default Need1031Exchange;
