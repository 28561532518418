import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  ExtendedFlex,
  Link,
  ExtendedBox,
  Heading,
  UnlockOpportunities,
  ScrollToTopOnMount,
  Video as Player,
} from '../../coreui';

import actions from './actions';

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    this.props.getVideoRequested(this.props.match.params.id);
  }

  render() {
    const {
      label,
      video,
      user,
    } = this.props;

    return (
      <ExtendedFlex
        w={1}
        flexDirection="column"
        align="center"
      >
        <ScrollToTopOnMount />
        <ExtendedFlex
          w={1}
          px={[0, 0, 0, '10%']}
          bg="gray.0"
          py={[0, 0, 0, 40]}
          height={['50vh', '60vh', '70vh']}
          justify="center"
          align="center"
          maxHeight={674}
        >
          <Player
            url={video}
            width="100%"
            height="100%"
          />
        </ExtendedFlex>
        <ExtendedFlex
          maxWidth={1600}
          w={1}
          flexDirection={['column', 'column', 'column', 'row']}
        >
          <ExtendedFlex
            height="auto"
            w={[1, 1, 1, 0.7]}
            px="10%"
            py="5%"
            boxSizing="border-box"
            flexDirection="column"
          >
            <ExtendedBox
              w={1}
              height="100%"
            >
              <Heading
                type="H2"
              >
                {label}
              </Heading>
            </ExtendedBox>
            {
              !user.isLoggedIn &&
              <ExtendedBox
                w={1}
                mt={20}
              >
                <Link
                  to="/user/register"
                  type="L1"
                >
                  Join now to get started
                </Link>
              </ExtendedBox>
            }
          </ExtendedFlex>
          <ExtendedBox
            height={300}
            w={[1, 1, 1, 0.3]}
            py="5%"
            px={['10%', '10%', '10%', '5%']}
            boxSizing="border-box"
          />
        </ExtendedFlex>

        <UnlockOpportunities
          isLoggedIn={user.isLoggedIn}
        />
      </ExtendedFlex>
    );
  }
}

const mapStateToProps = (state) => ({
  label: state.video.label,
  video: state.video.video,
  body: state.video.body,
  category: state.video.category,
  user: state.article.user,
});

const mapDispatchToProps = (dispatch) => ({
  getVideoRequested: (id) => {
    dispatch(actions.getVideoRequested(id));
  },
});

Video.propTypes = {
  video: PropTypes.string,
  label: PropTypes.string,
  getVideoRequested: PropTypes.func,
  match: PropTypes.object,
  user: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Video));
