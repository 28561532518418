import React from 'react';
import PropTypes from 'prop-types';

import {
  Heading,
  Flex,
  ExtendedFlex,
  InvestmentCard,
  Body,
} from '../../coreui';

import DynamicBrowseJoinLink from '../../helpers';
import AccreditedTooltip from './AccreditedTooltip';
import IMGLOCKED from '../../assets/img_card_locked.jpg';
import MRI from '../../assets/MRI.jpg';
import MRII from '../../assets/MRII.jpg';

const AvailableToday = ({
  heading,
  investments,
  subheader,
  paragraphs = [],
  user,
}) => {
  const createBodyParagraph = (text, parIndex) => {
    return (
      <Body
        key={parIndex}
        type="B1"
        display="block"
        textAlign="center"
        maxWidth={['75%', 540, 600, 840, 1160]}
        mt={parIndex === 0 ? 0 : [15, 20, 20, 20, 30]}
        opacity={1}
      >
        {text}
      </Body>
    );
  };

  return (
    <ExtendedFlex
      bg="gray.0"
      py={[40, 40, 120]}
      w={1}
      m="auto"
      align="center"
      justify="center"
      flexDirection="column"
    >
      <Heading
        type="H2"
        display="block"
        textAlign="center"
        maxWidth={800}
        m="auto"
        mb={[0, 0, 30, 30, 60]}
      >
        {heading}
      </Heading>
      {
        subheader &&
        <Body
          type="B1"
          display="block"
          textAlign="center"
          maxWidth={['75%', 540, 600, 840, 1160]}
          m={0}
          mb={[20, 20, 20, 30, 40]}
          w={1}
          mx="auto"
          transition="opacity 0.5s 0s linear"
        >
          {subheader}
        </Body>
      }
      {paragraphs.length > 0 && paragraphs.map(createBodyParagraph)}
      <Flex
        pt={[20, 40]}
        w={1}
        justify="center"
      >
        <Flex
          wrap="wrap"
          justify="center"
        >
          {
            investments &&
            investments.map(
              (investment, index) => {
                const ltype = 'to';
                let image = null;
                switch (investment.imageName) {
                  case 'MRI':
                    image = MRI;
                    break;
                  case 'MRII':
                    image = MRII;
                    break;
                  default:
                    image = IMGLOCKED;
                }
                let path = investment.path;
                let linkText = 'View Details';
                if (!investment.REIT) {
                  if (!user || !user.isLoggedIn) {
                    path = '/user/register';
                    linkText = 'Register For Access';
                  }
                  if (user && user.id && user.accreditationRoleStatus !== 'Accredited') {
                    path = null;
                    linkText = 'Accreditation status changed? (877) 977-2776';
                  }
                }

                return (
                  <InvestmentCard
                    zIndex="0"
                    cardHeight="mid"
                    fadeIndex={investment.fadeIndex !== undefined ? investment.fadeIndex : index}
                    key={investment.id}
                    id={investment.id}
                    reit={investment.REIT}
                    statusLabel={investment.statusLabel}
                    status={investment.status}
                    minimum={investment.minimum}
                    targetHoldMin={investment.targetHoldMin}
                    targetHoldMax={investment.targetHoldMax}
                    blurb={investment.blurb}
                    propertyName={investment.label}
                    investmentTypeDisplay={investment.investmentTypeDisplay}
                    propertyType={investment.propertyType}
                    propertyLocation={investment.propertyLocation}
                    ioLabel={investment.title}
                    // eslint-disable-next-line
                    {...(investment.titleToolTip ? {
                      IoLabelTooltip: AccreditedTooltip,
                    } : {})}
                    payoutLabel={investment.payoutLabel}
                    actualIrr={investment.actualIRR}
                    irrObjective={investment.IRRObjective}
                    returnObjective={investment.returnObjective}
                    actualHold={investment.actualHold}
                    investmentGoal={investment.IRRObjective}
                    fundedDate={investment.escrowCloseDate}
                    completedDate={investment.actualMaturityDate}
                    amountInvested={investment.amountInvested}
                    pathAlias={path}
                    imageUrl={image}
                    distribution={investment.lastDistributionDeclared}
                    distributionTooltip={investment.distributionTooltip}
                    payoutTooltip={investment.payoutTooltip}
                    watchlist={investment.watchlist}
                    noAnimate={investment.noAnimate}
                    linkText={linkText}
                    linkType={ltype}
                  />
                );
              },
            )
          }
        </Flex>
      </Flex>
      <Flex
        mt={[40, 40, 80]}
        mx="auto"
        textAlign="center"
        justify="center"
      >
        <DynamicBrowseJoinLink
          isLoggedIn={user.isLoggedIn}
          type="L1"
          anonText="Get Started"
          loggedInText="View Investments"
        />
      </Flex>
    </ExtendedFlex>
  );
};

AvailableToday.propTypes = {
  investments: PropTypes.array,
  heading: PropTypes.string,
  subheader: PropTypes.string,
  user: PropTypes.object,
  paragraphs: PropTypes.array,
};

export default AvailableToday;
