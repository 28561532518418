import types from './types';

export default {
  autoInvestDataRequested: () => ({
    type: types.AUTOINVEST_API_REQUESTED,
  }),
  autoInvestDataSucceeded: (data) => ({
    data,
    type: types.AUTOINVEST_API_SUCCEEDED,
  }),
  autoInvestDataFailed: (error) => ({
    error,
    type: types.AUTOINVEST_API_FAILED,
  }),
  autoInvestEnrollmentRequested: (data, promise) => ({
    type: types.AUTOINVEST_ENROLLMENT_REQUESTED,
    data,
    promise,
  }),
  autoInvestEnrollmentSucceeded: (data) => ({
    data,
    type: types.AUTOINVEST_ENROLLMENT_SUCCEEDED,
  }),
  autoInvestEnrollmentFailed: (error) => ({
    error,
    type: types.AUTOINVEST_ENROLLMENT_FAILED,
  }),
  autoInvestCancelRequested: (data, promise) => ({
    type: types.AUTOINVEST_CANCEL_REQUESTED,
    data,
    promise,
  }),
  autoInvestCancelSucceeded: (data) => ({
    data,
    type: types.AUTOINVEST_CANCEL_SUCCEEDED,
  }),
  autoInvestCancelFailed: (error) => ({
    error,
    type: types.AUTOINVEST_CANCEL_FAILED,
  }),
  autoInvestUpdateRequested: (id, data, promise) => ({
    type: types.AUTOINVEST_UPDATE_REQUESTED,
    id,
    data,
    promise,
  }),
  autoInvestUpdateSucceeded: (id, data) => ({
    id,
    data,
    type: types.AUTOINVEST_UPDATE_SUCCEEDED,
  }),
  autoInvestUpdateFailed: (id, error) => ({
    id,
    error,
    type: types.AUTOINVEST_UPDATE_FAILED,
  }),
};
