import React from 'react';
import {
  ExtendedFlex,
  LoaderContainer,
  Loader,
  ScrollToTopOnMount,
} from '../../coreui';

const Loading = () => {
  return (
    <ExtendedFlex
      w={[1]}
      zIndex={2}
      position="relative"
      minHeight="calc(100vh - 232px)"
    >
      <ScrollToTopOnMount />
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    </ExtendedFlex>
  );
};

export default Loading;
