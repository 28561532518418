import React from 'react';

import InvestmentCardViewDetails from '../components/InvestmentCardViewDetails';
import InvestmentCardDetails from '../components/InvestmentCardDetails';
import InvestmentCardFlag from '../components/InvestmentCardFlag';
import InvestmentCardEmptyPlaceholder from '../components/InvestmentCardEmptyPlaceholder';

const section7FactoryFactory = (props) => {
  const { pathAlias, pathProps, status, gtkyApproved, watchlist, linkText, linkType, hideLink } = props;

  const section7Factory = () => {
    if (status === 'status-40-closed' || (status === 'status-30-active' && !gtkyApproved)) {
      return (
        <InvestmentCardEmptyPlaceholder />
      );
    }
    if (status === 'status-20-coming-soon') {
      return (
        <InvestmentCardFlag
          text={watchlist.text}
          handleClick={watchlist.handleClick}
          loading={watchlist.loading}
        />
      );
    }
    if (pathAlias) {
      return (
        <InvestmentCardViewDetails
          pathAlias={pathAlias}
          pathProps={pathProps}
          linkText={linkText}
          linkType={linkType}
          hideLink={hideLink}
        />
      );
    }
    return (
      <InvestmentCardDetails
        text={linkText}
      />
    );
  };

  return section7Factory;
};

export default section7FactoryFactory;
