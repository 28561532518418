import Back from './Back';
import Container from './Container';
import Flipper from './Flipper';
import Front from './Front';

const FlipCore = {
  Back,
  Container,
  Flipper,
  Front,
};

export default FlipCore;
