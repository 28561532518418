import types from './types';

export default {
  getMessageDataRequested: () => ({
    type: types.GET_MESSAGE_DATA_REQUESTED,
  }),
  getMessageDataSucceeded: (data) => ({
    type: types.GET_MESSAGE_DATA_SUCCEEDED,
    data,
  }),
  getMessageDataFailed: (error) => ({
    type: types.GET_MESSAGE_DATA_FAILED,
    error,
  }),
  getMessageDataReadRequested: (id) => ({
    type: types.GET_MESSAGE_DATA_READ_REQUESTED,
    id,
  }),
  getMessageDataReadSucceeded: (id) => ({
    type: types.GET_MESSAGE_DATA_READ_SUCCEEDED,
    id,
  }),
  getMessageDataReadFailed: (data) => ({
    type: types.GET_MESSAGE_DATA_READ_FAILED,
    data,
  }),
};
