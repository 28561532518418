import styled from 'styled-components';

export default {
  ReitTabs: styled.div`
    width: 100%;
    padding: 70px 16px 80px;
    box-sizing: border-box;
    display: block;
    font-family: mrs-eaves-xl-serif-narrow,serif;
    color: #202020;
    background: ${({ background }) => `${background}`};
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      padding: 110px 100px 120px;
    }
  `,
  Container: styled.div`
    max-width: 1024px;
    margin: 0 auto;
    h1 {
      margin: 0 0 15px 0;
      font-weight: 800;
      font-size: 28px;
      line-height: 32px;
    }
    .react-tabs {
      -webkit-tap-highlight-color: transparent;
    }
    .react-tabs__tab-list {
      margin: 0;
      padding: 0;
      border: 0;
      width: 90%;
      display: flex;
      overflow: auto;
      align-items: stretch;
      list-style: none;
      ms-overflow-style: none;
      scrollbar-width: none; 
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .react-tabs__tab-panel {
      display: none;
    }
    .react-tabs__tab-panel--selected {
      display: block;
    }    
    .react-tabs__tab {
      display: inline-block;
      padding: 0;
      margin-right: 30px;
      line-height: 32px;
      white-space: nowrap;
      cursor: pointer;
      list-style: none;
      font-weight: 800;
      color: #777777;
      font-size: 18px;
      border-bottom: solid 6px transparent;
      user-select: none;
      ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none; 
      &:hover {
        color: #202020;
      }
      &:focus {
        outline: 0;
        box-shadow: none;
        border-color: #176DB6;
        &:after {
          content: none;
        }
      }
    }
    .react-tabs__tab--selected {
      color: #202020;
      border: 0;
      border-bottom: 6px solid #176DB6;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      h1 {
        font-size: 44px;
        line-height: 56px;
      }
    }
  `,
  FadeOut: styled.div`
    position: absolute;
    width: 90%;
    height: 32px;
    background-image: ${({ fadeOut }) => (fadeOut ? 'linear-gradient(to right, transparent 75%, white)' : 'none')};
    pointer-events: none;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      display: none;
    }
  `,
  Slide: styled.div`
    padding-top: 40px;
    box-sizing: border-box;
  `,
  PDFItem: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    span {
      color: #202020;
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      max-width: 36%;
    }
    .spacer {
      flex: 1;
      margin: 0 8px;
      height: 24px;
      border-bottom: 1px dashed #D1D1D1;
    }
    a {
      font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #176DB6;
      text-decoration: none;
    }
  `,
  TeamSlide: styled.div`
    display: flex;
    flex-direction: column;
    h3 {
      margin-top: 0;
      margin-bottom: 8px;
      font-family: mrs-eaves-xl-serif-narrow,serif;
      color: #202020;
      font-weight: 800;
      line-height: 24px;
      font-size: 18px;
    }
    td {
      margin: 0;
      font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 24px;
      color: #202020;
      font-weight: 400;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      flex-direction: row;
    }
  `,
  Name: styled.div`
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
    margin-bottom: 16px;
  `,
  Copy: styled.div`
    white-space: pre-line;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 24px;
  `,
  MapSlide: styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  `,
  Column: styled.div`
    width: 100%;
    img {
      width: 100%;
      margin-bottom: 25px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      width: 48.5%;
    }
  `,
  Breakdown: styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
  `,
  Swatch: styled.div`
    width: 16px;
    height: 16px;
    margin-top: 4px;
    margin-right: 16px;
    border-radius: 4px;
    background: ${({ background }) => `${background}`};
  `,
  BreakdownCopy: styled.div`
    width: calc(100% - 32px);
    margin-bottom: 24px;
    h2 {
      margin-top: 0;
      margin-bottom: 8px;
      font-family: mrs-eaves-xl-serif-narrow,serif;
      color: #202020;
      font-weight: 800;
      line-height: 24px;
      font-size: 18px;
    }
    p {
      margin: 0;
      font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 24px;
      color: #202020;
      font-weight: 400;
    }
  `,
  ButtonContainer: styled.div`
    width: 100%;
    margin: 40px auto 0;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      width: 65%;
    }
  `,
};
