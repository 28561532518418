import startsWith from 'lodash/startsWith';

export const isMobile = /iPhone|iPad|iPod|Android/i.test(
  typeof navigator !== 'undefined' ? navigator.userAgent : '',
);

export const isDrupal = () => typeof window !== 'undefined' &&
  window.Drupal && !window.AdminMenuDrupal;

export const isDrupalPath = (path) => {
  if (startsWith(path, '/admin')) {
    return true;
  }
  if (startsWith(path, '/invest/')) {
    return true;
  }
  if (path === '/raise-capital') {
    return true;
  }
  return false;
};

export default isMobile;
