import React from 'react';

import Text from '../../components/Text';

const Disclosure = () => (
  <Text>{`
    <p>
      RealtyMogul.com is a website owned by Realty Mogul, Co. and is part of the technology platform (“Platform”) operated by RM Technologies, LLC, a subsidiary of Realty Mogul, Co. (collectively with its affiliates, "RealtyMogul").  
      <br /><br />
      By accessing this site and any pages thereof, you agree to be bound by our <a href="/terms-service" rel="noopener" target="_blank">Terms of Service</a> and <a href="/privacy-policy" rel="noopener" target="_blank">Privacy Policy</a>.
      <br /><br />
      <strong>RealtyMogul Income REIT, LLC</strong>
      <br /><br />
      An investment in the common shares of The RealtyMogul Income REIT, LLC (the "Income REIT") is speculative and involves substantial risks. The RealtyMogul Income REIT, LLC (the "Income REIT") has filed an <a href="/materials/mr1/offering_circular.pdf" rel="noopener" target="_blank">offering circular (the “Income REIT Offering Circular”)</a> with the SEC for the offering to which the communications on the Platform relates. Each investor should carefully review and consider the <a href="/materials/mr1/offering_circular.pdf" rel="noopener" target="_blank">Income REIT Offering Circular</a>, including the risk factors discussed therein, before purchasing shares. A copy of the Income REIT's most recent version of the <a href="/materials/mr1/offering_circular.pdf" rel="noopener" target="_blank">Offering Circular</a> and any supplements may also be obtained for free on the Securities and Exchange Commission’s website at www.sec.gov.
      <br /><br />
      <strong>RealtyMogul Apartment Growth REIT, Inc.</strong>
      <br /><br />
      An investment in the common shares of the RealtyMogul Apartment Growth REIT, Inc. (“Apartment Growth REIT”) is speculative and involves substantial risks. The Apartment Growth REIT, Inc. has filed an <a href="/materials/mr2/offering_circular.pdf" rel="noopener" target="_blank">offering circular (the “Apartment Growth REIT Offering Circular”)</a> with the SEC for the offering to which this communication relates. Each investor should carefully review and consider the <a href="/materials/mr2/offering_circular.pdf" rel="noopener" target="_blank">Apartment Growth REIT Offering Circular</a>. including the risk factors discussed therein, before purchasing shares. A copy of the Apartment Growth REIT, Inc’s most recent version of the <a href="/materials/mr2/offering_circular.pdf" rel="noopener" target="_blank">Offering Circular</a> and any supplements may also be obtained for free on the Securities and Exchange Commission’s website at www.sec.gov.
      <br />
      <br />
      <strong>Advisory Services</strong>
      <br />
      <br />
      RM Manager, LLC is a wholly-owned subsidiary of Realty Mogul, Co. and an exempt reporting adviser that serves as manager to several special purpose entities/private placements, each pursuing a venture capital strategy. RM Manager, LLC's clients are the special purpose entities it advises and not the investors in such entities.
      <br />
      <br />
      RM Adviser, LLC is a wholly-owned subsidiary of Realty Mogul, Co. and an SEC-registered investment adviser that serves as manager to our REIT offerings (RealtyMogul Income REIT, LLC and RealtyMogul Apartment Growth REIT, Inc.) and single purpose funds. RM Adviser, LLC's clients are the REIT entities and single purpose funds, and not the investors in the REITs or the single purpose funds in their capacities as such.
      <br />
      <br />
      <strong>Private Placements</strong>
      <br />
      <br />
      Issuers who license the Platform technology typically conduct securities offerings in reliance on exemptions from registration under Rule 506(c) of Regulation D as promulgated under the Securities Act of 1933 and are not registered under state securities laws in reliance on certain exemptions from registration (“Private Placement”).  Issuer’s conducting Private Placements offer securities only to accredited investors (for persons residing in the U.S.) and for persons residing abroad in jurisdictions where securities registration exemptions are available. Securities sold through Private Placements are not publicly traded, are subject to holding period requirements, and are intended for investors who do not need a liquid investment. Private Placement investments are <u>NOT</u> bank deposits (and thus <u>NOT insured by the FDIC</u> or by any other federal governmental agency), are <u>NOT guaranteed by RM Technologies, LLC or its affiliates</u>, and are subject to risk of loss of the entire invested amount.
      <br />
      <br />
      Neither the Securities and Exchange Commission nor any federal or state securities commission or regulatory authority has recommended or approved any investment or the accuracy or completeness of any of the information or materials provided by any issuer conducting a Private Placement. Investors must be able to afford to lose their entire investment.
      <br />
      <br />
      <strong>No Investment Advice</strong>
      <br />
      <br />
      RM Technologies, LLC and its affiliates are not a registered broker-dealer, investment adviser or crowdfunding portal (except for RM Adviser, LLC, which is a registered investment adviser providing investment management services to certain REITs and funds, as described above).  All information provided on RealtyMogul.com and/or the Platform by RM Technologies and its affiliates is not directed at any investor or category of investors and is provided solely as general information about RM Technologies, LLC and its affiliates, and to otherwise provide general investment education. No information contained herein should be regarded as investment advice by RM Technologies, LLC or its affiliates, either on behalf of a particular security or regarding an overall investment strategy, or otherwise as a suggestion to engage in or refrain from any investment-related course of action as none of RM Technologies, LLC nor any of its affiliates is undertaking to provide investment advice with respect to any materials presented on RealtyMogul.com and/or the Platform.  Offers to sell, or the solicitations of offers to buy, any security can only be made through an issuer’s official offering documents which contain important information about risks, fees and expenses. Investors should conduct their own due diligence, not rely on the financial assumptions or estimates displayed on this website, <b>Advice from a securities professional is strongly advised, and we recommend that you consult with a financial advisor, attorney, accountant, and any other professional that can help you to understand and assess the risks associated with any real estate investment.</b>  Past performance is no guarantee of future results. Therefore, you should not assume that the future performance of any specific investment or investment strategy will be profitable or equal to corresponding past performance levels.  Inherent in any investment is the potential for loss.
      <br />
      <br />
      <strong>Forward Looking Statements</strong>
      <br />
      <br />
      Information, statements, and documents presented or posted on RealtyMogul.com and/or the Platform by issuers licensing the Platform technology contain forward-looking statements.  These forward-looking statements involve known and unknown risks, uncertainties and other important factors that could cause an issuer’s actual results, performance or achievements, or industry results, to differ materially from any predictions of future results, performance or achievements that an issuer expresses or implies in its offering documents or in the information incorporated by reference into an issuer’s offering documents.  The forward-looking statements included in an issuer’s offering documents are based upon our current expectations, plans, estimates, assumptions and beliefs that involve numerous risks and uncertainties. Assumptions relating to the foregoing involve judgments with respect to, among other things, future economic, competitive and market conditions and future business decisions, all of which are difficult or impossible to predict accurately and many of which may be beyond an issuer’s control.  An issuer’s actual results and performance could differ materially from those set forth in the forward-looking statements. There exist myriad factors which could have a material adverse effect on an issuer’s operations and future prospects. Please carefully review each issuer’s offering documents for a more detailed discussion of such factors and risks regarding forward-looking statements. 
      <br />
      <br />
      Any of the assumptions underlying forward-looking statements could be inaccurate. You are cautioned not to place undue reliance on any forward-looking statements included in any issuer’s offering documents. All forward-looking statements are typically made as of the date of the issuer’s offering documents and the risk that actual results will differ materially from the expectations expressed in this offering documents will increase with the passage of time. Issuers typically undertake no obligation to publicly update or revise any forward-looking statements after the date of the issuer’s offering documents, whether as a result of new information, future events, changed circumstances or any other reason. In light of the significant uncertainties inherent in the forward-looking statements included in an issuer’s offering documents, the inclusion of such forward-looking statements should not be regarded as a representation that the objectives and plans set forth in an issuer’s offering documents will be achieved. 
      <br />
      <br />
      <strong>Financial Projections</strong>
      <br />
      <br />
      Information presented or posted on RealtyMogul.com and/or the Platform concerning any prospective or actual real estate investment that includes financial and other assumptions and projections, including pro forma projections (collectively “Projections”), is provided by the relevant issuer (or its affiliates) licensing the Platform. Unless affirmatively and specifically stated to the contrary, all such Projections are not reflective of the position or opinions of, nor are they endorsed by, RealtyMogul or its affiliates (except where the issuer is an affiliate of RealtyMogul, and in which case any such positions and opinions are solely those of the relevant affiliated issuer, subject to the affiliated issuer’s disclosures), or any other person or entity other than the issuer or its affiliates.  RealtyMogul does not provide any assurance of returns or the accuracy or reasonableness of the Projections provided by issuers or their affiliates.
      <br />
      <br />
      There can be no assurance that an issuer’s methodology used for calculating any Projections, including Target IRR, Target Annualized Cash-on-Cash Return, and Target Equity Multiple (“Targets”), are appropriate or adequate or will accurately predict actual performance.  An issuer’s Projections and Targets are hypothetical, are not based on actual investment results, and are presented solely for the purpose of providing insight into that issuer’s investment objectives, detailing its anticipated risk and reward characteristics and for establishing a benchmark for future evaluation of that issuer’s performance. An issuer’s Projections and Targets are not a predictor, projection or guarantee of future performance.  There can be no assurance that an issuer’s Projections or Targets will be met or that an issuer will be successful in meeting any Projections and Targets.  Projections and Target returns should not be used as a primary basis for an investor’s decision to invest.
      <br />
      <br />
      <strong>No Approval, Opinion or Representation, or Warranty by RM Technologies, LLC or its Affiliates</strong>
      <br />
      <br />
      The information presented or posted on RealtyMogul.com and/or the Platform concerning any prospective or actual real estate investment, including an issuer’s offering documentation related to any offering of such real estate investment, which may include without limitation the Private Placement Memorandum, Operating or Limited Partnership Agreement, Subscription Agreement, the Project Summary and all exhibits and other documents attached thereto or referenced therein (“Investment Documents”) (collectively with all information provided by an issuer, the “Issuer Information”) is provided by the relevant issuer (or its affiliates) licensing the Platform.  Unless affirmatively and specifically stated to the contrary, RM Technologies, LLC and its affiliates make no representations or warranties as to the accuracy of Issuer Information and accepts no liability therefor; no part of any Issuer Information is intended to be binding on RM Technologies, LLC or its affiliates, or to supersede any of an issuer’s Investment Documents; and the opinions expressed on RealtyMogul.com and/or the Platform in connection with any prospective or actual real estate investment are solely the opinions of the relevant issuer and its affiliates and none of any such opinions expressed on RealtyMogul.com and/or the Platform are the opinions of, nor are they endorsed by, RM Technologies, LLC or its affiliates.
      <br />
      <br />
      <strong>Issuer’s Information Qualified by Investment Documents</strong>
      <br />
      <br />
      Issuer Information presented or posted on RealtyMogul.com and/or the Platform is qualified in its entirety by reference to the more complete information contained in the relevant issuer’s Investment Documents.  The information on RealtyMogul.com and/or the Platform is not complete, and each prospective investor should carefully read all of the Investment Documents and any supplements thereto, copies of which are available by clicking the links available on the Platform or upon request, before deciding whether to make an investment.  No information, including Issuer Information, presented or posted on RealtyMogul.com and/or the Platform should not be used as a primary basis for an investor’s decision to invest.  In the event of an inconsistency between any information, including Issuer Information, presented or posted on RealtyMogul.com and/or the Platform on one hand, and the relevant Investment Documents on the other, investors should rely on the information contained in the Investment Documents.  The information on RealtyMogul.com and/or the Platform and the information in the Investment Documents are subject to last minute changes up to the closing date at the sole discretion of the relevant Issuer and its affiliates.
      <br />
      <br />
      <strong>Risk of Investment</strong>
      <br />
      <br />
      All real estate investments are speculative and involve substantial risk.  There can be no assurances that all or any of an issuer’s Projections or Targets will be true or that actual performance of any real estate investment will bear any relation to the Projections or other hypothetical illustrations provided by an issuer, and no guarantee or representation is made that investment objectives of any issuer will be achieved.  In the event that actual performance is below an issuer’s Projections or Targets, investors’ investment could be materially and adversely affected, and there can be no assurance that any investor will not suffer significant losses.  A loss of part or all of the principal value of a real estate investment may occur.  All prospective investors should not invest unless such prospective investor can readily bear the consequences of such loss.  Please see and carefully review an issuer’s Investment Documents for additional information concerning any specific real estate investment, including an issuer’s discussion concerning risk factors.
      <br />
      <br />
      <strong><em>Investment Disclosure</em></strong>
      <br />
      <br />
      Real estate return data is from FTSE NAREIT US Real Estate Index Series (All REITs), while S&P return data is from Morningstar, Inc. Real estate return data should not be used to estimate returns of RealtyMogul.com investments. While the FTSE NAREIT All REITs index covers all publicly traded REITs, all RealtyMogul.com investments are either private placements or non-traded REITs.  Moreover, individual RealtyMogul.com investments performance can vary significantly from the performance of REITs.
      <br />
      <br />
      <strong>Third Party References</strong>
      <br />
      <br />
      Articles or information from third-party sources outside of this domain may discuss RM Technologies, LLC or its affiliates, or relate to information contained on RealtyMogul.com and/or the Platform, but RM Technologies, LLC and its affiliates do <b>not approve and is not responsible for such content</b>. Hyperlinks to third-party sites, or reproduction of third-party content, do not constitute an approval or endorsement by RM Technologies or its affiliates of the linked or reproduced content.
      <br />
      <br />
      <strong>Realty Mogul Commercial Capital, Co.</strong>
      <br />
      <br />
      Realty Mogul Commercial Capital, Co., an affiliate of Realty Mogul, Co., operates in California under BRE license #02003246 and CFL license #60DBO 54879.
    </p>
  `}
  </Text>
);

export default Disclosure;
