import IconPartner from '../assets/icon_partner.svg';
import IconQuickly from '../assets/quickly.svg';
import IconWallet from '../whyReits/assets/icon_wallet.svg';

export default {
  platform: [
    {
      title: 'Invest Immediately',
      image: IconQuickly,
      body: 'Ability to invest funds immediately — No Agents, No Escrow, No Closing Costs',
    },
    {
      title: 'Flexible Amounts',
      image: IconPartner,
      body: 'Investment amounts from $25,000 to $50,000,000',
    },
    {
      title: '100% Passive',
      image: IconWallet,
      body: 'Passive Commercial Real Estate Investment Opportunities',
    },
  ],
  overview: {
    title: 'UNDERSTANDING 1031 EXCHANGES',
    subtitle: 'A 1031 exchange is a powerful wealth management tool.<p>Under the Internal Revenue Code Section 1031, real estate investors can defer their capital gains tax on the sale of a property if they reinvest the proceeds in another qualifying property.</p>',
  },
  access: {
    heading: '1031 Benefits and Risks',
    insights: [
      {
        heading: '1031 Exchange Benefits',
        items: [
          'Defer capital gains taxes on the sale of property',
          'Invest in currently cash flowing properties',
          'Ensure certainty of closing',
          'Avoid property management headaches',
          'Diversify your portfolio with a broad range of property types in varied geographies',
        ],
      },
      {
        heading: '1031 Exchange Risks',
        items: [
          'A 1031 Exchange is a complicated transaction. Investors should consult tax professionals',
          'The underlying investments in real estate are illiquid',
          'Investors who fail to complete their 1031 exchange in the allowable time (typically 180 days) lose their capital gains tax protection',
          'Each investment has its own risks, and each investor should review offering materials for a full assessment of those risks',
        ],
      },
    ],
  },
};
