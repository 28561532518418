import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import { getGettingToKnowYouApi } from '../../api';

export function* getDataFlow() {
  try {
    const {
      data: responseData,
    } = yield call(getGettingToKnowYouApi);

    yield put(
      actions.userGettingToKnowYouDraftSucceeded(responseData),
    );
  } catch (error) {
    yield put(
      actions.userGettingToKnowYouDraftFailed(error),
    );
  }
}

export function* watchGettingToKnowYouDraftRequested() {
  yield takeLatest(
    types.USER_GETTING_TO_KNOW_YOU_DRAFT_REQUESTED,
    getDataFlow,
  );
}

export default watchGettingToKnowYouDraftRequested;
