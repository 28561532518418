import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
} from '../../coreui';

import TeamMember from './teamMember';

// eslint-disable-next-line
class Team extends React.Component {
  render() {
    const {
      members,
    } = this.props;
    if (members.length > 0) {
      return (
        <ExtendedFlex
          flexDirection={['column', 'row']}
          my={40}
          flexWrap="wrap"
          mx={[0, 40]}
        >
          {
            members &&
            members.map((member, index) => {
              const url = (member.linkedIn) ? member.linkedIn.url : null;
              return (
                <TeamMember
                  // eslint-disable-next-line
                  key={`${index}_member`}
                  photo={(member.photo) ? member.photo : {}}
                  linkedIn={url}
                  name={member.label}
                  title={member.title}
                  bio={member.bio}
                  flexBasis="28%"
                />
              );
            })
          }
        </ExtendedFlex>
      );
    }
    return '';
  }
}

Team.propTypes = {
  members: PropTypes.array,
};

export default Team;
