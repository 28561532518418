import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedBox,
  ExtendedFlex,
  Link,
  isDrupal,
} from '../../coreui';

const HeaderUserMenuLoggedOut = ({
  handleMouseEnter,
  handleMouseLeave,
  hoveredItem,
  isLimitedNav,
  isRegFlow,
  isApp,
  frontendPrefix,
}) => {
  const inDrupal = isDrupal();
  return (
    !isRegFlow ?
      <>
        <ExtendedFlex
          flexDirection="column"
          display={isLimitedNav ? 'none' : 'flex'}
          position="relative"
          justify="center"
          pr={[0, 0, 0, 5]}
          pl={[0, 0, 0, 10]}
          ml={[0, 0, 0, 10]}
          mr={[0, 0, 0, 15]}
          height={[70, 70, 80, 90]}
        >
          <ExtendedFlex
            display={['none', 'none', 'none', 'block']}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            pr={5}
            pl={10}
            ml={10}
            mr={15}
          >
            <Link
              color="gray.8"
              display="block"
              type="L5"
              id="sign_in"
              {...{
                [inDrupal ? 'href' : 'to']: '/user/login',
              }}
              whiteSpace="nowrap"
            >
              Sign In
            </Link>
            <ExtendedBox
              position="absolute"
              height={1}
              bg="black"
              w={1}
              bottom={25}
              left={0}
              opacity={
                hoveredItem === 'sign_in' ?
                  '1' :
                  '0'
              }
              transition="opacity 0.5s linear"
            />
          </ExtendedFlex>
        </ExtendedFlex>
        <Link
          type="L1mini"
          display={['none', 'none', 'none', 'block']}
          {...{
            [(inDrupal || isApp) ? 'href' : 'to']: `${isApp ? frontendPrefix : ''}/user/register`,
          }}
        >
          Join
        </Link>
      </> :
      <>
        <Link
          type="L1mini"
          to="/user/login"
          {...{
            [isApp ? 'href' : 'to']: `${isApp ? frontendPrefix : ''}/user/login`,
          }}
          display="block"
          whiteSpace="nowrap"
        >
          Sign In
        </Link>
      </>
  );
};

HeaderUserMenuLoggedOut.propTypes = {
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  hoveredItem: PropTypes.string,
  isLimitedNav: PropTypes.bool,
  isRegFlow: PropTypes.bool,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

export default HeaderUserMenuLoggedOut;
