import types from './types';

export default {
  flagApiActionRequested: (opts) => ({
    type: types.FLAG_TOGGLE_REQUESTED,
    opts,
  }),
  flagApiActionSucceeded: (data) => ({
    type: types.FLAG_TOGGLE_SUCCEEDED,
    data,
  }),
  flagToggled: (opts) => ({
    type: types.FLAG_TOGGLED,
    opts,
  }),
  flagApiActionFailed: (error) => ({
    type: types.FLAG_TOGGLE_FAILED,
    error,
  }),
};
