import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  UnlockOpportunities,
  ModalWithOverlay,
  ScrollToTopOnMount,
} from '../coreui';

import Hero from './hero';
import ExploreOpportunities from './exploreOpportunities';
import InvestmentPlatform from './investmentPlatform';
import EarnPassiveIncome from './earnPassiveIncome';
import RightOpportunities from './rightOpportunities';
import RecentInvestments from './recentInvestments';
import PressMentions from './pressMentions';
import browseActions from '../browse/browsePage/actions';

class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      modalContent: null,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.props.getBrowsePageDataRequested();
  }

  toggleModal(modalContent) {
    this.setState((state) => ({
      isModalVisible: !state.isModalVisible,
      modalContent: state.isModalVisible ? null : modalContent,
    }));
  }

  render() {
    const {
      isModalVisible,
      modalContent,
    } = this.state;

    const {
      user,
      totalMembers,
      totalAmountFinanced,
      amountPaidOut,
      totalPropertyValueFinanced,
      pageVariant = 'none',
      recentInvestmentsData,
      tokens,
    } = this.props;

    return (
      <>
        <ScrollToTopOnMount />
        <Hero
          isLoggedIn={user.isLoggedIn}
          variant={pageVariant}
        />
        <ExploreOpportunities
          totalMembers={totalMembers}
          totalAmountFinanced={totalAmountFinanced}
          amountPaidOut={amountPaidOut}
          totalPropertyValueFinanced={totalPropertyValueFinanced}
          variant={pageVariant}
        />
        <InvestmentPlatform
          isLoggedIn={user.isLoggedIn}
          variant={pageVariant}
        />
        <EarnPassiveIncome
          toggleModal={this.toggleModal}
          passiveIncomeNum={totalPropertyValueFinanced}
          variant={pageVariant}
          tokens={tokens}
        />
        <RightOpportunities
          isLoggedIn={user.isLoggedIn}
          variant={pageVariant}
        />
        <RecentInvestments recentInvestmentsData={recentInvestmentsData} />
        <PressMentions />
        <UnlockOpportunities
          isLoggedIn={user.isLoggedIn}
        />
        <ModalWithOverlay
          isVisible={isModalVisible}
          handleClick={this.toggleModal}
        >
          {modalContent}
        </ModalWithOverlay>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.homepage.user,
  totalMembers: state.homepage.totalMembers,
  totalAmountFinanced: state.homepage.totalAmountFinanced,
  amountPaidOut: state.homepage.amountPaidOut,
  totalPropertyValueFinanced: state.homepage.totalPropertyValueFinanced,
  tokens: state.tokens,
  recentInvestmentsData: _.orderBy(state.pastInvestments.completedEquityInvestments, ({ actualMaturityDate }) => actualMaturityDate || '', ['desc']).slice(0, 8),
});

const mapDispatchToProps = (dispatch) => ({
  getBrowsePageDataRequested: () => {
    dispatch(browseActions.getBrowseClosedDataRequested());
  },
});

Homepage.propTypes = {
  user: PropTypes.object,
  totalMembers: PropTypes.number,
  totalAmountFinanced: PropTypes.number,
  amountPaidOut: PropTypes.number,
  totalPropertyValueFinanced: PropTypes.number,
  pageVariant: PropTypes.string,
  tokens: PropTypes.object,
  getBrowsePageDataRequested: PropTypes.func,
  recentInvestmentsData: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
