import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedBox,
  ExtendedFlex,
} from '../../coreui';

import OverViewDetails from '../../ioDetails/components/overviewDetails';
import RMCarousel from './carousel';

// eslint-disable-next-line
class HeaderOverview extends React.PureComponent {
  render() {
    const {
      details,
      gallery,
      smartButton,
      id,
      title,
      watchList,
      link,
      progress,
      isApp,
      frontendPrefix,
    } = this.props;
    return (
      <ExtendedFlex
        flexDirection={['column', 'column', 'column', 'row']}
      >
        <ExtendedBox
          width={['100%', '100%', '100%', '900px']}
        >
          {
            gallery &&
            gallery[0] &&
            <RMCarousel
              key={`rm_carousel_${id}`}
              title={title}
              gallery={gallery}
            />
          }
        </ExtendedBox>
        <OverViewDetails
          smartButton={smartButton}
          watchList={watchList}
          details={details}
          progress={progress}
          id={id}
          link={link}
          isApp={isApp}
          frontendPrefix={frontendPrefix}
        />
      </ExtendedFlex>
    );
  }
}

HeaderOverview.propTypes = {
  id: PropTypes.number,
  gallery: PropTypes.array,
  details: PropTypes.array,
  title: PropTypes.string,
  smartButton: PropTypes.object,
  watchList: PropTypes.object,
  progress: PropTypes.object,
  link: PropTypes.object,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

export default HeaderOverview;
