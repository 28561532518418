import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ExtendedFlex,
  Heading,
  isDrupal,
  Loader,
} from '../../coreui';

import actions from '../actions';
import DashboardNavLink from '../components/DashboardNavLink';
import DashboardNotifications from '../components/DashboardNotifications';
import ChangeEmail from '../components/ChangeEmail';
import PlaidLinkDrupalWrapper from '../../investflow/components/PlaidLinkDrupalWrapper';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    if (!isDrupal()) {
      window.location.reload();
    } else {
      this.props.getPendingData();
    }
  }

  render() {
    const {
      pendingData,
      isLoggedIn,
      isDrupal403,
    } = this.props;

    if (!isLoggedIn || isDrupal403) {
      return null;
    }

    // We can get here if the user uses the back button.
    if (!isDrupal()) {
      return (
        <ExtendedFlex
          position="relative"
          minHeight="90vh"
        >
          <Loader />
        </ExtendedFlex>
      );
    }

    return (
      <ExtendedFlex
        flexDirection="column"
        justify="center"
        width="100%"
        alignItems="center"
      >
        <PlaidLinkDrupalWrapper />
        <DashboardNotifications pendingData={pendingData} />
        <ExtendedFlex
          flexDirection="column"
          justifyContent="center"
          align="center"
          width={1}
          maxWidth={1700}
          px={50}
        >
          <Heading
            type="H2"
            my="0px"
            pt="100px"
            pb="0px"
            dis
          >
            My Dashboard
          </Heading>
          <DashboardNavLink />
          <ChangeEmail />
        </ExtendedFlex>
      </ExtendedFlex>
    );
  }
}

Profile.propTypes = {
  getPendingData: PropTypes.func,
  pendingData: PropTypes.array,
  isLoggedIn: PropTypes.bool,
  isDrupal403: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  pendingData: state.dashboard.pendingData.pendingData.investments,
  isLoggedIn: state.app.user.isLoggedIn,
  isDrupal403: state.app.isDrupal403,
});

const mapDispatchToProps = (dispatch) => ({
  getPendingData: () => {
    dispatch(actions.pendingDashboardDataRequested());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
