import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Heading } from '../../coreui';
import RadioGroup from '../forms/RadioGroup';
import RadioLabel from '../forms/RadioLabel';
import FollowUp from './FollowUp';

class PrimaryReason extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dirty: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({
      dirty: true,
    });
    return this.props.handleChange(e);
  }

  render() {
    return (
      <>
        <Heading
          type="H6"
          textTransform="uppercase"
        >
          What is your primary reason for making this investment?
        </Heading>
        <RadioGroup
          name="primaryInvestmentReason"
          value={this.props.value}
          onChange={this.handleChange}
          inputProps={{
            'data-test': 'primary_reason',
            required: true,
          }}
        >
          <RadioLabel
            value="create"
            label="Create an additional source of income"
          />
          <RadioLabel
            value="grow"
            label="Grow my investment portfolio value"
          />
          <RadioLabel
            value="preserve"
            label="Preserve my savings with minimal risk"
          />
        </RadioGroup>
        {
          this.props.value === 'preserve' && this.state.dirty &&
          <FollowUp
            subheader="You indicated you are looking for a safe-haven for savings. The investment you have selected does not guarantee preservation of capital and carries risk including loss of principal."
            name="primaryInvestmentReasonFollowUp"
            value={this.props.followUp}
            handleChange={this.props.handleChange}
            options={[
              {
                value: 'comfortable',
                label: 'I am comfortable with the risks of losing some, if not all, of my invested capital.',
              },
              {
                value: 'not_comfortable',
                label: 'I am not comfortable risking the loss of any invested capital.',
              },
            ]}
          />
        }
      </>
    );
  }
}

PrimaryReason.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.oneOf([
    'create',
    'grow',
    'preserve',
  ]),
  followUp: PropTypes.string,
};

export default PrimaryReason;
