import numeral from 'numeral';
import {
  Formatters,
} from '../coreui';

import types from '../ioDetails/types';
import tips from './toolTipData';

const initialState = {
  io: {},
};

const getImage = (images) => {
  const image = {};
  if (!images) {
    return image;
  }
  if (images.thumbnail) {
    image.thumb = images.thumbnail.url;
  }
  if (images.modal) {
    image.modal = images.modal.url;
  }
  if (images.modal_large) {
    image.modalLarge = images.modal_large.url;
  }
  return image;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_IO_DATA_SUCCEEDED: {
      // @TODO This really should be making a copy by spreading, but there's
      // another reducer that ends up depending on this mutation.
      const io = action.data.data[0];
      let tt = {};
      let propertyType = {};
      let historical = {};
      if (io.reitPriceInfo) {
        historical = {
          purchasePrice: io.reitPriceInfo.cost.value,
          purchasePriceDate: io.reitPriceInfo.cost.effectiveDate,
          navPrice: io.reitPriceInfo.nav.value,
          navPriceDate: io.reitPriceInfo.nav.effectiveDate,
        };
      }
      switch (io.id) {
        case 274949:
          tt = tips.mr2;
          propertyType = tips.mr2;
          break;
        default:
          tt = tips.mr1;
          propertyType = tips.mr1;
          break;
      }

      const propTypeKey = 'propertyType';
      const propTypeKeyStat = 'propertyTypeMain';

      const currentTotal = io.assets
        .filter(({ active, currentBalance }) => Number(active) === 1 && currentBalance)
        .reduce(((currentTotal, { currentBalance }) => currentTotal + currentBalance), 0.0);

      const currentAssets = io.assets
        .filter((asset) => Number(asset.active) === 1)
        .map((({ amountInvested: amount = 0.0, currentBalance = 0.0, ...asset }, i) => ({
          key: asset.id,
          i,
          name: asset.label,
          location: `${asset.address.locality}, ${asset.address.administrative_area}`,
          propertyType: asset[propTypeKey],
          propertyTypeStat: asset[propTypeKeyStat],
          investmentType: asset.investmentType,
          weight: currentBalance / currentTotal,
          amount,
          image: getImage(asset.images),
          dateOfInvestment: asset.dateOfInvestment,
          totalValue: asset.totalValue,
          highlights: asset.investmentHighlights,
          disclaimer: asset.disclaimer,
          whyWeLikeIt: asset.whyWeLikeIt,
          active: asset.active,
          displayRow: (asset.displayValue && asset.totalValue > 0 && 1) || null,
          region: asset.region,
          marketTier: asset.marketTier,
          issuer: io.issuer,
          investment: asset.investment,
        })));

      const pastAssets = io.assets
        .filter((asset) => Number(asset.active) !== 1)
        .map((({ amountInvested: amount = null, ...asset }, i) => ({
          key: asset.id,
          i,
          name: asset.label,
          location: `${asset.address.locality}, ${asset.address.administrative_area}`,
          propertyType: asset[propTypeKey],
          investmentType: asset.investmentType,
          amount,
          image: getImage(asset.images),
          dateOfInvestment: asset.dateOfInvestment,
          dateCompleted: asset.dateCompleted,
          totalValue: asset.totalValue,
          highlights: asset.investmentHighlights,
          disclaimer: asset.disclaimer,
          whyWeLikeIt: asset.whyWeLikeIt,
          active: asset.active,
          investmentHighlights: asset.investmentHighlights.value,
          displayRow: 1,
        })));

      io.assets = {
        pastAssets,
        currentAssets,
        snapHref: io.id === 274949
          ? '/materials/mr2/portfolio_snapshot.pdf'
          : '/materials/mr1/portfolio_snapshot.pdf',
      };

      return {
        ...state,
        io,
        details: [
          {
            label: 'Annualized Distribution Rate',
            value: numeral(action.data.data[0].declaredDividendRateAnnualized / 100)
              .format(Formatters.PERCENTAGE2),
            tooltip: action.data.data[0].distributionTooltip,
          },
          { label: 'Distribution Frequency', value: action.data.data[0].distributionFrequency, tooltip: tt.freq },
          {
            label: 'Minimum Investment',
            value: numeral(action.data.data[0].minimumInvestment).format(Formatters.DOLLARS),
            tooltip: tt.min,
          },
          { label: 'Property Type', value: propertyType.propertyType, tooltip: tt.type },
        ],
        highlights: {
          investedValue: action.data.data[0].reitAssetValue,
          numInvestments: action.data.data[0].assets.currentAssets.length,
          numInvestors: action.data.data[0].numInvestors,
        },
        historical,
      };
    }
    case types.GET_IO_DATA_FAILED:
      return {
        ...state,
      };
    case types.GET_IO_FAQ_DATA_SUCCEEDED:
      return {
        ...state,
        faqItems: action.data.data.map((item) => {
          return ({
            header: item.header,
            content: item.content,
            id: item.id,
          });
        }),
      };
    case types.UNSET_IO_STATE:
      return {
        ...state,
        io: {},
        faqItems: null,
      };
    default:
      return {
        ...state,
      };
  }
};
