/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import uniqueId from 'lodash/uniqueId';

import {
  ExtendedBox,
  ExtendedFlex,
  StyledButton,
} from '../../../coreui';

import AssetInfo from './AssetInfo';
import '../css/carousel.css';

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      htmlID: uniqueId('slider'),
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goto = this.goto.bind(this);
  }

  componentDidMount() {
    this.goto();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ height: window.innerHeight, width: window.innerWidth });
  }

  next() {
    document.getElementById(this.state.htmlID).scrollTop = 0;
    this.slider.increment();
  }

  previous() {
    document.getElementById(this.state.htmlID).scrollTop = 0;
    this.slider.decrement();
  }

  goto() {
    if (this.slider !== null) {
      this.props.parentSlider(this.slider);
    }
  }

  render() {
    const {
      assets,
    } = this.props;

    if (assets.length === 0) {
      return null;
    }

    const {
      height,
      width,
    } = this.state;

    const settings = {
      showArrows: false,
      showStatus: false,
      showIndicators: false,
      showThumbs: false,
      autoPlay: false,
      infiniteLoop: true,
      transitionTime: 0,
    };

    return (
      <ExtendedBox
        position="relative"
        minWidth={['255px', '600px', '660px', '840px', '950px']}
        maxWidth={[
          Math.floor(width * 0.7),
          Math.floor(width * 0.8) > 950 ? 950 : Math.floor(width * 0.8),
        ]}
        m="0 auto"
        px={[0, 0, 10, 25, 40]}
        pt={[0, 0, 10, 25, 40]}
        display="inline-block"
      >
        <ExtendedBox
          maxHeight={Math.floor(height * 0.6)}
          minHeight={Math.floor(height * 0.6)}
          overflowY="auto"
          className="portfolio-carousel"
          id={this.state.htmlID}
        >
          <Carousel
            {...settings}
            ref={(c) => {
              this.slider = c;
            }}
          >
            {assets.map((asset) => {
              return <AssetInfo asset={asset} index={asset.key} />;
            })}
          </Carousel>
        </ExtendedBox>
        <ExtendedFlex
          mt={[20, 20, 30, 45, 60]}
          alignItems="space-between"
          position="relative"
        >
          <StyledButton
            display="block"
            type="L4"
            onClick={this.previous}
            position="absolute"
            bottom="0"
            left="0"
            zIndex="2"
            height="100%"
            bg="transparent"
            border="none" // Prevents browser defaulting styles on to Link.
            outline="none" // Prevents browser defaulting styles on to Link.
            style={{
              cursor: 'pointer',
            }}
          >
            Previous{' '}
            <ExtendedBox
              display={['none', 'inline']}
              style={{ cursor: 'pointer' }}
            >
              Property
            </ExtendedBox>
          </StyledButton>
          <StyledButton
            display="block"
            type="L4"
            onClick={this.next}
            position="absolute"
            bottom="0"
            right="0"
            zIndex="2"
            height="100%"
            bg="transparent"
            border="none" // Prevents browser defaulting styles on to Link.
            outline="none" // Prevents browser defaulting styles on to Link.
            ml="auto"
            style={{
              cursor: 'pointer',
            }}
          >
            Next{' '}
            <ExtendedBox
              display={['none', 'inline']}
              style={{ cursor: 'pointer' }}
            >
              Property
            </ExtendedBox>
          </StyledButton>
        </ExtendedFlex>
      </ExtendedBox>
    );
  }
}

Slider.propTypes = {
  assets: PropTypes.any,
  parentSlider: PropTypes.func,
};

export default Slider;
