import React from 'react';
import PropTypes from 'prop-types';

import {
  LoaderCore,
  Body,
} from '..';

const Loader = (props) => {
  const {
    width,
    height,
    frontColor,
    backColor,
    children,
    message,
    hideMessage,
  } = props;

  return (
    <LoaderCore.Wrapper>
      <LoaderCore.Div
        {
        ...{
          width,
          height,
        }
        }
      >
        <LoaderCore.Flipper>
          <LoaderCore.Front bg={frontColor} />
          <LoaderCore.Back bg={backColor} />
        </LoaderCore.Flipper>
        { message && !hideMessage &&
          <Body
            m={0}
            mt={10}
            ml="-50%"
            type="B2"
          >
            {message}
          </Body> }
      </LoaderCore.Div>
      {
        children &&
        <LoaderCore.ChildrenWrapper
          mt={height + 40}
          textAlign="center"
        >
          { children }
        </LoaderCore.ChildrenWrapper>
      }
    </LoaderCore.Wrapper>
  );
};

Loader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  frontColor: PropTypes.string,
  backColor: PropTypes.string,
  message: PropTypes.string,
  hideMessage: PropTypes.bool,
  children: PropTypes.object,
};

Loader.defaultProps = {
  width: 25,
  height: 25,
  frontColor: 'blue.1',
  backColor: 'gray.8',
  message: 'Loading...',
  hideMessage: false,
};

export default Loader;
