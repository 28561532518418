import {
  takeLatest,
  call,
  put,
  all,
} from 'redux-saga/effects';

import appTypes from '../../appWrapper/types';
import types from '../types';
import actions from '../actions';
import appActions from '../../appWrapper/actions';

import { getUserLoginApi } from '../../api';
import { getIdToken, getCurrentSession } from '../api';

export function* getAppInit() {
  try {
    const token = yield call(getIdToken);

    if (token !== null && token !== undefined) {
      yield put(appActions.getUserDataRequested());
    }
  } catch (e) {
    // Do nothing.
  }
}

export function* getUserDataFlow(action) {
  try {
    const {
      destination,
    } = action;

    const {
      idToken: {
        jwtToken: token,
        payload: {
          given_name: firstName,
          family_name: lastName,
        },
      },
    } = yield call(getCurrentSession);

    yield put(appActions.getUserDataSucceeded({
      isLoggedIn: true,
      firstName,
      lastName,
    }));

    const response = yield call(getUserLoginApi, token, destination);

    const user = {
      ...response.data,
      messages: response.data.unreadMessagesCount,
      isLoggedIn: true,
    };

    yield all([
      put(
        appActions.getUserDataRequested(),
      ),
      put(
        actions.userLoginSucceeded(user),
      ),
    ]);
  } catch (error) {
    yield put(
      actions.userLoginFailed(error),
    );
    if (error.response.status === 403) {
      yield put(appActions.getUserDataFailed(error));
    }
  }
}

export function* watchUserLoginRequested() {
  yield takeLatest(
    types.USER_LOGIN_REQUESTED,
    getUserDataFlow,
  );
}

export function* watchAppInit() {
  yield takeLatest(
    appTypes.APP_INIT,
    getAppInit,
  );
}

export default watchUserLoginRequested;
