import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  withRouter,
} from 'react-router-dom';
import {
  ExtendedFlex,
  Heading,
  NavLink,
} from '../../../coreui';
import actions from './actions';

class PopularContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.props.getPopularContentDataRequested();
  }

  render() {
    const { items } = this.props;
    return (
      <ExtendedFlex
        flexDirection="column"
      >
        <Heading
          type="H8"
          m={0}
        >
          Popular Articles
        </Heading>
        {
          items.map(
            (item) => {
              return (
                <NavLink
                  type="B3"
                  to={item._links.alias}
                  withActiveStyle={false}
                  display="block"
                  mt={20}
                >
                  {item.label}
                </NavLink>
              );
            },
          )
        }
      </ExtendedFlex>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPopularContentDataRequested: () => {
    dispatch(actions.getPopularContentDataRequested());
  },
});

const mapStateToProps = (state) => ({
  items: state.popularContent.items,
});

PopularContent.propTypes = {
  getPopularContentDataRequested: PropTypes.func,
  items: PropTypes.array,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PopularContent));
