import React from 'react';
import PropTypes from 'prop-types';

import { ExtendedFlex, Body } from '../../coreui';

const TableCell = (props) => {
  const { value } = props;
  return (
    <ExtendedFlex
      alignItems="center"
      m={0}
    >
      <Body type="B3" m={0}>
        {value}
      </Body>
    </ExtendedFlex>
  );
};

TableCell.propTypes = {
  value: PropTypes.node,
};

export default TableCell;
