import types from './types';

export default {
  updateSelectedCurrentInvestmentFilterValue: (value) => ({
    type: types.UPDATE_SELECTED_CURRENT_INVESTMENT_FILTER_VALUE,
    value,
  }),
  watchInvestmentFlagRequested: (investment) => ({
    type: types.WATCH_INVESTMENT_FLAG_REQUESTED,
    investment,
  }),
  watchInvestmentFlagSucceeded: (investment, result) => ({
    type: types.WATCH_INVESTMENT_FLAG_SUCCEEDED,
    investment,
    result,
  }),
  watchInvestmentFlagFailed: (investment, error) => ({
    type: types.WATCH_INVESTMENT_FLAG_FAILED,
    investment,
    error,
  }),
  updateFilteredInvestments: (filteredInvestments) => ({
    type: types.UPDATE_FILTERED_INVESTMENTS,
    filteredInvestments,
  }),
};
