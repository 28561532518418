import types from './types';

export default {
  userAccreditationRequested: (data, promise) => ({
    type: types.USER_ACCREDITATION_REQUESTED,
    data,
    promise,
  }),
  userAccreditationFailed: (error) => ({
    type: types.USER_ACCREDITATION_FAILED,
    error,
  }),
  userAccreditationSucceeded: (data) => ({
    type: types.USER_ACCREDITATION_SUCCEEDED,
    data,
  }),
};
