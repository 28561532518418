/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
  Link,
} from '../coreui';

import Container from './Container';
import FadeIn from './FadeIn';
import Divider from './Divider';
import IconCallout from './IconCallout';

const contentMaxWidth = ['100%', '100%', 400, 540, 700];

const IconCallouts = ({
  heading,
  body,
  more,
  moreComponent,
  insights,
  height,
  ...additionalProps
}) => {
  const learnMoreIndex = 1 + body.length + 1;

  const moreOutput = moreComponent || (more ?
    <Link
      type="L1"
      {...more}
    />
    : null
  );

  return (
    <ExtendedBox
      pt={[50, 50, 100, 140]}
      pb={[100, 100, 100, 140]}
      py={[60, 80, 100, 120, 140]}
      {...additionalProps}
    >
      <Container
        height={height}
      >
        <ExtendedFlex
          flexDirection="column"
          w={1}
          maxWidth={contentMaxWidth}
          boxSizing="border-box"
          mb={[70, 40]}
        >
          <FadeIn>
            <Heading
              type="H2"
              mt={[0, 30, 50, 70]}
            >
              {heading}
            </Heading>
            {
              body.map((item) => {
                return (
                  <Body
                    key={item}
                    type="B1"
                    mb={0}
                  >
                    {item}
                  </Body>
                );
              })
            }
          </FadeIn>
        </ExtendedFlex>
        <ExtendedBox
          alignSelf="flex-start"
          mt={[0, 25]}
          ml={[0, '10%', '16.5%']}
          w={1}
          maxWidth={[null, null, 300, 380, 500]}
          boxSizing="border-box"
        >
          {
            insights.map((insight, index) => {
              return (
                <FadeIn
                  key={insight.text}
                  fadeInIndex={index}
                >
                  <IconCallout
                    image={insight.icon}
                  >
                    {
                      insight.heading &&
                      <Heading
                        type="H6"
                        mt={0}
                        textTransform="uppercase"
                      >
                        {insight.heading}
                      </Heading>
                    }
                    <Body
                      type="B1"
                      mt={0}
                    >
                      {insight.text}
                    </Body>
                  </IconCallout>
                  {
                    index + 1 < insights.length &&
                    <Divider
                      type="horizontal"
                    />
                  }
                </FadeIn>
              );
            })
          }
        </ExtendedBox>
      </Container>
      <Container
        pt={[40]}
      >
        <ExtendedFlex
          flexDirection="column"
          w={1}
          maxWidth={contentMaxWidth}
          boxSizing="border-box"
        >
          <FadeIn
            fadeInIndex={learnMoreIndex}
          >
            {moreOutput}
          </FadeIn>
        </ExtendedFlex>
      </Container>
    </ExtendedBox>
  );
};

IconCallouts.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.arrayOf(PropTypes.string),
  more: PropTypes.shape({
    to: PropTypes.string,
    children: PropTypes.string,
  }),
  moreComponent: PropTypes.node,
  insights: PropTypes.arrayOf(PropTypes.shape(IconCallout.propTypes)),
};

export default IconCallouts;
