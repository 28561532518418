/* eslint-disable consistent-return */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import Styled from './index.styled';
import { CloseButton, Arrow } from '../../IconLibrary';

const OverlayComponent = ({
  pageSpacing = false,
  lightbox = false,
  fit = 'cover',
  buttonTheme = 'default',
  background = '#FFF',
  children,
  onClose,
  nested = false,
  hideClose = false,
  isShowingPage = false,
  isCarousel = false,
  onNextClick,
  onPrevClick,
}) => {
  const overlayScrollRef = useRef();
  const buttonRef = useRef();

  const handleCloseButton = () => {
    if (onClose) onClose();
  };

  useEffect(() => {
    if (nested) return;
    if (isShowingPage) {
      setTimeout(() => {
        disableBodyScroll(overlayScrollRef.current, {
          allowTouchMove: (el) => {
            let element = el;
            do {
              if (element.classList && (element.classList.contains('slick-slider') || element.classList.contains('simplebar-content-wrapper') || element.classList.contains('is-scrollable'))) {
                return true;
              }
              element = element.parentNode;
            } while (element);
            return false;
          },
        });
      }, 0);
    } else {
      clearAllBodyScrollLocks();
    }
    return () => {
      if (!isShowingPage) {
        clearAllBodyScrollLocks();
      }
    };
  }, [isShowingPage, nested]);

  const handleKeydown = (e) => {
    if (e.key === 'Escape') {
      handleCloseButton(e);
    }
  };

  return (
    <Styled.SubpageOverlayContainer isShowingPage={isShowingPage}>
      {isShowingPage &&
        <>
          <Styled.SubpageOverlayBackground onClick={handleCloseButton}>Close Overlay</Styled.SubpageOverlayBackground>
          <Styled.SubpageOverlay fit={lightbox ? 'lightbox' : fit} background={background} onKeyDown={handleKeydown}>
            {
              isCarousel &&
              <>
                <Styled.Prev onClick={onPrevClick}><Arrow fill="#FFF" /></Styled.Prev>
                <Styled.Next onClick={onNextClick}><Arrow fill="#FFF" /></Styled.Next>
              </>
            }
            {!hideClose &&
              <Styled.CloseButton aria-label="Close Overlay" theme={buttonTheme} ref={buttonRef} onClick={handleCloseButton}>
                <CloseButton fill="#FFF" />
              </Styled.CloseButton>}
            {
              isShowingPage &&
              (
                <Styled.PageContent className="is-scrollable" pageSpacing={pageSpacing} ref={overlayScrollRef} fit={lightbox ? 'lightbox' : fit}>
                  <Styled.ChildrenWrapper pageSpacing={pageSpacing}>{ children }</Styled.ChildrenWrapper>
                </Styled.PageContent>
              )
            }
          </Styled.SubpageOverlay>
        </>}
    </Styled.SubpageOverlayContainer>
  );
};

OverlayComponent.propTypes = {
  background: PropTypes.string,
  /** Adds spacing at the top of overlay */
  pageSpacing: PropTypes.bool,
  /** Controls if overlay will take over entire window or be a set width/height, override fit with 'lightbox' */
  lightbox: PropTypes.bool,
  /** Controls sizing of lightbox
   * child - Conform to size of children
   * cover - Takes over page
   * lightbox - Has a set width and height
   */
  fit: PropTypes.oneOf(['child', 'cover', 'lightbox']),
  /** Theme of close button */
  buttonTheme: PropTypes.oneOf(['default']),
  /** Close handler */
  onClose: PropTypes.func,
  /** Overlay content */
  children: PropTypes.node,
  /** Setting nested will keep the overlay from enabling/disabling scroll locks */
  nested: PropTypes.bool,
  /** Hides close button */
  hideClose: PropTypes.bool,
  isShowingPage: PropTypes.bool,
  isCarousel: PropTypes.bool,
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
};

export default OverlayComponent;
