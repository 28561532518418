import React from 'react';

import {
  Heading,
  Body,
  Link,
} from '../../coreui';

import CalloutFrame from '../../components/CalloutFrame';
import backgroundImage from '../../assets/img_panel_REITs.jpg';

const Reits = () => {
  return (
    <CalloutFrame
      image={backgroundImage}
      imagePosition="second"
    >
      <Heading
        type="H2"
        display="block"
      >
        DIVERSIFY EASILY WITH ONE INVESTMENT
      </Heading>
      <Body
        type="B1"
        display="block"
      >
        REITs are designed to allow investors to spread an investment across multiple properties, for higher levels of diversification.
      </Body>
      <Body
        type="B1"
        display="block"
      >
        Historically, REITs have provided investors with regular income streams, portfolio diversification, and long-term capital appreciation opportunities.
      </Body>
      <Link
        type="L1"
        href="/knowledge-center/why-reits"
        mt={125}
      >
        More about REITs
      </Link>
    </CalloutFrame>
  );
};

export default Reits;
