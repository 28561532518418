import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
} from '../../../coreui';

import FadeIn from '../../FadeIn';

const TriPanelSection1 = (props) => {
  const {
    bgImage,
    body,
    header,
    link,
    minHeight,
  } = props;
  return (
    <ExtendedBox
      width={[1, 1, 0.5, 0.5, 0.5]}
      bgRepeat="no-repeat"
      bgImage={bgImage}
      bgPosition="50% 26%"
      bgSize="cover"
    >
      <ExtendedFlex
        w={1}
        px={['15%', '25%', '10%', '10%', '10%']}
        flexDirection="column"
        align={['center', 'center', 'flex-end', 'flex-end', 'flex-end']}
      >
        <ExtendedFlex
          maxWidth={['100%', '100%', 400, 400, 540]}
          minHeight={minHeight}
          flexDirection="column"
          my={[60, 60, 100, 100, 100]}
        >
          <FadeIn>
            <Heading
              type="H2"
              color="white"
              m={0}
              mb="1em"
            >
              {header}
            </Heading>
            {
              body.length &&
              body.map((item) => (
                <Body
                  key={`triPanelSection1Body:${item}`}
                  type="B1"
                  color="white"
                  fontWeight="light"
                >
                  {item}
                </Body>
              ))
            }
            <ExtendedFlex
              mt="auto"
            >
              {link}
            </ExtendedFlex>
          </FadeIn>
        </ExtendedFlex>
      </ExtendedFlex>
    </ExtendedBox>
  );
};

TriPanelSection1.propTypes = {
  bgImage: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  link: PropTypes.node,
  minHeight: PropTypes.array,
};

TriPanelSection1.defaultProps = {
  body: [],
  minHeight: [460, 550, 725, 825, 900],
};

export default TriPanelSection1;
