import React from 'react';
import PropTypes from 'prop-types';
import JsxParser from 'react-jsx-parser';
import Token from '../Token';

const tokenParser = (text) => {
  if (!text) return '';
  const regex = /{.+?}/gm;
  const replacer = (match) => {
    const code = match.replace('{', '').replace('}', '').split('|');
    return `<Token code="${code[0]}" ${code[1] ? `format="${code[1]}"` : ''} ${code[2] ? `dateFormat="${code[2]}"` : ''}/>`;
  };
  return text.replace(regex, replacer);
};

const Text = (props) => (
  <JsxParser
    components={{ Token }}
    jsx={tokenParser(props.children)}
  />
);

Text.propTypes = {
  children: PropTypes.string,
};

export default Text;
