import types from '../types';
import authTypes from '../../auth/types';

const initialState = {
  taxYear: null,
  docType: null,
  account: null,
  data: [],
  dataCache: [],
  filters: [
    {
      filter: 'taxYear',
      options: 'taxYearOptions',
      prop: 'taxYear',
    },
    {
      filter: 'docType',
      options: 'docTypeOptions',
      prop: 'docType',
    },
    {
      filter: 'account',
      options: 'accountOptions',
      prop: 'accountType',
    },
  ],
  selectAll: false,
  accountOptions: [],
  docTypeOptions: [],
  taxYearOptions: [],
  loaded: false,
};

const docTypeStrip = 'Form ';
const mapDocType = (value) => {
  return (value.substring(0, docTypeStrip.length) === docTypeStrip) ?
    value.substring(docTypeStrip.length) : value;
};

const filterDocuments = (state) => {
  const appliedFilters = state.filters.filter((filter) => {
    return state[filter.filter] !== 'All';
  });
  return {
    ...state,
    data: state.dataCache.filter((item) => {
      return appliedFilters.reduce((accum, filter) => {
        return accum && item[filter.prop] === state[filter.filter];
      }, true);
    }),
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.TAXCENTER_DOCUMENT_TOGGLE_ALL: {
      const toggledData = state.data.map((item) => {
        const updatedSelectBox = {
          ...item.selectBox,
          selected: (!state.selectAll && item.status.value === 'uploaded'),
        };
        return {
          ...item,
          selectBox: updatedSelectBox,
        };
      });
      return {
        ...state,
        data: toggledData,
        selectAll: !state.selectAll,
      };
    }
    case types.TAXCENTER_DOCUMENT_SELECTED: {
      const updatedData = state.data.map((item) => {
        const updatedSelectBox = item.selectBox.id === action.data ?
          {
            ...item.selectBox,
            selected: !item.selectBox.selected,
          } : item.selectBox;
        return {
          ...item,
          selectBox: updatedSelectBox,
        };
      });
      return {
        ...state,
        data: updatedData,
        selectAll: false,
      };
    }
    case types.TAXCENTER_API_REQUESTED: {
      return {
        ...state,
        loaded: false,
      };
    }
    case types.TAXCENTER_UPDATE_FILTER: {
      return filterDocuments({
        ...state,
        [action.name]: action.value,
      });
    }
    case types.TAXCENTER_UPDATE_FILTER_MODAL: {
      return {
        ...state,
        [action.name]: action.value,
      };
    }
    case types.TAXCENTER_RESET_FILTERS: {
      return filterDocuments({
        ...state,
        taxYear: state.taxYearOptions[0],
        docType: state.docTypeOptions[0],
        account: state.accountOptions[0],
      });
    }
    case types.TAXCENTER_API_SUCCEEDED: {
      const selectedList = state.data.reduce((accum, item) => {
        if (item.selectBox.selected === true) {
          accum.push(item.selectBox.id);
        }
        return accum;
      }, []);
      const data = action.data.data.map(
        (item) => {
          const option = item.status._optionsText[
            item.status.value
          ].split(' - ');
          const optionNumber = option[0];
          const optionText = option[1];
          const selected = selectedList.includes(item.id);
          const account = `${item.investments[0].ownershipEntity.type.label} - ${item.investments[0].ownershipEntity.label}`;
          const docType = mapDocType(item.type);
          return {
            selectBox: {
              selected,
              id: item.id,
            },
            taxYear: item.taxYear,
            account,
            accountType: item.investments[0].ownershipEntity.type.label,
            issuer: item.investments[0].io.issuer,
            investment: item.investments[0].io.label,
            ioId: item.investments[0].io.id,
            docType,
            status: {
              optionNumber,
              optionText,
              value: item.status.value,
              docURL: item.download,
            },
          };
        },
      );
      return filterDocuments({
        ...state,
        dataCache: data,
        loaded: true,
        taxYear: action.data._filters.taxYear,
        docType: state.docType || action.data._filters.docType,
        account: state.account || action.data._filters.account,
        accountOptions: action.data._filters.accountOptions,
        docTypeOptions: action.data._filters.docTypeOptions.map(mapDocType),
        taxYearOptions: action.data._filters.taxYearOptions,
      });
    }
    case authTypes.USER_LOGOUT_REQUESTED: {
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
