import * as allAnimations from 'rm-react-animations';

export const animations = {
  ...allAnimations,
  firstTimeLine: {
    from: {
      opacity: 0,
      transform: 'translate3d(-200px, 0, 0)',
    },
    to: {
      opacity: 1,
      transform: 'none',
    },
  },
  secondTimeLine: {
    from: {
      opacity: 0,
      transform: 'translate3d(200px, 0, 0)',
    },
    to: {
      opacity: 1,
      transform: 'none',
    },
  },
};

export default {
  animations,
};
