import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from '../../coreui';
import RadioGroup from '../../components/forms/RadioGroup';
import RadioLabel from '../../components/forms/RadioLabel';

const IlliquidLoseValue = ({
  isVisible,
  handleChange,
  value,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        This investment opportunity is an illiquid investment into real estate. There is a chance it could lose value including the full value of your investment. Are you comfortable with this?
      </Heading>
      <RadioGroup
        name="illiquidLoseValue"
        value={value}
        onChange={handleChange}
        inputProps={{
          'data-test': 'illiquid_lose_value',
          required: true,
        }}
      >
        <RadioLabel
          value="Yes"
          label="Yes"
        />
        <RadioLabel
          value="No, please cancel my investment"
          label="No, please cancel my investment"
        />
      </RadioGroup>
    </>
  );
};

IlliquidLoseValue.propTypes = {
  isVisible: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.oneOf([
    'Yes',
    'No, please cancel my investment',
  ]),
};

export default IlliquidLoseValue;
