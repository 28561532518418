import React from 'react';
import PropTypes from 'prop-types';

import { ExtendedBox } from '../../coreui';

// The carousel implementation we're using (`react-slick`) causes issues with clicking.
// Basically, slides in carousel do not allow for clicking components inside.
// Something to do with way the carousel does positioning, z-index, etc.
// This is a simple wrapper to "elevate" another compoent to enable clicking when in a carousel.
const ClickableEnvironmentInCarousel = ({ children }) => {
  return (
    <ExtendedBox
      position="relative"
      zIndex="1"
    >
      {children}
    </ExtendedBox>
  );
};

ClickableEnvironmentInCarousel.propTypes = {
  children: PropTypes.any,
};

export default ClickableEnvironmentInCarousel;
