import { getCurrentSession } from '../../auth/api';

export const getFormsOfOwnership = (reit, formsOfOwnership) => (
  formsOfOwnership.reduce(
    (accum, foe) => {
      const foundFoes = reit.foes.filter((item) => item.foeId === foe.id);
      const hasActiveInvestment = foundFoes.length > 0 && foundFoes[0].totalShares > 0;
      return hasActiveInvestment
        ? [
          ...accum,
          {
            // Add in the auto invest object.
            ...foundFoes[0].autoInvest,
            foe,
          },
        ]
        : accum;
    },
    [],
  )
);

export const canEditEnrollmentConfig = ({ id, status }) => {
  return id && ['paused', 'active'].includes(status);
};

export const getRoomLeft = ({
  isAccredited,
  aggregateAmount,
  isq: {
    annualIncome = 0,
    netWorth = 0,
    netWorthSpecific,
  },
}) => {
  const actualNetWorth = (
    netWorthSpecific !== null && netWorthSpecific !== undefined && netWorthSpecific
  ) || netWorth;

  if (!isAccredited) {
    // Users cannot exceed their aggregate investment amount calculated by 10%
    // of the greater of their income or net worth.
    const allowedAmount = 0.1 * Math.max(annualIncome, actualNetWorth, 0);
    return allowedAmount - aggregateAmount;
  }

  const allowedAmount = 0.2 * Math.max(annualIncome, actualNetWorth, 0);
  return allowedAmount - aggregateAmount;
};

export const isFailedSuitability = (props) => {
  if (!props.isAccredited) {
    const roomLeft = getRoomLeft(props);
    const hasRoomLeft = roomLeft - 250 >= 0;
    return !hasRoomLeft;
  }

  // Accredited users can conditionally exceed 20% of greater of their net worth
  // or income.
  return false;
};

export const getEnrollmentRedirect = async (enrollmentUrl, includeJwt) => {
  let redirectUrl = enrollmentUrl;
  if (includeJwt) {
    const session = await getCurrentSession();
    if (includeJwt) {
      const idToken = session?.idToken?.jwtToken;
      const accessToken = session?.accessToken?.jwtToken;
      const refreshToken = session?.refreshToken?.token;

      const stringifiedTokens = JSON.stringify({
        idToken,
        accessToken,
        refreshToken,
      });

      redirectUrl += `#tokens=${stringifiedTokens}`;
    }
  }

  return redirectUrl;
};

export default {
  getFormsOfOwnership,
  canEditEnrollmentConfig,
  getRoomLeft,
  isFailedSuitability,
};
