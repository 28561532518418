import React from 'react';

import {
  Image,
} from '..';

const Icon = (props) => {
  return (
    <Image
      hover
      dimOnButtonParentHover
      transition="all 0.4s cubic-bezier(.25, .46, .45, .94)"
      // eslint-disable-next-line
      {...props}
    />
  );
};

export default Icon;
