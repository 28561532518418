import React from 'react';
import PropTypes from 'prop-types';

import { Heading, Body } from '../../coreui';
import Label from '../../components/forms/Label';
import Input from '../../components/forms/Input';
import DollarInput from './DollarInput';

const IncomeNetWorth = ({
  isVisible,
  handleChange,
  annualIncome,
  netWorthSpecific,
  sourceOfWealth,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        What is your current income?
      </Heading>
      <DollarInput
        id="annualIncome"
        placeholder="Current Income"
        name="annualIncome"
        onChange={handleChange}
        data-test="annualIncome"
        maxLength="16"
        value={annualIncome || ''}
        required
      />
      <Heading
        type="H6"
        textTransform="uppercase"
        mt={50}
      >
        What is your current net worth?
      </Heading>
      <DollarInput
        id="netWorthSpecific"
        placeholder="Current Net Worth"
        name="netWorthSpecific"
        onChange={handleChange}
        data-test="netWorthSpecific"
        maxLength="18"
        value={netWorthSpecific || ''}
        required
      />
      <Heading
        type="H6"
        textTransform="uppercase"
        mt={50}
      >
        Source of wealth
      </Heading>
      <Body
        type="B2"
      >
        What is the largest contributor to your net worth? Common sources of
        wealth include savings, company sale, or inheritance. This information
        helps us to confirm your status as an accredited investor.
      </Body>
      <Label
        htmlFor="sourceOfWealth"
      >
        Source of Wealth
        <Input
          id="sourceOfWealth"
          placeholder="Source of Wealth"
          name="sourceOfWealth"
          onChange={handleChange}
          data-test="sourceOfWealth"
          maxLength="80"
          value={sourceOfWealth || ''}
          required
        />
      </Label>
    </>
  );
};

IncomeNetWorth.propTypes = {
  isVisible: PropTypes.bool,
  handleChange: PropTypes.func,
  annualIncome: PropTypes.number,
  netWorthSpecific: PropTypes.number,
  sourceOfWealth: PropTypes.string,
};

IncomeNetWorth.defaultProps = {
};

export default IncomeNetWorth;
