/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import pick from 'lodash/pick';

import {
  steps,
} from '../auth/data';

import {
  ScrollToTopOnMount,
  ExtendedFlex,
  Image,
  Heading,
  Data,
  Body,
  isDrupalPath,
} from '../coreui';

import StepCounter from '../components/StepCounter';
import Container from '../auth/components/Container';
import FirstContainer from '../auth/components/FirstContainer';
import SecondContainer from '../auth/components/SecondContainer';
import InfoBlock from '../auth/components/InfoBlock';
import UnorderedList from '../auth/components/UnorderedList';
import ListItem from '../auth/components/ListItem';

import GettingToKnowYouQuestions from './components/GettingToKnowYouQuestions';
import DashboardLoader from '../dashboard/components/DashboardLoader';

import pressMentionsData from '../homepage/pressMentions/pressMentionsData';
import actions from './actions';
import { gtkyProps } from './data';

const orderedPressMentionsData = pressMentionsData.sort((a, b) => {
  if (a.weight === b.weight) {
    return 0;
  }
  return a.weight < b.weight ? -1 : 1;
});

class GettingToKnowYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirecting: false,
    };

    this.isUnmounted = false;

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getGettingToKnowYouDraftRequested();
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  handleFormSubmit(data) {
    const {
      history,
      postGettingToKnowYouRequested,
    } = this.props;

    return (new Promise((resolve, reject) => {
      postGettingToKnowYouRequested(data, {
        resolve,
        reject,
      });
    }))
      .then(({ data: { redirect } }) => {
        if (!this.isUnmounted && redirect) {
          if (isDrupalPath(redirect)) {
            window.location = redirect;
          } else {
            history.push(redirect);
          }
          this.setState({
            redirecting: true,
          });
        }
      });
  }

  render() {
    const {
      isLoggedIn,
      isAccred,
      isPreAccred,
      isAccredViaNetWorth,
      gtkyDraft,
    } = this.props;

    const {
      redirecting,
    } = this.state;

    if (redirecting) {
      return <DashboardLoader />;
    }

    const notLoggedIn = isLoggedIn !== undefined && !isLoggedIn;
    const notAccred = isAccred !== undefined && !isAccred;

    if (isPreAccred) {
      return <Redirect to="/accreditation" />;
    }

    if (notLoggedIn || notAccred) {
      return <Redirect to="/user/login" />;
    }

    return (
      <ExtendedFlex
        flexDirection="column"
      >
        <ExtendedFlex
          w="90%"
          maxWidth={1200}
          mt={50}
          mx="auto"
          display={['none', null, 'flex']}
        >
          <StepCounter
            steps={steps}
            activeStep={1}
          />
        </ExtendedFlex>
        <Container>
          <FirstContainer
            mx={0}
            mb={0}
            pl={[null, null, 0]}
            px={10}
            boxSizing="content-box"
          >
            <DashboardLoader
              loaded={isAccred !== undefined}
            >
              <ExtendedFlex
                flexDirection="column"
              >
                <ScrollToTopOnMount />
                <Heading
                  type="H2"
                >
                  Tell us a little more about yourself.
                </Heading>
                <Body
                  type="B1"
                >
                  Securities regulation require that we learn more about your
                  investment background before giving you full access to
                  investment opportunities.
                </Body>
                <GettingToKnowYouQuestions
                  handleSubmit={this.handleFormSubmit}
                  {...gtkyDraft}
                  isAccredViaNetWorth={isAccredViaNetWorth}
                />
              </ExtendedFlex>
            </DashboardLoader>
          </FirstContainer>
          <SecondContainer
            pl={60}
            borderLeft={1}
            borderColor="gray.10"
          >
            <InfoBlock
              heading="Membership Benefits"
              headingProps={{
                mt: 0,
              }}
            >
              <UnorderedList>
                <ListItem>
                  24/7 Access to fully vetted investment opportunities.
                </ListItem>
                <ListItem>
                  A team of Investor Relations professionals are available to talk by
                  phone or email about using the RealtyMogul platform.
                </ListItem>
                <ListItem>
                  Access to exclusive opportunities in
                  institutional-quality properties.
                </ListItem>
              </UnorderedList>
            </InfoBlock>
            <InfoBlock
              heading="As seen in"
              borderBottom="none"
            >
              {
                orderedPressMentionsData.map((data) => (
                  <React.Fragment
                    key={data.name}
                  >
                    <Image
                      src={data.logoImage}
                      my={10}
                      maxWidth="140px"
                    />
                    <Data
                      type="D3"
                    >
                      {data.quote}
                    </Data>
                  </React.Fragment>
                ))
              }
            </InfoBlock>
          </SecondContainer>
        </Container>
      </ExtendedFlex>
    );
  }
}

// const mapStateToProps = (state) => ({
const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.loginSuccess ||
    (state.auth.session !== null ? state.auth.session : undefined),
  isAccred: (state.app.user.loaded && state.app.user.gtkyStatus)
    ? (
      state.app.user.gtkyStatus === 'not_submitted' &&
      state.app.user.accreditationRoleStatus === 'Accredited'
    )
    : undefined,
  isPreAccred: (state.app.user.loaded && state.app.user.accreditationRoleStatus)
    ? state.app.user.accreditationRoleStatus === 'Pre-Accredited'
    : undefined,
  isAccredViaNetWorth: state.app.user.accredViaNetWorth,
  gtkyDraft: {
    ...pick(state.gtky, gtkyProps),
  },
});

const mapDispatchToProps = (dispatch) => ({
  getGettingToKnowYouDraftRequested: () => {
    dispatch(actions.userGettingToKnowYouDraftRequested());
  },
  postGettingToKnowYouRequested: (data, promise) => {
    dispatch(actions.userGettingToKnowYouRequested(data, promise));
  },
});

GettingToKnowYou.propTypes = {
  isLoggedIn: PropTypes.bool,
  isAccred: PropTypes.bool,
  getGettingToKnowYouDraftRequested: PropTypes.func,
  postGettingToKnowYouRequested: PropTypes.func,
  history: PropTypes.object,
  isPreAccred: PropTypes.bool,
  isAccredViaNetWorth: PropTypes.bool,
  gtkyDraft: PropTypes.object,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GettingToKnowYou),
);
