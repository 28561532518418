import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
  NavLink,
} from '../../../../coreui';

import FadeIn from '../../../../components/FadeIn';

const StrategySection2 = ({
  isVisible,
}) => {
  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
      height="100%"
    >
      <ExtendedFlex
        flexDirection="column"
        height="100%"
      >
        <ExtendedBox
          m={0}
          pb={30}
          bg="gray.1"
          height={[500, 500, '50%', '50%', '50%']}
        >
          <ExtendedFlex
            flexDirection="column"
            justify="center"
            maxWidth={[null, null, 520, 520, 660]}
            height="100%"
            px={['15%', '25%', 60, 100, 120]}
          >
            <FadeIn>
              <Heading
                type="H3"
                textTransform="uppercase"
                m={0}
                mb="0.7em"
                opacity={isVisible ? '1' : '0'}
              >
                RealtyMogul Income REIT: DIVERSIFIED
              </Heading>
              <Body
                type="B1"
                m={0}
                mb="4em"
                opacity={isVisible ? '1' : '0'}
              >
                The Income REIT is a public, non-traded REIT that is focused on providing monthly income to investors through debt and equity investments in a diversified pool of commercial real estate property types including multifamily, retail, office, and industrial.
              </Body>
              <NavLink
                type="L4"
                to="/investment-opportunity/193744"
                opacity={isVisible ? '1' : '0'}
              >
                Learn more &gt;
              </NavLink>
            </FadeIn>
          </ExtendedFlex>
        </ExtendedBox>
        <ExtendedBox
          mb={30}
          height={[500, 500, '50%', '50%', '50%']}
        >
          <ExtendedFlex
            flexDirection="column"
            justify="center"
            maxWidth={[null, null, 520, 520, 660]}
            height="100%"
            px={['15%', '25%', 60, 100, 120]}
          >
            <FadeIn>
              <Heading
                type="H3"
                textTransform="uppercase"
                m={0}
                mb="0.7em"
                opacity={isVisible ? '1' : '0'}
              >
                RealtyMogul Apartment Growth REIT: MULTIFAMILY
              </Heading>
              <Body
                type="B1"
                m={0}
                mb="4em"
                opacity={isVisible ? '1' : '0'}
              >
                The Apartment Growth REIT is a public, non-traded REIT that is focused on providing investors with the opportunity for capital appreciation by investing in value-add multifamily apartment buildings with both preferred and joint-venture equity.
              </Body>
              <NavLink
                type="L4"
                to="/investment-opportunity/274949"
                opacity={isVisible ? '1' : '0'}
              >
                Learn more &gt;
              </NavLink>
            </FadeIn>
          </ExtendedFlex>
        </ExtendedBox>
      </ExtendedFlex>
    </Box>
  );
};

StrategySection2.propTypes = {
  isVisible: PropTypes.bool,
};

export default StrategySection2;
