import {
  takeLatest,
  call,
  put,
  all,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import { getUserApi } from '../../api';

export function* getUserDataFlow(action) {
  const {
    resolve,
    reject,
  } = action.payload;

  try {
    const response = yield call(getUserApi);

    const user = response.data.id ? {
      ...response.data,
      messages: response.data.unreadMessagesCount,
      isLoggedIn: !!parseInt(response.data.id, 10),
    } : {
      isLoggedIn: false,
      // Check if we got a bad response. This could happen in maintenance mode.
      ...('accreditationRoleStatus' in response.data ? {} : {
        loginUnavailable: true,
      }),
    };

    yield put(
      actions.getUserDataSucceeded(user),
    );
    if (typeof resolve === 'function') {
      resolve(user);
    }
  } catch (error) {
    yield all([
      // If we got an error, just treat it like the user is logged out.
      put(
        actions.getUserDataSucceeded({
          isLoggedIn: false,
          // If we specifically got a 503 error, then hide the login.
          ...((error && 'response' in error && error.response && 'status' in error.response && error.response.status === 503) ? {
            loginUnavailable: true,
          } : {}),
        }),
      ),
      put(
        actions.getUserDataFailed(error),
      ),
    ]);

    if (typeof reject === 'function') {
      reject(error);
    }
  }
}

export function* watchUserDataRequested() {
  yield takeLatest(
    types.GET_USER_DATA_REQUESTED,
    getUserDataFlow,
  );
}

export default watchUserDataRequested;
