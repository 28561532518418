import React from 'react';

import {
  Box,
  Flex,
} from '../../coreui';

import EarnPassiveIncomeSection1 from './components/EarnPassiveIncomeSection1';
import EarnPassiveIncomeSection2 from './components/EarnPassiveIncomeSection2';

// Makes a single slide from a slide info object (see `slideCollection` above).
// INPUT: obj, slide info object, has all the various fields that need to be addressed
// OUTPUT: JSX, appropriately formatted slide to be used in carousel
const createContentSlide = (
  slideInfo,
  isVisible,
  handleSensorChange,
  toggleModal,
) => {
  return (
    <Box
      key={slideInfo.hookTitle}
    >
      <Flex
        flexDirection={['column', 'column', 'row', 'row', 'row']}
        alignItems="center"
      >
        <EarnPassiveIncomeSection1
          slideInfo={slideInfo}
          opacity={isVisible ? '1' : '0'}
          isVisible={isVisible}
          handleSensorChange={handleSensorChange}
        />
        <EarnPassiveIncomeSection2
          slideInfo={slideInfo}
          opacity={isVisible ? '1' : '0'}
          isVisible={isVisible}
          toggleModal={toggleModal}
        />
      </Flex>
    </Box>
  );
};

export default createContentSlide;
