import React from 'react';
import PropTypes from 'prop-types';

import {
  isDrupal,
} from '../coreui';

import CancelLink from './components/CancelLink';
import AutoInvestConfirmationWithPortal from './components/AutoInvestConfirmationWithPortal';
import InvestFlowIncomeNetWorthDrupalWrapper from './components/InvestFlowIncomeNetWorthDrupalWrapper';
import PlaidLinkDrupalWrapper from './components/PlaidLinkDrupalWrapper';
import DripPercentageDrupalWrapper from './components/DripPercentageDrupalWrapper';
import NewSdiraContinueWrapper from './components/NewSdiraContinueWrapper';
import InvestFlowISQDrupalWrapper from './components/InvestFlowISQDrupalWrapper';

const {
  settings: {
    rm_invest_flow: {
      foe_id: foeId = '',
      io_id: ioId = '',
      cancel_text: cancelText = '',
      cancel_url: cancelUrl = '',
    } = {},
  } = {},
} = (isDrupal() && window.Drupal) || {};

const InvestFlow = ({
  cancelText,
  cancelUrl,
  foeId,
  ioId,
}) => {
  return (
    <>
      {
        cancelUrl &&
        <CancelLink
          path={cancelUrl}
          text={cancelText}
        />
      }
      {
        foeId && ioId &&
        <AutoInvestConfirmationWithPortal
          foe={foeId}
          io={ioId}
        />
      }
      <DripPercentageDrupalWrapper io={ioId} />
      <InvestFlowIncomeNetWorthDrupalWrapper />
      <InvestFlowISQDrupalWrapper />
      <PlaidLinkDrupalWrapper />
      <NewSdiraContinueWrapper />
    </>
  );
};

InvestFlow.propTypes = {
  cancelText: PropTypes.string,
  cancelUrl: PropTypes.string,
  foeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  ioId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

InvestFlow.defaultProps = {
  cancelText,
  cancelUrl,
  foeId,
  ioId,
};

export default InvestFlow;
