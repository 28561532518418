import React from 'react';
import PropTypes from 'prop-types';

import AccordionHeading from './AccordionHeading';
import AccordionReveal from './AccordionReveal';

import {
  ExtendedFlex,
} from '../../..';

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndexes: [],
    };

    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  toggleAccordion(id) {
    this.setState((state) => ({
      activeIndexes: state.activeIndexes.includes(id) ?
        state.activeIndexes.filter(
          (i) => {
            return i !== id;
          },
        ) :
        [
          ...state.activeIndexes,
          id,
        ],
    }));
  }

  render() {
    const {
      items,
      contentIsHtml,
      isReitLP = false,
    } = this.props;

    const {
      activeIndexes,
    } = this.state;

    const getBGColor = (index) => { return index % 2 === 0 ? 'gray.0' : 'gray.12'; };

    return (
      <ExtendedFlex
        flexDirection="column"
        justify="center"
        align="center"
        w={1}
      >
        {
          items.map(
            (item, index) => {
              const isActive = activeIndexes.includes(item.id);
              return (
                <ExtendedFlex
                  key={item.id}
                  bg={isReitLP ? 'white' : getBGColor(index)}
                  w={1}
                  flexDirection="column"
                  py={isReitLP ? 12 : 20}
                >
                  <AccordionHeading
                    content={item.header}
                    id={item.id}
                    isActive={isActive}
                    handleClick={this.toggleAccordion}
                    isReitLP={isReitLP}
                  />

                  <AccordionReveal
                    content={item.content}
                    isActive={isActive}
                    contentIsHtml={contentIsHtml}
                    isReitLP={isReitLP}
                  />
                </ExtendedFlex>
              );
            },
          )
        }
      </ExtendedFlex>
    );
  }
}

Accordion.propTypes = {
  items: PropTypes.array,
  contentIsHtml: PropTypes.bool,
  isReitLP: PropTypes.bool,
};

export default Accordion;
