import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Image,
  FlatSelect,
  Body,
} from '../../coreui';

import downArrow from '../assets/down_blk.svg';

const TaxcenterFilterDropdown = ({ options, selected, handleChange, name, ...additionalProps }) => {
  return (
    <ExtendedFlex
      // eslint-disable-next-line
      {...additionalProps}
    >
      <FlatSelect
        value={selected}
        options={
          options.map((item) => ({
            value: item,
            label: <Body type="B5">{item}</Body>,
          }))
        }
        arrowRenderer={() => { return (<Image src={downArrow} />); }}
        placeholder={<Body type="B5">Select...</Body>}
        searchable={false}
        clearable={false}
        onChange={(e) => { handleChange(name, e.value); }}
      />
    </ExtendedFlex>
  );
};

TaxcenterFilterDropdown.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleChange: PropTypes.func,
};

export default TaxcenterFilterDropdown;
