/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
} from '../../../coreui';

import StrategySection1 from './components/StrategySection1';
import StrategySection2 from './components/StrategySection2';

class Strategy2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };

    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible,
      });
    }
  }

  render() {
    return (
      <ExtendedFlex
        flexDirection={['column', 'column', 'row', 'row', 'row']}
      >
        <StrategySection1
          handleSensorChange={this.handleSensorChange}
          isLoggedIn={this.props.isLoggedIn}
          {...this.state}
        />
        <StrategySection2
          {...this.state}
        />
      </ExtendedFlex>
    );
  }
}

Strategy2.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default Strategy2;
