import types from './types';

export default {
  getLatestIsqRequested: () => ({
    type: types.GET_LATEST_ISQ_REQUESTED,
  }),
  getLatestIsqSucceeded: (data) => ({
    type: types.GET_LATEST_ISQ_SUCCEEDED,
    data,
  }),
  getLatestIsqFailed: (error) => ({
    type: types.GET_LATEST_ISQ_FAILED,
    error,
  }),
  setLatestIsqRequested: (data, promise) => ({
    type: types.SET_LATEST_ISQ_REQUESTED,
    data,
    promise,
  }),
  setLatestIsqSucceeded: (data) => ({
    type: types.SET_LATEST_ISQ_SUCCEEDED,
    data,
  }),
  setLatestIsqFailed: (error) => ({
    type: types.SET_LATEST_ISQ_FAILED,
    error,
  }),
  setIrIsqRequested: (ir, data, promise) => ({
    type: types.SET_IR_ISQ_REQUESTED,
    ir,
    data,
    promise,
  }),
  setIrIsqSucceeded: (data) => ({
    type: types.SET_IR_ISQ_SUCCEEDED,
    data,
  }),
  setIrIsqFailed: (error) => ({
    type: types.SET_IR_ISQ_FAILED,
    error,
  }),
};
