import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Heading } from '../../coreui';
import RadioGroup from '../../components/forms/RadioGroup';
import RadioLabel from '../../components/forms/RadioLabel';
import FollowUp from '../../components/isq/FollowUp';

const MoneyAccess = ({
  isVisible,
  handleChange,
  value,
  followUp,
  holdPeriod,
}) => {
  const [dirty, setDirty] = useState(false);

  const onChange = useCallback((e) => {
    setDirty(true);
    handleChange(e);
  }, [setDirty, handleChange]);

  if (!isVisible) {
    return null;
  }

  const holdPeriodString = `${holdPeriod} ${holdPeriod === 1 ? 'year' : 'years'}`;

  return (
    <>
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        Do you expect to need access to any of the money being invested within the next {holdPeriodString}?
      </Heading>
      <RadioGroup
        name="moneyAccess"
        value={value}
        onChange={onChange}
        inputProps={{
          'data-test': 'money_access',
          required: true,
        }}
      >
        <RadioLabel
          value="Yes"
          label="Yes"
        />
        <RadioLabel
          value="No"
          label="No"
        />
      </RadioGroup>
      {
        value === 'Yes' && dirty &&
        <FollowUp
          subheader={`This investment opportunity has an expected hold period of ${holdPeriodString} and is an illiquid investment that may run beyond this hold period. In order to invest, you must understand and acknowledge this risk and ensure you do not need access to any of the money being invested.`}
          name="moneyAccessFollowUp"
          value={followUp}
          handleChange={onChange}
          options={[
            {
              value: 'I understand',
              label: 'I understand',
            },
            {
              value: 'Cancel my investment',
              label: 'Cancel my investment',
            },
          ]}
        />
      }
    </>
  );
};

MoneyAccess.propTypes = {
  isVisible: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.oneOf([
    'Yes',
    'No',
  ]),
  followUp: PropTypes.oneOf([
    'I understand',
    'Cancel my investment',
  ]),
  holdPeriod: PropTypes.number,
};

export default MoneyAccess;
