/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
} from '../coreui';

const columnConfig = {
  2: {
    flexDirection: ['column', 'row'],
    align: ['start', 'center', 'start'],
    w: [1, '50%'],
    height: '100%',
    maxWidth: ['100%', 800],
  },
};

const SliderContainer = ({
  children,
  ...additionalProps
}) => {
  const childLength = React.Children.count(children);
  const config = columnConfig[childLength] || columnConfig[2];

  return (
    <ExtendedFlex
      justifyContent="center"
      px={['15%', '15%', '10%']}
      w={1}
      flexDirection={config.flexDirection}
      align={config.align}
      {...additionalProps}
    >
      {
        React.Children.map(children, (child) => {
          return (
            <ExtendedBox
              w={config.w}
              height={config.height}
              maxWidth={config.maxWidth}
            >
              {child}
            </ExtendedBox>
          );
        })
      }
    </ExtendedFlex>
  );
};

SliderContainer.propTypes = {
  children: PropTypes.node,
};

export default SliderContainer;
