import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
} from '../../../../coreui';

import FadeIn from '../../../../components/FadeIn';

const StrategySection2 = ({
  isVisible,
}) => {
  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
      height="100%"
    >
      <ExtendedFlex
        flexDirection="column"
        height="100%"
      >
        <ExtendedBox
          m={0}
          pb={30}
          bg="gray.1"
          height={[500, 500, '50%', '50%', '50%']}
        >
          <ExtendedFlex
            flexDirection="column"
            justify="center"
            maxWidth={[null, null, 520, 520, 660]}
            height="100%"
            px={['15%', '25%', 60, 100, 120]}
          >
            <FadeIn>
              <Heading
                type="H3"
                textTransform="uppercase"
                m={0}
                mb="0.7em"
                opacity={isVisible ? '1' : '0'}
              >
                TAX TREATMENT
              </Heading>
              <Body
                type="B1"
                m={0}
                mb="4em"
                opacity={isVisible ? '1' : '0'}
              >
                Multifamily is a favored investment type in
                commercial real estate due to reduced
                depreciation schedules (27.5 years v. 39
                years).
              </Body>
            </FadeIn>
          </ExtendedFlex>
        </ExtendedBox>
        <ExtendedBox
          mb={30}
          height={[500, 500, '50%', '50%', '50%']}
        >
          <ExtendedFlex
            flexDirection="column"
            justify="center"
            maxWidth={[null, null, 520, 520, 660]}
            height="100%"
            px={['15%', '25%', 60, 100, 120]}
          >
            <FadeIn>
              <Heading
                type="H3"
                textTransform="uppercase"
                m={0}
                mb="0.7em"
                opacity={isVisible ? '1' : '0'}
              >
                CASH-FLOW POTENTIAL
              </Heading>
              <Body
                type="B1"
                m={0}
                mb="4em"
                opacity={isVisible ? '1' : '0'}
              >
                $467B of low rate mortgages are available from government sponsored
                agencies Fannie Mae and Freddie Mac, per Federal Reserve Data. Cheaper
                debt may mean potential cash-flow to investors.
              </Body>
            </FadeIn>
          </ExtendedFlex>
        </ExtendedBox>
      </ExtendedFlex>
    </Box>
  );
};

StrategySection2.propTypes = {
  isVisible: PropTypes.bool,
};

export default StrategySection2;
