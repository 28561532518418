import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';
import stubResponse from '../data';

import { getGlossaryDataAPI } from '../../../api';

export const mockApi = () => ({
  data: stubResponse,
});

export function* getGlossaryDataFlow() {
  try {
    const response = yield call(getGlossaryDataAPI);
    yield put(
      actions.getGlossaryDataSucceeded(response.data),
    );
  } catch (error) {
    yield put(
      actions.getGlossaryDataFailed(error),
    );
  }
}

export function* watchGetGlossaryDataRequested() {
  yield takeLatest(
    types.GET_GLOSSARY_REQUESTED,
    getGlossaryDataFlow,
  );
}

export default watchGetGlossaryDataRequested;
