import styled from 'styled-components';
import {
  fontSize,
  width,
  height,
  maxWidth,
  minWidth,
  space,
  borders,
  borderColor,
  color,
  textAlign,
  lineHeight,
  fontWeight,
  letterSpacing,
  position,
  top,
  right,
  bottom,
  left,
} from 'styled-system';
import Theme from '../Theme';

import {
  hoverColor,
  hoverBackgroundColor,
  hoverBorderColor,
  loadingLinkStyles,
  extendDefaultTransition,
} from '../helpers/style';

const Button = styled.button`
  ${fontSize}
  ${width}
  ${height}
  ${maxWidth}
  ${minWidth}
  ${space}
  ${borders}
  ${color}
  ${textAlign}
  ${lineHeight}
  ${fontWeight}
  ${letterSpacing}
  ${borderColor}
  ${top}
  ${right}
  ${bottom}
  ${left}
  ${position}
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ${(props) => Theme.fontFamily[props.fontFamily] || 'Helvetica, Arial, sans-serif'};
  text-indent: ${(props) => props.textIndent};
  text-transform: ${(props) => (props.textTransform || 'none')};
  font-style: ${(props) => props.fontStyle || 'normal'};
  white-space: ${(props) => props.whiteSpace || 'normal'};
  display: ${(props) => props.display || 'inline'};
  opacity: ${(props) => props.opacity || '1'};
  box-sizing: border-box;
  text-decoration: ${(props) => (props.textDecoration || 'none')};
  transition: ${(props) => extendDefaultTransition(props.transition)};
  ${(props) => (props.outline ? `outline: ${props.outline};` : '')}

  &:hover {
    cursor: pointer;
    ${hoverColor}
    ${hoverBackgroundColor}
    ${hoverBorderColor}
  }

  &.active {
    background: black;
    color: white;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${loadingLinkStyles}
`;

export default Button;
