import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import { callApiCallback } from '../../api';

export function* watchlistToggleDataFlow(action) {
  try {
    const id = action.opts.id;
    const response = yield call(callApiCallback, action.opts.callback);
    yield put(
      actions.watchListToggleApiActionSucceeded(
        {
          ...response.data,
          id,
        },
      ),
    );
  } catch (error) {
    yield put(
      actions.watchListToggleApiActionFailed(
        error,
      ),
    );
  }
}

export function* watchWatchListToggleRequested() {
  yield takeLatest(
    types.WATCHLIST_TOGGLE_REQUESTED,
    watchlistToggleDataFlow,
  );
}

export default watchWatchListToggleRequested;
