import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ExtendedFlex,
  Body,
  Heading,
  Link,
  StyledButton,
} from '../../coreui';

import actions from '../actions';

class CancelModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmLoading: false,
    };

    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleWaitlistClick = this.handleWaitlistClick.bind(this);
  }

  handleCancelClick() {
    this.props.cancelInvestment(this.props.id, this.props.cancel);

    this.setState({
      confirmLoading: true,
    });
  }

  handleWaitlistClick() {
    this.props.waitlistFlag(this.props.id, this.props.cancel);

    this.setState({
      confirmLoading: true,
    });
  }

  render() {
    const {
      canCancel,
      cancel,
      handleClick,
      investmentStage,
      cancelModalRedirect,
    } = this.props;

    if (this.state.confirmLoading && cancelModalRedirect) {
      window.location = cancelModalRedirect;
    }

    return (
      <ExtendedFlex
        flexDirection="column"
        align="center"
        justify="center"
      >
        <Heading
          type="H3"
        >
          {
            !canCancel &&
            'Unable to Cancel'
          }
          {
            canCancel &&
            'Cancel Investment'
          }
        </Heading>
        <Body
          type="B1"
          textAlign="center"
        >
          {
            !canCancel &&
            'This investment can no longer be cancelled. Please contact us if you have questions.'
          }
          {
            canCancel && !cancel &&
            'This investment can not be cancelled from your dashboard at this time. Please contact us if you would like to cancel.'
          }
          {
            canCancel && cancel && investmentStage !== 'waitlist' &&
            `By cancelling, you will forfeit your reserved spot and it will be removed from your "${investmentStage === 'pending' ? 'Pending' : 'Pledged'} Investments". Should you change your mind after cancelling, you will need to re-complete the investment process.`
          }
          {
            investmentStage === 'waitlist' &&
            'By cancelling, it will be removed from your "Waitlist Investments".'
          }
        </Body>
        {
          canCancel && cancel &&
          <Body
            type="B1"
            textAlign="center"
          >
            {
              investmentStage !== 'waitlist' &&
              'Do you wish to cancel this investment?'
            }
            {
              investmentStage === 'waitlist' &&
              'Do you wish to remove this investment?'
            }
          </Body>
        }
        <ExtendedFlex
          align="center"
          justify="center"
        >
          {
            !cancel &&
            <>
              <Body
                type="B1"
                textAlign="center"
                mx={20}
              >
                (877) 977-2776
              </Body>
              <Body
                type="B1"
                textAlign="center"
                mx={20}
              >
                investor-help@realtymogul.com
              </Body>
            </>
          }
          {
            cancel &&
            <ExtendedFlex
              flexDirection="row"
              align="center"
              justify="center"
              mt={20}
            >
              <StyledButton
                type="L3"
                onClick={() => {
                  if (!this.state.confirmLoading) {
                    handleClick();
                  }
                }}
                mx={10}
              >
                Back
              </StyledButton>
              <Link
                type="L1"
                mx={10}
                loading={this.state.confirmLoading}
                // eslint-disable-next-line
                {...(investmentStage === 'waitlist' ? {
                  onClick: () => {
                    if (!this.state.confirmLoading) {
                      this.handleWaitlistClick();
                    }
                  },
                } : {
                  onClick: () => {
                    if (!this.state.confirmLoading) {
                      this.handleCancelClick();
                    }
                  },
                })}
              >
                Confirm
              </Link>
            </ExtendedFlex>
          }
        </ExtendedFlex>
      </ExtendedFlex>
    );
  }
}

CancelModal.propTypes = {
  id: PropTypes.number,
  canCancel: PropTypes.bool,
  cancel: PropTypes.string,
  handleClick: PropTypes.func,
  waitlistFlag: PropTypes.func,
  cancelInvestment: PropTypes.func,
  investmentStage: PropTypes.string,
  cancelModalRedirect: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    cancelModalRedirect: state.dashboard.cancelModalRedirect,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    waitlistFlag: (id, callback) => {
      dispatch(actions.pendingDashboardWaitlistFlagRequested(id, false, callback));
    },
    cancelInvestment: (id, callback) => {
      dispatch(actions.cancelInvestmentRequested(id, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelModal);
