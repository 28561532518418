import React from 'react';
import PropTypes from 'prop-types';

import { Body, ScrollToTopOnMount, Link, Heading } from '../../coreui';

import ShareRepurchaseHeading from './ShareRepurchaseHeading';
import ShareRepurchaseWrapper from './ShareRepurchaseWrapper';

const ShareRepurchaseConfirmation = ({
  investmentOpportunityLabel,
  currentAccountTypeLabel,
  currentAccountLabel,
  sharesPercentFormatted,
  repeat,
}) => {
  return (
    <ShareRepurchaseWrapper>
      <ScrollToTopOnMount />
      <ShareRepurchaseHeading>Thank you</ShareRepurchaseHeading>
      <Heading type="H4" textTransform="uppercase">
        Repurchase request received for:
      </Heading>
      <Body type="B1">
        {investmentOpportunityLabel} - {currentAccountTypeLabel}:{' '}
        {currentAccountLabel} - {sharesPercentFormatted}{' '}
        {repeat ? 'recurring' : 'one time'}
      </Body>
      <Body type="B1">
        The current status of your request is pending. Your repurchase request
        requires a signature from all authorized parties to be completed. We
        will email you a DocuSign Repurchase Package to execute within 3
        business days. Please check your junk/spam email folders if you do not
        receive the DocuSign. For the status of your repurchase request, please
        visit your Investor Dashboard.
      </Body>
      <Link type="L4" to="/investor-dashboard">
        View my Dashboard &gt;
      </Link>
    </ShareRepurchaseWrapper>
  );
};

ShareRepurchaseConfirmation.propTypes = {
  investmentOpportunityLabel: PropTypes.string,
  currentAccountTypeLabel: PropTypes.string,
  currentAccountLabel: PropTypes.string,
  sharesPercentFormatted: PropTypes.string,
  repeat: PropTypes.bool,
};

export default ShareRepurchaseConfirmation;
