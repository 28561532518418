import { all } from 'redux-saga/effects';

import appWrapperSagas from './appWrapper/sagas';
import authSagas from './auth/sagas';
import accreditationSagas from './accreditation/sagas';
import gettingToKnowYouSagas from './gtky/sagas';
import homepageSagas from './homepage/sagas';
import careersSagas from './careers/sagas';
import ourStorySagas from './ourStory/sagas';
import browseSagas from './browse/sagas';
import knowledgeCenterSagas from './knowledgeCenter/sagas';
import investedSagas from './invested/sagas';
import faqSagas from './faq/sagas';
import dashboardSagas from './dashboard/sagas';
import adminMenuSagas from './adminMenu/sagas';
import queueFAQItemsSagas from './components/queueFAQItems/sagas';
import popularContentSagas from './knowledgeCenter/components/popularContent/sagas';
import glossarySagas from './knowledgeCenter/glossary/sagas';
import ioDetailsSagas from './ioDetails/sagas';
import retirementSagas from './retirement/sagas';
import flagSagas from './components/FlagButton/sagas';
import dsmSagas from './dsm/sagas';
import revalidateEmailSagas from './revalidateEmail/sagas';
import salesforceSagas from './salesforce/sagas';
import isqSagas from './isq/sagas';
import shareRepurchaseRequestSagas from './shareRepurchase/sagas';
import dataLayerSagas from './dataLayer/sagas';
import reitsDataSagas from './components/ReitLanding/sagas';
import datadogSagas from './datadog/sagas';

export default () => {
  return function* () {
    yield all([
      ...appWrapperSagas,
      ...authSagas,
      ...datadogSagas,
      ...accreditationSagas,
      ...gettingToKnowYouSagas,
      ...careersSagas,
      ...ourStorySagas,
      ...homepageSagas,
      ...browseSagas,
      ...flagSagas,
      ...knowledgeCenterSagas,
      ...investedSagas,
      ...faqSagas,
      ...dashboardSagas,
      ...adminMenuSagas,
      ...queueFAQItemsSagas,
      ...popularContentSagas,
      ...glossarySagas,
      ...ioDetailsSagas,
      ...retirementSagas,
      ...dsmSagas,
      ...revalidateEmailSagas,
      ...salesforceSagas,
      ...isqSagas,
      ...shareRepurchaseRequestSagas,
      ...dataLayerSagas,
      ...reitsDataSagas,
    ]);
  };
};
