/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  NavLink,
  ExtendedBox,
  ExtendedFlex,
  Image,
  ClickableLogo,
  isDrupal,
} from '../../coreui';

import {
  profileUrl,
} from '../../dashboard/links';

import HeaderMobileUserMenu from './HeaderMobileUserMenu';

import carat from '../../assets/down_expand_white.svg';

class HeaderMobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNavItems: [],
      items: [],
      zIndex: -1,
    };
    this.childMenuClickToggle = this.childMenuClickToggle.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.items !== nextProps.items) {
      let items;
      if (nextProps.user.isLoggedIn) {
        items = [
          ...nextProps.items,
          {
            children: [],
            id: '1234',
            label: 'Profile',
            machineName: 'profile',
            parent: 0,
            url: profileUrl,
            drupal: true,
          },
        ];
      } else {
        items = nextProps.items;
      }

      this.setState({
        items,
      });
    }

    if (this.props.mobileMenuVisible !== nextProps.mobileMenuVisible) {
      if (nextProps.mobileMenuVisible) {
        if (this.zIndexTimeout) {
          clearTimeout(this.zIndexTimeout);
        }
        this.setState({ zIndex: 9990 });
      } else {
        this.zIndexTimeout = setTimeout(() => {
          this.setState({ zIndex: -1 });
        }, 1000);
      }
    }
  }

  childMenuClickToggle(clickedItem, e) {
    const activeNavItem = clickedItem.machineName;

    if (clickedItem.children.length > 0) {
      e.preventDefault();
    }

    let activeNavItems;
    if (this.state.activeNavItems.includes(activeNavItem)) {
      activeNavItems = this.state.activeNavItems.filter((item) => item !== activeNavItem);
    } else {
      activeNavItems = [
        ...this.state.activeNavItems,
        activeNavItem,
      ];
    }

    this.setState({
      activeNavItems });
  }

  render() {
    const {
      mobileMenuVisible,
      user,
      closeMenu,
      handleLogout,
      isApp,
      frontendPrefix,
    } = this.props;
    const { activeNavItems, items } = this.state;
    const inDrupal = isDrupal();
    return (
      <ExtendedBox
        display={['block', 'block', 'block', 'none']}
        visibility={mobileMenuVisible ? 'visible' : 'hidden'}
        position={['fixed', 'fixed', 'fixed', 'absolute']}
        zIndex={this.state.zIndex}
        top={[0, 0, 0, 'auto']}
        left={[0, 0, 0, 'auto']}
        width={['100vw', '100vw', '100vw', 'auto']}
        height={['100vh', '100vh', '100vh', 'auto']}
        bg={['gray.9', 'gray.9', 'gray.9', 'transparent']}
        color={['white', 'white', 'white', 'black']}
        textAlign="center"
        opacity={
          mobileMenuVisible ?
            '1' :
            '0'
        }
        transition={`opacity 0.1s ${mobileMenuVisible ? '0' : '0.1'}s linear, visibility 0.1s ${mobileMenuVisible ? '0' : '0.1'}s linear`}
      >
        <ExtendedFlex
          w={1}
          height={[70, 70, 80]}
          justify="center"
          align="center"
          visibility={mobileMenuVisible ? 'visible' : 'hidden'}
          px={[30, 30, 30, '3%']}
        >
          <ExtendedFlex
            maxWidth={150}
          >
            <ClickableLogo
              logoColor="white"
              onClick={closeMenu}
              isApp={isApp}
              frontendPrefix={frontendPrefix}
            />
          </ExtendedFlex>
        </ExtendedFlex>
        {
          items && items.map(
            (item, index) => {
              const isActive = activeNavItems.includes(item.machineName);

              return (
                <ExtendedBox
                  key={item.machineName}
                  id={item.machineName}
                  visibility={mobileMenuVisible ? 'visible' : 'hidden'}
                  borderTop={index === 0 ? 1 : 0}
                  borderBottom={1}
                  borderColor="gray.7"
                  mx={30}
                  textAlign="center"
                  opacity={
                    mobileMenuVisible ?
                      '1' :
                      '0'
                  }
                  transition="opacity 0.1s linear"
                >
                  <Link
                    type="L5"
                    {
                    ...((item.drupal || inDrupal || isApp) ? {
                      href: item.url,
                    } : {
                      to: item.url,
                    })}
                    id={item.machineName}
                    color={isActive ? 'blue.2' : 'white'}
                    display="block"
                    hoverColor={isActive ? 'blue.2' : 'white'}
                    py="20px"
                    onClick={(e) => {
                      if (item.children.length > 0) {
                        this.childMenuClickToggle(item, e);
                      } else if (!item.drupal) {
                        closeMenu(e);
                      }
                    }}
                  >
                    <ExtendedFlex
                      align="center"
                      flexDirection="row"
                      visibility={mobileMenuVisible ? 'visible' : 'hidden'}
                      justify="center"
                      id={item.machineName}
                      onClick={(e) => this.childMenuClickToggle(item, e)}
                      transition="visibility 0.1s linear"
                    >
                      {item.label}
                      {
                        item.children.length > 0 &&
                        <Image
                          src={carat}
                          w={13}
                          id={item.machineName}
                          onClick={(e) => this.childMenuClickToggle(item, e)}
                          height={13}
                          transition="transform 0.1s linear"
                          transform={isActive ? 'rotate(180deg)' : 'none'}
                          ml={5}
                        />
                      }
                    </ExtendedFlex>
                  </Link>
                  {
                    item.children.length > 0 &&
                    <ExtendedBox
                      maxHeight={isActive ? '1000px' : '0px'}
                      visibility={
                        item.children.length > 0 && isActive ?
                          'visible' :
                          'hidden'
                      }
                      overflowY="hidden"
                      transition="max-height 0.2s ease-in-out"
                    >
                      <ExtendedFlex
                        justify="center"
                        flexDirection="column"
                        align="center"
                        pb={20}
                      >
                        {
                          item.children && item.children.map(
                            (child) => {
                              return (
                                <ExtendedBox
                                  key={child.machineName}
                                >
                                  <NavLink
                                    type="L5"
                                    id={child.machineName}
                                    to={child.url}
                                    drupal={child.drupal}
                                    hoverColor="white"
                                    onClick={closeMenu}
                                    py={10}
                                    display="block"
                                    color="white"
                                    withActiveStyle={false}
                                  >
                                    {child.label}
                                  </NavLink>
                                </ExtendedBox>
                              );
                            },
                          )
                        }
                      </ExtendedFlex>
                    </ExtendedBox>
                  }
                </ExtendedBox>
              );
            },
          )
        }
        <HeaderMobileUserMenu
          user={user}
          mobileMenuVisible={mobileMenuVisible}
          closeMenu={closeMenu}
          handleLogout={handleLogout}
          isApp={isApp}
          frontendPrefix={frontendPrefix}
        />
      </ExtendedBox>
    );
  }
}

HeaderMobileMenu.propTypes = {
  mobileMenuVisible: PropTypes.bool,
  items: PropTypes.array,
  user: PropTypes.object,
  closeMenu: PropTypes.func,
  handleLogout: PropTypes.func,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

export default HeaderMobileMenu;
