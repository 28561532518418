import React from 'react';
import PropTypes from 'prop-types';

import { Link, Button } from '..';

const StyledButton = ({
  children,
  ...props
}) => {
  return (
    <Link
      component={Button}
      type="L1"
      buttonType="button"
      outline="none"
      // eslint-disable-next-line
      {...props}
    >
      {children}
    </Link>
  );
};

StyledButton.propTypes = {
  children: PropTypes.any,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default StyledButton;
