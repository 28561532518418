/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import animateScrollTo from 'animated-scroll-to';
import {
  withRouter,
} from 'react-router-dom';

class ScrollToHash extends Component {
  constructor(props) {
    super(props);
    this.wrapper = null;

    this.setWrapper = this.setWrapper.bind(this);
    this.checkIfShouldScroll = this.checkIfShouldScroll.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
  }

  componentDidMount() {
    this.checkIfShouldScroll();
  }

  componentDidUpdate(prevProps) {
    const prevRequestedLocation = prevProps.location.hash.substr(1);
    const requestedLocation = this.props.location.hash.substr(1);

    if (prevRequestedLocation !== requestedLocation) {
      this.checkIfShouldScroll();
    }
  }

  setWrapper(element) {
    this.wrapper = element;
  }

  checkIfShouldScroll() {
    // If location exists, scroll to it
    const requestedLocation = this.props.location.hash.substr(1);
    if (requestedLocation === this.props.name) {
      setTimeout(() => {
        this.scrollTo();
      }, this.props.delay);
    }
  }

  scrollTo() {
    animateScrollTo(this.wrapper, {
      passive: true,
      onComplete: this.props.onComplete,
      offset: this.props.offset,
    });
  }

  render() {
    const Wrapper = this.props.wrapper ? 'div' : React.Fragment;
    return (
      <Wrapper
        {
          ...(this.props.wrapper
            ? {
              ref: (el) => { this.wrapper = el; },
            }
            : {}
          )
        }
      >
        {
          !this.props.wrapper &&
          <span ref={(el) => { this.wrapper = el; }} />
        }
        {this.props.children}
      </Wrapper>
    );
  }
}

ScrollToHash.propTypes = {
  location: PropTypes.object,
  name: PropTypes.string,
  children: PropTypes.object,
  delay: PropTypes.number,
  onComplete: PropTypes.func,
  offset: PropTypes.number,
  wrapper: PropTypes.bool,
};

ScrollToHash.defaultProps = {
  delay: 100,
  onComplete() {},
  offset: 0,
  wrapper: true,
};

export default withRouter(ScrollToHash);
