import React from 'react';
import PropTypes from 'prop-types';

import numeral from 'numeral';

export const registerFormatter = () => {
  if (!numeral.locales.en.abbreviations.million !== 'mm') {
    numeral.locales.en.abbreviations.million = 'mm';
  }
};

class Numeral extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {

    };
    registerFormatter();
  }

  render() {
    const {
      value,
      format,
      upper,
    } = this.props;

    let result = numeral(value).format(format);
    if (format.includes('w')) {
      result = result.replace(numeral.locales.en.abbreviations.million, 'million')
        .replace(numeral.locales.en.abbreviations.billion, 'billion');
    }

    return (
      <>
        { upper ? result.toUpperCase() : result }
      </>
    );
  }
}

Numeral.propTypes = {
  value: PropTypes.number,
  format: PropTypes.string,
  upper: PropTypes.bool,
};

Numeral.defaultProps = {
  upper: true,
};

export default Numeral;
