import store from '../store';

import actions from './actions';
import { isMockSession } from './api';

let dataMemory = {};
let syncPromise = null;

const webClientId = window._env_.REACT_APP_COGNITO_WEB_CLIENT_ID;
const prefix = `CognitoIdentityServiceProvider.${webClientId}`;

class Storage {
  // set item with the key
  static setItem(key, value) {
    dataMemory[key] = value;
  }

  // get item with the key
  static getItem(key) {
    return Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : undefined;
  }

  // remove item with the key
  static removeItem(key) {
    return delete dataMemory[key];
  }

  // clear out the storage
  static clear() {
    dataMemory = {};
    return dataMemory;
  }

  // If the storage operations are async(i.e AsyncStorage)
  // Then you need to sync those items into the memory in this method
  static sync() {
    if (!syncPromise) {
      syncPromise = new Promise((res) => {
        if (isMockSession()) {
          res();
          return;
        }
        store.dispatch(actions.userSessionRequested(res));
      });

      syncPromise.then(({
        mail: email,
        accessToken,
        idToken,
        refreshToken,
      } = {}) => {
        if (email) {
          Storage.setItem(`${prefix}.${email}.accessToken`, accessToken);
          Storage.setItem(`${prefix}.${email}.idToken`, idToken);
          Storage.setItem(`${prefix}.${email}.refreshToken`, refreshToken);
          Storage.setItem(`${prefix}.LastAuthUser`, email);
        }
      })
        .catch(() => {
          // Do nothing.
        });
    }
    return syncPromise;
  }
}

export default Storage;
