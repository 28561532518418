/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

class LoadableIntlTelInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IntlTelInput: null,
    };
  }

  UNSAFE_componentWillMount() {
    import(
      /* webpackChunkName: "intltelinput" */
      // eslint-disable-next-line
      'react-intl-tel-input'
    ).then((IntlTelInput) => {
      this.setState({ IntlTelInput: IntlTelInput.default });
    });
  }

  render() {
    const {
      IntlTelInput,
    } = this.state;
    if (IntlTelInput) {
      const {
        setPhoneInputRef,
        ...props
      } = this.props;
      return (
        <IntlTelInput
          {...(setPhoneInputRef ? {
            ref: setPhoneInputRef,
          } : {})}
          {...props}
        />
      );
    }
    return null;
  }
}

LoadableIntlTelInput.propTypes = {
  setPhoneInputRef: PropTypes.func,
};

export default LoadableIntlTelInput;
