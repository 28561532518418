import styled from 'styled-components';

export default {
  ReitPortfolio: styled.div`
    width: 100%;
    padding: 70px 16px 80px;
    box-sizing: border-box;
    display: block;
    font-family: mrs-eaves-xl-serif-narrow,serif;
    color: #202020;
    background: ${({ background }) => `${background}`};
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      padding: 110px 100px 120px;
    }
  `,
  Container: styled.div`
    max-width: 1024px;
    margin: 0 auto;
    h1 {
      margin: 0 0 15px 0;
      font-weight: 800;
      font-size: 28px;
      line-height: 32px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      h1 {
        font-size: 44px;
        line-height: 56px;
      }
    }
    .ReactTable .rt-tbody .rt-tr-group {
      border-bottom: none;
    }
  `,
  ButtonContainer: styled.div`
    width: 100%;
    margin: 40px auto 0;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      width: 65%;
    }
  `,
  PortfolioSlide: styled.div`
    display: flex;
    flex-direction: column;
  `,
  SlideHeader: styled.div`
    height: 100%;
    width: 100%;
    padding: 10px 0;
    color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .name {
      font-family: mrs-eaves-xl-serif-narrow,serif;
      font-size: 24px;
      line-height: 28px;
      font-weight: 800;
      max-width: 75%;
      @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
        font-size: 28px;
        line-height: 32px;
      }
    }
    .location {
      font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 24px;
      color: #F2F2F2;
    }
  `,
  Info: styled.div`
    padding: 32px 16px;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      padding: 32px;
    }
  `,
  StatsContainer: styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 25px;
  `,
  StatItem: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    width: 100%;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    span {
      color: #202020;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
    }
    .spacer {
      flex: 1;
      margin: 0 8px;
      height: 24px;
      border-bottom: 1px dashed #D1D1D1;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      width: 48.5%;
      margin-bottom: 0;
      span {
        font-size: 14px;
        line-height: 32px;
      }
    }
  `,
  BodyCopy: styled.div`
    h2 {
      font-family: mrs-eaves-xl-serif-narrow,serif;
      font-size: 24px;
      line-height: 28px;
      font-weight: 800;
      margin: 0;
    }
    p {
      font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 24px;
      color: #777777;
      small {
        font-size: 14px;
        line-height: 24px;
      }
      strong {
        font-family: mrs-eaves-xl-serif-narrow,serif;
        font-size: 18px;
        line-height: 24px;
        font-weight: 800;
        color: #202020
        margin: 0;
      }
      span {
        font-size: initial !important;
      }
    }
    li {
      font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 24px;
      color: #777777;
    }
  `,
  Disclaimer: styled.div`
    margin-top: 50px;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 18px;
    color: #777777;
  `,
};
