import {
  takeEvery,
  call,
  put,
  select,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import getUserId from '../selectors/getUserId';
import getToken from '../selectors/getToken';

import { setUserApi } from '../../api';

export function* setUserDataFlow(action) {
  const {
    resolve,
    reject,
    data,
  } = action.payload;

  try {
    const userId = yield select(getUserId);
    const token = yield select(getToken);

    const response = yield call(setUserApi, userId, token, data);

    const user = {
      ...response.data,
      messages: response.data.unreadMessagesCount,
      isLoggedIn: !!parseInt(response.data.id, 10),
    };

    yield put(
      actions.setUserDataSucceeded(user),
    );

    if (typeof resolve === 'function') {
      resolve(user);
    }
  } catch (error) {
    yield put(
      actions.setUserDataFailed(error),
    );

    if (typeof reject === 'function') {
      reject(error);
    }
  }
}

export function* watchSetUserDataRequested() {
  yield takeEvery(
    types.SET_USER_DATA_REQUESTED,
    setUserDataFlow,
  );
}

export default watchSetUserDataRequested;
