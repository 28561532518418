import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _set from 'lodash/set';
import startsWith from 'lodash/startsWith';
import pick from 'lodash/pick';
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';

import {
  enrollmentSteps as steps,
  isqProps,
} from '../data';

import {
  cleanUpIsqSubmission,
} from '../../../isq/utility';

import {
  ExtendedFlex,
  Heading,
  Body,
} from '../../../coreui';
import Stepper from '../../../components/forms/Stepper';
import Actions from '../../../components/forms/Actions';
import PrimaryButton from '../../../components/forms/PrimaryButton';
import CancelButtonWithModal from './CancelButtonWithModal';
import Form from '../../../components/forms/Form';
import PrimaryReason from '../../../components/isq/PrimaryReason';
import Experience from '../../../components/isq/Experience';
import Exit from '../../../components/isq/Exit';
import Risk from '../../../components/isq/Risk';
import IncomeNetWorth from '../../../components/isq/IncomeNetWorth';
import EmploymentStatus from '../../../components/isq/EmploymentStatus';
import StepCounter from './StepCounter';
import AutoInvestContentWrapper from './AutoInvestContentWrapper';
import ScrollToSpanOnMount from '../../../components/ScrollToSpanOnMount';
import EnrollmentErrors from './EnrollmentErrors';

class ISQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      total: 6,
      submitting: false,
      riskIsValid: undefined,
      ...(pick(props.latestIsq, isqProps)),
    };
    if (!Array.isArray(this.state.risk)) {
      this.state.risk = [];
    }

    this.isUnmounted = false;

    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  handleAddressChange(e) {
    const { name, value } = e.target;
    this.setState((state) => {
      const employerAddress = {
        employerAddress: {
          ...state.employerAddress,
        },
      };
      _set(employerAddress, name, value);
      // Reset the state if it changed.
      if (employerAddress.employerAddress.country !== 'US') {
        delete employerAddress.employerAddress.administrative_area;
      }
      return employerAddress;
    });
  }

  handleChange(e) {
    const { name, value, type, checked } = e.target;
    let additionalStateChanges = {};

    if (name === 'employmentType') {
      // Reset the employer data when it's changing.
      additionalStateChanges = {
        employer: '',
        employmentPositionHeld: '',
        employmentIndustry: '',
        sameWorkAddress: false,
        sourceOfIncome: '',
        employerAddress: {
          administrative_area: '',
          country: '',
          locality: '',
          postal_code: '',
          premise: '',
          thoroughfare: '',
        },
      };
    } else if (name === 'netWorth') {
      // Reset net worth specific so users can set their value.
      additionalStateChanges = {
        netWorthSpecific: '',
      };
    } else if (name === 'sameWorkAddress') {
      // Reset net worth specific so users can set their value.
      additionalStateChanges = {
        employerAddress: {
          administrative_area: '',
          country: '',
          locality: '',
          postal_code: '',
          premise: '',
          thoroughfare: '',
        },
      };
    } else if (name === 'primaryInvestmentReason') {
      additionalStateChanges = {
        primaryInvestmentReasonFollowUp: undefined,
      };
    } else if ([
      'expStocks',
      'expBonds',
      'expRealEstate',
      'expPrivateEquity',
      'expVentureCapital',
      'expSmallBiz',
    ].includes(name)) {
      additionalStateChanges = {
        experienceFollowUp: undefined,
      };
    } else if (name === 'exit') {
      additionalStateChanges = {
        exitFollowUp: undefined,
      };
    } else if (name === 'risk') {
      additionalStateChanges = {
        riskFollowUp: undefined,
      };
    }

    if (startsWith(name, 'employerAddress.')) {
      this.handleAddressChange(e);
    } else {
      const checkType = ['checkbox'].includes(type);
      this.setState((state) => ({
        ...additionalStateChanges,
        [name]: (checkType && Array.isArray(state[name]))
          // Handle the case where we have a group of checkboxes.
          ? ((reducedSet) => (
            // Start with a reduced set that definitely excludes the value.
            // Then we just add it back in if the value is checked.
            checked ? [
              ...reducedSet,
              value,
            ] : reducedSet
          ))(state[name].filter((val) => val !== value))
          // Otherwise, it's just 1:1 with name to value.
          : (() => (checkType ? checked : value))(),
      }), () => {
        if (name === 'risk') {
          this.setState((state) => ({
            riskIsValid: state.risk.length > 0,
          }));
        }
      });
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();

    if (this.state.step < this.state.total) {
      if (this.state.step === 4) {
        const riskInvalid = this.state.riskIsValid === false || isEqual(this.state.risk, []);
        if (this.state.riskIsValid === undefined
            && isEqual(this.state.risk, [])) {
          this.setState({
            riskIsValid: false,
          });
        }
        if (riskInvalid) {
          return;
        }
      }
      this.setState(((state) => ({
        step: state.step + 1,
      })));
    } else {
      const isqSubmission = cleanUpIsqSubmission(
        this.state,
        { timeHorizon: this.props.timeHorizon, isqProps },
      );

      const promise = this.props.handleSubmit({
        ...isqSubmission,
        // Fix any default values.
        employmentType: isqSubmission.employmentType === null
          ? 'employed'
          : isqSubmission.employmentType,
      });
      if (promise) {
        this.setState({
          submitting: true,
        });
        promise.finally(() => {
          if (!this.isUnmounted) {
            this.setState({
              submitting: false,
            });
          }
        });
      }
    }
  }

  render() {
    const {
      showStepCounter,
      handleCancel,
      cancelText,
      cancelHeader,
      cancelBody,
      enrollmentErrors,
      timeHorizon,
    } = this.props;
    const {
      step,
      total,
      primaryInvestmentReason,
      primaryInvestmentReasonFollowUp,
      expStocks,
      expBonds,
      expRealEstate,
      expPrivateEquity,
      expVentureCapital,
      expSmallBiz,
      experienceFollowUp,
      exit,
      exitFollowUp,
      risk,
      riskFollowUp,
      riskIsValid,
      annualIncome,
      netWorth,
      netWorthSpecific,
      sourceOfWealth,
      sourceOfIncome,
      employmentType,
      employer,
      employmentPositionHeld,
      employmentIndustry,
      sameWorkAddress,
      employerAddress,
      submitting,
    } = this.state;

    return (
      <AutoInvestContentWrapper>
        <ScrollToSpanOnMount key={step} offset={[-60, -110, null, -110]} />
        {
          showStepCounter &&
          <StepCounter
            steps={steps}
            activeStep={1}
          />
        }
        <ExtendedFlex
          w={[1, null, 650]}
          flexDirection="column"
        >
          <Heading
            type="H2"
          >
            Tell us more about yourself
          </Heading>
          <Body
            type="B1"
          >
            We need to learn more about your investment experience and
            preferences to gauge if this investment is appropriate for you.
          </Body>
          <Stepper
            step={step}
            total={total}
          />
          <Form onSubmit={(e) => this.handleFormSubmit(e)} >
            <ExtendedFlex
              flexDirection="column"
              w={1}
            >
              {step === 1 && (
                <PrimaryReason
                  value={primaryInvestmentReason}
                  followUp={primaryInvestmentReasonFollowUp}
                  handleChange={this.handleChange}
                />
              )}
              {step === 2 && (
                <Experience
                  expStocks={expStocks}
                  expBonds={expBonds}
                  expRealEstate={expRealEstate}
                  expPrivateEquity={expPrivateEquity}
                  expVentureCapital={expVentureCapital}
                  expSmallBiz={expSmallBiz}
                  followUp={experienceFollowUp}
                  handleChange={this.handleChange}
                />
              )}
              {step === 3 && (
                <Exit
                  value={exit}
                  handleChange={this.handleChange}
                  followUp={exitFollowUp}
                  timeHorizon={timeHorizon}
                />
              )}
              {step === 4 && (
                <Risk
                  value={risk}
                  handleChange={this.handleChange}
                  followUp={riskFollowUp}
                  isValid={riskIsValid}
                />
              )}
              {step === 5 && (
                <IncomeNetWorth
                  handleChange={this.handleChange}
                  annualIncome={`${annualIncome}`}
                  netWorth={`${netWorth}`}
                  netWorthSpecific={netWorthSpecific}
                  sourceOfWealth={sourceOfWealth}
                />
              )}
              {
                step === 6 &&
                <EmploymentStatus
                  handleChange={this.handleChange}
                  employmentType={employmentType}
                  employer={employer}
                  employmentPositionHeld={employmentPositionHeld}
                  employmentIndustry={employmentIndustry}
                  sameWorkAddress={sameWorkAddress}
                  employerAddress={employerAddress}
                  sourceOfIncome={sourceOfIncome}
                />
              }
            </ExtendedFlex>
            {
              enrollmentErrors &&
              <EnrollmentErrors
                // eslint-disable-next-line
                {...enrollmentErrors}
              />
            }
            <Actions>
              <PrimaryButton
                loading={submitting}
                disable={submitting}
                buttonType="submit"
              >
                Continue
              </PrimaryButton>
              <CancelButtonWithModal
                text={cancelText}
                header={cancelHeader}
                handleCancel={handleCancel}
                body={cancelBody}
              />
            </Actions>
          </Form>
        </ExtendedFlex>
      </AutoInvestContentWrapper>
    );
  }
}

ISQ.propTypes = {
  showStepCounter: PropTypes.bool,
  handleSubmit: PropTypes.func,
  timeHorizon: PropTypes.number,
  latestIsq: PropTypes.shape({
    primaryInvestmentReason: PrimaryReason.propTypes.value,
    expStocks: Experience.propTypes.expStocks,
    expBonds: Experience.propTypes.expBonds,
    expRealEstate: Experience.propTypes.expRealEstate,
    expPrivateEquity: Experience.propTypes.expPrivateEquity,
    expVentureCapital: Experience.propTypes.expVentureCapital,
    expSmallBiz: Experience.propTypes.expSmallBiz,
    exit: Exit.propTypes.value,
    risk: Risk.propTypes.value,
    annualIncome: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    netWorth: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    netWorthSpecific: PropTypes.number,
    sourceOfWealth: IncomeNetWorth.propTypes.sourceOfWealth,
    employmentType: EmploymentStatus.propTypes.employmentType,
    employer: EmploymentStatus.propTypes.employer,
    employmentPositionHeld: EmploymentStatus.propTypes.employmentPositionHeld,
    employmentIndustry: EmploymentStatus.propTypes.employmentIndustry,
    sameWorkAddress: EmploymentStatus.propTypes.sameWorkAddress,
    employerAddress: EmploymentStatus.propTypes.employerAddress,
    sourceOfIncome: EmploymentStatus.propTypes.sourceOfIncome,
  }),
  handleCancel: PropTypes.func,
  cancelText: PropTypes.string,
  cancelHeader: PropTypes.string,
  cancelBody: CancelButtonWithModal.propTypes.body,
  enrollmentErrors: PropTypes.object,
};

ISQ.defaultProps = {
  showStepCounter: true,
  handleSubmit: noop,
  handleCancel: noop,
};

export default ISQ;
