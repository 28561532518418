import React, { useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Button } from 'rtmg-component-library';

import {
  Box,
  ExtendedBox,
  Heading,
  Link,
  ExtendedFlex,
  Formatters,
} from '../../coreui';

import DashboardSection from './DashboardSection';
import IOCard from './IOCard';
import SummaryItem from './SummaryItem';

const PrivatePlacements = ({ data }) => {
  const [investmentsShown, setInvestmentsShown] = useState(25);
  const privatePlacementData = data.activeInvestmentsData.privatePlacementData;
  const totalInvested = numeral(privatePlacementData.summary.items.totalInvested.value)
    .format(Formatters.DOLLARS_DECIMALS);
  const totalDistributions = numeral(privatePlacementData.summary.items.totalDistributions.value)
    .format(Formatters.DOLLARS_DECIMALS);

  return privatePlacementData === {} ? '' : (
    <ExtendedFlex
      flexDirection="column"
      width={1}
      alignItems="center"
    >
      <DashboardSection
        title="PRIVATE PLACEMENTS"
        contentWidth="100%"
      >
        <ExtendedFlex
          flexDirection="column"
          alignItems="flex-start"
          w={1}
        >
          <ExtendedFlex
            flexDirection={['column', 'column', 'column', 'row', 'row']}
            justifyContent="center"
            alignItems="center"
            w={1}
            py={48}
          >
            {
              totalInvested !== undefined &&
              <SummaryItem
                value={totalInvested}
                label="Total Invested"
                valueFormat={Formatters.DOLLARS_DECIMALS}
              />
            }
            <Box m={8} mx={[0, 0, 0, 16, 24]} />
            {
              totalDistributions !== undefined &&
              <SummaryItem
                value={totalDistributions}
                label="Total Distributed"
                valueFormat={Formatters.DOLLARS_DECIMALS}
              />
            }
          </ExtendedFlex>
        </ExtendedFlex>
        <ExtendedFlex
          flexDirection="column"
          w={1}
          alignItems="left"
          mb={36}
        >
          {
            (privatePlacementData.ios.length === 0) &&
            <ExtendedFlex
              w={1}
              my={24}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Heading type="H4" lineHeight={1.5} mb={24}>You currently have no private placement investments.</Heading>
              <Link
                type="L1"
                href="https://www.realtymogul.com/investment-opportunities"
              >
                Browse Open Investments
              </Link>
            </ExtendedFlex>
          }
          {
            (privatePlacementData.ios.length !== 0) &&
            <ExtendedBox>
              {privatePlacementData?.ios.slice(0, investmentsShown).map((io) => (
                <IOCard data={{ ...io, portfolioAllocation: io.totalInvestmentAmount / data.activeInvestmentsData.totalInvested.value }} />
              ))}
              {
                (investmentsShown < privatePlacementData.ios.length) &&
                <div style={{ maxWidth: '300px', margin: '36px auto 0' }}>
                  <Button onClick={() => setInvestmentsShown(investmentsShown + 25)}>
                    Show More
                  </Button>
                </div>
              }
            </ExtendedBox>
          }
        </ExtendedFlex>
      </DashboardSection>
    </ExtendedFlex>
  );
};

PrivatePlacements.propTypes = {
  data: PropTypes.object,
};

export default PrivatePlacements;
