/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';

import {
  VisibilitySensor,
} from '../coreui';
import FadeInChildren from './FadeInChildren';

class FadeIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible: true,
      });
    }
  }

  render() {
    const isVisible = this.state.isVisible;

    return (
      <>
        <VisibilitySensor
          callback={this.handleSensorChange}
        />
        <FadeInChildren isVisible={isVisible} {...this.props} />
        <VisibilitySensor
          callback={this.handleSensorChange}
        />
      </>
    );
  }
}

export default FadeIn;
