import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  Box,
  Flex,
} from '../../..';

class SelectFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.props.updateSelectedFilterValue(
      value,
      this.props.id,
    );
  }

  render() {
    const {
      filterItems,
      selectedFilterValue,
    } = this.props;

    return (
      <Flex
        justify="center"
        align="center"
      >
        <Box
          width={275}
        >
          <Select
            clearable={false}
            searchable={false}
            simpleValue
            options={filterItems.map((label) => {
              if (!label.count) {
                return label;
              }
              return {
                ...label,
                label: `${label.label} (${label.count})`,
              };
            })}
            onChange={this.handleChange}
            value={selectedFilterValue}
          />
        </Box>
      </Flex>
    );
  }
}

SelectFilter.propTypes = {
  id: PropTypes.string,
  filterItems: PropTypes.arrayOf(PropTypes.object),
  selectedFilterValue: PropTypes.string,
  updateSelectedFilterValue: PropTypes.func,
};

export default SelectFilter;
