import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  fontSize,
  width,
  space,
  color,
  textAlign,
  lineHeight,
  fontWeight,
  letterSpacing,
  maxWidth,
  minHeight,
  maxHeight,
  zIndex,
  display,
  propTypes,
  style,
  // flexbox
  alignItems,
  alignContent,
  justifyContent,
  flexWrap,
  flexDirection,
  flex,
  flexBasis,
  justifySelf,
  alignSelf,
  order,
} from 'styled-system';
import CleanElement from 'clean-element';

const CleanLabel = CleanElement('label');

CleanLabel.propTypes = {
  ...propTypes.lineHeight,
  ...propTypes.maxWidth,
  textTransform: PropTypes.string,
};

const StyledLabel = styled(CleanLabel)`
  ${fontSize}
  ${width}
  ${maxWidth}
  ${space}
  ${color}
  ${textAlign}
  ${lineHeight}
  ${fontWeight}
  ${letterSpacing}
  ${minHeight}
  ${maxHeight}
  ${zIndex}
  ${alignItems}
  ${alignContent}
  ${justifyContent}
  ${flexWrap}
  ${flexDirection}
  ${flex}
  ${flexBasis}
  ${justifySelf}
  ${alignSelf}
  ${order}
  ${(props) => (style({
    prop: 'textIndent',
    cssProperty: 'textIndent',
    numberToPx: true,
  })(props))};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ${(props) => props.theme.fontFamily[props.fontFamily] || 'Helvetica, Arial, sans-serif'};
  ${(props) => (props.textTransform ? { textTransform: props.textTransform } : '')};
  font-style: ${(props) => props.fontStyle || 'normal'};
  white-space: ${(props) => props.whiteSpace || 'normal'};
  text-shadow: ${(props) => props.textShadow || 'inherit'};
  ${(props) => (props.display ? display(props) : { display: 'inline-block' })};
  opacity: ${(props) => props.opacity || '1'};
  box-sizing: border-box;
  transition: ${(props) => props.transition || 'none'};
  visibility: ${(props) => props.visibility || 'visible'};
`;

export default StyledLabel;
