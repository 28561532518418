import React from 'react';
import PropTypes from 'prop-types';

import { Heading, Body } from '../../coreui';
import CheckboxLabel from '../../components/forms/CheckboxLabel';
import CheckboxGroup from '../../components/forms/CheckboxGroup';

const RiskTolerance = ({
  value,
  handleChange,
  isValid,
}) => {
  return (
    <>
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        Your Risk Tolerances
      </Heading>
      <Body
        type="B2"
      >
        Select the hold periods you are comfortable with.
      </Body>
      <CheckboxGroup
        name="risk"
        value={value}
        onChange={handleChange}
        inputProps={{
          'data-test': 'risk',
        }}
        required
        isValid={isValid}
      >
        <CheckboxLabel
          value="high_risk_return"
          label="High Risk, High Return"
        />
        <CheckboxLabel
          value="moderate_risk_return"
          label="Moderate Risk, Moderate Return"
        />
        <CheckboxLabel
          value="low_risk_return"
          label="Low Risk, Low Return"
        />
      </CheckboxGroup>
    </>
  );
};

RiskTolerance.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.oneOf([
    'high_risk_return',
    'moderate_risk_return',
    'low_risk_return',
  ])),
  isValid: PropTypes.bool,
};

export default RiskTolerance;
