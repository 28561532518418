/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  themeGet,
  space,
  width,
  height,
} from 'styled-system';
import MUIRadio from '@material-ui/core/Radio';

const defaultProps = {
  w: [16, null, 28],
  height: [16, null, 28],
  ml: [10, null, 30],
  mr: [15, null, 25],
};

const smallProps = {
  w: 16,
  height: 16,
  ml: 0,
  mr: 10,
};

export const StyledRadioIcon = styled.i`
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => width(props.isSmall ? smallProps : defaultProps)}
  ${(props) => height(props.isSmall ? smallProps : defaultProps)}
  ${(props) => space(props.isSmall ? smallProps : defaultProps)}


  ${(props) => ({
    border: `1px ${themeGet('colors.gray.10')(props)} solid`,
  })}
  background: #FFFFFF;
  color: #FFFFFF;
  border-radius: 50%;
  flex: none;
  position: relative;

  &::after {
    content: '';
    ${(props) => width({ w: props.isSmall ? 10 : [10, null, 18] })}
    ${(props) => height({ height: props.isSmall ? 10 : [10, null, 18] })}
    border-radius: 50%;
    background: transparent;
  }

  ${(props) => (props.checked || props.hovering ? {
    '&::after': {
      background: props.checked
        ? themeGet('colors.blue.0')(props)
        : themeGet('colors.gray.10')(props),
      transition: `background ${themeGet('transition')(props)}ms ease`,
    },
  } : {})}
`;

export const StyledRadio = styled(MUIRadio)`
  && {
    padding: 0;
  }
`;

const Radio = ({
  hovering,
  isSmall,
  ...props
}) => {
  return (
    <StyledRadio
      icon={<StyledRadioIcon hovering={hovering} isSmall={isSmall} />}
      checkedIcon={<StyledRadioIcon checked isSmall={isSmall} />}
      disableRipple
      {...props}
    />
  );
};

Radio.propTypes = {
  hovering: PropTypes.bool,
  isSmall: PropTypes.bool,
};

export default Radio;
