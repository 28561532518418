import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Hero,
  UnlockOpportunities,
  Link,
  Numeral,
  ScrollToTopOnMount,
  ExtendedFlex,
  Formatters,
} from '../coreui';

import ScrollToHash from '../components/ScrollToHash';
import OurStorySection from './components/OurStory';
import ExclusiveAccess from './components/ExclusiveAccess';
import IconCallouts from '../components/IconCallouts';
import Access from './components/Access';
import Leadership from './components/Leadership';
import PressMentions from '../homepage/pressMentions';
import LocationSlide from './components/LocationSlide';

import Section from '../ioDetails/components/section';
import heroBannerImage from '../assets/OS_hero.jpg';
import heroCareers from '../assets/OS_careers.jpg';
import Highlighted3ColCallout from '../components/Highlighted3ColCallout';
import BrowseLink from '../components/BrowseLink';

import actions from './actions';

const OurStory = ({
  isLoggedIn,
  apartmentUnitsFinanced,
  totalPropertyValueFinanced,
  totalMembers,
  locations,
  ourStory,
  exclusiveAccess,
  wellManaged,
  access,
  leadership,
  trackRecord,
  getLeadershipList,
}) => {
  useEffect(() => { getLeadershipList(); }, [getLeadershipList]);

  const CalloutHighlights = [
    {
      highlight: (totalPropertyValueFinanced) ?
        <Numeral value={totalPropertyValueFinanced} format={`${Formatters.SHORTENED_DOLLARS_POINTS}+`} /> :
        null,
      description: trackRecord.highlights[0].description,
    },
    {
      highlight: (totalMembers) ? <Numeral value={totalMembers} format="0a+" /> : null,
      description: trackRecord.highlights[1].description,
    },
    {
      highlight: (apartmentUnitsFinanced) ? <Numeral value={apartmentUnitsFinanced} format={`${Formatters.INTEGER}+`} /> : null,
      description: trackRecord.highlights[2].description,
    },
  ];
  return (
    <>
      <ScrollToTopOnMount />
      <Hero
        image={heroBannerImage}
        header="Building RealtyMogul"
        subHeader="The pursuit of building wealth"
        bgPosition="74%"
      />
      <OurStorySection
        // eslint-disable-next-line
        {...ourStory}
      />
      <ExclusiveAccess
        // eslint-disable-next-line
        {...exclusiveAccess}
      />
      <IconCallouts
        bg="gray.1"
        // eslint-disable-next-line
        {...wellManaged}
      />
      <Access
        // eslint-disable-next-line
        {...access}
      />
      <Section
        bg="gray.0"
        title={null}
        name="track-record"
      >
        <Highlighted3ColCallout
          title={trackRecord.title}
          subTitle={trackRecord.subTitle}
          highlights={CalloutHighlights}
          cta={<BrowseLink isLoggedIn={isLoggedIn} />}
        />
      </Section>
      <ScrollToHash
        name="leadershipteam"
      >
        <Leadership
          // eslint-disable-next-line
          {...leadership}
        />
      </ScrollToHash>
      <Hero
        image={heroCareers}
        header="Join a world class team"
        bgPosition="26%"
      >
        <Link
          type="L2"
          href="/careers"
        >
          Careers
        </Link>
      </Hero>
      <ExtendedFlex
        bg="gray.1"
      >
        {
          locations.map((location) => {
            return (
              <LocationSlide
                key={location.uuid}
                // eslint-disable-next-line
                {...location}
              />
            );
          })
        }
      </ExtendedFlex>
      <PressMentions />
      <UnlockOpportunities
        isLoggedIn={isLoggedIn}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.ourStory.user.isLoggedIn,
  totalMembers: state.ourStory.totalMembers,
  totalPropertyValueFinanced: state.ourStory.totalPropertyValueFinanced,
  apartmentUnitsFinanced: state.tokens.apartmentUnitCount,
  locations: state.ourStory.locations,
  ourStory: state.ourStory.ourStory,
  exclusiveAccess: state.ourStory.exclusiveAccess,
  wellManaged: state.ourStory.wellManaged,
  access: state.ourStory.access,
  leadership: state.ourStory.leadership,
  trackRecord: state.ourStory.trackRecord,
});

const mapDispatchToProps = (dispatch) => ({
  getLeadershipList: () => {
    dispatch(actions.getLeadershipList());
  },
});

OurStory.propTypes = {
  isLoggedIn: PropTypes.bool,
  totalMembers: PropTypes.number,
  totalPropertyValueFinanced: PropTypes.number,
  apartmentUnitsFinanced: PropTypes.number,
  locations: PropTypes.arrayOf(PropTypes.shape(LocationSlide.propTypes)),
  ourStory: PropTypes.shape(OurStorySection.propTypes),
  exclusiveAccess: PropTypes.shape(ExclusiveAccess.propTypes),
  wellManaged: PropTypes.shape(IconCallouts.propTypes),
  access: PropTypes.shape(Access.propTypes),
  leadership: PropTypes.shape(Leadership.propTypes),
  trackRecord: PropTypes.object,
  getLeadershipList: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(OurStory);
