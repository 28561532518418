import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  TextLink,
  SocialMediaIcon,
} from '../../coreui';

const FooterSection2 = ({
  items,
}) => {
  return (
    <Flex
      w={[1, 1, 0.2]}
      flexDirection={['row', 'row', 'column']}
      justify={['space-between', 'space-between', 'center']}
      align={['center', 'center', 'flex-end']}
    >
      <Flex
        mb={[0, 0, 40]}
      >
        {
          items.map(
            (item) => {
              return (
                <TextLink
                  key={item.id}
                  href={item.url}
                  target="_blank"
                  m={0}
                >
                  <SocialMediaIcon icon={item.machineName} />
                </TextLink>
              );
            },
          )
        }
      </Flex>
    </Flex>
  );
};

FooterSection2.propTypes = {
  items: PropTypes.array,
};

export default FooterSection2;
