import React from 'react';
import PropTypes from 'prop-types';
import { InvestmentTile } from 'rtmg-component-library';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
  InlineTextLink,
  CarouselRecentInvestments,
  VisibilitySensor,
} from '../../coreui';

import FadeIn from '../../components/FadeIn';
import BrowseLink from '../../components/BrowseLink';

class RecentInvestments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };

    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible,
      });
    }
  }

  render() {
    const { isVisible } = this.state;

    const {
      recentInvestmentsData = [],
      title = 'Fully realized returns',
      subtitle,
      smartBtn = false,
      bgColor = 'gray.0',
      isLoggedIn = false,
    } = this.props;

    return (
      <ExtendedBox
        bg={bgColor}
        py={[100, 100, 100, 160, 220]}
      >
        <ExtendedFlex
          flexDirection="column"
          align="Center"
          mb={[20, 30, 40, 60, 80]}
        >
          <VisibilitySensor
            callback={this.handleSensorChange}
          />
          <FadeIn>
            <Heading
              type="H9"
              m={0}
              display="block"
              textAlign="center"
              opacity={isVisible ? '1' : '0'}
            >
              {title}
            </Heading>
            <Heading
              type="H8"
              display="block"
              textAlign="center"
              mx={[30, 20, 0, 0, 0]}
              opacity={isVisible ? '1' : '0'}
            >
              {subtitle}
            </Heading>
          </FadeIn>
        </ExtendedFlex>
        <ExtendedFlex
          flexDirection="column"
          align="Center"
          maxWidth="100%"
          mb={80}
        >
          <Body
            type="B2"
            m={0}
            display="block"
            textAlign="center"
            width={[0.75, 500, 500, 500, 620]}
          >
            Below are the most recent completed investments from which final distributions have been processed. Investments on RealtyMogul are illiquid and loss of invested capital is possible.
            Please review risk factors for private placements{' '}
            <InlineTextLink
              href="https://www.realtymogul.com/materials/riskswheninvestinginprivateplacements.pdf"
              target="_blank"
            >
              here
            </InlineTextLink>
            .
          </Body>
          <VisibilitySensor
            callback={this.handleSensorChange}
          />
        </ExtendedFlex>
        <ExtendedBox>
          <CarouselRecentInvestments>
            {
              recentInvestmentsData.map(
                (data) => {
                  return (
                    <div>
                      <div style={{ width: '320px', margin: '0 20px' }}>
                        <InvestmentTile
                          key={data.title}
                          type="past"
                          {...data}
                        />
                      </div>
                    </div>
                  );
                },
              )
            }
          </CarouselRecentInvestments>
        </ExtendedBox>
        {smartBtn &&
          <ExtendedBox
            pl={12}
          >
            <BrowseLink
              isLoggedIn={isLoggedIn}
            />
          </ExtendedBox>}
      </ExtendedBox>
    );
  }
}

RecentInvestments.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  smartBtn: PropTypes.bool,
  bgColor: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  recentInvestmentsData: PropTypes.array,
};

export default RecentInvestments;
