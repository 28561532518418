export default {
  MRF: {
    heading: 'Income REIT FINANCING',
    body: 'The Income REIT’s objectives are to pay attractive and consistent cash distributions and to preserve, protect, increase, and return stockholders’ capital contributions. As such, the Income REIT offers Preferred Equity, Joint Venture Equity and Co-General Partner equity with a focus on stabilized transactions that can meet our cash on cash targets.',
  },
  MRIIF: {
    heading: 'Apartment Growth REIT FINANCING',
    body: 'The REIT\'s objectives are to realize capital appreciation in the value of our investments over the long term and to pay attractive and stable cash distributions to stockholders. The REIT offers real estate companies investment capital for their multifamily projects, with Preferred Equity, Joint Venture Equity and Co-General Partner Equity. RealtyMogul looks to partner with multifamily experts in their local market who have access to the transactions and need capital to grow.',
  },
  PPF: {
    heading: 'PRIVATE PLACEMENT FINANCING',
    body: 'The RealtyMogul Private Placement platform offers real estate companies access to small balance capital for value-add transactions across the country. With either Joint Venture Equity or Co-General Partner Equity, RealtyMogul looks to partner with commercial real estate professionals on their value-add transactions.',
  },
  mogulreitI: {
    header: 'Income REIT TYPICAL FINANCING',
    subheader: 'FOR REAL ESTATE COMPANIES ONLY',
    data: [
      {
        heading: 'PREFERRED EQUITY',
        range: '$3MM - $10MM',
        leverage: 'Leverage',
        value1: 'Up to 85% of Total Project Cost',
        pricing: 'Underwritten To',
        value2: '10% - 12%',
        term: 'Term',
        value3: 'Match Term with Senior Lender',
        timetoclose: 'Time To Close',
        value4: '30 - 45 Days',
        link: '/sponsor-application',
      },
      {
        heading: 'JOINT VENTURE EQUITY',
        range: '$3MM - $10MM',
        leverage: 'Leverage',
        value1: 'Up to 90% of Equity',
        pricing: 'Underwritten To',
        value2: '15% +',
        term: 'Term',
        cashoncash: 'Required Cash on Cash',
        value5: 'Potential 8% + average over hold period, no less than 6% in year 1',
        value3: '2 - 10 Years',
        timetoclose: 'Time To Close',
        value4: '30 - 45 Days',
        link: '/sponsor-application',
      },
      {
        heading: 'CO-GENERAL PARTNER EQUITY',
        range: '$3MM - $10MM',
        leverage: 'Leverage',
        value1: 'Up to 98% of Equity',
        pricing: 'Underwritten To',
        value2: '15% +',
        cashoncash: 'Required Cash on Cash',
        value5: 'Potential 8% + average over hold period, no less than 6% in year 1',
        term: 'Term',
        value3: '2 - 10 Years',
        timetoclose: 'Time To Close',
        value4: '30 - 45 Days',
        link: '/sponsor-application',
      },
    ],
  },
  mogulreitII: {
    header: 'Apartment Growth REIT TYPICAL FINANCING',
    subheader: 'FOR REAL ESTATE COMPANIES ONLY',
    data: [
      {
        heading: 'MULTIFAMILY PREFERRED EQUITY',
        range: '$3MM - $10MM',
        leverage: 'Leverage',
        value1: 'Combined with Senior, Up to 85% of Total Project Cost',
        pricing: 'Underwritten To',
        value2: '10% - 12%',
        term: 'Term',
        value3: 'Match Term with Senior Lender',
        timetoclose: 'Time To Close',
        value4: '30 - 45 Days',
        link: '/sponsor-application',
      },
      {
        heading: 'MULTIFAMILY JV EQUITY',
        range: '$3MM - $10MM',
        leverage: 'Leverage',
        value1: 'Up to 90% of Equity',
        pricing: 'Underwritten To',
        value2: '15% +',
        term: 'Term',
        cashoncash: 'Required Cash on Cash',
        value5: 'Potential 8% + average over hold period, no less than 6% in year 1',
        value3: '2 - 10 Years',
        timetoclose: 'Time To Close',
        value4: '30 - 45 Days',
        link: '/sponsor-application',
      },
      {
        heading: 'CO-GENERAL PARTNER EQUITY',
        range: '$3MM - $10MM',
        leverage: 'Leverage',
        value1: 'Up to 98% of Equity',
        pricing: 'Underwritten To',
        value2: '15% +',
        cashoncash: 'Required Cash on Cash',
        value5: 'Potential 8% + average over hold period, no less than 6% in year 1',
        term: 'Term',
        value3: '2 - 10 Years',
        timetoclose: 'Time To Close',
        value4: '30 - 45 Days',
        link: '/sponsor-application',
      },
    ],
  },
  privatePlacement: {
    header: 'PRIVATE PLACEMENT FINANCING OPTIONS',
    subheader: 'FOR REAL ESTATE COMPANIES ONLY',
    data: [
      {
        heading: 'JOINT VENTURE EQUITY',
        range: '$3MM - $10MM',
        leverage: 'Leverage',
        value1: 'Up to 90% of Equity',
        pricing: 'Underwritten To',
        value2: '15%+',
        cashoncash: 'Required Cash on Cash',
        value5: '6% + average over hold period',
        term: 'Term',
        value3: '2 - 10 Years',
        timetoclose: 'Time To Close',
        value4: '30 - 45 Days',
        link: '/sponsor-application',
      },
      {
        heading: 'CO-GENERAL PARTNER EQUITY',
        range: '$3MM - $10MM',
        leverage: 'Leverage',
        value1: 'Up to 98% of Equity',
        pricing: 'Underwritten To',
        value2: '15%+',
        cashoncash: 'Required Cash on Cash',
        value5: '6% + average over hold period',
        term: 'Term',
        value3: '2 - 10 Years',
        timetoclose: 'Time To Close',
        value4: '30 - 45 Days',
        link: '/sponsor-application',
      },
    ],
  },
};
