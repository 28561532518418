import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ExtendedBox,
  ExtendedFlex,
  Data,
  Switch,
} from '../../../coreui';

import actions from '../../actions';

class WatchListToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlagged: this.props.isFlagged,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    const url = (checked) ? this.props.flagURL : this.props.unflagURL;
    this.props.watchToggled({ isFlagged: checked });
    this.props.watchToggleRequested({ id: this.props.id, callback: url });
  }

  render() {
    if (!this.props.isLoggedIn) {
      return null;
    }
    return (
      <ExtendedFlex
        flexDirection="row"
        justifyContent="center"
        my="30px"
        mx="auto"
        maxWidth="260px"
      >
        <Data
          type="D3"
          lineHeight="30px"
          my={0}
        >
          Watch Investment?
        </Data>
        <ExtendedBox
          ml={20}
        >
          <Switch
            label="Add To Watchlist"
            onChange={this.handleChange}
            checked={this.state.isFlagged}
            on="Yes"
            off="No"
          />
        </ExtendedBox>
      </ExtendedFlex>
    );
  }
}

WatchListToggle.propTypes = {
  isFlagged: PropTypes.bool,
  watchToggleRequested: PropTypes.func,
  watchToggled: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  id: PropTypes.number,
  flagURL: PropTypes.string,
  unflagURL: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isFlagged: state.watchList.isFlagged,
  isLoggedIn: state.app.user.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => {
  return {
    watchToggleRequested: (opts) => {
      dispatch(actions.watchListToggleApiActionRequested(opts));
    },
    watchToggled: (opts) => {
      dispatch(actions.watchListToggled(opts));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchListToggle);
