import types from './types';
import autoInvest from './autoinvest/reducer';
import taxcenter from './taxcenter/reducer';
import messages from './messages/reducer';
import dashboardTransactions from './transactions/reducer';
import {
  deserializeInvestment,
  deserializePendingInvestment,
  dripToggleProcess,
} from './deserializers/investments';
import authTypes from '../auth/types';

const initialState = {
  foeId: null,
  ownershipFilter: null,
  ownershipOptions: [],
  data: {
    loaded: false,
    activeInvestmentsData: {},
    completedInvestmentsData: {},
    watchListData: {
      items: [],
    },
    waitListData: {
      items: [],
    },
  },
  pendingData: {
    investments: [],
    pendingData: {
      investments: null,
    },
    pledgedData: {
      investments: null,
    },
  },
  modalContent: null,
  modalVisible: false,
  cancelModalRedirect: null,
  notifications: { data: [] },
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case types.OWNERSHIP_API_SUCCEEDED:
      return {
        ...state,
        ownershipOptions: action.data.map((item) => ({
          ...item,
          id: parseInt(item.id, 10),
        })),
      };

    case types.OWNERSHIP_FILTER_SET:
      return {
        ...state,
        ownershipFilter: action.foeObject,
      };

    case types.DASHBOARD_API_REQUESTED:
      return {
        ...state,
        foeId: action.foeId,
        data: {
          ...state.data,
          ...(action.foeId !== state.foeId ? {
            loaded: false,
          } : {}),
        },
      };

    case types.DASHBOARD_API_SUCCEEDED:
      return {
        ...state,
        data: {
          ...deserializeInvestment(action.data),
          loaded: true,
        },
      };

    case types.CANCEL_INVESTMENT_API_SUCCEEDED: {
      const investments = state.pendingData.investments.reduce((accum, item) => {
        if (item.id === action.id) {
          return [...accum];
        }
        return [
          ...accum,
          { ...item },
        ];
      }, []);
      return {
        ...state,
        pendingData: {
          ...state.pendingData,
          ...deserializePendingInvestment(investments),
        },
      };
    }

    case types.PENDING_DASHBOARD_API_SUCCEEDED:
      return {
        ...state,
        pendingData: {
          ...deserializePendingInvestment(action.data.data),
        },
      };

    case types.PENDING_DASHBOARD_WAITLIST_FLAG_REQUESTED:
      return {
        ...state,
        pendingData: {
          ...state.pendingData,
          pendingData: {
            ...state.pendingData.pendingData,
            investments: state.pendingData.pendingData.investments.map((item) => ({
              ...item,
              ...(item.id === action.id ? {
                waitListFlagLoading: true,
              } : {}),
            })),
          },
        },
        cancelModalRedirect: null,
      };

    case types.PENDING_DASHBOARD_WAITLIST_FLAG_SUCCEEDED: {
      let waitListedIo;

      return {
        ...state,
        // Update for when it was flagged.
        ...(action.flagged ? {
          modalContent: 'waitlist',
          modalVisible: true,
          pendingData: {
            ...state.pendingData,
            pendingData: {
              ...state.pendingData.pendingData,
              investments: state.pendingData.pendingData.investments.reduce((accum, item) => {
                if (item.id === action.id) {
                  waitListedIo = item;
                  return [
                    ...accum,
                    {
                      ...item,
                      fundedWaitListFlag: true,
                    },
                  ];
                }
                return [
                  ...accum,
                  { ...item },
                ];
              }, []),
            },
          },
          data: {
            ...state.data,
            waitListData: {
              ...state.data.waitListData,
              investments: [
                ...state.data.waitListData.investments,
                {
                  ...(waitListedIo !== undefined ? {
                    ...waitListedIo,
                    id: waitListedIo.ioId,
                    cancel: waitListedIo.fundedWaitListUnflag,
                    investmentStage: 'waitlist',
                  } : {}),
                },
              ],
            },
          },
        } : {
          data: {
            ...state.data,
            waitListData: {
              ...state.data.waitListData,
              // Remove the unflagged investment from the waitlist.
              investments: state.data.waitListData.investments.reduce((accum, item) => {
                if (item.id === action.id) {
                  return [...accum];
                }
                return [
                  ...accum,
                  { ...item },
                ];
              }, []),
            },
          },
        }),
      };
    }

    case types.PENDING_DASHBOARD_MODAL_CLOSE:
      return {
        ...state,
        modalContent: null,
        modalVisible: false,
      };

    case types.NOTIFICATION_API_SUCCEEDED:
      return {
        ...state,
        notifications: action.data,
      };

    case types.DRIP_TOGGLE_REQUESTED:
      return dripToggleProcess(action, state);

    case types.DRIP_TOGGLE_SUCCEEDED:
      return dripToggleProcess(action.data, state, {
        dripToggleDisabled: null,
        receiveCashDistributionsURL: action.data.receiveCashDistributionsURL,
        reinvestDistributionsURL: action.data.reinvestDistributionsURL,
        dripSetting: action.data.dripStatus,
      });

    case authTypes.USER_LOGOUT_REQUESTED: {
      return {
        ...state,
        ...initialState,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default {
  dashboard,
  autoInvest,
  taxcenter,
  messages,
  dashboardTransactions,
};
