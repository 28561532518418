import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { Heading } from '../../coreui';
import CheckboxGroup from '../forms/CheckboxGroup';
import CheckboxLabel from '../forms/CheckboxLabel';
import FollowUp from './FollowUp';

class Risk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dirty: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({
      dirty: true,
    });
    return this.props.handleChange(e);
  }

  render() {
    return (
      <>
        <Heading
          type="H6"
          textTransform="uppercase"
        >
          Please indicate what investments you would be comfortable with.
          Select all that apply.
        </Heading>
        <CheckboxGroup
          name="risk"
          value={this.props.value}
          onChange={this.handleChange}
          inputProps={{
            'data-test': 'risk',
          }}
          required
          isValid={this.props.isValid}
        >
          <CheckboxLabel
            value="high"
            label="A high risk investment for a high potential return"
          />
          <CheckboxLabel
            value="moderate"
            label="A moderate risk investment for a moderate potential return"
          />
          <CheckboxLabel
            value="low"
            label="A low risk investment for a low potential return"
          />
        </CheckboxGroup>
        {
          isEqual(this.props.value, ['low']) && this.state.dirty &&
          <FollowUp
            subheader="You have indicated that you have a preference for &quot;Low risk, low return&quot;. The investment you have selected has at least a moderate level of risk and there is a chance that your investment may lose value or suffer principal loss."
            name="riskFollowUp"
            value={this.props.followUp}
            handleChange={this.props.handleChange}
            options={[
              {
                value: 'comfortable',
                label: 'I am comfortable with the risks inherent in this investment and wish to proceed.',
              },
              {
                value: 'not_comfortable',
                label: 'I am not comfortable with anything other than low risk investments.',
              },
            ]}
          />
        }
      </>
    );
  }
}

Risk.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.oneOf([
    'high',
    'moderate',
    'low',
  ])),
  followUp: PropTypes.string,
  isValid: PropTypes.bool,
};

export default Risk;
