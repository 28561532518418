import types from './types';

const initialState = {
  topics: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FAQ_DATA_SUCCEEDED:
      return {
        topics: action.data.data.map((faq) => ({
          ...faq,
          // eslint-disable-next-line camelcase
          machine_name: faq.machine_name === 'taxes_1099_div'
            ? 'taxes' : faq.machine_name,
        })),
      };

    default:
      return {
        ...state,
      };
  }
};
