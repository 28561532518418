import {
  takeLatest,
  call,
} from 'redux-saga/effects';

import authTypes from '../../auth/types';

export function* getUserDataFlow(action) {
  const {
    type,
    data: {
      id,
    },
  } = action;

  yield call(() => {
    const _dataLayer = window.dataLayer || [];
    _dataLayer.push({ event: type, accountId: id });
  });
}

export function* watchUserRegister() {
  yield takeLatest(
    authTypes.USER_REGISTER_SUCCEEDED,
    getUserDataFlow,
  );
}

export default watchUserRegister;
