import { takeLatest, call, put, all } from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';
import { postShareRepurchaseRequest } from '../../api';

export function* postDataFlow(action) {
  const { data, promise: { resolve, reject } = {} } = action;
  try {
    const response = yield call(postShareRepurchaseRequest, data);
    yield all([
      call(resolve, { data: response.data }),
      put(
        actions.shareRepurchasePostSucceeded(
          response.data,
        ),
      ),
    ]);
  } catch (error) {
    yield all([
      call(reject, error),
      put(
        actions.shareRepurchasePostFailed(
          error,
        ),
      ),
    ]);
  }
}

export function* watchShareRepurchaseListRequested() {
  yield takeLatest(types.SHARE_REPURCHASE_POST_API_REQUESTED, postDataFlow);
}

export default watchShareRepurchaseListRequested;
