/* eslint-disable */
import React from 'react';
import ReactError from './components/ReactError';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: String(error) };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, {error, errorInfo});
  }

  render() {
    if (this.state.hasError) {
      return <ReactError error={this.state.error} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
