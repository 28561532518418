import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'qs';
import { withCookies } from 'react-cookie';

import actions from '../actions';

import {
  isDrupal,
  isDrupalPath,
} from '../../coreui';

class LoginHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.getDestination = this.getDestination.bind(this);
    this.redirecting = false;
  }

  componentDidMount() {
    const {
      loginSuccess,
    } = this.props;

    const destination = this.getDestination();

    if (loginSuccess) {
      return;
    }
    this.props.userLoginRequested(destination);
  }

  getDestination() {
    const {
      is403,
      location: {
        pathname,
        search,
      },
    } = this.props;

    return (() => {
      if (is403) {
        return pathname;
      }
      const {
        destination,
      } = qs.parse(search, {
        ignoreQueryPrefix: true,
      });
      return destination;
    })();
  }

  render() {
    const {
      loginSuccess,
      loginRedirect,
      doRedirect,
      cookies,
      closeOverlayFunc = () => {},
    } = this.props;
    let redirect;
    if (loginSuccess && doRedirect && !this.redirecting) {
      cookies.set('returning-user', true, { path: '/', maxAge: 60 * 60 * 24 * 30 });
      closeOverlayFunc(true);
      if (isDrupal() || isDrupalPath(loginRedirect)) {
        window.location = loginRedirect;
        this.redirecting = true;
      } else {
        redirect = <Redirect to={loginRedirect} />;
      }
    }
    return (
      <>
        {redirect}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  loginSuccess: state.auth.loginSuccess,
  loginRedirect: state.auth.loginRedirect,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginRequested: (destination) => {
    dispatch(actions.userLoginRequested(destination));
  },
});

LoginHandler.propTypes = {
  location: PropTypes.object,
  userLoginRequested: PropTypes.func,
  loginSuccess: PropTypes.bool,
  loginRedirect: PropTypes.string,
  doRedirect: PropTypes.bool,
  is403: PropTypes.bool,
  cookies: PropTypes.object,
  closeOverlayFunc: PropTypes.func,
};

LoginHandler.defaultProps = {
  doRedirect: true,
};

export default withCookies(withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginHandler),
));
