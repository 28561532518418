import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
} from '../../coreui';

const TriPanelSection = ({
  children,
}) => (
  <ExtendedFlex
    flexDirection={['column', 'column', 'row', 'row', 'row']}
  >
    {children}
  </ExtendedFlex>
);

TriPanelSection.propTypes = {
  children: PropTypes.node,
};

export default TriPanelSection;
