import React from 'react';
import { ExtendedFlex } from '../../coreui';

const SecondContainer = (props) => {
  return (
    <ExtendedFlex
      flexDirection="column"
      textAlign="right"
      flex={1}
      display={['none', null, 'block']}
      // eslint-disable-next-line
      {...props}
    />
  );
};

export default SecondContainer;
