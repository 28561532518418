import glossaryTypes from './types';

const initialState = {
  sections: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case glossaryTypes.GET_GLOSSARY_SUCCEEDED: {
      const terms = action.data.data.map((term) => {
        return {
          id: term.id,
          content: term.definition,
          header: term.label,
        };
      });

      // Construct an array of all letters used in the Glossary
      const letters = [];
      terms.forEach((term) => {
        if (!letters.find((letter) => {
          return term.header[0] === letter;
        })) {
          letters.push(term.header[0]);
        }
      });

      const sects = [];
      letters.forEach((letter) => {
        const termsOfLetter = terms.filter((term) => {
          return term.header[0] === letter;
        });
        sects.push(
          { [letter]: termsOfLetter },
        );
      });

      return {
        ...state,
        sections: sects,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
