/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cleanElement from 'clean-element';

import {
  Data,
} from '../../coreui';

const CleanLi = cleanElement('li');

const StyledLi = styled(CleanLi)`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 40px;

  &::before {
    content: '\\2713';
    font-family: 'merlinIcons';
    font-weight: 700;
    font-size: .8em;
    margin-right: 5px;
  }
`;

const ListItem = ({
  children,
  ...props
}) => (
  <StyledLi {...props}>
    <Data
      type="D3"
      m={0}
    >
      {children}
    </Data>
  </StyledLi>
);

ListItem.propTypes = {
  children: PropTypes.object,
};

export default ListItem;
