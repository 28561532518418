/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Body,
  Image,
  Data,
} from '../coreui';
import FadeIn from './FadeIn';

const CarouselQuote = ({
  body,
  image,
  name,
  title,
  ...props
}) => {
  return (
    <ExtendedBox
      {...props}
    >
      <FadeIn>
        <ExtendedFlex
          align="center"
          justify="center"
          flexDirection="column"
        >
          {
            image &&
            <Image src={image} width={175} height="auto" />
          }
          {
            name &&
            <Data
              type="D9"
              textAlign="center"
              mx={['15%', '25%', 0, '15%', 0]}
              mt="3em"
              mb={0}
            >
              {name}
            </Data>
          }
          {
            title &&
            <Body
              type="B3"
              textAlign="center"
              mt={0}
              mx={['15%', '25%', 0, '15%', 0]}
            >
              {title}
            </Body>
          }
          <ExtendedBox
            maxWidth="100%"
          >
            <Body
              type="B4"
              display="block"
              textAlign="center"
              maxWidth={[340, 500, 600, 840, 1000]}
              mx={['15%', '25%', 0, '15%', 0]}
            >
              {body}
            </Body>
          </ExtendedBox>
        </ExtendedFlex>
      </FadeIn>
    </ExtendedBox>
  );
};

CarouselQuote.propTypes = {
  body: PropTypes.node.isRequired,
  image: PropTypes.string,
  name: PropTypes.node,
  title: PropTypes.node,
};

export default CarouselQuote;
