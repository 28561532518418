/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  ExtendedBox,
} from '../..';

import DrawerHeading from './components/DrawerHeading';

class Drawer extends React.Component {
  constructor(props) {
    super(props);
    const requestedLocation = window.location.hash.substr(1);
    const active = (requestedLocation === props.name);
    this.state = {
      isActive: active,
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  toggleDrawer() {
    this.setState((state) => ({
      isActive: !state.isActive,
    }));
  }

  render() {
    const {
      title,
      drawerColor,
      ...passedProps
    } = this.props;

    const {
      isActive,
    } = this.state;

    return (
      <ExtendedFlex
        flexDirection="column"
        justify="center"
        align="center"
        pt={20}
        m="auto"
        width="calc(100% - 40px)"
        {...passedProps}
      >
        <ExtendedFlex
          flexDirection="column"
          justify="center"
          w={1}
          align="center"
          mt={5}
          bg={drawerColor}
        >
          <ExtendedBox
            w={1}
            py={20}
          >
            <DrawerHeading
              content={title}
              isActive={isActive}
              handleClick={this.toggleDrawer}
              isUppercase
            />
          </ExtendedBox>
          <ExtendedBox
            maxHeight={isActive ? 10000 : 0}
            px={20}
            width="calc(100% - 40px)"
            overflowY="hidden"
            opacity={isActive ? '1' : '0'}
            transition={`opacity ${isActive ? '0.6s 0.1s' : '0s'} linear`}
            mb={isActive ? 40 : 0}
          >
            {this.props.children}
          </ExtendedBox>
        </ExtendedFlex>
      </ExtendedFlex>
    );
  }
}

Drawer.propTypes = {
  children: PropTypes.object,
  name: PropTypes.string,
  title: PropTypes.string,
  drawerColor: PropTypes.string,
};

Drawer.defaultProps = {
  drawerColor: 'white',
};

export default Drawer;
