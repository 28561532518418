import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import { getStatusMessages } from '../../api';

export function* getDSMFlow() {
  try {
    const response = yield call(getStatusMessages);
    yield put(
      actions.getDSMSucceeded(response.data),
    );
  } catch (error) {
    yield put(
      actions.getDSMFailed(error),
    );
  }
}

export function* watchGetDSMRequested() {
  yield takeLatest(
    types.GET_DSM_REQUESTED,
    getDSMFlow,
  );
}

export default watchGetDSMRequested;
