/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  generatePath,
} from 'react-router-dom';
import ReitContentItem from '../../components/ReitContentItem';

import {
  ExtendedFlex,
  Formatters,
  Heading,
  Link,
  Switch,
  Body,
  StyledButton,
} from '../../../coreui';
import {
  autoInvestEditUrl,
} from '../../links';
import CancelButtonWithModal from './CancelButtonWithModal';
import { canEditEnrollmentConfig, getEnrollmentRedirect } from '../utility';

const NOT_ENROLLED_LABEL = 'Not Enrolled';
const PENDING_LABEL = 'Pending';
const PAUSED_LABEL = 'Paused';
const ON_HOLD_LABEL = 'On Hold';
const NOT_APPLICABLE_LABEL = 'N/A';

const ON_HOLD_TOOLTIP = (
  <>
    Your Auto Investment enrollment can periodically be placed
    &quot;On Hold&quot; if an issue occurs that requires additional action from
    you to resolve.
  </>
);

const PENDING_TOOLTIP = (
  <>
    Your auto investment enrollment will remain &quot;pending&quot; until your enrollment
    request has been approved. This approval and first withdrawal typically
    occurs on or around the 10th of the month directly following your auto
    invest enrollment.
  </>
);

const getAmountProp = (status, prop) => {
  if (status === 'inactive') {
    return NOT_ENROLLED_LABEL;
  }
  if (status === 'pending') {
    return PENDING_LABEL;
  }
  return prop > 0 ? prop : NOT_APPLICABLE_LABEL;
};

const getDateProp = (status, prop) => {
  if (status === 'inactive') {
    return NOT_ENROLLED_LABEL;
  }
  if (status === 'pending') {
    return PENDING_LABEL;
  }
  return prop !== null
    ? moment(prop).format(Formatters.MMDDYY)
    : 'N/A';
};

const getTotalInvested = ({ status, totalAutoInvested: prop }) => {
  return getAmountProp(status, prop);
};

const getRequestedAmount = ({ status, requestedAmount: prop }) => {
  if (status === 'on_hold') {
    return ON_HOLD_LABEL;
  }
  if (status === 'paused') {
    return PAUSED_LABEL;
  }
  if (status === 'pending') {
    return getAmountProp(undefined, prop);
  }
  return getAmountProp(status, prop);
};

const getLastWithdrawalAmount = ({ status, lastWithdrawalAmount: prop }) => {
  return getAmountProp(status, prop);
};

const getLastWithdrawal = ({ status, lastWithdrawalDate: prop }) => {
  return getDateProp(status, prop);
};

const getNextWithdrawalDate = ({ status, nextWithdrawalDate: prop, io }) => {
  if (status === 'on_hold') {
    return ON_HOLD_LABEL;
  }
  if (status === 'paused') {
    return PAUSED_LABEL;
  }
  if (status === 'pending') {
    return getDateProp(undefined, prop);
  }
  if (io?.editingDisabled && !io.showNextWithdrawalOnDisabledIo) {
    return NOT_APPLICABLE_LABEL;
  }
  return getDateProp(status, prop);
};

const getNextWithdrawalTooltip = ({ id, status }) => {
  const tooltipId = `${id}.nextWithdrawalTooltip`;
  if (status === 'on_hold') {
    return {
      tooltipText: ON_HOLD_TOOLTIP,
      tooltipId,
    };
  }
  if (status === 'pending') {
    return {
      tooltipText: PENDING_TOOLTIP,
      tooltipId,
    };
  }
  return undefined;
};

const getBankAccount = ({
  status,
  bankAccount: {
    accountTypeLabel,
    lastFour,
    status: bankAccountStatus,
  },
}) => {
  if (status === 'inactive') {
    return NOT_ENROLLED_LABEL;
  }
  if (bankAccountStatus === 'on_hold') {
    return ON_HOLD_LABEL;
  }
  return `${accountTypeLabel} - ${lastFour}`;
};

class AutoInvestSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleSubmitting: false,
    };

    this.isUnmounted = false;
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const {
      id,
      enrollmentUrl,
      includeJwt,
      handleCancel,
      title,
      status,
      foe,
      io,
      handleActiveToggle,
    } = this.props;
    const {
      toggleSubmitting,
    } = this.state;

    const totalInvested = getTotalInvested(this.props);
    const requestedAmount = getRequestedAmount(this.props);
    const lastWithdrawalAmount = getLastWithdrawalAmount(this.props);
    const lastWithdrawal = getLastWithdrawal(this.props);
    const nextWithdrawalDate = getNextWithdrawalDate(this.props);
    const nextWithdrawalTooltip = getNextWithdrawalTooltip(this.props);
    const bankAccount = getBankAccount(this.props);

    return (
      <>
        <ExtendedFlex
          flexDirection="column"
          width={1}
          pt={[10, 20, 30]}
          pb={[30, 50, 80]}
          px={[0, '2em']}
        >
          <Heading
            type="H9"
          >
            {title}
          </Heading>
          <ExtendedFlex
            mb={50}
            w={[1, '46%']}
            justify="space-between"
            align="center"
            flexDirection={['column', 'row']}
          >
            {
              ['pending', 'on_hold'].includes(status) &&
              <CancelButtonWithModal
                type="L1"
                handleCancel={() => handleCancel({ id, io, foe })}
                subtext={`${io.label}: ${foe.type.label}: ${foe.title}`}
              />
            }
            {
              canEditEnrollmentConfig({ id, status, io }) &&
              <ExtendedFlex
                align={['center', 'flex-start']}
                flexDirection="column"
              >
                {
                  !io?.editingDisabled &&
                  <Link
                    type="L1"
                    w={[1, 'auto']}
                    to={generatePath(autoInvestEditUrl, { id })}
                  >
                    Edit Enrollment
                  </Link>
                }
                {
                  !io?.disableEnrollmentMessage && io?.editingDisabled &&
                  <Body
                    type="B2"
                    w={[1, 'auto']}
                    mr={[undefined, undefined, 20]}
                  >
                    {
                      io.showNextWithdrawalOnDisabledIo &&
                      <>
                        You may pause your auto-invest, but re-enrollment is
                        currently unavailable. Auto-invest enrollment will be
                        available again soon.
                      </>
                    }
                    {
                      !io.showNextWithdrawalOnDisabledIo &&
                      <>
                        The Auto Investment Program for this offering has been
                        paused and your active enrollment has been placed on hold.
                        Once the Auto Investment Program is available for investors
                        and your enrollment has been reinstated, we will promptly
                        notify you.
                      </>
                    }
                  </Body>
                }
              </ExtendedFlex>
            }
            {
              status === 'inactive' && (
                <>
                  {
                    !io?.enrollmentsDisabled &&
                    <StyledButton
                      type="L1"
                      w={[1, 'auto']}
                      onClick={() => {
                        // Redirect to the enrollment page
                        getEnrollmentRedirect(enrollmentUrl, includeJwt).then((redirect) => {
                          if (redirect && typeof window !== 'undefined') {
                            window.location.href = redirect;
                          }
                        });
                      }}
                    >
                      Enroll Account
                    </StyledButton>
                  }
                </>
              )
            }
            {
              ['active', 'paused'].includes(status) &&
              <ExtendedFlex
                align="center"
                ml={[null, 10]}
                mt={[10, 0]}
              >
                <Body
                  type="B1"
                  my={0}
                  mr={20}
                >
                  Active?
                </Body>
                <Switch
                  label="Enrollment Active"
                  checked={toggleSubmitting
                    ? status !== 'active'
                    : status === 'active'}
                  disabled={toggleSubmitting || (status === 'paused' && io?.editingDisabled)}
                  onChange={() => {
                    this.setState({
                      toggleSubmitting: true,
                    });
                    handleActiveToggle()
                      .finally(() => {
                        if (!this.isUnmounted) {
                          this.setState({
                            toggleSubmitting: false,
                          });
                        }
                      });
                  }}
                  on="Yes"
                  off="No"
                />
              </ExtendedFlex>
            }
          </ExtendedFlex>
          <ExtendedFlex
            flexDirection={['column', 'row']}
            justifyContent="space-between"
          >
            <ExtendedFlex
              flexDirection="column"
              width={['100%', '46%']}
            >
              <ReitContentItem
                label="NEXT ANTICIPATED AUTO INVESTMENT"
                value={nextWithdrawalDate}
                {...nextWithdrawalTooltip}
              />
              <ReitContentItem
                label="MONTHLY AUTO INVESTMENT AMOUNT"
                value={requestedAmount}
                valueFormat={requestedAmount > 0 ? Formatters.DOLLARS_DECIMALS : null}
              />
              <ReitContentItem
                label="BANK ACCOUNT"
                value={bankAccount}
                lastItem
              />
            </ExtendedFlex>
            <ExtendedFlex
              flexDirection="column"
              width={['100%', '46%']}
            >
              <ReitContentItem
                label="TOTAL AUTO INVESTED"
                value={totalInvested}
                valueFormat={totalInvested > 0 ? Formatters.DOLLARS_DECIMALS : null}
              />
              <ReitContentItem
                label="LAST AUTO INVESTMENT AMOUNT"
                value={lastWithdrawalAmount}
                valueFormat={lastWithdrawalAmount > 0 ? Formatters.DOLLARS_DECIMALS : null}
              />
              <ReitContentItem
                label="LAST AUTO INVESTMENT"
                value={lastWithdrawal}
                lastItem
              />
            </ExtendedFlex>
          </ExtendedFlex>
        </ExtendedFlex>
      </>
    );
  }
}

AutoInvestSection.propTypes = {
  id: PropTypes.number,
  enrollmentUrl: PropTypes.string,
  includeJwt: PropTypes.bool,
  handleCancel: PropTypes.func,
  title: PropTypes.string,
  status: PropTypes.string,
  bankAccount: PropTypes.object,
  totalAutoInvested: PropTypes.number,
  requestedAmount: PropTypes.number,
  lastWithdrawalAmount: PropTypes.number,
  lastWithdrawalDate: PropTypes.number,
  nextWithdrawalDate: PropTypes.string,
  handleActiveToggle: PropTypes.func,
  foe: PropTypes.object,
  io: PropTypes.object,
};

export default AutoInvestSection;
