/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  color,
  propTypes,
  transition,
} from 'styled-system';
import CleanElement from 'clean-element';

import {
  hoverColor,
} from '../helpers/style';

const CleanA = CleanElement('a');
const StyledCleanA = styled(CleanA)`
  ${color}
  ${transition}
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: ${(props) => (props.textDecoration || 'none')};

  &:visited {
    ${color}
  }

  &:hover {
    cursor: pointer;
    ${hoverColor}
  }
`;
const CleanLink = CleanElement(Link);
const StyledCleanLink = StyledCleanA.withComponent(CleanLink);

const InlineTextLink = (props) => {
  const {
    to,
  } = props;

  const Element = to ? StyledCleanLink : StyledCleanA;

  return <Element {...props} />;
};

CleanLink.propTypes = {
  ...propTypes.color,
  ...propTypes.transition,
  ...propTypes.textDecoration,
  hoverColor: PropTypes.string,
};
CleanA.propTypes = CleanLink.propTypes;

InlineTextLink.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  transition: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

InlineTextLink.defaultProps = {
  color: 'blue.1',
  hoverColor: 'black',
  transition: 'color 0.5s linear',
  to: null,
};

export default InlineTextLink;
