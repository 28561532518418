/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  theme,
} from 'styled-system';

import {
  Image,
  ExtendedFlex,
} from '..';
import CheckWhite from '../assets/check_wht.svg';

const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  opacity: 0;
  width: 100%;
  height: 100%;

  & + div {
    background-color: ${theme('colors.white')};
    border-color: ${theme('colors.gray.10')};
    transition: background .2s ease;
  }

  &:checked + div {
    background-color: ${theme('colors.blue.1')};
    border-color: ${theme('colors.blue.1')};
  }
`;

const StyledCheckbox = React.forwardRef(({
  handleClick,
  checked,
  disabled,
  ...props
}, ref) => {
  return (
    <ExtendedFlex
      w={30}
      height={30}
      {...props}
      position="relative"
    >
      <Checkbox checked={checked} onChange={handleClick} ref={ref} disabled={disabled} />
      <ExtendedFlex
        border="1px solid"
        align="center"
        justify="center"
        {...props}
        w={1}
        height="100%"
      >
        <Image
          src={CheckWhite}
          w={18}
          height={18}
        />
      </ExtendedFlex>
    </ExtendedFlex>
  );
});

StyledCheckbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default StyledCheckbox;
