/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import actions from '../actions';

import RelatedContent from '../../components/RelatedContent';

class RelatedCategoryContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getKnowledgeCenterRelatedDataRequested(this.props.category);
  }

  render() {
    const {
      category,
      exclude,
      items,
      ...additionalProps
    } = this.props;

    // Don't output unless we have some items.
    if (items.length === 0) {
      return null;
    }

    return (
      <RelatedContent
        items={items.filter((item) => item.id !== exclude).slice(0, 3)}
        {...additionalProps}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getKnowledgeCenterRelatedDataRequested: (category) => {
    dispatch(actions.updateKnowledgeCenterFilterRequested(category, 'topic'));
  },
});

const mapStateToProps = (state) => ({
  items: state.knowledgeCenter.items,
});

RelatedCategoryContent.propTypes = {
  items: PropTypes.array,
  category: PropTypes.string,
  exclude: PropTypes.string,
  getKnowledgeCenterRelatedDataRequested: PropTypes.func,
};

RelatedCategoryContent.defaultProps = {
  category: 'all',
};

export default connect(mapStateToProps, mapDispatchToProps)(RelatedCategoryContent);
