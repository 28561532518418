import types from './types';
import authTypes from '../auth/types';
import { setCsrfToken } from '../auth/api';
import { determineFrontendPrefix, determineIsApp } from './helpers';

const initialState = {
  loaded: undefined,
  user: {
    isStaff: false,
    isNonAuthenticated: false,
  },
  csrfToken: undefined,
  loginUnavailable: false,
  isDrupal403: undefined,
  isDrupal404: undefined,
  isDrupal500: undefined,
  isApp: determineIsApp(),
  frontendPrefix: determineFrontendPrefix(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        user: action.data.user,
        isDrupal403: action.data.drupalStatus.isDrupal403,
        isDrupal404: action.data.drupalStatus.isDrupal404,
        isDrupal500: action.data.drupalStatus.isDrupal500,
        isApp: determineIsApp(),
        frontendPrefix: determineFrontendPrefix(),
      };
    }

    case types.SET_USER_DATA_SUCCEEDED:
    case types.GET_USER_DATA_SUCCEEDED: {
      const {
        loginUnavailable,
        ...user
      } = action.user;

      return {
        ...state,
        user: {
          ...user,
          loaded: true,
        },
        loginUnavailable: !!loginUnavailable,
      };
    }

    case authTypes.USER_SESSION_REQUESTED: {
      setCsrfToken(undefined);
      return {
        ...state,
        csrfToken: undefined,
      };
    }

    case authTypes.USER_SESSION_SUCCEEDED: {
      const csrfToken = action.data['X-CSRF-Token'];
      setCsrfToken(csrfToken);
      return {
        ...state,
        csrfToken,
      };
    }

    case types.GET_USER_DATA_REQUESTED: {
      return {
        ...state,
        user: {
          ...state.user,
          loaded: false,
        },
      };
    }

    case types.GET_USER_DATA_FAILED: {
      return {
        ...state,
        user: {
          loaded: true,
          isLoggedIn: false,
          loginUnavailable: true,
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
};
