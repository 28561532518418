import styled from 'styled-components';
import {
  space,
  width,
  display,
  propTypes,
  maxWidth,
  minWidth,
  minHeight,
  maxHeight,
  position,
  top,
  right,
  bottom,
  left,
} from 'styled-system';

import CleanElement from 'clean-element';

const CleanVideo = CleanElement('video');

CleanVideo.propTypes = {
  ...propTypes.maxWidth,
  ...propTypes.minWidth,
  ...propTypes.minHeight,
  ...propTypes.maxHeight,
};

const CoverVideo = styled(CleanVideo)`
  ${space}
  ${width}
  ${display}
  ${maxWidth}
  ${minWidth}
  ${minHeight}
  ${maxHeight}
  ${position}
  ${top}
  ${right}
  ${bottom}
  ${left}
  object-fit: cover;
`;

export default CoverVideo;
