import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';

import {
  Box,
  Data,
  TooltipLink,
  Tooltip,
  InlineTextLink,
} from '../../..';

const InvestmentCardIoLabel = ({
  ioLabel,
  IoLabelTooltip,
}) => {
  const tooltipId = IoLabelTooltip && uniqueId('io-label-tooltip');

  return (
    <Box
      px={10}
      pb={5}
    >
      <Data
        type="D3"
        display="block"
        m={0}
      >
        {IoLabelTooltip ? (
          <TooltipLink
            component={InlineTextLink}
            tooltipId={tooltipId}
          >
            {ioLabel }
          </TooltipLink>
        ) : ioLabel }
      </Data>
      {
        IoLabelTooltip &&
        <Tooltip
          id={tooltipId}
        >
          <IoLabelTooltip />
        </Tooltip>
      }
    </Box>
  );
};

InvestmentCardIoLabel.propTypes = {
  ioLabel: PropTypes.string,
  IoLabelTooltip: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]),
};

InvestmentCardIoLabel.defaultProps = {
  ioLabel: 'MogulREIT I',
};

export default InvestmentCardIoLabel;
