import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import styled from 'styled-components';

import {
  Body,
  Data,
  ExtendedBox,
  Tooltip,
  TooltipIcon,
} from '../../coreui';

const Label = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
  letter-spacing: normal;
  line-height: normal;
  @media (min-width: 1920px) {
    font-size: 20px;
  }
`;

const SummaryItem = ({ value, label, valueFormat, tooltipText, changeLabel, marginTop = 0, type = null }) => {
  const formattedValue = numeral(value).format(valueFormat);
  return (
    <ExtendedBox width="fit-content" mt={marginTop} textAlign={['center', 'center', 'center', 'left', 'left']}>
      <ExtendedBox alignItems="center" justifyContent={['center', 'center', 'flex-start', 'flex-start', 'flex-start']}>
        <Label>
          {label}
          {tooltipText && (
            <TooltipIcon ml={5} tooltipId={label} />
          )}
        </Label>
        <Tooltip id={label} ml={5}>
          <Body type="B5">{tooltipText}</Body>
        </Tooltip>
      </ExtendedBox>
      <ExtendedBox>
        <Data type="D6" fontWeight="400" data-label={label}>{formattedValue}{type ? ` ${type}` : ''}</Data>
      </ExtendedBox>
      <ExtendedBox>
        <Data type="D3" color="#A7A9AC">{changeLabel}</Data>
      </ExtendedBox>
    </ExtendedBox>
  );
};

SummaryItem.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  valueFormat: PropTypes.string,
  tooltipText: PropTypes.string,
  changeLabel: PropTypes.string,
  marginTop: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
  ]),
  type: PropTypes.string,
};

export default SummaryItem;
