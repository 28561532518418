/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
} from '../../../coreui';
import AutoInvestSectionWrapper from './AutoInvestSectionWrapper';
import AutoInvestSection from './AutoInvestSection';

const Manage = ({
  ownershipOptions,
  handleEnrollment,
  handleCancel,
  handleActiveToggle,
}) => {
  const ownershipOptionsGrouped = ownershipOptions.reduce((accum, option) => ({
    ...accum,
    [option.foe.type.label]: [
      ...(accum[option.foe.type.label] || []),
      option,
    ],
  }), {});
  return (
    <ExtendedFlex
      w={1}
      flexDirection="column"
      align="center"
    >
      {
        Object.keys(ownershipOptionsGrouped).map((bundle) => (
          <AutoInvestSectionWrapper
            key={bundle}
            title={bundle}
            contentWidth="100%"
            initialIsExpanded
          >
            {
              ownershipOptionsGrouped[bundle].map((option) => (
                <AutoInvestSection
                  key={`${option.foe.id}-${option.io.id}`}
                  title={`${option.foe.title} - ${option.io.label}`}
                  {...option}
                  handleEnrollment={handleEnrollment}
                  handleCancel={handleCancel}
                  handleActiveToggle={() => handleActiveToggle(option.id)}
                />
              ))
            }
          </AutoInvestSectionWrapper>
        ))
      }
    </ExtendedFlex>
  );
};

Manage.propTypes = {
  ownershipOptions: PropTypes.array,
  handleEnrollment: PropTypes.func,
  handleCancel: PropTypes.func,
  handleActiveToggle: PropTypes.func,
};

export default Manage;
