import {
  setAccreditedInvestorState,
  setNonAccreditedInvestorState,
  setPreAccreditedInvestorState,
} from './state-setter';

import { fetchInvestmentMetricFromData } from './helper';
import {
  parsePrivatePlacementDataFromJson,
} from './private-placement';
import {
  parseReitFromJson,
  ACTIVE_INVESTMENT_TOTALS_TOTAL_INVESTED,
  ACTIVE_INVESTMENT_TOTALS_TOTAL_DISTRIBUTIONS,
  ACTIVE_INVESTMENT_TOTALS_REIT_RETURN,
  ACTIVE_INVESTMENT_TOTALS_PRIVATE_PLACEMENT_CASH_ON_CASH,
  ACTIVE_INVESTMENT_REITS_DISTRIBUTIONS_REINVESTED,
  COMPLETED_INVESTMENT_TOTALS_TOTAL_INVESTED,
  COMPLETED_INVESTMENT_TOTALS_TOTAL_RETURNED,
  COMPLETED_INVESTMENT_PRIVATE_PLACEMENTS_ANNUALIZED_RETURN,
} from './reit';
import { parseProgressBarData } from './progress-bar';
import {
  TOTAL_PENDING_TOOLTIP,
  TOTAL_PLEDGED_TOOLTIP,
  PENDING_INVESTMENTS_TOOLTIP,
  PLEDGED_INVESTMENTS_TOOLTIP,
  WAITLIST_INVESTMENTS_TOOLTIP,
  WATCHLIST_INVESTMENTS_TOOLTIP,
} from './tooltips';
import { determineFrontendPrefix, determineIsApp } from '../../appWrapper/helpers';

export const ACCREDITED = 'Accredited';
export const NON_ACCREDITED = 'Non-Accredited';
export const PRE_ACCREDITED = 'Pre-Accredited';

export const HAS_INVESTMENT_MAIN_CONTENT = `
  <div class="ice-row">
    <div class="ice-column">
      <div class="ice-content -medium">This is your "My Investments" Dashboard.</div>
    </div>
  </div>
  <div class="ice-row">
    <div class="ice-column">
      <ul class="ice-list--bullets-condensed">
        <li class="ice-list--bullets-condensed__item">
          <span class="ice-list--bullets-condensed__content">Track the performance of your investments</span>
        </li>
        <li class="ice-list--bullets-condensed__item">
          <span class="ice-list--bullets-condensed__content">Manage distributions</span>
        </li>
        <li class="ice-list--bullets-condensed__item">
          <span class="ice-list--bullets-condensed__content">Access your tax documents</span>
        </li>
      </ul>
    </div>
  </div>`;

export const NO_INVESTMENT_MAIN_CONTENT = `
  <div class="ice-row">
    <div class="ice-column">
      <div class="ice-content -medium">This is your "My Investments" Dashboard.</div>
    </div>
  </div>
  <div class="ice-row -pad-bottom">
    <div class="ice-column">
      <ul class="ice-list--bullets-condensed">
        <li class="ice-list--bullets-condensed__item">
          <span class="ice-list--bullets-condensed__content">Track the performance of your investments</span>
        </li>
        <li class="ice-list--bullets-condensed__item">
          <span class="ice-list--bullets-condensed__content">Manage distributions</span>
        </li>
        <li class="ice-list--bullets-condensed__item">
          <span class="ice-list--bullets-condensed__content">Access your tax documents</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="ice-row">
    <div class="ice-column">
      <div class="ice-content -medium">Right now you don't have any investments, but feel free to click around.</div>
    </div>
  </div>`;

export function getTotalDistributionsObject(jsonData) {
  return {
    totalDistributions:
    fetchInvestmentMetricFromData(
      jsonData,
      'totalDistributions',
      'Total Distributions',
      'currency',
      'big',
      { metricGroup: ['Total Distributions'], tooltip: ACTIVE_INVESTMENT_TOTALS_TOTAL_DISTRIBUTIONS },
    ),
  };
}

export function getActiveInvestmentTotalInvestedObject(jsonData) {
  return {
    totalInvested:
    fetchInvestmentMetricFromData(
      jsonData,
      'totalInvested',
      'Total Invested',
      'currency',
      'big',
      {
        metricGroup: ['Total Invested'],
        tooltip: ACTIVE_INVESTMENT_TOTALS_TOTAL_INVESTED,
      },
    ),
  };
}

export function parsePendingDataFromJson(jsonData, investmentStage) {
  return jsonData.reduce((accum, io) => {
    if (io.investmentStage !== investmentStage) {
      return accum;
    }
    if (io.investmentStage === 'pending' && io.fundedWaitListFlag) {
      return accum;
    }
    return [
      ...accum,
      {
        ...io,
        actionUrl: io.url,
        actionUrlType: 'L1',
      },
    ];
  }, []);
}

export function getPendingInvestmentTotalInvestedObject(jsonData) {
  const investments = parsePendingDataFromJson(jsonData, 'pending');
  return {
    totalInvested: {
      label: 'Total Pending',
      value: investments.reduce((amount, investment) => {
        return amount + (investment.amount || 0);
      }, 0),
      tooltip: TOTAL_PENDING_TOOLTIP,
    },
  };
}

export function getPledgedInvestmentTotalInvestedObject(jsonData) {
  const investments = parsePendingDataFromJson(jsonData, 'pledged');
  return {
    totalInvested: {
      label: 'Total Pledged',
      value: investments.reduce((amount, investment) => {
        return amount + (investment.amount || 0);
      }, 0),
      tooltip: TOTAL_PLEDGED_TOOLTIP,
    },
  };
}

export function firstState() {
  return {};
}

export function jsonObjectReducer(json, state, serializer) {
  const serResult = serializer(json);
  const val = { ...state, ...serResult };
  return val;
}

export function evaluateSerializers(json, serializers) {
  const boundReducer = jsonObjectReducer.bind(this, json);
  const reducedVal = serializers.reduce(boundReducer);
  return reducedVal;
}

export function getCashInvestedObject(jsonData) {
  return {
    cashInvested:
        fetchInvestmentMetricFromData(jsonData, 'cashInvested', 'Cash Invested', 'currency', 'medium', { metricGroup: ['Total Invested'] }),
  };
}

export function getDistributionsInvestedObject(jsonData) {
  return {
    distributionsInvested:
        fetchInvestmentMetricFromData(jsonData, 'distributionsReinvested', 'Distributions Reinvested', 'currency', 'medium', { metricGroup: ['Total Invested', 'Total Distributions'], tooltip: ACTIVE_INVESTMENT_REITS_DISTRIBUTIONS_REINVESTED }),
  };
}

export function getCashDistributionsPaidObject(jsonData) {
  return {
    cashDistributionsPaid:
        fetchInvestmentMetricFromData(jsonData, 'cashDistributionsPaid', 'Cash Distributions Paid', 'currency', 'medium', { metricGroup: ['Total Distributions'] }),
  };
}

export function getReitTotalReturnObject(jsonData) {
  return {
    reitTotalReturn:
        fetchInvestmentMetricFromData(jsonData, 'reitTotalReturn', 'REITs Return', 'percentage', 'medium-highlighted', { tooltip: ACTIVE_INVESTMENT_TOTALS_REIT_RETURN }),
  };
}

export function getPrivatePlacementCashOnCashObject(jsonData) {
  return {
    privatePlacementsCashOnCash:
        fetchInvestmentMetricFromData(jsonData, 'privatePlacementsCashOnCash', 'Private Placements Cash on Cash', 'percentage', 'medium-highlighted', { tooltip: ACTIVE_INVESTMENT_TOTALS_PRIVATE_PLACEMENT_CASH_ON_CASH }),
  };
}

export function getReitDataObject(jsonData) {
  const reitObject = parseReitFromJson(jsonData);

  return {
    reitData:
        reitObject,
  };
}

export function getPrivatePlacementObject(jsonData) {
  const ppObject = parsePrivatePlacementDataFromJson(jsonData);

  return {
    privatePlacementData:
        ppObject,
  };
}

export function getPendingInvestments(jsonData) {
  return {
    investments: [
      ...parsePendingDataFromJson(jsonData, 'pending'),
    ],
    tooltip: PENDING_INVESTMENTS_TOOLTIP,
  };
}

export function getPledgedInvestments(jsonData) {
  return {
    investments: [
      ...parsePendingDataFromJson(jsonData, 'pledged'),
    ],
    tooltip: PLEDGED_INVESTMENTS_TOOLTIP,
  };
}

export function getCompletedInvestmentTotalInvestedObject(jsonData) {
  return {
    totalInvested:
        fetchInvestmentMetricFromData(jsonData, 'totalInvested', 'Total Invested', 'currency', 'big', { metricGroup: ['Total Invested'], tooltip: COMPLETED_INVESTMENT_TOTALS_TOTAL_INVESTED }),
  };
}

export function getActiveInvestmentData(jsonData) {
  return evaluateSerializers(
    jsonData.activeInvestmentsData,
    [firstState,
      getActiveInvestmentTotalInvestedObject,
      getTotalDistributionsObject,
      getCashInvestedObject,
      getDistributionsInvestedObject,
      getCashDistributionsPaidObject,
      getReitTotalReturnObject,
      getPrivatePlacementCashOnCashObject,
      getReitDataObject,
      getPrivatePlacementObject],
  );
}

// cashDistributionsPaid maps to Total Returned
export function getCompletedInvestmentTotalReturnedObject(jsonData) {
  return {
    totalReturned:
        fetchInvestmentMetricFromData(jsonData, 'cashDistributionsPaid', 'Total Returned', 'currency', 'big', { tooltip: COMPLETED_INVESTMENT_TOTALS_TOTAL_RETURNED }),
  };
}

// TODO: Wes will add this to next data model
export function getTotalAnnualizedReturnObject(jsonData) {
  return {
    totalAnnualizedReturn:
        fetchInvestmentMetricFromData(jsonData, 'totalAnnualizedReturn', 'Realized Returns', 'percentage', 'big', { tooltip: COMPLETED_INVESTMENT_PRIVATE_PLACEMENTS_ANNUALIZED_RETURN }),
  };
}

export function getCompletedInvestmentData(jsonData) {
  return evaluateSerializers(
    jsonData.completedInvestmentsData,
    [
      firstState,
      getCompletedInvestmentTotalInvestedObject,
      getCompletedInvestmentTotalReturnedObject,
      getTotalAnnualizedReturnObject,
      getReitDataObject,
      getPrivatePlacementObject,
    ],
  );
}

export function getModalContent(jsonData) {
  const { totalInvested } = jsonData.activeInvestmentsData.privatePlacementData.summary;
  const anyInvestments = totalInvested > 0 ||
        jsonData.activeInvestmentsData.reitData.summary.totalInvested > 0 ||
        jsonData.completedInvestmentsData.privatePlacementData.summary.totalInvested > 0 ||
        jsonData.completedInvestmentsData.reitData.summary.totalInvested > 0;

  const modalContent = {
    modalHeaderContent: 'Welcome!',
    modalMainContent: anyInvestments ? HAS_INVESTMENT_MAIN_CONTENT : NO_INVESTMENT_MAIN_CONTENT,
    modalButtonContent: anyInvestments ? 'Close' : 'Explore',
  };

  return modalContent;
}

export function getProgressBarData(jsonData) {
  return parseProgressBarData(jsonData);
}

export function getPendingData(jsonData) {
  return evaluateSerializers(
    jsonData,
    [
      firstState,
      getPendingInvestments,
      getPendingInvestmentTotalInvestedObject,
    ],
  );
}

export function getPledgedData(jsonData) {
  return evaluateSerializers(
    jsonData,
    [
      firstState,
      getPledgedInvestments,
      getPledgedInvestmentTotalInvestedObject,
    ],
  );
}

export function getWatchListData(jsonData) {
  return {
    items: jsonData.watchListData,
    tooltip: WATCHLIST_INVESTMENTS_TOOLTIP,
  };
}

export function getWaitListData(jsonData) {
  return {
    investments: jsonData.waitListData.reduce((accum, io) => {
      return [
        ...accum,
        ...[{
          ...io,
          investment: io.title,
          investmentUrl: io.buttonUrl,
          date: io.date,
          cancel: io.cancelUrl,
        }],
      ];
    }, []),
    tooltip: WAITLIST_INVESTMENTS_TOOLTIP,
  };
}

export function serializePayloadToInitialState(jsonData) {
  const activeInvestmentObject = getActiveInvestmentData(jsonData);
  const completedInvestmentObject = getCompletedInvestmentData(jsonData);
  const progressBarData = getProgressBarData(jsonData);
  const watchListObject = getWatchListData(jsonData);
  const waitListObject = getWaitListData(jsonData);
  const modalsObject = getModalContent(jsonData);

  return {
    activeInvestmentsData: activeInvestmentObject,
    targetOverviewData: activeInvestmentObject,
    completedInvestmentsData: completedInvestmentObject,
    progressBarData,
    watchListData: watchListObject,
    waitListData: waitListObject,
    modalContent: modalsObject,
    isApp: determineIsApp(),
    frontendPrefix: determineFrontendPrefix(),
  };
}

export function dripToggleProcess(action, state, setData) {
  return {
    ...state,
    data: {
      ...state.data,
      activeInvestmentsData: {
        ...state.data.activeInvestmentsData,
        reitData: {
          ...state.data.activeInvestmentsData.reitData,
          reits: state.data.activeInvestmentsData.reitData.reits.reduce((accum, reit) => {
            return [
              ...accum,
              ...[{
                ...reit,
                dripToggleDisabled: (reit.nid === action.id) ? true : null,
                ...(reit.nid === action.id ? setData : {}),
              }],
            ];
          }, []),
        },
      },
    },
  };
}

export function deserializeInvestment(jsonData) {
  const initialState = serializePayloadToInitialState(jsonData);
  switch (jsonData.accreditationStatus) {
    case ACCREDITED:
      return setAccreditedInvestorState(initialState, jsonData);
    case PRE_ACCREDITED:
      return setPreAccreditedInvestorState(initialState, jsonData);
    case NON_ACCREDITED:
      return setNonAccreditedInvestorState(initialState, jsonData);
    default:
      return {};
  }
}

export const deserializePendingInvestment = (jsonData) => {
  return {
    investments: [...jsonData],
    pendingData: getPendingData(jsonData),
    pledgedData: getPledgedData(jsonData),
  };
};

export default {
  deserializeInvestment,
};
