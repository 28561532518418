import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Loader,
} from '../../coreui';

import Section from '../../ioDetails/components/section';
import MessagesTable from '../../dashboard/messages/components/MessagesTable';

const InvestmentUpdates = ({
  loaded,
  messages,
}) => {
  return (
    <Section
      title="Investment Updates"
      bg="white"
      w={1}
    >
      {
        loaded &&
        <MessagesTable
          messages={messages}
          showInvestmentLabel={false}
        />
      }
      {
        !loaded &&
        <ExtendedFlex
          position="relative"
          minHeight={300}
        >
          <Loader />
        </ExtendedFlex>
      }
    </Section>
  );
};

InvestmentUpdates.propTypes = {
  loaded: PropTypes.bool,
  messages: PropTypes.array,
};

export default InvestmentUpdates;
