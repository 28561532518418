import popularContentTypes from './types';

const initialState = {
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case popularContentTypes.GET_POPULAR_CONTENT_SUCCEEDED: {
      return {
        ...state,
        items: action.data.data,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
