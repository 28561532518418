import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import { getKnowledgeCenterDataApi } from '../../api';

export function* updateKnowledgeCenterFilterFlow(action) {
  try {
    const response = yield call(
      getKnowledgeCenterDataApi,
      action.category,
    );
    yield put(
      actions.updateKnowledgeCenterFilterSucceeded(response.data),
    );
  } catch (error) {
    yield put(
      actions.updateKnowledgeCenterFilterFailed(error),
    );
  }
}

export function* watchUpdateKnowledgeCenterFilterRequested() {
  yield takeLatest(
    types.UPDATE_KNOWLEDGE_CENTER_FILTER_REQUESTED,
    updateKnowledgeCenterFilterFlow,
  );
}

export default watchUpdateKnowledgeCenterFilterRequested;
