import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton, Body, ScrollToTopOnMount } from '../../coreui';
import Actions from '../../components/forms/Actions';
import PrimaryButton from '../../components/forms/PrimaryButton';
import ShareRepurchaseHeading from './ShareRepurchaseHeading';
import ShareRepurchaseWrapper from './ShareRepurchaseWrapper';
import StyledTable from '../../dashboard/components/StyledTable';
import HeaderComponent from '../../dashboard/components/TableHeader';
import TableCell from './TableCell';

const columns = [
  {
    id: 'year',
    Header: <HeaderComponent>Share Repurchase Anniversary (Year)</HeaderComponent>,
    accessor: 'year',
    Cell: TableCell,
    className: 'title-column',
    headerClassName: 'title-column',
  },
  {
    id: 'weight',
    accessor: 'weight',
    show: false,
  },
  {
    id: 'rate',
    Header: <HeaderComponent>Effective Repurchase Rate*</HeaderComponent>,
    accessor: 'rate',
    Cell: TableCell,
  },
];

const data = [
  {
    year: 'Less than 1 year',
    rate: '(Lock up) 0%',
  },
  {
    year: '1 year until 2 years',
    rate: '98%',
  },
  {
    year: '2 years until 3 years',
    rate: '99%',
  },
  {
    year: '3 or more years',
    rate: '100%',
  },
  {
    year: 'Death (exception repurchase)',
    rate: '100%',
  },
];

const UnderstandingShareRepurchaseRates = ({ handleFormSubmit, handelCancelRequest }) => {
  return (
    <ShareRepurchaseWrapper>
      <ScrollToTopOnMount />
      <ShareRepurchaseHeading>
        Understanding Share Repurchase Rates
      </ShareRepurchaseHeading>
      <Body type="B1">
        The Company will repurchase the shares at the most recently announced
        NAV per share, multiplied by the Effective Repurchase Rate. The
        repurchase rates at which the Company will repurchase shares are
        presented in the table below.
      </Body>
      <StyledTable
        data={data.map((job, i) => ({
          ...job,
          weight: i,
        }))}
        noDataText="No job listings."
        additionalTableProps={{
          showPageSizeOptions: false,
          defaultSorted: [
            {
              id: 'weight',
              desc: false,
            },
          ],
        }}
        columns={columns}
        showPagination={false}
        pageSize={data.length}
        tableWrapperProps={{
          overflowX: 'hidden',
        }}
      />
      <Body type="B2">
        * Percentage of the Repurchase Base Price per share. The repurchase
        price will be rounded down to the nearest $0.01.
      </Body>
      <Actions>
        <PrimaryButton buttonType="submit" onClick={handleFormSubmit}>
          Continue
        </PrimaryButton>
        <StyledButton type="L4" onClick={handelCancelRequest}>
          Cancel request
        </StyledButton>
      </Actions>
    </ShareRepurchaseWrapper>
  );
};

UnderstandingShareRepurchaseRates.propTypes = {
  handleFormSubmit: PropTypes.func,
  handelCancelRequest: PropTypes.func,
};

export default UnderstandingShareRepurchaseRates;
