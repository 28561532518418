export const enrollmentSteps = [
  {
    label: 'Info',
  },
  {
    label: 'Suitability',
  },
  {
    label: 'Investment',
  },
  {
    label: 'Confirmation',
  },
];

export const gtkyProps = [
  'risk',
  'holdPeriod',
  'currentInvestments',
  'propertyTypes',
  'need1031',
  'employmentType',
  'employer',
  'employmentPositionHeld',
  'employmentIndustry',
  'sourceOfIncome',
  'sourceOfWealth',
];

export default {
  enrollmentSteps,
};
