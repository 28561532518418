import types from './types';
import browsePageTypes from '../browse/browsePage/types';

const initialState = {
  id: null,
  investmentType: null,
  status: null,
  investments: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_IO_DATA_SUCCEEDED:
      return {
        ...state,
        id: action.data.data[0].id,
        label: action.data.data[0].label,
        ioDetailType: action.data.data[0].ioDetailType,
        is1031: action.data.data[0].is1031,
        status: '200',
      };

    case types.GET_IO_DATA_FAILED:
      return {
        ...state,
        status: action.error.response.status.toString(),
      };
    case types.GET_IO_FAQ_DATA_SUCCEEDED:
      return {
        ...state,
        faqStatus: '200',
      };
    case types.UNSET_IO_STATE:
      return {
        ...state,
        faqStatus: null,
        status: null,
        id: null,
      };
    case browsePageTypes.GET_BROWSE_OPEN_DATA_SUCCEEDED: {
      const investments = action.data;

      return {
        ...state,
        loaded: true,
        dataLoaded: true,
        investments,
        investmentsCount: investments.length,
        allInvestments: investments,
        allInvestmentsCount: investments.length,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
