import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import {
  steps,
} from '../auth/data';

import {
  ScrollToTopOnMount,
  ExtendedFlex,
  Image,
  Heading,
  Data,
  Body,
  InlineTextLink,
  Tooltip,
  TooltipLink,
  isDrupalPath,
} from '../coreui';

import StepCounter from '../components/StepCounter';
import Container from '../auth/components/Container';
import FirstContainer from '../auth/components/FirstContainer';
import SecondContainer from '../auth/components/SecondContainer';
import InfoBlock from '../auth/components/InfoBlock';
import UnorderedList from '../auth/components/UnorderedList';
import ListItem from '../auth/components/ListItem';

import Form from '../components/forms/Form';
import Actions from '../components/forms/Actions';
import Select from '../components/forms/Select';
import RadioGroup from '../components/forms/RadioGroup';
import RadioLabel from '../components/forms/RadioLabel';
import PrimaryButton from '../components/forms/PrimaryButton';
import DashboardLoader from '../dashboard/components/DashboardLoader';

import pressMentionsData from '../homepage/pressMentions/pressMentionsData';
import actions from './actions';

const orderedPressMentionsData = pressMentionsData.sort((a, b) => {
  if (a.weight === b.weight) {
    return 0;
  }
  return a.weight < b.weight ? -1 : 1;
});

const netWorthOptions = [
  { value: '10m_more', label: '$10 Million +' },
  { value: '5m_10m', label: '$5 Million - $10 Million' },
  { value: '3m_5m', label: '$3 Million - $5 Million' },
  { value: '1m_3m', label: '$1 Million - $3 Million' },
  { value: '200k_1m', label: '$200,000 - $1 Million' },
  { value: '50k_200k', label: '$50,000 - $200,000' },
  { value: '25k_50k', label: '$25,000 - $50,000' },
  { value: '10k_25k', label: '$10,000 - $25,000' },
  { value: 'less_10k', label: 'Less than $10,000' },
];

const incomeOptions = [
  { value: '500k_greater', label: 'Greater than $500,000' },
  { value: '300k_to_500k', label: '$300,000 - $500,000' },
  { value: '200k_to_300k', label: '$200,000 - $300,000' },
  { value: '100k_to_200k', label: '$100,000 - $200,000' },
  { value: '50k_to_100k', label: '$50,000 - $100,000' },
  { value: 'less_50k', label: 'Less than $50,000' },
  { value: 'retired', label: 'Retired' },
  { value: 'student', label: 'Student' },
];

const currentYear = parseInt((new Date()).getFullYear(), 10);

class Accreditation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      netWorth: '',
      incomeDetermination: 'individual',
      currentIncome: '',
      y1Income: '',
      y2Income: '',
      redirecting: false,
    };

    this.isUnmounted = false;

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.setState({
      submitting: true,
    });

    const {
      history,
      postAccreditationRequested,
    } = this.props;

    return (new Promise((resolve, reject) => {
      /* eslint-disable camelcase */
      postAccreditationRequested({
        net_worth: this.state.netWorth,
        income_determination: this.state.incomeDetermination,
        year_current_income: this.state.currentIncome,
        year_y1_income: this.state.y1Income,
        year_y2_income: this.state.y2Income,
      }, {
        resolve,
        reject,
      });
      /* eslint-enable camelcase */
    }))
      .then(({ data: { redirect } }) => {
        if (!this.isUnmounted) {
          if (isDrupalPath(redirect)) {
            window.location = redirect;
          } else {
            history.push(redirect);
          }
          this.setState({
            redirecting: true,
          });
        }
      })
      .catch((err) => {
        if (!this.isUnmounted) {
          this.setState({
            submitting: false,
          });
        }
        throw err;
      });
  }

  render() {
    const {
      isLoggedIn,
      isPreAccred,
    } = this.props;

    const {
      netWorth,
      incomeDetermination,
      currentIncome,
      y1Income,
      y2Income,
      submitting,
      redirecting,
    } = this.state;

    if (redirecting) {
      return <DashboardLoader />;
    }

    const notLoggedIn = isLoggedIn !== undefined && !isLoggedIn;
    const notPreAccred = isPreAccred !== undefined && !isPreAccred;

    if (notLoggedIn || notPreAccred) {
      return <Redirect to="/user/login" />;
    }

    return (
      <ExtendedFlex
        flexDirection="column"
      >
        <ExtendedFlex
          w="90%"
          maxWidth={1200}
          mt={50}
          mx="auto"
          display={['none', null, 'flex']}
        >
          <StepCounter
            steps={steps}
            activeStep={1}
          />
        </ExtendedFlex>
        <Container>
          <FirstContainer
            mx={0}
            mb={0}
            pl={[null, null, 0]}
            px={10}
            boxSizing="content-box"
          >
            <DashboardLoader
              loaded={isPreAccred !== undefined}
            >
              <Form onSubmit={this.handleFormSubmit} >
                <ExtendedFlex
                  flexDirection="column"
                >
                  <ScrollToTopOnMount />
                  <Heading
                    type="H2"
                  >
                    Determine Your Investment Profile.
                  </Heading>
                  <Body
                    type="B1"
                  >
                    The following information determines which investments we are
                    allowed to offer you.
                  </Body>
                  <Body
                    type="B2"
                  >
                    The SEC requires that certain investment opportunities are
                    only available to <TooltipLink component={<InlineTextLink />} tooltipId="accreditedInvestor">accredited investors</TooltipLink>.
                  </Body>
                  <Tooltip id="accreditedInvestor">
                    <Data type="D5">
                      An Accredited Investor meets at least one of the following criteria:
                    </Data>
                    <UnorderedList>
                      <ListItem>
                        Net Worth in excess of $1 million (individually or
                        jointly), excluding the value of your primary residence.
                      </ListItem>
                      <ListItem>
                        Income in excess of $200,000 for this year and in the
                        previous two years, with the expectation of maintaining
                        such level of income in the future.
                      </ListItem>
                      <ListItem>
                        Joint Income in excess of $300,000 for this year and the
                        previous two years, with the expectation of maintaining
                        such level of income in the future (Note: it is not
                        mandatory to qualify jointly with your spouse if you
                        qualify as an individual.)
                      </ListItem>
                    </UnorderedList>
                  </Tooltip>
                  <Heading
                    type="H5"
                    textTransform="uppercase"
                    mt={40}
                    mb={10}
                  >
                    Net Worth
                  </Heading>
                  <Body
                    type="B2"
                    mt={0}
                  >
                    Your current net worth, excluding your primary residence.
                  </Body>
                  <Select
                    name="net_worth"
                    value={netWorth}
                    onChange={(e) => {
                      this.setState({
                        netWorth: e.target.value,
                      });
                    }}
                    inputProps={{
                      'data-test': 'net_worth',
                    }}
                    placeholder="Select current net worth"
                    required
                    options={netWorthOptions}
                  />
                  <Heading
                    type="H5"
                    textTransform="uppercase"
                    mt={60}
                    mb={10}
                  >
                    Annual Income
                  </Heading>
                  <Body
                    type="B2"
                    mt={0}
                  >
                    Your expected income for this year, as well as your income
                    from the previous two years. You may record as an individual
                    or joint/married.
                  </Body>
                  <RadioGroup
                    name="incomeDetermination"
                    value={incomeDetermination}
                    onChange={(e) => {
                      this.setState({
                        incomeDetermination: e.target.value,
                      });
                    }}
                    inputProps={{
                      'data-test': 'incomeDetermination',
                      required: true,
                    }}
                  >
                    <RadioLabel
                      value="individual"
                      label="Individual"
                    />
                    <RadioLabel
                      value="joint"
                      label="Joint (married)"
                    />
                  </RadioGroup>
                  <Select
                    name="current_income"
                    value={currentIncome}
                    onChange={(e) => {
                      this.setState({
                        currentIncome: e.target.value,
                      });
                    }}
                    inputProps={{
                      'data-test': 'income1',
                    }}
                    placeholder={`Select ${currentYear} expected income`}
                    required
                    options={incomeOptions}
                  />
                  <Select
                    name="y1_income"
                    value={y1Income}
                    onChange={(e) => {
                      this.setState({
                        y1Income: e.target.value,
                      });
                    }}
                    inputProps={{
                      'data-test': 'income2',
                    }}
                    placeholder={`Select ${currentYear - 1} income`}
                    required
                    zebra="even"
                    options={incomeOptions}
                  />
                  <Select
                    name="y2_income"
                    value={y2Income}
                    onChange={(e) => {
                      this.setState({
                        y2Income: e.target.value,
                      });
                    }}
                    inputProps={{
                      'data-test': 'income3',
                    }}
                    placeholder={`Select ${currentYear - 2} income`}
                    required
                    options={incomeOptions}
                  />
                </ExtendedFlex>
                <Actions
                  flexWrap="wrap"
                  mt={0}
                >
                  <PrimaryButton
                    loading={submitting}
                    disable={submitting}
                    buttonType="submit"
                  >
                    Continue
                  </PrimaryButton>
                </Actions>
              </Form>
            </DashboardLoader>
          </FirstContainer>
          <SecondContainer
            pl={60}
            borderLeft={1}
            borderColor="gray.10"
          >
            <InfoBlock
              heading="Membership Benefits"
              headingProps={{
                mt: 0,
              }}
            >
              <UnorderedList>
                <ListItem>
                  24/7 Access to fully vetted investment opportunities.
                </ListItem>
                <ListItem>
                  A team of Investor Relations professionals are available to talk by
                  phone or email about using the RealtyMogul platform.
                </ListItem>
                <ListItem>
                  Access to exclusive opportunities in
                  institutional-quality properties.
                </ListItem>
              </UnorderedList>
            </InfoBlock>
            <InfoBlock
              heading="As seen in"
              borderBottom="none"
            >
              {
                orderedPressMentionsData.map((data) => (
                  <React.Fragment
                    key={data.name}
                  >
                    <Image
                      src={data.logoImage}
                      my={10}
                      maxWidth="140px"
                    />
                    <Data
                      type="D3"
                    >
                      {data.quote}
                    </Data>
                  </React.Fragment>
                ))
              }
            </InfoBlock>
          </SecondContainer>
        </Container>
      </ExtendedFlex>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.loginSuccess ||
    (state.auth.session !== null ? state.auth.session : undefined),
  isPreAccred: state.app.user.accreditationRoleStatus
    ? state.app.user.accreditationRoleStatus === 'Pre-Accredited'
    : undefined,
});

const mapDispatchToProps = (dispatch) => ({
  postAccreditationRequested: (data, promise) => {
    dispatch(actions.userAccreditationRequested(data, promise));
  },
});

Accreditation.propTypes = {
  isLoggedIn: PropTypes.bool,
  isPreAccred: PropTypes.bool,
  postAccreditationRequested: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Accreditation),
);
