import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import { getRetirementFAQDataApi } from '../../api';

export function* getFAQDataFlow() {
  try {
    const response = yield call(getRetirementFAQDataApi);
    yield put(
      actions.getFAQDataSucceeded(response.data),
    );
  } catch (error) {
    yield put(
      actions.getFAQDataFailed(error),
    );
  }
}

export function* watchGetFAQDataRequested() {
  yield takeLatest(
    types.GET_FAQ_DATA_REQUESTED,
    getFAQDataFlow,
  );
}

export default watchGetFAQDataRequested;
