import React from 'react';
import PropTypes from 'prop-types';
import {
  GlossaryAccordion,
  ExtendedBox,
  Body,
} from '../../../coreui';

const GlossarySection = ({ section }) => {
  return (
    <ExtendedBox
      justify="left"
      align="left"
      w={1}
      mb={50}

    >
      <Body
        type="B4"
        color="black"
        px={15}
        mb={5}
      >
        { Object.keys(section)[0] }
      </Body>
      <ExtendedBox
        borderTop={1}
        borderColor="gray.7"
      >
        <GlossaryAccordion
          items={section[Object.keys(section)[0]]}
          contentIsHtml
        />
      </ExtendedBox>
    </ExtendedBox>
  );
};

GlossarySection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default GlossarySection;
