import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Styled from './AuthOverlay.styled';
import { CloseButton, CheckmarkIcon } from '../../../IconLibrary';
import Auth from '../../../auth';

const SellingPoint = ({ paragraph }) => (
  <div className="auth-overlay-sidebar-line">
    <div className="auth-overlay-sidebar-checkmark"><CheckmarkIcon fill="#176DB6" /></div>
    <div className="auth-overlay-sidebar-line-text">{paragraph}</div>
  </div>
);

SellingPoint.propTypes = {
  paragraph: PropTypes.string.isRequired,
};

const AuthOverlay = ({
  closeOverlayFunc,
  url,
  whichScreen,
}) => {
  const [title, setTitle] = useState('');
  const [currentScreen, setCurrentScreen] = useState(whichScreen || 'signUp');
  const overlayScrollRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      disableBodyScroll(overlayScrollRef.current, {
        allowTouchMove: (el) => {
          let element = el;
          do {
            if (element.classList && (element.classList.contains('slick-slider') || element.classList.contains('simplebar-content-wrapper') || element.classList.contains('is-scrollable'))) {
              return true;
            }
            element = element.parentNode;
          } while (element);
          return false;
        },
      });
    }, 0);

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  useEffect(() => {
    switch (currentScreen) {
      case 'forgotpassword':
        setTitle('FORGOT PASSWORD');
        break;
      case 'signIn':
        setTitle('SIGN IN');
        break;
      case 'signUp':
      default:
        setTitle('JOIN REALTYMOGUL');
    }
  }, [currentScreen]);

  return (
    <Styled.OverlayWrapper>
      <Styled.OverlayBackground onClick={() => closeOverlayFunc(false)} />
      <Styled.Overlay isRegisterScreen={currentScreen === 'signUp'}>
        <Styled.PageContent ref={overlayScrollRef}>
          <Styled.DesktopContent>
            <Styled.TopBar>
              <div className="auth-overlay-title">{title}{url}</div>
              <button type="button" className="auth-overlay-close-btn" onClick={() => closeOverlayFunc(false)}>CLOSE<CloseButton fill="#A4C2D5" /></button>
            </Styled.TopBar>
          </Styled.DesktopContent>
          <Styled.MobileContent>
            <Styled.TopBar>
              {currentScreen === 'signUp' &&
                <>
                  <div className="auth-overlay-topbar-wrapper">
                    <div className="auth-overlay-topbar-text">
                      Have an account? <button type="button" className="auth-overlay-signIn-btn" onClick={() => setCurrentScreen('signIn')}>{'Sign In >'}</button>
                    </div>
                    <button type="button" className="auth-overlay-close-btn" onClick={() => closeOverlayFunc(false)}>CLOSE<CloseButton fill="#A4C2D5" /></button>
                  </div>
                  <div className="auth-overlay-title">{title}</div>
                </>}
              {currentScreen === 'signIn' &&
                <>
                  <div className="auth-overlay-title">{title}{url}</div>
                  <button type="button" className="auth-overlay-close-btn" onClick={() => closeOverlayFunc(false)}>CLOSE<CloseButton fill="#A4C2D5" /></button>
                </>}
            </Styled.TopBar>
          </Styled.MobileContent>
          <Styled.MainContent>
            <Styled.DesktopContent>{
              currentScreen === 'signUp' &&
              <Styled.SideBar>
                <div style={{ marginTop: '15px' }}>
                  <SellingPoint paragraph="Gain access to commercial real estate deals across the country" />
                  <SellingPoint paragraph="Easily review, compare and invest in deals that meet your criteria" />
                  <SellingPoint paragraph="Build the real estate portfolio that’s right for you" />
                  <SellingPoint paragraph="Potential benefits include diversification, growth and passive income" />
                </div>
                <div>
                  <div className="auth-overlay-sidebar-text">
                    Have an account? <button type="button" className="auth-overlay-signIn-btn" onClick={() => setCurrentScreen('signIn')}>{'Sign In >'}</button>
                  </div>
                </div>
              </Styled.SideBar>
              }
            </Styled.DesktopContent>
            <Styled.FormWrapper>
              <Auth screen={currentScreen} closeOverlayFunc={closeOverlayFunc} />
            </Styled.FormWrapper>
          </Styled.MainContent>
        </Styled.PageContent>
      </Styled.Overlay>
    </Styled.OverlayWrapper>
  );
};

AuthOverlay.propTypes = {
  closeOverlayFunc: PropTypes.func,
  url: PropTypes.string,
  whichScreen: PropTypes.string,
};

export default AuthOverlay;
