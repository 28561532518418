export const matchOwnershipData = (id, ownershipData) => (
  ownershipData.filter((item) => item.id === id)
);

export const transformTransactions = (transactions, ownershipData) => {
  return transactions.map((item) => {
    const foeId = parseInt(item.foeID, 10);
    const [{
      label,
      title,
    }] = matchOwnershipData(foeId, ownershipData);

    return {
      investment: item.ioTitle,
      ioId: item.ioNID,
      date: item.date,
      type: item.type,
      typeOfTransaction: item.typeOfTransaction,
      pending: item.pending,
      category: item.category,
      issuer: item.issuer,
      account: title,
      accountType: label,
      foeId,
      amount: item.amount !== null ? parseFloat(item.amount, 10) : null,
      shares: item.shares !== null ? parseFloat(item.shares, 10) : null,
      purchasePrice: item.purchasePrice !== null ? parseFloat(item.purchasePrice, 10) : null,
    };
  });
};

export const getTransactions = (json) => {
  const ownershipData = json.formsOfOwnership.map((item) => ({
    ...item,
    id: parseInt(item.id, 10),
  }));
  return {
    completedInvestmentsData: transformTransactions(
      json.completedInvestmentsData,
      ownershipData,
    ),
    activeInvestmentsData: transformTransactions(
      json.activeInvestmentsData,
      ownershipData,
    ),
    allInvestmentsData: transformTransactions(
      json.allInvestmentsData,
      ownershipData,
    ),
  };
};

export default {
  getTransactions,
};
