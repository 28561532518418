/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '..';

export const types = {
  H1: {
    fontSize: [60, 60, 80, 109, 109],
    fontFamily: 'serif',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    letterSpacing: 0,
  },
  H2: {
    fontSize: [28, 36, 36, 44, 52],
    fontFamily: 'serif',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    letterSpacing: '0.036em',
  },
  H3: {
    fontSize: [28, 36, 28, 30, 36], // xs, and s always match H2
    fontFamily: 'serif',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    letterSpacing: '0.07em',
  },
  H4: {
    fontSize: [14, 14, 14, 16, 22],
    fontFamily: 'sansSerif',
    fontWeight: '500',
    textTransform: 'none',
    letterSpacing: 'normal',
    lineHeight: '0.075em',
  },
  H5: {
    fontSize: [18, 18, 20, 20, 28],
    fontFamily: 'sansSerif',
    fontWeight: 'normal',
    textTransform: 'none',
    letterSpacing: 'normal',
  },
  H6: {
    fontSize: [14, 14, 16, 18, 24],
    fontFamily: 'sansSerif',
    fontWeight: 'normal',
    textTransform: 'none',
    letterSpacing: 'normal',
    lineHeight: '1.5',
  },
  H7: {
    fontSize: [12, 12, 14, 14, 20],
    fontFamily: 'sansSerif',
    fontWeight: '300',
    textTransform: 'uppercase',
    letterSpacing: 'normal',
    lineHeight: '1.8em',
  },
  H8: {
    fontSize: [13, 13, 14, 16, 18],
    fontFamily: 'sansSerif',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '0.150em',
  },
  H9: {
    fontSize: [22, 22, 26, 32, 42],
    fontFamily: 'serif',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    letterSpacing: '0.131em',
  },
  ReitLP: {
    fontSize: '18px',
    fontFamily: 'serif',
    fontWeight: '800',
  },
  DashboardLabel: {
    fontSize: [14, 14, 16, 18, 20],
    fontFamily: 'serif',
    fontWeight: '400',
    lineHeight: 1,
  },
};

types['H4.1'] = {
  ...types.H4,
  textTransform: 'uppercase',
};

types['H5.1'] = {
  ...types.H5,
  textTransform: 'uppercase',
};

types['H6.1'] = {
  ...types.H5,
  textTransform: 'uppercase',
};

const Heading = (props) => {
  const { children, type, ...passedProps } = props;

  return (
    <Text
      fontSize={types[type].fontSize}
      fontFamily={types[type].fontFamily}
      fontWeight={types[type].fontWeight}
      textTransform={types[type].textTransform}
      letterSpacing={types[type].letterSpacing}
      lineHeight={types[type].lineHeight}
      {...passedProps}
    >
      {children}
    </Text>
  );
};

Heading.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf([
    'H1',
    'H2',
    'H3',
    'H4',
    'H4.1',
    'H5',
    'H5.1',
    'H6',
    'H7',
    'H8',
    'H9',
  ]).isRequired,
  color: PropTypes.string,
};

Heading.defaultProps = {
  color: 'black',
};

export default Heading;
