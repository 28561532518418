import types from './types';

export default {
  getDSMRequested: () => ({
    type: types.GET_DSM_REQUESTED,
  }),
  getDSMSucceeded: (data) => ({
    type: types.GET_DSM_SUCCEEDED,
    data,
  }),
  getDSMFailed: (error) => ({
    type: types.GET_DSM_FAILED,
    error,
  }),
  resetDSMRequested: () => ({
    type: types.RESET_DSM_REQUESTED,
  }),
  addStatusMessageRequested: (data) => ({
    type: types.ADD_STATUS_MESSAGE_REQUESTED,
    data,
  }),
  addStatusMessageSucceeded: (data) => ({
    type: types.ADD_STATUS_MESSAGE_SUCCEEDED,
    data,
  }),
  addStatusMessageFailed: (error) => ({
    type: types.ADD_STATUS_MESSAGE_FAILED,
    error,
  }),
  handleHideStayConnected: () => ({
    type: types.HIDE_STAY_CONNECTED_MESSAGE,
  }),
};
