/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedFlex,
  Data,
} from '../../coreui';

import Step from './Step';
import Filler from './Filler';

const StepCounter = ({
  steps,
  activeStep,
  ...props
}) => {
  return (
    <>
      {
        steps.filter((item, i) => activeStep >= i)
          .reverse()
          .slice(0, 1)
          .map((item) => (
            <Data
              type="D4"
              m={0}
              letterSpacing={3}
              display={['block', null, 'none']}
              color="blue.0"
              mt={[28, 0]}
              mb={10}
            >
              {item.label}
            </Data>
          ))
      }
      <ExtendedFlex
        flexDirection="row"
        align="center"
        w={1}
        {...props}
      >
        {
          steps.map((item, i) => (
            <React.Fragment
              key={`step-${item.label}`}
            >
              {
                i > 0 &&
                <Filler
                  active={activeStep >= i}
                />
              }
              <Step
                active={activeStep >= i}
                count={i + 1}
              >
                {item.label}
              </Step>
            </React.Fragment>
          ))
        }
      </ExtendedFlex>
    </>
  );
};

StepCounter.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.number,
};

export default StepCounter;
