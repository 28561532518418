/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import styled from 'styled-components';
import { detect } from 'detect-browser';

import {
  flex,
  flexDirection,
} from 'styled-system';

import {
  Data,
} from '../../coreui';

const StyledRadioGroup = styled(RadioGroup)`
  && {
    ${flex}
    ${flexDirection}
  }
`;

class RadioGroupInline extends React.Component {
  constructor(props) {
    super(props);
    const browser = detect();
    this.state = {
      valid: null,
      browser: browser.name === 'ie' ? 'ie' : 'notie',
    };
    this.handleOnValid = this.handleOnValid.bind(this);
    this.handleOnInvalid = this.handleOnInvalid.bind(this);
  }

  handleOnValid(evt) {
    evt.preventDefault();
    this.setState({
      valid: true,
    });
  }

  handleOnInvalid(evt) {
    evt.preventDefault();
    this.setState({
      valid: false,
    });
  }

  render() {
    const {
      children,
      spaceBetween,
      inputProps,
      ...props
    } = this.props;

    let counter = 0;
    return (
      <>
        <StyledRadioGroup
          display="flex"
          flexDirection={['column', null, 'row']}
          {...props}
        >
          {
            React.Children.map(children, (child) => {
              if (!React.isValidElement(child)) {
                return child;
              }

              const {
                type: {
                  displayName,
                },
                props: {
                  radioProps = {},
                  value = counter,
                },
              } = child;
              const {
                inputRef,
              } = radioProps;
              if (displayName === 'RadioLabel') {
                counter += 1;
                return React.cloneElement(child, {
                  key: value,
                  py: [15, null, 22, null, 27],
                  mb: ['1em', null, 0],
                  inputProps,
                  radioProps: {
                    ...radioProps,
                    inputRef: (ref) => {
                      if (ref) {
                        if (this.state.browser === 'notie') {
                          ref.addEventListener('valid', this.handleOnValid);
                        }
                        ref.addEventListener('invalid', this.handleOnInvalid);
                      }
                      return inputRef ? inputRef(ref) : undefined;
                    },
                  },
                  flex: '1 0 auto',
                  bg: 'gray.0',
                  ...(counter > 1 ? { ml: spaceBetween } : {}),
                  ...child.props,
                });
              }
              return child;
            })
          }
        </StyledRadioGroup>
        {
          this.state.valid === false &&
          <Data
            type="D4"
            display="block"
            my={2}
            color="red"
          >
            Please select a value.
          </Data>
        }
      </>
    );
  }
}

RadioGroupInline.propTypes = {
  children: PropTypes.array,
  inputProps: PropTypes.object,
  spaceBetween: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf([
      PropTypes.number,
      PropTypes.string,
    ]),
  ]),
};

RadioGroupInline.defaultProps = {
  spaceBetween: [null, null, '1em'],
};

export default RadioGroupInline;
