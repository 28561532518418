/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import withPortal from '../../withPortal';
import AutoInvestConfirmation from './AutoInvestConfirmation';

const componentRoot = typeof document !== 'undefined' ? document.getElementById('autoinvest-confirmation-root') : null;

const AutoInvestConfirmationWithPortal = (props) => (
  <AutoInvestConfirmation {...props} />
);

export default withPortal(
  AutoInvestConfirmationWithPortal,
  componentRoot,
  {
    noRootNoRender: true,
  },
);
