/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import Slider from 'react-slick';

import {
  Button,
  ExtendedBox,
  Arrow,
} from '..';

class CarouselRecentInvestments extends React.Component {
  constructor(props) {
    super(props);

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.pauseCarousel = this.pauseCarousel.bind(this);

    this.state = { // Carousel settings change on click, so lives in state
      settings: {
        dots: false,
        infinite: true,
        speed: 1000,
        variableWidth: true, // This displays as much as there is space for.
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnHover: false,
        onSwipe: this.pauseCarousel, // Any form of navigation should pause, not just arrows
      },
    };
  }

  pauseCarousel() {
    this.setState((state) => ({
      settings: {
        ...state.settings,
        autoplay: false,
      },
    }));
  }

  next() {
    this.slider.slickNext();
    this.pauseCarousel();
  }

  previous() {
    this.slider.slickPrev();
    this.pauseCarousel();
  }

  render() {
    const {
      children,
    } = this.props;

    return (
      <ExtendedBox position="relative" height={['450px', '475px', '500px', '525px', '625px']}>
        <Slider ref={(c) => { this.slider = c; }} {...this.state.settings} >
          {children}
        </Slider>
        <Button
          onClick={this.previous}
          position="absolute"
          bottom={[0, 0, -35, -40, -50]}
          left="50%"
          zIndex="2"
          ml={[-35, -40, -35, -40, -50]} // Accounts for width at various breakpoints
          bg="transparent"
          border="none" // Prevents browser defaulting styles on to button.
          outline="none" // Prevents browser defaulting styles on to button.
        >
          <Arrow
            direction="left"
            size="large"
          />
        </Button>
        <Button
          onClick={this.next}
          position="absolute"
          bottom={[0, 0, -35, -40, -50]}
          left="50%"
          zIndex="2"
          ml={20}
          bg="transparent"
          border="none" // Prevents browser defaulting styles on to button.
          outline="none" // Prevents browser defaulting styles on to button.
        >
          <Arrow
            direction="right"
            size="large"
          />
        </Button>
      </ExtendedBox>
    );
  }
}

CarouselRecentInvestments.propTypes = {
  children: PropTypes.any,
};

export default CarouselRecentInvestments;
