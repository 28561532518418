import React from 'react';

import ArticleCardPromo from '../components/ArticleCardPromo';

const section3FactoryFactory = (props) => {
  const {
    promo,
  } = props;

  const section3Factory = () => {
    return (
      <ArticleCardPromo
        promo={promo}
      />
    );
  };

  return section3Factory;
};

export default section3FactoryFactory;
