import React from 'react';
import { fetchInvestmentMetricFromData, parseSmartButton } from './helper';

export const NO_TOOLTIP = '';
export const ACTIVE_INVESTMENT_TOTALS_TOTAL_INVESTED = 'Total amount invested by you in transactions that are still active, as well as any reinvested distributions. Amount does not include committed capital for an investment where final payment has not yet been processed.';
export const ACTIVE_INVESTMENT_TOTALS_TOTAL_DISTRIBUTIONS = 'All amounts distributed to you on transactions that are still active, even if you chose to automatically reinvest them.';
export const ACTIVE_INVESTMENT_TOTALS_REIT_RETURN = 'Annual(ized) weighted average return on all active REIT investments. The return calculation for each REIT investment takes into account both distributions and changes in price per share. Each investment is weighted, taking into account its size and the time it has been outstanding.';
export const ACTIVE_INVESTMENT_TOTALS_PRIVATE_PLACEMENT_CASH_ON_CASH = 'Annual(ized) weighted average cash on cash return on all active private placement investments. Each investment is weighted taking into account its size and the time it has been outstanding. ';

export const ACTIVE_INVESTMENT_REITS_REIT_RETURN = 'Annual(ized) weighted average total return on all of your investments in this REIT. The return calculation takes into account cash distributions, reinvested distributions, and changes in price per share, and does not take into account repurchase discounts or costs. Each investment is weighted, taking into account its size and the time it has been outstanding.';
export const ACTIVE_INVESTMENT_REITS_CURRENT_INVESTMENT_VALUE = 'The product of the number of REIT shares that you currently own and their most recent available Net Asset Value (NAV) per share. This does not take into account any repurchase discounts or costs. Please note that the calculation of NAV includes estimates. Review the terms of the Offering Circular for more information.';
export const ACTIVE_INVESTMENT_GENERAL_MOGULREITI_CURRENT_INVESTMENT_VALUE = 'The product of the number of REIT shares that you currently own and their most recent available Net Asset Value (NAV) per share. This does not take into account any repurchase discounts or costs. Please note that the calculation of NAV includes estimates and NAV will be recalculated quarterly. Review the terms of the Offering Circular for more information.';

// Using distributrion yield to date
export const ACTIVE_INVESTMENT_REITS_REIT_DISTRIBUTION_RATE_ANNUALIZED = 'The REIT’s annual(ized) return to its investors. Please note that this figure represents the return to investors since inception and therefore may be different than your return on the REIT.';
export const ACTIVE_INVESTMENT_REITS_NAV_PER_SHARE = 'The goal of the Net Asset Value (NAV) calculation is to provide a reasonable estimate of the value of the REIT’s common shares. This valuation is subject to a number of subjective judgments and assumptions. Review the terms of the Offering Circular for more information.';
export const ACTIVE_INVESTMENT_GENERAL_MOGULREITI_NAV_PER_SHARE = 'The goal of the Net Asset Value (NAV) calculation is to provide a reasonable estimate of the value of the REIT’s common shares. This valuation is subject to a number of subjective judgments and assumptions and will be recalculated quarterly. Review the terms of the Offering Circular for more information.';
export const ACTIVE_INVESTMENT_REITS_CURRENT_TOTAL_INVESTED = 'Total amount invested in the REIT by you, as well as any reinvested REIT distributions.';
export const ACTIVE_INVESTMENT_REITS_CASH_DISTRIBUTIONS_PAID = 'Distributions represent the cash distributions authorized and distributed by the Company and may be inclusive of returned principal.';
export const ACTIVE_INVESTMENT_REITS_DRIP = "All funds sent to investors during the year will initially be classified as distributions. After year end, earnings and profits calculations will be performed to determine the break out between income and return of capital and will be reported through a Form 1099.<br><br>By selecting to participate in RealtyMogul.com's Distribution Reinvestment Plan (DRIP), you are agreeing to monitor and notify RealtyMogul.com in advance of the event that your continued participation in the DRIP would cause you to exceed applicable qualified purchaser limits as set forth in Regulation A of the Securities Act, and that you will terminate your participation in the DRIP.";
export const ACTIVE_INVESTMENT_REITS_DISTRIBUTIONS_REINVESTED = 'Total dollar value of distributions that were used to purchase new shares at the time of reinvestment. In the event that shares marked as reinvested distributions are redeemed, the redeemed dollar amount will be deducted from this value.';

export const ACTIVE_INVESTMENT_PRIVATE_PLACEMENT_CASH_ON_CASH = 'Annual(ized) cash on cash return for each individual private placement investment. The cash on cash return metric represents the ratio of annual distributions you received to your investment amount. Please note that we use the transaction escrow close date as the start date for this calculation.';
export const COMPLETED_INVESTMENT_TOTALS_TOTAL_INVESTED = 'Total amount invested by you in transactions that are completed.';
export const COMPLETED_INVESTMENT_TOTALS_TOTAL_RETURNED = 'All amounts distributed to you on transactions that are completed.';
export const COMPLETED_INVESTMENT_TOTALS_TOTAL_ANNUALIZED_RETURN = 'Weighted average return on all completed transactions. Each investment is weighted, taking into account its size and the time was outstanding.';

export const COMPLETED_INVESTMENT_PRIVATE_PLACEMENTS_ANNUALIZED_RETURN = 'Actual annual(ized) return on each of your investments that have been completed. Please note that we use the transaction escrow close date as the start date for this calculation.';

export function getReitDataSummaryFromJson(summaryJson) {
  return {
    title: 'REITs',
    items: {
      totalInvested: fetchInvestmentMetricFromData(summaryJson, 'totalInvested', 'Total Invested', 'currency', 'medium', {}),
      currentValue: fetchInvestmentMetricFromData(summaryJson, 'currentValue', 'Current Value', 'currency', 'medium', {}),
      portfolioAllocation: fetchInvestmentMetricFromData(summaryJson, 'portfolioAllocation', 'Portfolio Allocation', 'percentage', 'medium', {}),
    },
  };
}

export function constructReitOverviewFromJson(reitObjJson) {
  // RTMG-4595: MogulREIT I needs it's own NAV label..
  let currentNavLabel = 'NAV per Share';
  let tooltipInvestmentValue = ACTIVE_INVESTMENT_REITS_CURRENT_INVESTMENT_VALUE;
  let tooltipNav = ACTIVE_INVESTMENT_REITS_NAV_PER_SHARE;
  if (reitObjJson.nid === 193744) {
    currentNavLabel = 'NAV per Share<br><a href="/investment-opportunity/193744?qt-io_fund_details=5#navfaq">Learn More</a>';
    tooltipInvestmentValue = ACTIVE_INVESTMENT_GENERAL_MOGULREITI_CURRENT_INVESTMENT_VALUE;
    tooltipNav = ACTIVE_INVESTMENT_GENERAL_MOGULREITI_NAV_PER_SHARE;
  }

  return {
    currentInvestmentValue: fetchInvestmentMetricFromData(reitObjJson, 'currentInvestmentValue', 'Current Investment Value', 'currency', '', { tooltip: tooltipInvestmentValue }),
    totalShares: fetchInvestmentMetricFromData(
      reitObjJson,
      'totalShares',
      'Total Shares',
      'noFormat',
      '',
      {},
    ),
    currentNav: fetchInvestmentMetricFromData(reitObjJson, 'currentNav', currentNavLabel, 'currency', '', { tooltip: tooltipNav }),
    distributionYieldToDate: fetchInvestmentMetricFromData(reitObjJson, 'distributionYieldToDate', 'REIT Distribution Rate, Annualized', 'percentage', '', { tooltip: ACTIVE_INVESTMENT_REITS_REIT_DISTRIBUTION_RATE_ANNUALIZED }),
    currentTotalInvested: fetchInvestmentMetricFromData(reitObjJson, 'totalInvested', 'Current Total Invested', 'currency', '', { tooltip: ACTIVE_INVESTMENT_REITS_CURRENT_TOTAL_INVESTED }),
    cashInvested: fetchInvestmentMetricFromData(reitObjJson, 'cashInvested', 'Cash Invested', 'currency', '', { tooltip: ACTIVE_INVESTMENT_REITS_CURRENT_TOTAL_INVESTED }),
    distributionsReinvested: fetchInvestmentMetricFromData(reitObjJson, 'distributionsReinvested', 'Distributions Reinvested', 'currency', '', { tooltip: ACTIVE_INVESTMENT_REITS_DISTRIBUTIONS_REINVESTED }),
    cashDistributionsPaid: fetchInvestmentMetricFromData(reitObjJson, 'cashDistributionsPaid', 'Cash Distributions Paid', 'currency', '', { tooltip: ACTIVE_INVESTMENT_REITS_CASH_DISTRIBUTIONS_PAID }),
    cashRedeemed: fetchInvestmentMetricFromData(reitObjJson, 'cashRedeemed', 'Cash Redeemed', 'currency', '', {}),
    sharesRedeemed: fetchInvestmentMetricFromData(reitObjJson, 'sharesRedeemed', 'Number of Shares Redeemed', 'noFormat', '', {}),

  };
}

export function getReitsFromJson(reitsJson) {
  // Check if the user has any active share repurcase request.
  const hasShareRepurchaseWorkflow = reitsJson.reduce((accum, data) => (accum || data?.currentWorkflow !== undefined), false);

  return reitsJson.map((reit) => {
    const smartButton = parseSmartButton(reit.smartButton, reit.nid);
    const nodePath = `/node/${reit.nid}`;
    return {
      nid: reit.nid,
      title: reit.title,
      path: 'path' in reit ? reit.path : nodePath,
      imageUrl: 'imageUrl' in reit ? reit.imageUrl : '',
      dripSetting: reit.transactions.length > 0 ? reit.dripToggle : true,
      dripToggleDisabled: reit?.dripToggleDisabled || null,
      hasShareRepurchaseWorkflow,
      currentWorkflow: reit?.currentWorkflow,
      reinvestDistributionsURL: 'reinvestDistributionsURL' in reit ? reit.reinvestDistributionsURL : '',
      receiveCashDistributionsURL: 'receiveCashDistributionsURL' in reit ? reit.receiveCashDistributionsURL : '',
      distributionReinvestmentPlanUrl: 'distributionReinvestmentPlanUrl' in reit ? reit.distributionReinvestmentPlanUrl : '',
      distributionReinvestmentPlanTooltip: <span
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: ACTIVE_INVESTMENT_REITS_DRIP,
        }}
      />,
      viewAllTransactionsUrl: 'viewAllTransactionsUrl' in reit ? reit.viewAllTransactionsUrl : '',
      smartButtonText: smartButton.text,
      smartButtonMessageText: smartButton.content,
      smartButtonUrl: (smartButton.url) ? smartButton.url : undefined,
      overview: constructReitOverviewFromJson(reit),
      transactions: reit.transactions,
      isPledge: reit.isPledge,
      foes: reit.foes || [],
    };
  });
}

export function parseReitFromJson(jsonData) {
  const { reitData } = jsonData;

  return {
    summary: getReitDataSummaryFromJson(reitData.summary),
    reits: getReitsFromJson(reitData.reits),
  };
}

export default {
  parseReitFromJson,
};
