import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Loader,
} from '../../coreui';

const DashboardLoader = ({
  children = null,
  loaded,
}) => {
  if (loaded) {
    return children;
  }
  return (
    <ExtendedFlex
      position="relative"
      height="40vh"
      minHeight={300}
    >
      <Loader />
    </ExtendedFlex>
  );
};

DashboardLoader.propTypes = {
  loaded: PropTypes.bool,
  children: PropTypes.object,
};

export default DashboardLoader;
