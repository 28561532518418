import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Hero,
  UnlockOpportunities,
  ScrollToTopOnMount,
  Numeral,
} from '../coreui';

import SimpleSteps from './simpleSteps';
import BeyondTraditionalInvesting from './BeyondTraditionalInvesting';
import HowWeSelect from './HowWeSelect';
import Highlighted3ColCallout from '../components/Highlighted3ColCallout';
import BrowseLink from '../components/BrowseLink';
import QueueFAQItems from '../components/queueFAQItems';
import Section from '../ioDetails/components/section';
import heroBannerImage from '../assets/HIW_hero.jpg';

class HowItWorks extends React.Component {
  // TODO -- Make this constructor not pointless or revert to stateless function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      user,
      apartmentUnitsFinanced,
      totalPropertyValueFinanced,
      totalMembers,
    } = this.props;

    const isLoggedIn = user.isLoggedIn;
    const calloutTitle = 'THE PLATFORM’S REACH';
    const calloutSubTitle = 'On the RealtyMogul platform, institutional-quality deals are offered to Members seeking to build wealth through commercial real estate. This straightforward approach focuses on offering smart diversification strategies, generating cash flow, and creating value in office, retail, and apartment buildings';
    const calloutHighlights = [
      {
        highlight: (totalPropertyValueFinanced) ?
          <Numeral value={totalPropertyValueFinanced} format="$0.0a+" /> :
          null,
        description: 'PROPERTY VALUE FINANCED VIA THE REALTYMOGUL PLATFORM',
      },
      {
        highlight: (totalMembers) ? <Numeral value={totalMembers} format="0a+" /> : null,
        description: 'REGISTERED REALTYMOGUL PLATFORM MEMBERSHIP BASE',
      },
      {
        highlight: (apartmentUnitsFinanced) ? <Numeral value={apartmentUnitsFinanced} format="0,0+" /> : null,
        description: 'APARTMENT UNITS FINANCED ON THE REALTYMOGUL PLATFORM',
      },
    ];
    return (
      <>
        <ScrollToTopOnMount />
        <Hero
          image={heroBannerImage}
          header="Commercial Real Estate, Simplified"
          bgPosition="left"
        />
        <SimpleSteps
          isLoggedIn={isLoggedIn}
        />
        <BeyondTraditionalInvesting
          isLoggedIn={isLoggedIn}
        />
        <HowWeSelect />
        <Section
          bg="gray.0"
        >
          <Highlighted3ColCallout
            title={calloutTitle}
            subTitle={calloutSubTitle}
            highlights={calloutHighlights}
            cta={<BrowseLink isLoggedIn={isLoggedIn} />}
          />
        </Section>
        <QueueFAQItems
          title="Popular Questions"
          queueName="popular_questions"
        />
        <UnlockOpportunities
          isLoggedIn={isLoggedIn}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.howItWorks.user,
  totalMembers: state.howItWorks.totalMembers,
  totalPropertyValueFinanced: state.howItWorks.totalPropertyValueFinanced,
  apartmentUnitsFinanced: state.tokens.apartmentUnitCount,
});

HowItWorks.propTypes = {
  user: PropTypes.object,
  totalMembers: PropTypes.number,
  totalPropertyValueFinanced: PropTypes.number,
  apartmentUnitsFinanced: PropTypes.number,
};

export default connect(mapStateToProps)(HowItWorks);
