import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import { callApiCallback } from '../../../api';

export function* watchFlagToggleDataFlow(action) {
  try {
    const id = action.opts.id;
    const response = yield call(callApiCallback, action.opts.callback);
    yield put(
      actions.flagApiActionSucceeded(
        {
          ...response.data,
          id,
        },
      ),
    );
  } catch (error) {
    yield put(
      actions.flagApiActionFailed(
        error,
      ),
    );
  }
}

export function* watchFlagToggleRequested() {
  yield takeLatest(
    types.FLAG_TOGGLE_REQUESTED,
    watchFlagToggleDataFlow,
  );
}

export default watchFlagToggleRequested;
