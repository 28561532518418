import {
  takeLatest,
  call,
  all,
  put,
} from 'redux-saga/effects';
import noop from 'lodash/noop';

import types from '../types';
import actions from '../actions';
import appActions from '../../appWrapper/actions';

import { postGettingToKnowYouApi } from '../../api';

export function* postDataFlow(action) {
  const {
    data,
    promise: {
      resolve = noop,
      reject = noop,
    } = {},
  } = action;

  try {
    const {
      data: responseData,
    } = yield call(postGettingToKnowYouApi, data);

    yield all([
      call(resolve, responseData),
      put(
        actions.userGettingToKnowYouSucceeded(responseData),
      ),
      !data.draft ? put(
        appActions.getUserDataRequested(),
      ) : 0,
    ].filter((i) => i));
  } catch (error) {
    yield all([
      call(reject, error),
      put(
        actions.userGettingToKnowYouFailed(error),
      ),
    ]);
  }
}

export function* watchUserGettingToKnowYouRequested() {
  yield takeLatest(
    types.USER_GETTING_TO_KNOW_YOU_REQUESTED,
    postDataFlow,
  );
}

export default watchUserGettingToKnowYouRequested;
