import React from 'react';

import {
  Body,
  ExtendedFlex,
  Link,
} from '../../coreui';

import ProgressIndicator from './ProgressIndicator';

/*
 * This is not actually a component but a function used
 * specifically for React-table cells
 */
export const createStatusComponent = (row) => {
  return (
    <ExtendedFlex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      m={0}
      height="30px"
    >
      {
        row.value.value === 'uploaded' &&
        <Link
          type="L4"
          href={row.value.docURL}
          target="_blank"
        >
          Download
        </Link>
      }
      {
        row.value.value !== 'uploaded' &&
        <Body
          type="B5"
          m={0}
        >
          {row.value.value}
        </Body>
      }
      {
        row.value.value !== 'uploaded' &&
        <ProgressIndicator
          stage={row.value.optionNumber}
          w="45%"
        />
      }
    </ExtendedFlex>
  );
};

export default {
  createStatusComponent,
};
