import React, { useEffect, useState } from 'react';
import InvestFlowSpecificNetWorth from './InvestFlowSpecificNetWorth';

import Portal from './Portal';

const InvestFlowIncomeNetWorthDrupalWrapper = () => {
  const [portalId, setPortalId] = useState(null);

  // Determine if we should be showing Plaid.
  const drupal = typeof window !== 'undefined' && window?.Drupal;
  useEffect(() => {
    if (drupal) {
      // Get the initial setting from Drupal.
      // eslint-disable-next-line camelcase
      const initialPlaidId = drupal?.settings?.rm_invest_flow?.isq_income_net_worth_portal;
      setPortalId(initialPlaidId);
    }
  }, [drupal, portalId]);

  if (!portalId) {
    return null;
  }

  return (
    <Portal id={portalId}>
      <InvestFlowSpecificNetWorth />
    </Portal>
  );
};

export default InvestFlowIncomeNetWorthDrupalWrapper;
