import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  AccordionWithTopic,
  ScrollToTopOnMount,
} from '../coreui';

import actions from './actions';

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  UNSAFE_componentWillMount() {
    this.props.getFaqDataRequested();
  }

  render() {
    const { topics } = this.props;

    return (
      <ExtendedBox
        w={1}
        bg="gray.0"
      >
        <ScrollToTopOnMount />
        <ExtendedFlex
          py={40}
          flexDirection="column"
          justify="center"
          align="center"
          w={1}
        >
          <Heading
            type="H3"
            textTransform="uppercase"
            textAlign="center"
          >
            Frequently asked questions
          </Heading>
          <ExtendedBox
            w={1}
            px="10%"
            boxSizing="border-box"
          >
            <AccordionWithTopic
              items={topics}
              contentIsHtml
            />
          </ExtendedBox>
        </ExtendedFlex>
      </ExtendedBox>
    );
  }
}

const mapStateToProps = (state) => ({
  topics: state.faq.topics,
});

const mapDispatchToProps = (dispatch) => ({
  getFaqDataRequested: () => {
    dispatch(actions.getFaqDataRequested());
  },
});

Faq.propTypes = {
  topics: PropTypes.array,
  getFaqDataRequested: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
