/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import animateScrollTo from 'animated-scroll-to';

import {
  withRouter,
} from 'react-router-dom';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  ModalWithOverlay,
} from '../../../coreui';

import actions from '../../actions';

import PendingSummary from '../../components/PendingSummary';
import WaitlistModal from '../../components/WaitlistModal';
import Watchlist from '../../components/Watchlist';
import IOCardPending from '../../components/IOCardPending';
import IOCardPledged from '../../components/IOCardPledged';

class PendingInvestmentsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.scrollToPending = this.scrollToPending.bind(this);

    // React 16.2 and earlier does not support ref forwarding. So, we simulate
    // it by giving the passed in ref function access to this.
    if (this.props.pendingRef) {
      this.props.pendingRef(this);
    }

    // Keep track of the ref for the pending components so that we can scroll
    // to it when requested.
    this.pendingInvestments = null;
    this.setPendingRef = (element) => {
      this.pendingInvestments = element;
    };

    this.waitlistTable = React.createRef();
  }

  componentWillUnmount() {
    if (this.props.modalVisible) {
      this.props.handleCloseModal();
    }
  }

  scrollToPending() {
    animateScrollTo(this.pendingInvestments, {
      passive: true,
    });
  }

  render() {
    const {
      pending,
      pledges,
      waitlist,
      watchlist,
      modalContent,
      modalVisible,
      handleCloseModal,
      isApp,
      frontendPrefix,
    } = this.props;

    return (
      <ExtendedFlex
        flexDirection="column"
        w={1}
        maxWidth={1700}
        px={50}
        alignItems="flex-start"
      >
        <ExtendedFlex
          flexDirection="column"
          justifyContent="flex-start"
          w={[1, '92%']}
          mx="auto"
        >
          <PendingSummary
            data={{
              pendingData: pending,
              pledgedData: pledges,
            }}
          />
          <Heading
            type="H9"
          >
            Pending Investments
          </Heading>
          {
            pending.investments?.length !== 0 ?
              <ExtendedBox>
                {pending.investments?.map((io) => (
                  <IOCardPending data={{ ...io, ...pending }} />
                ))}
              </ExtendedBox> :
              <Heading
                type="H7"
                my={24}
              >
                You have no pending investments at this time.
              </Heading>
          }
          <Heading
            type="H9"
            mt={48}
          >
            Pledged Investments
          </Heading>
          {
            pledges.investments?.length !== 0 ?
              <ExtendedBox>
                {pledges.investments?.map((io) => (
                  <IOCardPledged data={{ ...io }} />
                ))}
              </ExtendedBox> :
              <Heading
                type="H7"
              >
                You have no pledged items.
              </Heading>
          }
          {
            // We wrap our component in a plain div so that we can pass a ref to
            // to it. This allows the WaitList modal to have access to the node
            // so that we can scroll to it if the user clicks the appropriate
            // button.
          }
          <ExtendedFlex
            flexDirection="column"
            justifyContent="flex-start"
            width={1}
            mb={64}
          >
            <Heading
              type="H9"
              my={48}
            >
              Waitlist
            </Heading>
            {
              waitlist.investments?.length !== 0 ?
                <ExtendedBox>
                  {waitlist.investments?.map((io) => (
                    <IOCardPending data={{ ...io }} />
                  ))}
                </ExtendedBox> :
                <Heading
                  type="H7"
                >
                  You have no waitlist items.
                </Heading>
            }
          </ExtendedFlex>
          <Watchlist
            {...watchlist}
            isApp={isApp}
            frontendPrefix={frontendPrefix}
          />
        </ExtendedFlex>
        <ModalWithOverlay
          isVisible={modalVisible}
          handleClick={handleCloseModal}
        >
          {
            modalContent === 'waitlist' &&
            <WaitlistModal
              handleClick={handleCloseModal}
              waitListRef={this.waitlistTable}
            />
          }
        </ModalWithOverlay>
      </ExtendedFlex>
    );
  }
}

// TODO: HERE. Implement flow
PendingInvestmentsContainer.propTypes = {
  pendingRef: PropTypes.func,
  location: PropTypes.object,
  pending: PropTypes.object,
  pledges: PropTypes.object,
  waitlist: PropTypes.object,
  watchlist: PropTypes.object,
  modalContent: PropTypes.string,
  modalVisible: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    pending: state.dashboard.pendingData.pendingData,
    pledges: state.dashboard.pendingData.pledgedData,
    waitlist: state.dashboard.data.waitListData,
    watchlist: state.dashboard.data.watchListData,
    modalContent: state.dashboard.modalContent,
    modalVisible: state.dashboard.modalVisible,
    isApp: state.app.isApp,
    frontendPrefix: state.app.frontendPrefix,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleCloseModal: () => {
      dispatch(actions.pendingDashboardModalClose());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PendingInvestmentsContainer),
);
