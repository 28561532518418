import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { Auth } from 'aws-amplify';

import types from '../types';
import actions from '../actions';

import { setUserRegisterApi } from '../../api';

export const getCurrentSession = async () => {
  return Auth.currentSession();
};

export const getAuthUser = async () => {
  return Auth.currentAuthenticatedUser();
};

export function* getUserDataFlow(action) {
  try {
    const {
      data,
    } = action;

    const {
      idToken: {
        jwtToken: token,
      },
    } = yield call(getCurrentSession);

    const result = yield call(setUserRegisterApi, token, data);
    yield put(
      actions.userRegisterSucceeded(result?.data),
    );

    // Fire off the accredited event if the user passed self accreditation.
    if (result?.data?.data?.accredited) {
      yield put(
        actions.accredRegisterSucceeded(result.data),
      );
    }
  } catch (error) {
    yield put(
      actions.userLoginFailed(error),
    );
  }
}

export function* watchUserRegisterRequested() {
  yield takeLatest(
    types.USER_REGISTER_REQUESTED,
    getUserDataFlow,
  );
}

export default watchUserRegisterRequested;
