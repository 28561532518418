import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';
import stubResponse from '../data';

import { getFaqApi } from '../../api';

export const mockApi = () => ({
  data: stubResponse,
});

export function* getFaqDataFlow() {
  try {
    const response = yield call(getFaqApi);
    yield put(
      actions.getFaqDataSucceeded(response.data),
    );
  } catch (error) {
    yield put(
      actions.getFaqDataFailed(error),
    );
  }
}

export function* watchGetFaqDataRequested() {
  yield takeLatest(
    types.GET_FAQ_DATA_REQUESTED,
    getFaqDataFlow,
  );
}

export default watchGetFaqDataRequested;
