/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
  StyledTable as CoreStyledTable,
  StyledButton,
} from '../../../coreui';

const StyledTable = (props) => {
  const {
    title,
    tooltip,
    columns,
    data,
    showPagination,
    additionalTableProps,
    defaultSorted,
    noDataText,
    pageSize,
    ...additionalProps
  } = props;
  const calculatedPageSize = 'pageSize' in props ? pageSize : data.length;

  return (
    <ExtendedBox
      w={1}
      maxWidth={['100vw', '100vw', '100vw', 1700]}
      boxSizing="border-box"
      px={50}
      mx="auto"
      overflowX="auto"
      {...additionalProps}
    >
      <ExtendedBox
        w={['auto', 'auto', 'auto', 1]}
        mx={[-50, -50, -50, 0, 0]}
      >
        {
          title &&
          <ExtendedFlex
            mb={50}
            justifyContent="flex-start"
            align="center"
            width={[1, 1, 1, '92%']}
            px={[50, 50, 50, 0, 0]}
            mx="auto"
          >
            <Heading
              type="H9"
              my={0}
              {...(tooltip ? {
                mr: 10,
              } : {})}
            >
              {title}
            </Heading>
            {tooltip}
          </ExtendedFlex>
        }
        <ExtendedBox
          w={1}
        >
          <CoreStyledTable
            data={data}
            columns={columns}
            sortable
            showPagination={showPagination}
            pageSize={calculatedPageSize}
            width="100%"
            defaultSorted={defaultSorted}
            className="-highlighting"
            {...(noDataText ? {
              NoDataComponent: () => { return null; },
            } : {})}
            PreviousComponent={({ disabled, children, onClick }) => (
              <StyledButton type="L3" disabled={disabled} onClick={onClick} >{children}</StyledButton>
            )}
            NextComponent={({ disabled, children, onClick }) => (
              <StyledButton type="L3" disabled={disabled} onClick={onClick} >{children}</StyledButton>
            )}
            pageText={<Body type="B1">Page</Body>}
            ofText={<Body type="B1">of</Body>}
            rowsText={<Body type="B1">rows</Body>}
            getTrProps={() => {
              return {
                style: {
                  alignItems: 'center',
                },
              };
            }}
            {...additionalTableProps}
          />
        </ExtendedBox>
        {
          data.length === 0 && noDataText &&
          <ExtendedFlex
            w="calc(92% - 30px)"
            px={[0, 50, 50, 0, 0]}
            mx="auto"
            flexDirection="column"
            align="flex-start"
          >
            {
              typeof noDataText === 'string' &&
              <Body
                type="B1"
              >
                {noDataText}
              </Body>
            }
            {
              typeof noDataText !== 'string' && noDataText
            }
          </ExtendedFlex>
        }
      </ExtendedBox>
    </ExtendedBox>
  );
};

StyledTable.propTypes = {
  title: PropTypes.node,
  tooltip: PropTypes.node,
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.array,
  pageSize: PropTypes.number,
  defaultSorted: PropTypes.array,
  showPagination: PropTypes.bool,
  noDataText: PropTypes.node,
  additionalTableProps: PropTypes.object,
};

StyledTable.defaultProps = {
  defaultSorted: [{ id: 'date', desc: true }],
  showPagination: false,
};

export default StyledTable;
