import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import actions from '../actions';
import Loading from './Loading';

class LogoutHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    this.props.userLogoutRequested();
  }

  render() {
    return (
      <>
        <Loading />
        <Redirect
          to="/"
          push={false}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  userLogoutRequested: () => {
    dispatch(actions.userLogoutRequested());
  },
});

LogoutHandler.propTypes = {
  userLogoutRequested: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(LogoutHandler);
