/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  Hero,
  ExtendedFlex,
  ExtendedBox,
  ScrollToTopOnMount,
} from '../coreui';

import heroBannerImage from './assets/img_hero_GetFin.jpg';
import RecentTransactions from './RecentTransactions';
import FinancingAdvantage from './FinancingAdvantage';
import RelatedQueueContent from '../knowledgeCenter/components/RelatedQueueContent';
import FlexibleOptions from './FlexibleOptions';
import ProjectFinancing from './ProjectFinancing';
import StaticContentData from './data';
import FinancingOptions from './components/financingOptions';
import ImageAndCopy from './components/imageAndCopy';
import MRIImage from '../assets/img_MRI.jpg';
import MRIIImage from '../assets/img_MRII.jpg';
import PrivatePlacementImage from './assets/img_PP.jpg';

class GetFinancing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <ExtendedBox>
        <ScrollToTopOnMount />
        <ExtendedFlex>
          <Hero
            image={heroBannerImage}
            header="LAUNCH YOUR NEXT VENTURE"
            subHeader="FOR REAL ESTATE COMPANIES ONLY"
            bgPosition="74%"
          />
        </ExtendedFlex>
        <ProjectFinancing />
        <FinancingAdvantage />
        <FlexibleOptions />
        <ImageAndCopy
          {...StaticContentData.MRF}
          image={MRIImage}
        />
        <FinancingOptions
          {...StaticContentData.mogulreitI}
        />
        <ImageAndCopy
          {...StaticContentData.MRIIF}
          image={MRIIImage}
        />
        <FinancingOptions
          {...StaticContentData.mogulreitII}
        />
        <ImageAndCopy
          {...StaticContentData.PPF}
          image={PrivatePlacementImage}
        />
        <FinancingOptions
          {...StaticContentData.privatePlacement}
        />
        <RecentTransactions />
        <RelatedQueueContent
          title="Related Articles"
          queue="get_financing_articles"
        />
      </ExtendedBox>
    );
  }
}

export default GetFinancing;
