/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Heading,
  Body,
  Image,
  FlipCard,
} from '../../coreui';

import CloseIcon from '../../assets/icon_closeX_blk.svg';
import Divider from '../../components/Divider';

const Bio = ({
  image,
  name,
  title,
  bio,
  ...additionalProps
}) => {
  const boxShadow = 'box-shadow 0.3s linear';

  return (
    <ExtendedFlex
      m={7}
      {...additionalProps}
    >
      <FlipCard
        w={[315, 315, 315, 400, 525]}
        height={[375, 375, 460, 396, 520]}
        front={
          <ExtendedFlex
            align="center"
            justify="center"
            flexDirection="column"
            bg="gray.0"
            w={1}
            height="100%"
            cursor="pointer"
            hover
            hoverShadow
            hoverRemoveFilter
            transition={boxShadow}
          >
            <Image
              src={image}
              w={[117, 117, 117, 150, 196]}
              height={[117, 117, 117, 150, 196]}
              backfaceVisibility="visible"
            />
            <Heading
              type="H6"
              mt={[30, 30, 30, 45, 70]}
              mb={0}
              maxWidth="80%"
            >
              {name}
            </Heading>
            <Body
              type="B3"
              maxWidth="80%"
              textAlign="center"
            >
              {title}
            </Body>
          </ExtendedFlex>
        }
        back={
          <ExtendedFlex
            flexDirection="column"
            bg="gray.0"
            w={1}
            height="100%"
            pt={40}
            pb={20}
            px={40}
            boxSizing="border-box"
            boxShadow="0 6px 14px rgba(0,0,0,0.05), 0 6px 10px rgba(0,0,0,0.05)"
            hover
            hoverShadow
            transition={boxShadow}
          >
            <Image
              src={CloseIcon}
              position="absolute"
              top={20}
              right={35}
            />
            <Heading
              type="H6"
              m={0}
            >
              {name}
            </Heading>
            <Body
              type="B3"
              m={0}
            >
              {title}
            </Body>
            <Divider
              type="horizontal"
              my={25}
            />
            <Body
              type="B5"
            >
              {bio}
            </Body>
          </ExtendedFlex>
        }
      />
    </ExtendedFlex>
  );
};

Bio.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  bio: PropTypes.string,
};

export default Bio;
