import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  VisibilitySensor,
  Theme,
} from '..';

class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible: true,
      });
    }
  }

  render() {
    const {
      isVisible,
    } = this.state;

    const {
      image, // NOTE! Expected dimension is 1920x674, per design. Dimensional stuff hardcoded!
      header,
      subHeader,
      bgPosition, // gets default of 'center center'
      bgOpacity,
      children,
    } = this.props;

    return (
      <ExtendedBox
        width={1}
        bg="black"
        position="relative"
        maxHeight={674}
        height={['32vh', '50vh']}
      >
        <ExtendedBox
          width={1}
          bgImage={image}
          bgRepeat="no-repeat"
          bgPosition={bgPosition}
          bg="black"
          rbgSize="cover"
          height={['32vh', '50vh']}
          maxHeight={674}
          opacity={bgOpacity}
        />
        <ExtendedFlex
          height={['32vh', '50vh']}
          maxHeight={674}
          flexDirection="column"
          justify="center"
          align="center"
          mx={[10, 20, 20, 20, 20]}
          my={['-32vh', '-50vh']}
          zIndex={10}
        >
          <VisibilitySensor
            callback={this.handleSensorChange}
          />
          <Heading
            type="H2"
            m={0}
            color="white"
            display="block"
            textAlign="center"
            maxWidth="1460px"
            opacity={isVisible ? '1' : '0'}
            transition={`opacity ${Theme.fadeInDelay}ms 0.3s linear`}
          >
            {header}
          </Heading>
          {
            subHeader &&
            <Heading
              type="H8"
              mt={[30, 40, 40, 40, 60]}
              color="white"
              display="block"
              textAlign="center"
              maxWidth="1460px"
              opacity={isVisible ? '1' : '0'}
              transition={`opacity ${Theme.fadeInDelay}ms 0.6s linear`}
            >
              {subHeader}
            </Heading>
          }
          {
            children &&
            <ExtendedBox
              mt={[30, 40, 40, 40, 60]}
              maxWidth="100%"
              opacity={isVisible ? '1' : '0'}
              transition={
                !subHeader ?
                  `opacity ${Theme.fadeInDelay}ms 0.6s linear` : `opacity ${Theme.fadeInDelay}ms 0.9s linear`
              }
            >
              {children}
            </ExtendedBox>
          }
        </ExtendedFlex>
      </ExtendedBox>
    );
  }
}

Hero.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  image: PropTypes.string,
  bgPosition: PropTypes.string,
  bgOpacity: PropTypes.string,
  children: PropTypes.node,
};

Hero.defaultProps = {
  bgPosition: 'center center',
  bgOpacity: '1',
};

export default Hero;
