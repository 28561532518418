/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';

import {
  ScrollToTopOnMount,
  ExtendedBox,
  ExtendedFlex,
  Formatters,
  Heading,
  Link,
  StyledButton,
  NavLink,
  Modal,
} from '../../coreui';

import dashboardActions from '../actions';

import TableHeader from '../components/TableHeader';
import StyledTable from '../components/StyledTable';
import DashboardNavLink from '../components/DashboardNavLink';
import DashboardTableCell from '../components/DashboardTableCell';
import FilterButtons from '../components/FilterButtons';
import DateRangeFilter from '../components/DateRangeFilter';
import InvestmentsFilterDropdown from '../components/InvestmentsFilterDropdown';
import InvestmentFilterButtons from '../components/InvestmentFilterButtons';
import FoeDropdown from '../components/FoeDropdown';
import { transactionsUrl, transactionsCompletedUrl, transactionsActiveUrl } from '../links';
import DashboardNotifications from '../components/DashboardNotifications';
import DashboardLoader from '../components/DashboardLoader';
import RedirectToLoginOnLoggedOut from '../../components/RedirectToLoginOnLoggedOut';

const TransactionsTableConfig = [
  {
    Header: <TableHeader>Date</TableHeader>,
    accessor: 'date',
    Cell: DashboardTableCell(Formatters.MMDDYYYY),
  },
  {
    Header: <TableHeader>Investment</TableHeader>,
    accessor: 'investment',
    Cell: DashboardTableCell(),
  },
  {
    Header: <TableHeader>Issuer</TableHeader>,
    accessor: 'issuer',
    Cell: DashboardTableCell(),
  },
  {
    Header: <TableHeader>Type</TableHeader>,
    accessor: 'typeOfTransaction',
    Cell: DashboardTableCell(),
  },
  {
    Header: <TableHeader>Account</TableHeader>,
    accessor: 'accountType',
    Cell: DashboardTableCell(),
  },
  {
    Header: <TableHeader>Shares</TableHeader>,
    accessor: 'shares',
    id: 'transactionsShares',
    Cell: DashboardTableCell(Formatters.SHARES),
  },
  {
    Header: <TableHeader>Purchase Price</TableHeader>,
    accessor: 'purchasePrice',
    Cell: DashboardTableCell(Formatters.DOLLARS_DECIMALS),
  },
  {
    Header: <TableHeader>Amount</TableHeader>,
    accessor: 'amount',
    id: 'pendingAmount',
    Cell: DashboardTableCell(Formatters.DOLLARS_DECIMALS),
  },
];

const NULL_FOE = {
  id: null,
  title: 'ALL ACCOUNTS',
};

// filtering
//
class TransactionsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFoe: (props.ownershipFilter && props.ownershipFilter.id) ?
        props.ownershipFilter : NULL_FOE,
      typeFilter: null,
      dateFilterStart: null,
      dateFilterEnd: null,
      investmentFilter: [],
      prevFilters: {},
      filterModalOpen: false,
    };

    this.dateRangeFilter = React.createRef();
    this.dateRangeFilterMobile = React.createRef();

    this.selectFoeOption = this.selectFoeOption.bind(this);
    this.setFilterType = this.setFilterType.bind(this);
    this.setFilterDate = this.setFilterDate.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.handleFilterModalToggle = this.handleFilterModalToggle.bind(this);
    this.setDateFilters = this.setDateFilters.bind(this);
    this.filter = this.filter.bind(this);
    this.filterByFoe = this.filterByFoe.bind(this);
    this.filterByType = this.filterByType.bind(this);
    this.filterByDate = this.filterByDate.bind(this);
    this.filterByInvestment = this.filterByInvestment.bind(this);
    this.addInvestmentFilter = this.addInvestmentFilter.bind(this);
    this.removeInvestmentFilter = this.removeInvestmentFilter.bind(this);
    this.selectTabFilterData = this.selectTabFilterData.bind(this);
    this.typeOptions = [
      { id: null, label: 'all', handleClick: () => this.setFilterType(null) },
      { id: 'Purchases', label: 'Purchases', handleClick: () => this.setFilterType('Purchases') },
      { id: 'Distributions', label: 'Distributions', handleClick: () => this.setFilterType('Distributions') },
      { id: 'Sales/Repurchases', label: 'Sales/Repurchases', handleClick: () => this.setFilterType('Sales/Repurchases') },
      { id: 'Auto Investments', label: 'Auto Investments', handleClick: () => this.setFilterType('Auto Investments') },
    ];
  }

  componentDidMount() {
    this.props.getDashboardApiData();
    this.props.getPendingData();
  }

  setFilterType(investmentType) {
    this.setState({ typeFilter: investmentType });
  }

  setFilterDate(startDate, endDate) {
    this.setState({
      dateFilterStart: startDate,
      dateFilterEnd: endDate,
    });
    this.setDateFilters(startDate, endDate);
  }

  setDateFilters(startDate, endDate) {
    this.dateRangeFilter.current.setDates(startDate, endDate);
    this.dateRangeFilterMobile.current.setDates(startDate, endDate);
  }

  selectFoeOption(foeObject) {
    this.setState({ selectedFoe: foeObject });
    this.props.setOwnershipFilter(foeObject);
  }

  handleFilterModalToggle(reset) {
    const state = {
      filterModalOpen: !this.state.filterModalOpen,
      prevFilters: {
        typeFilter: this.state.typeFilter,
        dateFilterStart: this.state.dateFilterStart,
        dateFilterEnd: this.state.dateFilterEnd,
        investmentFilter: this.state.investmentFilter,
      },
      ...(reset ? {
        ...this.state.prevFilters,
      } : {}),
    };
    this.setState(state);

    // Keep the date filters in sync.
    this.setDateFilters(this.state.dateFilterStart, this.state.dateFilterEnd);
  }

  addInvestmentFilter(title) {
    this.setState((prevState) => ({
      investmentFilter: [...prevState.investmentFilter, title],
    }));
  }

  removeInvestmentFilter(title) {
    this.setState((prevState) => ({
      investmentFilter: prevState.investmentFilter.filter((item) => item !== title),
    }));
  }

  resetFilters() {
    this.setState({
      typeFilter: null,
      dateFilterStart: null,
      dateFilterEnd: null,
      investmentFilter: [],
      filterModalOpen: false,
    });

    this.dateRangeFilter.current.resetDates();
    this.dateRangeFilterMobile.current.resetDates();
  }

  filterByFoe(data) {
    let filtered = data;
    if (this.state.selectedFoe !== NULL_FOE) {
      filtered = filtered.filter((x) => x.foeId === this.state.selectedFoe.id);
    }
    return filtered;
  }

  filterByType(data) {
    let filtered = data;
    if (this.state.typeFilter !== null) {
      filtered = filtered.filter((x) => x.category === this.state.typeFilter);
    }
    return filtered;
  }

  filterByDate(data) {
    let filtered = data;
    let dateFilter;
    const inputDateFormat = 'YYYY-MM-DD';
    const dateFormat = 'YYYY-MM-DD';
    if (
      this.state.dateFilterStart !== null &&
      this.state.dateFilterEnd !== null
    ) {
      dateFilter = (x) => {
        const itemDate = moment(x.date, dateFormat).toDate();
        const start = moment(this.state.dateFilterStart, inputDateFormat).toDate();
        const end = moment(this.state.dateFilterEnd, inputDateFormat).toDate();
        return (
          itemDate >= start &&
          itemDate <= end
        );
      };
    } else if (this.state.dateFilterStart !== null) {
      dateFilter = (x) => {
        const itemDate = moment(x.date, dateFormat).toDate();
        const start = moment(this.state.dateFilterStart, inputDateFormat).toDate();
        return itemDate >= start;
      };
    } else if (this.state.dateFilterEnd !== null) {
      dateFilter = (x) => {
        const itemDate = moment(x.date, dateFormat).toDate();
        const end = moment(this.state.dateFilterEnd, inputDateFormat).toDate();
        return itemDate <= end;
      };
    }
    if (dateFilter !== undefined) {
      filtered = filtered.filter(dateFilter);
    }
    return filtered;
  }

  filterByInvestment(data) {
    let filtered = data;
    if (this.state.investmentFilter.length !== 0) {
      filtered = filtered.filter((x) => this.state.investmentFilter.includes(x.investment));
    }
    return filtered;
  }

  filter(data) {
    let filtered = data;
    filtered = this.filterByFoe(filtered);
    filtered = this.filterByType(filtered);
    filtered = this.filterByDate(filtered);
    filtered = this.filterByInvestment(filtered);
    return filtered;
  }

  selectTabFilterData() {
    switch (this.props.location.pathname) {
      default:
      case transactionsUrl:
        return {
          data: this.filterByFoe(this.props.allData),
          exportUrl: this.props.allDataExportUrl,
        };

      case transactionsActiveUrl:
        return {
          data: this.filterByFoe(this.props.activeData),
          exportUrl: this.props.activeDataExportUrl,
        };

      case transactionsCompletedUrl:
        return {
          data: this.filterByFoe(this.props.completedData),
          exportUrl: this.props.completedExportUrl,
        };
    }
  }

  render() {
    const {
      data,
      exportUrl,
    } = this.selectTabFilterData();

    const filtered = this.filter(data);
    const uniqueInvestments = data.reduce((accum, item) => {
      if (!accum.includes(item.investment)) {
        accum.push(item.investment);
      }
      return accum;
    }, []).sort();

    const appliedInvestmentFilters = this.state.investmentFilter.map((item) => ({
      value: item,
      handleClick: () => this.removeInvestmentFilter(item),
    }));

    const adjustedOptions = this.state.selectedFoe.id !== null ? [
      NULL_FOE,
      ...this.props.ownershipOptions,
    ] : this.props.ownershipOptions;

    return (
      <ExtendedFlex
        flexDirection="column"
        justifyContent="center"
        width="100%"
        alignItems="center"
      >
        <ScrollToTopOnMount />
        <RedirectToLoginOnLoggedOut />
        <DashboardNotifications pendingData={this.props.pendingData} />
        <Heading
          type="H2"
          my="0px"
          pt="100px"
          pb="0px"
        >
          My Dashboard
        </Heading>
        <DashboardNavLink />
        {
          this.props.ownershipOptions.length !== 0 &&
          <FoeDropdown
            options={adjustedOptions}
            selected={this.state.selectedFoe}
            handleSelect={this.selectFoeOption}
          />
        }
        <ExtendedFlex
          justifyContent="center"
          flexDirection={['column', 'row']}
          width="100%"
          py="60px"
        >
          <ExtendedFlex
            justifyContent="center"
          >
            <NavLink
              to={transactionsActiveUrl}
              type="L7"
              mr={[2.5, 10]}
              textAlign="center"
            >
              ACTIVE INVESTMENTS
            </NavLink>
            <NavLink
              to={transactionsCompletedUrl}
              type="L7"
              mr={[2.5, 10]}
              textAlign="center"
            >
              COMPLETED INVESTMENTS
            </NavLink>
          </ExtendedFlex>
          <ExtendedFlex
            justifyContent="center"
          >
            <NavLink
              exact
              to={transactionsUrl}
              type="L7"
              mr={[2.5, 10]}
              mt={[5, 0]}
              textAlign="center"
            >
              ALL INVESTMENTS
            </NavLink>
          </ExtendedFlex>
        </ExtendedFlex>
        {
          this.props.loaded &&
          <ExtendedFlex
            width="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <ExtendedFlex
              w={1}
              px={50}
              maxWidth={1700}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <ExtendedFlex
                w={1}
                borderBottom="3px solid #176DB6"
                justifyContent="center"
              >
                <ExtendedFlex
                  width="90%"
                  my="20px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Heading
                    type="H3"
                    m="0px"
                  >
                    Transactions
                  </Heading>
                  <Link
                    type="L4"
                    href={exportUrl}
                    target="_blank"
                  >
                    Export All
                  </Link>
                </ExtendedFlex>
              </ExtendedFlex>

              <ExtendedBox
                w={1}
                display={['block', 'block', 'none']}
              >
                <ExtendedFlex
                  w={1}
                  my={30}
                  justify="space-between"
                  align="center"
                >
                  <StyledButton
                    type="L3mini"
                    minWidth={150}
                    maxWidth="none"
                    onClick={() => { this.handleFilterModalToggle(); }}
                  >
                    Filters
                  </StyledButton>
                  <StyledButton
                    type="L4"
                    onClick={this.resetFilters}
                  >
                    Reset
                  </StyledButton>
                </ExtendedFlex>
                <Modal
                  full
                  isVisible={this.state.filterModalOpen}
                  handleClick={() => {
                    const opened = this.state.filterModalOpen;
                    this.handleFilterModalToggle(opened);
                  }}
                >
                  <ExtendedFlex
                    flexDirection="column"
                    align="center"
                    justify="space-between"
                    minHeight="calc(100vh - 80px)"
                  >
                    <ExtendedFlex
                      flexDirection="column"
                      align="center"
                      minHeight="60vh"
                    >
                      <Heading
                        type="H3"
                        textAlign="center"
                        maxWidth={275}
                      >
                        Filter Transactions
                      </Heading>
                      <FilterButtons
                        options={this.typeOptions}
                        active={this.state.typeFilter}
                        flexDirection="row"
                        flexWrap="wrap"
                        maxWidth={275}
                        linkProps={{
                          mx: 5,
                          my: 10,
                        }}
                      />
                      <ExtendedBox
                        mt={20}
                      >
                        <DateRangeFilter
                          ref={this.dateRangeFilterMobile}
                          handleUpdate={this.setFilterDate}
                        />
                      </ExtendedBox>
                      <ExtendedBox
                        mt={60}
                      >
                        <InvestmentsFilterDropdown
                          options={uniqueInvestments}
                          investmentFilter={this.state.investmentFilter}
                          handleChange={this.addInvestmentFilter}
                          width="100%"
                        />
                      </ExtendedBox>
                      {
                        appliedInvestmentFilters.length > 0 &&
                        <ExtendedBox
                          w={['90vw', 600]}
                          py={20}
                          my={30}
                          mx={-40}
                          overflowX="scroll"
                          bg="gray.0"
                        >
                          <ExtendedFlex
                            px={40}
                            justifyContent="space-between"
                          >
                            <InvestmentFilterButtons
                              filters={appliedInvestmentFilters}
                            />
                          </ExtendedFlex>
                        </ExtendedBox>
                      }
                    </ExtendedFlex>
                    <ExtendedFlex
                      mt={30}
                      flexDirection="column"
                      align="center"
                      justify="center"
                    >
                      <StyledButton
                        type="L1"
                        mb={30}
                        onClick={() => {
                          this.handleFilterModalToggle();
                          // this.props.applyFilters();
                        }}
                      >
                        Apply
                      </StyledButton>
                      <StyledButton
                        type="L4"
                        onClick={() => {
                          this.handleFilterModalToggle();
                          this.resetFilters();
                        }}
                      >
                        Reset
                      </StyledButton>
                    </ExtendedFlex>
                  </ExtendedFlex>
                </Modal>
              </ExtendedBox>

              <ExtendedBox
                w={1}
                display={['none', 'none', 'block']}
              >
                <ExtendedFlex
                  width="100%"
                  justifyContent="space-between"
                  borderBottom="1px solid #202020"
                  alignItems="center"
                  py="30px"
                  flexWrap="wrap"
                >
                  <FilterButtons
                    options={this.typeOptions}
                    active={this.state.typeFilter}
                    my={10}
                  />
                  <DateRangeFilter
                    handleUpdate={this.setFilterDate}
                    ref={this.dateRangeFilter}
                  />
                  <InvestmentsFilterDropdown
                    options={uniqueInvestments}
                    investmentFilter={this.state.investmentFilter}
                    handleChange={this.addInvestmentFilter}
                  />
                  <StyledButton
                    type="L4"
                    onClick={this.resetFilters}
                  >
                    Reset
                  </StyledButton>
                </ExtendedFlex>
                {
                  appliedInvestmentFilters.length > 0 &&
                  <ExtendedFlex
                    width="100%"
                    pt={30}
                  >
                    <InvestmentFilterButtons
                      filters={appliedInvestmentFilters}
                    />
                  </ExtendedFlex>
                }
              </ExtendedBox>
            </ExtendedFlex>
            <ExtendedBox
              width="100%"
              pb="140px"
              pt={60}
            >
              <StyledTable
                data={filtered}
                columns={TransactionsTableConfig}
                noDataText="No transactions found."
                additionalTableProps={{
                  showPageSizeOptions: false,
                }}
                {...(filtered.length > 0 ? {
                  showPagination: true,
                  pageSize: Math.min(20, filtered.length),
                } : {
                  showPagination: false,
                  pageSize: 0,
                })}
              />
            </ExtendedBox>
          </ExtendedFlex>
        }
        <DashboardLoader loaded={this.props.loaded} />
      </ExtendedFlex>
    );
  }
}

TransactionsPage.propTypes = {
  loaded: PropTypes.bool,
  location: PropTypes.object,
  getDashboardApiData: PropTypes.func,
  getPendingData: PropTypes.func,
  completedData: PropTypes.array,
  completedExportUrl: PropTypes.string,
  activeData: PropTypes.array,
  activeDataExportUrl: PropTypes.string,
  allData: PropTypes.array,
  allDataExportUrl: PropTypes.string,
  ownershipFilter: PropTypes.object,
  ownershipOptions: PropTypes.array,
  pendingData: PropTypes.array,
  setOwnershipFilter: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    loaded: state.dashboardTransactions.loaded,
    ownershipFilter: state.dashboard.ownershipFilter,
    ownershipOptions: state.dashboard.ownershipOptions,
    completedData: state.dashboardTransactions.completedInvestmentsData,
    completedExportUrl: state.dashboardTransactions.completedInvestmentsDataExportUrl,
    activeData: state.dashboardTransactions.activeInvestmentsData,
    activeDataExportUrl: state.dashboardTransactions.activeInvestmentsDataExportUrl,
    allData: state.dashboardTransactions.allInvestmentsData,
    allDataExportUrl: state.dashboardTransactions.allInvestmentsDataExportUrl,
    pendingData: state.dashboard.pendingData.pendingData.investments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardApiData: (foeObject) => {
      const foeId = (foeObject && foeObject.id) ? parseInt(foeObject.id, 10) : undefined;
      dispatch(dashboardActions.dashboardApiActionRequested(foeId));
    },
    getPendingData: () => {
      dispatch(dashboardActions.pendingDashboardDataRequested());
    },
    setOwnershipFilter: (foeObject) => {
      dispatch(dashboardActions.ownershipFilterSet(foeObject));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransactionsPage),
);
