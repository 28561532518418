import React from 'react';

import TimelineWrapper from '../../components/TimelineWrapper';
import TimelineContainer from '../../components/TimelineContainer';
import TimelineCard from '../../components/TimelineCard';

import iconBackground from '../../assets/Potential_Efficiencies.svg';
import iconBed from '../../assets/icon_bed.svg';
import iconCalculated from '../../assets/icon_calculated.svg';
import iconChart from '../../assets/icon_wallet.svg';
import iconNavigate from '../../assets/icon_navigate.svg';
import iconOwnIt from '../../assets/icon_ownit.svg';

const Culture = () => {
  return (
    <TimelineWrapper>
      <TimelineContainer>
        <TimelineCard
          icon={iconBed}
          heading="Sleep Well At Night"
          body="Ethics drive our actions."
        />
        <TimelineCard
          icon={iconBackground}
          heading="Empower The Investor"
          body="Thoughtful, transparent information enables investors to make personal decisions in pursuit of their financial goals."
        />
      </TimelineContainer>
      <TimelineContainer>
        <TimelineCard
          icon={iconOwnIt}
          heading="Own It"
          body="Own work from start to finish. Be
          accountable for delivering timely,
          high-quality work. Details matter.
          Strive for continual improvement."
        />
        <TimelineCard
          icon={iconCalculated}
          heading="Take Calculated Risks"
          body="Thorough analysis enables us to seize the big opportunities that shape our future."
        />
      </TimelineContainer>
      <TimelineContainer>
        <TimelineCard
          icon={iconNavigate}
          heading="Climb Mount Everest"
          body="A diverse team who exhibits empathy and respect is more powerful than any one individual.  We're committed to transparently growing and learning together."
        />
        <TimelineCard
          icon={iconChart}
          heading="Spend Profitably"
          body="We are innovating and disrupting real estate finance. Every dollar saved is a dollar we can invest in this mission."
        />
      </TimelineContainer>
    </TimelineWrapper>
  );
};

export default Culture;
