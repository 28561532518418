/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { ReitHero, ReitCards, WhatIsReit } from 'rtmg-component-library';
import SubNav from '../subNav';
import { ExtendedFlex, Loader } from '../../coreui'
import HistoricalPerformance from './HistoricalPerformance';
import Portfolio from './Portfolio';
import Resources from './Resources';
import Management from './Management';
import FAQs from './FAQs';
import CTATail from './CTATail';
import Styled from './index.styled';
import createData from './_data';
import actions from './actions';

const navItems = [
  { title: 'Historical Performance', name: 'historical-performance' },
  { title: 'Current Portfolio', name: 'current-portfolio' },
  { title: 'Past Investments', name: 'past-investments' },
  { title: 'Resources', name: 'resources' },
  { title: 'Management', name: 'management' },
  { title: 'FAQ', name: 'faqs' },
];

const ReitLanding = ({ ios, faqItems, tokens, getReitsDataRequested, isLoggedIn, cookies, history }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getReitsDataRequested();
  }, []);

  useEffect(() => {
    if (ios) {
      setData(createData({ ios, faqItems, tokens }));
    }
  }, [ios, faqItems, tokens]);

  const handleSubmit = async (e, email) => {
    e.preventDefault();
    try {
      await cookies.set('rm_captured_email', email, { maxAge: 3600, path: '/' });
      history.push('/user/register');
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  };

  return (
    <>
      {
        data && faqItems ?
        <Styled.ReitLanding>
          <ReitHero {...data.reitHero} submitFunction={handleSubmit} isLoggedIn={isLoggedIn} />
          <ReitCards subTitle="OPEN FOR INVESTMENT" cards={data.reitCards} />
          <WhatIsReit {...data.whatIsReit} />
          <SubNav
            items={navItems}
            newStyle={true}
          />
          <div name="historical-performance" />
          <HistoricalPerformance mr1Button={ios[0].smartButton} mr2Button={ios[1].smartButton} />
          <div name="current-portfolio" />
          <Portfolio isCurrent={true} assets={ios} />
          <div name="past-investments" />
          <Portfolio isCurrent={false} assets={ios} />
          <div name="resources" />
          <Resources resources={ios} />
          <div name="management" />
          <Management />
          <div name="faqs" />
          <FAQs faqItems={faqItems} />
          <CTATail />
        </Styled.ReitLanding>
        :
        <ExtendedFlex
          position="relative"
          minHeight={700}
        >
          <Loader />
        </ExtendedFlex>
      }
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getReitsDataRequested: () => {
    dispatch(actions.getReitsDataRequested(193744, 274949));
  },
});

const mapStateToProps = (state) => ({
  ios: state.reitLanding.data,
  faqItems: state.reitLanding.faqItems,
  tokens: state.tokens,
  isLoggedIn: state.app.user.isLoggedIn,
});

ReitLanding.propTypes = {
  ios: PropTypes.array,
  faqItems: PropTypes.array,
  getReitsDataRequested: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  cookies: PropTypes.object,
};

export default withCookies(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReitLanding)));
