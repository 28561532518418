import {
  takeEvery,
  takeLatest,
  call,
  put,
  select,
} from 'redux-saga/effects';

import autoInvestApiSagas from './autoinvest/sagas';
import dashboardApiSagas from './investments/sagas';
import dashboardMessagesApiSagas from './messages/sagas';
import dashboardTransactionsApiSagas from './transactions/sagas';
import { selectFilters, selectDocumentsChecked } from './taxcenter/selectors';
import types from './types';
import actions from './actions';
import {
  notificationApi,
  taxcenterApi,
  taxcenterDocumentDownloadApi,
} from './api';
import { callApiCallback } from '../api';

export function* notificationDataFlow() {
  try {
    const response = yield call(notificationApi);
    yield put(
      actions.notificationApiActionSucceeded(
        response.data,
      ),
    );
  } catch (error) {
    yield put(
      actions.notificationApiActionFailed(
        error,
      ),
    );
  }
}

export function* dripToggleDataFlow(action) {
  try {
    const id = action.id;
    const callback = action.callback;
    const response = yield call(callApiCallback, callback);

    yield put(
      actions.dripToggleApiActionSucceeded(
        {
          ...response.data,
          id,
        },
      ),
    );
  } catch (error) {
    yield put(
      actions.dripToggleApiActionFailed(
        error,
      ),
    );
  }
}

export function* watchDripToggleRequested() {
  yield takeLatest(
    types.DRIP_TOGGLE_REQUESTED,
    dripToggleDataFlow,
  );
}

export function* taxcenterDataFlow() {
  try {
    const params = yield select(selectFilters);
    const response = yield call(taxcenterApi, params);
    yield put(
      actions.taxcenterApiActionSucceeded(
        response.data,
      ),
    );
  } catch (error) {
    yield put(
      actions.taxcenterApiActionFailed(
        error,
      ),
    );
  }
}

export function* taxCenterFilterFlow(action) {
  const {
    name,
    value,
  } = action;

  // First, update the filter in state.
  yield put(
    actions.taxcenterUpdateFilter(
      name,
      value,
    ),
  );

  // If this is the taxYear filter, we need to hit the API.
  if (name === 'taxYear') {
    yield put(actions.taxcenterApiActionRequested());
  }
}

export function* taxCenterApplyFilters() {
  yield put(actions.taxcenterApiActionRequested());
}

export function* taxCenterResetFlow() {
  const oldParams = yield select(selectFilters);
  yield put(actions.taxcenterResetFilters());
  const newParams = yield select(selectFilters);

  if (oldParams.taxYear !== newParams.taxYear) {
    yield put(actions.taxcenterApiActionRequested());
  }
}

export function* taxCenterDownloadsFlow() {
  try {
    const ids = yield select(selectDocumentsChecked);
    yield call(taxcenterDocumentDownloadApi, ids);
  } catch (error) {
    // There aren't really any errors to handle since the
    // "API" call is just a `window.location`.
  }
}

export function* cancelInvestmentFlow(action) {
  try {
    const id = action.id;
    const callback = action.callback;
    const response = yield call(callApiCallback, callback);

    yield put(
      actions.cancelInvestmentSucceeded(
        id,
        {
          ...response.data,
        },
      ),
    );
  } catch (error) {
    yield put(
      actions.cancelInvestmentFailed(
        action.id,
        error,
      ),
    );
  }
}

export function* cancelInvestmentSucceedFlow() {
  yield put(actions.dashboardApiActionRequested());
}

export function* watchTaxcenterApiRequested() {
  yield takeLatest(
    types.TAXCENTER_API_REQUESTED,
    taxcenterDataFlow,
  );
}

export function* watchTaxcenterFilterRequested() {
  yield takeLatest(
    types.TAXCENTER_FILTER_REQUESTED,
    taxCenterFilterFlow,
  );
}

export function* watchTaxcenterApplyFilters() {
  yield takeLatest(
    types.TAXCENTER_APPLY_FILTERS,
    taxCenterApplyFilters,
  );
}

export function* watchTaxcenterResetRequested() {
  yield takeLatest(
    types.TAXCENTER_RESET_REQUESTED,
    taxCenterResetFlow,
  );
}

export function* watchTaxcenterDownloadsRequested() {
  yield takeLatest(
    types.TAXCENTER_DOWNLOADS_REQUESTED,
    taxCenterDownloadsFlow,
  );
}

export function* watchCancelInvestmentRequested() {
  yield takeEvery(
    types.CANCEL_INVESTMENT_API_REQUESTED,
    cancelInvestmentFlow,
  );
}

export function* watchCancelInvestmentSucceeded() {
  yield takeLatest(
    types.CANCEL_INVESTMENT_API_SUCCEEDED,
    cancelInvestmentSucceedFlow,
  );
}

export default [
  ...autoInvestApiSagas,
  ...dashboardApiSagas,
  ...dashboardMessagesApiSagas,
  ...dashboardTransactionsApiSagas,
  watchDripToggleRequested(),
  watchTaxcenterApiRequested(),
  watchTaxcenterApplyFilters(),
  watchTaxcenterFilterRequested(),
  watchTaxcenterResetRequested(),
  watchTaxcenterDownloadsRequested(),
  watchCancelInvestmentRequested(),
  watchCancelInvestmentSucceeded(),
];
