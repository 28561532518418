/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ConfirmSignUp } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import uuidv1 from 'uuid/v1';

import {
  ExtendedFlex,
  Heading,
  Body,
} from '../../coreui';

import Container from './Container';
import FirstContainer from './FirstContainer';
import SecondContainer from './SecondContainer';

import Form from '../../components/forms/Form';
import Label from '../../components/forms/Label';
import Input from '../../components/forms/Input';
import Questions from './Questions';
import SecondaryButton from '../../components/forms/SecondaryButton';
import PrimaryButton from '../../components/forms/PrimaryButton';
import Actions from '../../components/forms/Actions';
import trackError from './trackError';

const isEmailInUse = async (email) => {
  // We use a new UUID as a password to guarantee sign in fails
  try {
    await Auth.signIn(email, uuidv1());
    return true;
  } catch (error) {
    return error.code === 'NotAuthorizedException'
      || error.message.indexOf('UserMigration failed with error incorrect password.') === 0;
  }
};

class CustomConfirmSignUp extends ConfirmSignUp {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      submitting: false,
      editingEmail: false,
      email: undefined,
      isConfirmSignedIn: false,
    };
    this._validAuthStates = ['confirmSignUp'];

    this.updateState = this.updateState.bind(this);
    this.handleResend = this.handleResend.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleUpdateEmail = this.handleUpdateEmail.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.authState !== 'confirmSignUp' && state.submitting) {
      return {
        submitting: false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.authState !== prevProps.authState && this.props.authState === 'confirmSignUp') {
      Auth.currentSession()
        .then(() => {
          this.updateState({
            isConfirmSignedIn: true,
          });
        })
        .catch(() => {
          // Do nothing.
        });
    } else if (this.props.authState !== prevProps.authState && prevProps.authState === 'confirmSignUp') {
      this.updateState({
        email: undefined,
      });
    }
  }

  error(err) {
    if (this.state.submitting) {
      this.updateState({ submitting: false });
    }
    trackError('Confirm SignUp Error', { error: err });
    super.error(err);
  }

  updateState(...args) {
    this.setState(...args);
  }

  handleResend(...args) {
    this.error('New verification code sent.');
    this.resend(...args);
  }

  handleFormSubmit(e, callback) {
    e.preventDefault();
    if (!this.state.submitting) {
      this.updateState({ submitting: true });
      callback();
    }
  }

  async confirm() {
    if (this.state.isConfirmSignedIn) {
      const {
        code,
      } = this.inputs;

      try {
        // Mark it as confirmed.
        await Auth.verifyCurrentUserAttributeSubmit('email', code);

        // Update the cached token.
        const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

        this.changeState('signedIn', cognitoUser);
      } catch (e) {
        this.error(e);
        if (e === 'No current user') {
          this.updateState({
            submitting: false,
            editingEmail: false,
            email: undefined,
            isConfirmSignedIn: false,
          });
          this.changeState('signIn');
          this.error('Your account has been logged out. Please log in again.');
        } else {
          this.error(e);
        }
      }
    } else {
      super.confirm();
    }
  }

  async handleUpdateEmail() {
    try {
      // Check if the new email set is already in use.
      const email = this.inputs.username;
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const {
        attributes: {
          email: currentEmail,
        },
      } = cognitoUser;

      // Make sure they actually changed the value and has a value set.
      if (!email || email === currentEmail) {
        this.updateState({
          submitting: false,
          editingEmail: false,
        });
        return;
      }

      const emailInUse = await isEmailInUse(email);
      if (!emailInUse) {
        await Auth.updateUserAttributes(cognitoUser, {
          email,
        });

        // Refresh the current user data, otherwise the token data will be
        // stale.
        Auth.currentAuthenticatedUser({ bypassCache: true })
          .then((user) => {
            this.updateState({
              submitting: false,
              editingEmail: false,
              email: user.attributes.email,
            });
          })
          .catch((e) => {
            this.error(e);
          });
      } else {
        this.error('An account with the given email already exists.');
      }
    } catch (e) {
      if (e === 'not authenticated') {
        this.updateState({
          submitting: false,
          editingEmail: false,
          email: undefined,
          isConfirmSignedIn: false,
        });
        this.changeState('signIn');
        this.error('You have been logged out. Please authenticate again.');
      } else {
        this.error(e);
      }
    }
  }

  resend() {
    if (this.state.isConfirmSignedIn) {
      Auth.verifyCurrentUserAttribute('email')
        .then(() => {
          // Do nothing.
        })
        .catch((e) => {
          this.error(e);
        });
    } else {
      super.resend();
    }
  }

  showComponent() {
    const username = this.state.email || this.usernameFromAuthData();
    const {
      editingEmail,
      submitting,
      isConfirmSignedIn,
    } = this.state;

    return (
      <Container>
        <FirstContainer>
          <Heading
            type="H2"
          >
            Email Verification.
          </Heading>
          {
            !editingEmail
              ? (
                <>
                  <Body
                    type="B1"
                  >
                    A verification code has been sent to your email. If not received,
                    please confirm your email is correct below and check your spam folder.
                  </Body>
                  <ExtendedFlex>
                    <Body
                      type="B2"
                    >
                      Didn&apos;t receive your code?
                    </Body>
                    <SecondaryButton
                      onClick={this.handleResend}
                      data-test="resend-code"
                    >
                      Re-send verification code
                    </SecondaryButton>
                  </ExtendedFlex>
                </>
              )
              : (
                <Body
                  type="B1"
                >
                  You will receive a new verification code to the email address
                  you enter below.
                </Body>
              )
          }
          <Form
            onSubmit={(e) => this.handleFormSubmit(
              e,
              !editingEmail
                ? this.confirm
                : this.handleUpdateEmail,
            )}
          >
            <Label
              htmlFor="username"
            >
              Email
              <Input
                id="username"
                placeholder="E-mail"
                name="username"
                disabled={username && !editingEmail}
                disabledColor="#2e2e2e"
                type="email"
                required
                {...(editingEmail ? {
                  key: 'username',
                  defaultValue: username,
                } : {
                  key: 'usernameEditing',
                  value: username,
                })}
                onChange={(e) => {
                  e.target.value = (e.target.value || '').toLowerCase();
                  this.handleInputChange(e);
                }}
              />
            </Label>
            {
              !editingEmail &&
              <Label
                htmlFor="code"
              >
                Code
                <Input
                  id="code"
                  name="code"
                  autoComplete="off"
                  onChange={this.handleInputChange}
                  placeholder="Enter Code"
                  required
                  onInput={(e) => {
                    const value = e.target.value
                      // Replace non-digits.
                      .replace(/[^\S]/g, '');
                    e.target.value = value;
                  }}
                  pattern="^[\S]+$"
                  data-test="verification-code"
                />
              </Label>
            }
            <Actions>
              <PrimaryButton
                loading={submitting}
                disable={submitting}
                buttonType="submit"
              >
                {
                  editingEmail
                    ? 'Send new code'
                    : 'Continue'
                }
              </PrimaryButton>
              {
                isConfirmSignedIn
                  ? (
                    <SecondaryButton
                      onClick={() => this.updateState((prevState) => ({
                        editingEmail: !prevState.editingEmail,
                      }))}
                      disabled={submitting}
                    >
                      {
                        editingEmail
                          ? 'Cancel'
                          : 'Edit your email address'
                      }
                    </SecondaryButton>
                  )
                  : (
                    <SecondaryButton
                      onClick={() => this.changeState('signIn')}
                    >
                      Back to Sign In
                    </SecondaryButton>
                  )
              }
            </Actions>
          </Form>
          <Questions />
        </FirstContainer>
        <SecondContainer />
      </Container>
    );
  }
}

export default CustomConfirmSignUp;
