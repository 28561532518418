/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  ExtendedFlex,
  Hero,
  ExtendedBox,
  ScrollToTopOnMount,
} from '../coreui';

import heroBannerImage from '../assets/img_hero_ContactUs.jpg';
import LocationSlide from '../ourStory/components/LocationSlide';
import Callouts from './callouts';
import AskUs from './components/AskUs';
import InvestToday from './components/InvestToday';
import locations from './data';

const ContactUs = () => {
  return (
    <ExtendedBox>
      <ScrollToTopOnMount />
      <ExtendedFlex>
        <Hero
          image={heroBannerImage}
          header="CONTACT US"
          subHeader="WE'RE HERE TO ANSWER YOUR QUESTIONS"
          bgPosition="74%"
        />
      </ExtendedFlex>
      <AskUs />
      <ExtendedFlex
        bg="gray.1"
      >
        {
          locations.map((location) => {
            return (
              <LocationSlide
                key={location.uuid}
                {...location}
              />
            );
          })
        }
      </ExtendedFlex>
      <InvestToday />
      <Callouts />
    </ExtendedBox>
  );
};

export default ContactUs;
