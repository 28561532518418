/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Body,
  ModalWithOverlay,
  CancelIcon,
} from '../../coreui';

import CancelModal from './CancelModal';

class PendingDashboardCancelCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };

    this.handleModalToggle = this.handleModalToggle.bind(this);
  }

  handleModalToggle() {
    this.setState((state) => ({
      modalVisible: !state.modalVisible,
    }));
  }

  render() {
    return (
      <ExtendedFlex
        alignItems="center"
        justify="center"
        m={0}
      >
        <Body
          type="B3"
          m={0}
          onClick={this.handleModalToggle}
        >
          <CancelIcon />
        </Body>
        <ModalWithOverlay
          handleClick={this.handleModalToggle}
          isVisible={this.state.modalVisible}
        >
          <CancelModal
            {...this.props.original}
            isVisible={this.state.modalVisible}
            handleClick={this.handleModalToggle}
          />
        </ModalWithOverlay>
      </ExtendedFlex>
    );
  }
}

PendingDashboardCancelCell.propTypes = {
  original: PropTypes.object,
};

export default PendingDashboardCancelCell;
