import styled from 'styled-components';

const BigNumber = styled.div`
  font-family: 'Playfair Display', serif;
  font-feature-settings: "lnum" 1;
  font-size: 150px;
  color: #176DB6;
  text-align: center;
  span {
    position: relative;
    font-size: 30%;
    bottom: 75px;
    margin-right: 5px;
    text-transform: uppercase;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: 200px;
    span {
      bottom: 100px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    font-size: 160px;
    span {
      bottom: 80px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: 220px;
    span {
      bottom: 110px;
    }
  }
  @media (min-width: 1200px) {
    font-size: 300px;
    span {
      bottom: 150px;
    }
  }
`;

export default {
  BigNumber,
  Percentage: styled(BigNumber)`
    span {
      bottom: 2em;
    }
  `,
};
