import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import { getIOApi } from '../../api';

export function* getIODataFlow(action) {
  const id = action.id.id;
  try {
    const response = yield call(getIOApi, id);
    yield put(actions.getIODataSucceeded({
      ...response.data,
      pageViewed: true,
    }));
  } catch (error) {
    yield put(
      actions.getIODataFailed(error),
    );
  }
}

export function* watchGetIODataRequested() {
  yield takeLatest(
    types.GET_IO_DATA_REQUESTED,
    getIODataFlow,
  );
}

export default watchGetIODataRequested;
