import types from './types';

export default {
  dashboardTransactionsApiActionRequested: () => ({
    type: types.DASHBOARD_TRANSACTIONS_API_REQUESTED,
  }),
  dashboardTransactionsApiActionSucceeded: (data) => ({
    data,
    type: types.DASHBOARD_TRANSACTIONS_API_SUCCEEDED,
  }),
  dashboardTransactionsApiActionFailed: () => ({
    type: types.DASHBOARD_TRANSACTIONS_API_FAILED,
  }),
};
