import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Heading,
} from '../../coreui';

const InfoBlock = ({
  heading,
  headingProps,
  children,
  ...props
}) => {
  return (
    <ExtendedFlex
      flexDirection="column"
      borderBottom={1}
      borderColor="gray.10"
      // eslint-disable-next-line
      {...props}
    >
      <Heading
        type="H6"
        textTransform="uppercase"
        mt={60}
        mb={30}
        // eslint-disable-next-line
        {...headingProps}
      >
        {heading}
      </Heading>
      {children}
    </ExtendedFlex>
  );
};

InfoBlock.propTypes = {
  heading: PropTypes.string,
  headingProps: PropTypes.object,
  children: PropTypes.object,
};

export default InfoBlock;
