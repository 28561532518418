import types from './types';

const initialState = {
  items: [],
  prevUrl: '',
  nextUrl: '',
  currentPage: 0,
  loaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_KC_SEARCH_REQUESTED: {
      return {
        ...state,
        query: action.query,
        currentPage: action.pageNum,
        items: [],
        prevUrl: null,
        nextUrl: null,
        loaded: false,
      };
    }

    case types.GET_KC_SEARCH_SUCCEEDED: {
      const items = action.data.data;
      const prevPage = state.currentPage - 1;
      const pathname = `/knowledge-center/search/${state.query}`;

      return {
        ...state,
        items,
        // eslint-disable-next-line
        prevUrl: action.data._links.previous ?
          {
            pathname,
            ...(prevPage > 0 ? { search: `?page=${prevPage}` } : {}),
          } : null,
        // eslint-disable-next-line
        nextUrl: action.data._links.next ?
          {
            pathname,
            search: `?page=${state.currentPage + 1}`,
          } : null,
        totalPages: action.data._pager.totalPages,
        loaded: true,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
