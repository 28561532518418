import React from 'react';
import { Link } from 'react-router-dom';

import Styled from './index.styled';

import Background from './header_skyline.jpg';
import Skyline from './mid_skyline.jpg';

const FiveReasons = () => {
  return (
    <Styled.Pages>
      <header className="text-image-header" style={{ backgroundImage: `url(${Background})` }}>
        <h1 className="heading">5 Reasons To Invest In Commercial Real Estate</h1>
      </header>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p>Savvy investors understand the importance of having a diversified portfolio, and a truly diversified portfolio includes real estate. Commercial real estate in particular can potentially deliver the appreciation and stable returns most investors are looking for, even during times of economic uncertainty.</p>
            <p>Here are 5 reasons you may want to consider investing in commercial real estate, if you haven’t already.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2>1. Diversification</h2>
            <p>You may have heard the saying, “Don&apos;t put all your eggs in one basket.” Having a wide range of investment opportunities is the way to go, which is why commercial real estate can potentially be a strong addition to any portfolio. That way, if one asset isn&apos;t performing, you have other options  potentially to fall back on.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2>2. Cash Flow and Passive Income</h2>
            <p>As a RealtyMogul Member, if you invest in a real estate opportunity on the Platform, you do not have to deal with the ongoing hassles of property management.  By investing in platform real estate deals, RealtyMogul Members can invest and potentially reap the benefits of real estate ownership without the daily nuisances.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2>3. Tax Benefits</h2>
            <p>Commercial real estate can potentially offer a wide variety of tax benefits for Members. Deductions related to depreciation, interest expense, and other items can potentially shelter or defer taxes on cash distributions.</p>
            <p>For example, current cash flow will often be less than the total depreciation and interest expense, thereby creating a return similar to a tax-free bond. However, in most cases, these potential benefits will be recaptured upon a sale of the property.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <img src={Skyline} style={{ width: '100%' }} alt="skyline" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2>4. Inflation Hedge</h2>
            <p>While inflation is generally regarded negatively because of the associated price increases in everyday goods and activities, many real estate investors potentially benefit from inflation due to the rise in property value.</p>
            <p>According to a report by TIAA-CREF, “If inflation emerges, investors in commercial real estate will likely withstand it according to the historical performance of the asset class. Over five-year holding periods historically, commercial real estate returns outpaced inflation nicely. Over short term periods, commercial real estate returns have been modestly correlated with inflation demonstrating their ‘inflation hedging’ capacity.”</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2>5. Leverage</h2>
            <p>A major benefit of direct commercial real estate investment is the ability to place debt on the property which can increase the purchasing power of each dollar of equity. This in turn increases the total potential returns of the property, which also increases the risk.</p>
            <p>A simple example is a $1,000,000 property. Typically, only $250,000 of equity will be required with the balance funded by debt. If the property is then sold for $1,250,000 in one year, the cash return would be 100%. If the property was purchased with no debt, the cash return would only be 25%.</p>
            <p>Here at RealtyMogul, our mission is to empower you to live the life you want by potentially generating wealth through real estate investing. Since 2012, RealtyMogul Members have collectively invested over $800 million into over $4.1 billion of real estate nationwide.</p>
            <p>To view investment opportunities on the RealtyMogul Platform, click on the button below.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Link to="/investment-opportunities"><button style={{ fontSize: '142%', margin: '0 auto' }} type="button">VIEW INVESTMENTS</button></Link>
            <p style={{ fontSize: '8px' }}>*As of December 31st, 2021</p>
          </div>
        </div>
      </div>
    </Styled.Pages>
  );
};

export default FiveReasons;
