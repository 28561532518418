import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import {
  ConnectedRouter,
} from 'react-router-redux';
import 'nodelist-foreach-polyfill'; // IE11 support, needed for 'react-slider' carousels
import 'isomorphic-fetch';
import hyperform from 'hyperform';
import { CookiesProvider } from 'react-cookie';

import { Amplify } from 'aws-amplify';
import { global as designSystemGlobal } from 'rtmg-component-library';

import { datadogRum } from '@datadog/browser-rum';

import './publicUrl';

import App from './App';
import AppRouter from './appRouter';
import './globalStyle';
import store, { history } from './store';
import storage from './auth/storage';

window.hyperform = hyperform;
window.store = store;
window.appHistory = history;

const {
  REACT_APP_ENV: env,
  REACT_APP_COGNITO_IDENTITY_POOL_ID: identityPoolId,
  REACT_APP_COGNITO_POOL_ID: userPoolId,
  REACT_APP_COGNITO_WEB_CLIENT_ID: userPoolWebClientId,
} = window._env_ || {};

if (env) {
  // Check if it's Cypress.
  const isCypress = !!navigator.userAgent.match(/[Cc]ypress/);

  if (!isCypress) {
    datadogRum.init({
      applicationId: '0460dbce-d7a5-4f68-8dde-e3caa44f5ea7',
      clientToken: 'pubd671e8269d515266454094e97149e4d0',
      site: 'us5.datadoghq.com',
      service: 'legacyreact',
      allowedTracingUrls: [window && window.location.origin],
      env,
      // Specify a version number to identify the deployed version of your
      // application in Datadog version: '1.0.0',
      sessionSampleRate: 100,
      premiumSampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }
}

Amplify.configure({
  Auth: {
    identityPoolId,
    region: 'us-west-2',
    identityPoolRegion: 'us-west-2',
    userPoolId,
    userPoolWebClientId,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    storage,
  },
});

const { GlobalStyle } = designSystemGlobal;

hydrate(
  (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <CookiesProvider>
          <App>
            <GlobalStyle />
            <AppRouter />
          </App>
        </CookiesProvider>
      </ConnectedRouter>
    </Provider>
  ),
  document.getElementById('root'),
);
