import {
  takeEvery,
  all,
  put,
  call,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';
import ioDetailsActions from '../../ioDetails/actions';
import { getInvestedApi } from '../../api';

export function* investedFlow(action) {
  try {
    const response = yield call(getInvestedApi, action.id);

    yield all([
      put(
        actions.investedPageSucceeded(
          action.id,
          response.data,
        ),
      ),
      put(
        ioDetailsActions.getIODataSucceeded(
          {
            pageViewed: false,
            data: [
              response.data.io,
            ],
          },
        ),
      ),
    ]);
  } catch (error) {
    yield put(
      actions.investedPageFailed(
        action.id,
        error,
      ),
    );
  }
}

export function* watchInvestedRequested() {
  yield takeEvery(
    types.INVESTED_PAGE_REQUESTED,
    investedFlow,
  );
}

export default watchInvestedRequested;
