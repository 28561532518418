/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { ExtendedBox } from '../coreui';

import actions from './actions';

class AdminMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      js: [],
      loaded: false,
    };

    this.loadScriptAsync = this.loadScriptAsync.bind(this);
    this.loadNextScript = this.loadNextScript.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (typeof document !== 'undefined') {
      document.body.classList.add('adminimal-menu');
    }
    if (typeof window !== 'undefined' && !window.Drupal) {
      window.AdminMenuDrupal = true;
      this.props.getAdminMenuContentRequested();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.js !== prevProps.js) {
      this.state.js = this.props.js;
      this.loadNextScript();
    }
  }

  loadScriptAsync(scrInfo) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.onload = () => {
        this.setState({
          loaded: true,
        });
        resolve();
      };
      if (scrInfo.url) {
        script.setAttribute('src', scrInfo.url);
      }
      if (scrInfo.content) {
        const scriptText = document.createTextNode(scrInfo.content);
        script.appendChild(scriptText);
      }
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
    });
  }

  loadNextScript() {
    if (this.state.js.length > 0) {
      // @TODO This is written wrong and needs to be fixed.
      // eslint-disable-next-line react/no-access-state-in-setstate
      const remaining = this.state.js;
      const scrInfo = remaining.shift();
      this.setState({ js: remaining });
      const scriptLoaded = this.loadScriptAsync(scrInfo);
      scriptLoaded.then(() => {
        if (this.state.js.length > 0) {
          this.loadNextScript();
        }
      });
    }
  }

  render() {
    const {
      content,
      css,
    } = this.props;

    return (
      <ExtendedBox
        w={1}
        {...(!this.state.loaded ? {
          display: 'none',
        } : {})}
      >
        {
          css &&
          css.map((stylesheetURL) => {
            return (
              <link key={stylesheetURL} rel="stylesheet" type="text/css" href={stylesheetURL} />
            );
          })
        }
        {ReactHtmlParser(content)}
      </ExtendedBox>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAdminMenuContentRequested: () => {
    dispatch(actions.getAdminMenuContentRequested());
  },
});

const mapStateToProps = (state) => ({
  content: state.adminMenu.content,
  css: state.adminMenu.css,
  js: state.adminMenu.js,
});

AdminMenu.propTypes = {
  getAdminMenuContentRequested: PropTypes.func,
  content: PropTypes.string,
  css: PropTypes.array,
  js: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu);
