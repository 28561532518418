import styled from 'styled-components';
import ReactPlayer from 'react-player';

const Video = styled(ReactPlayer)`
  &[style] {
    width: ${(props) => props.width || 'auto'} !important;
    height: ${(props) => props.height || 'auto'} !important;
  }
`;

export default Video;
