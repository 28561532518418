import React from 'react';
import PropTypes from 'prop-types';

import { Heading, Body } from '../../coreui';
import RadioLabel from '../../components/forms/RadioLabel';
import RadioGroup from '../../components/forms/RadioGroup';
import Select from '../../components/forms/Select';

const incomeOptions = [
  { value: '500k_greater', label: 'Greater than $500,000' },
  { value: '300k_to_500k', label: '$300,000 - $500,000' },
  { value: '200k_to_300k', label: '$200,000 - $300,000' },
  { value: '100k_to_200k', label: '$100,000 - $200,000' },
  { value: '50k_to_100k', label: '$50,000 - $100,000' },
  { value: 'less_50k', label: 'Less than $50,000' },
  { value: 'retired', label: 'Retired' },
  { value: 'student', label: 'Student' },
];

const currentYear = parseInt((new Date()).getFullYear(), 10);

const AnnualIncome = ({
  value,
  handleChange,
  incomeDetermination,
  handleIncomeDetermination,
}) => {
  return (
    <>
      <Heading
        type="H5"
        textTransform="uppercase"
        mt={60}
        mb={10}
      >
        Annual Income
      </Heading>
      <Body
        type="B2"
        mt={0}
        color="#707070"
      >
        Your expected income for this year, as well as your income
        from the previous two years. You may record as an individual
        or joint/married.
      </Body>
      <RadioGroup
        name="incomeDetermination"
        value={incomeDetermination}
        onChange={handleIncomeDetermination}
        inputProps={{
          'data-test': 'incomeDetermination',
          required: true,
        }}
      >
        <RadioLabel
          value="individual"
          label="Individual"
        />
        <RadioLabel
          value="joint"
          label="Joint (married)"
        />
      </RadioGroup>
      <Select
        name="current_income"
        value={value}
        onChange={handleChange}
        inputProps={{
          'data-test': 'income1',
        }}
        placeholder={`Select ${currentYear} expected income`}
        required
        options={incomeOptions}
      />
    </>
  );
};

AnnualIncome.propTypes = {
  handleChange: PropTypes.func,
  handleIncomeDetermination: PropTypes.func,
  value: PropTypes.number,
  incomeDetermination: PropTypes.string,
};

export default AnnualIncome;
