import React from 'react';
import PropTypes from 'prop-types';

import {
  Body,
  Link,
  ExtendedFlex,
  Heading,
} from '../../coreui';

const NotificationItem = (props) => {
  const {
    action,
    cancel,
    label,
    message,
    url,
    isLast,
  } = props;

  const borderValue = isLast !== true ? 'medium none' : '1px solid #202020';
  return (
    <ExtendedFlex
      height={['360px', '184px']}
      width={['280px', '100%']}
      maxWidth="1440px"
      px={[0, 50]}
      py={48}
      borderBottom={borderValue}
      justifyContent="space-between"
      alignItems={['flex-start', 'center']}
      flexDirection={['column', 'row']}
    >
      <ExtendedFlex
        flexDirection="column"
      >
        <Heading
          type="H6"
        >
          {label}
        </Heading>
        <Body
          type="B3"
        >
          {message}
        </Body>
      </ExtendedFlex>
      <ExtendedFlex
        flexDirection="column"
        alignItems="center"
      >
        <Link
          type="L3"
          href={url}
        >
          {action}
        </Link>
        {
          cancel &&
          <Link
            type="L4"
            href={cancel}
            mt={30}
          >
            CANCEL INVESTMENT
          </Link>
        }
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

NotificationItem.propTypes = {
  label: PropTypes.string,
  message: PropTypes.string,
  url: PropTypes.string,
  action: PropTypes.string,
  cancel: PropTypes.string,
  isLast: PropTypes.bool,
};

export default NotificationItem;
