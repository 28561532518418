import types from './types';

export default {
  getFooterDataRequested: () => ({
    type: types.GET_FOOTER_DATA_REQUESTED,
  }),
  getFooterDataFaled: (error) => ({
    type: types.GET_FOOTER_DATA_FAILED,
    error,
  }),
  getFooterDataSucceeded: (data) => ({
    type: types.GET_FOOTER_DATA_SUCCEEDED,
    data,
  }),
};
