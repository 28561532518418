import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';

import {
  states,
} from '../../data';

const State = ({
  name,
  value,
  onChange,
}) => {
  return (
    <Select
      name={name}
      value={value}
      onChange={onChange}
      placeholder="Select State"
      required
      options={Object.keys(states).reduce((accum, stateCode) => [
        ...accum,
        { value: stateCode, label: states[stateCode] },
      ], [])}
    />
  );
};

State.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default State;
