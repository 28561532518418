import React from 'react';

import {
  Body,
} from '../../coreui';

const AccreditedTooltip = () => {
  return (
    <Body
      type="B5"
    >
      An Accredited investor meets at least one of the following criteria:
      <ul>
        <li>

          Net Worth in excess of $1 million (individually or jointly),
          excluding the value of your primary residence.
        </li>
        <li>
          Income in excess of $200,000 for this year and in the previous
          two years, with the expectation of maintaining such level of income in the future.
        </li>
        <li>
          Joint Income in excess of $300,000 for this year and the
          previous two years, with the expectation of maintaining such level
          of income in the future (Note: it is not mandatory to qualify
          jointly with your spouse if you qualify as an individual.
        </li>
      </ul>
    </Body>
  );
};

export default AccreditedTooltip;
