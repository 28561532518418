export const TOTAL_PENDING_TOOLTIP = `
Total amount of funds that have been committed to recent investments. These funds will be reflected in your "Active Investments" and "Transactions" once you have supplied all necessary information to invest and processing is complete.
`;

export const TOTAL_PLEDGED_TOOLTIP = `
Total amount you have pledged to invest in opportunities. You will be contacted when the opportunities become available and will be able to adjust your investment amount.
`;

export const PENDING_INVESTMENTS_TOOLTIP = `
Investments you have in progress are shown here. An investment in progress may or may not require your attention, and will display on your active investments once finished processing.
`;

export const PLEDGED_INVESTMENTS_TOOLTIP = `
Investments that you have pledged are shown here. Once we are ready to receive your investment, an investment will move to the pending investments area.
`;

export const WAITLIST_INVESTMENTS_TOOLTIP = `
These opportunities are no longer available. You have requested to be notified in the event that space becomes available.
`;

export const WATCHLIST_INVESTMENTS_TOOLTIP = `
You have chosen to watch these opportunities. Opportunities no longer available will automatically be removed.
`;

export const ACCREDITED_INVESTOR_TOOLTIP = `
An individual with income exceeding $200,000 in each of the two most recent years (or joint income with his / her spouse exceeding $300,000 in each of those years) and a reasonable expectation of reaching the same income level in the current year; OR an individual with a net worth (or joint net worth with his / her spouse) exceeding $1 million, excluding the person's primary residence.
`;
