import React from 'react';
import PropTypes from 'prop-types';

class StartEnrollment extends React.Component {
  componentDidMount() {
    const {
      io,
      foe,
      direct,
      redirect,
      replace,
    } = this.props;
    this.props.handleEnrollment({
      io,
      foe,
      redirect,
      direct,
      replace,
    });
  }

  render() {
    return null;
  }
}

StartEnrollment.propTypes = {
  handleEnrollment: PropTypes.func,
  io: PropTypes.number.isRequired,
  foe: PropTypes.number.isRequired,
  redirect: PropTypes.string,
  replace: PropTypes.bool,
  direct: PropTypes.bool,
};

export default StartEnrollment;
