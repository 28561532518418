export default {
  APP_INIT: 'APP_INIT',
  GET_USER_DATA_REQUESTED: 'GET_USER_DATA_REQUESTED',
  GET_USER_DATA_FAILED: 'GET_USER_DATA_FAILED',
  GET_USER_DATA_SUCCEEDED: 'GET_USER_DATA_SUCCEEDED',
  SET_USER_DATA_REQUESTED: 'SET_USER_DATA_REQUESTED',
  SET_USER_DATA_FAILED: 'SET_USER_DATA_FAILED',
  SET_USER_DATA_SUCCEEDED: 'SET_USER_DATA_SUCCEEDED',
  USER_LOGOUT_REQUESTED: 'USER_LOGOUT_REQUESTED',
  USER_LOGOUT_SUCCEEDED: 'USER_LOGOUT_SUCCEEDED',
  USER_LOGOUT_FAILED: 'USER_LOGOUT_FAILED',
};
