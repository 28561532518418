import appWrapperTypes from '../appWrapper/types';
import retirementTypes from './types';

const initialState = {
  user: {
    isLoggedIn: false,
  },
  faqs: [],
  articles: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case appWrapperTypes.APP_INIT:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data.user,
        },
      };

    case appWrapperTypes.GET_USER_DATA_SUCCEEDED:
      return {
        ...state,
        user: action.user,
      };

    case retirementTypes.GET_FAQ_DATA_SUCCEEDED:
      return {
        ...state,
        faqItems: action.data.data.map((item) => {
          return ({
            header: item.header,
            content: item.content,
            id: item.id,
          });
        }),
      };

    case retirementTypes.GET_ARTICLE_DATA_SUCCEEDED:
      return {
        ...state,
        articles: action.data.data.map((item) => {
          return ({
            header: '',
            content: item.content,
            id: '',
          });
        }),
      };

    default:
      return {
        ...state,
      };
  }
};
