export default {
  AUTOINVEST_API_REQUESTED: 'AUTOINVEST_API_REQUESTED',
  AUTOINVEST_API_SUCCEEDED: 'AUTOINVEST_API_SUCCEEDED',
  AUTOINVEST_API_FAILED: 'AUTOINVEST_API_FAILED',
  AUTOINVEST_ENROLLMENT_REQUESTED: 'AUTOINVEST_ENROLLMENT_REQUESTED',
  AUTOINVEST_ENROLLMENT_SUCCEEDED: 'AUTOINVEST_ENROLLMENT_SUCCEEDED',
  AUTOINVEST_ENROLLMENT_FAILED: 'AUTOINVEST_ENROLLMENT_FAILED',
  AUTOINVEST_CANCEL_REQUESTED: 'AUTOINVEST_CANCEL_REQUESTED',
  AUTOINVEST_CANCEL_SUCCEEDED: 'AUTOINVEST_CANCEL_SUCCEEDED',
  AUTOINVEST_CANCEL_FAILED: 'AUTOINVEST_CANCEL_FAILED',
  AUTOINVEST_UPDATE_REQUESTED: 'AUTOINVEST_UPDATE_REQUESTED',
  AUTOINVEST_UPDATE_SUCCEEDED: 'AUTOINVEST_UPDATE_SUCCEEDED',
  AUTOINVEST_UPDATE_FAILED: 'AUTOINVEST_UPDATE_FAILED',
};
