import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Data,
} from '../../coreui';

const TableHeader = ({
  children,
  dataProps,
  ...additionalProps
}) => {
  return (
    <ExtendedFlex
      align="center"
      // eslint-disable-next-line
      {...additionalProps}
    >
      <Data
        type="D5"
        m={0}
        // eslint-disable-next-line
        {...dataProps}
      >
        {children}
      </Data>
    </ExtendedFlex>
  );
};

TableHeader.propTypes = {
  children: PropTypes.node,
  dataProps: PropTypes.object,
};

export default TableHeader;
