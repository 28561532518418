import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  Image,
} from '../..';

import image from '../../assets/closeX_blk.svg';

const Limited = ({
  top,
  isVisible,
  showClose,
  handleClick,
  children,
}) => (
  <ExtendedBox
    w={[0.9, 'auto']}
    minHeight={140}
    position="fixed"
    top={top || null}
    left="50%"
    transform="translate(-50%, -50%)"
    bg="white"
    p={40}
    boxSizing="border-box"
    opacity={isVisible ? '1' : '0'}
    transition={`
      opacity 0.5s linear,
      visibility 0.5s linear
    `}
    zIndex="2"
    visibility={isVisible ? 'visible' : 'hidden'}
  >
    {
      showClose &&
      <ExtendedBox
        position="absolute"
        top="10px"
        right="10px"
        visibility={isVisible ? 'visible' : 'hidden'}
      >
        <Image
          src={image}
          onClick={handleClick}
          visibility={isVisible ? 'visible' : 'hidden'}
          hover
        />
      </ExtendedBox>
    }
    {children}
  </ExtendedBox>
);

Limited.propTypes = {
  top: PropTypes.any,
  children: PropTypes.any,
  isVisible: PropTypes.bool,
  showClose: PropTypes.bool,
  handleClick: PropTypes.func,
};

Limited.defaultProps = {
  showClose: true,
};

export default Limited;
