import {
  takeEvery,
  call,
  put,
  all,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';
import appActions from '../../../appWrapper/actions';

import { getMessagesReadApi } from '../../api';

export function* getMessagesDataReadFlow(action) {
  try {
    yield call(getMessagesReadApi, action.id);
    yield all([
      put(
        actions.getMessageDataReadSucceeded(action.id),
      ),
      put(
        appActions.getUserDataRequested(),
      ),
    ]);
  } catch (error) {
    yield all([
      put(
        actions.getMessageDataReadFailed(error),
      ),
      put(
        appActions.getUserDataRequested(),
      ),
    ]);
  }
}

export function* watchGetMessageDataReadRequested() {
  yield takeEvery(
    types.GET_MESSAGE_DATA_READ_REQUESTED,
    getMessagesDataReadFlow,
  );
}

export default watchGetMessageDataReadRequested;
