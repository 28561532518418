import logoFoxBusiness from '../../assets/press_logo_fox_business.jpg';
import logoWSJ from '../../assets/press_logo_wsj.jpg';

export default [
  {
    name: 'Fox Business', // Not very important, mostly internal.
    quote: `“RealtyMogul is breaking down barriers by connecting
      investors with major real estate projects.”`,
    logoImage: logoFoxBusiness,
    weight: 3,
  },
  {
    name: 'Wall Street Journal', // Not very important, mostly internal.
    quote: `“RealtyMogul lets investors browse, conduct due diligence, invest and
      track the performance of their investments through an online dashboard.”`,
    logoImage: logoWSJ,
    weight: 1,
  },
];
