import types from './types';

export default {
  getTokensRequested: () => ({
    type: types.GET_TOKENS_REQUESTED,
  }),
  getTokensFailed: (error) => ({
    type: types.GET_TOKENS_FAILED,
    error,
  }),
  getTokensSucceeded: (tokens) => ({
    type: types.GET_TOKENS_SUCCEEDED,
    tokens,
  }),
};
