import React from 'react';
import PropTypes from 'prop-types';

import numeral from 'numeral';

import {
  propTypes,
} from 'styled-system';

import {
  Data,
  ExtendedFlex,
  Formatters,
  Image,
} from '..';

import { round5 } from '../helpers/math';
import pc0 from '../assets/pie_chart_00.png';
import pc5 from '../assets/pie_chart_05.png';
import pc10 from '../assets/pie_chart_10.png';
import pc15 from '../assets/pie_chart_15.png';
import pc20 from '../assets/pie_chart_20.png';
import pc25 from '../assets/pie_chart_25.png';
import pc30 from '../assets/pie_chart_30.png';
import pc35 from '../assets/pie_chart_35.png';
import pc40 from '../assets/pie_chart_40.png';
import pc45 from '../assets/pie_chart_45.png';
import pc50 from '../assets/pie_chart_50.png';
import pc55 from '../assets/pie_chart_55.png';
import pc60 from '../assets/pie_chart_60.png';
import pc65 from '../assets/pie_chart_65.png';
import pc70 from '../assets/pie_chart_70.png';
import pc75 from '../assets/pie_chart_75.png';
import pc80 from '../assets/pie_chart_80.png';
import pc85 from '../assets/pie_chart_85.png';
import pc90 from '../assets/pie_chart_90.png';
import pc95 from '../assets/pie_chart_95.png';
import pc100 from '../assets/pie_chart_100.png';

const images = {
  pc0,
  pc5,
  pc10,
  pc15,
  pc20,
  pc25,
  pc30,
  pc35,
  pc40,
  pc45,
  pc50,
  pc55,
  pc60,
  pc65,
  pc70,
  pc75,
  pc80,
  pc85,
  pc90,
  pc95,
  pc100,
};

const PieChart = ({
  percentage,
  position,
  zIndex,
  left,
  right,
  top,
  bottom,
  m,
  w,
  p,
}) => {
  const val = percentage * 100;
  let image;
  if (val > 0) {
    const roundedPercentage = round5(val);
    image = images[`pc${roundedPercentage}`] || null;
  } else {
    image = images.pc0;
  }
  return (
    <ExtendedFlex
      justify="center"
      align="center"
      flexDirection="column"
      height="80%"
      position="relative"
    >
      <Image
        src={image}
        position={position || 'absolute'}
        zIndex={zIndex || 'auto'}
        left={left || 'auto'}
        right={right || 'auto'}
        top={top || 'auto'}
        bottom={bottom || 'auto'}
        m={m || '0'}
        w={w || 'auto'}
        p={p || '0'}
      />
      <Data
        type="D5"
        m={20}
        display="block"
        zIndex="1"
      >
        {numeral(percentage).format(Formatters.PERCENTAGE)}
      </Data>
    </ExtendedFlex>
  );
};

PieChart.propTypes = {
  percentage: PropTypes.number,
  position: PropTypes.string,
  zIndex: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  top: PropTypes.string,
  bottom: PropTypes.string,
  m: PropTypes.string,
  ...propTypes.m,
  ...propTypes.w,
  p: PropTypes.string,
};

export default PieChart;
