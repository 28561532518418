import React from 'react';
import PropTypes from 'prop-types';

import {
  Theme,
} from '../coreui';

const getFadeInMultiplier = ({ speed }) => {
  switch (speed) {
    default:
    case 'normal':
      return 1;

    case 'fast':
      return 2 / 3;
  }
};

const FadeInChildren = (props) => {
  const {
    children,
    fadeInIndex,
    fadeInDelay,
    isVisible,
  } = props;

  let multiplier = fadeInIndex;
  const fadeInMultiplier = Theme.fadeInDelay * getFadeInMultiplier(props);

  return (
    React.Children.map(children, (child) => {
      // Skip children that aren't rendered.
      if (!child || !React.isValidElement(child)) {
        return null;
      }

      const timeout = (fadeInMultiplier * multiplier) + fadeInDelay;
      const {
        opacity,
        transition,
      } = child.props;

      const defaultOpacity = opacity !== undefined ? opacity : '1';

      multiplier += 1;

      return (
        React.cloneElement(
          child,
          {
            isVisible,
            opacity: isVisible ? defaultOpacity : '0',
            transition: `${transition ? `${transition},` : ''}opacity ${Theme.fadeInDelay}ms ${timeout}ms linear`,
          },
        )
      );
    })
  );
};

FadeInChildren.propTypes = {
  children: PropTypes.node,
  fadeInDelay: PropTypes.number,
  fadeInIndex: PropTypes.number,
  speed: PropTypes.oneOf([
    'normal',
    'fast',
  ]),
};

FadeInChildren.defaultProps = {
  fadeInDelay: 0,
  fadeInIndex: 1,
  speed: 'normal',
};

export default FadeInChildren;
