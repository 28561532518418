/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';
import {
  height,
  themeGet,
  space,
  color,
  flex,
  flexGrow,
  flexBasis,
} from 'styled-system';

import {
  Heading,
} from '../../coreui';

import Radio from './Radio';

// To override the specificity of Material UI components, we need to concat the
// styled-components class onto itself, hence the `&&`.
const StyledFormControlLabel = styled(({
  zebra,
  height,
  ...props
}) => <FormControlLabel {...props} />)`
  && {
    margin-left: 0;
    margin-right: ${(props) => (props.isSmall ? '25px' : 0)};
    ${height}
    align-items: center;
    ${space}
    ${flex}
    ${flexGrow}
    ${flexBasis}
    ${color}
  }

  ${(props) => (props.zebra === 'odd' && !props.disableZebra ? {
    background: themeGet('colors.gray.0')(props),
  } : {})}
`;

class RadioLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovering: false,
    };

    this.handleOnMouseOver = this.handleOnMouseOver.bind(this);
    this.handleOnMouseOut = this.handleOnMouseOut.bind(this);
  }

  handleOnMouseOver() {
    this.setState({ hovering: true });
  }

  handleOnMouseOut() {
    this.setState({ hovering: false });
  }

  render() {
    const {
      label,
      inputProps,
      radioProps,
      ...props
    } = this.props;

    return (
      <StyledFormControlLabel
        onMouseOver={this.handleOnMouseOver}
        onMouseOut={this.handleOnMouseOut}
        onFocus={this.handleOnMouseOver}
        onBlur={this.handleOnMouseOut}
        isSmall={props.isSmall}
        control={(
          <Radio
            hovering={this.state.hovering}
            inputProps={inputProps}
            {...radioProps}
            isSmall={props.isSmall}
          />
        )}
        label={
          <Heading
            type="H7"
          >
            {label}
          </Heading>
        }
        {...props}
      />
    );
  }
}

RadioLabel.propTypes = {
  label: PropTypes.string,
  inputProps: PropTypes.object,
  radioProps: PropTypes.object,
};

RadioLabel.displayName = 'RadioLabel';

export default RadioLabel;
