/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Body,
  ExtendedFlex,
  Link,
} from '../../coreui';

import actions from '../actions';

class PendingDashboardActionCell extends React.Component {
  constructor(props) {
    super(props);
    this.handleWaitlistFlagClick = this.handleWaitlistFlagClick.bind(this);
  }

  handleWaitlistFlagClick() {
    this.props.waitlistFlag(this.props.original.id, this.props.original.fundedWaitList);
  }

  render() {
    const {
      original,
    } = this.props;

    const value = (
      ((this.props.value && original.actionUrl) || original.fundedWaitList) ? (
        <Link
          width={140}
          maxWidth={null}
          py={7}
          height="auto"
          {...(original.fundedWaitList ? {
            onClick: this.handleWaitlistFlagClick,
            type: 'L3mini',
            children: 'Waitlist',
            disabled: !!original.waitListFlagLoading,
            loading: !!original.waitListFlagLoading,
          } : {
            href: original.actionUrl,
            type: 'L1mini',
            children: this.props.value,
          })}
        />
      ) : (this.props.value || 'N/A')
    );

    return (
      <ExtendedFlex
        alignItems="center"
        justify="center"
        m={0}
        position="relative"
      >
        <Body
          type="B3"
          m={0}
          w={1}
          textAlign="center"
        >
          {value}
          {original.wireInstructionsUrl && (
            <Link
              href={original.wireInstructionsUrl}
              type="L4"
              style={{
                display: 'block',
                fontSize: '10px',
              }}
            >
              {original.wireInstructionsText || 'Find wire instructions'}
            </Link>
          )}
        </Body>
      </ExtendedFlex>
    );
  }
}

PendingDashboardActionCell.propTypes = {
  value: PropTypes.string,
  original: PropTypes.object,
  waitlistFlag: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    waitlistFlag: (id, callback) => {
      dispatch(actions.pendingDashboardWaitlistFlagRequested(id, true, callback));
    },
  };
};

export default connect(null, mapDispatchToProps)(PendingDashboardActionCell);
