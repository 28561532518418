/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Theme,
} from '../../coreui';

const ProgressIndicator = (props) => {
  const { stage, itemProps, hover, maxStages, ...additionalProps } = props;
  const totalStages = maxStages > 0 ? maxStages : 3;
  return (
    <ExtendedFlex
      alignItems="center"
      justifyContent="space-between"
      height="10px"
      w={1}
      hover={hover}
      {...additionalProps}
    >
      {
        Array(totalStages).fill().map((item, index) => {
          const position = index;
          const color = position < stage ? Theme.colors.blue[0] : Theme.colors.gray[12];
          return (
            <ExtendedFlex
              key={`progressIndicator:${position}:${totalStages}`}
              height="3px"
              width={`calc(${(1 / totalStages) * 100}% - 12px)`}
              bg={color}
              mx="6px"
              hover={hover}
              {...itemProps}
            />
          );
        })
      }
    </ExtendedFlex>
  );
};

ProgressIndicator.propTypes = {
  stage: PropTypes.number,
  maxStages: PropTypes.number,
  itemProps: PropTypes.object,
  hover: PropTypes.bool,
};

export default ProgressIndicator;
