import types from './types';

export default {
  accredRegisterSucceeded: (data) => ({
    type: types.USER_REGISTER_ACCRED_SUCCEEDED,
    data,
  }),
  userRegisterRequested: (data) => ({
    type: types.USER_REGISTER_REQUESTED,
    data,
  }),
  userRegisterFailed: (error) => ({
    type: types.USER_REGISTER_FAILED,
    error,
  }),
  userRegisterSucceeded: (data) => ({
    type: types.USER_REGISTER_SUCCEEDED,
    data,
  }),
  userLoginRequested: (destination) => ({
    type: types.USER_LOGIN_REQUESTED,
    destination,
  }),
  userLoginFailed: (error) => ({
    type: types.USER_LOGIN_FAILED,
    error,
  }),
  userLoginSucceeded: (data) => ({
    type: types.USER_LOGIN_SUCCEEDED,
    data,
  }),
  userLogoutRequested: () => ({
    type: types.USER_LOGOUT_REQUESTED,
  }),
  userLogoutFailed: (error) => ({
    type: types.USER_LOGOUT_FAILED,
    error,
  }),
  userLogoutSucceeded: (data) => ({
    type: types.USER_LOGOUT_SUCCEEDED,
    data,
  }),
  userSessionRequested: (callback) => ({
    type: types.USER_SESSION_REQUESTED,
    callback,
  }),
  userSessionFailed: (error) => ({
    type: types.USER_SESSION_FAILED,
    error,
  }),
  userSessionSucceeded: (data) => ({
    type: types.USER_SESSION_SUCCEEDED,
    data,
  }),
};
