import React from 'react';
import PropTypes from 'prop-types';

import { Heading, Body } from '../../coreui';
import CheckboxLabel from '../../components/forms/CheckboxLabel';
import CheckboxGroup from '../../components/forms/CheckboxGroup';

const CurrentInvestments = ({
  value,
  handleChange,
  isValid,
}) => {
  return (
    <>
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        Your Current Investments
      </Heading>
      <Body
        type="B2"
      >
        Do you invest in or operate any of the following?
      </Body>
      <CheckboxGroup
        name="currentInvestments"
        value={value}
        onChange={handleChange}
        inputProps={{
          'data-test': 'current_investments',
        }}
        required
        isValid={isValid}
      >
        <CheckboxLabel
          value="invest_pub_reits"
          label="Public REITs"
        />
        <CheckboxLabel
          value="invest_priv_reits"
          label="Private REITs"
        />
        <CheckboxLabel
          value="invest_re_syndications"
          label="Real estate syndications"
        />
        <CheckboxLabel
          value="invest_own_comm_re"
          label="I operate my own commercial real estate investments"
        />
        <CheckboxLabel
          value="invest_op_resi_re"
          label="I operate my own residential real estate investments"
        />
        <CheckboxLabel
          value="invest_none"
          label="None of the above"
        />
      </CheckboxGroup>
    </>
  );
};

CurrentInvestments.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.oneOf([
    'invest_pub_reits',
    'invest_priv_reits',
    'invest_re_syndications',
    'invest_own_comm_re',
    'invest_op_resi_re',
    'invest_none',
  ])),
  isValid: PropTypes.bool,
};

export default CurrentInvestments;
