/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  color,
  propTypes,
} from 'styled-system';

import {
  colorFill,
  hoverColor,
  hoverColorFill,
  extendDefaultTransition,
} from '../helpers/style';

import {
  ExtendedFlex,
  Link,
  Theme,
} from '..';

const Container = styled(ExtendedFlex)`
  transition: ${(props) => extendDefaultTransition(props.transition)};

  a {
    ${color}
  }

  path {
    ${colorFill}
    transition: ${(props) => extendDefaultTransition(props.transition)};
  }

  &.active a,
  a:hover {
    ${hoverColor}
    cursor: pointer;

    path {
      ${hoverColorFill}
    }
  }
`;

const NavIcon = ({
  to,
  href,
  icon,
  label,
  active,
  ...additionalProps
}) => {
  return (
    <Container
      flexDirection="column"
      alignItems="center"
      flex="1"
      {...(active ? {
        className: 'active',
      } : {})}
      {...additionalProps}
    >
      <Link
        to={to}
        href={href}
        type="L8"
        textTransform="uppercase"
        textDecoration="none"
      >
        {
          React.cloneElement(icon, {
            w: [30, 40, 50, 62],
            height: [23, 31, 39, 48],
            viewBox: '0 0 62 48',
            mx: 'auto',
            display: 'block',
          },
          (
            <g
              strokeWidth={1}
              fill={Theme.colors.black}
              fillRule="nonzero"
              stroke={Theme.colors.gray[0]}
            >
              {icon.props.children}
            </g>
          ))
        }
        <span
          style={{
            marginTop: 5,
            display: 'block',
          }}
        >
          {label}
        </span>
      </Link>
    </Container>
  );
};

NavIcon.propTypes = {
  ...propTypes.w,
  ...propTypes.width,
  icon: PropTypes.node,
  label: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  active: PropTypes.bool,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverColorFill: propTypes.string,
};

NavIcon.defaultProps = {
  color: 'black',
  hoverColor: 'blue.0',
  hoverColorFill: 'blue.0',
};

export default NavIcon;
