import React from 'react';
import PropTypes from 'prop-types';
import { SignUp } from 'aws-amplify-react';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import {
  Auth,
  JS,
} from 'aws-amplify';
import { InfoModal, icons } from 'rtmg-component-library';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  ExtendedFlex,
  Heading,
  Body,
  Data,
  InlineTextLink,
} from '../../coreui';

import Form from '../../components/forms/Form';
import Label from '../../components/forms/Label';
import Input from '../../components/forms/Input';
import PasswordInput from '../../components/forms/PasswordInput';
import PhoneInput from '../../components/forms/PhoneInput';
import RadioGroup from '../../components/forms/RadioGroup';
import RadioLabel from '../../components/forms/RadioLabel';
import CheckboxLabel from '../../components/forms/CheckboxLabel';
import PrimaryButton from '../../components/forms/PrimaryButton';
import trackError from './trackError';

const symbols = '\\^$*.\\[\\]{}()?\\-"!@#%&\\/\\\\,><\':;\\|_~`+=';

const RegisterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  max-width: calc(100% - 30px);
  height: 100%;
  margin: 15px;
  padding: 0 20px 55px;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  button {
    margin: 0;
    width: 100vw;
    max-width: 100% !important;
  }
  @media (min-width: 768px) {
    left: 280px;
    button {
      max-width: 100%;
      width: 470px;
    }
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 15px 0 25px;
  label {
    margin-bottom: 25px;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    label {
      width: 45%;
      margin-bottom: 0;
    }
  }
`;

const PasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 25px;
  .register-modal-password-hint {
    margin-top: 15px;
    font: normal normal 400 10px/12px var(--font-sans);;
    letter-spacing: 0;
    color: #9B9B9B;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    .password-wrapper-item {
      width: 45%;
      margin-bottom: 0;
    }
    label {
      width: 100%;
    }
}
`;

class CustomSignUp extends SignUp {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      submitting: false,
      accountPurpose: 'investing',
      usCitizen: undefined,
      internationalPhone: false,
      sms: false,
      phoneValid: undefined,
      showPhone: true,
      isAccred: false,
      modalOpen: false,
    };
    this._validAuthStates = ['signUp'];

    this.phoneInput = null;
    this.setPhoneInputRef = (ref) => { this.phoneInput = ref; };

    this.checkContact = this.checkContact.bind(this);
    this.signIn = this.signIn.bind(this);
    this.updateState = this.updateState.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handlePhoneInputChange = this.handlePhoneInputChange.bind(this);
    this.handleSelectFlag = this.handleSelectFlag.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.authState !== 'signUp' && state.submitting) {
      return {
        submitting: false,
      };
    }
    return null;
  }

  handlePhoneInputChange(isValid, number, country) {
    this.setState({
      phoneValid: isValid,
    });
    this.inputs = this.inputs || {};
    // eslint-disable-next-line camelcase
    this.inputs.phone_line_number = number.replace(/[\s-()]/g, '');
    // eslint-disable-next-line camelcase
    this.inputs.dial_code = `+${country.dialCode}`;
  }

  handleSelectFlag(currentNumber, countryData) {
    const isUS = countryData.iso2 === 'us';

    this.updateState({
      internationalPhone: !isUS,
      usCitizen: !isUS ? 'no' : undefined,
    });
  }

  handleRadioChange(evt) {
    const { name, value, checked } = evt.target;

    const setValue = checked ? value : undefined;
    this.updateState({
      [name]: setValue,
    });

    if (name === 'accountType') {
      this.props.history.replace(this.props.location.pathname, {
        ...this.props.history.state,
        accountPurpose: setValue,
      });
    }
  }

  error(err) {
    this.updateState({ submitting: false });
    trackError('SignUp Error', { error: err });
    super.error(err);
  }

  updateState(...args) {
    this.setState(...args);
  }

  handleFormSubmit(e, callback) {
    e.preventDefault();
    if (!this.state.submitting) {
      if (!this.state.showPhone || this.state.phoneValid) {
        this.updateState({ submitting: true });
        callback();
      }
    }
  }

  signUp() {
    if (!this.inputs.dial_code) {
      // eslint-disable-next-line camelcase
      this.inputs.dial_code = this.getDefaultDialCode();
    }
    const validation = this.validate();
    if (validation && validation.length > 0) {
      return this.error(`The following fields need to be filled out: ${validation.join(', ')}`);
    }
    if (!Auth || typeof Auth.signUp !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    const signupInfo = {
      username: this.inputs.username,
      password: this.inputs.password,
      attributes: { },
    };

    const inputKeys = Object.keys(this.inputs);
    const inputVals = Object.values(this.inputs);

    inputKeys.forEach((key, index) => {
      if (key === 'password_confirm') return;
      if (!['username', 'password', 'checkedValue', 'dial_code'].includes(key)) {
        if (key !== 'phone_line_number' && key !== 'dial_code' && key !== 'error') {
          const newKey = `${this.needPrefix(key) ? 'custom:' : ''}${key}`;
          signupInfo.attributes[newKey] = inputVals[index];
        } else if (inputVals[index]) {
          // eslint-disable-next-line camelcase
          signupInfo.attributes.phone_number = `${this.inputs.dial_code}${this.inputs.phone_line_number.replace(/[-()]/g, '')}`;
        }
      }
    });

    Auth.signUp(signupInfo).then(() => {
      this.signIn();
    })
      .catch((err) => this.error(err));

    return null;
  }

  async signIn() {
    const { username = '', password } = this.inputs;
    if (!Auth || typeof Auth.signIn !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    this.setState({ loading: true });
    try {
      const user = await Auth.signIn(username, password);
      const id = this.props.cookies.get('rm_io_id');
      this.props.userRegisterRequested({
        sms: this.state.sms,
        id,
        accredited: this.state.isAccred,
      });
      const _dataLayer = window.dataLayer || [];
      _dataLayer.push({ email: username });
      this.props.cookies.remove('rm_io_id', { path: '/' });
      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        this.changeState('confirmSignIn', user);
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.changeState('requireNewPassword', user);
      } else if (user.challengeName === 'MFA_SETUP') {
        this.changeState('TOTPSetup', user);
      } else {
        this.checkContact(user);
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        this.changeState('confirmSignUp', { username });
      } else if (err.code === 'PasswordResetRequiredException') {
        this.changeState('forgotPassword', { username });
      } else {
        this.error(err);
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  checkContact(user) {
    if (!Auth || typeof Auth.verifiedContact !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    Auth.verifiedContact(user)
      .then((data) => {
        if (!JS.isEmpty(data.verified)) {
          this.changeState('signedIn', user);
        } else {
          Auth.verifyCurrentUserAttribute('email')
            .then(() => {
              this.changeState('confirmSignUp', user.username);
            })
            .catch((err) => this.error(err));
        }
      });
  }

  showComponent() {
    const {
      showNew,
    } = this.props;
    const email = this.props.cookies.get('rm_captured_email');
    const {
      usCitizen,
      strings = {
        passwordLabel: 'Password',
        buttonText: 'Continue',
      },
    } = this.state;

    if (this.checkCustomSignUpFields()) {
      this.signUpFields = this.props.signUpConfig.signUpFields;
    }

    // Set any defaults.
    // We need to pull this out of state because this.inputs gets reset by the
    // parent class when we're not the component being output.
    this.inputs = this.inputs || {};
    this.inputs['custom:account_purpose'] = 'investing';
    if (typeof usCitizen === 'undefined'
        && typeof this.inputs['custom:us_citizen'] !== 'undefined') {
      delete this.inputs['custom:us_citizen'];
    } else if (usCitizen) {
      this.inputs['custom:us_citizen'] = usCitizen;
    }

    // Make sure the default value email added to the inputs.
    if (email && !Object.prototype.hasOwnProperty.call(this.inputs, 'username')) {
      this.inputs.username = email.toLowerCase();
    }

    return (
      showNew ? (
        <RegisterWrapper>
          <Form
            onSubmit={(e) => this.handleFormSubmit(e, this.signUp)}
            validationOptions={{ confirmPassword: true }}
            style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
          >
            <NameWrapper>
              <Input
                id="firstName"
                isNew
                label="First Name"
                placeholder=" "
                name="given_name"
                fontSize="12px"
                isSmall
                onChange={(e) => {
                  const { value } = e.target;
                  e.target.value = value.charAt(0).toUpperCase() + value.slice(1);
                  this.handleInputChange(e);
                }}
                data-test="first_name"
                maxLength={40}
                required
              />
              <Input
                id="lastName"
                label="Last Name"
                isNew
                placeholder=" "
                name="family_name"
                fontSize="12px"
                isSmall
                onChange={(e) => {
                  const { value } = e.target;
                  e.target.value = value.charAt(0).toUpperCase() + value.slice(1);
                  this.handleInputChange(e);
                }}
                data-test="last_name"
                maxLength={80}
                required
              />
            </NameWrapper>
            <Label color="#9B9B9B" fontSize="10px" fontWeight="400" letterSpacing="0" py={0} my={0}>
              <span>ARE YOU AN ACCREDITED INVESTOR?
                <button
                  aria-label="Are you an accredited investor?"
                  type="button"
                  onClick={() => {
                    this.setState({
                      modalOpen: true,
                    });
                  }}
                  style={{ background: 'transparent', border: 'none', cursor: 'pointer', verticalAlign: 'middle' }}
                >
                  <icons.QuestionMark width="12" height="12" backgroundColor="#D2D2D2" />
                </button>
              </span>
            </Label>
            <RadioGroup
              name="isAccred"
              value={this.state.isAccred}
              row
              style={{ marginTop: '10px' }}
              disableZebra
              removePadding
              isSmall
              onChange={(e) => {
                this.setState({
                  isAccred: e.target.value,
                });
              }}
              inputProps={{
                'data-test': 'isAccred',
                required: true,
                marginRight: '25px',
              }}
            >
              <RadioLabel
                value="yes"
                label="Yes"
              />
              <RadioLabel
                value="no"
                label="No"
              />
            </RadioGroup>
            {
              this.state.showPhone &&
              <div style={{ width: '100%', marginTop: '15px' }}>
                <PhoneInput
                  setPhoneInputRef={this.setPhoneInputRef}
                  id="phone"
                  name="phone_number"
                  autoComplete="tel"
                  fontSize="14px"
                  onChange={this.handlePhoneInputChange}
                  onSelectFlag={this.handleSelectFlag}
                  data-test="phone"
                  inputProps={{
                    required: true,
                    'data-test': 'phone',
                  }}
                />
                  {
                    this.state.phoneValid === false &&
                    <Data
                      type="D4"
                      display="block"
                      my={2}
                      color="red"
                    >
                      Please enter a valid phone number.
                    </Data>
                  }
                <CheckboxLabel
                  style={{ backgroundColor: '#FFF', padding: '10px 20px' }}
                  label={(
                    <Body
                      type="B6"
                      display="block"
                      mb={0}
                      mt={3}
                      color="#606060"
                    >
                      OPTIONAL: I would like to receive alerts regarding platform offerings and updates from RealtyMogulCo-Notifications. Message frequency varies. Text HELP to 66485 for help. Text STOP to 66485 to stop.
                    </Body>
                  )}
                  checkboxProps={{
                    checked: !!this.state.sms,
                    onChange: (event) => {
                      this.setState({
                        sms: event.target.checked,
                      });
                    },
                  }}
                  inputProps={{
                    'data-test': 'commpref',
                  }}
                />
              </div>
            }
            <div style={{ width: '100%' }}>
              <Input
                id="username"
                isNew
                label="E-mail"
                placeholder=" "
                name="username"
                type="email"
                fontSize="12px"
                isSmall
                autoComplete="email"
                defaultValue={this.props.cookies.get('rm_captured_email') && this.props.cookies.get('rm_captured_email').toLowerCase()}
                onChange={(e) => {
                  e.target.value = (e.target.value || '').toLowerCase();
                  this.handleInputChange(e);
                }}
                data-test="email"
                required
              />
            </div>
            <PasswordWrapper>
              <div className="password-wrapper-item">
                <Input
                  id="password"
                  isNew
                  label="Password"
                  name="password"
                  onChange={this.handleInputChange}
                  autoComplete="new-password"
                  placeholder=" "
                  data-test="password"
                  fontSize="12px"
                  isSmall
                  type="password"
                  pattern={`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[${symbols}])[A-Za-z\\d${symbols}]{8,}$`}
                  required
                />
                <div className="register-modal-password-hint">
                  Password should be at least 8 characters, contain an uppercase
                  character, a lowercase character, a number and a symbol.
                </div>
              </div>
              <div className="password-wrapper-item">
                <Input
                  id="password_confirm"
                  isNew
                  label="Confirm Password"
                  name="password_confirm"
                  onChange={this.handleInputChange}
                  autoComplete="new-password"
                  fontSize="12px"
                  isSmall
                  type="password"
                  placeholder=" "
                  data-test="password_confirm"
                  pattern={`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[${symbols}])[A-Za-z\\d${symbols}]{8,}$`}
                  required
                />
              </div>
            </PasswordWrapper>
            <Body
              type="B5"
              display="block"
              color="#9B9B9B"
              fontWeight="300"
              mt={15}
              letterSpacing={0}
              fontSize={10}
              mb={0}
              mx="auto"
              w={1}
              textAlign="center"
            >
              By clicking &quot;Submit&quot; you are agreeing to our <InlineTextLink display="inline" href="/terms-service" target="_blank">Terms of Service</InlineTextLink> and <InlineTextLink display="inline" href="/privacy-policy" target="_blank">Privacy Policy</InlineTextLink>.
            </Body>
            <ButtonWrapper>
              <PrimaryButton
                loading={this.state.submitting}
                disable={this.state.submitting}
                buttonType="submit"
                className="register-submit-button"
              >
                <span className="rm-registration-cta-button-text">REGISTER FOR REALTYMOGUL</span>
              </PrimaryButton>
            </ButtonWrapper>
          </Form>
          {
            this.state.modalOpen &&
            <InfoModal
              onClose={() => {
                this.setState({
                  modalOpen: false,
                });
              }}
              title="ACCREDITED INVESTOR?"
            >
              <>
                <p>
                  Generally, you are an accredited investor if ANY of the following are
                  true:
                </p>
                <li>
                  Average annual income over the past two years of $200k+ for individuals or $300k+ for joint households OR
                </li>
                <li>Net worth greater than $1MM (excluding your primary residence) OR</li>
                <li>You hold a Series 7, Series 65 or Series 82</li>
              </>
            </InfoModal>
          }
        </RegisterWrapper>
      )
        :
        (
          <ExtendedFlex
            flexDirection="column"
            flexWrap="wrap"
            w="calc(100% - 60px)"
            maxWidth={760}
            mx="auto"
            my={75}
          >
            <Heading
              type="H2"
              style={{ marginBottom: 0 }}
            >
              JOIN REALTYMOGUL
            </Heading>
            <Body type="B2">
              Unlock investment opportunities.
            </Body>
            <Form
              onSubmit={(e) => this.handleFormSubmit(e, this.signUp)}
            >
              <Label htmlFor="firstName" color="#202020" fontSize="18px" fontWeight="400" letterSpacing="0.63px">
                First Name
                <Input
                  id="firstName"
                  placeholder="ENTER FIRST NAME HERE"
                  name="given_name"
                  onChange={(e) => {
                    const { value } = e.target;
                    e.target.value = value.charAt(0).toUpperCase() + value.slice(1);
                    this.handleInputChange(e);
                  }}
                  data-test="first_name"
                  maxLength={40}
                  required
                />
              </Label>
              <Label htmlFor="lastName" color="#202020" fontSize="18px" fontWeight="400" letterSpacing="0.63px">
                Last Name
                <Input
                  id="lastName"
                  placeholder="ENTER LAST NAME HERE"
                  name="family_name"
                  onChange={this.handleInputChange}
                  data-test="last_name"
                  maxLength={80}
                  required
                />
              </Label>
              <Label color="#202020" fontSize="18px" fontWeight="400" letterSpacing="0.63px">
                <span>ARE YOU AN ACCREDITED INVESTOR?
                  <button
                    aria-label="Are you an accredited investor?"
                    type="button"
                    onClick={() => {
                      this.setState({
                        modalOpen: true,
                      });
                    }}
                    style={{ background: 'transparent', border: 'none', cursor: 'pointer', marginLeft: '5px', verticalAlign: 'middle' }}
                  >
                    <icons.QuestionMark width="18" height="18" />
                  </button>
                </span>
              </Label>
              <RadioGroup
                name="isAccred"
                value={this.state.isAccred}
                onChange={(e) => {
                  this.setState({
                    isAccred: e.target.value,
                  });
                }}
                inputProps={{
                  'data-test': 'isAccred',
                  required: true,
                }}
              >
                <RadioLabel
                  value="yes"
                  label="Yes"
                />
                <RadioLabel
                  value="no"
                  label="No"
                />
              </RadioGroup>
              {
                this.state.showPhone &&
                <>
                  <Label htmlFor="phone" color="#202020" fontSize="18px" fontWeight="400" letterSpacing="0.63px">
                    PHONE
                    <PhoneInput
                      setPhoneInputRef={this.setPhoneInputRef}
                      id="phone"
                      name="phone_number"
                      autoComplete="tel"
                      onChange={this.handlePhoneInputChange}
                      onSelectFlag={this.handleSelectFlag}
                      data-test="phone"
                      inputProps={{
                        required: true,
                        'data-test': 'phone',
                      }}
                    />
                    {
                      this.state.phoneValid === false &&
                      <Data
                        type="D4"
                        display="block"
                        my={2}
                        color="red"
                      >
                        Please enter a valid phone number.
                      </Data>
                    }
                  </Label>
                  <CheckboxLabel
                    style={{ backgroundColor: '#F7F8F8', padding: '20px' }}
                    label={(
                      <Body
                        type="B1"
                        display="block"
                        mt={3}
                        mb={0}
                        color="#606060"
                      >
                        OPTIONAL: I would like to receive alerts regarding platform offerings and updates from RealtyMogulCo-Notifications. Message frequency varies. Text HELP to 66485 for help. Text STOP to 66485 to stop.
                      </Body>
                    )}
                    checkboxProps={{
                      checked: !!this.state.sms,
                      onChange: (event) => {
                        this.setState({
                          sms: event.target.checked,
                        });
                      },
                    }}
                    inputProps={{
                      'data-test': 'commpref',
                    }}
                  />
                </>
              }
              <Label htmlFor="username" color="#202020" fontSize="18px" fontWeight="400" letterSpacing="0.63px">
                E-mail
                <Input
                  id="username"
                  placeholder="E-mail"
                  name="username"
                  type="email"
                  autoComplete="email"
                  defaultValue={this.props.cookies.get('rm_captured_email') && this.props.cookies.get('rm_captured_email').toLowerCase()}
                  onChange={(e) => {
                    e.target.value = (e.target.value || '').toLowerCase();
                    this.handleInputChange(e);
                  }}
                  data-test="email"
                  required
                />
              </Label>
              <Label
                htmlFor="password"
                color="#202020"
                fontSize="18px"
                fontWeight="400"
                letterSpacing="0.63px"
                style={{ marginBottom: '0' }}
              >
                <span className="rm-registration-password-label">{strings?.passwordLabel}</span>
                <PasswordInput
                  id="password"
                  name="password"
                  onChange={this.handleInputChange}
                  autoComplete="new-password"
                  placeholder="ENTER PASSWORD HERE"
                  data-test="password"
                  pattern={`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[${symbols}])[A-Za-z\\d${symbols}]{8,}$`}
                  required
                />
                <Body
                  type="B5"
                  mt={10}
                  mb={0}
                  display="block"
                  color="gray.7"
                >
                  Password should be at least 8 characters, contain an uppercase
                  character, a lowercase character, a number and a symbol.
                </Body>
              </Label>
              <Body
                type="B5"
                display="block"
                color="#707070"
                mt={3}
                mb={0}
                mx="auto"
                textAlign="center"
              >
                By clicking &quot;Submit&quot; you are agreeing to our <InlineTextLink display="inline" href="/terms-service" target="_blank">Terms of Service</InlineTextLink> and <InlineTextLink display="inline" href="/privacy-policy" target="_blank">Privacy Policy</InlineTextLink>.
              </Body>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <PrimaryButton
                  loading={this.state.submitting}
                  disable={this.state.submitting}
                  buttonType="submit"
                  className="register-submit-button"
                >
                  <span className="rm-registration-cta-button-text">{strings?.buttonText}</span>
                </PrimaryButton>
              </div>
              <Body
                type="B5"
                display="block"
                color="#707070"
                mt={50}
                mb={0}
                mx="auto"
                textAlign="center"
              >
                Already have an account? <InlineTextLink display="inline" href="/user/login" target="_blank">Sign in</InlineTextLink>.
              </Body>
            </Form>
            {
              this.state.modalOpen &&
              <InfoModal
                onClose={() => {
                  this.setState({
                    modalOpen: false,
                  });
                }}
                title="ACCREDITED INVESTOR?"
              >
                <>
                  <p>
                    Generally, you are an accredited investor if ANY of the following are
                    true:
                  </p>
                  <li>
                    Average annual income over the past two years of $200k+ for individuals or $300k+ for joint households OR
                  </li>
                  <li>Net worth greater than $1MM (excluding your primary residence) OR</li>
                  <li>You hold a Series 7, Series 65 or Series 82</li>
                </>
              </InfoModal>
            }
          </ExtendedFlex>
        )
    );
  }
}

CustomSignUp.propTypes = {
  ...CustomSignUp.propTypes,
  history: PropTypes.object,
  location: PropTypes.object,
  accountPurpose: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  steps: PropTypes.array,
  userRegisterRequested: PropTypes.func,
  showNew: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  pageVariant: state.variants?.userRegister?.v,
});

export default withCookies(
  withRouter(
    connect(mapStateToProps)(CustomSignUp),
  ),
);
