import React from 'react';
import PropTypes from 'prop-types';

import {
  Heading,
  Body,
  Link,
  Tooltip,
  TooltipIcon,
  ExtendedFlex,
} from '../../coreui';

import WatchlistItem from './WatchlistItem';

const Watchlist = ({
  tooltip,
  items,
  isApp,
  frontendPrefix,
}) => {
  if (!tooltip) {
    return null;
  }
  const length = items.length;
  return (
    <>
      <ExtendedFlex
        flexDirection="row"
        w={1}
      >
        <Heading
          type="H9"
          my={0}
          mr={10}
        >
          Watchlist
        </Heading>
        <TooltipIcon
          tooltipId="pendingWatchlistInvestmentsTooltip"
        />
        <Tooltip id="pendingWatchlistInvestmentsTooltip">
          <Body type="B5">
            {tooltip}
          </Body>
        </Tooltip>
      </ExtendedFlex>
      <>
        {
          length > 0 &&
          items.map((item) => (
            <WatchlistItem
              key={`watchlist:${item.id}`}
              item={item}
            />
          ))
        }
        {
          length === 0 &&
          <>
            <Body
              type="B1"
              mt={30}
              mb={50}
            >
              You have no watchlist items.
            </Body>
            <Link
              type="L1"
              {...(isApp ? {
                href: `${frontendPrefix}/investment-opportunities`,
              } : {
                to: '/investment-opportunities',
              })}
            >
              Browse Opportunities
            </Link>
          </>
        }
      </>
    </>
  );
};

Watchlist.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  tooltip: PropTypes.node,
  isApp: PropTypes.bool,
  frontendPrefix: PropTypes.string,
};

export default Watchlist;
