import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Flex,
  ExtendedFlex,
  Heading,
  CarouselLarge,
  VisibilitySensor,
  Theme,
} from '../../coreui';

import DynamicBrowseJoinLink from '../../helpers';

import createContentSlide from './createContentSlide';
import slideCollection from './slideCollection';

class SimpleSteps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.handleSensorChange = this.handleSensorChange.bind(this);
  }

  handleSensorChange(isVisible) {
    if (isVisible) {
      this.setState({
        isVisible: true,
      });
    }
  }

  render() {
    const {
      isVisible,
    } = this.state;

    const {
      isLoggedIn,
      hideTitle,
      anonText,
      slideSet,
    } = this.props;

    const slides = slideSet || slideCollection;

    return (
      <Box
        py={[60, 80, 100, 120, 140]}
      >
        <Flex
          justify="center"
          align="center"
          mb={[40, 40, 40, 60, 80]}
        >
          {
            !hideTitle &&
            <Heading
              type="H3"
              display="block"
              textAlign="center"
              maxWidth={['85%', 600, 540, 540, 700]}
              m={0}
              opacity={isVisible ? '1' : '0'}
              transition={`opacity ${Theme.fadeInDelay}ms 0s linear`}
            >
              4 Simple Steps to Invest in Commercial Real Estate
            </Heading>
          }
          <VisibilitySensor
            callback={this.handleSensorChange}
          />
        </Flex>
        <CarouselLarge>
          {
            slides.map((slideInfo) => {
              return createContentSlide(
                slideInfo,
                isVisible,
              );
            })
          }
        </CarouselLarge>
        <ExtendedFlex
          justify="center"
          mt={[50, 60, 60, 60, 80]}
          opacity={isVisible ? '1' : '0'}
          transition={`opacity ${Theme.fadeInDelay}ms 1.2s linear`}
        >
          <DynamicBrowseJoinLink
            type="L1"
            isLoggedIn={isLoggedIn}
            anonText={anonText}
          />
        </ExtendedFlex>
        <VisibilitySensor
          callback={this.handleSensorChange}
        />
      </Box>
    );
  }
}

SimpleSteps.propTypes = {
  isLoggedIn: PropTypes.bool,
  hideTitle: PropTypes.bool,
  anonText: PropTypes.string,
  slideSet: PropTypes.array,
};

export default SimpleSteps;
