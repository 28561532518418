import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import {
  Box,
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
  NavLink,
  VisibilitySensor,
} from '../../../coreui';

import ClickableEnvironmentInCarousel from '../../components/ClickableEnvironmentInCarousel';
import FadeIn from '../../../components/FadeIn';

const EarnPassiveIncomeSection1 = ({
  slideInfo,
  opacity,
  handleSensorChange,
}) => {
  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
    >
      <Box
        w={1}
        px={['15%', '25%', '10%', '10%', '10%']}
      >
        <ExtendedFlex
          flexDirection="column"
          align={['center', 'center', 'flex-end', 'flex-end', 'flex-end']}
        >
          <ExtendedFlex
            maxWidth={['100%', '100%', 400, 400, 540]}
            flexDirection="column"
          >
            <VisibilitySensor
              callback={handleSensorChange}
            />
            <FadeIn>
              <Heading
                type="H8"
                m={0}
                mb="3em"
                opacity={opacity}
                wordWrap="break"
              >
                {slideInfo.hookTitle}
              </Heading>
              <Heading
                type="H2"
                m={0}
                mb="1em"
                opacity={opacity}
              >
                {ReactHtmlParser(slideInfo.mainTitle)}
              </Heading>
              <Body
                type="B1"
                mb={[0, 0, 80, 80, 100]}
              >
                {slideInfo.bodyContent}
              </Body>
            </FadeIn>
            <VisibilitySensor
              callback={handleSensorChange}
            />
            <Box>
              <ExtendedBox
                display={['none', 'none', 'block']}
              >
                <ClickableEnvironmentInCarousel>
                  <FadeIn
                    fadeInIndex={4}
                  >
                    <NavLink
                      type="L1"
                      to={slideInfo.linkButtonDestination}
                      opacity={opacity}
                    >
                      Learn more
                    </NavLink>
                  </FadeIn>
                </ClickableEnvironmentInCarousel>
              </ExtendedBox>
            </Box>
          </ExtendedFlex>
        </ExtendedFlex>
      </Box>
    </Box>
  );
};

EarnPassiveIncomeSection1.propTypes = {
  opacity: PropTypes.string,
  slideInfo: PropTypes.object,
  handleSensorChange: PropTypes.func,
};

export default EarnPassiveIncomeSection1;
