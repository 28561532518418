/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import HighchartsReact from 'highcharts-react-official';

class LoadableHighcharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Highcharts: null,
    };
  }

  UNSAFE_componentWillMount() {
    import(
      /* webpackChunkName: "highcharts" */
      'highcharts'
    ).then((Highcharts) => {
      this.setState({ Highcharts: Highcharts.default });
    });
  }

  render() {
    const {
      Highcharts,
    } = this.state;
    if (Highcharts) {
      return <HighchartsReact highcharts={Highcharts} {...this.props} />;
    }
    return null;
  }
}

export default LoadableHighcharts;
