export default {
  GET_LATEST_ISQ_REQUESTED: 'GET_LATEST_ISQ_REQUESTED',
  GET_LATEST_ISQ_SUCCEEDED: 'GET_LATEST_ISQ_SUCCEEDED',
  GET_LATEST_ISQ_FAILED: 'GET_LATEST_ISQ_FAILED',
  SET_LATEST_ISQ_REQUESTED: 'SET_LATEST_ISQ_REQUESTED',
  SET_LATEST_ISQ_SUCCEEDED: 'SET_LATEST_ISQ_SUCCEEDED',
  SET_LATEST_ISQ_FAILED: 'SET_LATEST_ISQ_FAILED',
  SET_IR_ISQ_REQUESTED: 'SET_IR_ISQ_REQUESTED',
  SET_IR_ISQ_SUCCEEDED: 'SET_IR_ISQ_SUCCEEDED',
  SET_IR_ISQ_FAILED: 'SET_IR_ISQ_FAILED',
};
