import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Data,
  NavLink,
} from '../../coreui';

const FooterSiteMapList = ({
  data,
}) => {
  return (
    <Flex
      flexDirection="column"
      w={['50%', 'auto', 'auto', 'auto']}
      pb={[20]}
      pr={[20, 0]}
      mr={[0, 0, 25, 60]}
    >
      <Data
        type="D1"
        color="white"
        mb={30}
      >
        {data.label}
      </Data>
      {
        data.children.map(
          (child) => {
            return (
              <NavLink
                key={child.id}
                to={child.url}
                drupal={child.drupal}
                target={child.target}
                type="L6"
                color="gray.12"
                hoverColor="gray.10"
                mb={15}
                withActiveStyle={false}
              >
                {child.label}
              </NavLink>
            );
          },
        )
      }
    </Flex>
  );
};

FooterSiteMapList.propTypes = {
  data: PropTypes.object,
};

export default FooterSiteMapList;
