/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import {
  Data,
  ExtendedFlex,
  Image,
  Link,
} from '../../../coreui';

const TableCell = (formatter, onClick, isNew = false) => {
  const Component = (props) => {
    let value;
    if (props.column.id === 'name' && (props.value && props.original.image)) {
      const ioLink = props.original.investment && props.original.investment.link && !isNew;
      return (
        <ExtendedFlex
          flexDirection="row"
          alignItems="center"
          m={0}
        >
          <Link
            {...(ioLink ? {
              href: ioLink,
              target: '_blank',
            } : {
              onClick: () => onClick(props.original.i),
            })}
          >
            <Image
              src={props.original.image.thumb}
              width="90px"
              height="50px"
            />
          </Link>
          <Link
            {...(ioLink ? {
              href: ioLink,
              target: '_blank',
            } : {
              onClick: () => onClick(props.original.i),
            })}
          >
            <Data
              type="D3"
              m={0}
              ml={[8, 10, 13, 15, 20]}
              color="blue.0"
              display="block"
              fontWeight={isNew ? 'bold' : 'normal'}
            >
              {props.value}
            </Data>
          </Link>
        </ExtendedFlex>
      );
    }
    if (props.column.id === 'location' && (props.original.city && props.original.state)) {
      value = `${props.original.city}, ${props.original.state}`;
    } else {
      value = formatter ? numeral(props.value).format(formatter) : props.value;
    }
    return (
      <ExtendedFlex
        alignItems="center"
        m={0}
      >
        <Data
          type="D3"
          m={0}
        >
          {value}
        </Data>
      </ExtendedFlex>
    );
  };

  Component.propTypes = {
    value: PropTypes.string,
    column: PropTypes.object,
    original: PropTypes.object,
  };

  return Component;
};

export default TableCell;
