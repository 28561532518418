import appWrapperTypes from '../appWrapper/types';
import types from './types';
import ioDetailsTypes from '../ioDetails/types';

const initialState = {
  user: {
    isLoggedIn: false,
  },
  id: null,
  isReit: null,
  loaded: false,
  signedDocuments: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case appWrapperTypes.APP_INIT:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data.user,
        },
      };

    case appWrapperTypes.GET_USER_DATA_SUCCEEDED:
      return {
        ...state,
        user: action.user,
      };

    case types.INVESTED_PAGE_REQUESTED:
      return {
        ...state,
        id: action.id,
        loaded: false,
        signedDocuments: [],
      };

    case types.INVESTED_PAGE_SUCCEEDED:
      return {
        ...state,
        loaded: false,
        signedDocuments: action.data.signedDocuments,
      };

    case types.INVESTED_PAGE_FAILED:
      return {
        ...state,
        loaded: true,
        status: action.error.response.status,
      };

    case ioDetailsTypes.GET_IO_DATA_SUCCEEDED:
      return {
        ...state,
        loaded: true,
      };

    default:
      return {
        ...state,
      };
  }
};
