import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Body,
  Heading,
  ExtendedFlex,
  Image,
  Data,
} from '../../coreui';

import FadeIn from '../../components/FadeIn';
import ModalLink from '../../components/modalLink';
import BigNumber from '../../components/BigNumber';

import DynamicBrowseJoinLink from '../../helpers';

const chartAndCopy = ({
  heading,
  body,
  chart = undefined,
  bigNumber = undefined,
  disclosure,
  legend,
  toggleModal,
  isLoggedIn,
  backgroundColor,
}) => {
  const content = (
    disclosure &&
    disclosure.map((item) => {
      return (
        <Body
          type="B5"
          color="black"
          display="block"
        >
          {item}
        </Body>
      );
    })
  );

  return (
    <Box
      py={[60, 80, 100, 120, 140]}
      bg={backgroundColor}
    >
      <ExtendedFlex
        flexDirection={['column', 'column', 'row', 'row', 'row']}
        px={0}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width={[1, 1, 0.5, 0.5, 0.5]}
          maxWidth={540}
        >
          <FadeIn>
            <ExtendedFlex
              flexDirection="column"
              align={['center', 'center', 'flex-end']}
              mb={[40, 40, 0, 0, 0]}
            >
              <ExtendedFlex
                flexDirection="column"
                align={['center']}
                mx={[0, 0, 0, '15%']}
              >
                {
                  chart ?
                    <Image
                      src={chart}
                      maxWidth="542px"
                      width={['75%', '75%', '85%', '100%', '100%']}
                      IEAspectRatioFix
                    />
                    :
                    null
                }
                {
                  bigNumber &&
                  <BigNumber
                    number={bigNumber.number}
                    type={bigNumber.type}
                  />
                }
                {
                  legend &&
                  <ExtendedFlex
                    align="center"
                    flexDirection="column"
                  >
                    <Data
                      type="D1"
                      maxWidth="100%"
                      display="block"
                      textAlign="center"
                      lineHeight="2em"
                      mx={20}
                    >
                      {legend}
                    </Data>
                    {
                      disclosure &&
                      <ModalLink
                        content={content}
                        handleClick={toggleModal}
                        title="Disclosure"
                      />
                    }
                  </ExtendedFlex>
                }

              </ExtendedFlex>
            </ExtendedFlex>
          </FadeIn>
        </Box>
        <Box
          width={[1, 1, 0.5, 0.5, 0.5]}
        >
          <FadeIn>
            <ExtendedFlex
              flexDirection="column"
              align={['center', 'center', 'flex-start']}
            >
              <ExtendedFlex
                flexDirection="column"
                align={['center', 'center', 'flex-start', 'flex-start', 'flex-start']}
                ml={[0, 0, 20, 40, 60]}
                maxWidth={['100%', '100%', 320, 400, 540]}
              >
                <Heading
                  type="H2"
                  display="block"
                  textAlign={['center', 'center', 'left', 'left', 'left']}
                  maxWidth={['75%', 540, '100%', '100%', '100%']}
                  m={0}
                  mb="0.5em"
                >
                  {heading}
                </Heading>
                {
                  body &&
                  body.map((item) => {
                    return (
                      <Body
                        key={item}
                        type="B1"
                        textAlign={['center', 'center', 'left', 'left', 'left']}
                        mb={0}
                        maxWidth={['75%', 540, '100%', '100%', '100%']}
                        display="block"
                      >
                        {body}
                      </Body>
                    );
                  })
                }
                <Box
                  mt={[40]}
                >
                  <DynamicBrowseJoinLink
                    isLoggedIn={isLoggedIn}
                    type="L1"
                    loggedInText="View Investments"
                    anonText="Join Now"
                  />
                </Box>
              </ExtendedFlex>
            </ExtendedFlex>
          </FadeIn>
        </Box>
      </ExtendedFlex>
    </Box>
  );
};

chartAndCopy.propTypes = {
  heading: PropTypes.string,
  chart: PropTypes.string,
  backgroundColor: PropTypes.string,
  disclosure: PropTypes.any,
  legend: PropTypes.any,
  body: PropTypes.arrayOf(PropTypes.string),
  toggleModal: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  bigNumber: PropTypes.object,
};

export default chartAndCopy;
