import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import { postWebToSalesforce } from '../../api';

export function* postWebToSalesforceRequested(action) {
  const {
    data: request,
  } = action;

  const {
    type = 'lead',
    data,
  } = request;

  try {
    const response = yield call(postWebToSalesforce, type, data);

    yield put(
      actions.postWebToSalesforceSucceeded({
        request,
        response,
      }),
    );
  } catch (error) {
    yield put(
      actions.postWebToSalesforceFailed(error),
    );
  }
}

export function* watchRequested() {
  yield takeEvery(
    types.POST_WEB_TO_SALESFORCE_REQUESTED,
    postWebToSalesforceRequested,
  );
}

export default watchRequested;
