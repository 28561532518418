export const determineIsDrupal403 = () => {
  return typeof window !== 'undefined' &&
    window.Drupal &&
    window.Drupal.settings &&
    window.Drupal.settings.rm &&
    window.Drupal.settings.rm.is403;
};

export const determineIsDrupal404 = () => {
  return typeof window !== 'undefined' &&
    window.Drupal &&
    window.Drupal.settings &&
    window.Drupal.settings.rm &&
    window.Drupal.settings.rm.is404;
};

export const determineIsDrupal500 = () => {
  return typeof window !== 'undefined' &&
    window.Drupal &&
    window.Drupal.settings &&
    window.Drupal.settings.rm &&
    window.Drupal.settings.rm.is500;
};

export const determineIsApp = () => {
  return typeof window !== 'undefined' &&
    window.location.hostname.startsWith('app.');
};

export const determineFrontendPrefix = () => {
  let frontendPrefix = '';
  if (determineIsApp()) {
    frontendPrefix = 'https://www.realtymogul.com';
    if (typeof window !== 'undefined' && window.location.hostname.startsWith('app.stg.')) {
      frontendPrefix = 'https://stg.realtymogul.com';
    }
  }
  return frontendPrefix;
};

export default {
  determineIsDrupal403,
  determineIsDrupal404,
  determineIsDrupal500,
  determineIsApp,
  determineFrontendPrefix,
};
