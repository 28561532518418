import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Content,
} from '../../../coreui';

import actions from '../actions';

import AttachmentList from './AttachmentList';
import Disclaimer from '../../../components/Disclaimer';

class SubComponent extends Component {
  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount() {
    if (!this.props.isRead) {
      this.props.getMessageDataReadRequested(this.props.id);
    }
  }

  render() {
    const {
      investment,
      subject,
      content,
      attachments,
      disclaimer,
      showInvestmentLabel,
    } = this.props;

    return (
      <ExtendedBox pt={90} pb={50} px={[50, 50, 100, 125, 175]}>
        <ExtendedFlex flexDirection="column">
          <Heading mt={0} type="H6">
            {showInvestmentLabel ? investment : subject}
          </Heading>
          <ExtendedFlex flexDirection={['column', 'column', 'row']}>
            <Content w={1} dangerouslySetInnerHTML={{ __html: content }} />
            {attachments && attachments.length > 0 && (
              <ExtendedFlex
                flexDirection="column"
                mt={[20, 20, 0]}
                ml={[0, 0, 50]}
              >
                <Heading type="H8">ATTACHMENTS</Heading>
                <AttachmentList attachments={attachments} />
              </ExtendedFlex>
            )}
          </ExtendedFlex>
          {disclaimer && (
            <Disclaimer mb={0} w="75%">
              {disclaimer}
            </Disclaimer>
          )}
          <Disclaimer mb={0} w="75%">
            The above presentation is based upon information supplied by the
            Sponsor. Realty Mogul, Co., and RM Manager, LLC along with their
            respective affiliates, officers, directors or representatives
            (the &quot;RM Parties&quot;) hereby advise you that none of them
            has independently confirmed or verified any of the information
            contained herein. The RM Parties further make no representations
            as to the accuracy or completeness of any such information and
            undertake no obligation now or in the future to update or
            correct this presentation or any information contained herein.
          </Disclaimer>
        </ExtendedFlex>
      </ExtendedBox>
    );
  }
}

SubComponent.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  subject: PropTypes.string,
  investment: PropTypes.string,
  attachments: PropTypes.array,
  content: PropTypes.string,
  disclaimer: PropTypes.string,
  isRead: PropTypes.bool,
  getMessageDataReadRequested: PropTypes.func,
  showInvestmentLabel: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  getMessageDataReadRequested: (id) => {
    dispatch(actions.getMessageDataReadRequested(id));
  },
});

export default connect(null, mapDispatchToProps)(SubComponent);
