import styled from 'styled-components';

const Flipper = styled.div`
  transition: ${(props) => props.transition || '0.6s'};
  transform-style: ${(props) => props.transformStyle || 'preserve-3d'};
  position: ${(props) => props.position || 'relative'};
  ${(props) => (props.transform ? `transform: ${props.transform};` : null)}
`;

export default Flipper;
