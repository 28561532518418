/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Data,
  ExtendedFlex,
  TextInput,
  dataTypes,
} from '../../coreui';

class DateRangeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateStart: null,
      dateEnd: null,
    };

    this.startDate = React.createRef();
    this.endDate = React.createRef();

    this.handleDateStartChange = this.handleDateStartChange.bind(this);
    this.handleDateEndChange = this.handleDateEndChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.setDates = this.setDates.bind(this);
    this.resetDates = this.resetDates.bind(this);
  }

  setDates(startDate, endDate) {
    this.startDate.current.value = startDate;
    this.endDate.current.value = endDate;
  }

  handleDateStartChange(e) {
    this.setState({
      dateStart: e.target.value === '' ? null : e.target.value,
    });
  }

  handleDateEndChange(e) {
    this.setState({
      dateEnd: e.target.value === '' ? null : e.target.value,
    });
  }

  handleUpdate() {
    this.props.handleUpdate(this.state.dateStart, this.state.dateEnd);
  }

  resetDates() {
    this.startDate.current.value = null;
    this.endDate.current.value = null;
  }

  render() {
    return (
      <ExtendedFlex
        justifyContent="space-between"
        alignItems="center"
      >
        <ExtendedFlex
          flexDirection={['column', 'row']}
          alignItems="center"
        >
          <Data
            type="D1"
            textTransform="uppercase"
            mx="5px"
          >
            From
          </Data>
          <TextInput
            innerRef={this.startDate}
            type="date"
            placeholder="MM/DD/YY"
            onChange={this.handleDateStartChange}
            onBlur={this.handleUpdate}
            mx="5px"
            textAlign="center"
            color="gray.7"
            placeholderColor="gray.7"
            border="1px solid"
            borderColor="gray.8"
            height={23}
            {...dataTypes.D4}
          />
        </ExtendedFlex>
        <ExtendedFlex
          flexDirection={['column', 'row']}
          alignItems="center"
        >
          <Data
            type="D1"
            textTransform="uppercase"
            mx="5px"
          >
            To
          </Data>
          <TextInput
            innerRef={this.endDate}
            type="date"
            placeholder="MM/DD/YY"
            mx="5px"
            onChange={this.handleDateEndChange}
            onBlur={this.handleUpdate}
            textAlign="center"
            color="gray.7"
            placeholderColor="gray.7"
            border="1px solid"
            borderColor="gray.8"
            height={23}
            {...dataTypes.D4}
          />
        </ExtendedFlex>
      </ExtendedFlex>
    );
  }
}

DateRangeFilter.propTypes = {
  handleUpdate: PropTypes.func,
};

export default DateRangeFilter;
