import styled from 'styled-components';

export default {
  ReitSection: styled.div`
    width: 100%;
    padding: 415px 16px 80px;
    box-sizing: border-box;
    display: block;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    color: #202020;
    background: ${({ background }) => `${background}`};
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      padding: 120px 100px 120px;
    }
    h1 {
      font-family: mrs-eaves-xl-serif-narrow,serif;
      margin: 0;
      font-weight: 800;
      font-size: 28px;
      line-height: 32px;
    }
    p {
      font-size: 16px;
      line-height: 28px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      h1 {
        font-size: 44px;
        line-height: 56px;
      }
      p {
        font-size: 18px;
      line-height: 32px;
      }
    }
  `,
  Container: styled.div`
  max-width: 1024px;
  margin: 0 auto;
  `,
};
