import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
  VisibilitySensor,
  Link,
} from '../../../coreui';

import FadeIn from '../../../components/FadeIn';

const ExploreOpportunitiesSection1 = ({
  handleSensorChange,
  isVisible,
  linkButtonText,
  variant,
}) => {
  const [strings, setStrings] = useState({
    heading: 'EXPLORE OPPORTUNITIES BEYOND STOCKS AND BONDS',
    subHeading: 'Diversify with commercial real estate with the potential to generate income and grow in value.',
  });

  useEffect(() => {
    if (variant !== 'none') {
      setStrings((oldStrings) => {
        return {
          ...oldStrings,
          heading: 'What % of your portfolio is allocated to real estate?',
          subHeading: ['A well-balanced portfolio contains more than just stocks, bonds and mutual funds.', <br />, <br />, 'Adding commercial real estate to your portfolio can provide meaningful diversification among other benefits.'],
        };
      });
    }
  }, [setStrings, variant]);

  return (
    <ExtendedBox
      boxSizing="border-box"
      width={[1, 1, 0.5, 0.5, 0.5]}
      px={['15%', '25%', '5%', '5%', '5%']}
      pt={[100, 100, 100, 160, 220]}
      pb={[0, 0, 100, 120, 180]}
    >
      <ExtendedFlex
        flexDirection="column"
        align="flex-end"
      >
        <ExtendedFlex
          maxWidth={['100%', '100%', 320, 400, 540]}
          flexDirection="column"
        >
          <FadeIn>
            <Heading
              type="H2"
              m={0}
              mb="1em"
              opacity={isVisible ? '1' : '0'}
            >
              {strings.heading}
            </Heading>
          </FadeIn>
          <VisibilitySensor
            callback={handleSensorChange}
          />
          <FadeIn
            fadeInIndex={2}
          >
            <Body
              type="B1"
              mb={[0, 0, 80, 80, 100]}
              opacity={isVisible ? '1' : '0'}
            >
              {strings.subHeading}
            </Body>
          </FadeIn>
          <ExtendedBox
            display={['none', 'none', 'block']}
          >
            <FadeIn
              fadeInIndex={3}
            >
              <Link type="L1" to="/investment-opportunities">
                {variant === 'none' ? linkButtonText : 'VIEW INVESTMENTS'}
              </Link>
            </FadeIn>
          </ExtendedBox>
        </ExtendedFlex>
      </ExtendedFlex>
    </ExtendedBox>
  );
};

ExploreOpportunitiesSection1.propTypes = {
  handleSensorChange: PropTypes.func,
  isVisible: PropTypes.bool,
  linkButtonText: PropTypes.string,
  variant: PropTypes.string,
};

export default ExploreOpportunitiesSection1;
