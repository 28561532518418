export default {
  GET_IO_DATA_REQUESTED: 'GET_IO_DATA_REQUESTED',
  GET_IO_DATA_SUCCEEDED: 'GET_IO_DATA_SUCCEEDED',
  GET_IO_DATA_FAILED: 'GET_IO_DATA_FAILED',
  GET_IO_FAQ_DATA_REQUESTED: 'GET_IO_FAQ_DATA_REQUESTED',
  GET_IO_FAQ_DATA_SUCCEEDED: 'GET_IO_FAQ_DATA_SUCCEEDED',
  GET_IO_FAQ_DATA_FAILED: 'GET_IO_FAQ_DATA_FAILED',
  UNSET_IO_STATE: 'UNSET_IO_STATE',
  WATCHLIST_TOGGLE_REQUESTED: 'WATCHLIST_TOGGLE_REQUESTED',
  WATCHLIST_TOGGLE_SUCCEEDED: 'WATCHLIST_TOGGLE_SUCCEEDED',
  WATCHLIST_TOGGLE_FAILED: 'WATCHLIST_TOGGLE_FAILED',
  WATCHLIST_TOGGLED: 'WATCHLIST_TOGGLED',
};
