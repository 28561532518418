import divImg from '../assets/Img_card_REITs.jpg';
import passImg from '../assets/Img_card_PP.jpg';

export default {
  hero: {
    header: 'Invest with new levels of access and confidence',
    subheader: 'Investment options to meet your needs',
  },
  overview: {
    title: 'A Range of Investing Benefits',
    subtitle: 'Commercial real estate investing offers many possibilities across various property types.  The investment products available to RealtyMogul members offer distinct benefits including “REITs” and individual properties.',
  },
  tools: [
    {
      image: divImg,
      title: 'REITS',
      body: 'Invest in a growing portfolio of diverse commercial real estate properties through public, non-traded REITs. The REITs are designed for income and growth and offer retirement investing options.',
      section: 'reits',
    },
    {
      image: passImg,
      title: 'INDIVIDUAL PROPERTIES',
      body: 'Qualified, Accredited Investors may invest in single properties or "Private Placements" which are structured as an LLC and designed to meet specific objectives.',
      section: 'pp',
    },
  ],
  meet: {
    title: 'MEET THE REITS',
    body: 'The Income REIT’s objective is to pay attractive and consistent cash distributions and to preserve, protect, increase and return capital contributions through debt and equity investments in a diversified pool of commercial real estate property types, while The Apartment Growth REIT focuses on providing investors with the opportunity for capital appreciation by investing in value-add multifamily properties with both preferred and joint-venture equity.',
    bottom: 'THE REITS GIVE YOU THE OPTION OF INVESTING USING A RETIREMENT ACCOUNT',
  },
  reits: [],
};
