import styled from 'styled-components';

const Styled = {
  OverlayWrapper: styled.div`
    position: fixed;
    transform: scale3d(1, 1, 1);
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 1;
    z-index: 999999;
    transition: opacity 0.3s;
    box-sizing: border-box;
  `,

  OverlayBackground: styled.button`
    text-indent: -9999px;
    display: block;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    border: none;
  `,

  Overlay: styled.div`
    display: flex;
    position: absolute;
    background: ${({ background }) => background || '#FFF'};
    transform: scale3d(1, 1, 1);
    width: 100%;
    height: 100%;
    @media (min-width: 768px) {
      max-width: ${({ isRegisterScreen }) => (isRegisterScreen ? '768px' : '480px')};
      max-height: ${({ isRegisterScreen }) => (isRegisterScreen ? '600px' : '500px')};
      width: calc(100% - 50px);
      height: calc(100% - 50px);
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
    }
  `,

  PageContent: styled.div`
    width: 100%;
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
  `,

  MobileContent: styled.div`
    display: block;
    @media (min-width: 768px) {
      display: none;
    };
  `,

  DesktopContent: styled.div`
    display: none;
    @media (min-width: 768px) {
      display: block;
    };
  `,

  TopBar: styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px 30px;
  box-sizing: border-box;
  background: #000;
  @media (min-width: 768px) {
    height: 60px;
  }
  .auth-overlay-topbar-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .auth-overlay-topbar-text {
    font: normal normal 300 12px/20px var(--font-sans);
    letter-spacing: 0px;
    color: #EBEBEB;
  }
  .auth-overlay-title {
    color: #FFF;
    font: normal normal 500 16px/24px var(--font-sans);
    letter-spacing: 2.88px;
  }
  .auth-overlay-close-btn, .auth-overlay-signIn-btn {
    color: #A4C2D5;
    font: normal normal 500 10px/12px var(--font-sans);
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    svg {
      margin-left: 8px;
      padding-bottom: 2px;
      width: 10px;
    }
  }

  .auth-overlay-signIn-btn {
    display: inline-block;
    font: normal normal 400 12px/20px var(--font-sans);
    letter-spacing: 0;
    color: #A4C2D5;
  }
`,

  MainContent: styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 768px) {
      height: calc(100% - 60px);
    }
  `,

  SideBar: styled.div`
    display: flex;
    box-sizing: border-box;
    padding: 0 30px;
    width: 280px;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    background: #F7F8F8;
    .auth-overlay-sidebar-checkmark {
      svg {
        width: 20px;
        height: 20px;
        margin-top: 3px;
      }
    }
    .auth-overlay-sidebar-line {
      display: flex;
      margin-bottom: 10px;
    }
    .auth-overlay-sidebar-line-text {
      display: inline-block;
      margin-left: 15px;
      font: normal normal 300 13px/24px var(--font-sans);
      letter-spacing: 0px;
      color: #202020;
    }
    .auth-overlay-sidebar-text {
      font: normal normal 300 12px/20px var(--font-sans);
      letter-spacing: 0px;
      color: #9B9B9B;
      padding: 15px 0;
      border-top: 1px solid #E2E2E2;
    }
    .auth-overlay-signIn-btn {
      display: inline-block;
      cursor: pointer;
      font: normal normal 500 12px/20px var(--font-sans);
      letter-spacing: 0;
      color: #176DB6;
      background: none;
      border: none;
    }
  `,

  FormWrapper: styled.div`
    flex: 1
    overflow-y: auto;
    @media (min-width: 768px) {
      max-height: 540px;
    }
  `,
};

export default Styled;
