import styled from 'styled-components';
import {
  width,
  height,
} from 'styled-system';

const Container = styled.div`
  ${width}
  ${height}
  cursor: ${(props) => props.cursor || 'pointer'};
  perspective: ${(props) => props.perspective || '1000px'};
  /* transform-style: ${(props) => props.transformStyle || 'preserve-3d'}; */
`;

export default Container;
