/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
} from '../coreui';

const Divider = ({
  type,
  ...additionalProps
}) => {
  return (
    <ExtendedBox
      width={1}
      height="1px"
      mx={0}
      my={20}
      {...(type === 'vertical' ? {
        width: [1, 1, '1px'],
        height: ['1px', '1px', 'auto'],
        mx: [0, 0, 50],
        my: [10, 10, 0],
      } : {})}
      bg="gray.7"
      flex="0 0 auto"
      {...additionalProps}
    />
  );
};

Divider.propTypes = {
  type: PropTypes.oneOf([
    'horizontal',
    'vertical',
  ]),
};

Divider.defaultProps = {
  type: 'vertical',
};

export default Divider;
