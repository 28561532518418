/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  withRouter,
} from 'react-router-dom';
import { Button, BrowseFilter, InvestmentTile, FadeIn } from 'rtmg-component-library';
import _ from 'lodash';

import {
  ExtendedBox,
  ExtendedFlex,
  Link,
} from '../../coreui';

import Loading from '../../auth/components/Loading';

import actions from './actions';
import browseActions from '../browsePage/actions';
import Calendar from '../../assets/Calendar.svg';

const InvestmentsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  @media (min-width: 1050px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1410px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1770px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const UpdatedPastInvestments = ({ investments, user }) => {
  const [filteredInvestments, setFilteredInvestments] = useState([]);
  const [filters, setFilters] = useState([]);
  const [invStatusFilterState, setInvStatusFilterState] = useState({});
  const [propertyTypeFilterState, setPropertyTypeFilterState] = useState({});
  const [investmentStrategyFilterState, setInvestmentStrategyFilterState] = useState({});
  const [matchedNum, setMatchedNum] = useState();
  const [numOfTilesShown, setNumOfTilesShown] = useState(12);
  const totalNum = investments.length;

  const loadMore = () => {
    if (numOfTilesShown + 12 >= filteredInvestments.length) {
      setNumOfTilesShown(filteredInvestments.length);
    } else {
      setNumOfTilesShown(numOfTilesShown + 12);
    }
  };

  const sortCategories = ['Recently Closed', 'IRR', 'Hold Period'];

  const buildFilter = useCallback((label) => {
    const result = investments.reduce((acc, curr) => {
      const doesElemExist = acc.findIndex((item) => {
        return item.label === curr[label];
      });
      if (doesElemExist === -1) {
        if (curr[label]) {
          const obj = {};
          obj.label = curr[label];
          obj.number = 1;
          switch (curr[label].toLowerCase()) {
            case 'funded':
              obj.toolTip = {
                paragraph: <><span style={{ fontWeight: 600 }}>FUNDED</span> investments are active investments that have received full funding and are not currently accepting further investment.</>,
              };
              break;
            case 'completed equity':
              obj.toolTip = {
                paragraph: <><span style={{ fontWeight: 600 }}>COMPLETED</span> investments have concluded and final distributions have been processed.</>,
              };
              break;
            case 'completed debt':
              obj.toolTip = {
                paragraph: <><span style={{ fontWeight: 600 }}>COMPLETED</span> investments have concluded and final distributions have been processed.</>,
              };
              break;
            default:
          }
          acc.push(obj);
        }
      } else {
        acc[doesElemExist].number += 1;
      }
      return acc;
    }, []);
    return result;
  }, [investments]);

  const filterInvestmentStatus = useCallback((label) => {
    if (Object.keys(invStatusFilterState).includes(label)) {
      setInvStatusFilterState((i) => {
        return { ...i, [label]: !i[label] };
      });
    } else {
      setInvStatusFilterState((i) => {
        return { ...i, [label]: true };
      });
    }
  }, [invStatusFilterState]);

  const filterPropertyTypeStatus = useCallback((label) => {
    if (Object.keys(propertyTypeFilterState).includes(label)) {
      setPropertyTypeFilterState((i) => {
        return { ...i, [label]: !i[label] };
      });
    } else {
      setPropertyTypeFilterState((i) => {
        return { ...i, [label]: true };
      });
    }
  }, [propertyTypeFilterState]);

  const filterInvestmentStrategyStatus = useCallback((label) => {
    if (Object.keys(investmentStrategyFilterState).includes(label)) {
      setInvestmentStrategyFilterState((i) => {
        return { ...i, [label]: !i[label] };
      });
    } else {
      setInvestmentStrategyFilterState((i) => {
        return { ...i, [label]: true };
      });
    }
  }, [investmentStrategyFilterState]);

  useEffect(() => {
    const filters = [];
    filters.push({
      label: 'Investment Status',
      filterFunction: filterInvestmentStatus,
      filters: buildFilter('statusLabel'),
    });
    filters.push({
      label: 'Property Type',
      filterFunction: filterPropertyTypeStatus,
      filters: buildFilter('propertyType'),
    });
    const investmentStrategyFilters = buildFilter('investmentStrategyLabel');
    if (investmentStrategyFilters.length >= 1) {
      filters.push({
        label: 'Investment Strategy',
        filterFunction: filterInvestmentStrategyStatus,
        filters: investmentStrategyFilters,
      });
    }
    setFilters(filters);
    setFilteredInvestments(investments);
    setMatchedNum(investments.length);
  }, [investments, buildFilter, filterInvestmentStatus, filterPropertyTypeStatus, filterInvestmentStrategyStatus]);

  const filterIsEmpty = (filter) => {
    let isEmpty = true;
    Object.entries(filter).map((item) => {
      if (item[1]) isEmpty = false;
      return null;
    });
    return isEmpty;
  };

  const findMatches = useCallback((filterState, filterBy, arrayToFilter) => {
    if (filterIsEmpty(filterState)) {
      return arrayToFilter;
    }
    let matches = [];
    Object.entries(filterState).map((item) => {
      if (item[1]) {
        matches = matches.concat(arrayToFilter.filter((investItem) => {
          return investItem[filterBy] === item[0];
        }));
      }
      return null;
    });
    return matches.length > 0 || filterBy !== 'statusLabel' ? matches : arrayToFilter;
  }, []);

  const buildFilteredArray = useCallback(() => {
    const noFilterSelected = filterIsEmpty(invStatusFilterState) && filterIsEmpty(propertyTypeFilterState) && filterIsEmpty(investmentStrategyFilterState);

    if (noFilterSelected) return investments;

    const statusFilteredArray = findMatches(invStatusFilterState, 'statusLabel', investments);
    const propertyTypeFilteredArray = findMatches(propertyTypeFilterState, 'propertyType', statusFilteredArray);
    const investmentStrategyFilteredArray = findMatches(investmentStrategyFilterState, 'investmentStrategyLabel', propertyTypeFilteredArray);

    setMatchedNum(investmentStrategyFilteredArray.length);
    return investmentStrategyFilteredArray;
  }, [invStatusFilterState, propertyTypeFilterState, investmentStrategyFilterState, investments, findMatches]);

  useEffect(() => {
    setFilteredInvestments(buildFilteredArray());
    setNumOfTilesShown(12);
  }, [buildFilteredArray]);

  const resetFunction = () => {
    setFilteredInvestments(investments);
    setInvStatusFilterState({});
    setPropertyTypeFilterState({});
  };

  const sortFunction = (index) => {
    let sortedArray = [];
    setNumOfTilesShown(12);
    switch (index) {
      case 0:
        sortedArray = buildFilteredArray();
        break;
      case 1:
        sortedArray = _.orderBy(filteredInvestments, ({ actualIRR }) => actualIRR || '', ['desc']);
        break;
      default:
        sortedArray = _.orderBy(filteredInvestments, ({ actualHold }) => actualHold, ['asc']);
    }
    setFilteredInvestments(sortedArray);
  };

  return (
    <ExtendedFlex
      flexDirection={['column', 'row', 'row']}
      bg="gray.0"
      w={[1]}
      py={40}
    >
      <ExtendedBox minWidth={[1, 225]} ml={[0, 30]} mr={[0, 35]} mb={[30, 0]} px={[20, 0]} boxSizing="border-box">
        <BrowseFilter
          matchedNum={matchedNum}
          totalNum={totalNum}
          resetFunction={resetFunction}
          sort={sortCategories}
          sortFunction={sortFunction}
          filters={filters}
        />
      </ExtendedBox>
      <InvestmentsContainer>
        {
          filteredInvestments.map((item) => (
            <FadeIn key={item.id}>
              <ExtendedBox
                pr={[0, 40]}
                pb={40}
              >
                {
                  !user.isLoggedIn ?
                    <Link to="/user/register" className={`past-investment-card ${item.investmentType}`}>
                      <InvestmentTile
                        type="past"
                        {...item}
                      />
                    </Link>
                    :
                    <div className={item.investmentType}>
                      <InvestmentTile
                        type="past"
                        {...item}
                      />
                    </div>
                }
              </ExtendedBox>
            </FadeIn>
          )).slice(0, numOfTilesShown)
        }
        {
          numOfTilesShown !== filteredInvestments.length &&
          <ButtonWrapper>
            <Button onClick={() => loadMore()}>
              LOAD MORE
            </Button>
          </ButtonWrapper>
        }
      </InvestmentsContainer>
    </ExtendedFlex>
  );
};

UpdatedPastInvestments.propTypes = {
  investments: PropTypes.array,
  user: PropTypes.object,
};

const ScheduleAppointmentWrapper = styled.div`
  position: fixed;
  bottom: 75px;
  width: calc(100% - 60px);
  text-align: center;
  margin: 0 30px;
  box-sizing: border-box;
  height: 64px;
  padding: 15px 20px;
  background: #F7F8F8 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 40px #0000004D;
  cursor: pointer;
  a {
    text-decoration: none;
  }
  &:hover {
    background: #FFF;
    .bottom-line {
      color: #1D89FF;
    }
    .top-line {
      color: #0C4779;
    }
  }
  .top-line {
    color: #202020;
    font: normal normal 400 13px/14px var(--font-sans);
    letter-spacing: 1.56px;
  }
  .bottom-line {
    font: normal normal 400 11px/28px var(--font-sans);
    color: #176DB6;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 13px;
      margin-right: 7.5px;
    }
  }
  @media (min-width: 768px) {
    right: 0;
    text-align: left;
    width: 300px;
    .bottom-line {
      justify-content: flex-start;
    }
  }
`;

const ScheduleAppointment = () => (
  <ScheduleAppointmentWrapper>
    <a data-testid="schedule-appointment-past-investments-test" href="https://calendly.com/realtymogul-investor-relations/realty-mogul-representative-call?utm_campaign=pastinvestments" target="_blank" rel="noopener noreferrer">
      <div className="top-line">SCHEDULE AN APPOINTMENT</div>
      <div className="bottom-line"><img src={Calendar} alt="Calendar icon" /> Book an Investor Relations Professional</div>
    </a>
  </ScheduleAppointmentWrapper>
);

const ButtonWrapper = styled.div`
  width: calc(100% - 60px);
  margin: 0 auto;
  box-sizing: border-box;
  @media (min-width: 768px) {
    max-width: 320px;
    grid-column: 1/-1;
  }
`;
class PastInvestments extends React.Component {
  componentDidMount() {
    this.props.getBrowsePageDataRequested();
    // RTML-1254: Hide ZenDesk widget on /past-investments
    if (document.querySelector('iframe[id="launcher"]')) {
      document.querySelector('iframe[id="launcher"]').style.display = 'none';
    }
  }

  componentWillUnmount() {
    if (document.querySelector('iframe[id="launcher"]')) {
      document.querySelector('iframe[id="launcher"]').style.display = 'block';
    } else {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://static.zdassets.com/ekr/snippet.js?key=f0fb7231-d555-4b6c-b382-06ff3c2efb4e';
      s.id = 'ze-snippet';
      document.body.appendChild(s);
    }
  }

  render() {
    const {
      combinedInvestments,
      user,
      loaded,
    } = this.props;

    const shouldShowVariant = typeof this.props.pageVariant === 'string';

    return (
      <>
        {
          loaded ?
            <UpdatedPastInvestments
              investments={
                combinedInvestments.map((item) => {
                  return {
                    ...item,
                    statusLabel: item.statusLabel.toLowerCase() === 'completed' ? `${item.statusLabel} ${item.investmentType}` : item.statusLabel,
                  };
                })
              }
              user={user}
            />
            :
            <Loading />
        }
        {
          shouldShowVariant &&
          <ScheduleAppointment />
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loaded: state.pastInvestments.loaded,
  combinedInvestments: _.orderBy(state.pastInvestments.completedEquityInvestments.concat(state.pastInvestments.completedDebtInvestments).concat(state.pastInvestments.fundedInvestments), ({ actualMaturityDate }) => actualMaturityDate || '', ['desc']),
  gtkyApproved: true,
  pageVariant: state.variants?.pastInvestments?.v,
  user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
  getBrowsePageDataRequested: () => {
    dispatch(browseActions.getBrowseClosedDataRequested());
  },
  updateSelectedIoTypeFilterValue: (value) => {
    dispatch(actions.updateSelectedPastInvestmentIoTypeFilterValue(value));
  },
  showMoreRequested: (value) => {
    dispatch(actions.updateShowMorePastInvestments(value));
  },
});

PastInvestments.propTypes = {
  loaded: PropTypes.bool,
  combinedInvestments: PropTypes.array,
  getBrowsePageDataRequested: PropTypes.func,
  user: PropTypes.object,
  pageVariant: PropTypes.string,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PastInvestments));
