import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Body,
  NavLink,
  Numeral,
} from '../../../coreui';

import FadeIn from '../../../components/FadeIn';

const ExploreOpportunitiesSection2 = ({
  isVisible,
  linkButtonText,
  totalMembers,
  totalAmountFinanced,
  totalPropertyValueFinanced,
  variant,
}) => {
  const [strings, setStrings] = useState({});

  useEffect(() => {
    setStrings((oldStrings) => {
      return {
        ...oldStrings,
        ...(
          variant !== 'none'
            ? {
              heading1: 'DIVERSIFICATION',
              subHeading1: 'Protect your portfolio in the event that one or more asset classes underperforms',
              copy1: '',
              heading2: 'REDUCE VOLATILITY',
              subHeading2: 'Invest in tangible assets that don’t go up and down in tandem with the stock market',
              copy2: '',
              heading3: 'CAPITAL PRESERVATION',
              subHeading3: 'Hedge against inflation and potentially earn outsized returns',
              copy3: '',
            }
            : {
              heading1: 'ACCESS',
              subHeading1: 'Private market offerings.',
              copy1: [<Numeral value={totalMembers} format="0,0" />, '+ investors have joined'],
              heading2: 'INVEST',
              subHeading2: 'In private placements and Real Estate Investment Trusts (“REITS”)',
              copy2: ['Over ', <Numeral value={totalAmountFinanced} format="$0 aw" upper={false} />, ' invested on the platform'],
              heading3: 'GAIN',
              subHeading3: 'Exposure to more deals.',
              copy3: ['Over ', <Numeral value={totalPropertyValueFinanced} format="($0. aw)" upper={false} />, ' in deals posted on the platform.'],
            }
        ),
      };
    });
  }, [setStrings, variant, totalMembers, totalAmountFinanced, totalPropertyValueFinanced]);

  return (
    <ExtendedBox
      boxSizing="border-box"
      width={[1, 1, 0.5, 0.5, 0.5]}
      px={['15%', '25%', '5%', '5%', '5%']}
      pt={[50, 50, 100, 160, 220]}
      pb={[100, 100, 100, 160, 220]}
    >
      <ExtendedFlex
        flexDirection="column"
        mr={['0', '0', '10%', '10%', '10%']}
        maxWidth={[null, null, 300, 380, 500]}
      >
        <FadeIn>
          <ExtendedBox
            m={0}
            mb={30}
            pb={30}
            borderColor="gray.7"
            borderBottom={1}
            opacity={isVisible ? '1' : '0'}
          >
            <Flex flexDirection="column">
              <Heading type="H3" textTransform="uppercase" m={0} mb="0.2em">
                {strings.heading1}
              </Heading>
              <Body
                type="B1"
                lineHeight="1.8em"
                m={0}
                textTransform="none"
                mb="0.8em"
              >
                {strings.subHeading1}
              </Body>
              {totalMembers ? (
                <Body type="B2" m={0}>
                  {strings.copy1}
                </Body>
              ) : null}
            </Flex>
          </ExtendedBox>
          <ExtendedBox
            pb={30}
            borderColor="gray.7"
            borderBottom={1}
            mb={30}
            opacity={isVisible ? '1' : '0'}
          >
            <Flex flexDirection="column">
              <Heading type="H3" textTransform="uppercase" m={0} mb="0.2em">
                {strings.heading2}
              </Heading>
              <Body
                type="B1"
                lineHeight="1.8em"
                m={0}
                textTransform="none"
                mb="0.8em"
              >
                {strings.subHeading2}
              </Body>
              {totalAmountFinanced ? (
                <Body type="B2" m={0}>
                  {strings.copy2}
                </Body>
              ) : null}
            </Flex>
          </ExtendedBox>
          <ExtendedBox opacity={isVisible ? '1' : '0'}>
            <Flex flexDirection="column" mb={60}>
              <Heading type="H3" textTransform="uppercase" m={0} mb="0.2em">
                {strings.heading3}
              </Heading>
              <Body
                type="B1"
                lineHeight="1.8em"
                m={0}
                textTransform="none"
                mb="0.8em"
              >
                {strings.subHeading3}
              </Body>
              <Body type="B2" m={0}>
                {strings.copy3}
              </Body>
            </Flex>
          </ExtendedBox>
        </FadeIn>
        <ExtendedBox display={['block', null, 'none']}>
          <FadeIn fadeInIndex={4}>
            <NavLink
              type="L1"
              to="/investment-options"
              opacity={isVisible ? '1' : '0'}
            >
              {variant === 'none' ? linkButtonText : 'VIEW INVESTMENTS'}
            </NavLink>
          </FadeIn>
        </ExtendedBox>
      </ExtendedFlex>
    </ExtendedBox>
  );
};

ExploreOpportunitiesSection2.propTypes = {
  isVisible: PropTypes.bool,
  linkButtonText: PropTypes.string,
  totalMembers: PropTypes.number,
  totalAmountFinanced: PropTypes.number,
  totalPropertyValueFinanced: PropTypes.number,
  variant: PropTypes.string,
};

export default ExploreOpportunitiesSection2;
