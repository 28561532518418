import types from './types';

export default {
  shareRepurchaseListRequested: () => ({
    type: types.SHARE_REPURCHASE_LIST_API_REQUESTED,
  }),
  shareRepurchaseListSucceeded: (data) => ({
    data,
    type: types.SHARE_REPURCHASE_LIST_API_SUCCEEDED,
  }),
  shareRepurchaseListFailed: (error) => ({
    error,
    type: types.SHARE_REPURCHASE_LIST_API_FAILED,
  }),
  shareRepurchasePostRequested: (data, promise) => ({
    type: types.SHARE_REPURCHASE_POST_API_REQUESTED,
    data,
    promise,
  }),
  shareRepurchasePostSucceeded: (data) => ({
    data,
    type: types.SHARE_REPURCHASE_POST_API_SUCCEEDED,
  }),
  shareRepurchasePostFailed: (error) => ({
    error,
    type: types.SHARE_REPURCHASE_POST_API_FAILED,
  }),
};
