import React from 'react';
import { ExtendedFlex } from '../../coreui';

const FirstContainer = (props) => {
  return (
    <ExtendedFlex
      maxWidth={800}
      pr={[null, null, 100]}
      px={['2em']}
      flexDirection="column"
      flex={2}
      mx="1em"
      mb={80}
      // eslint-disable-next-line
      {...props}
    />
  );
};

export default FirstContainer;
