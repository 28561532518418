import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  Box,
  ExtendedFlex,
  Heading,
  Body,
  VisibilitySensor,
} from '../../../../coreui';

import backgroundImage from '../../../../assets/multifamily_strategy2.jpg';
import FadeIn from '../../../../components/FadeIn';
import BrowseLink from '../../../../components/BrowseLink';

const StrategySection1 = ({
  handleSensorChange,
  isVisible,
  isLoggedIn,
}) => {
  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
    >
      <ExtendedBox
        w={1}
        bgRepeat="no-repeat"
        bgImage={backgroundImage}
        bgPosition="50% 26%"
        bgSize="cover"
      >
        <Box
          w={1}
          px={['15%', '25%', '10%', '10%', '10%']}
        >
          <ExtendedFlex
            flexDirection="column"
            align={['center', 'center', 'flex-end', 'flex-end', 'flex-end']}
          >
            <ExtendedFlex
              maxWidth={['100%', '100%', 400, 400, 540]}
              flexDirection="column"
              my={[60, 60, 100, 100, 100]}
            >
              <FadeIn>
                <Heading
                  type="H2"
                  color="white"
                  m={0}
                  mb="1em"
                  opacity={isVisible ? '1' : '0'}
                >
                  OUR STRATEGY IS VALUE-ADD
                </Heading>
              </FadeIn>
              <VisibilitySensor
                callback={handleSensorChange}
              />
              <FadeIn
                fadeInIndex={2}
              >
                <Body
                  type="B1"
                  color="white"
                  fontWeight="light"
                  opacity={isVisible ? '1' : '0'}
                >
                  At RealtyMogul, we have invested in
                  multifamily housing worth over $1.5B and we pride ourselves on
                  helping investors learn the details of
                  real estate investing. We offer equity
                  investments in commercial real estate
                  to investors, explicitly seeking out
                  properties that offer potential cash-flow and a value-add opportunity.
                </Body>
                <Body
                  type="B1"
                  color="white"
                  fontWeight="light"
                  opacity={isVisible ? '1' : '0'}
                >
                  We focus on value-add as an
                  investment strategy because while it
                  aims to increase the value of the
                  property, we believe there is lower risk
                  involved than ground-up construction
                  which carries entitlement and
                  construction exposure.
                </Body>
                <ExtendedBox
                  opacity={isVisible ? '1' : '0'}
                >
                  <BrowseLink
                    isLoggedIn={isLoggedIn}
                  />
                </ExtendedBox>
              </FadeIn>
            </ExtendedFlex>
          </ExtendedFlex>
        </Box>
      </ExtendedBox>
    </Box>
  );
};

StrategySection1.propTypes = {
  handleSensorChange: PropTypes.func,
  isVisible: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
};

export default StrategySection1;
