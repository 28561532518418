import { delay } from 'redux-saga';
import {
  takeEvery,
  call,
  all,
  put,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';

import {
  deleteAutoInvestApi,
} from '../../api';

export function* getCancelDataFlow(action) {
  const { resolve, reject } = action.promise;
  try {
    const {
      id,
    } = action.data;
    yield delay(1);
    if (id) {
      yield call(deleteAutoInvestApi, id);
    }
    yield all([
      call(resolve, action.data),
      put(
        actions.autoInvestCancelSucceeded(
          action.data,
        ),
      ),
    ]);
  } catch (error) {
    yield all([
      call(reject, error),
      put(
        actions.autoInvestCancelFailed(
          error,
        ),
      ),
    ]);
  }
}

export function* watchAutoInvestCancelRequested() {
  yield takeEvery(
    types.AUTOINVEST_CANCEL_REQUESTED,
    getCancelDataFlow,
  );
}

export default watchAutoInvestCancelRequested;
