import {
  takeEvery,
  takeLatest,
  call,
  put,
  all,
} from 'redux-saga/effects';
import { Auth } from 'aws-amplify';

import appTypes from '../../appWrapper/types';
import types from '../types';
import actions from '../actions';
import appActions from '../../appWrapper/actions';

import { getUserLogoutApi } from '../../api';
import { isDrupal } from '../../coreui';

const logoutFromAws = () => {
  return Auth.currentAuthenticatedUser()
    .then(() => Auth.signOut())
    .catch(() => {
      // Do nothing.
    });
};

const reloadToFrontPage = () => {
  window.location = '/';
};

export function* getUserDataFlow() {
  try {
    const [
      response,
    ] = yield all([
      call(getUserLogoutApi),
      call(logoutFromAws),
    ]);

    const user = {
      ...response.data,
      messages: null,
      isLoggedIn: false,
    };

    const inDrupal = isDrupal();

    // Only do some of the actions if we're in Drupal.
    yield all([
      inDrupal && call(reloadToFrontPage),
      put(
        actions.userLogoutSucceeded(user),
      ),
      !inDrupal && put(
        appActions.getUserDataSucceeded(user),
      ),
    ].filter(Boolean));
  } catch (error) {
    yield put(
      actions.userLogoutFailed(error),
    );
  }
}

export function* getUserDataSucceededFlow(action) {
  try {
    if (!action.user.isLoggedIn) {
      yield call(logoutFromAws);
    }
  } catch (e) {
    // Do nothing.
  }
}

export function* watchUserLogoutRequested() {
  yield takeLatest(
    types.USER_LOGOUT_REQUESTED,
    getUserDataFlow,
  );
}

export function* watchGetUserDataSucceeded() {
  yield takeEvery(
    appTypes.GET_USER_DATA_SUCCEEDED,
    getUserDataSucceededFlow,
  );
}

export default watchUserLogoutRequested;
