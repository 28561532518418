import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import Helmet from 'react-helmet';
import { Auth } from 'aws-amplify';

import {
  isDrupal,
  Theme,
  ThemeProvider,
} from '../coreui';

import actions from './actions';

import Header from '../header';
import Footer from '../footer';
import AdminMenu from '../adminMenu';
import Zendesk from '../components/Zendesk';
import FourOhFour from '../components/FourOhFour';
import FiveHundred from '../components/FiveHundred';
import Messages from '../dsm';
import AuthComponent from '../auth';

import {
  determineIsDrupal403,
  determineIsDrupal404,
  determineIsDrupal500,
} from './helpers';
import { isMockSession, getCurrentSession } from '../auth/api';

const isDrupal403 = determineIsDrupal403();
const isDrupal404 = determineIsDrupal404();
const isDrupal500 = determineIsDrupal500();

export const getLocalUser = async () => {
  if (isMockSession()) {
    return getCurrentSession()
      .then(({
        idToken: {
          payload: {
            given_name: firstName,
            family_name: lastName,
            email_verified: emailVerified,
          },
        },
      }) => ({
        firstName,
        lastName,
        emailVerified,
      }));
  }
  return Auth.currentAuthenticatedUser()
    .then(({
      attributes: {
        given_name: firstName,
        family_name: lastName,
        email_verified: emailVerified,
        email,
      },
    }) => ({
      firstName,
      lastName,
      emailVerified,
      email,
    }))
    .catch(() => false);
};

class AppWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    let localUser;
    getLocalUser()
      .then((user) => {
        localUser = user;
        const _dataLayer = window.dataLayer || [];
        _dataLayer.push({
          firstName: user.firstName,
          email: user.email,
        });
      })
      .catch(() => {
        // Do nothing.
      })
      .finally(() => {
        this.props.appInit({
          user: {
            isLoggedIn: !!localUser,
            ...(localUser || {}),
          },
          drupalStatus: {
            isDrupal403,
            isDrupal404,
            isDrupal500,
          },
        });
      });
    const jQuery = get(window, 'jQuery');
    if (jQuery && isDrupal()) {
      // Unclear if this needed, but it was copied from html.tpl.php on Drupal.
      // And it seems to work.
      jQuery('body').animate({ opacity: 1 }, {
        duration: 200,
        complete: () => {
          jQuery('input').trigger('change');
        },
      });
      jQuery('body').animate({ opacity: 1 }, 200, 'swing', () => {
        jQuery('body').trigger('merlin-show');
      });
    }
  }

  render() {
    const {
      isStaff,
      children,
    } = this.props;

    return (
      <ThemeProvider theme={Theme}>
        <>
          {
            !isDrupal403 &&
            <Helmet>
              <meta charSet="utf-8" />
              <title>RealtyMogul.com™ | Real Estate Crowdfunding & Investing</title>
              <meta name="description" content="RealtyMogul simplifies commercial real estate investing, giving our members access to vetted commercial real estate opportunities with the potential to generate passive income." />
            </Helmet>
          }
          {
            isStaff &&
            <AdminMenu />
          }
          <Header />
          <Messages />
          <Zendesk />
          {
            isDrupal403 &&
            <AuthComponent
              is403={isDrupal403}
            />
          }
          {
            isDrupal404 &&
            <FourOhFour />
          }
          {
            isDrupal500 &&
            <FiveHundred />
          }
          {children}
          <Footer />
        </>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  isStaff: state.app.user.isStaff,
});

const mapDispatchToProps = (dispatch) => ({
  appInit: (value) => {
    dispatch(actions.appInit(value));
  },
});

AppWrapper.propTypes = {
  appInit: PropTypes.func,
  children: PropTypes.element,
  isStaff: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
