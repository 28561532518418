import React from 'react';
import PropTypes from 'prop-types';

import {
  ModalWithOverlay,
  Data,
  ExtendedFlex,
  Link,
} from '../../coreui';

const NewSdiraContinueModal = ({
  handleClick,
  path,
  label,
  continueHandler = () => {},
  children,
  ...props
}) => {
  return (
    <ModalWithOverlay
      handleClick={handleClick}
      // eslint-disable-next-line
      {...props}
    >
      <ExtendedFlex
        flexDirection="column"
        align="center"
        m={[null, null, 30]}
      >
        <Data type="D2" textTransform="uppercase" textAlign="center">
          Click the &apos;continue&apos; button to be redirected to the website
          of our preferred custodian, {label}, to open a new
          account.
        </Data>
        <ExtendedFlex
          w={1}
          my={30}
          justify="center"
          align="center"
          flexDirection={['column', null, 'row']}
        >
          <Link type="L1" href={path} onClick={continueHandler} mt={[30, null, 0]}>
            Continue
          </Link>
        </ExtendedFlex>
      </ExtendedFlex>
    </ModalWithOverlay>
  );
};

NewSdiraContinueModal.propTypes = {
  handleClick: PropTypes.func,
  continueHandler: PropTypes.func,
  children: PropTypes.object,
  path: PropTypes.string,
  label: PropTypes.string,
};

export default NewSdiraContinueModal;
