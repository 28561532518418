import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Data,
} from '../../..';

class GlossaryAccordionHeading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.handleClick(this.props.id);
  }

  render() {
    const {
      content,
      isUppercase,
    } = this.props;

    return (
      <ExtendedFlex
        w={1}
        justify="flex-start"
        align="center"
        onClick={this.handleClick}
        px={['4%', 20]}
        hover
      >
        <Data
          type="D2"
          color="blue.0"
          m={0}
          ml={0}
          textTransform={isUppercase ? 'uppercase' : 'none'}
        >
          {content}
        </Data>
      </ExtendedFlex>
    );
  }
}

GlossaryAccordionHeading.propTypes = {
  content: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleClick: PropTypes.func,
  isUppercase: PropTypes.bool,
};

export default GlossaryAccordionHeading;
