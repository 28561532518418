/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import {
  ExtendedFlex,
  ExtendedBox,
  Body,
  Link,
  Image,
  ModalWithOverlay,
} from '../../coreui';

import dlIcon from '../../assets/icon_download.svg';
import webIcon from '../../assets/icon_webinar.svg';

// eslint-disable-next-line
class DocumentItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState((state) => ({
      isModalVisible: !state.isModalVisible,
    }));
  }

  render() {
    const {
      doc,
      web,
      linkTarget,
      ...passedProps
    } = this.props;
    const linkStyle = {
      height: 'auto',
      lineHeight: '1.75',
      marginTop: '12px',
    };

    let icon = dlIcon;
    let linkText = 'Download PDF';

    if (web) {
      icon = webIcon;
      linkText = 'View Webinar';
    }
    return (
      <ExtendedFlex
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="gray.12"
        py={10}
        flexDirection={['column', 'row']}
        {...passedProps}
      >
        <Body
          type="B2"
        >
          {doc.title}
        </Body>
        <Link
          href={(web ? undefined : doc.url)}
          type="L4"
          style={linkStyle}
          onClick={(web ? this.toggleModal : undefined)}
          {...(linkTarget ? { target: linkTarget } : {})}
        >
          <Image
            src={icon}
            mr={10}
            height="20px"
            position="relative"
            top="4px"
            display="inline-block"
          />
          {linkText}
        </Link>
        {web &&
          <ModalWithOverlay
            isVisible={this.state.isModalVisible}
            handleClick={this.toggleModal}
          >
            <ExtendedBox
              width={['100%', '100%', '640px', '800px']}
              height={['100%', '100%', '360px', '440px']}
            >
              <ReactPlayer
                url={doc.url}
                controls
                width="100%"
                height="100%"
                playing={this.state.isModalVisible}
              />
            </ExtendedBox>
          </ModalWithOverlay>}

      </ExtendedFlex>
    );
  }
}

DocumentItem.propTypes = {
  doc: PropTypes.object,
  web: PropTypes.bool,
  linkTarget: PropTypes.string,
};

export default DocumentItem;
