import types from './types';

export default {
  getGlossaryDataRequested: () => ({
    type: types.GET_GLOSSARY_REQUESTED,
  }),
  getGlossaryDataSucceeded: (data) => ({
    type: types.GET_GLOSSARY_SUCCEEDED,
    data,
  }),
  getGlossaryDataFailed: (error) => ({
    type: types.GET_GLOSSARY_FAILED,
    error,
  }),
};
