import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Heading } from '../../coreui';
import RadioGroup from '../../components/forms/RadioGroup';
import RadioLabel from '../../components/forms/RadioLabel';
import FollowUp from '../../components/isq/FollowUp';

const FINRAQuestions = ({
  isVisible,
  handleChange,
  value,
  followUp,
}) => {
  const [dirty, setDirty] = useState(false);

  const onChange = useCallback((e) => {
    setDirty(true);
    handleChange(e);
  }, [setDirty, handleChange]);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        FINRA Affiliation
      </Heading>
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        Are you an employee of FINRA or an associated person of a FINRA broker-dealer?
      </Heading>
      <RadioGroup
        name="isFinraBdEmployee"
        value={value}
        onChange={onChange}
        inputProps={{
          'data-test': 'is_finra_bd_employee',
          required: true,
        }}
      >
        <RadioLabel
          value="Yes"
          label="Yes"
        />
        <RadioLabel
          value="No"
          label="No"
        />
      </RadioGroup>
      {
        value === 'Yes' && dirty &&
        <FollowUp
          subheader="Have you notified your employer of your intention to invest and do you have the requisite approvals from your employer?"
          name="hasNotifiedFinraBdEmployer"
          value={followUp}
          handleChange={onChange}
          options={[
            {
              value: 'Yes',
              label: 'Yes',
            },
            {
              value: 'No',
              label: 'No',
            },
          ]}
        />
      }
    </>
  );
};

FINRAQuestions.propTypes = {
  isVisible: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.oneOf([
    'grow',
    'preserve',
  ]),
  followUp: PropTypes.string,
};

export default FINRAQuestions;
