import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import actions from '../actions';
import ISQv2 from './ISQv2';

const InvestmentQualification = ({
  ir,
  setIrIsqRequested,
  ...props
}) => {
  const promise = useRef(Promise.resolve());
  const [showBlockerPage, setShowBlockerPage] = useState(false);

  const handleSubmit = useCallback((data) => {
    return (new Promise((resolve, reject) => {
      setIrIsqRequested(ir, data, {
        resolve: () => {
          // Check if we need to show the blocker page.
          if (data.hasNotifiedFinraBdEmployer === 'No') {
            setShowBlockerPage(true);
            resolve();
          } else {
            // If we're doing a redirect, don't resolve the promise. We want the
            // form to appear like it's still submitting.
            window.location = `/invest/${ir}`;
          }
        },
        reject,
      });
    }));
  }, [ir, setIrIsqRequested]);
  const handlePartialSubmit = useCallback((data) => {
    if (isEmpty(data)) {
      // Nothing to do.
      return Promise.resolve();
    }
    // Keep track of the most recent partial submission. We use this to make
    // sure we don't short circuit it if the user requests a cancel by either
    // clicking the cancel link or pressing back, changing their answer to
    // please cancel, and then waits for the cancel.
    promise.current = (new Promise((resolve, reject) => {
      setIrIsqRequested(ir, data, {
        resolve,
        reject,
      });
    }));
    return promise.current;
  }, [ir, setIrIsqRequested]);
  const handleCancel = useCallback((data) => {
    if (showBlockerPage) {
      // If the blocker page is being displayed, there's nothing to submit.
      window.location = `/invest/${ir}/cancel`;
      return null;
    }
    return handlePartialSubmit(data).finally(() => (new Promise(() => {
      // We don't want to resolve this fully because we're doing a redirect and
      // want the form to appear like it's still submitting.
      window.location = `/invest/${ir}/cancel`;
    })));
  }, [ir, handlePartialSubmit, showBlockerPage]);

  return (
    <ISQv2
      handleSubmit={handleSubmit}
      handlePartialSubmit={handlePartialSubmit}
      handleCancel={handleCancel}
      showBlockerPage={showBlockerPage}
      {...props}
    />
  );
};

InvestmentQualification.propTypes = {
  ir: PropTypes.number,
  setIrIsqRequested: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setIrIsqRequested: (ir, data, promise) => {
    dispatch(actions.setIrIsqRequested(ir, data, promise));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentQualification);
