import React from 'react';
import PropTypes from 'prop-types';

import {
  Body,
  Heading,
} from '../../coreui';

import CalloutFrame from '../../components/CalloutFrame';
import ExclusiveAccessImage from '../../assets/img_panel_white_apts.jpg';

const ExclusiveAccess = ({
  heading,
  body,
}) => {
  return (
    <CalloutFrame
      image={ExclusiveAccessImage}
      bg="gray.0"
      imagePosition="second"
    >
      <Heading
        type="H2"
        mt={0}
      >
        {heading}
      </Heading>
      {
        body.map((item) => {
          return (
            <Body
              key={item}
              type="B1"
              mb={0}
            >
              {item}
            </Body>
          );
        })
      }
    </CalloutFrame>
  );
};

ExclusiveAccess.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
};

export default ExclusiveAccess;
