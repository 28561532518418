import React from 'react';
import PropTypes from 'prop-types';
import Styled from './index.styled';
import Text from '../Text';

const ReitRiskFactors = ({
  background = '#F2F2F2',
  title = 'Overview',
  content,
}) => {
  return (
    <Styled.ReitRiskFactors background={background}>
      <Styled.Container>
        <h1>{title}</h1>
        <Styled.DisclaimerCopy>
          <Text>{content}</Text>
        </Styled.DisclaimerCopy>
      </Styled.Container>
    </Styled.ReitRiskFactors>
  );
};

ReitRiskFactors.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default ReitRiskFactors;
