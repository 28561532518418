/* eslint camelcase: ["error", {allow: ["io_id", "io_title", "offering_type"]}] */
import { EventTypes } from 'redux-segment';

const actionMapper = {
  mapper: {
    USER_LOGIN_SUCCEEDED: (getState, action) => {
      return [{
        eventType: EventTypes.identify,
        eventPayload: {
          userId: action.data.id,
        },
      }];
    },
    USER_SESSION_SUCCEEDED: (getState, action) => {
      return [{
        eventType: EventTypes.identify,
        eventPayload: {
          userId: action.data.id,
        },
      }];
    },
    GET_IO_DATA_SUCCEEDED: (getState, action) => {
      const io = action.data.data[0];
      return [{
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Investment Opportunity Viewed - Server Side',
          properties: {
            io_id: io.id,
            io_title: io.label,
            offering_type: io.securitiesOfferingTypes,
          },
        },
      }];
    },
    USER_GETTING_TO_KNOW_YOU_SUCCEEDED: (getState, action) => {
      if (action.data.data.complete === true) {
        return [{
          eventType: EventTypes.track,
          eventPayload: {
            event: 'GTKY Form Submit',
          },
        }];
      }
      return [];
    },
  },
};

export default actionMapper;
