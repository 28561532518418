import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedBox,
  Box,
  ExtendedFlex,
  Heading,
  Body,
  VisibilitySensor,
  Link,
} from '../../../coreui';

import backgroundImage from '../../assets/img_advantage.jpg';

const FinancingAdvantage1 = ({
  handleSensorChange,
  isVisible,
}) => {
  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
    >
      <ExtendedBox
        w={1}
        bgRepeat="no-repeat"
        bgImage={backgroundImage}
        bgPosition="50% 26%"
        bgSize="cover"
      >
        <Box
          w={1}
          px={['15%', '25%', '10%', '10%', '10%']}
        >
          <ExtendedFlex
            flexDirection="column"
            align={['center', 'center', 'flex-end', 'flex-end', 'flex-end']}
          >
            <ExtendedFlex
              maxWidth={['100%', '100%', 400, 400, 540]}
              flexDirection="column"
              my={[60, 60, 100, 100, 100]}
            >
              <Heading
                type="H2"
                color="white"
                m={0}
                pr={20}
                mb="1em"
                opacity={isVisible ? '1' : '0'}
                transition={`
                  opacity 0.5s ${!isVisible ? '0' : '0.3'}s linear
                `}
              >
                REALTYMOGUL HAS A DISTINCT FINANCING ADVANTAGE
              </Heading>
              <VisibilitySensor
                callback={handleSensorChange}
              />
              <Body
                type="B1"
                color="white"
                fontWeight="light"
                mb={[20, 20, 20, 20, 20]}
                opacity={isVisible ? '1' : '0'}
                transition={`
                  opacity 0.5s ${!isVisible ? '0' : '0.6'}s linear
                `}
              >
                We offer both equity and preferred equity to
                real estate investors and companies.
              </Body>
              <Body
                type="B1"
                color="white"
                fontWeight="light"
                mb={[100, 100, 180, 250, 300]}
                opacity={isVisible ? '1' : '0'}
                transition={`
                  opacity 0.5s ${!isVisible ? '0' : '0.6'}s linear
                `}
              >
                Discretionary capital gives us control
                over the transaction
                from start to finish, providing an efficient
                and transparent process.
              </Body>
              <ExtendedFlex
                opacity={isVisible ? '1' : '0'}
                transition={`
                  opacity 0.5s ${!isVisible ? '0' : '0.9'}s linear
                `}
                flexDirection={['column', 'column', 'column', 'column', 'column']}
              >
                <Link
                  type="L2"
                  href="/financing/jv-equity"
                  mb={10}
                >
                  Learn Our JV Equity Process
                </Link>
              </ExtendedFlex>
            </ExtendedFlex>
          </ExtendedFlex>
        </Box>
      </ExtendedBox>
    </Box>
  );
};

FinancingAdvantage1.propTypes = {
  handleSensorChange: PropTypes.func,
  isVisible: PropTypes.bool,
};

export default FinancingAdvantage1;
