import React from 'react';
import PropTypes from 'prop-types';

import { ExtendedFlex } from '../../coreui';

const ShareRepurchaseRequest = ({ children }) => {
  return (
    <>
      <ExtendedFlex
        w={1}
        flexDirection="column"
        px={50}
        maxWidth={980}
        align="flex-start"
      >
        <ExtendedFlex
          flexDirection="column"
          w={1}
          maxWidth={650}
          mt={[50]}
          mb={50}
        >
          {children}
        </ExtendedFlex>
      </ExtendedFlex>
    </>
  );
};

ShareRepurchaseRequest.propTypes = {
  children: PropTypes.object,
};

export default ShareRepurchaseRequest;
