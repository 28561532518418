import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';

export const cleanUpIsqSubmission = (props, { timeHorizon, isqProps, nullifyProps = false }) => {
  const isqSubmission = pick(props, isqProps);

  // Perform employer type specific clean up to state.
  switch (isqSubmission.employmentType) {
    case 'employed':
      isqSubmission.employmentIndustry = null;
      isqSubmission.sourceOfIncome = null;
      delete isqSubmission.sameWorkAddress;
      if (!nullifyProps) {
        delete isqSubmission.employmentIndustry;
        delete isqSubmission.sourceOfIncome;
      }
      break;

    case 'self_employed':
      isqSubmission.sourceOfIncome = null;
      if (isqSubmission.sameWorkAddress) {
        isqSubmission.employerAddress = null;
        if (!nullifyProps) {
          delete isqSubmission.employerAddress;
        }
      }
      if (!nullifyProps) {
        delete isqSubmission.sourceOfIncome;
      }
      break;

    case 'retired':
      delete isqSubmission.sameWorkAddress;
      isqSubmission.employerAddress = null;
      if (!nullifyProps) {
        delete isqSubmission.employerAddress;
      }
      break;

    case 'student':
      isqSubmission.employmentPositionHeld = null;
      isqSubmission.employmentIndustry = null;
      delete isqSubmission.sameWorkAddress;
      isqSubmission.employerAddress = null;
      if (!nullifyProps) {
        delete isqSubmission.employmentPositionHeld;
        delete isqSubmission.employmentIndustry;
        delete isqSubmission.employerAddress;
      }
      break;

    case 'not_employed':
      isqSubmission.employer = null;
      isqSubmission.employmentPositionHeld = null;
      isqSubmission.employmentIndustry = null;
      delete isqSubmission.sameWorkAddress;
      isqSubmission.employerAddress = null;
      if (!nullifyProps) {
        delete isqSubmission.employer;
        delete isqSubmission.employmentPositionHeld;
        delete isqSubmission.employmentIndustry;
        delete isqSubmission.employerAddress;
      }
      break;

    default:
      break;
  }

  // Clean up the follow up questions.
  if (Object.prototype.hasOwnProperty.call(isqSubmission, 'primaryInvestmentReason') && isqSubmission.primaryInvestmentReason !== 'preserve') {
    isqSubmission.primaryInvestmentReasonFollowUp = null;
    if (!nullifyProps) {
      delete isqSubmission.primaryInvestmentReasonFollowUp;
    }
  }

  if (Object.prototype.hasOwnProperty.call(isqSubmission, 'moneyAccess') && isqSubmission?.moneyAccess !== 'Yes') {
    isqSubmission.moneyAccessFollowUp = null;
    if (!nullifyProps) {
      delete isqSubmission.moneyAccessFollowUp;
    }
  }

  if (Object.prototype.hasOwnProperty.call(isqSubmission, 'over20PctNetWorth') && isqSubmission?.over20PctNetWorth !== 'Yes') {
    isqSubmission.over20PctNetWorthFollowUp = null;
    if (!nullifyProps) {
      delete isqSubmission.over20PctNetWorthFollowUp;
    }
  }

  if (Object.prototype.hasOwnProperty.call(isqSubmission, 'isFinraBdEmployee') && isqSubmission?.isFinraBdEmployee !== 'Yes') {
    isqSubmission.hasNotifiedFinraBdEmployer = null;
    if (!nullifyProps) {
      delete isqSubmission.hasNotifiedFinraBdEmployer;
    }
  }

  // ISQ v1.
  if (Object.prototype.hasOwnProperty.call(isqSubmission, 'expStocks')) {
    const experienceFollowUp =
      isqSubmission.expStocks === 'none'
      && isqSubmission.expBonds === 'none'
      && isqSubmission.expRealEstate === 'none'
      && isqSubmission.expPrivateEquity === 'none'
      && isqSubmission.expVentureCapital === 'none'
      && isqSubmission.expSmallBiz === 'none';
    if (!experienceFollowUp) {
      delete isqSubmission.experienceFollowUp;
    }
  // ISQ v2.
  } else if (Object.prototype.hasOwnProperty.call(isqSubmission, 'expRealEstate') && isqSubmission.expRealEstate !== 'none') {
    isqSubmission.experienceFollowUp = null;
  }

  if (Object.prototype.hasOwnProperty.call(isqSubmission, 'exit') && Number.parseInt(isqSubmission.exit, 10) >= timeHorizon) {
    delete isqSubmission.exitFollowUp;
  }
  if (!isEqual(isqSubmission.risk, ['low'])) {
    delete isqSubmission.riskFollowUp;
  }

  // Cast to integers for submission.
  if (Object.prototype.hasOwnProperty.call(isqSubmission, 'annualIncome')) {
    isqSubmission.annualIncome = Number.parseInt(isqSubmission.annualIncome, 10);
  }
  if (Object.prototype.hasOwnProperty.call(isqSubmission, 'netWorth')) {
    isqSubmission.netWorth = Number.parseInt(isqSubmission.netWorth, 10);
  }
  if (Object.prototype.hasOwnProperty.call(isqSubmission, 'netWorthSpecific')) {
    isqSubmission.netWorthSpecific = (isqSubmission.netWorthSpecific !== undefined
      && Number.parseInt(isqSubmission.netWorthSpecific, 10)) || undefined;
  }

  // Clean up employerAddress.
  if (isqSubmission.employerAddress) {
    isqSubmission.employerAddress = pick(isqSubmission.employerAddress, [
      'country',
      'thoroughfare',
      'premise',
      'locality',
      'administrative_area',
      'postal_code',
    ]);
  }
  return isqSubmission;
};

export const getAdditionalStateChanges = ({ name }) => {
  let additionalStateChanges = {};

  if (name === 'employmentType') {
    // Reset the employer data when it's changing.
    additionalStateChanges = {
      employer: '',
      employmentPositionHeld: '',
      employmentIndustry: '',
      sourceOfIncome: '',
    };
  } else if (name === 'primaryInvestmentReason') {
    additionalStateChanges = {
      primaryInvestmentReasonFollowUp: undefined,
    };
  } else if (name === 'moneyAccess') {
    additionalStateChanges = {
      moneyAccessFollowUp: undefined,
    };
  } else if (name === 'over20PctNetWorth') {
    additionalStateChanges = {
      over20PctNetWorthFollowUp: undefined,
    };
  } else if (name === 'isFinraBdEmployee') {
    additionalStateChanges = {
      hasNotifiedFinraBdEmployer: undefined,
    };
  } else if ([
    'expRealEstate',
    'expPrivateEquity',
    'expVentureCapital',
  ].includes(name)) {
    additionalStateChanges = {
      experienceFollowUp: undefined,
    };
  }
  return additionalStateChanges;
};

export const updateFormDataByHook = (event, hook) => {
  const { name, value, type, checked } = event.target;
  const additionalStateChanges = getAdditionalStateChanges(event.target);

  const checkType = ['checkbox'].includes(type);
  hook((formData) => ({
    ...formData,
    ...additionalStateChanges,
    [name]: (checkType && Array.isArray(formData[name]))
      // Handle the case where we have a group of checkboxes.
      ? ((reducedSet) => (
        // Start with a reduced set that definitely excludes the value.
        // Then we just add it back in if the value is checked.
        checked ? [
          ...reducedSet,
          value,
        ] : reducedSet
      ))(formData[name].filter((val) => val !== value))
      // Otherwise, it's just 1:1 with name to value.
      : (() => (checkType ? checked : value))(),
  }));
};

export default {
  cleanUpIsqSubmission,
  getAdditionalStateChanges,
  updateFormDataByHook,
};
