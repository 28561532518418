import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { injectGlobal } from 'styled-components';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Content,
  Data,
  Theme,
} from '../../coreui';

export const glanceCSS = injectGlobal`
.at-a-glance .rm-coreui-content p {
  margin: 0;
}
`;

const AtAGlanceCol = ({
  items,
}) => {
  return (
    <ExtendedFlex
      flexDirection="column"
      flexBasis="calc(50% - 30px)"
    >
      {
        items &&
        items.map((item) => {
          return (
            <ExtendedFlex
              py={20}
              key={item.label}
              borderColor={Theme.colors.gray[7]}
              borderBottom="1px solid"
              mx={5}
              justifyContent="space-between"
              alignItems="flex-start"
              flexDirection={['column', 'column', 'column', 'row']}
            >
              <ExtendedFlex
                flexBasis="50%"
              >
                <Data
                  type="D5"
                  style={{
                    margin: '0px',
                  }}
                >
                  {item.label}
                </Data>
              </ExtendedFlex>
              <ExtendedFlex
                flexBasis="50%"
                className="at-a-glance"
              >
                <Content
                  type="B1"
                  textAlign={['left', 'left', 'left', 'right']}
                  width={1}
                >
                  {ReactHtmlParser(item.value)}
                </Content>
              </ExtendedFlex>
            </ExtendedFlex>
          );
        })
      }
    </ExtendedFlex>
  );
};

// eslint-disable-next-line
class AtAGlance extends React.Component {
  render() {
    const { items } = this.props;
    const columns = 2;
    let cols = null;
    if (items && items.length > 0) {
      cols = items.reduce((resultArray, item, index) => {
        const chunkIndex = index % columns;
        if (!resultArray[chunkIndex]) {
          // eslint-disable-next-line
          resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
    }
    return (
      <ExtendedBox
        maxWidth={1440}
        m="auto"
      >
        <Heading
          type="H8"
          display="block"
          textAlign="center"
          alignSelf="center"
          my={[40, 40, 60, 100, 120]}
        >
          Property At A Glance
        </Heading>
        <ExtendedFlex
          flexDirection={['column', 'column', 'column', 'row']}
          justifyContent="space-between"
          mx={40}
        >
          {
            cols &&
            cols.map((col, index) => {
              return (
                <AtAGlanceCol
                  // eslint-disable-next-line
                  key={`${index}_at_glance_column`}
                  items={col}
                />
              );
            })
          }
        </ExtendedFlex>
      </ExtendedBox>
    );
  }
}

AtAGlance.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

AtAGlanceCol.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

export default AtAGlance;
