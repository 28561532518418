import styled from 'styled-components';
import {
  color,
  fontSize,
  borders,
  borderColor,
  borderRadius,
  width,
  space,
  textAlign,
  lineHeight,
  fontWeight,
  height,
} from 'styled-system';

import { Theme } from '..';
import { placeholderColor } from '../helpers/style';

const TextInput = styled.input.attrs({
  type: 'text',
})`
  ${color}
  ${fontSize}
  ${width}
  ${lineHeight}
  ${space}
  ${fontWeight}
  ${textAlign}
  ${borders}
  ${borderColor}
  ${borderRadius}
  box-sizing: border-box;
  font-family: ${(props) => {
    if (props.fontFamily && props.fontFamily in Theme.fontFamily) {
      return Theme.fontFamily[props.fontFamily];
    }
    return props.fontFamily || 'Helvetica, Arial, sans-serif';
  }};
  box-shadow: none;
  ${(props) => {
    return height({
      height: 30,
      ...props,
    });
  }};
  display: ${(props) => props.display || 'inline'};
  text-indent: ${(props) => props.textIndent || '0'}

  &::placeholder {
    ${fontSize}
    ${placeholderColor}
    font-family: ${(props) => {
    if (props.fontFamily && props.fontFamily in Theme.fontFamily) {
      return Theme.fontFamily[props.fontFamily];
    }
    return props.fontFamily || 'Helvetica, Arial, sans-serif';
  }};
  }
`;

export default TextInput;
