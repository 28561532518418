import types from './types';

export default (state, action) => {
  switch (action.type) {
    case types.FLAG_TOGGLE_SUCCEEDED:
      return {
        flagged: (action.data.flagStatus === 'flagged' && action.data.flagSuccess),
      };
    case types.FLAG_TOGGLED:
      return {
        flagged: action.opts.flagged,
      };
    case types.FLAG_TOGGLE_FAILED:
      return {
        flagged: !state.flagged,
      };
    default:
      return {
        ...state,
      };
  }
};
