import React from 'react';

import {
  ExtendedFlex,
  Link,
  Body,
} from '../../coreui';
import TimelineWrapper from '../../components/TimelineWrapper';
import TimelineContainer from '../../components/TimelineContainer';
import TimelineCard from '../../components/TimelineCard';

import iconCore from '../../assets/icon_core.svg';
import iconOffMarket from '../../assets/icon_off_market.svg';
import iconShield from '../../assets/icon_shield.svg';
import iconQuality from '../../assets/icon_quality.svg';
import iconTenancy from '../../assets/icon_tenancy.svg';
import iconCheck from '../../assets/Icon_check.svg';
import iconMobile from '../../assets/icon_mobile.svg';
import iconPartner from '../../assets/icon_partner.svg';

const HowItWorks = () => {
  return (
    <TimelineWrapper>
      <TimelineContainer>
        <TimelineCard
          icon={iconCore}
          heading="STEP 1: SUBMISSION"
          body={`As a sponsor, you will provide us:
          <br/><br/>
          • An overview of your company and management including your track record<sup>1</sup>
          <br/>
          • An offering memorandum for the proposed real estate transaction, including an organization chart<sup>2</sup>
          <br/>
          • An Excel or Argus financial model and supporting information<sup>3</sup>`}
        />
        <TimelineCard
          icon={iconOffMarket}
          heading="STEP 2: INITIAL SCREENING"
          body="Our internal team will review your submission to determine whether your proposed project meets the minimum qualifications required for Sponsors to license and use the RealtyMogul technology platform. We will typically provide feedback within 3-5 business days."
        />
      </TimelineContainer>
      <TimelineContainer
        heading="SCREENING"
        dotsBelow
        dotsLeftUp
        dotsRightUp
      />
      <TimelineContainer
        heading="DUE DILIGENCE AND FINAL UNDERWRITING"
        dotsLeftDown
        dotsRightDown
      >
        <TimelineCard
          icon={iconShield}
          heading="STEP 3: MEETING MINIMUM QUALIFICATIONS"
          body="If the proposed project meets our initial minimum qualifications, we will then engage in a more detailed review of the sponsor group and the project, including background, credit, and criminal reports for all managing members of the Sponsor group to make a final determination as to whether the Sponsor and project meet our minimum qualifications. Finally, we schedule a site visit to the property to walk the asset with you and your team."
        />
        <TimelineCard
          icon={iconQuality}
          heading="STEP 4: TECHNOLOGY SERVICES AGREEMENT"
          body="Next, we formalize the Technology Services Agreement, the legal agreement between RM Technology, LLC and the Sponsor. This Agreement outlines the Sponsor’s non-exclusive license and use of the platform and related technology solutions provided by RealtyMogul on an ongoing basis."
        />
      </TimelineContainer>
      <TimelineContainer
        heading=" "
        dotsRightUp
        dotsLeftDown
      />
      <TimelineContainer>
        <TimelineCard
          icon={iconTenancy}
          heading="STEP 5: LAUNCH"
          body="Upon the execution of the Technology Services Agreement, the project content and a marketing campaign are drafted and approved by the Sponsor and your offering will be ready to launch on the RealtyMogul platform as “Open for Pledging.” Pledging allows investors to create non-binding reservations for a specific investment amount so you can gauge interest and appropriately target the marketing campaign."
        />
        <TimelineCard
          icon={iconCheck}
          heading="STEP 6: OPEN FOR FUNDING"
          body="To transition from “Open for Pledging” to “Open for Funding,” all of your legal offering documents must be finalized. RealtyMogul will set your team up to host a webinar. The webinar will give you an opportunity to share information about your project and answer investor questions. At this time, the transaction will be officially “Open for Funding” and binding capital commitments are made by accredited investors."
        />
      </TimelineContainer>
      <TimelineContainer
        heading="FUNDRAISING"
        dotsLeftUp
        dotsRightUp
        dotsBelow
      />
      <TimelineContainer
        heading="CLOSING"
        dotsRightDown
        dotsLeftDown
      >
        <TimelineCard
          icon={iconMobile}
          heading="PREPARATION FOR CLOSING"
          body="Prior to closing, you review and approve all investors interested in your offering. Our platform and technology collects, processes and provides you with detailed information for each potential investor including “Know-Your-Customer” compliance and accreditation verification to help facilitate your investor review and approval process."
        />
        <TimelineCard
          icon={iconPartner}
          heading="TIME TO CLOSE"
          body="Once all investors have been approved by you, RealtyMogul coordinates with an unaffiliated escrow agent to wire all funds into the property escrow account 2-3 days prior to the transaction closing. "
        />
      </TimelineContainer>
      <ExtendedFlex
        mt={50}
      >
        <Link
          type="L1"
          href="/sponsor-application"
          mx="auto"
        >
          Apply Here
        </Link>
      </ExtendedFlex>
      <ExtendedFlex>
        <Body
          type="B2"
          display="block"
          mt={50}
          ml={20}
        >
          1. Includes request for most recent financial statement.<br /><br />
          2. Real estate funds need to provide the limited partnership agreement and private placement memorandum.<br /><br />
          3. Applicable supporting information include but are not limited to purchase sale agreement, applicable leases, debt term sheet, property condition report, applicable environmental reports, entitlements, zoning, etc.
        </Body>
      </ExtendedFlex>
    </TimelineWrapper>
  );
};

export default HowItWorks;
