import React from 'react';
import Input from '../../components/forms/Input';

const NetWorthSpecificInput = (props) => {
  return (
    <Input
      id="specific_net_worth"
      placeholder="$"
      name="specific_net_worth"
      data-test="specific_net_worth"
      required
      {...props}
    />
  );
};

export default NetWorthSpecificInput;
