import {
  takeEvery,
  put,
  call,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';
import { callApiCallback } from '../../../api';

export function* watchlistFlagFlow(action) {
  try {
    const investment = action.investment;
    const callback = investment.watchlist.flagged ?
      investment.watchlist.unflagLink : investment.watchlist.flagLink;

    const response = yield call(callApiCallback, callback);

    if (!response.data.flagSuccess) {
      throw response;
    }

    yield put(
      actions.watchInvestmentFlagSucceeded(
        action.investment,
        response.data,
      ),
    );
  } catch (error) {
    yield put(
      actions.watchInvestmentFlagFailed(
        action.investment,
        error,
      ),
    );
  }
}

export function* watchWatchlistFlagRequested() {
  yield takeEvery(
    types.WATCH_INVESTMENT_FLAG_REQUESTED,
    watchlistFlagFlow,
  );
}

export default watchWatchlistFlagRequested;
