import types from './types';

export default {
  getBrowsePageDataRequested: () => ({
    type: types.GET_BROWSE_PAGE_DATA_REQUESTED,
  }),
  getBrowsePageDataSucceeded: (data) => ({
    type: types.GET_BROWSE_PAGE_DATA_SUCCEEDED,
    data,
  }),
  getBrowsePageDataFailed: (error) => ({
    type: types.GET_BROWSE_PAGE_DATA_FAILED,
    error,
  }),
  closeWelcomeMessageRequested: () => ({
    type: types.CLOSE_WELCOME_MESSAGE_REQUESTED,
  }),
  closeWelcomeMessageSucceeded: (data) => ({
    type: types.CLOSE_WELCOME_MESSAGE_SUCCEEDED,
    data,
  }),
  closeWelcomeMessageFailed: (error) => ({
    type: types.CLOSE_WELCOME_MESSAGE_FAILED,
    error,
  }),
  updateBrowseNavAnimationSettings: () => ({
    type: types.UPDATE_BROWSE_NAV_ANIMATION_SETTINGS,
  }),
  getBrowseOpenDataRequested: () => ({
    type: types.GET_BROWSE_OPEN_DATA_REQUESTED,
  }),
  getBrowseOpenDataSucceeded: (data) => ({
    type: types.GET_BROWSE_OPEN_DATA_SUCCEEDED,
    data,
  }),
  getBrowseOpenDataFailed: (error) => ({
    type: types.GET_BROWSE_OPEN_DATA_FAILED,
    error,
  }),
  getBrowseClosedDataRequested: () => ({
    type: types.GET_BROWSE_CLOSED_DATA_REQUESTED,
  }),
  getBrowseClosedDataSucceeded: (data) => ({
    type: types.GET_BROWSE_CLOSED_DATA_SUCCEEDED,
    data,
  }),
  getBrowseClosedDataFailed: (error) => ({
    type: types.GET_BROWSE_CLOSED_DATA_FAILED,
    error,
  }),
};
