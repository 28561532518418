// Creates collection of all the content that is used in slides.
// Generates an array that is consumed by a .map and displayed in order by carousel.

import growBarChart from '../../assets/slide_grow_bar_chart_05_2021.png';
import reitsImg from '../../assets/reits.png';
import efficientFrontierChart from '../../assets/slide_diversify_efficient_frontier.png';

// Creates slide collection with appropriate image for passive income number.
// INPUT: num (OR null/undef/bad data), whole number in millions for passive income
// OUTPUT: array, slide info ready to be consumed (first slide may be trimmed if input bad)
const createSlideCollection = (passiveIncomeNum, variant, tokens) => {
  // Basic slide collection, potentially will be modified depending on passive income image

  const isVariant = variant !== 'none';
  const slideCollection = [
    {
      hookTitle: isVariant ? 'INDIVIDUAL DEALS' : 'Objective: Income',
      mainTitle: isVariant ? 'Invest in apartments, office, retail, industrial and other commercial properties' : 'Members can invest in professionally managed, well-leased properties.',
      bodyContent: isVariant ? 'Sort opportunities by projected return metrics, hold period, property type, investment strategy and other criteria to find the deals that are right for you.' : 'RealtyMogul simplifies commercial real estate investing, giving Members access to commercial real estate opportunities with the potential to generate income.',
      number: passiveIncomeNum,
      linkButtonDestination: isVariant ? '/investment-opportunities' : '/benefits-of-investing#passive-income',
      captionText: `of Property Value Offered on the RealtyMogul platform since inception and as of ${tokens?.totalPropertyValueDate || '4/30/22'}`,
    },
    {
      hookTitle: isVariant ? 'REAL ESTATE INVESTMENT TRUSTS (REITs)' : 'Objective: Grow Portfolio Value',
      mainTitle: isVariant ? 'Invest in a portfolio of professionally-managed commercial real estate' : 'Consider real-estate as a vehicle for long-term appreciation',
      bodyContent: isVariant ? 'Buy shares in a portfolio of commercial properties located across the United States. Many of the properties in RealtyMogul’s two public, non-traded REITs were once only available as individual deals to accredited investors' : 'By investing in markets with the potential for growth, and properties with the potential for operational improvement, the RealtyMogul platform provides opportunities that may grow wealth over the long-term.',
      image: isVariant ? reitsImg : growBarChart,
      linkButtonDestination: isVariant ? '/investment-opportunity/reits' : '/benefits-of-investing#grow-value',
      captionText: isVariant ? null : 'NAREIT Equity REIT Index Annualized Return',
      disclosureTooltipText: 'Disclosure',
      disclosureTooltipParagraphs: isVariant ? ['3. Represents the average total return based on the average aggregate distributions issued by RealtyMogul Income REIT, LLC and increase in NAV per share for the last consecutive twelve months’ ended September 30, 2021. An individual shareholder’s total return may vary from this average total return, and there is no assurance that shareholders will be able to realize the estimated NAV per share upon attempting to sell their shares. For purposes of this calculation, “Total Return” equals distributions issued to a shareholder plus the change in NAV per share (either positive or negative), less any applicable share repurchase fees, over the course of such shareholder’s investment.', '4. Represents the average total return based on the average aggregate distributions issued by RealtyMogul Apartment Growth REIT, Inc. and increase in NAV per share for the quarter ended September 30, 2021 and the last twelve months’ ended September 30, 2021. An individual stockholder’s total return may vary from this average total return, and there is no assurance that stockholders will be able to realize the estimated NAV per share upon attempting to sell their shares. For purposes of this calculation, “Total Return” equals distributions issued to a stockholder plus the change in NAV per share (either positive or negative), less any applicable share repurchase fees, over the course of such stockholder’s investment.'] : [
        'Average annualized returns (%) as of 12/31/20',
        'NAREIT Equity REIT Index sourced from NCREIF (NCREIF.org). The NAREIT Equity REIT Index contains all publicly listed US Equity REITs not designated as Timber REITs or Infrastructure REITs. The NAREIT Equity REIT Index is illustrative of the potential average annualized returns available to investors in Commercial Real Estate. REITs offered by RealtyMogul are not publicly traded, require minimum investments and may have better or worse average annualized return performance compared to this Index.',
      ],
    },
    {
      hookTitle: 'Objective: Diversification',
      mainTitle: 'The strongest portfolios often include alternative investments.',
      bodyContent: 'The “efficient frontier” is the highest expected return for a defined level of risk. Including alternative investments like real estate in a portfolio has historically improved returns, while decreasing risk.',
      image: efficientFrontierChart,
      linkButtonDestination: '/benefits-of-investing#diversified-portfolio',
      captionText: 'Efficient Frontier: Traditional investments with & without alternatives',
      disclosureTooltipText: 'Disclosure',
      disclosureTooltipParagraphs: [
        'Based on the returns of the S&P 500 Index, Barclays U.S. Aggregate Bond Index, and the NCREIF (National Council of Real Estate Investment Fiduciaries) Index, with and without an asset allocation to direct real estate, over an 11-year time period (2006-2016).',
        'Each index provides a broad representation of a particular asset class and is not indicative of any investment. Asset allocation does not ensure a profit or protect against a loss. The rates of returns shown do not reflect the deduction of fees and expenses inherent in investing.',
      ],
    },
  ];

  // If we had bad data to generate passive income image we remove that slide entirely.
  if (slideCollection[0].image === null) {
    slideCollection.splice(0, 1);
  }

  return slideCollection;
};

export default createSlideCollection;
