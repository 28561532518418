import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  Heading,
  Body,
  Link,
  InlineTextLink,
} from '../../../coreui';
import { autoInvestUrl } from '../../links';
import AutoInvestContentWrapper from './AutoInvestContentWrapper';

const NotEligible = ({
  link,
}) => {
  return (
    <AutoInvestContentWrapper>
      <ExtendedFlex
        flexDirection="column"
        w={1}
        maxWidth={650}
      >
        <Heading
          type="H2"
        >
          We are Unable to Enroll your Account in to Auto Invest
        </Heading>
        <Body
          type="B1"
        >
          Based on the information provided, your current investment amount is
          not eligible for Auto Invest enrollment at this time due to qualified
          purchaser limits as set forth by SEC regulations.
        </Body>
        <Body
          type="B1"
        >
          For more information, please see the State Law and Exemption and Purchase Restrictions outlined in the{' '}
          <InlineTextLink
            href={link}
            target="_blank"
          >
            Offering Circular
          </InlineTextLink>
          {' '}for this offering. If you have any questions, please contact us
          at{' '}
          <InlineTextLink
            href="tel:8777817062"
          >
            (877) 781-7062
          </InlineTextLink>
          {' '}or email{' '}
          <InlineTextLink
            href="mailto:investor-help@realtymogul.com"
          >
            investor-help@realtymogul.com
          </InlineTextLink>.
        </Body>
        <Link
          type="L4"
          to={autoInvestUrl}
        >
          Back to Auto Investments Home &gt;
        </Link>
      </ExtendedFlex>
    </AutoInvestContentWrapper>
  );
};

NotEligible.propTypes = {
  link: PropTypes.string,
};

export default NotEligible;
