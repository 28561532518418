import React from 'react';
import PropTypes from 'prop-types';

import PlaidLink from './PlaidLink';
import { ExtendedFlex, StyledButton } from '../../coreui';

const PlaidLinkWrapper = (props) => {
  return (
    <ExtendedFlex
      flexDirection="column"
      my={80}
    >
      {
        props.token ? (
          <PlaidLink
            {...props}
          >
            Connect to Bank
          </PlaidLink>
        ) : (
          <StyledButton
            type="L1"
            loading
            disabled
          >
            Connect to Bank
          </StyledButton>
        )
      }
    </ExtendedFlex>
  );
};

PlaidLinkWrapper.propTypes = {
  token: PropTypes.string,
};

export default PlaidLinkWrapper;
