import React from 'react';
import PropTypes from 'prop-types';

import {
  InlineTextLink,
  Body,
  Heading,
} from '../../../../coreui';
import Select from '../../../../components/forms/Select';

const SelectBankAccount = ({
  value,
  options,
  onChange,
}) => {
  return (
    <>
      <Heading
        type="H6"
        textTransform="uppercase"
      >
        Select a bank account
      </Heading>
      <Body
        type="B2"
      >
        Select from an approved bank account below. If you would like to add
        a new account, please contact us
        at <InlineTextLink href="tel:+18777817062">877-781-7062</InlineTextLink> or
        via email
        at <InlineTextLink href="mailto:investor-help@realtymogul.com">investor-help@realtymogul.com</InlineTextLink>.
      </Body>
      <Select
        name="bank_account"
        value={value}
        onChange={onChange}
        placeholder="Select a bank account"
        showEmpty={false}
        required
        options={options}
      />
    </>
  );
};

SelectBankAccount.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default SelectBankAccount;
