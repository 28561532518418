/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedBox,
  Circle,
  Text,
  Link,
  Image,
  isDrupal,
} from '../../coreui';

import {
  messagesUrl,
} from '../../dashboard/links';

import messagesIconBlack from '../../assets/bell_black.svg';
import messagesIconWhite from '../../assets/bell_white.svg';

const types = {
  black: messagesIconBlack,
  white: messagesIconWhite,
};

class HeaderMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: '1',
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseEnter() {
    this.setState({
      opacity: '0.5',
    });
  }

  handleMouseLeave() {
    this.setState({
      opacity: '1',
    });
  }

  render() {
    const {
      messages,
      type,
    } = this.props;

    const {
      opacity,
    } = this.state;

    return (
      <Link
        {...(isDrupal() ? {
          href: messagesUrl,
        } : {
          to: messagesUrl,
        })}
        display="block"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <ExtendedBox
          position="relative"
          mr={[0, 0, 0, 20]}
          w={35}
          height={30}
          hover
        >
          <Image
            src={types[type]}
            w={35}
            height={30}
            opacity={opacity}
            transition="opacity 0.2s linear"
            hover
          />
          {
            messages > 0 &&
            <Circle
              w={20}
              height={20}
              bg="blue.1"
              position="absolute"
              top="0"
              right="0"
              border={2}
              borderColor="white"
            >
              <Text
                fontFamily="sansSerif"
                letterSpacing="0"
                fontWeight={600}
                fontSize={8}
                color="white"
              >
                {messages}
              </Text>
            </Circle>
          }
        </ExtendedBox>
      </Link>
    );
  }
}

HeaderMessage.propTypes = {
  messages: PropTypes.number,
  type: PropTypes.oneOf([
    'white',
    'black',
  ]),
};

HeaderMessage.defaultProps = {
  type: 'black',
};

export default HeaderMessage;
