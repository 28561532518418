/* eslint-disable react/jsx-props-no-spreading, no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Link,
} from '../../../coreui';

class Tab extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { label, onClick } = this.props;
    onClick(label);
  }

  render() {
    const {
      onClick,
      props: {
        activeTab,
        label,
        isNew = false,
      },
    } = this;

    return (
      <Link
        {...(activeTab === label ? isNew ? {
          bg: '#176DB6',
          color: '#FFF',
        }
          :
          {
            bg: 'black',
            color: 'white',
          } : isNew ?
          {
            color: '#176DB6',
            borderColor: '#176DB6',
          } : {}
        )}
        hoverBG={isNew ? '#176DB6' : '#202020'}
        type="L7"
        mr={[2.5, 10]}
        textAlign="center"
        transition="all 0.5s linear"
        onClick={onClick}
        textTransform="uppercase"
      >
        {label}
      </Link>
    );
  }
}

Tab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
};

export default Tab;
