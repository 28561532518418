import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  Box,
  ExtendedFlex,
  Heading,
  Body,
  VisibilitySensor,
  Theme,
} from '../../../coreui';

import DynamicBrowseJoinLink from '../../../helpers';

import backgroundImage from '../../assets/img_leftpanel.jpg';

const InvestmentAlternativesSection1 = ({
  isLoggedIn,
  handleSensorChange,
  isVisible,
}) => {
  return (
    <Box
      width={[1, 1, 0.5, 0.5, 0.5]}
    >
      <ExtendedBox
        w={1}
        bgRepeat="no-repeat"
        bgImage={backgroundImage}
        bgPosition="50% 26%"
        bgSize="cover"
      >
        <Box
          w={1}
          px={['15%', '25%', '10%', '10%', '10%']}
        >
          <ExtendedFlex
            flexDirection="column"
            align={['center', 'center', 'flex-end', 'flex-end', 'flex-end']}
          >
            <ExtendedFlex
              maxWidth={['100%', '100%', 400, 400, 540]}
              flexDirection="column"
              my={[60, 60, 100, 100, 100]}
            >
              <Heading
                type="H2"
                color="white"
                m={0}
                mb="1em"
                opacity={isVisible ? '1' : '0'}
                transition={`
                  opacity ${Theme.fadeInDelay}ms ${!isVisible ? '0' : '0.3'}s linear
                `}
              >
                PURSUING SMART INVESTMENT ALTERNATIVES
              </Heading>
              <VisibilitySensor
                callback={handleSensorChange}
              />
              <Body
                type="B1"
                color="white"
                fontWeight="light"
                mb={[10, 10, 20, 20, 20]}
                mt={0}
                opacity={isVisible ? '1' : '0'}
                transition={`
                  opacity ${Theme.fadeInDelay}ms ${!isVisible ? '0' : '0.6'}s linear
                `}
              >
                Forward-thinking investors deserve smart alternatives outside the volatility of the stock market and public exchanges.  Real estate may provide a solution.
              </Body>
              <Body
                type="B1"
                color="white"
                fontWeight="light"
                mb={[100, 150, 325, 325, 400]}
                opacity={isVisible ? '1' : '0'}
                transition={`
                  opacity ${Theme.fadeInDelay}ms ${!isVisible ? '0' : '0.6'}s linear
                `}
              />
              <ExtendedBox
                opacity={isVisible ? '1' : '0'}
                transition={`
                  opacity ${Theme.fadeInDelay}ms ${!isVisible ? '0' : '0.9'}s linear
                `}
              >
                <DynamicBrowseJoinLink
                  isLoggedIn={isLoggedIn}
                />
              </ExtendedBox>
            </ExtendedFlex>
          </ExtendedFlex>
        </Box>
      </ExtendedBox>
    </Box>
  );
};

InvestmentAlternativesSection1.propTypes = {
  isLoggedIn: PropTypes.bool,
  handleSensorChange: PropTypes.func,
  isVisible: PropTypes.bool,
};

export default InvestmentAlternativesSection1;
