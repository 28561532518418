import React from 'react';
import PropTypes from 'prop-types';

import StyledTable from '../../dashboard/components/StyledTable';
import HeaderComponent from '../../dashboard/components/TableHeader';
import TableCell from './TableCell';

const columns = [
  {
    id: 'title',
    Header: <HeaderComponent>Job Title</HeaderComponent>,
    accessor: 'title',
    Cell: TableCell,
    className: 'title-column',
    headerClassName: 'title-column',
  },
  {
    id: 'weight',
    accessor: 'weight',
    show: false,
  },
  {
    id: 'department',
    Header: (
      <HeaderComponent
        justify="center"
      >
        Department
      </HeaderComponent>
    ),
    accessor: 'department',
    Cell: TableCell,
  },
  {
    Header: <HeaderComponent>Location</HeaderComponent>,
    accessor: 'location',
    Cell: TableCell,
    className: 'location-column',
    headerClassName: 'location-column',
  },
];

const JobListings = ({ jobs }) => {
  return (
    <StyledTable
      data={jobs.map((job, i) => ({
        ...job,
        weight: i,
      }))}
      noDataText="No job listings."
      additionalTableProps={{
        showPageSizeOptions: false,
        defaultSorted: [{
          id: 'weight',
          desc: false,
        }],
      }}
      columns={columns}
      showPagination={false}
      pageSize={jobs.length}
      tableWrapperProps={{
        overflowX: 'hidden',
      }}
    />
  );
};

JobListings.propTypes = {
  jobs: PropTypes.array,
};

export default JobListings;
