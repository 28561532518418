import IconCashFlow from './assets/icon_income.svg';
import IconInflationProtection from './assets/icon_protection.svg';
import IconDiversification from './assets/icon_diversification.svg';
import IconCore from './assets/icon_core.svg';
import IconValueAdd from './assets/icon_valadd.svg';
import IconOpportunistic from './assets/icon_opportunistic.svg';

export const BalancingRiskData = {
  heading: 'BALANCING RISK AND RETURN',
  paragraphs: [
    {
      text: 'Moving between the three primary real estate investment strategies is a bit like stepping up the ladder in terms of taking on more risk and, in theory, being compensated for that risk with higher returns.',
    },
    {
      text: 'A well-balanced commercial real estate portfolio may include some or even all of these different investment strategies.',
    },
  ],
  imageLabel: 'INVESTMENT STRATEGY RISK/REWARD',
};

export const CalloutsData = [
  {
    header: 'LEARN MORE',
    subHeader: 'See how investing in real estate can help you meet your financial goals.',
    linkText: 'BENEFITS OF INVESTING >',
    linkUrl: '/benefits-of-investing',
    borderRight: [0, 0, 0, 1],
    borderColor: 'gray.7',
  },
  {
    header: 'DISCUSS IN PERSON',
    subHeader: 'Speak to an Investor Relations professional today about using the RealtyMogul platform.',
    linkText: '(877) 781-7062',
    linkUrl: '/contact-us',
    borderRight: [0, 0, 0, 1],
    borderColor: 'gray.7',
  },
  {
    header: 'FAQS',
    subHeader: 'Looking for more answers? Get all your questions answered here.',
    linkText: 'VIEW ALL FAQS >',
    linkUrl: '/knowledge-center/faqs',
  },
];

export const InvestmentStrategiesData = {
  heading: 'INVESTMENT STRATEGIES',
  subHeading: ['Each of the three primary categories of real estate investment strategies has its own risk and return characteristics.'],
  cards: [
    {
      label: 'CORE',
      image: IconCore,
      blurb: 'Properties that are stable, fully leased, well-located and typically Class A. Lower risk and lower reward, with low leverage, if any.',
    },
    {
      label: 'VALUE-ADD',
      image: IconValueAdd,
      blurb: 'Lower occupancy or secondary market locations with an opportunity to increase value through renovations or repositioning. Medium risk and reward with low to medium leverage.',
    },
    {
      label: 'OPPORTUNISTIC',
      image: IconOpportunistic,
      blurb: 'Typically raw land or ground-up development with little to no near term cash flow. High risk and high reward with high leverage.',
    },
  ],
};

export const PassiveVsActiveData = {
  heading: 'PASSIVE VS. ACTIVE FORMS OF OWNERSHIP',
  subHeading: ['The way an investor owns real estate can be just as important as the underlying real estate and investment strategy. Considerations need to be made for taxes, transparency, property operations, potential tenant issues, diversification and individual financial responsibility.'],
  notOffered: {
    heading: 'Not offered by RealtyMogul',
    label: 'Higher Minimum Investment and High Effort',
    paragraphs: [
      'Investor purchases their own income property & hires a third party property manager to oversee the property',
      'Investor purchases their own income property & does their own property management & upkeep',
    ],
  },
  offered: {
    heading: 'Offered by RealtyMogul',
    label: 'Lower Minimum Investment and Low Effort',
    paragraphs: [
      'Investment in a fund that invests in properties managed by operating partners who handle the day to day',
      'Investment in a property with an operating partner who handles the day-to-day & provides oversight and management',
    ],
  },
};

export const PotentialBenefitsData = [
  {
    title: 'Cash Flow',
    image: IconCashFlow,
    body: 'Properties earn rental income from tenants. The more stabilized the property or higher its occupancy, the greater the chance of a steady and predictable stream of cash',
  },
  {
    title: 'Inflation Protection',
    image: IconInflationProtection,
    body: 'Over the long-term, real estate may provide a hedge against inflation, since property values and rental income typically increase during periods of inflation',
  },
  {
    title: 'Diversification',
    image: IconDiversification,
    body: 'Real estate can provide diversification within a portfolio of traditional investments such as stocks and bonds',
  },
];

export const PropertyTypesData = [
  {
    heading: 'Multifamily',
    description: 'Residential buildings that vary by location (urban or suburban) and may be further classified by structure: high-rise, mid-rise, or garden-style.',
    advantages: 'Economic drivers include demographic trends, home ownership, household formation rates, and local employment growth. Leases are typically short term and adjust quickly to market conditions. Generally considered to be one of the more defensive investment types within commercial real estate, though they are still subject to competitive pressures from newer construction.',
  },
  {
    heading: 'Retail',
    description: 'Everything from small shopping centers, strip malls, and outlets to large power centers with a "category-dominating" anchor tenant.',
    advantages: 'Most broadly influenced by the state of the national economy generally, especially such indicators as employment growth and consumer confidence levels. More local factors include the property location and its traffic flow; population demographics; and local household incomes and buying patterns. Leases also often have long terms, which means that after a while lease rates may lag current market rates, and step ups may need to wait until lease expirations.',
  },
  {
    heading: 'Office',
    description: 'Range from high-rise multi-tenant structures in city business districts to mid-rise single-tenant buildings in suburban areas.',
    advantages: 'Rents and valuations are influenced by employment growth and a region\'s economic focus. Individualized tenant improvements are usually not very involved, but credit quality of tenants is key; re-leases of office space typically require some lead time to consummate. Office properties often have longer-term leases that can lag behind current market lease rates, so that significant "step-ups" or "step-downs" of rental rates may occur when leases expire.',
  },
  {
    heading: 'Industrial',
    description: 'Manufacturing facilities, warehouse and distribution centers, research & development (R&D) properties and flex-space.',
    advantages: 'Manufacturing and R&D properties tend to be build-to-suit buildings that can be difficult to "re-tenant" without extensive modifications, while warehouses and distribution centers can be more generic buildings. Industrial properties are also influenced less by local job growth more than by larger economic drivers such as global trade growth (imports and exports) and corporate inventory levels. As with office buildings and retail centers, industrial property leases tend to have long terms, so that over time lease rates can fall behind "market."',
  },
  {
    heading: 'Other',
    description: 'Self-storage facilities, mobile home parks, student housing and hospitality.',
    advantages: 'Key drivers include demographic trends, the state of the national economy in general, and large macroeconomic drivers such as supply/demand.',
  },
];

export default {};
