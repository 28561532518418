import React, { Component } from 'react';
import PropTypes from 'prop-types';
import animateScrollTo from 'animated-scroll-to';
import get from 'lodash/get';

import {
  Span,
  Theme,
} from '../coreui';

const startingDisplay = Theme.breakpoints.reduce((accum) => {
  return [
    ...accum,
    null,
  ];
}, ['none']);

class ScrollToSpanOnMount extends Component {
  constructor(props) {
    super(props);
    this.wrapper = null;
    this.breakpoints = [];
    this.isUnmounted = false;

    this.setWrapper = this.setWrapper.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.getOffset = this.getOffset.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.scrollTo();
    }, this.props.delay);
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  setWrapper(element) {
    this.wrapper = element;
  }

  getOffset() {
    const offsetOptions = this.props.offset;
    if (!Array.isArray(offsetOptions)) {
      return offsetOptions;
    }
    return this.breakpoints.reduce((offset, el, i) => {
      // Check if the span is visible.
      if (get(el, 'offsetParent', null) !== null) {
        // Find the offset at that index in the options.
        const offsetOpt = offsetOptions
          .find((value, index) => index === i);
        // If we found a value, use that as the new offset.
        if (offsetOpt !== undefined && offsetOpt !== null) {
          return offsetOpt;
        }
      }
      return offset;
    }, 0);
  }

  scrollTo() {
    if (this.isUnmounted) {
      return;
    }
    const offset = this.getOffset();
    animateScrollTo(this.wrapper, {
      passive: true,
      offset,
    });
  }

  render() {
    return (
      <>
        <span ref={(el) => { this.wrapper = el; }} />
        {
          Theme.breakpoints.map((value, i) => (
            <Span
              key={value}
              display={(() => {
                const display = [...startingDisplay]
                  .fill('block', i, i + 1);
                return display;
              })()}
              innerRef={(el) => { this.breakpoints[i] = el; }}
            />
          ))
        }
      </>
    );
  }
}

ScrollToSpanOnMount.propTypes = {
  delay: PropTypes.number,
  offset: PropTypes.number,
};

ScrollToSpanOnMount.defaultProps = {
  delay: 100,
};

export default ScrollToSpanOnMount;
