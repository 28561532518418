import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
} from '../../coreui';

export const Filler = ({
  active,
}) => (
  active
    ? (
      <ExtendedFlex
        w={1}
        align="center"
        justify="center"
        mx={[null, null, 10]}
      >
        <ExtendedFlex
          flex="1 0 5px"
          height={5}
          width={5}
          borderRadius="50%"
          bg="blue.0"
          p={0}
          display={['none', null, 'block']}
        />
        <ExtendedFlex
          flex="1 1 auto"
          align="center"
          w={1}
          borderTop="1px solid"
          borderColor="blue.0"
          height={1}
        />
        <ExtendedFlex
          flex="1 0 5px"
          height={5}
          width={5}
          borderRadius="50%"
          bg="blue.0"
          p={0}
          display={['none', null, 'block']}
        />
      </ExtendedFlex>
    ) : (
      <>
        <ExtendedFlex
          flex="1 1 auto"
          align="center"
          w={1}
          borderTop="1px dashed"
          borderColor="gray.12"
          height={1}
          display={['flex', null, 'none']}
        />
        <ExtendedFlex
          flex="1 1 auto"
          align="center"
          w={1}
          borderTop="1px dashed"
          borderColor="gray.10"
          height={1}
          display={['none', null, 'flex']}
          mx={10}
        />
      </>
    )
);

Filler.propTypes = {
  active: PropTypes.bool,
};

export default Filler;
