import React from 'react';
import PropTypes from 'prop-types';
import {
  ExtendedFlex,
  Heading,
  Body,
  StyledButton,
} from '../../coreui';

import BrowseLink from '../../components/BrowseLink';

class InvestToday extends React.Component {
  // eslint-disable-next-line
  showZendesk() {
    // eslint-disable-next-line
    if (typeof zE !== 'undefined') {
      // eslint-disable-next-line
      zE.activate();
    }
  }

  render() {
    const {
      isLoggedIn,
      showPhone = false,
    } = this.props;

    return (
      <ExtendedFlex
        w={1}
        align="center"
        justify="center"
        flexDirection="column"
        py={[60, 80, 100, 120, 140]}
        px={[60, 80, 100, 120, 140]}
      >
        <Heading
          type="H2"
          color="black"
          m={0}
        >
          INVEST TODAY
        </Heading>
        <ExtendedFlex
          mt={[40, 40, 60, 60, 80]}
        >
          <BrowseLink
            isLoggedIn={isLoggedIn}
          />
        </ExtendedFlex>
        {
          showPhone &&
          <>
            <Body
              type="B1"
              m={0}
              pt={20}
            >
              Questions?
            </Body>
            <Heading
              m={0}
              type="H6"
            >
              (424) 320-4205
            </Heading>
            <StyledButton
              type="L4"
              mt={20}
              display="block"
              onClick={this.showZendesk}
            >
              Contact Investor Relations
            </StyledButton>
          </>
        }
      </ExtendedFlex>
    );
  }
}

InvestToday.propTypes = {
  isLoggedIn: PropTypes.bool,
  showPhone: PropTypes.bool,
};

export default InvestToday;
