import styled from 'styled-components';
import {
  width,
  height,
} from 'styled-system';

const Back = styled.div`
  ${width}
  ${height}
  transition: ${(props) => props.transition || '0.6s'};
  backface-visibility: ${(props) => props.backfaceVisibility || 'hidden'};
  transform-style: ${(props) => props.transformStyle || 'preserve-3d'};
  position: ${(props) => props.position || 'absolute'};
  top: ${(props) => props.top || 0};
  left: ${(props) => props.left || 0};
  z-index: ${(props) => props.zIndex || 1};
  transform: ${(props) => props.transform || 'rotateY(-180deg)'};
`;

export default Back;
