/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import StepCounterCore from '../../../components/StepCounter';
import { ExtendedFlex } from '../../../coreui';

const StepCounter = (props) => {
  return (
    <ExtendedFlex
      w={1}
      justify="center"
      my={[20, null, 50]}
    >
      <ExtendedFlex
        w={[1, null, 800]}
        flexDirection="column"
      >
        <StepCounterCore
          {...props}
        />
      </ExtendedFlex>
    </ExtendedFlex>
  );
};

StepCounter.propTypes = StepCounterCore.propTypes;

export default StepCounter;
