/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import MUIRadioGroup from '@material-ui/core/RadioGroup';
import { detect } from 'detect-browser';

import {
  Data,
} from '../../coreui';

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    const browser = detect();
    this.state = {
      valid: null,
      browser: browser.name === 'ie' ? 'ie' : 'notie',
    };

    this.handleOnValid = this.handleOnValid.bind(this);
    this.handleOnInvalid = this.handleOnInvalid.bind(this);
  }

  handleOnValid(evt) {
    evt.preventDefault();
    this.setState({
      valid: true,
    });
  }

  handleOnInvalid(evt) {
    evt.preventDefault();
    this.setState({
      valid: false,
    });
  }

  render() {
    const {
      children,
      inputProps,
      ...props
    } = this.props;

    let counter = 0;

    return (
      <>
        <MUIRadioGroup
          {...props}
        >
          {
            React.Children.map(children, (child) => {
              if (!React.isValidElement(child)) {
                return child;
              }

              const {
                type: {
                  displayName,
                },
                props: {
                  radioProps = {},
                  value = counter,
                },
              } = child;
              const {
                inputRef,
              } = radioProps;
              if (displayName === 'RadioLabel') {
                counter += 1;
                return React.cloneElement(child, {
                  key: value,
                  py: props.removePadding ? 0 : [15, null, 21, null, 28],
                  zebra: counter % 2 ? 'odd' : 'even',
                  inputProps,
                  disableZebra: props.disableZebra,
                  isSmall: props.isSmall,
                  radioProps: {
                    ...radioProps,
                    inputRef: (ref) => {
                      if (ref) {
                        if (this.state.browser === 'notie') {
                          ref.addEventListener('valid', this.handleOnValid);
                        }
                        ref.addEventListener('invalid', this.handleOnInvalid);
                      }
                      return inputRef ? inputRef(ref) : undefined;
                    },
                  },
                });
              }
              return child;
            })
          }
        </MUIRadioGroup>
        {
          this.state.valid === false &&
            <Data
              type="D4"
              display="block"
              my={2}
              color="red"
            >
              Please select a value.
            </Data>
        }
      </>
    );
  }
}

RadioGroup.propTypes = {
  children: PropTypes.array,
  inputProps: PropTypes.object,
};

export default RadioGroup;
