import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Image,
} from '../../..';

import collapseIcon from '../../../assets/icon_collapse.svg';
import expandIcon from '../../../assets/icon_expand.svg';

class DrawerHeading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.handleClick();
  }

  render() {
    const {
      isActive,
      content,
      isUppercase,
    } = this.props;

    return (
      <ExtendedFlex
        w={1}
        justify="flex-start"
        align="center"
        onClick={this.handleClick}
        px={['10%', 20]}
        hover
      >
        <ExtendedBox
          w={15}
          height={15}
          position="relative"
          transition="transform 0.3s linear"
          transform={isActive ? 'rotate(0deg)' : 'rotate(-90deg)'}
        >
          <Image
            position="absolute"
            top="0"
            left="0"
            w={15}
            src={collapseIcon}
            hover
          />
          <Image
            position="absolute"
            top="0"
            left="0"
            w={15}
            src={expandIcon}
            hover
            opacity={isActive ? '0' : '1'}
            transition="opacity 0.3s linear"
          />
        </ExtendedBox>
        <Heading
          color="black"
          type="H6"
          m={0}
          ml={10}
          textTransform={isUppercase ? 'uppercase' : 'none'}
        >
          {content}
        </Heading>
      </ExtendedFlex>
    );
  }
}

DrawerHeading.propTypes = {
  isActive: PropTypes.bool,
  content: PropTypes.string,
  handleClick: PropTypes.func,
  isUppercase: PropTypes.bool,
};

export default DrawerHeading;
