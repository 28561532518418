import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedBox,
  ExtendedFlex,
  Heading,
  Data,
} from '../../coreui';

import FadeIn from '../../components/FadeIn';
import FinancingCards from './financingCards';

class FinancingOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      header,
      subheader,
      data,
    } = this.props;
    return (
      <ExtendedBox>
        <ExtendedBox
          bg="gray.0"
          py={[100, 100, 100, 160, 220]}
          w={1}
        >
          <FadeIn>
            <Heading
              type="H3"
              display="block"
              textAlign="center"
              maxWidth={['75%', 540, 600, 840, 1160]}
              mx="auto"
              mb={[30, 40, 40, 40, 50]}
            >
              {header}
            </Heading>
            <Data
              type="D1"
              display="block"
              textAlign="center"
              mx="auto"
              maxWidth={['75%', 540, 600, 840, 1160]}
              m={0}
              mb={[30, 40, 40, 50, 60]}
            >
              {subheader}
            </Data>
          </FadeIn>
          <ExtendedFlex
            w={1}
            px={['5%', '5%', '5%', '15%', '10%']}
            flexDirection="row"
            bg="gray.0"
            wrap="wrap"
            justify="center"
            align="center"
          >
            {
              data &&
              data.map(
                (data) => {
                  return (
                    <FinancingCards
                      heading={data.heading}
                      range={data.range}
                      pricing={data.pricing}
                      leverage={data.leverage}
                      term={data.term}
                      timetoclose={data.timetoclose}
                      cashoncash={data.cashoncash}
                      value1={data.value1}
                      value2={data.value2}
                      value3={data.value3}
                      value4={data.value4}
                      value5={data.value5}
                      link={data.link}
                    />
                  );
                },
              )
            }
          </ExtendedFlex>
        </ExtendedBox>
      </ExtendedBox>
    );
  }
}

FinancingOptions.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.object,
  data: PropTypes.array,
};

export default FinancingOptions;
