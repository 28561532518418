import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Link,
  NavLink,
} from '../coreui';

// Makes a button link to either "browse" or registering
// Reason this exists is because internal React app links need `NavLink`, while
// external and Drupal facing links use `Link`.
// This is probably not a long-term solution, but it will do for now.
const DynamicBrowseJoinLink = ({
  isLoggedIn,
  accreditationRoleStatus,
  loggedInText,
  anonText,
  type,
  ...additionalProps
}) => {
  const isPreAccred = accreditationRoleStatus === 'Pre-Accredited';
  if (isLoggedIn) {
    return (
      <NavLink
        type={type}
        to={isPreAccred ? '/accreditation' : '/investment-opportunities'}
        // eslint-disable-next-line
        {...(isPreAccred
          ? {
            drupal: true,
          }
          : {})}
        // eslint-disable-next-line
        {...additionalProps}
      >
        {loggedInText}
      </NavLink>
    );
  } // else, since boolean return
  return (
    <Link
      type={type}
      to="/user/register"
      // eslint-disable-next-line
      {...additionalProps}
    >
      {anonText}
    </Link>
  );
};

DynamicBrowseJoinLink.propTypes = {
  isLoggedIn: PropTypes.bool,
  accreditationRoleStatus: PropTypes.string,
  type: PropTypes.string,
  loggedInText: PropTypes.string,
  anonText: PropTypes.string,
};

DynamicBrowseJoinLink.defaultProps = {
  type: 'L2',
  loggedInText: 'View Investments',
  anonText: 'Join Now',
};

const mapStateToProps = (state) => ({
  accreditationRoleStatus: state.app.user.accreditationRoleStatus,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DynamicBrowseJoinLink);
