import pick from 'lodash/pick';

import types from './types';
import { gtkyProps } from './data';

const initialState = {
  loaded: false,
  risk: [],
  holdPeriod: [],
  currentInvestments: [],
  propertyTypes: [],
  need1031: undefined,
  employmentType: 'employed',
  employer: null,
  employmentPositionHeld: null,
  employmentIndustry: null,
  sourceOfIncome: null,
  sourceOfWealth: null,
  netWorth: '',
  incomeDetermination: 'individual',
  currentIncome: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.USER_GETTING_TO_KNOW_YOU_DRAFT_SUCCEEDED: {
      return {
        ...state,
        ...pick(action.data.data[0], gtkyProps),
        loaded: true,
      };
    }
    case types.USER_ACCREDITATION_NEW_SUCCEEDED: {
      return {
        ...state,
        loaded: true,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
