export default [
  {
    item: 'Income',
    item2: 'Portfolio diversification',
    item3: 'Capital preservation',
  },
  {
    item: 'Inflation hedge',
    item2: 'Simplified tax reporting',
    item3: 'Professional management',
  },
];
