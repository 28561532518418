/* eslint-disable */
import React from 'react';
import numeral from 'numeral';
import {
  Schedule,
  Shield,
  Income,
  Diversified,
  TargetedMarket,
  Growth,
} from '../IconLibrary';

import MRIBackgroundImg from '../assets/mr1_background.jpg';
import MRIIBackgroundImg from '../assets/mr2_background.jpg';
import MRIProp from '../assets/mr1prop.png';
import MRIMarket from '../assets/mr1market.png';
import MRIIProp from '../assets/mr2prop.png';
import MRIIMarket from '../assets/mr2market.png';

if (!numeral.locales.en.abbreviations.million !== 'MM') {
  numeral.locales.en.abbreviations.million = 'MM';
};

const REIT_DICTIONARY = {
  193744: {
    reitTitle: 'The RealtyMogul Income REIT',
    shortTitle: 'The Income REIT',
    ordinal: 'first',
    shortName: 'mr1',
    reitType: 'income-focused',
    investLink: 'https://invest.realtymogul.com/reit-1/',
    oldInvestLink: '/invest/193744',
    descriptionBackground: MRIBackgroundImg,
    distributionAmount: '{mogulReit1DistributionAmount|$0.0 a}',
    tavTooltip: `Aggregate value of properties owned by The Income REIT, LLC based on the most recent internal valuations as of the end of the fiscal quarter upon which our most recently announced NAV per share is based pursuant to our valuation policies. The aggregate value of the properties underlying loans made by The Income REIT, LLC is based on independent appraisals dated within six months of the original acquisition dates by RM Adviser, Realty Mogul, Co. or Realty Mogul Commercial Capital, Co., as applicable.  As with any methodology used to estimate value, the methodology employed by our affiliates’ internal accountants or asset managers is based upon a number of estimates and assumptions about future events that may not be accurate or complete.  For more information, see the section of our Offering Circular captioned “Description of Our Common Shares – Valuation Policies.”`,
    annualReturn: '{mogulReit1AverageAnnualReturn|0.0%}',
    annualReturnYear: '{mogulReit1AverageAnnualReturnYear}',
    aarTooltip: `Average annual returns represent past performance which is no guarantee of future results. Investment returns and principal value will fluctuate so that when shares are redeemed, they may be worth more or less than their original cost. Current performance may be higher or lower than the performance shown.`,
    distributionDuration: '{mogulReit1DistributionDuration}',
    cdpTooltip: `Although The Income REIT has historically made monthly distributions, there is no guarantee that the REIT will make distributions, and if we do, we may fund such distributions from sources other than cash flow from operations, including, without limitation, the sale of assets, borrowings, return of capital or offering proceeds, and we have no limits on the amounts we may pay from such sources. `,
    overviewItems: [
      {
        title: 'Distribution History',
        icon: <Schedule />,
        description: 'Since inception, The Income REIT has distributed {mogulReit1DistributionDuration} of consecutive distributions to investors totaling approximately <span style="text-transform: lowercase">{mogulReit1DistributionAmount|$0. aw}</span>.',
      },
      {
        title: 'Diversified',
        icon: <Diversified />,
        description: 'Broad selection of investments across property types and geographies designed to reduce risk.',
      },
      {
        title: 'Passive Income',
        icon: <Income />,
        description: 'Cash flow from equity and debt investments in commercial real estate properties.',
      },
      {
        title: 'Trusted',
        icon: <Shield />,
        description: 'Regular audits conducted by Cohn Reznick.',
      },
    ],
    portfolioTabs: [
      {
        title: 'Property Type',
        type: 'MAP',
        img: MRIProp,
        items: [
          {
            swatchColor: '#176DB6',
            header: 'Multi-Family',
            copy: `Properties that have five or more residential units in a single building and may be further classified as garden style, low-rise, or high-rise`,
          },
          {
            swatchColor: '#548DBD',
            header: 'Office',
            copy: `Mid-rise or high-rise, downtown or suburban.`,
          },
          {
            swatchColor: '#A4C1D5',
            header: 'Retail',
            copy: `Grocery-anchored centers, shopping centers, power centers and strip malls.`,
          },
        ],
      },
      {
        title: 'Investment Type',
        type: 'MAP',
        img: MRIMarket,
        items: [
          {
            swatchColor: '#176DB6',
            header: 'Joint Venture Equity',
            copy: `Investors in Joint Venture Equity own an interest in an entity (usually an LLC) that invests in the equity portion of a property. After all debt is paid, and any Preferred Equity distributions are made, the Joint Venture Equity investor receives a pro rata portion of a preferred return, cash flow, and any profits upon sale. Joint Venture Equity is the riskiest investment as it has the lowest priority of distributions, although it has the greatest upside potential.`,
          },
          {
            swatchColor: '#548DBD',
            header: 'Mezzanine Debt',
            copy: `Second in line for repayment are investors in Mezzanine Debt and B Notes. Mezzanine Debt is structured as a loan secured by a pledge of interest in the entity owning the property. In the event of loan default, investors may have the right to foreclose on the interests of the entity and step into ownership of the property, subject to any senior debt. B Notes are secondary tranches of senior loans with an A/B structure, and are secured by the property itself. In the event of loan default, the investors in a B Note may participate in the right to foreclose on the property and receive sale proceeds to repay principal, unpaid interest and any fees, subject to the A Note investor.`,
          },
          {
            swatchColor: '#A4C1D5',
            header: 'Preferred Equity',
            copy: `Investors in Preferred Equity investments own an interest in the property and have a priority over the other equity investors to receive distributions of cash flow and capital invested. In the event of loan default, Preferred Equity investors may have the right to take over control and management of the property.`,
          },
        ],
      },
    ],
    overviewBackground: '#F2F2F2',
    resourcesBackground: '#F2F2F2',
    performanceBackground: '#FFF',
    portfolioBreakdownBackground: '#F2F2F2',
    portfolioBackground: '#FFF',
    riskFactorsBackground: '#F2F2F2',
  },
  274949: {
    reitTitle: 'Apartment Growth REIT (MogulREIT II)',
    shortTitle: 'The Apartment Growth REIT',
    ordinal: 'second',
    shortName: 'mr2',
    reitType: 'growth-focused',
    investLink: 'https://invest.realtymogul.com/reit-2/',
    oldInvestLink: '/invest/274949',
    descriptionBackground: MRIIBackgroundImg,
    distributionAmount: '{mogulReit2DistributionAmount|$0.0 a}',
    tavTooltip: `Aggregate value of all underlying properties in The RealtyMogul Apartment Growth REIT, Inc. based on the most recent internal valuations as of the end of the fiscal quarter upon which our most recently announced NAV per share is based pursuant to our valuation policies. As with any methodology used to estimate value, the methodology employed by our affiliates’ internal accountants or asset managers is based upon a number of estimates and assumptions about future events that may not be accurate or complete.  For more information, see the “Description of Our Common Stock – Valuation Policies” section of our offering circular.`,
    annualReturn: '{mogulReit2NetAssetValuePerShare|$0.00}',
    annualReturnYear: '{mogulReit2AverageAnnualReturnYear}',
    distributionDuration: '{mogulReit2DistributionDuration}',
    cdpTooltip: `Although The RealtyMogul Apartment Growth REIT has historically made quarterly distributions, there is no guarantee that the REIT will make distributions, and if we do, we may fund such distributions from sources other than cash flow from operations, including, without limitation, the sale of assets, borrowings, return of capital or offering proceeds, and we have no limits on the amounts we may pay from such sources. `,
    overviewDescription: ['Since inception, The RealtyMogul Apartment Growth REIT has made quarterly distributions equating to 4.5% annualized based on purchase price.',
    'The Apartment Growth REIT is a non-traded REIT that invests in apartment buildings located in resilient markets that can offer current income and solid growth potential.  The REIT’s primary objective is to realize capital appreciation in the value of its investments over the long term through the renovation and repositioning of the multifamily properties as well as to pay attractive and stable cash distributions to stockholders.'],
    overviewItems: [
      {
        title: 'Capital Appreciation',
        icon: <Growth fill="#176DB6" />,
        description: 'Value creation through property exterior and unit improvements in order to increase rental rates and ultimate resale value.',
      },
      {
        title: 'Targeted Markets',
        icon: <TargetedMarket fill="#176DB6" />,
        description: 'Focus on regions with strong economic fundamentals and apartment communities that have demonstrated consistently high occupancy and income levels across market cycles.',
      },
      {
        title: 'Potential Income',
        icon: <Income />,
        description: 'Source of cash flow derived from tenants paying rent and additional potential income stemming from enhancements to the property, such as the addition of a laundry facility.',
      },
      {
        title: 'Trusted',
        icon: <Shield />,
        description: 'Regular audits conducted by Cohn Reznick.',
      },
    ],
    portfolioTabs: [
      {
        title: 'Investment Type',
        type: 'MAP',
        img: MRIIMarket,
        items: [
          {
            swatchColor: '#176DB6',
            header: 'Primary',
            copy: `Locations where the broader MSAs have a population of 5 million or greater.`,
          },
          {
            swatchColor: '#548DBD',
            header: 'Secondary',
            copy: `Locations where the broader MSAs have a population between 2 – 5 million.`,
          },
          {
            swatchColor: '#A4C1D5',
            header: 'Tertiary',
            copy: `Locations where the broader MSAs have a population less than 2 million.`,
          },
        ],
      },
      {
        title: 'Property Type',
        type: 'MAP',
        img: MRIIProp,
        items: [
          {
            swatchColor: '#176DB6',
            header: 'Multi-Family',
            copy: `The RealtyMogul Apartment Growth REIT targets apartment communities that have demonstrated consistently high occupancy and income levels across market cycles as well as multifamily properties that offer value added opportunities with appropriate risk-adjusted returns and opportunity for value appreciation.`,
          },
        ],
      },
    ],
    overviewBackground: '#F2F2F2',
    resourcesBackground: '#F2F2F2',
    performanceBackground: '#FFF',
    portfolioBreakdownBackground: '#F2F2F2',
    portfolioBackground: '#FFF',
    riskFactorsBackground: '#F2F2F2',
  },
};

const getValue = (id, key) => {
  return REIT_DICTIONARY[id][key] || `Key: ${key} not found`;
}

export const createData = ({
  io, faqItems, highlights, historical, details
}) => {
  let reitData = {};
  reitData.description = {
    title: io.label,
    subTitle: null,
    description: io.id === 193744 ? ['For {mogulReit1DistributionDuration} consecutively, our Income REIT has been doing exactly that by paying investors an annualized cash distribution of between 6-8% (net of fees).<sup>1</sup>'] : [`Join the ${numeral(highlights.numInvestors).format('0,0')} investors that have earned passive income from RealtyMogul’s ${getValue(io.id, 'reitType')} Real Estate Investment Trust “REIT”.`],
    items: [
      {
        value: numeral(highlights.investedValue).format('$0,0 a'),
        label: 'Total Asset Value',
        tooltip: getValue(io.id, 'tavTooltip')
      },
      {
        value: getValue(io.id, 'distributionAmount'),
        label: 'Distributed to Investors',
      },
      {
        value: io.id === 193744 ? '{mogulReit1LastTwelveMonthsReturn}%' : '{mogulReit2LastTwelveMonthsReturn}%',
        label: `LTM Return as of ${io.id === 193744 ? '{mogulReit1LastTwelveMonthsReturnDate}' : '{mogulReit2LastTwelveMonthsReturnDate}'}`,
        tooltip: 'Last Twelve Months (“LTM”) returns represent the most recent consecutive twelve-month period immediately preceding such date.  Past performance is not indicative of current and future results.',
      },
      {
        value: getValue(io.id, 'distributionDuration'),
        label: 'Consecutive Distribution Periods',
        tooltip: getValue(io.id, 'cdpTooltip')
      },
    ],
    background: getValue(io.id, 'descriptionBackground'),
    startInvestment: {
      title: 'Start Your Investment Now',
      items: details,
      investLink: getValue(io.id, 'investLink'),
      oldInvestLink: io.smartButton.button_href,
      buttonLabel: 'GET STARTED',
      loggedInButtonLabel: io.smartButton.button_text,
    },
  };
  reitData.overview = {
    title: io.id === 193744 ? 'Over {mogulReit1DistributionAmount|$0.0 a} distributed to investors to date': 'Overview',
    background: getValue(io.id, 'overviewBackground'),
    description: io.id === 193744 ? ['The RealtyMogul Income REIT (Real Estate Investment Trust) is a non-traded REIT making equity and debt investments in commercial real estate properties diversified by investment type, geography and property type.',
    'The REIT\'s primary focus is to <b>provide monthly income to investors</b> by rigorously evaluating numerous investment opportunities to find those that can support the REIT\'s distribution target.',
    'Anyone can invest in RealtyMogul’s Income REIT with a minimum investment of just $5,000, regardless of income level or net worth.',
    'The Income REIT has:',
    `<li>Paid investors an annualized cash distribution of between 6-8% (net of fees) for {mogulReit1DistributionDuration}</li>
    <li>Distributed a total of {mogulReit1DistributionAmount|$0.0 a} to investors to date.</li>
    <li>Delivered a {mogulReit1LastTwelveMonthsReturn}% total return for the last twelve months.<sup>2</sup></li>
    <li>${numeral(io.reitAssetValue).format('$0,0 A')} in assets purchased by approximately ${numeral(io.numInvestors).format('0,0')} unique investors.<sup>3</sup></li>`] : getValue(io.id, 'overviewDescription'),
    items: getValue(io.id, 'overviewItems'),
    cta: {
      label: `${getValue(io.id, 'shortTitle')} FACTSHEET`,
      href: `/materials/${getValue(io.id, 'shortName')}/brochure.pdf`,
      type: 'Secondary',
    },
  };
  reitData.resources = {
    title: 'Resources',
    background: getValue(io.id, 'resourcesBackground'),
    tabs: [
      {
        title: 'Offering Documents',
        type: 'PDF',
        items: io.investmentDocuments.map((item) => {return {
          label: item.title,
          link: item.url,
        }}),
      },
      {
        title: 'Reports',
        type: 'PDF',
        items: io.investmentDocumentsForms.map((item) => {return {
          label: item.title,
          link: item.url,
        }}),
      },
      {
        title: 'Other',
        type: 'PDF',
        items: io.investmentDocumentsOther.map((item) => {return {
          label: item.title,
          link: item.url,
        }}),
      },
      {
        title: 'Management Team',
        type: 'TEAM',
        content: faqItems.find(({header}) => header.toUpperCase() === 'MANAGEMENT').content,
      },
      {
        title: 'FAQ',
        type: 'FAQ',
        items: faqItems.filter(({header}) => header.toUpperCase() !== 'MANAGEMENT' && header.toUpperCase() !== 'RISK FACTORS'),
      },
    ],
  };
  reitData.historical = {
    title: 'Historical Performance',
    background: getValue(io.id, 'performanceBackground'),
    graph: {
      title: 'Cumulative Distributions',
      image: io.cumulativeDistributionGraph,
    },
    distributions: {
      title: 'Consecutive Distribution Periods',
      value: getValue(io.id, 'distributionDuration'),
    },
    items: [
      {
        title: 'Purchase Price',
        value: '$' + historical.purchasePrice.substring(0, 5),
        label: 'Per share as of<br />' + new Date(historical.purchasePriceDate + 'T00:00').toLocaleDateString(window.navigator.language, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
      },
      {
        title: 'Net Asset Value (NAV)',
        value: '$' + historical.navPrice.substring(0, 5),
        label: 'Per share as of<br />' + new Date(historical.navPriceDate + 'T00:00').toLocaleDateString(window.navigator.language, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
      },
    ],
    cta: {
      label: 'DISTRIBUTION HISTORY',
      href: `/materials/${getValue(io.id, 'shortName')}/distribution_history.pdf`,
      type: 'Secondary',
    },
  };
  reitData.portfolioBreakdown = {
    title: 'Portfolio Breakdown',
    background: getValue(io.id, 'portfolioBreakdownBackground'),
    tabs: getValue(io.id, 'portfolioTabs'),
    cta: {
      label: 'PORTFOLIO SNAPSHOT',
      href: `/materials/${getValue(io.id, 'shortName')}/portfolio_snapshot.pdf`,
      type: 'Secondary',
      target: '_blank',
    },
  };
  reitData.portfolio = {
    title: 'Portfolio',
    background: getValue(io.id, 'portfolioBackground'),
  };
  reitData.riskFactors = {
    title: 'Disclaimers and Risk Factors',
    background: getValue(io.id, 'riskFactorsBackground'),
    content: faqItems.find(({header}) => header.toUpperCase() === 'RISK FACTORS').content,
  };
  
  return reitData;
}