import React from 'react';

const Profile = () => {
  /* eslint-disable */
  return (
    <g stroke="none" transform="translate(15.000000, 5.000000)" fillRule="nonzero" strokeWidth="0.7">
      <path d="M3.8917609,40 L29.1082391,40 C31.2407108,40 33,38.2046632 33,36.0284974 L33,27.4870466 C33,25.9093264 32.0403877,24.4404145 30.6009693,23.8419689 L22.0177706,20.142487 C18.4458805,18.619171 14.3941842,18.619171 10.8756058,20.142487 L2.29240711,23.8419689 C0.959612278,24.4404145 0,25.8549223 0,27.4870466 L0,36.0284974 C0,38.2046632 1.75928918,40 3.8917609,40 Z M2.13247173,27.4870466 C2.13247173,26.7797927 2.55896607,26.126943 3.19870759,25.8005181 L11.7819063,22.1010363 C13.2746365,21.4481865 14.9273021,21.1217617 16.5266559,21.1217617 C18.1260097,21.1217617 19.7786753,21.4481865 21.2714055,22.1010363 L29.8546042,25.8005181 C30.4943457,26.0725389 30.9208401,26.7253886 30.9208401,27.4870466 L30.9208401,36.0284974 C30.9208401,37.007772 30.1211632,37.8238342 29.1615509,37.8238342 L3.8917609,37.8238342 C2.93214863,37.8238342 2.13247173,37.007772 2.13247173,36.0284974 L2.13247173,27.4870466 Z" id="Shape"></path>
      <path d="M15.1,0 C11.75,0 9,3.01612903 9,6.69032258 L9,10.3096774 C9,13.983871 11.75,17 15.1,17 L15.9,17 C19.25,17 22,13.983871 22,10.3096774 L22,6.69032258 C22,3.01612903 19.25,0 15.9,0 L15.1,0 Z M20,6.69032258 L20,10.3096774 C20,12.7774194 18.15,14.8064516 15.9,14.8064516 L15.1,14.8064516 C12.85,14.8064516 11,12.7774194 11,10.3096774 L11,6.69032258 C11,4.22258065 12.85,2.19354839 15.1,2.19354839 L15.9,2.19354839 C18.15,2.19354839 20,4.22258065 20,6.69032258 Z" id="Shape"></path>
    </g>
  );
  /* eslint-enable */
};

export default Profile;
