import React from 'react';
import PropTypes from 'prop-types';

import {
  Body,
  Heading,
  Link,
  ExtendedBox,
  isDrupal,
} from '../coreui';

const FourOhFour = ({
  linkPath,
  linkText,
  title,
  description,
}) => {
  return (
    <ExtendedBox
      maxWidth={1440}
      maxHeight={674}
      height={['32vh', '50vh']}
      px="10%"
      m="auto"
      mt={[25, 70, 90, 110]}
      pb={[25, 70, 90, 150]}
    >
      <Heading
        type="H2"
        color="blue.1"
        textTransform="uppercase"
        display="block"
        textAlign="left"
      >
        {title}
      </Heading>
      <Body type="B1">
        {description}
      </Body>
      <ExtendedBox
        mt={80}
        mb={80}
      >
        <Link
          {...{
            [isDrupal() ? 'href' : 'to']: linkPath,
          }}
          type="L1"
          textTransform="uppercase"
          display="block"
        >
          {linkText}
        </Link>
      </ExtendedBox>
    </ExtendedBox>
  );
};

FourOhFour.propTypes = {
  linkText: PropTypes.string,
  linkPath: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
};

FourOhFour.defaultProps = {
  linkText: 'RealtyMogul Home',
  linkPath: '/',
  description: 'The page you are looking for may have been moved or deleted.',
  title: '404: Page Not Found.',
};

export default FourOhFour;
