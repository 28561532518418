import adminMenuTypes from './types';

export default (state = {}, action) => {
  switch (action.type) {
    case adminMenuTypes.GET_ADMIN_MENU_SUCCEEDED: {
      return {
        ...state,
        content: action.content.data.content,
        css: action.content.data.css,
        js: action.content.data.js,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
