import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StatBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: calc(50% - 1px);
  text-align: center;
`;

const Value = styled.div`
  font-size: 36px;
  font-family: var(--font-serif);
  color: var(--color-text-blue);
  @media (min-width: 400px) {
    font-size: 48px;
  }
  @media (min-width: 1200px) {
    font-size: 58px;
  }
`;

const Label = styled.div`
  font-family: var(--font-sans);
  font-size: 12px;
  font-weight: 600;
  color: #FFF;
  margin-top: 10px;
`;

const StatBox = ({
  value,
  label,
  prefix,
}) => (
  <StatBoxContainer>
    <Value>{prefix}{value}</Value>
    <Label>{label}</Label>
  </StatBoxContainer>
);

StatBox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.string,
};

export default StatBox;
