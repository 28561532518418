import styled from 'styled-components';

export default {
  ReitLanding: styled.div`
    width: 100%;
  `,
  Container: styled.div`
    max-width: 1024px;
    margin: 0 auto;
    h1 {
      margin: 0 0 15px 0;
      font-weight: 800;
      font-size: 28px;
      line-height: 32px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      h1 {
        font-size: 44px;
        line-height: 56px;
      }
    }
  `,
  DisclaimerCopy: styled.div`
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-style: italic;
    line-height: 24px;
  `,
};
