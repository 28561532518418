import {
  takeLatest,
  put,
  call,
} from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';
import { getAdminMenuContent } from '../../api';

export function* getAdminDataFlow() {
  try {
    const response = yield call(getAdminMenuContent);
    yield put(
      actions.getAdminMenuContentSucceeded(response.data),
    );
  } catch (error) {
    yield put(
      actions.getAdminMenuContentFailed(error),
    );
  }
}

export function* watchAdminContentRequested() {
  yield takeLatest(
    types.GET_ADMIN_MENU_REQUESTED,
    getAdminDataFlow,
  );
}

export default watchAdminContentRequested;
