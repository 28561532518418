import { takeLatest, call, put } from 'redux-saga/effects';

import types from '../types';
import actions from '../actions';
import { getShareRepurchaseList } from '../../api';

export function* getDataFlow() {
  try {
    const response = yield call(getShareRepurchaseList);
    yield put(actions.shareRepurchaseListSucceeded(response.data));
  } catch (error) {
    yield put(actions.shareRepurchaseListFailed(error));
  }
}

export function* watchShareRepurchaseListRequested() {
  yield takeLatest(types.SHARE_REPURCHASE_LIST_API_REQUESTED, getDataFlow);
}

export default watchShareRepurchaseListRequested;
