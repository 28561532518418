import React from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';

import {
  ExtendedFlex,
  Heading,
  Body,
} from '../../coreui';

import Form from '../../components/forms/Form';
import Label from '../../components/forms/Label';
import Input from '../../components/forms/Input';
import Actions from '../../components/forms/Actions';
import PrimaryButton from '../../components/forms/PrimaryButton';
import SecondaryButton from '../../components/forms/SecondaryButton';

class VerifyEmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      email: undefined,
      code: '',
    };

    this.emailInput = null;
    this.setEmailInputRef = (element) => {
      this.emailInput = element;
    };

    this.updateState = this.updateState.bind(this);
    this.updateSubmittingStatus = this.updateSubmittingStatus.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleResendCode = this.handleResendCode.bind(this);
    this.handleEmailValidation = this.handleEmailValidation.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      email: state.email === undefined ? props.email : state.email,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.editingEmail !== this.state.editingEmail && this.state.editingEmail) {
      if (window && window.hyperform) {
        window.hyperform.addValidator(this.emailInput, this.handleEmailValidation);
      }
    }
  }

  handleEmailValidation(element) {
    const {
      email,
    } = this.state;
    const valid = email !== this.props.email;

    element.setCustomValidity(valid ? '' : 'Please enter a new email address.');

    return valid;
  }

  updateState(...args) {
    this.setState(...args);
  }

  updateSubmittingStatus(submitting) {
    this.setState({
      submitting,
    });
  }

  handleResendCode() {
    Auth.verifyCurrentUserAttribute('email')
      .then(() => {
        this.props.handleStatusMessage('Code resent to your email. Please check your spam/junk folder.');
      })
      .catch((err) => {
        this.props.handleErrorMessage(err);
      })
      .finally(() => {
        this.updateState({
          submitting: false,
        });
      });
  }

  handleFormSubmit(e, callback) {
    e.preventDefault();
    if (!this.state.submitting) {
      this.updateSubmittingStatus(true);
      callback();
    }
  }

  handleConfirm() {
    const {
      code,
    } = this.state;

    Auth.verifyCurrentUserAttributeSubmit('email', code)
      .then(this.props.handleEmailVerified)
      .catch((err) => {
        this.props.handleErrorMessage(err);
        this.updateState({
          submitting: false,
        });
      });
  }

  render() {
    const {
      email,
      code,
      editingEmail,
      submitting,
    } = this.state;
    return (
      <>
        <ExtendedFlex
          flexDirection="column"
        >
          <Heading
            type="H6"
            textTransform="uppercase"
            mb={0}
          >
            Email Verification
          </Heading>
          <Body
            type="B2"
          >
            A verification code has been sent to your new email address. If not received,
            please confirm your email below is correct and check your spam folder.
          </Body>
          <ExtendedFlex>
            <Body
              type="B2"
            >
              Didn&apos;t receive your code?
            </Body>
            <SecondaryButton
              onClick={this.handleResendCode}
              data-test="resend-code"
            >
              Re-send verification code
            </SecondaryButton>
          </ExtendedFlex>
        </ExtendedFlex>
        <Form
          onSubmit={(e) => this.handleFormSubmit(
            e,
            !editingEmail
              ? this.handleConfirm
              : () => this.props.handleChangeEmail(this.state.email)
                .then(() => {
                  this.updateState((prevState) => ({
                    editingEmail: !prevState.editingEmail,
                  }));
                  this.props.handleClearMessages();
                })
                .catch((err) => {
                  this.props.handleErrorMessage(err);
                })
                .finally(() => {
                  this.updateState({
                    submitting: false,
                  });
                }),
          )}
        >
          {
            !editingEmail &&
            <Label
              htmlFor="email"
            >
              Email
              <Input
                name="email"
                disabled
                type="email"
                required
                value={this.state.email}
                data-test="verify-email"
              />
            </Label>
          }
          {
            editingEmail &&
            <Label
              htmlFor="email"
            >
              Email
              <Input
                forwardedRef={this.setEmailInputRef}
                placeholder="E-mail"
                name="email"
                disabled={email && !editingEmail}
                type="email"
                required
                value={this.state.email}
                onChange={(e) => {
                  this.updateState({
                    email: (e.target.value || '').toLowerCase(),
                  });
                }}
                data-test="update-email"
              />
            </Label>
          }
          {
            !editingEmail &&
            <Label
              htmlFor="code"
            >
              Code
              <Input
                id="code"
                name="code"
                autoComplete="off"
                placeholder="Enter Code"
                required
                value={code}
                onChange={(e) => {
                  const value = e.target.value
                    // Replace non-digits.
                    .replace(/[^\S]/g, '');
                  this.updateState({
                    code: value,
                  });
                }}
                pattern="^[\S]+$"
                data-test="verification-code"
              />
            </Label>
          }
          <Actions>
            <PrimaryButton
              loading={submitting}
              disable={submitting}
              buttonType="submit"
            >
              {
                editingEmail
                  ? 'Send new code'
                  : 'Save Changes'
              }
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                this.props.handleClearMessages();
                this.updateState((prevState) => ({
                  editingEmail: !prevState.editingEmail,
                  email: prevState.editingEmail
                    ? this.props.email
                    : prevState.email,
                }));
              }}
              disabled={submitting}
            >
              {
                editingEmail
                  ? 'Cancel'
                  : 'Edit your email address'
              }
            </SecondaryButton>
          </Actions>
        </Form>
      </>
    );
  }
}

VerifyEmailForm.propTypes = {
  email: PropTypes.string,
  handleClearMessages: PropTypes.func,
  handleChangeEmail: PropTypes.func,
  handleEmailVerified: PropTypes.func,
  handleStatusMessage: PropTypes.func,
  handleErrorMessage: PropTypes.func,
};

export default VerifyEmailForm;
