import { Auth } from 'aws-amplify';

let csrfToken;
let csrfResolve;

export const isMockSession = () => {
  return window && window.MOCK_SESSION;
};

export const getIdToken = async () => {
  if (isMockSession()) {
    return '';
  }
  const session = await Auth.currentSession();
  return session.getIdToken().jwtToken;
};

export const getCsrfToken = async () => {
  if (isMockSession()) {
    return '';
  }

  if (csrfToken) {
    return csrfToken;
  }

  return new Promise((res) => {
    csrfResolve = res;
  });
};

export const setCsrfToken = (token) => {
  csrfToken = token;
  if (csrfToken && csrfResolve) {
    csrfResolve(csrfToken);
  }
};

export const getCurrentSession = async () => {
  if (isMockSession()) {
    return {
      idToken: {
        jwtToken: '',
        payload: {
          /* eslint-disable camelcase */
          given_name: 'Justin',
          family_name: 'Hughes',
          email_verified: true,
          /* eslint-enable camelcase */
        },
      },
    };
  }
  return Auth.currentSession();
};

export default {
  isMockSession,
  getIdToken,
  getCurrentSession,
};
