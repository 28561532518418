import types from './types';

export default {
  getFaqDataRequested: () => ({
    type: types.GET_FAQ_DATA_REQUESTED,
  }),
  getFaqDataSucceeded: (data) => ({
    type: types.GET_FAQ_DATA_SUCCEEDED,
    data,
  }),
  getFaqDataFailed: (error) => ({
    type: types.GET_FAQ_DATA_FAILED,
    error,
  }),
};
