import types from './types';

import autoinvestActions from './autoinvest/actions';

export default {
  ...autoinvestActions,
  ownershipDataRequested: () => ({
    type: types.OWNERSHIP_API_REQUESTED,
  }),
  ownershipDataSucceeded: (data) => ({
    data,
    type: types.OWNERSHIP_API_SUCCEEDED,
  }),
  ownershipFilterSet: (foeObject) => ({
    foeObject,
    type: types.OWNERSHIP_FILTER_SET,
  }),
  dashboardApiActionRequested: (foeId) => ({
    type: types.DASHBOARD_API_REQUESTED,
    foeId,
  }),
  dashboardApiActionSucceeded: (data) => ({
    data,
    type: types.DASHBOARD_API_SUCCEEDED,
  }),
  dashboardApiActionFailed: () => ({
    type: types.DASHBOARD_API_FAILED,
  }),
  cancelInvestmentRequested: (id, callback) => ({
    type: types.CANCEL_INVESTMENT_API_REQUESTED,
    id,
    callback,
  }),
  cancelInvestmentSucceeded: (id, data) => ({
    id,
    data,
    type: types.CANCEL_INVESTMENT_API_SUCCEEDED,
  }),
  cancelInvestmentFailed: (id, error) => ({
    id,
    error,
    type: types.CANCEL_INVESTMENT_API_FAILED,
  }),
  pendingDashboardDataRequested: () => ({
    type: types.PENDING_DASHBOARD_API_REQUESTED,
  }),
  pendingDashboardDataSucceeded: (data) => ({
    data,
    type: types.PENDING_DASHBOARD_API_SUCCEEDED,
  }),
  pendingDashboardDataFailed: (error) => ({
    error,
    type: types.PENDING_DASHBOARD_API_FAILED,
  }),
  pendingDashboardWaitlistFlagRequested: (id, flagged, callback) => ({
    type: types.PENDING_DASHBOARD_WAITLIST_FLAG_REQUESTED,
    id,
    flagged,
    callback,
  }),
  pendingDashboardWaitlistFlagSucceeded: (id, flagged, data) => ({
    type: types.PENDING_DASHBOARD_WAITLIST_FLAG_SUCCEEDED,
    id,
    flagged,
    data,
  }),
  pendingDashboardWaitlistFlagFailed: (error) => ({
    type: types.PENDING_DASHBOARD_WAITLIST_FLAG_FAILED,
    error,
  }),
  pendingDashboardWatchlistFlagRequested: (id, flagged, callback) => ({
    type: types.PENDING_DASHBOARD_WATCHLIST_FLAG_REQUESTED,
    id,
    flagged,
    callback,
  }),
  pendingDashboardWatchlistFlagSucceeded: (id, flagged, data) => ({
    type: types.PENDING_DASHBOARD_WATCHLIST_FLAG_SUCCEEDED,
    id,
    flagged,
    data,
  }),
  pendingDashboardWatchlistFlagFailed: (error) => ({
    type: types.PENDING_DASHBOARD_WATCHLIST_FLAG_FAILED,
    error,
  }),
  pendingDashboardModalOpen: (content) => ({
    content,
    type: types.PENDING_DASHBOARD_MODAL_OPEN,
  }),
  pendingDashboardModalClose: () => ({
    type: types.PENDING_DASHBOARD_MODAL_CLOSE,
  }),
  notificationApiActionRequested: () => ({
    type: types.NOTIFICATION_API_REQUESTED,
  }),
  notificationApiActionSucceeded: (data) => ({
    data,
    type: types.NOTIFICATION_API_SUCCEEDED,
  }),
  notificationApiActionFailed: () => ({
    type: types.NOTIFICATION_API_FAILED,
  }),
  dripToggleApiActionRequested: ({ id, callback }) => ({
    type: types.DRIP_TOGGLE_REQUESTED,
    id,
    callback,
  }),
  dripToggleApiActionSucceeded: (data) => ({
    type: types.DRIP_TOGGLE_SUCCEEDED,
    data,
  }),
  dripToggleApiActionFailed: (error) => ({
    type: types.DRIP_TOGGLE_FAILED,
    error,
  }),
  taxcenterApplyFilters: () => ({
    type: types.TAXCENTER_APPLY_FILTERS,
  }),
  taxcenterUpdateFilter: (name, value) => ({
    name,
    value,
    type: types.TAXCENTER_UPDATE_FILTER,
  }),
  taxcenterUpdateFilterModal: (name, value) => ({
    name,
    value,
    type: types.TAXCENTER_UPDATE_FILTER_MODAL,
  }),
  taxCenterFilterRequested: (name, value) => ({
    name,
    value,
    type: types.TAXCENTER_FILTER_REQUESTED,
  }),
  taxcenterApiActionRequested: () => ({
    type: types.TAXCENTER_API_REQUESTED,
  }),
  taxcenterApiActionSucceeded: (data) => ({
    data,
    type: types.TAXCENTER_API_SUCCEEDED,
  }),
  taxcenterApiActionFailed: () => ({
    type: types.TAXCENTER_API_FAILED,
  }),
  documentSelected: (id) => ({
    data: id,
    type: types.TAXCENTER_DOCUMENT_SELECTED,
  }),
  documentToggleAll: () => ({
    type: types.TAXCENTER_DOCUMENT_TOGGLE_ALL,
  }),
  taxcenterResetFilters: () => ({
    type: types.TAXCENTER_RESET_FILTERS,
  }),
  taxcenterResetRequested: () => ({
    type: types.TAXCENTER_RESET_REQUESTED,
  }),
  taxCenterDownloadsRequested: () => ({
    type: types.TAXCENTER_DOWNLOADS_REQUESTED,
  }),
};
