import types from './types';
import appWrapperTypes from '../appWrapper/types';

const initialState = {
  items: [],
  itemTypes: [],
  itemTopics: [],
  itemsVisible: 12,
  itemsPerPage: 12,
  nextUrl: '',
  currentPage: 1,
  selectedCategory: 'all',
  selectedType: 'all',
  waitingForPayload: false,
  user: {
    isLoggedIn: false,
  },
};

const getItemTopics = (items) => {
  return items.reduce(
    (accum, item) => {
      const accumValues = accum.map(
        (obj) => {
          return obj.value;
        },
      );
      if (!accumValues.includes(item.machine_name) &&
          item.machine_name && item.machine_name !== '1031_exchange') {
        return [
          ...accum,
          {
            label: item.label,
            value: item.machine_name,
          },
        ];
      }
      return accum;
    }, [
      {
        label: 'All Topics',
        value: 'all',
      },
    ],
  );
};

const getItemTypes = (items) => {
  return items.reduce(
    (accum, item) => {
      const accumValues = accum.map(
        (obj) => {
          return obj.value;
        },
      );
      if (!accumValues.includes(item.type)) {
        return [
          ...accum,
          {
            label: item.label,
            value: item.type,
          },
        ];
      }
      return accum;
    }, [
      {
        value: 'all',
        label: 'All',
      },
    ],
  );
};

export default (state = initialState, action) => {
  switch (action.type) {
    case appWrapperTypes.APP_INIT:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data.user,
        },
      };

    case appWrapperTypes.GET_USER_DATA_SUCCEEDED:
      return {
        ...state,
        user: action.user,
      };

    case types.UPDATE_KNOWLEDGE_CENTER_FILTER_SUCCEEDED: {
      const items = action.data.data;
      // eslint-disable-next-line
      const itemTypes = getItemTypes(action.data._types);
      // eslint-disable-next-line
      const itemTopics = getItemTopics(action.data._categories);

      return {
        ...state,
        items,
        itemTypes,
        itemTopics,
        // eslint-disable-next-line
        nextUrl: action.data._links.next,
      };
    }

    case types.GET_ARTICLE_QUEUE_SUCCEEDED: {
      return {
        ...state,
        items: action.data.items,
      };
    }

    case types.GET_MORE_ARTICLES_REQUESTED: {
      const waitingForPayload = true;
      return {
        ...state,
        waitingForPayload,
      };
    }

    case types.GET_MORE_ARTICLES_FAILED: {
      const waitingForPayload = false;
      return {
        ...state,
        waitingForPayload,
      };
    }

    case types.GET_MORE_ARTICLES_SUCCEEDED: {
      const items = action.data.data;
      // eslint-disable-next-line
      const itemTypes = getItemTypes(action.data._types);
      // eslint-disable-next-line
      const itemTopics = getItemTopics(action.data._categories);
      const waitingForPayload = false;

      return {
        ...state,
        items: [
          ...state.items,
          ...items,
        ],
        itemTypes,
        itemTopics,
        waitingForPayload,
        // eslint-disable-next-line
        nextUrl: action.data._links.next,
      };
    }

    case types.UPDATE_KNOWLEDGE_CENTER_FILTER_REQUESTED: {
      const selectedCategory = action.category;
      return {
        ...state,
        selectedCategory,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
