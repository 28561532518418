import React from 'react';
import PropTypes from 'prop-types';

import {
  Image,
  ExtendedBox,
  NavLink,
} from '../../..';

class ArticleCardImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      imageUrl,
      url,
    } = this.props;

    return (
      <ExtendedBox
        w={1}
        height={[165, 165, 220, 220, 265]}
        overflowX="hidden"
        overflowY="hidden"
      >
        <NavLink
          data-test="article-image-link"
          type="L4"
          to={url}
          withActiveStyle={false}
        >
          <Image
            w={1}
            src={imageUrl}
            hoverColorize
            hover
            transition="0.2s linear filter"
          />
        </NavLink>
      </ExtendedBox>
    );
  }
}

ArticleCardImage.propTypes = {
  imageUrl: PropTypes.string,
  url: PropTypes.string,
};

ArticleCardImage.defaultProps = {
  imageUrl: '',
  url: '/',
};

export default ArticleCardImage;
