/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ExtendedFlex,
  ExtendedBox,
  Image,
  StyledButton,
  Heading,
  Link,
} from '../../coreui';

import webinarIcon from '../../assets/icon_webinar.svg';
import newWebinarIcon from '../../assets/Webinar.svg';
import WebinarModal from '../../privatePlacement/components/WebinarModal';

class Webinar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState((state) => ({
      isModalVisible: !state.isModalVisible,
    }));
  }

  render() {
    const {
      Video,
      RegistrationUrl,
      newStyle = false,
      ...passedProps
    } = this.props;

    const {
      isModalVisible,
    } = this.state;

    return (
      <ExtendedFlex
        flexBasis="100%"
        bg="gray.1"
        p={15}
        textAlign="center"
        my={[5, 5, 30]}
        mx={10}
        name="webinar"
        flexDirection={newStyle ? 'column' : 'initial'}
        {...passedProps}
      >
        {newStyle &&
          <Heading
            type="H8"
            display="block"
            textAlign="center"
            alignSelf="center"
            my="0"
            fontSize="13px"
            letterSpacing="1.56px"
            color="#202020"
            fontWeight="500"
          >
            PROJECT WEBINAR
          </Heading>}
        <ExtendedBox
          textAlign="center"
        >
          <Image
            src={newStyle ? newWebinarIcon : webinarIcon}
            mr={10}
            height={newStyle ? '14px' : '20px'}
            position="relative"
            top="4px"
            display="inline-block"
          />
          { !Video && !RegistrationUrl &&
            <StyledButton
              onClick={() => { return null; }}
              type="L4"
              lineHeight="26px"
              fontSize={newStyle ? '11px' : '12px'}
              letterSpacing={newStyle ? 0 : '0.15em'}
              style={{ cursor: 'default' }}
            >
              Webinar Coming Soon
            </StyledButton> }
          { !Video && RegistrationUrl &&
            <Link
              href={RegistrationUrl}
              type="L4"
              fontSize={newStyle ? '11px' : '12px'}
              letterSpacing={newStyle ? 0 : '0.15em'}
              lineHeight="26px"
              target="_blank"
              data-button-id="webinar-button"
            >
              Register for Webinar
            </Link> }
          { Video &&
            <>
              <StyledButton
                onClick={this.toggleModal}
                type="L4"
                fontSize={newStyle ? '11px' : '12px'}
                letterSpacing={newStyle ? 0 : '0.15em'}
                lineHeight="26px"
                data-button-id="webinar-button"
              >
                View The Webinar
              </StyledButton>
              <WebinarModal
                isVisible={this.state.isModalVisible}
                handleClick={this.toggleModal}
                url={Video.video_url}
                playing={isModalVisible}
              />
            </> }
        </ExtendedBox>
      </ExtendedFlex>
    );
  }
}

Webinar.propTypes = {
  Video: PropTypes.object,
  RegistrationUrl: PropTypes.string,
  newStyle: PropTypes.bool,
};

export default Webinar;
