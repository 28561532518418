import React from 'react';

import {
  ExtendedFlex,
  Heading,
  Body,
  StyledButton,
} from '../../coreui';

class InvestToday extends React.Component {
  // eslint-disable-next-line
  showZendesk() {
    // eslint-disable-next-line
    if (typeof zE !== 'undefined') {
      // eslint-disable-next-line
      zE.activate();
    }
  }

  render() {
    return (
      <ExtendedFlex
        w={1}
        align="center"
        justify="center"
        flexDirection="column"
        py={[60, 80, 100, 120, 140]}
        px={[60, 80, 100, 120, 140]}
      >
        <Heading
          type="H2"
          color="black"
          m={0}
        >
          SUBMIT A QUESTION
        </Heading>
        <StyledButton
          type="L1"
          onClick={this.showZendesk}
          mt={[40, 40, 60, 60, 80]}
        >
          Email Us
        </StyledButton>
        <Body
          type="B1"
          textAlign="center"
          pt={40}
          maxWidth={1000}
        >
          You may find an answer to your question in our FAQS and can learn
          more about real estate investing in our Knowledge Center.
        </Body>
      </ExtendedFlex>
    );
  }
}

export default InvestToday;
